import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppData } from '@wm-accounts-backoffice-center/general-types';
import { ThunkAction } from 'redux-thunk';
import {
  Process,
  copyAccountApi,
  ProcessesByAccounts,
  ProcessesByAccountResponse,
  processStatus,
  ImportTargetProcess,
  ImportFromSameEnvError,
} from '@wm-accounts-backoffice-center/wm-api';
import { ProcessSource, ProcessTarget } from '@wm/copy-account-types';
import rootReducer from 'apps/home/src/redux/rootReducer';
import { ProcessDetails } from '@wm/copy-account-types/src/lib/process-details/process-details.type';

export interface PageProcesses {
  processes: ProcessesByAccounts;
  pollingIndex: number;
}

export interface CopyAccountState {
  CopyAccountDetails: AppData<Process>;
  PageProcesses: AppData<PageProcesses>;
  ExportLink: AppData<string>;
  ImportDetails: AppData<Process>;
}

interface PolledProcess {
  process: Process;
  accountIds: number[];
}

type PollingResult = Record<string, PolledProcess>;

export const initialAccountState: CopyAccountState = {
  CopyAccountDetails: {
    loading: false,
    error: '',
    data: undefined,
  },
  ImportDetails: {
    loading: false,
    error: '',
    data: undefined,
  },
  ExportLink: {
    loading: false,
    error: '',
    data: '',
  },
  PageProcesses: {
    loading: false,
    error: '',
    data: {
      processes: [],
      pollingIndex: 0,
    },
  },
};

const copyAccountSlice = createSlice({
  name: 'copyAccountSlice',
  initialState: initialAccountState,
  reducers: {
    copyAccountStart(state: CopyAccountState) {
      state.CopyAccountDetails.error = '';
      state.CopyAccountDetails.loading = true;
      return state;
    },
    copyAccountCleanup(state: CopyAccountState) {
      state.CopyAccountDetails.error = '';
      state.CopyAccountDetails.loading = false;
      state.CopyAccountDetails.data = undefined;
      return state;
    },
    copyAccountSuccess(
      state: CopyAccountState,
      action: PayloadAction<Process>
    ) {
      state.CopyAccountDetails.data = action.payload;
      state.CopyAccountDetails.loading = false;
      state.CopyAccountDetails.error = '';
      return state;
    },
    copyAccountFailed(state, action: PayloadAction<string>) {
      state.CopyAccountDetails.loading = false;
      state.CopyAccountDetails.error = action.payload;
      return state;
    },
    exportLinkStart(state: CopyAccountState) {
      state.ExportLink.error = '';
      state.ExportLink.loading = true;
      return state;
    },
    exportLinkSuccess(state: CopyAccountState, action: PayloadAction<string>) {
      state.ExportLink.data = action.payload;
      state.ExportLink.loading = false;
      state.ExportLink.error = '';
      return state;
    },
    exportLinkFailed(state, action: PayloadAction<string>) {
      state.ExportLink.loading = false;
      state.ExportLink.error = action.payload;
      return state;
    },
    importLinkDetailsStart(state: CopyAccountState) {
      state.ImportDetails.error = '';
      state.ImportDetails.loading = true;
      return state;
    },
    importLinkDetailsSuccess(
      state: CopyAccountState,
      action: PayloadAction<Process>
    ) {
      state.ImportDetails.data = action.payload;
      state.ImportDetails.loading = false;
      state.ImportDetails.error = '';
      return state;
    },
    importLinkDetailsFailed(state, action: PayloadAction<string>) {
      state.ImportDetails.loading = false;
      state.ImportDetails.error = action.payload;
      return state;
    },
    fetchPageProcessesStart(state: CopyAccountState) {
      state.PageProcesses.data.pollingIndex++; // stop current polling, if exists
      state.PageProcesses.error = '';
      state.PageProcesses.loading = true;
      return state;
    },
    fetchPageProcessesSuccess(
      state: CopyAccountState,
      action: PayloadAction<ProcessesByAccounts>
    ) {
      state.PageProcesses.data.processes = action.payload;
      state.PageProcesses.loading = false;
      state.PageProcesses.error = '';
      return state;
    },
    fetchPageProcessesFailed(
      state: CopyAccountState,
      action: PayloadAction<string>
    ) {
      state.PageProcesses.loading = false;
      state.PageProcesses.error = action.payload;
      return state;
    },
    processSmartPollingSuccess(
      state: CopyAccountState,
      action: PayloadAction<PollingResult>
    ) {
      const allProcsById = state.PageProcesses.data.processes;

      const pollingResult = action.payload;

      Object.keys(pollingResult).forEach((processId) => {
        const procPollingResult = pollingResult[processId];
        procPollingResult.accountIds.forEach((accountId) => {
          const accountProcs = allProcsById[accountId];
          const procToModify = accountProcs.find((p) => p.id === processId);
          if (procToModify) {
            Object.assign(procToModify, procPollingResult.process);
          }
        });
      });

      state.PageProcesses.error = '';
      return state;
    },
    importLinkDetailsCleanup(state) {
      state.ImportDetails.loading = false;
      state.ImportDetails.error = '';
      state.ImportDetails.data = undefined;
      return state;
    },
  },
});

export { copyAccountSlice };
const {
  copyAccountStart,
  copyAccountSuccess,
  copyAccountFailed,
  copyAccountCleanup,
  fetchPageProcessesStart,
  fetchPageProcessesSuccess,
  fetchPageProcessesFailed,
  processSmartPollingSuccess,
  exportLinkStart,
  exportLinkSuccess,
  exportLinkFailed,
  importLinkDetailsStart,
  importLinkDetailsSuccess,
  importLinkDetailsFailed,
  importLinkDetailsCleanup,
} = copyAccountSlice.actions;
export type rootReducerType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

export const startCopyAccount =
  (sourceAccount: ProcessSource, targetAccount: ProcessTarget): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(copyAccountStart());
      const copyAccountItem: Process = await copyAccountApi.startCopyAccount(
        sourceAccount,
        targetAccount
      );
      dispatch(copyAccountSuccess(copyAccountItem));
      dispatch(
        getCopyAccountProcessByAccountID([
          sourceAccount.account.id,
          targetAccount.account.id,
        ])
      );
    } catch (err) {
      dispatch(copyAccountFailed(err.message));
      return;
    }
  };
export const startCopySpecific =
  (sourceAccount: ProcessSource, targetAccount: ProcessTarget): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(copyAccountStart());
      const copyAccountItem: Process = await copyAccountApi.startCopySpecific(
        sourceAccount,
        targetAccount
      );
      dispatch(copyAccountSuccess(copyAccountItem));
      dispatch(
        getCopyAccountProcessByAccountID([
          sourceAccount.account.id,
          targetAccount.account.id,
        ])
      );
    } catch (err) {
      dispatch(copyAccountFailed(err.message));
      return;
    }
  };
export const startExportCopySpecific =
  (sourceAccount: ProcessSource): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(copyAccountStart());
      const copyAccountItem: Process =
        await copyAccountApi.startExportCopySpecific(sourceAccount);
      dispatch(copyAccountSuccess(copyAccountItem));
      dispatch(getCopyAccountProcessByAccountID([sourceAccount.account.id]));
    } catch (err) {
      dispatch(copyAccountFailed(err.message));
      return;
    }
  };
export const startExportCopyAccount =
  (sourceAccount: ProcessSource): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(copyAccountStart());
      const copyAccountItem: Process =
        await copyAccountApi.startExportCopyAccount(sourceAccount);
      dispatch(copyAccountSuccess(copyAccountItem));
      dispatch(getCopyAccountProcessByAccountID([sourceAccount.account.id]));
    } catch (err) {
      dispatch(copyAccountFailed(err.message));
      return;
    }
  };
export const startImportCopyAccount =
  (targetAccount: ImportTargetProcess): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(copyAccountStart());
      const copyAccountItem: Process =
        await copyAccountApi.startImportCopyAccount(targetAccount);
      dispatch(copyAccountSuccess(copyAccountItem));
      dispatch(
        getCopyAccountProcessByAccountID([targetAccount.target.account.id])
      );
    } catch (err) {
      dispatch(copyAccountFailed(err.message));
      return;
    }
  };
export const startImportSpecificCopyAccount =
  (targetAccount: ImportTargetProcess): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(copyAccountStart());
      const copyAccountItem: Process =
        await copyAccountApi.startImportSpecificCopyAccount(targetAccount);
      dispatch(copyAccountSuccess(copyAccountItem));
      dispatch(
        getCopyAccountProcessByAccountID([targetAccount.target.account.id])
      );
    } catch (err) {
      dispatch(copyAccountFailed(err.message));
      return;
    }
  };
export const getExportLink =
  (processId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(exportLinkStart());
      const copyAccountItem: string = await copyAccountApi.getExportLink(
        processId
      );
      dispatch(exportLinkSuccess(copyAccountItem));
    } catch (err) {
      dispatch(exportLinkFailed(err.message));
      return;
    }
  };
export const getImportDetails =
  (processUrl: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(importLinkDetailsStart());
      const importProcessDetails: Process =
        await copyAccountApi.getImportDetails(processUrl);
      dispatch(importLinkDetailsSuccess(importProcessDetails));
    } catch (err) {
      if (err instanceof ImportFromSameEnvError) {
        dispatch(
          importLinkDetailsFailed(
            'Cannot import a link generated in the same environment. Please use the "Copy Account" option instead.'
          )
        );
      } else {
        dispatch(importLinkDetailsFailed('Link is expired or invalid'));
      }
    }
  };
export const importDetailsCleanup = (): AppThunk => async (dispatch) => {
  dispatch(importLinkDetailsCleanup());
};
export const copyCleanup = (): AppThunk => async (dispatch) => {
  dispatch(copyAccountCleanup());
};
export const getCopyAccountProcessByAccountID =
  (accountIds: number[]): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchPageProcessesStart());
      // save the polling number to a variable immediately in case another call happens while we await the fetch
      const statePollingIndex =
        getState().copyAccountState.PageProcesses.data.pollingIndex;

      const copyAccountProcess: ProcessesByAccountResponse =
        await copyAccountApi.getProcessByAccounts(accountIds);
      dispatch(fetchPageProcessesSuccess(copyAccountProcess.processes));

      // start polling over the queried data
      dispatch(startProcessSmartPolling(statePollingIndex));
    } catch (err) {
      dispatch(fetchPageProcessesFailed(err.message));
      return;
    }
  };
export const startProcessSmartPolling =
  (pollingIndex: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      const statePollingIndex =
        getState().copyAccountState.PageProcesses.data.pollingIndex;
      if (statePollingIndex > pollingIndex) {
        return;
      }

      const stateProcsByAccounts: ProcessesByAccounts =
        getState().copyAccountState.PageProcesses.data.processes;

      if (hasUnfinishedProcs(stateProcsByAccounts)) {
        await new Promise((resolve) => setTimeout(resolve, 3000));

        const unfinishedProcsByAccountIds =
          filterUnfinishedProcs(stateProcsByAccounts);
        const pollingResult: PollingResult = await queryUnfinishedProcsStatus(
          unfinishedProcsByAccountIds
        );

        // We check this AGAIN in case it had changed in the meantime
        const statePollingIndex =
          getState().copyAccountState.PageProcesses.data.pollingIndex;
        if (statePollingIndex > pollingIndex) {
          return;
        }
        dispatch(processSmartPollingSuccess(pollingResult));
        dispatch(startProcessSmartPolling(pollingIndex));
      }
    } catch (err) {
      console.error('Could not poll unfinished Copy Account Processes', err);
      return;
    }
  };
function hasUnfinishedProcs(procsByAccountIds: ProcessesByAccounts) {
  for (const accountId in procsByAccountIds) {
    const accountProcs: any[] = procsByAccountIds[accountId];
    if (accountProcs.find((p) => isUnfinishedProcess(p))) {
      return true;
    }
  }
  return false;
}
function isUnfinishedProcess(process: Process): boolean {
  return (
    process.status === processStatus.IN_PROGRESS ||
    process.status === processStatus.PENDING
  );
}
function filterUnfinishedProcs(
  procsByAccountIds: ProcessesByAccounts
): ProcessesByAccounts {
  const unfinishedProcsByAccountId: ProcessesByAccounts = {};
  for (const accountId in procsByAccountIds) {
    const accountProcs: Process[] = procsByAccountIds[accountId];

    const unfinishedProceesses = accountProcs.filter((process) =>
      isUnfinishedProcess(process)
    );
    if (unfinishedProceesses.length) {
      unfinishedProcsByAccountId[accountId] = unfinishedProceesses;
    }
  }

  return unfinishedProcsByAccountId;
}
async function queryUnfinishedProcsStatus(
  procsByAccountIds: ProcessesByAccounts
): Promise<PollingResult> {
  const reverseDictionary: Record<string, number[]> =
    reverseProcessesDictionary(procsByAccountIds);

  const uniqueProcessIds = Object.keys(reverseDictionary);
  const updatedProcesses = await Promise.all(
    uniqueProcessIds.map((pId) => {
      return copyAccountApi.getProcessStatusById(pId);
    })
  );

  const result: PollingResult = {};
  updatedProcesses.forEach((updatedProcess) => {
    result[updatedProcess.id] = {
      process: updatedProcess,
      accountIds: reverseDictionary[updatedProcess.id],
    };
  });

  return result;
}
function reverseProcessesDictionary(
  procsByAccountIds: ProcessesByAccounts
): Record<string, number[]> {
  const reverseDictionary: Record<string, number[]> = {};
  Object.keys(procsByAccountIds).forEach((aIdString) => {
    const accountId = Number(aIdString);
    const processes: Process[] = procsByAccountIds[accountId];
    processes.forEach((process) => {
      reverseDictionary[process.id] = reverseDictionary[process.id] || [];
      reverseDictionary[process.id].push(accountId);
    });
  });
  return reverseDictionary;
}
