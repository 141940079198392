import { StyledIdpEmptyState, StyledSubTitle } from './idp-empty-state.styles';
import { Typography } from '@walkme/ui-core';
import React from 'react';

export const IdpEmptyState = () => {
  return (
    <StyledIdpEmptyState>
      <Typography variant="T20-2">No Results</Typography>
      <StyledSubTitle>
        The account's Admin should setup IDP vendor to allow automatic UUID
        assignment
      </StyledSubTitle>
    </StyledIdpEmptyState>
  );
};
