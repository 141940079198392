import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface CommonSnackbarProps {
  open: boolean;
  severity: string;
  messageText: string;
  onClose: (event, reason) => void;
  autoHideDuration?: number;
}

export const CommonSnackbar = ({
  open,
  onClose,
  messageText,
  severity,
  autoHideDuration = 3000,
}: CommonSnackbarProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity}>
        {messageText}
      </Alert>
    </Snackbar>
  );
};
