import { Systems, SystemTypeKey } from 'wm-accounts-sdk';
import { AppData } from '../../../../general-types/src';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSystems, SystemsState } from '../redux/systems.slice';

export const useSystems = (filterByTypes?: SystemTypeKey[]) => {
  const systemsAppData: AppData<Systems> = useSelector(
    (state: { systemsState: SystemsState }) => state.systemsState.systems
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSystems(false, filterByTypes));
  }, [dispatch]);

  return {
    systemsAppData,
  };
};

export default useSystems;
