import Skeleton from '@material-ui/lab/Skeleton';
import { styledTheme } from '@wm-accounts-backoffice-center/shared-styles';
import styled from 'styled-components';

const {
  colors: {
    sideBar: { darkLoader },
  },
} = styledTheme;

export const StyledSkeleton = styled(Skeleton)`
  &.side-menu {
    margin: 10px auto 20px;
  }

  &.dark {
    background-color: ${darkLoader};
  }
`;
