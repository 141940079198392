import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { WMSnackbarVariant } from '@walkme/wm-ui';
import { Account, SnackBarMessage } from 'libs/wm-api/src';
import { AppThunk } from './merge-account.store';
import { MergeAccountsResult, mergeAccountApi } from './api/merge-account-api';

export interface MergeRequestStatus {
  loading: boolean;
  error: any;
  result: MergeAccountsResult;
}

export interface MergeDataState {
  sourceAccount?: Account;
  targetAccount?: Account;
  excludeSystemIds: number[];
  excludedUserIds: number[];
  mergeRequestStatus: MergeRequestStatus;
}

export const initialMergeDataState: MergeDataState = {
  sourceAccount: null,
  targetAccount: null,
  excludedUserIds: [],
  excludeSystemIds: [],
  mergeRequestStatus: {
    loading: false,
    error: null,
    result: null,
  },
};

const mergeDataSlice = createSlice({
  name: 'mergeData',
  initialState: initialMergeDataState,
  reducers: {
    resetMergeData(state: MergeDataState) {
      state.sourceAccount = null;
      state.targetAccount = null;
      state.excludedUserIds = [];
      state.excludeSystemIds = [];
      state.mergeRequestStatus = {
        loading: false,
        error: null,
        result: null,
      };
    },
    sourceAccountSelected(
      state: MergeDataState,
      action: PayloadAction<Account>
    ) {
      state.sourceAccount = action.payload;
    },
    systemIncluded(state: MergeDataState, action: PayloadAction<number>) {
      const systemId = action.payload;
      const index = state.excludeSystemIds.indexOf(systemId);
      if (index > -1) {
        state.excludeSystemIds.splice(index, 1);
      }
    },
    systemExcluded(state: MergeDataState, action: PayloadAction<number>) {
      state.excludeSystemIds.push(action.payload);
    },
    userIncluded(state: MergeDataState, action: PayloadAction<number>) {
      const userId = action.payload;
      const index = state.excludedUserIds.indexOf(userId);
      if (index > -1) {
        state.excludedUserIds.splice(index, 1);
      }
    },
    userExcluded(state: MergeDataState, action: PayloadAction<number>) {
      state.excludedUserIds.push(action.payload);
    },
    targetAccountSelected(
      state: MergeDataState,
      action: PayloadAction<Account>
    ) {
      state.targetAccount = action.payload;
    },
    mergeRequestStarted(state: MergeDataState) {
      state.mergeRequestStatus.loading = true;
      state.mergeRequestStatus.error = null;
      state.mergeRequestStatus.result = null;
    },
    mergeRequestFinished(state: MergeDataState, action: PayloadAction<any>) {
      state.mergeRequestStatus.loading = false;
      state.mergeRequestStatus.error = null;
      state.mergeRequestStatus.result = action.payload;
    },
    mergeRquestFailed(state: MergeDataState, action: PayloadAction<any>) {
      state.mergeRequestStatus.loading = false;
      state.mergeRequestStatus.error = action.payload;
      state.mergeRequestStatus.result = null;
    },
  },
});

export default mergeDataSlice.reducer;

export const {
  resetMergeData,
  sourceAccountSelected,
  systemIncluded,
  systemExcluded,
  userExcluded,
  userIncluded,
  targetAccountSelected,
  mergeRequestStarted,
  mergeRequestFinished,
  mergeRquestFailed,
} = mergeDataSlice.actions;

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const mergeSubmitted = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(mergeRequestStarted());
    const mergeAccountResult = await submitMergeReqeuqst(getState().mergeData);
    dispatch(mergeRequestFinished(mergeAccountResult));
  } catch (error) {
    dispatch(mergeRquestFailed(error));
  }
};

const submitMergeReqeuqst = async (data: MergeDataState) => {
  const fromAccountGuid = data?.sourceAccount?.guid;
  const toAccountGuid = data?.targetAccount?.guid;

  if (!fromAccountGuid || !toAccountGuid) {
    throw new Error('Missing source or target account');
  }

  const excludedSystemsGuids = data.sourceAccount.systems
    .filter((system) => data.excludeSystemIds.includes(system.id))
    .map((system) => system.guid);

  const excludedUsersGuids = data.sourceAccount.users
    .filter((user) => data.excludedUserIds.includes(user.id))
    .map((user) => user.guid);

  return mergeAccountApi.startMergeAccount({
    fromAccountGuid,
    toAccountGuid,
    excludedSystemsGuids,
    excludedUsersGuids,
  });
};
