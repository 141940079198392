import styled from 'styled-components';

export const StyledSystemTheming = styled.div`
  padding: 22px 8px 22px;

  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;

    & span[class^='Tooltip'] {
      display: flex;
    }
  }

  .enable-auto-theming {
    margin-top: 12px;
    display: flex;
    gap: 16px;
    align-items: center;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #2f426c;
  }
`;
