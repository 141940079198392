import React from 'react';
import {
  MergeAccountsActionResult,
  MergeAccountsActionStatus,
} from '../../api/merge-account-api.types';
import { WMUser } from 'wm-accounts-sdk';
import { StatusVariant } from '@walkme/ui-core';
import {
  SectionWrapperDiv,
  StyledStatus,
  SummaryError,
  SummaryHeader,
  SummaryItem,
  SummarySubHeader,
} from '../merge-summary.styles';

export interface UsersSummaryProps {
  usersResult: MergeAccountsActionResult<Partial<WMUser>[]>;
}

export const UsersSummary = ({ usersResult }: UsersSummaryProps) => {
  if (!usersResult) {
    return;
  }

  const hasError = usersResult.status === MergeAccountsActionStatus.failure;
  const users = (!hasError ? usersResult.value : []) as Partial<WMUser[]>;
  const sortedUsers = [...users].sort((a, b) => (a.email < b.email ? -1 : 1));
  const error = usersResult.value as Error;

  return (
    <SectionWrapperDiv>
      <SummaryHeader>
        <StyledStatus
          variant={!hasError ? StatusVariant.Success : StatusVariant.Error}
        />
        Merge Users: {hasError ? 'Failed' : 'Completed Successfully'}
      </SummaryHeader>
      {hasError && <SummaryError>Error: {error.message}</SummaryError>}
      {!hasError && (
        <>
          <SummarySubHeader>Users merged:</SummarySubHeader>
          {
            sortedUsers.length
            ? sortedUsers.map((user) => (
              <SummaryItem key={user.guid}>{user.email}</SummaryItem>
            ))
            : <SummaryItem>(None)</SummaryItem>
          }
        </>
      )}
    </SectionWrapperDiv>
  );
};
