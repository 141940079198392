import * as React from 'react';
import { SVGProps } from 'react';

const SvgPreferences = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#Preferences_svg__a)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M.5 10v3.5l3.5 2 2.5-1.429M4 15.5V12M12 8l-3.5 2 3.5 2 3.5-2L12 8Z" />
      <path d="M8.5 10v3.5l3.5 2 3.5-2V10M12 12v3.5M8 .5l-3.5 2 3.5 2 3.5-2L8 .5Z" />
      <path d="M4.5 2.5V6L8 8l3.5-2V2.5M8 4.5V8M6.5 9.429 4 8 .5 10 4 12l2-1.143" />
    </g>
    <defs>
      <clipPath id="Preferences_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPreferences;
