import * as React from 'react';
import { SVGProps } from 'react';

const SvgConfigurations = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 6.5h-10M17.5 6.5h-1M16.5 13.5h-7M5.5 13.5h-3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={14.5} cy={6.5} r={2} stroke="currentColor" />
    <circle cx={7.5} cy={13.5} r={2} stroke="currentColor" />
  </svg>
);

export default SvgConfigurations;
