import UrlCellRenderer from './url-cell-renderer';
import moment from 'moment';

export const headCells = [
  {
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerName: 'Display Name',
    field: 'aliasDisplayName',
    minWidth: 200,
    sortable: true,
    resizable: true,
  },
  {
    headerName: 'Alias',
    field: 'alias',
    sortable: true,
    resizable: true,
  },
  {
    headerName: 'Event Name',
    field: 'eventName',
    minWidth: 200,
    sortable: true,
    resizable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      debounceMs: 500,
      caseSensitive: false,
      textFormatter: (value: string) => {
        return value ? value.replace(/_/g, ' ') : value;
      },
    },
  },
  {
    headerName: 'Url',
    field: 'url',
    minWidth: 200,
    sortable: true,
    filter: 'agTextColumnFilter',
    resizable: true,
    cellRendererFramework: UrlCellRenderer,
  },
  {
    headerName: 'Sync Group',
    field: 'syncOpGroup',
    sortable: true,
    resizable: true,
    minWidth: 100,
    maxWidth: 200,
  },
  {
    headerName: 'Priority',
    field: 'syncOpGroupPriority',
    resizable: true,
    sortable: true,
    sort: 'asc',
    sortIndex: 0,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    headerName: 'Creation Date',
    field: 'creationDate',
    resizable: true,
    sortable: true,
    minWidth: 100,
    maxWidth: 150,
    valueGetter: (params: any) =>
      moment(params.data.creationDate).format('MMM Do, YYYY'),
  },
];
