import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface AppsSelectionIconProps {
  active: number;
  className?: string;
}

const StyledAppsSelectionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  background: ${(props: AppsSelectionIconProps) =>
    props.active ? '#ebeffd' : 'white'};

  &:hover {
    background: #ebeffd;
  }
`;

export const AppsSelectionIcon = (
  props: AppsSelectionIconProps
): ReactElement => {
  return (
    <StyledAppsSelectionIcon active={props.active} className={props.className}>
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3 12a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1v-2a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1H7a1 1 0 01-1-1v-2a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1h2zM3 6a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1V7a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1H7a1 1 0 01-1-1V7a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V7a1 1 0 011-1h2zM3 0a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1H7a1 1 0 01-1-1V1a1 1 0 011-1h2zm6 0a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V1a1 1 0 011-1h2z"
          fill={props.active ? '#3b61eb' : '#9DA5C2'}
          fillRule="evenodd"
        />
      </svg>
    </StyledAppsSelectionIcon>
  );
};

export default AppsSelectionIcon;
