import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { aiManagerApi } from '@wm-accounts-backoffice-center/wm-api';
import { AiSettings, AiManagerState, Rule } from './types';
import { AppData } from '@wm-accounts-backoffice-center/general-types';

export const initialAiManagerState: AiManagerState = {
  aiSettings: {
    loading: false,
    error: '',
    data: {
      isEnabled: {
        loading: false,
        error: '',
        data: false,
      },
      rules: [],
    },
  },
};

export const getAiSettings = createAsyncThunk(
  'aiManager/getAiSettings',
  async (accountId: string, { rejectWithValue }) => {
    try {
      return await aiManagerApi.getAiSettings(accountId);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addToAllowList = createAsyncThunk(
  'aiManager/addToWhiteList',
  async (accountId: string, { rejectWithValue }) => {
    try {
      return await aiManagerApi.addToAllowList(accountId);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const removeFromAllowList = createAsyncThunk(
  'aiManager/removeFromWhitList',
  async (accountId: string, { rejectWithValue }) => {
    try {
      return await aiManagerApi.removeFromAllowList(accountId);
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

const aiManagerSlice = createSlice({
  name: 'aiManagerSlice',
  initialState: initialAiManagerState,
  reducers: {
    clearAiSettingsState: (state: AiManagerState) => {
      state.aiSettings = initialAiManagerState.aiSettings;
    },
  },
  extraReducers: {
    [`${getAiSettings.pending}`]: (state: AiManagerState) => {
      state.aiSettings.loading = true;
    },
    [`${getAiSettings.fulfilled}`]: (
      state: AiManagerState,
      action: PayloadAction<AiSettings>
    ) => {
      state.aiSettings.loading = false;
      state.aiSettings.data.isEnabled.data = action.payload.isEnabled;
      state.aiSettings.data.rules = action.payload.rules;
    },
    [`${getAiSettings.rejected}`]: (state) => {
      state.aiSettings.loading = false;
    },

    [`${addToAllowList.pending}`]: (state: AiManagerState) => {
      state.aiSettings.data.isEnabled.loading = true;
    },
    [`${addToAllowList.fulfilled}`]: (state: AiManagerState) => {
      state.aiSettings.data.isEnabled.loading = false;
      state.aiSettings.data.isEnabled.data = true;
    },
    [`${addToAllowList.rejected}`]: (state) => {
      state.aiSettings.data.isEnabled.loading = false;
    },

    [`${removeFromAllowList.pending}`]: (state: AiManagerState) => {
      state.aiSettings.data.isEnabled.loading = true;
    },
    [`${removeFromAllowList.fulfilled}`]: (state: AiManagerState) => {
      state.aiSettings.data.isEnabled.loading = false;
      state.aiSettings.data.isEnabled.data = false;
    },
    [`${removeFromAllowList.rejected}`]: (state) => {
      state.aiSettings.data.isEnabled.loading = false;
    },
  },
});

export const { clearAiSettingsState } = aiManagerSlice.actions;
export const getIsEnabled = (state: any): AppData<boolean> =>
  state.aiManagerState.aiSettings.data.isEnabled;
export const getRules = (state: any): Rule[] =>
  state.aiManagerState.aiSettings.data.rules;
export const getAiSettingsMetaData = (
  state: any
): { loading: boolean; error: string } => {
  return {
    loading: state.aiManagerState.aiSettings.loading,
    error: state.aiManagerState.aiSettings.error,
  };
};

export { aiManagerSlice };
