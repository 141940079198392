import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Account,
  apiErrorFormatter,
} from '@wm-accounts-backoffice-center/wm-api';
import {
  AccountsSdk,
  AccountType,
  AddPartnerUser,
  BoConnectSystemsDto,
  ClientSystemsDeployableData,
  ContractsRawData,
  PartnerUserClientSystem,
  WMPartnerClient,
} from 'wm-accounts-sdk';
import {
  PartnerClientsTable,
  PartnerManageUsers,
  PartnerClientRow,
  PartnerAllowedRole,
} from '@walkme/accounts-partners-ui';
import { EmptyLabel, SubTitleTab, TitleTab } from '../styles/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Loader,
  Spinner,
  ToasterVariant,
  Tooltip,
  useToaster,
} from '@walkme/ui-core';
import { Add, Delete } from '@walkme/ui-icons';
import PartnerClientsSelection from './partners-clients-selection';

export interface PartnerUnassignDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

function PartnerUnassignDialog({
  isOpen,
  onConfirm,
  onClose,
}: PartnerUnassignDialogProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogTitle>Remove Partner</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will remove partner captabilites and all assigned clients and
          users
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true);
            onConfirm();
          }}
          variant="solid"
          color="danger"
        >
          {isLoading ? <Spinner /> : 'Remove'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface PartnersWrapperProps {
  accountData: Account;
}

export function PartnersWrapper({ accountData }: PartnersWrapperProps) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRemovePartnerDialogOpen, setRemovePartnerDialogOpen] =
    useState<boolean>(false);
  const [isAssignPartnerClientDialogOpen, setAssignPartnerClientDialogOpen] =
    useState<boolean>(false);
  const [isChangingPartner, setIsChangingPartner] = useState<boolean>(false);
  const { addToaster } = useToaster();
  const [isPartner, setIsPartner] = useState<boolean>(false);
  const [parnterClientsData, setParnterClientsData] = useState<
    WMPartnerClient[]
  >([]);
  const [parnterClientsDeployablesData, setParnterClientsDeployablesData] =
    useState<ClientSystemsDeployableData[]>([]);
  const [manageUsersDrawerOpen, setManageUsersDrawerOpen] = useState(false);
  const [selectedClientToManageUsers, setSelectedClientToManageUsers] =
    useState<PartnerClientRow>(null);
  const [updatingManageUsers, setupdatingManageUsers] = useState(false);

  const selectedClient =
    selectedClientToManageUsers &&
    parnterClientsData?.find(
      (client) =>
        client.accountId === selectedClientToManageUsers.clientAccountId
    );
  const allUPartnerUsers = accountData.users;
  const partnerAssignedUsersForSelectedClient: PartnerUserClientSystem[] =
    selectedClient?.assignedUsersToSystem || [];
  const selectedClientSystems = selectedClient ? selectedClient.systems : [];
  const selectedClientAllowedRoles: PartnerAllowedRole[] = selectedClient
    ? selectedClient.partnerUsersRoles.map((roleId) => ({
        id: roleId,
        displayName: accountData?.roles?.find(
          (allowedRole) => allowedRole.id === roleId
        )?.displayName,
      }))
    : [];

  const onClickToManageUsers = (clientRowData: PartnerClientRow) => {
    setSelectedClientToManageUsers(clientRowData);
    setManageUsersDrawerOpen(true);
  };

  const handleAssignUsers = useCallback(
    async (updateUsers: AddPartnerUser[]) => {
      setupdatingManageUsers(true);
      try {
        await AccountsSdk.getInstance().partners.backofficeAssignPartnerUsersForClient(
          accountData.id,
          {
            clientAccountId: selectedClientToManageUsers.clientAccountId,
            partnerUsers: updateUsers,
          }
        );
        const partnerClients =
          await AccountsSdk.getInstance().partners.backofficGetPartnerAllClients(
            accountData.id
          );
        setParnterClientsData(partnerClients);
        setManageUsersDrawerOpen(false);
        addToaster({
          message: `sucessfully update client assigned users`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        setupdatingManageUsers(false);
      } catch (e) {
        addToaster({
          message: `failed to update client users: ${e}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        setupdatingManageUsers(false);
      }
    },
    [
      selectedClientToManageUsers,
      setManageUsersDrawerOpen,
      parnterClientsData,
      setupdatingManageUsers,
      dispatch,
    ]
  );

  const deleteAssignUsers = useCallback(
    async (deletUsers: number[]) => {
      setupdatingManageUsers(true);
      try {
        await AccountsSdk.getInstance().partners.backofficeRemovePartnerUsersForClient(
          accountData.id,
          {
            clientAccountId: selectedClientToManageUsers.clientAccountId,
            partnerUsers: deletUsers,
          }
        );
        const partnerClients =
          await AccountsSdk.getInstance().partners.backofficGetPartnerAllClients(
            accountData.id
          );
        setParnterClientsData(partnerClients);
        setManageUsersDrawerOpen(false);
        addToaster({
          message: `sucessfully deleted client assigned users`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        setupdatingManageUsers(false);
      } catch (e) {
        addToaster({
          message: `failed to delete client users: ${e}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        setupdatingManageUsers(false);
      }
    },
    [
      selectedClientToManageUsers,
      setManageUsersDrawerOpen,
      parnterClientsData,
      setupdatingManageUsers,
      dispatch,
    ]
  );

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const partnerData =
        await AccountsSdk.getInstance().partners.backofficGetPartner(
          accountData.id
        );
      if (!partnerData?.isPartner) {
        setIsPartner(false);
        setIsLoading(false);
        return;
      }
      setIsPartner(true);
      const partnerClients =
        await AccountsSdk.getInstance().partners.backofficGetPartnerAllClients(
          accountData.id
        );
      setParnterClientsData(partnerClients);
      setIsLoading(false);
      if (partnerClients?.length > 0) {
        const partnerClientsDeployables =
          await AccountsSdk.getInstance().partners.backofficGetPartnerAllClientsDeployables(
            accountData.id
          );
        setParnterClientsDeployablesData(partnerClientsDeployables);
      }
    } catch (e) {
      setIsLoading(true);
      addToaster({
        message: `failed to get partner data: ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [
    accountData,
    dispatch,
    setParnterClientsData,
    setIsLoading,
    setIsPartner,
  ]);

  useEffect(() => {
    fetchData();
  }, [accountData]);

  const removePartnerClient = useCallback(
    async (clientId: number) => {
      try {
        setIsLoading(true);
        await AccountsSdk.getInstance().partners.backofficeDeleteClientForPartner(
          accountData.id,
          clientId
        );
        const partnerClients =
          await AccountsSdk.getInstance().partners.backofficGetPartnerAllClients(
            accountData.id
          );
        setParnterClientsData(partnerClients);
        setIsLoading(false);
        addToaster({
          message: `sucessfully remove partner client`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        setIsChangingPartner(false);
      } catch (e) {
        addToaster({
          message: `failed to remove partner client: ${apiErrorFormatter(e)}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        setIsLoading(false);
      }
    },
    [accountData, dispatch, setIsLoading]
  );

  const removePartnerClientSystem = useCallback(
    async (clientId: number, systemId: number) => {
      try {
        setIsLoading(true);
        await AccountsSdk.getInstance().partners.backofficeDeleteClientSystemForPartner(
          accountData.id,
          clientId,
          systemId
        );
        const partnerClients =
          await AccountsSdk.getInstance().partners.backofficGetPartnerAllClients(
            accountData.id
          );
        setParnterClientsData(partnerClients);
        setIsLoading(false);
        addToaster({
          message: `sucessfully remove partner client system`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        setIsChangingPartner(false);
      } catch (e) {
        addToaster({
          message: `failed to remove partner client system: ${apiErrorFormatter(
            e
          )}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        setIsLoading(false);
      }
    },
    [accountData, dispatch, setIsLoading]
  );

  const handleAccountPartnerType = useCallback(
    async (assignAsPartner: boolean) => {
      try {
        setIsChangingPartner(true);
        if (assignAsPartner) {
          await AccountsSdk.getInstance().partners.backofficAddPartner(
            accountData.id,
            {}
          );
        } else {
          await AccountsSdk.getInstance().partners.backofficRemovePartner(
            accountData.id
          );
          setParnterClientsData([]);
          setParnterClientsDeployablesData([]);
          setRemovePartnerDialogOpen(false);
        }
        const partnerData =
          await AccountsSdk.getInstance().partners.backofficGetPartner(
            accountData.id
          );
        setIsPartner(partnerData.isPartner);
        addToaster({
          message: `sucessfully update account as partner`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        setIsChangingPartner(false);
      } catch (e) {
        setRemovePartnerDialogOpen(false);
        addToaster({
          message: `failed to update partner data: ${apiErrorFormatter(e)}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        setIsChangingPartner(false);
      }
    },
    [accountData, dispatch, setIsChangingPartner]
  );

  const headerActions = [
    <Tooltip title="Add new client">
      <IconButton
        variant="ghost"
        onClick={() => {
          setAssignPartnerClientDialogOpen(true);
        }}
      >
        <Add />
      </IconButton>
    </Tooltip>,
    <Tooltip title="Remove all clients and systems and unassign as partner">
      <IconButton
        variant="ghost"
        onClick={() => {
          setRemovePartnerDialogOpen(true);
        }}
      >
        <Delete />
      </IconButton>
    </Tooltip>,
  ];

  return (
    <div>
      <PartnerClientsSelection
        account={accountData}
        showDialog={isAssignPartnerClientDialogOpen}
        partnerClients={parnterClientsData}
        wizardExitCallback={(addedNewClient) => {
          setAssignPartnerClientDialogOpen(false);
          if (addedNewClient) {
            fetchData();
          }
        }}
      />
      <PartnerUnassignDialog
        isOpen={isRemovePartnerDialogOpen}
        onClose={() => {
          setRemovePartnerDialogOpen(false);
        }}
        onConfirm={() => {
          handleAccountPartnerType(false);
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '24px',
        }}
      >
        <TitleTab>Partner Details</TitleTab>
        <SubTitleTab>Partner connected clients</SubTitleTab>
      </div>
      <div>
        {isLoading ? (
          <div
            style={{
              background: 'white',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: '400px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader />
          </div>
        ) : (
          <div>
            {isPartner ? (
              <div style={{ height: '49vh' }}>
                <PartnerClientsTable
                  partnerClients={parnterClientsData}
                  partnerClientsDeployables={parnterClientsDeployablesData}
                  hideButtons={true}
                  onClickToManageUsers={onClickToManageUsers}
                  onClickMenuOptions={null}
                  onClickToRemoveClient={(clientId: number) => {
                    removePartnerClient(clientId);
                  }}
                  onClickToRemoveClientSystem={(
                    clientId: number,
                    systemId: number
                  ) => {
                    removePartnerClientSystem(clientId, systemId);
                  }}
                  headerActions={headerActions}
                ></PartnerClientsTable>
                <PartnerManageUsers
                  isDrawerOpen={manageUsersDrawerOpen}
                  allPartnerUsers={allUPartnerUsers}
                  loading={updatingManageUsers}
                  allowedSystems={selectedClientSystems}
                  allowedRoles={selectedClientAllowedRoles}
                  onClickAssignedUsers={handleAssignUsers}
                  onClickDeleteUsers={deleteAssignUsers}
                  onClose={() => {
                    setManageUsersDrawerOpen(false);
                  }}
                  partnerClientName={selectedClientToManageUsers?.clientName}
                  assignedUsers={partnerAssignedUsersForSelectedClient}
                />
              </div>
            ) : (
              <div
                style={{
                  marginTop: '50px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src="/assets/searching.svg" width="250px" height="250px" />
                <EmptyLabel>Account not assigned as partner</EmptyLabel>
                <Button
                  disabled={isChangingPartner}
                  onClick={() => {
                    handleAccountPartnerType(true);
                  }}
                  variant="solid"
                  color="primary"
                >
                  {isChangingPartner ? <Spinner /> : 'Set as partner'}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
export default PartnersWrapper;
