import React, { ReactElement } from 'react';
import { StyledSkeleton } from './styles/styles';

export interface LoaderProps {
  variant: 'text' | 'rect' | 'circle';
  width: number;
  height: number;
  className?: string;
}

export const SkeletonLoader = ({
  variant,
  width,
  height,
  className,
}: LoaderProps): ReactElement => {
  return (
    <StyledSkeleton
      width={width}
      height={height}
      variant={variant}
      className={className && className}
    />
  );
};

export default SkeletonLoader;
