import React, { useState } from 'react';
import { ChevronRight } from '@walkme/ui-icons/large';
import { ChevronDown } from '@walkme/ui-icons/large';
import { makeStyles } from '@material-ui/core';
import { TaskView } from './task';
import { StatusIndicator } from './status-indicator';
import { indentationConstant } from './consts';

const useStyles = makeStyles(() => ({
  logViewChildProcess: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '5px 20px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

export const ChildProcessView = ({
  childProcess,
  isSpecific,
  indentFactor = 0,
}) => {
  const classes = useStyles();
  const [openProcess, setOpenProcess] = useState(true);

  let additionalInfo: string;
  const isExport: boolean = childProcess.actionType?.includes('export');
  const isAccountLevel: boolean = childProcess.actionType?.includes('account');
  let additionalInfoSource;
  if (isExport) {
    additionalInfoSource = isAccountLevel
      ? childProcess.details.source.account
      : childProcess.details.source.system;
  } else {
    additionalInfoSource = isAccountLevel
      ? childProcess.details.target.account
      : childProcess.details.target.system;
  }

  additionalInfo = isSpecific
    ? ` (display name: ${additionalInfoSource?.displayName}, type: ${additionalInfoSource?.name}, id: ${additionalInfoSource?.id})`
    : ` (name: ${additionalInfoSource?.name}, id: ${additionalInfoSource?.id})`;

  return (
    <div>
      <div
        className={classes.logViewChildProcess}
        onClick={() => setOpenProcess(!openProcess)}
        // style={openProcess ? { background: '#F7F9FC' } : null}
        style={{
          background: openProcess ? '#F7F9FC' : '#FFF',
          paddingLeft: `${20 + indentFactor * indentationConstant}px`,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {openProcess ? <ChevronDown /> : <ChevronRight />}
          {childProcess.actionType}
          {additionalInfo}
        </div>
        <StatusIndicator
          status={childProcess.status}
          errors={childProcess.errors}
        />
      </div>
      {openProcess &&
        childProcess.tasks.map((task, index) => {
          return (
            <TaskView
              task={task}
              key={index}
              isSpecific={isSpecific}
              indentFactor={indentFactor + 1}
            />
          );
        })}
    </div>
  );
};
