import {
  Divider,
  FormControlLabel,
  makeStyles,
  RadioGroup,
} from '@material-ui/core';
import { WMDivider, WMRadio } from '@walkme/wm-ui';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Checkbox,
  InputSearch,
  Tooltip,
  Loader,
  CheckboxSizes,
} from '@walkme/ui-core';
import { getAdminSpaceBaseURL } from '@wm-accounts-backoffice-center/wm-api';
import {
  DeployablesState,
  flatDeployablesAndFolders,
  getSystemDeployables,
  useSystemDeployables,
} from '@wm-accounts-backoffice-center/state-management-deployables';
import {
  Shuttle,
  Shotout,
  Launcher,
  Task,
  Survey,
  Resource,
  Settings,
  Folder,
  ArrowLeft,
  Home,
  ArrowRight,
} from '@walkme/ui-icons';
import { PhotoSizeSelectActual } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import SmartWalkThrough from '../../styles/SmartWalkThrough';
import OnbordingTask from '../../styles/OnbordingTask';
import * as icons from '@walkme/ui-icons';

const StyledLabelOption = styled('div')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
  margin-bottom: 10px;
`;

const StyledLabelOptionContent = styled('div')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #637191;
  margin-bottom: 20px;
`;

const SystemNameLabel = styled('label')`
  color: #2f426c;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
const SystemSnippetEmailLabel = styled('label')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8d97ae;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
  margin-left: 0;
`;

const useStyles = makeStyles((theme) => ({
  selectedSystem: {
    border: '1px solid #3B61EB',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
  },
  notSelectedSystem: {
    border: '1px solid #e6ecf8',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(212, 223, 245, 0.5)',
    },
  },
}));

export interface CopyAccountWizardPage1Props {
  account: any;
  setDisabled: (disabled: boolean) => void;
  selectedSystemsToCopy: any;
  setSelectedSystems: (selectedSystems) => void;
  copyProgressOption: string;
  setCopyProgressOption: (copyProgressOption) => void;
  deployablesToCopy: any[];
  setDeployablesToCopy: (deployablesToCopy) => void;
}
const CopyAccountWizardPage1 = ({
  account,
  setDisabled,
  selectedSystemsToCopy,
  setSelectedSystems,
  copyProgressOption,
  setCopyProgressOption,
  deployablesToCopy,
  setDeployablesToCopy,
}: CopyAccountWizardPage1Props) => {
  const ADMIN_SPACE_BASE_URL = getAdminSpaceBaseURL();
  const classes = useStyles();
  const dispatch = useDispatch();
  const allSystems = (account?.systems || [])
    .filter((s) => !s.statusDetails || s.statusDetails.status === 'enabled')
    .sort((s1, s2) => {
      return s1.displayName < s2.displayName ? -1 : 1;
    });
  const webSystems = allSystems.filter((s) => !s.associatedSystem);

  const [initialSystems, setInitialSystems] = useState([...webSystems]);
  const [displayedSystems, setDisplayedSystems] = useState([...webSystems]);

  const [copySpecificSelectedSystem, setCopySpecificSelectedSystem] = useState(
    selectedSystemsToCopy.length > 0 ? selectedSystemsToCopy[0].id : null
  );
  const [copySpecificFolderId, setCopySpecificFolderId] = useState(null);
  const [query, setQuery] = useState('');

  const [queryDeployablesOrFolders, setQueryDeployablesOrFolders] =
    useState('');

  const systemDeployablesAppData = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.systemDeployables
  );

  const flatDeployablesAppData = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.flatSystemDeployables
  );

  const DeployablesTypesAppData = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.realDeployablesTypes
  );

  const [folderAndDeployables, setFolderAndDeployables] = useState(
    systemDeployablesAppData.data
  );

  useEffect(() => {
    copyProgressOption === 'contentItems' &&
      selectedSystemsToCopy.length > 0 &&
      dispatch(
        getSystemDeployables(
          selectedSystemsToCopy[0].id,
          copySpecificFolderId,
          false
        )
      );
  }, [copyProgressOption, selectedSystemsToCopy, copySpecificFolderId]);

  useEffect(() => {
    setFolderAndDeployables(systemDeployablesAppData.data);
  }, [systemDeployablesAppData.data]);

  const selectedAll = useMemo(() => {
    return selectedSystemsToCopy.length === displayedSystems.length;
  }, [selectedSystemsToCopy]);

  const [selectedAllDeployables, setSelectedAllDeployables] = useState(false);

  const handleChange = (event) => {
    setCopyProgressOption(event.target.value);
    setInitialSystems(
      event.target.value === 'systems' ? webSystems : allSystems
    );
    setSelectedSystems([]);
    setQuery('');
    setDeployablesToCopy([]);
  };

  useEffect(() => {
    if (
      selectedAllDeployables &&
      selectedSystemsToCopy &&
      selectedSystemsToCopy.length > 0
    ) {
      dispatch(
        flatDeployablesAndFolders(
          selectedSystemsToCopy[0].id,
          copySpecificFolderId
        )
      );
      setDeployablesToCopy(flatDeployablesAppData.data);
    }
    if (!selectedAllDeployables) setDeployablesToCopy([]);
  }, [selectedAllDeployables, copySpecificFolderId]);

  useEffect(() => {
    setDeployablesToCopy(flatDeployablesAppData.data);
  }, [flatDeployablesAppData.data]);

  const handleDeployableSelection = (deployable) => {
    const isDeployableIndepolyableToCopy =
      deployablesToCopy?.includes(deployable);
    const newDeployablesToCopy = isDeployableIndepolyableToCopy
      ? deployablesToCopy?.filter(
          (deployableToSearch) => deployableToSearch.id != deployable.id
        )
      : deployablesToCopy?.concat([deployable]);
    setDeployablesToCopy(newDeployablesToCopy);
  };

  const handleItemClicked = (system) => {
    const systemAlreadySelected = selectedSystemsToCopy.includes(system);
    systemAlreadySelected
      ? setSelectedSystems(
          selectedSystemsToCopy.filter(
            (selectedSystem) =>
              system !== selectedSystem &&
              selectedSystem.associatedSystem?.id !== system.id
          )
        )
      : setSelectedSystems(selectedSystemsToCopy.concat([system]));
  };

  useEffect(() => {
    selectedSystemsToCopy.length > 0 && setDisabled(false);
    selectedSystemsToCopy.length === 0 && setDisabled(true);
  }, [selectedSystemsToCopy]);

  useEffect(() => {
    const newSystems = initialSystems.filter(
      (system) =>
        system.displayName.includes(query) ||
        system.displayName.toLowerCase().includes(query) ||
        system.guid.includes(query) ||
        system.email.includes(query)
    );
    setDisplayedSystems(newSystems);
  }, [query, initialSystems]);

  useEffect(() => {
    const newFolders =
      systemDeployablesAppData?.data &&
      systemDeployablesAppData.data.subFolders.filter(
        (folder) =>
          folder.name.includes(queryDeployablesOrFolders) ||
          folder.name.toLowerCase().includes(queryDeployablesOrFolders)
      );
    const newDeployables =
      systemDeployablesAppData?.data &&
      systemDeployablesAppData.data.deployables.filter(
        (deployable) =>
          deployable.name.includes(queryDeployablesOrFolders) ||
          deployable.name.toLowerCase().includes(queryDeployablesOrFolders)
      );
    folderAndDeployables &&
      setFolderAndDeployables({
        ...folderAndDeployables,
        subFolders: newFolders,
        deployables: newDeployables,
      });
  }, [queryDeployablesOrFolders]);

  const deployableIcon = (deployable) => {
    const iconName = DeployablesTypesAppData.data.find(
      (deployableImage) =>
        deployable.displayType === deployableImage.displayType
    )?.iconComponent;
    const DynamicIcon = iconName
      ? icons[iconName]
      : icons['DeployablesWalkThru'];
    return <DynamicIcon />;
  };

  return (
    <div style={{ margin: '32px' }}>
      <div
        style={{
          background: 'white',
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'row',
          minHeight: '530px',
        }}
      >
        <div style={{ width: '30%' }}>
          <div style={{ marginLeft: '32px', marginTop: '24px' }}>
            <label
              style={{
                fontFamily: 'Proxima Nova',
                fontWeight: '600',
                fontSize: '16px',
                color: '#2F426C',
              }}
            >
              What would you like to copy?
            </label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '24px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <RadioGroup value={copyProgressOption} onChange={handleChange}>
                  <StyledFormControlLabel
                    value="systems"
                    control={<WMRadio ds2 style={{ marginTop: '2px' }} />}
                    label={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '10px',
                        }}
                      >
                        <StyledLabelOption> Systems</StyledLabelOption>
                        <StyledLabelOptionContent>
                          Single system, multiple systems, or an entire account
                          (all systems)
                        </StyledLabelOptionContent>
                      </div>
                    }
                  />
                  <StyledFormControlLabel
                    value="contentItems"
                    control={<WMRadio ds2 style={{ marginTop: '2px' }} />}
                    label={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '10px',
                        }}
                      >
                        <StyledLabelOption>Content items</StyledLabelOption>
                        <StyledLabelOptionContent>
                          Specific items like a Smart Walk-Thru, Launcher, or
                          SmartTip Set
                        </StyledLabelOptionContent>
                      </div>
                    }
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
        <WMDivider orientation="vertical" flexItem />
        <div style={{ width: '70%' }}>
          {copyProgressOption === 'systems' &&
            !systemDeployablesAppData.loading && (
              <div style={{ margin: '24px 32px 0' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                  }}
                >
                  <label
                    style={{
                      fontFamily: 'Proxima Nova',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#2F426C',
                    }}
                  >
                    Select systems
                  </label>
                  <Checkbox
                    label="Select all"
                    size={CheckboxSizes.Medium}
                    checked={selectedAll}
                    disabled={displayedSystems.length != webSystems.length}
                    indeterminate={
                      !selectedAll && selectedSystemsToCopy.length > 0
                    }
                    onChange={() => {
                      selectedAll
                        ? setSelectedSystems([])
                        : setSelectedSystems(displayedSystems);
                    }}
                  />
                </div>
                <InputSearch
                  placeholder="Search for the system name or system snippet email"
                  onChange={(e) => setQuery(e)}
                />
                <div
                  style={{
                    marginTop: '24px',
                    height: '400px',
                    overflow: 'auto',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '16px',
                    }}
                  >
                    {displayedSystems.map((system) => {
                      return (
                        <div
                          className={
                            selectedSystemsToCopy.find(
                              (selctedSystemItem) =>
                                system === selctedSystemItem
                            )
                              ? classes.selectedSystem
                              : classes.notSelectedSystem
                          }
                          onClick={(item) => handleItemClicked(system)}
                        >
                          <div
                            style={{ marginRight: '12px', marginLeft: '24px' }}
                          >
                            <div
                              style={{
                                background: '#F0F3F9',
                                borderRadius: '8px',
                                width: '36px',
                                height: '36px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                src={`${ADMIN_SPACE_BASE_URL}/assets/icons/icon-${system.name}.png`}
                                width="20px"
                                height="20px"
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              overflow: 'hidden',
                              width: '50%',
                            }}
                          >
                            <SystemNameLabel>
                              {system.displayName}
                            </SystemNameLabel>
                            <Tooltip
                              title={'Guid: ' + system.guid}
                              children={
                                <SystemSnippetEmailLabel>
                                  {system.guid}
                                </SystemSnippetEmailLabel>
                              }
                            />
                            <SystemSnippetEmailLabel>
                              {system.systemTypeKey}
                            </SystemSnippetEmailLabel>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          {copyProgressOption === 'contentItems' &&
            !systemDeployablesAppData.loading && (
              <div style={{ margin: '24px 32px 0' }}>
                {selectedSystemsToCopy.length === 0 && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '24px',
                      }}
                    >
                      <label
                        style={{
                          fontFamily: 'Proxima Nova',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#2F426C',
                        }}
                      >
                        Select source system
                      </label>
                    </div>
                    <InputSearch
                      placeholder="Search for the system name or system snippet email"
                      onChange={(e) => setQuery(e)}
                    />
                    <div
                      style={{
                        marginTop: '24px',
                        height: '400px',
                        overflow: 'auto',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '16px',
                        }}
                      >
                        {displayedSystems.map((system) => {
                          return (
                            <div
                              className={
                                selectedSystemsToCopy.find(
                                  (selctedSystemItem) =>
                                    system === selctedSystemItem
                                )
                                  ? classes.selectedSystem
                                  : classes.notSelectedSystem
                              }
                              onClick={(item) => handleItemClicked(system)}
                            >
                              <div
                                style={{
                                  marginRight: '12px',
                                  marginLeft: '24px',
                                }}
                              >
                                <div
                                  style={{
                                    background: '#F0F3F9',
                                    borderRadius: '8px',
                                    width: '36px',
                                    height: '36px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <img
                                    src={`${ADMIN_SPACE_BASE_URL}/assets/icons/icon-${system.name}.png`}
                                    width="20px"
                                    height="20px"
                                  />
                                </div>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  overflow: 'hidden',
                                  width: '50%',
                                }}
                              >
                                <SystemNameLabel>
                                  {system.displayName}
                                </SystemNameLabel>
                                <Tooltip
                                  title={'Guid: ' + system.guid}
                                  children={
                                    <SystemSnippetEmailLabel>
                                      {system.guid}
                                    </SystemSnippetEmailLabel>
                                  }
                                />
                                <SystemSnippetEmailLabel>
                                  {system.systemTypeKey}
                                </SystemSnippetEmailLabel>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
                {selectedSystemsToCopy.length > 0 && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <ArrowLeft
                          style={{ cursor: 'pointer' }}
                          onClick={() => setSelectedSystems([])}
                        />
                        <img
                          src={`${ADMIN_SPACE_BASE_URL}/assets/icons/icon-${selectedSystemsToCopy[0].name}.png`}
                          width="20px"
                          height="20px"
                        />
                        <label
                          style={{
                            fontFamily: 'Proxima Nova',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '24px',
                          }}
                        >
                          {selectedSystemsToCopy[0].displayName}
                        </label>
                        <label
                          style={{
                            color: 'darkgray',
                            fontFamily: 'Proxima Nova',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            fontSize: '14px',
                            lineHeight: '24px',
                          }}
                        >{`(${selectedSystemsToCopy[0].systemTypeKey})`}</label>
                      </div>
                      <div style={{ width: '300px' }}>
                        <InputSearch
                          placeholder="Search for deployable nameִ"
                          onChange={(e) => setQueryDeployablesOrFolders(e)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: '24px',
                        height: '400px',
                        overflow: 'auto',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          height: '40px',
                          alignItems: 'center',
                          gap: '10px',
                          boxShadow: 'inset 0px -1px 0px #f5f6f8',
                        }}
                      >
                        <Checkbox
                          indeterminate={
                            !selectedAllDeployables &&
                            deployablesToCopy?.length > 0
                          }
                          onChange={
                            () =>
                              setSelectedAllDeployables(!selectedAllDeployables)
                            // selectedAllDeployables
                            //   ? setDeployablesToCopy([])
                            //   : setDeployablesToCopy(
                            //       folderAndDeployables.deployables
                            //     )
                          }
                          checked={selectedAllDeployables}
                        />
                        <Home
                          style={{ cursor: 'pointer' }}
                          onClick={() => setCopySpecificFolderId(null)}
                        />
                        <label
                          style={{
                            fontFamily: 'Proxima Nova',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '16px',
                          }}
                        >
                          {folderAndDeployables &&
                          folderAndDeployables.parentId ? (
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <ArrowRight />
                              <label style={{ color: '#385FEB' }}>
                                {folderAndDeployables.name}
                              </label>
                            </div>
                          ) : (
                            <label style={{ color: '#8D97AE' }}>Home</label>
                          )}
                        </label>
                      </div>
                      {folderAndDeployables &&
                        folderAndDeployables.subFolders.map((subFolders) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                height: '40px',
                                alignItems: 'center',
                                gap: '10px',
                                boxShadow: 'inset 0px -1px 0px #f5f6f8',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                setCopySpecificFolderId(subFolders.id)
                              }
                            >
                              <Folder style={{ fill: '#6D81A6' }} />
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <label
                                  style={{
                                    fontFamily: 'Proxima Nova',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    color: ' #2F426C',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {subFolders.name}
                                </label>
                                <label
                                  style={{
                                    fontFamily: 'Proxima Nova',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    color: '#8D97AE',
                                  }}
                                >
                                  {subFolders.deployableAmount}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      {folderAndDeployables &&
                        folderAndDeployables.deployables &&
                        folderAndDeployables.deployables.map((deployable) => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                height: '40px',
                                alignItems: 'center',
                                gap: '10px',
                                boxShadow: 'inset 0px -1px 0px #f5f6f8',
                              }}
                            >
                              <Checkbox
                                onChange={() =>
                                  handleDeployableSelection(deployable)
                                }
                                checked={
                                  deployablesToCopy?.includes(deployable) ||
                                  selectedAllDeployables
                                }
                                disabled={selectedAllDeployables}
                              />
                              {deployableIcon(deployable)}
                              <label
                                style={{
                                  fontFamily: 'Proxima Nova',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  color: '#2F426C',
                                }}
                              >
                                {deployable.name}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              </div>
            )}
          {copyProgressOption !== 'systems' &&
            copyProgressOption !== 'contentItems' &&
            !systemDeployablesAppData.loading && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '500px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src="/assets/folder-women.svg"
                  width={'150px'}
                  height={'150px'}
                />
                <span
                  style={{
                    marginTop: '50px',
                    fontFamily: 'Proxima Nova',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#2F426C',
                  }}
                >
                  Select a system/s or content Items from the menu
                </span>
              </div>
            )}

          {systemDeployablesAppData.loading && (
            <div
              style={{
                background: 'white',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '530px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CopyAccountWizardPage1;
