import { Box, Button, Divider, IconButton, Tab, Tabs } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  WMButton,
  WMButtonVariant,
  WMIconSettings,
  WMSelect,
  WMTextField,
} from '@walkme/wm-ui';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  EntityFeatureFlag,
  FeatureFlag,
  useGetFeaturesTypes,
} from '@wm-accounts-backoffice-center/state-management-features';
import { FeatureFlagType } from '@wm-accounts-backoffice-center/wm-api';
import { RuntimeFeatureFlagsConfiguration } from '@wm-accounts-backoffice-center/runtime-feature-flags-configuration';

export interface DetailedFFProps {
  entityAssignedToFeature?: EntityFeatureFlag;
  selectedFF: FeatureFlag;
  featureFlagTypes: FeatureFlagType[];
  exit: () => void;
  onDelete: (ffToDelete: string[]) => void;
  onEdit: (
    featureFlagName: string,
    description: string,
    featureType: string
  ) => void;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: '#2F426C',
    letterSpacing: '0em',
    marginLeft: '32px',
  },
  tabBoxes: { marginBottom: '24px' },
  indicator: { backgroundColor: '#3B61EB' },
  tab: {
    minWidth: '140px',
    textTransform: 'none',
    fontFamily: 'Proxima Nova',
    fontWeight: 400,
    fontSize: '14px',
    color: '#2F426C',
  },
  headerDiv: {
    margin: '32px 0px',
  },
  titleSection: {
    padding: '0 0 10px',
    fontFamily: 'ProximaNova',
    fontSize: '12px',
    color: '#2F426C',
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '16px',
  },
  select: {
    '& .css-10szhiz-control': {
      backgroundColor: 'var(--wmBackground)',
    },
  },
  titleOverview: {
    padding: '0 0 10px',
    fontFamily: 'ProximaNova',
    fontSize: '12px',
    color: '#2F426C',
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '16px',
  },
  titleDetail: {
    padding: '0 0 10px',
    fontFamily: 'ProximaNova',
    fontSize: '12px',
    color: '#2F426C',
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '16px',
  },
  bottomDiv: {
    marginBottom: '32px',
    position: 'fixed',
    bottom: '0px',
    width: '416px',
  },
  styleDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonDelete: {
    color: '#EB3232',
    border: '1px solid #EB3232',
    borderRadius: '40px',
    fontFamily: 'ProximaNova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'none',
  },
  buttonCreate: {
    color: 'White',
    border: '1px solid #3B61EB',
    borderRadius: '40px',
    fontFamily: 'ProximaNova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'none',
    background: '#3B61EB',
  },
  textField: {
    '& .WMTextField-input': {
      backgroundColor: '#F5F6F8',
      cursor: 'default',
    },
  },
}));

const DetailedFF = ({
  entityAssignedToFeature,
  selectedFF,
  featureFlagTypes,
  exit,
  onDelete,
  onEdit,
}: DetailedFFProps) => {
  const isDisabled = entityAssignedToFeature ? true : false;
  const classes = useStyles();
  const [selectedFFDesc, setSelectedFFDesc] = useState(
    selectedFF.description ? selectedFF.description.description : ''
  );
  const featuresOptionsRows = featureFlagTypes.map((featureType) => ({
    value: featureType.value,
    label: featureType.displayName,
  }));
  const originalSelectedType =
    featuresOptionsRows.find((typeRow) => typeRow.value === selectedFF.type) ||
    (featuresOptionsRows.length > 0 ? featuresOptionsRows[0] : null);
  const [selectedFeatureTypeRow, setSelectedFeatureTypeRow] =
    useState(originalSelectedType);
  const onChangeFeatureType = (newFeatureType: {
    value: string;
    label: string;
  }): void => {
    setSelectedFeatureTypeRow(newFeatureType);
  };

  const [activeTab, setActiveTab] = useState(0);

  // show entity feature state
  const addedBy = entityAssignedToFeature
    ? entityAssignedToFeature.activatedByUserEmail
    : null;
  const addedAt = entityAssignedToFeature
    ? entityAssignedToFeature.createdAt
    : null;

  // show feature state
  const modifiedBy = !entityAssignedToFeature
    ? selectedFF.description && selectedFF.description.email
    : null;

  const modifiedAt = !entityAssignedToFeature
    ? selectedFF.description && selectedFF.description.updatedAt
    : null;

  const deleteFF = () => {
    onDelete([selectedFF.flagName]);
  };

  const dataChanged = () => {
    const origDescriptionText = selectedFF.description
      ? selectedFF.description.description
      : '';
    const descriptionChanged = origDescriptionText != selectedFFDesc;
    const typeChanged =
      selectedFF.type !=
      (selectedFeatureTypeRow && selectedFeatureTypeRow.value);

    const dataChanged = descriptionChanged || typeChanged;

    return !dataChanged;
  };

  const saveFeatureData = async () => {
    onEdit &&
      onEdit(
        selectedFF.flagName,
        selectedFFDesc,
        selectedFeatureTypeRow && selectedFeatureTypeRow.value
      );
  };

  const onTabChange = (event: ChangeEvent, newTabId: number): void => {
    setActiveTab(newTabId);
  };

  const detailsTab = useCallback(() => {
    return (
      <>
        <div className={classes.headerDiv}>
          <label className={classes.titleSection}>Name</label>
          <WMTextField
            placeholder="Type feature name"
            className={classes.textField}
            value={selectedFF.flagName}
            inputProps={{ readOnly: true, fontfamiliy: 'proximaNova' }}
          />
        </div>

        <div className={classes.headerDiv}>
          <label className={classes.titleSection}>Description</label>
          <WMTextField
            inputProps={{ readOnly: isDisabled, fontfamiliy: 'proximaNova' }}
            className={classes.textField}
            placeholder={isDisabled ? 'N/A' : 'Type feature Description'}
            value={
              selectedFF.description ? selectedFF.description.description : ''
            }
            onChange={(e) => setSelectedFFDesc(e.target.value)}
          />
        </div>

        <div className={classes.headerDiv}>
          <label className={classes.titleSection}>Feature Type</label>
          <WMSelect
            className={classes.select}
            options={featuresOptionsRows}
            isDisabled={true}
            onChange={onChangeFeatureType}
            value={selectedFeatureTypeRow}
          />
        </div>

        <Divider style={{ margin: '12px 0px 12px 0px', color: '#E1E9F5' }} />
        <div className={classes.headerDiv}>
          <label className={classes.titleOverview}>Overview</label>
        </div>
        <div className={classes.headerDiv}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {addedBy && (
              <Box display="flex" flexDirection="column">
                <label className={classes.titleDetail}>Added By</label>
                <label className={classes.titleOverview}>{addedBy}</label>
              </Box>
            )}
            {addedBy && (
              <Box display="flex" flexDirection="column">
                <label className={classes.titleDetail}>Added on</label>
                {addedAt && (
                  <label className={classes.titleOverview}>
                    {moment(addedAt).format('MMM Do YYYY')}
                  </label>
                )}
              </Box>
            )}
            {modifiedBy && (
              <Box
                display="flex"
                flexDirection="column"
                style={{ marginTop: '16px' }}
              >
                <label className={classes.titleDetail}>Modified By</label>
                <label className={classes.titleOverview}>{modifiedBy}</label>
              </Box>
            )}
            {modifiedBy && (
              <Box display="flex" flexDirection="column">
                <label className={classes.titleDetail}>Last Modified</label>
                {modifiedAt && (
                  <label className={classes.titleOverview}>
                    {moment(modifiedAt).format('MMM Do YYYY')}
                  </label>
                )}
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginTop: '16px' }}
          >
            <label className={classes.titleDetail}>Modified By</label>
            <label className={classes.titleOverview}>
              {selectedFF.description ? selectedFF.description.email : ''}
            </label>
          </Box>
        </div>

        <div className={classes.bottomDiv}>
          <div className={classes.styleDiv}>
            <WMButton
              variant={WMButtonVariant.Secondary}
              type="button"
              style={{ color: 'red', border: '1px solid red' }}
              disabled={false}
              onClick={deleteFF}
            >
              {entityAssignedToFeature
                ? 'Remove Feature From Entity'
                : 'Delete Feature'}
            </WMButton>
            {!isDisabled && (
              <WMButton
                variant={WMButtonVariant.Primary}
                type="button"
                disabled={dataChanged()}
                onClick={saveFeatureData}
              >
                {'Save'}
              </WMButton>
            )}
          </div>
        </div>
      </>
    );
  }, []);

  const runtimeFeatureFlagsConfigurationTab = useCallback(() => {
    return (
      <>
        <div className={classes.headerDiv}>
          <RuntimeFeatureFlagsConfiguration
            selectedFF={selectedFF}
            entityAssignedToFeature={entityAssignedToFeature}
          />
        </div>
      </>
    );
  }, []);

  const tabOptions = () => {
    const options =
      entityAssignedToFeature && selectedFF.type === 'SystemRuntime'
        ? [tabsEnvironments]
        : [tabsDetails];
    return options;
  };

  const tabsDetails = {
    label: 'Details',
    element: detailsTab(),
    id: 1,
    disabled: entityAssignedToFeature && selectedFF.type === 'SystemRuntime',
  };
  const tabsEnvironments = {
    label: 'Environments',
    element: runtimeFeatureFlagsConfigurationTab(),
    id: 2,
    disabled: selectedFF.type !== 'SystemRuntime',
  };

  return (
    <div style={{ width: '480px' }}>
      <div
        style={{
          position: 'sticky',
          top: '0',
          backgroundColor: 'white',
          paddingTop: '20px',
        }}
      >
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="row"
            flexGrow={1}
            alignItems="center"
          >
            <label className={classes.title}>{selectedFF.flagName}</label>
          </Box>
          <Box display="flex" flexDirection="row">
            <IconButton style={{ marginRight: '32px' }} onClick={exit}>
              <img src="assets/icons/close.svg" alt="close" />
            </IconButton>
          </Box>
        </Box>
        <Divider style={{ margin: '12px 0px 12px 0px', color: '#E1E9F5' }} />
      </div>
      <Box
        className={classes.tabBoxes}
        style={{ marginLeft: '30px', marginRight: '30px' }}
      >
        <Tabs
          value={activeTab}
          classes={{
            indicator: classes.indicator,
          }}
          onChange={onTabChange}
        >
          {tabOptions().map((tab) => {
            return (
              <Tab
                key={tab.id}
                className={classes.tab}
                label={tab.label}
                disabled={tab.disabled}
              />
            );
          })}
        </Tabs>
        <div>{tabOptions()[activeTab].element}</div>
      </Box>
    </div>
  );
};

export default DetailedFF;
