import * as React from 'react';
import { SVGProps } from 'react';

const SvgOnbordingTask = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1} y={3.5} width={15} height={8} rx={1.5} stroke="#162A69" />
    <rect
      x={8.75}
      y={7.25}
      width={4.5}
      height={0.5}
      rx={0.25}
      stroke="#162A69"
      strokeWidth={0.5}
    />
    <rect
      x={2.75}
      y={14.25}
      width={11.5}
      height={0.5}
      rx={0.25}
      stroke="#162A69"
      strokeWidth={0.5}
    />
    <rect
      x={2.75}
      y={0.25}
      width={11.5}
      height={0.5}
      rx={0.25}
      stroke="#162A69"
      strokeWidth={0.5}
    />
    <path
      d="m7 6.5-2 2-1-1"
      stroke="#162A69"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgOnbordingTask;
