import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, MergeAccountState } from '../../../../merge-account.store';

import { userExcluded, userIncluded } from '../../../../merge-data.slice';
import { User } from 'wm-accounts-sdk';
import { userCanProceed } from '../../merge-account-wizard.slice';

export interface ExcludeUsersState {
  query: string;
  filteredUsers: User[];
}

export const initialExcludeUsersState: ExcludeUsersState = {
  query: '',
  filteredUsers: [],
};

const excludeUsersSlice = createSlice({
  name: 'excludeUsers',
  initialState: initialExcludeUsersState,
  reducers: {
    resetExcludeUsers: (state: ExcludeUsersState) => {
      state.query = '';
      state.filteredUsers = [];
    },
    queryValueUpdted: (
      state: ExcludeUsersState,
      action: PayloadAction<string>
    ) => {
      state.query = action.payload;
    },
    usersFiltered: (
      state: ExcludeUsersState,
      action: PayloadAction<User[]>
    ) => {
      state.filteredUsers = [...action.payload] // cannot change readnonly action.payload
        .sort((u1, u2) => (u1.email > u2.email ? 1 : -1));
    },
  },
});

export const { resetExcludeUsers, usersFiltered, queryValueUpdted } =
  excludeUsersSlice.actions;

const filterUsersByQuery = (usersToFilter: User[], query: string): User[] => {
  // user might type both first and last name of the user, so we need to filter by both
  let filteredUsersByName = [];
  const queryItems = query
    .split(' ')
    .map((item) => item.trim())
    .filter((item) => item !== '');
  queryItems.forEach((queryItem) => {
    const usersByFirstName = usersToFilter.filter((user) => {
      user.firstName.toLowerCase().includes(queryItem.toLowerCase());
    });
    const usersByLastName = usersToFilter.filter((user) => {
      user.lastName.toLowerCase().includes(queryItem.toLowerCase());
    });
    filteredUsersByName = filteredUsersByName
      .concat(usersByFirstName)
      .concat(usersByLastName);
  });

  const filteredUsersByEmail = usersToFilter.filter((user) => {
    return user.email.toLowerCase().includes(query.toLowerCase());
  });

  // using Set to make sure each user appears in the result only once
  const filteredUsers = [
    ...new Set([...filteredUsersByName, ...filteredUsersByEmail]),
  ];
  return filteredUsers;
};

export const stepLoaded = (): AppThunk => async (dispatch, getState) => {
  dispatch(userCanProceed());
};

export const accountUsersUpdated =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState() as MergeAccountState;
    const sourceAccountUsers = state.mergeData?.sourceAccount?.users || [];
    dispatch(queryValueUpdted(''));
    dispatch(usersFiltered(sourceAccountUsers));
  };

export const usersQueryChanged =
  (query: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState() as MergeAccountState;
    const sourceAccountUsers = state.mergeData?.sourceAccount?.users || [];

    query = query?.trim() || '';
    dispatch(queryValueUpdted(query));
    let filteredUsers;
    if (query === '') {
      filteredUsers = [...sourceAccountUsers];
      dispatch(usersFiltered(sourceAccountUsers));
    } else {
      const filterdUsers = filterUsersByQuery(sourceAccountUsers, query);
      dispatch(usersFiltered(filterdUsers));
    }
  };

export const userClicked =
  (userId: number): AppThunk =>
  async (dispatch, getState) => {
    const state = getState() as MergeAccountState;
    const excludedUserIds = state.mergeData.excludedUserIds || [];
    if (excludedUserIds.indexOf(userId) === -1) {
      dispatch(userExcluded(userId));
    } else {
      dispatch(userIncluded(userId));
    }
  };

export default excludeUsersSlice.reducer;
