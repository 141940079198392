import {
  Divider,
  FormControlLabel,
  makeStyles,
  RadioGroup,
} from '@material-ui/core';
import { WMDivider, WMRadio } from '@walkme/wm-ui';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, CheckboxSizes, Tooltip } from '@walkme/ui-core';
import { getAdminSpaceBaseURL } from 'libs/wm-api/src/lib/utils';

const StyledLabelOption = styled('div')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
  margin-bottom: 10px;
`;

const StyledLabel = styled('div')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #637191;
  margin-bottom: 20px;
`;

const SystemNameLabel = styled('label')`
  color: #2f426c;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
const SystemSnippetEmailLabel = styled('label')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8d97ae;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
  margin-left: 0;
`;

const useStyles = makeStyles((theme) => ({
  titleLabel: {
    fontFamily: 'Proxima Nova',
    fontDtyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '45px',
    color: '#2F426C',
  },
  selectedSystem: {
    border: '1px solid #3B61EB',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
  },
  notSelectedSystem: {
    border: '1px solid #e6ecf8',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(212, 223, 245, 0.5)',
    },
  },
}));

export interface CopyAccountWizardPage1_5Props {
  account: any;
  selectedSystemsToCopy: any;
  setDisabled: (value: boolean) => void;
  setSelectedSystems: (selectedSystems) => void;
}
const CopyAccountWizardPage1_5 = ({
  account,
  selectedSystemsToCopy,
  setDisabled,
  setSelectedSystems,
}: CopyAccountWizardPage1_5Props) => {
  const ADMIN_SPACE_BASE_URL = getAdminSpaceBaseURL();

  const platformSystems =
    account?.systems?.filter(
      (s) =>
        !!s.associatedSystem &&
        (!s.statusDetails || s.statusDetails.status === 'enabled')
    ) || [];
  const selectedSystemsWithPlatformSystems = selectedSystemsToCopy.filter(
    (s) => !!platformSystems.find((ps) => ps.associatedSystem.id === s.id)
  );

  const classes = useStyles();

  const handleSystemCheckboxChange = (system) => {
    const systemAlreadySelected = !!selectedSystemsToCopy.find(
      (ps) => ps.associatedSystem?.id === system.id
    );
    systemAlreadySelected
      ? setSelectedSystems(
          selectedSystemsToCopy.filter(
            (ps) => ps.associatedSystem?.id !== system.id
          )
        )
      : setSelectedSystems(
          selectedSystemsToCopy.concat(
            platformSystems.filter((ps) => ps.associatedSystem.id === system.id)
          )
        );
  };

  useEffect(() => {
    setDisabled(false);
  }, []);

  return (
    <div style={{ margin: '32px' }}>
      <div
        style={{
          background: 'white',
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'row',
          minHeight: '530px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 32px 0',
          }}
        >
          <label className={classes.titleLabel}>Include Platform Systems</label>
          {selectedSystemsWithPlatformSystems.length ? (
            <StyledLabel>
              Some of the selected systems have associated platform systems (e.g. Mobile Web etc.).
              Select which platform systems to include in your export.
            </StyledLabel>
          ) : (
            <StyledLabel>
              None of the selected systems have associated platform systems.
            </StyledLabel>
          )}
          <div
            style={{
              marginTop: '30px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '16px',
            }}
          >
            {selectedSystemsWithPlatformSystems.map((system) => {
              const appTypes = platformSystems
                .filter((ps) => ps.associatedSystem.id === system.id)
                .map((ps) => ps.systemTypeKey);

              return (
                <div
                  className={
                    !!selectedSystemsToCopy.find(
                      (ps) => ps.associatedSystem?.id === system.id
                    )
                      ? classes.selectedSystem
                      : classes.notSelectedSystem
                  }
                  onClick={(item) => handleSystemCheckboxChange(system)}
                >
                  <div style={{ marginRight: '12px', marginLeft: '24px' }}>
                    <div
                      style={{
                        background: '#F0F3F9',
                        borderRadius: '8px',
                        width: '36px',
                        height: '36px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={`${ADMIN_SPACE_BASE_URL}/assets/icons/icon-${system.name}.png`}
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                      width: '50%',
                    }}
                  >
                    <SystemNameLabel>{system.displayName}</SystemNameLabel>
                    <Tooltip
                      title={'Guid: ' + system.guid}
                      children={
                        <SystemSnippetEmailLabel>
                          {system.guid}
                        </SystemSnippetEmailLabel>
                      }
                    />
                    <SystemSnippetEmailLabel>
                      {`(${appTypes.join(', ')})`}
                    </SystemSnippetEmailLabel>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyAccountWizardPage1_5;
