import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import {
  WMButtonVariant,
  WMDataGrid,
  WMDialog,
  WMDivider,
  WMIconDelete,
  WMIconWarning,
  WMSnackbar,
  WMSnackbarVariant,
  WMTextField,
} from '@walkme/wm-ui';
import { ColDef } from '@ag-grid-community/core';
import {
  AccountState,
  useLoggedInUser,
} from '@wm-accounts-backoffice-center/state-management-users';
import moment from 'moment';
import { datadogLogs } from '@datadog/browser-logs';
import debounce from 'lodash/debounce';
import {
  accountsSubscriptionApi,
  SystemWithStatus,
} from '@wm-accounts-backoffice-center/wm-api';
import { Card, Title } from '../backoffice-admins-styles';
import { getSystemsRows } from './systems-deletion-center';
import { useSystemDeletionCenterStyles } from './styles';

export interface ReadyForDeletionProps {
  pageData: {
    dueForDeletionSystems: SystemWithStatus[];
    headCells: ColDef[];
    setDueForDeletionSystems: (systems: SystemWithStatus[]) => void;
  };
  loadingData: boolean;
}
const DEBOUNCE_TIME = 300;

export function ReadyForDeletion({
  pageData,
  loadingData,
}: ReadyForDeletionProps) {
  let { dueForDeletionSystems, headCells, setDueForDeletionSystems } = pageData;

  const classes = useSystemDeletionCenterStyles();
  const [systemsToDelete, setsystemsToDelete] = useState<SystemWithStatus[]>(
    []
  );
  const [softDeletedSystems, setSoftDeletedSystems] = useState<
    SystemWithStatus[]
  >([]);
  const [showDeleteSystemsDialog, setShowDeleteSystemsDialog] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingStatusChange, setloadingStatusChange] =
    useState<boolean>(false);
  const [isDeleteEnabled, setIsDeleteEnabled] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState<string>('');
  const [successSnackbarMessage, setSuccessSnackbarMessage] =
    useState<string>('');
  const masterPasswordRef = useRef('');
  const [clearRowSelection, setClearRowSelection] = useState<boolean>(false);

  const { loggedInUserAppData } = useLoggedInUser();

  const LOGGED_IN_USER_EMAIL = useMemo(
    () => loggedInUserAppData.data?.email,
    [loggedInUserAppData]
  );

  const footerActionsSoft = [
    {
      onClick: (rows) => {
        handleSystemsDeletionClick(rows);
      },
      icon: <WMIconDelete />,
      title: 'Move systems to hard delete',
      className: classes.deleteButton,
    },
  ];

  const footerActionsDue = [
    {
      onClick: (rows) => {
        handleSystemsDeletionClick(rows);
      },
      icon: <WMIconDelete />,
      title: 'Delete Systems',
      className: classes.deleteButton,
    },
  ];

  const handleSystemsDeletionClick = (rowsToDelete) => {
    setsystemsToDelete(rowsToDelete);
    setShowDeleteSystemsDialog(true);
  };

  const handleDeletion = async () => {
    setloadingStatusChange(true);
    try {
      const systemsGuids = systemsToDelete.map((system) => system.systemGuid);
      const response =
        await accountsSubscriptionApi.startSystemsSchedulerBySystemsGuids(
          systemsGuids,
          masterPasswordRef.current
        );
      datadogLogs.logger.log(
        `Systems hard deletion was initiated by ${LOGGED_IN_USER_EMAIL}`,
        {
          status: 'warning',
          triggerdBy: LOGGED_IN_USER_EMAIL,
          notifySecurityTeam: true,
          eventName: 'SYSTEMS_HARD_DELETION',
          systemsGuids: systemsGuids,
          amount: systemsGuids.length,
        }
      );
      setLoading(true);
      const deletedIds = response.systems.map((system) => system.systemId);
      const updatedDueForDeletionSystems = dueForDeletionSystems.filter(
        ({ id }) => !deletedIds.includes(id)
      );
      setDueForDeletionSystems(updatedDueForDeletionSystems);
      setClearRowSelection(true);
      setLoading(false);
    } catch (err) {
      setErrorSnackbarMessage(
        err.message?.includes('403') ? err.message : 'Error deleting systems'
      );
    } finally {
      setShowDeleteSystemsDialog(false);
      setloadingStatusChange(false);
      setClearRowSelection(false);
      setInputValue('');
      masterPasswordRef.current = '';
    }
  };

  const handleInputChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);
      setIsDeleteEnabled(value === LOGGED_IN_USER_EMAIL);
    }, DEBOUNCE_TIME),
    []
  );

  const handleCancelClicked = () => {
    setShowDeleteSystemsDialog(false);
    setInputValue('');
    masterPasswordRef.current = '';
  };

  const triggerDeleteAccountsDialog = useCallback(() => {
    const buttons = [
      {
        children: 'Cancel',
        variant: WMButtonVariant.Text,
        style: { marginRight: '30px', color: '#637191' },
        onClick: () => handleCancelClicked(),
      },
      {
        children: 'Delete',
        onClick: () => handleDeletion(),
        className: classes.deleteItemsButton,
        loading: loadingStatusChange,
        disabled: !isDeleteEnabled,
      },
    ];
    const title =
      systemsToDelete.length === 1
        ? 'Delete System'
        : `Delete ${systemsToDelete.length} Systems`;
    return (
      <WMDialog
        ds2
        disableHeaderDivider={true}
        open={showDeleteSystemsDialog}
        onClose={() => setShowDeleteSystemsDialog(false)}
        title={title}
        buttons={buttons}
      >
        <Typography>
          By clicking 'Delete', you will be adding the selected systems to the
          immediate deletion cycle.
          <br />
          Please be aware that <b>this action is irreversible</b> and once
          completed, the systems cannot be restored.
        </Typography>
        <WMTextField
          label={
            <span style={{ userSelect: 'none' }}>
              Type '{LOGGED_IN_USER_EMAIL}' to enable delete
            </span>
          }
          autoFocus
          autoComplete="off"
          value={inputValue}
          onChange={handleInputChange}
          style={{ marginTop: '26px' }}
        />
        <WMTextField
          label={<span>Type in the master password</span>}
          autoComplete="off"
          value={masterPasswordRef.current}
          onChange={(e) => (masterPasswordRef.current = e.target.value)}
          style={{ marginTop: '5px' }}
        />
        <Box className={classes.warningBox}>
          <WMIconWarning
            color={'#F7B500'}
            size={20}
            style={{ flexShrink: 0, paddingRight: '12px' }}
          />
          Please carefully review all the systems you have selected for
          deletion.
          <br />
        </Box>
      </WMDialog>
    );
  }, [
    showDeleteSystemsDialog,
    systemsToDelete,
    isDeleteEnabled,
    loadingStatusChange,
  ]);

  const handleSnackBarErrorClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setErrorSnackbarMessage('');
    setSuccessSnackbarMessage('');
  };

  return (
    <div>
      {triggerDeleteAccountsDialog()}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      ></div>

      <Box style={{ width: '100%', padding: 0 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={{ flex: 5, padding: '0 10px' }}>
            <Card style={{ marginTop: '10px' }}>
              <div style={{ height: '70vh' }}>
                <WMDataGrid
                  clearRowSelection={clearRowSelection}
                  columnDefs={headCells}
                  rows={getSystemsRows(dueForDeletionSystems)}
                  title={'Ready for deletion systems'}
                  headerDescription={
                    'Systems that have been soft deleted for at least 3 months.'
                  }
                  entityName={'System'}
                  footerActions={footerActionsDue}
                  loadingData={loading}
                />
              </div>
            </Card>
          </div>
        </div>
      </Box>

      <WMSnackbar
        open={!!errorSnackbarMessage || !!successSnackbarMessage}
        onClose={handleSnackBarErrorClose}
        variant={
          errorSnackbarMessage
            ? WMSnackbarVariant.Error
            : WMSnackbarVariant.Success
        }
        message={errorSnackbarMessage || successSnackbarMessage}
      />
    </div>
  );
}

export default ReadyForDeletion;
