import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import SideMenu, { Tab } from '../side-menu/side-menu';
import { AccountsSideMenu } from '@walkme/accounts-side-menu';

export interface SideBarProps {
  tabs: Tab[];
}

export const SideBar = ({ tabs }: SideBarProps) => {
  const { pathname } = useLocation();
  return <AccountsSideMenu tabs={tabs} path={pathname} />;
};

export default SideBar;
