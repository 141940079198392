import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExcludedEnvironmentsState,
  getExcludedEnvironments,
  resetUpdateData,
} from '../redux/excluded-environments.slice';

export const useGetExcludedEnvironments = (
  systemId: number,
  featureFlagName: string
) => {
  const excludedEnvironmentsData = useSelector(
    (state: { excludedEnvironmentsState: ExcludedEnvironmentsState }) =>
      state.excludedEnvironmentsState?.excludedEnvironments
  );

  const excludedEnvironmentsUpdateData = useSelector(
    (state: { excludedEnvironmentsState: ExcludedEnvironmentsState }) =>
      state.excludedEnvironmentsState?.excludedEnvironmentsUpdate
  );

  const resetUpdate = () => {
    dispatch(resetUpdateData());
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getExcludedEnvironments(systemId, featureFlagName));
  }, [dispatch, systemId, featureFlagName]);

  return {
    excludedEnvironmentsData,
    excludedEnvironmentsUpdateData,
    resetUpdate,
  };
};

export default useGetExcludedEnvironments;
