import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeployablesState,
  getSystemDeployables,
} from '../redux/deployables.slice';

export const useSystemDeployables = (
  systemId: number,
  folderId: number,
  forceLoad = false
) => {
  const systemDeployablesAppData = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.systemDeployables
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSystemDeployables(systemId, folderId, forceLoad));
  }, [dispatch, folderId, forceLoad, systemId]);

  return {
    systemDeployablesAppData,
  };
};

export default useSystemDeployables;
