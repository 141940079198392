import { Typography } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  InputVariant,
  Spinner,
  ToasterVariant,
  useToaster,
  IconButton,
  Tooltip,
} from '@walkme/ui-core';
import { WMIconWarning, WMTooltipProps } from '@walkme/wm-ui';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AccountsSdk, User } from 'wm-accounts-sdk';
import styled from 'styled-components';
import { Copy } from '@walkme/ui-icons';

const StyledInput = styled(Input)`
  & input {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
`;

export interface UGenerateUserTempPasswordProps {
  user: User;
  onClose: () => void;
  isOpen: boolean;
}

export function GenerateUserTempPassword({
  user,
  isOpen,
  onClose,
}: UGenerateUserTempPasswordProps) {
  const dispatch = useDispatch();
  const [generatingPassword, setGeneratingPassword] = useState(false);
  const [newGeneratedPassword, setNewGeneratedPassword] = useState('');
  const [clickedCopy, setClickedCopy] = useState(false);

  const { addToaster } = useToaster();

  const onGenerateTempPassword = useCallback(async () => {
    try {
      setGeneratingPassword(true);
      const tempPassResponse =
        await AccountsSdk.getInstance().backofficeUsers.createTempPassword(
          user.email
        );
      setNewGeneratedPassword(tempPassResponse.tempPassword);
      setGeneratingPassword(false);
      // onClose();
    } catch (e) {
      setGeneratingPassword(false);
      addToaster({
        message: `failed to generate temp password for ${user.email}. ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [user, setGeneratingPassword, dispatch, onClose, setNewGeneratedPassword]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogTitle>Generate User new temp password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will generate a new temp password for. <b>{user?.email}</b> copy
          the new password and send it to the right user.
        </DialogContentText>
        {!newGeneratedPassword && (
          <DialogContentText>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 16px',
                marginBottom: '10px',
                marginTop: '10px',
                backgroundColor: '#FEF8E8',
                border: `1px solid #F7B500`,
                borderRadius: '4px',
              }}
            >
              <WMIconWarning
                color={'#F7B500'}
                size={20}
                style={{ flexShrink: 0, paddingRight: '12px' }}
              />
              <Typography>
                Please use this option just in case the reset password mail not
                receiving (if this is a fake email, you should create a new user
                for the customer with a real email address)
              </Typography>
            </div>
          </DialogContentText>
        )}
        {newGeneratedPassword && (
          <div
            style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}
          >
            <StyledInput
              style={{ marginRight: '10px' }}
              disabled
              onChange={function noRefCheck() {}}
              placeholder={newGeneratedPassword}
              variant={InputVariant.primary}
              width={380}
            />
            <div onMouseLeave={() => setClickedCopy(false)}>
              <Tooltip title={clickedCopy ? 'Copied' : 'Copy'} placement="top">
                <IconButton
                  active
                  variant="ghost"
                  onClick={() => {
                    setClickedCopy(true);
                    navigator.clipboard.writeText(newGeneratedPassword);
                  }}
                >
                  <Copy />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {newGeneratedPassword ? 'Close' : 'Cancel'}
        </Button>
        {!newGeneratedPassword && (
          <Button
            disabled={generatingPassword}
            onClick={onGenerateTempPassword}
            variant="solid"
            color="danger"
          >
            {generatingPassword ? <Spinner /> : `Generate`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
export default GenerateUserTempPassword;
