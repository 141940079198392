import React from 'react';
import {
  ExcludedUserUnassignedSystems,
  MergeAccountsActionResult,
  MergeAccountsActionStatus,
} from '../../api/merge-account-api.types';
import {
  BoldLabel,
  SectionWrapperDiv,
  StyledStatus,
  SummaryError,
  SummaryHeader,
  SummaryItem,
  SummarySubHeader,
  SummarySubItem,
} from '../merge-summary.styles';

import { StatusVariant } from '@walkme/ui-core';

export interface UnassignedUsersSummaryProps {
  excludedUsersResult: MergeAccountsActionResult<
    ExcludedUserUnassignedSystems[]
  >;
}

export const UnassignedUsersSummary = ({
  excludedUsersResult,
}: UnassignedUsersSummaryProps) => {
  if (!excludedUsersResult) {
    return;
  }

  const hasError =
    excludedUsersResult.status === MergeAccountsActionStatus.failure;
  const excludedUsers = (!hasError ? excludedUsersResult.value : []) as Partial<
    ExcludedUserUnassignedSystems[]
  >;
  const sortedExcludedUsers = [...excludedUsers].sort((a, b) =>
    a.user.email < b.user.email ? -1 : 1
  );
  const error = excludedUsersResult.value as Error;

  return (
    <SectionWrapperDiv>
      <SummaryHeader>
        <StyledStatus
          variant={!hasError ? StatusVariant.Success : StatusVariant.Error}
        />
        Unassign Excluded Users:{' '}
        {hasError ? 'Failed' : 'Completed Successfully'}
      </SummaryHeader>
      {hasError && <SummaryError>Error: {error.message}</SummaryError>}
      {!hasError && (
        <>
          <SummarySubHeader>Users unassigned:</SummarySubHeader>
          {
            sortedExcludedUsers.length
              ? sortedExcludedUsers.map((excludedUser) => (
                <React.Fragment key={excludedUser.user.guid}>
                  <SummaryItem key={excludedUser.user.guid}>
                    User:&nbsp;<BoldLabel>{excludedUser.user.email}</BoldLabel>
                    ,&nbsp;
                    {excludedUser.unassignedSystems.length
                      ? 'unassigned from:'
                      : 'no systems were assigned to this user.'}
                  </SummaryItem>
                  {[...excludedUser.unassignedSystems]
                    .sort((a, b) => (a.displayName < b.displayName ? -1 : 1))
                    .map((system) => (
                      <SummarySubItem key={system.guid}>
                        {system.displayName}
                      </SummarySubItem>
                    ))}
                </React.Fragment>
              ))
              : <SummaryItem>(None)</SummaryItem>
          }
        </>
      )}
    </SectionWrapperDiv>
  );
};
