import React from 'react';
import {
  ExcludedSystemUnassignedUsers,
  MergeAccountsActionResult,
  MergeAccountsActionStatus,
} from '../../api/merge-account-api.types';
import {
  BoldLabel,
  SectionWrapperDiv,
  StyledStatus,
  SummaryError,
  SummaryHeader,
  SummaryItem,
  SummarySubHeader,
  SummarySubItem,
} from '../merge-summary.styles';

import { StatusVariant } from '@walkme/ui-core';

export interface UnassignedSystemsSummaryProps {
  excludedSystemsResult: MergeAccountsActionResult<
    ExcludedSystemUnassignedUsers[]
  >;
}
export const UnassignedSystemsSummary = ({
  excludedSystemsResult,
}: UnassignedSystemsSummaryProps) => {
  if (!excludedSystemsResult) {
    return;
  }

  const hasError =
    excludedSystemsResult.status === MergeAccountsActionStatus.failure;
  const excludedSystems = (
    !hasError ? excludedSystemsResult.value : []
  ) as ExcludedSystemUnassignedUsers[];
  const sortedExcludedSystems = [...excludedSystems].sort((a, b) =>
    a.system.displayName < b.system.displayName ? -1 : 1
  );
  const error = excludedSystemsResult.value as Error;

  return (
    <SectionWrapperDiv>
      <SummaryHeader>
        <StyledStatus
          variant={!hasError ? StatusVariant.Success : StatusVariant.Error}
        />
        Unassign Excluded Systems:{' '}
        {hasError ? 'Failed' : 'Completed Successfully'}
      </SummaryHeader>
      {hasError && <SummaryError>Error: {error.message}</SummaryError>}
      {!hasError && (
        <>
          <SummarySubHeader>Systems unassigned:</SummarySubHeader>
          {
            sortedExcludedSystems.length
              ? sortedExcludedSystems.map((excludedSystem) => (
                <React.Fragment key={excludedSystem.system.guid}>
                  <SummaryItem key={excludedSystem.system.guid}>
                    System:&nbsp;
                    <BoldLabel>{excludedSystem.system.displayName}</BoldLabel>
                    ,&nbsp;
                    {excludedSystem.unassignedUsers.length
                      ? 'unassigned from:'
                      : 'no users were assigned to this system.'}
                  </SummaryItem>
                  {[...excludedSystem.unassignedUsers]
                    .sort((a, b) => (a.email < b.email ? -1 : 1))
                    .map((user) => (
                      <SummarySubItem key={user.guid}>{user.email}</SummarySubItem>
                    ))}
                </React.Fragment>
              ))
              : <SummaryItem>(None)</SummaryItem>
          }
        </>
      )}
    </SectionWrapperDiv>
  );
};
