import styled from 'styled-components';
import { WMDataGrid } from '@walkme/wm-ui';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const StyledWMDataGrid = styled(WMDataGrid)`
  .WMDataGridHeader-wmDataGridHeader {
    align-items: center;
  }
  .WMSearchFilter-clearButton {
    top: 20%;
  }
`;

export const StyledDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background-color: rgba(47, 66, 108, 0.61);
  }
`;

export const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loader: {
    margin: '0 auto',
    display: 'block',
  },
  deleteItemMenu: {
    '& .WMMenu-label': {
      color: '#EB3232',
    },
  },
  DisableButton: {
    border: '1px solid rgba(235, 50, 50, 0.2)',
    '&:hover': {
      border: '1px solid #EB3232',
    },
    borderRadius: '40px !important',
    color: '#EB3232 !important',
    '& .WMDataGridActionDialog-actionButton': {
      color: '#EB3232',
      padding: '5px 15px',
      '&:hover': {
        color: '#EB3232',
      },
    },
    '& .WMDataGridActionDialog-actionIcon': {
      marginRight: '0px',
    },
  },
  title: {
    marginBottom: '16px !important',
  },
}));
