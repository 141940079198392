import React, { createContext } from 'react';

export const SystemIconGeneratorContext = createContext(null);

export const SystemIconGeneratorProvider: React.FC<{
  iconUrlGenerator: any;
}> = ({ children, iconUrlGenerator }) => {
  return (
    <SystemIconGeneratorContext.Provider value={iconUrlGenerator}>
      {children}
    </SystemIconGeneratorContext.Provider>
  );
};
