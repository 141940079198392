import React, { useCallback, useEffect } from 'react';
import {
  MergeLogContainer,
  StyledLoader,
  StyledWMDataGrid,
} from './merge-log.styles';
import { getAllMergeProcs, mergeProcessClicked } from './merge-log.slice';
import { useDispatch, useSelector } from 'react-redux';
import { MergeAccountState } from '../merge-account.store';
import { gridColumns } from './merge-log.lib';
import { Button, Tooltip } from '@walkme/ui-core';
import { MergeSummaryDrawer } from './drawer/merge-summary-drawer';

export interface MergeLogProps {
  onStartMergeClicked: () => void;
}

export const MergeLog = ({ onStartMergeClicked }: MergeLogProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMergeProcs());
  }, [dispatch]);

  const gridRows = useSelector(
    (state: MergeAccountState) => state.mergeLogs?.gridRows || []
  );

  const isLoading = useSelector(
    (state: MergeAccountState) => state.mergeLogs?.mergeProcs?.loading || false
  );

  const onMergeProcessClicked = useCallback(
    (event) => {
      dispatch(mergeProcessClicked(event.data.id));
    },
    [dispatch]
  );

  const headerActions = [
    <Tooltip title="Click here to start a new Merge Account process">
      <Button variant="solid" color="primary" onClick={onStartMergeClicked}>
        {'Start Merge Accounts'}
      </Button>
    </Tooltip>,
  ];

  if (isLoading) {
    return (
      <MergeLogContainer>
        <StyledLoader />
      </MergeLogContainer>
    );
  }

  return (
    <>
      <MergeLogContainer>
        <StyledWMDataGrid
          title={'Merge Accounts Processes'}
          columnDefs={gridColumns}
          rows={gridRows}
          entityName={'Merge Process'}
          supressResetOnNewData={true}
          hideColumnPicker
          hideExport
          defaultPageSize={25}
          width={880}
          gridConfig={{
            onRowClicked: (event) => onMergeProcessClicked(event),
          }}
          headerActions={headerActions}
        />
      </MergeLogContainer>
      <MergeSummaryDrawer />
    </>
  );
};
