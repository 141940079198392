import styled from "styled-components";
import {Tabs} from "@walkme/ui-core";

export const StyledPageContainer = styled.div`
  height:calc(100% - 56px);
  display: flex;
  flex-direction: column;
`

export const Title = styled('label')`
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #2f426c;
`;

export const SubTitle = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
`;

export const StyledTabs = styled(Tabs)`
    height: calc(100% - 84px);
    [role*='tabpanel'] {
        margin-top: 24px;
        height: calc(100% - 60px);
    }
`;

export const StyledNoPermissions=styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`
