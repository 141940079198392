import React, { useMemo, useState, useEffect, useReducer } from 'react';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogTitle,
  Tooltip,
  Typography,
} from '@walkme/ui-core';
import {
  StyledContentContainer,
  StyledDialog,
  StyledField,
  StyledWMSelect,
  StyledButton,
  StyledDialogContent,
  StyledInput,
  StyledImg,
} from './create-edit-feature-toggle-dialog.styles';
import {
  featureTogglesSlice,
  FeatureTogglesState,
} from '../../../../../state-management-feature-toggles/src';
import { useDispatch, useSelector } from 'react-redux';
import useOwnerTeams from '../../../../../state-management-feature-toggles/src/lib/hooks/use-get-owner-teams';
import { WMSnackbarVariant, WMSwitch } from '@walkme/wm-ui';
import {
  Type,
  Policy,
  createFeatureFlag,
  editFeatureFlag,
} from '../../../../../state-management-feature-toggles/src';
import {
  validateGuids,
  getFFDialogConfirmButtonText,
  getFFDialogSubTitle,
  getFFDialogTitle,
  SET_FIELD,
  initialFeatureFlagState,
  splitOnCapitalAndLowerCase,
  featureFlagStateReducer,
  validateState,
  isFFCreationDialog,
  validationTooltipText,
  isEnabledTooltipText,
  makeFirstCharCapital,
  getEntitiesTooltipText,
  isSyncTooltipText,
} from './create-edit-feature-toggle-dialog.lib';
import { isEqual } from 'lodash';

export interface CreateEditFeatureToggleDialogProps {
  isOpen: boolean;
  dialogType: string;
  data?: any;
  unsetSelectedFeatureFlag: () => void;
}

export const CreateEditFeatureToggleDialog = ({
  isOpen,
  dialogType,
  data,
  unsetSelectedFeatureFlag,
}: CreateEditFeatureToggleDialogProps) => {
  const dispatch = useDispatch();
  const [state, localDispatch] = useReducer(
    featureFlagStateReducer,
    initialFeatureFlagState
  );
  const { name, description, isEnabled, team, type, policy, entities } = state;
  const [isValidateChecked, setValidateChecked] = useState<boolean>(false);
  const [isSyncChecked, setSyncChecked] = useState<boolean>(false);
  const [isConfirmEnabled, setConfirmEnabled] = useState<boolean>(false);
  const createEditFeatureFlagsAppData = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.createEditFeatureFlag
  );

  const ownerTeams = useOwnerTeams();
  const ownerTeamsOptions = useMemo(() => {
    return ownerTeams?.data?.map((team) => {
      return {
        value: team._id,
        label: team.name,
        color: '#5243AA',
      };
    });
  }, [ownerTeams]);

  const types = Object.keys(Type);
  const typesOptions = useMemo(() => {
    return types?.map((type) => {
      const adjustedType = makeFirstCharCapital(type);
      return {
        value: type,
        label: adjustedType,
        color: '#5243AA',
      };
    });
  }, [types]);

  const policies = Object.keys(Policy);
  const policiesOptions = useMemo(() => {
    return policies?.map((policy) => {
      const adjustedPolicy = makeFirstCharCapital(
        splitOnCapitalAndLowerCase(policy)
      );
      return {
        value: policy,
        label: adjustedPolicy,
        color: '#5243AA',
      };
    });
  }, [policies]);

  const setField = (field, value) => {
    localDispatch({ type: SET_FIELD, field, value });
  };

  const setEntitiesField = (value) => {
    localDispatch({
      type: SET_FIELD,
      field: 'entities',
      value: value.length > 0 ? value.split(/[, ]+/) : null,
    });
  };

  const onCancel = () => {
    dispatch(
      featureTogglesSlice.actions.setCreateEditFeatureFlagDialogOpen(false)
    );
    unsetSelectedFeatureFlag();
  };

  const createOnConfirm = () => {
    let targetPopulation;
    if (entities !== null) {
      if (isValidateChecked) {
        if (validateGuids(entities)) {
          targetPopulation = { type, policy, entities };
        } else {
          dispatch(
            featureTogglesSlice.actions.setNotificationMessage({
              text: 'Some of the entities are invalid or may be duplicated. Please check the input and ensure all entities are valid and unique',
              variant: WMSnackbarVariant.Error,
              isOpen: true,
            })
          );
          return;
        }
      } else {
        if (policy === Policy.all) {
          targetPopulation = { type, policy, entities: null };
        } else {
          targetPopulation = { type, policy, entities };
        }
      }
    } else {
      targetPopulation = { type, policy, entities: null };
    }

    const newFeatureFlag = {
      name,
      description,
      ownerTeam: team,
      targetPopulation,
      isEnabled: isEnabled,
    };
    dispatch(
      createFeatureFlag(newFeatureFlag, isValidateChecked, isSyncChecked)
    );
    unsetSelectedFeatureFlag();
  };

  const getFeatureFlagChanges = () => {
    const currentData = {
      name,
      description,
      ownerTeam: team,
      targetPopulation: { type, policy, entities },
      isEnabled: isEnabled,
    };

    const changes = Object.keys(currentData).reduce(
      (result, key) => {
        if (key === 'targetPopulation') {
          const targetPopulation = currentData[key];
          let hasChanges = false;

          for (let tpKey in targetPopulation) {
            if (
              !isEqual(targetPopulation[tpKey], data?.targetPopulation[tpKey])
            ) {
              hasChanges = true;
              if (
                tpKey === 'entities' &&
                targetPopulation[tpKey] !== null &&
                isValidateChecked &&
                !validateGuids(targetPopulation[tpKey])
              ) {
                throw new Error(
                  'Some of the entities are invalid or may be duplicated. Please check the input and ensure all entities are valid and unique'
                );
              }
            }
          }

          if (hasChanges) {
            if (policy === Policy.all) {
              targetPopulation.entities = null;
            }
            result[key] = targetPopulation;
          }
        } else if (!isEqual(currentData[key], data[key])) {
          result[key] = currentData[key];
        }

        return result;
      },
      { name, ownerTeam: team }
    );

    return changes;
  };

  const editOnConfirm = async () => {
    try {
      const featureFlagChanges = getFeatureFlagChanges();
      await dispatch(editFeatureFlag(featureFlagChanges, isValidateChecked));
      unsetSelectedFeatureFlag();
    } catch (err) {
      dispatch(
        featureTogglesSlice.actions.setNotificationMessage({
          text: err.message,
          variant: WMSnackbarVariant.Error,
          isOpen: true,
        })
      );
      return;
    }
  };

  const checkIsConfirmEnabled = () => {
    const argsToValidate: (string | string[])[] = [
      name,
      description,
      team,
      type,
      policy,
    ];
    if (policy !== Policy.all) {
      argsToValidate.push(entities);
    }
    if (isFFCreationDialog(dialogType)) {
      setConfirmEnabled(validateState(argsToValidate));
    } else {
      const isFeatureFlagDataChanged =
        name !== data?.name ||
        description !== data?.description ||
        team !== data?.ownerTeam?._id ||
        type !== data?.targetPopulation?.type ||
        policy !== data?.targetPopulation?.policy ||
        !isEqual(entities, data?.targetPopulation?.entities) ||
        isEnabled !== data?.isEnabled;
      setConfirmEnabled(isFeatureFlagDataChanged);
    }
  };

  const handleCheckboxWhenPolicyIsAll = () => {
    if (isValidTypeForEntitiesValidation) {
      if (policy === Policy.all) {
        setValidateChecked(false);
      } else {
        setValidateChecked(true);
      }
    }
  };

  const isValidTypeForEntitiesValidation = useMemo(() => {
    let isValidType = false;
    switch (type) {
      case 'account':
      case 'system':
      case 'user':
        isValidType = true;
        setValidateChecked(true);
        break;
      case 'extension':
      case 'other':
      default:
        isValidType = false;
        setValidateChecked(false);
        break;
    }
    return isValidType;
  }, [type]);

  const initState = () => {
    if (data) {
      const flattenedData = {
        ...data,
        ...data.targetPopulation,
        team: data.ownerTeam._id,
      };
      delete flattenedData.targetPopulation;
      delete flattenedData.ownerTeam;

      for (let key in flattenedData) {
        setField(key, flattenedData[key]);
      }
    }
  };

  useEffect(handleCheckboxWhenPolicyIsAll, [type, policy]);

  useEffect(checkIsConfirmEnabled, [
    name,
    description,
    team,
    type,
    policy,
    entities,
    isEnabled,
  ]);

  useEffect(initState, [data]);

  return (
    <StyledDialog isOpen={isOpen} onClose={onCancel} preventClose>
      <DialogTitle>
        {getFFDialogTitle(isFFCreationDialog(dialogType))}
      </DialogTitle>
      <StyledDialogContent>
        <StyledContentContainer>
          <div className="sub-title">
            <Typography variant={'T20'}>
              {getFFDialogSubTitle(isFFCreationDialog(dialogType))}
            </Typography>
          </div>
          <StyledField id="name-field">
            <Typography variant={'T20'}>Name</Typography>
            <StyledInput
              id="name-input"
              value={name}
              placeholder={'Enter feature flag name'}
              onChange={(e) => {
                setField('name', e.target.value);
              }}
              disabled={!isFFCreationDialog(dialogType)}
              maxlength={100}
            />
          </StyledField>
          <StyledField id="description-field">
            <Typography variant={'T20'}>Description</Typography>
            <StyledInput
              value={description}
              placeholder={'Enter a brief description of the feature flag'}
              onChange={(e) => {
                setField('description', e.target.value);
              }}
              maxlength={700}
            />
          </StyledField>
          <StyledField>
            <Typography variant={'T20'}>Team</Typography>
            <StyledWMSelect
              value={ownerTeamsOptions.find(
                (ownerTeam) => ownerTeam.value === team
              )}
              placeholder={'Select a team...'}
              options={ownerTeamsOptions}
              onChange={(e) => {
                setField('team', e.value);
              }}
              isDisabled={!isFFCreationDialog(dialogType)}
            />
          </StyledField>
          <StyledField>
            <Typography variant={'T20'}>Type</Typography>
            <StyledWMSelect
              value={typesOptions.find(
                (typeOption) => typeOption.value === type
              )}
              placeholder={'Select a type...'}
              options={typesOptions}
              onChange={(e) => {
                setField('type', e.value);
              }}
            />
          </StyledField>
          <StyledField>
            <Typography variant={'T20'}>Policy</Typography>
            <StyledWMSelect
              value={policiesOptions.find(
                (policyOption) => policyOption.value === policy
              )}
              placeholder={'Select a policy...'}
              options={policiesOptions}
              onChange={(e) => {
                setField('policy', e.value);
              }}
            />
          </StyledField>
          <StyledField id="entities-field">
            <div className="field-title-with-info">
              <Typography variant={'T20'}>Entities</Typography>
              <Tooltip title={getEntitiesTooltipText(policy)}>
                <StyledImg alt={'more'} src={`/assets/icons/icon-info.svg`} />
              </Tooltip>
            </div>
            <StyledInput
              value={policy !== Policy.all ? entities : ''}
              placeholder={
                policy === Policy.all
                  ? 'GUIDs entry disabled due to policy'
                  : 'Enter associated entity GUIDs'
              }
              multiline
              onChange={(e) => {
                setEntitiesField(e.target.value);
              }}
              disabled={policy === Policy.all}
            />
          </StyledField>
          {isValidTypeForEntitiesValidation && (
            <div id="validate-field-container">
              <StyledField id="validate-field">
                <div
                  id="validation-title-with-info"
                  className="field-title-with-info"
                >
                  <Typography variant={'T20'}>
                    Validate GUID Entities?
                  </Typography>
                  <Tooltip title={validationTooltipText}>
                    <StyledImg
                      alt={'more'}
                      src={`/assets/icons/icon-info.svg`}
                    />
                  </Tooltip>
                </div>
                <Checkbox
                  checked={isValidateChecked}
                  onChange={(e) => {
                    setValidateChecked(e.target.checked);
                  }}
                  disabled={policy === Policy.all}
                />
              </StyledField>
            </div>
          )}
          <StyledField>
            <div className="field-title-with-info">
              <Typography variant={'T20'}>Enable</Typography>
              <Tooltip title={isEnabledTooltipText}>
                <StyledImg alt={'more'} src={`/assets/icons/icon-info.svg`} />
              </Tooltip>
            </div>
            <WMSwitch
              ds2
              checked={isEnabled}
              onChange={(e) => {
                setField('isEnabled', e.target.checked);
              }}
              label={<span>{isEnabled ? 'On' : 'Off'}</span>}
            />
          </StyledField>
          {isFFCreationDialog(dialogType) && (
            <StyledField id="sync-field">
              <div className="field-title-with-info">
                <Typography variant={'T20'}>Sync</Typography>
                <Tooltip title={isSyncTooltipText}>
                  <StyledImg alt={'more'} src={`/assets/icons/icon-info.svg`} />
                </Tooltip>
              </div>
              <WMSwitch
                ds2
                checked={isSyncChecked}
                onChange={(e) => {
                  setSyncChecked(e.target.checked);
                }}
                label={<span>{isSyncChecked ? 'On' : 'Off'}</span>}
              />
            </StyledField>
          )}
        </StyledContentContainer>
      </StyledDialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <StyledButton
          onClick={() =>
            isFFCreationDialog(dialogType) ? createOnConfirm() : editOnConfirm()
          }
          loading={createEditFeatureFlagsAppData.loading}
          disabled={!isConfirmEnabled}
        >
          {getFFDialogConfirmButtonText(isFFCreationDialog(dialogType))}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};
