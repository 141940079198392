import { CSSLint } from 'csslint';

export const validJsonFields = [
  'multilinkHelpDesk',
  'deployablesRoles',
  'deployablesAriaLabels',
  'accessibilityMultilang',
  'accessibilityFocusLock',
  'accessibilityHidePopupBackground',
  'surveyAccessibilitySettings',
  'menuAccessibilitySettings',
  'defaultFirstElementForFocus',
  'uuidHash',
];

interface ValidatorResponse {
  isValid: boolean;
  errorMessage: string;
}

interface InvalidCss {
  line: number;
  column: number;
  message: string;
  type: string;
}

export const cssLinter = [];

export const validateJson = (jsonSnippet): ValidatorResponse => {
  try {
    const trimedJsonSnippet = jsonSnippet && jsonSnippet.trim();
    const snippet = trimedJsonSnippet ? jsonSnippet : '{}';
    const parsedJSON = JSON.parse(snippet);
    const unexpectedFields = Object.keys(parsedJSON).filter(
      (field) => !validJsonFields.includes(field)
    );

    if (hasDuplicateKeys(snippet)) {
      return {
        isValid: false,
        errorMessage: 'Duplicate identical keys are not allowed.',
      };
    }
    if (unexpectedFields.length > 0) {
      return {
        isValid: false,
        errorMessage: 'Non-allowed keys cannot be saved as default',
      };
    }
    return {
      isValid: true,
      errorMessage: '',
    };
  } catch (error) {
    return {
      isValid: false,
      errorMessage: 'Not a valid JSON',
    };
  }
};

export const validateCSS = (cssSnippet): ValidatorResponse => {
  if (!cssSnippet)
    return {
      isValid: true,
      errorMessage: '',
    };
  const cssLintRes: any = CSSLint.verify(cssSnippet, getCSSLintRuleSet());
  const parsedResults: InvalidCss[] = parseCSSLintResult(cssLintRes);

  const errors = parsedResults.filter((item) => item.type === 'error');
  const warnings = parsedResults.filter((item) => item.type === 'warning');

  if (errors.length) {
    return {
      isValid: false,
      errorMessage: 'Not a valid CSS',
    };
  }
  return {
    isValid: true,
    errorMessage: '',
  };
};

const parseCSSLintResult = (cssLintRes: any): InvalidCss[] => {
  if (!cssLintRes?.messages?.length) return [];

  const invalidCssList: InvalidCss[] = cssLintRes.messages.map((x) => {
    const res: InvalidCss = {
      line: x.line,
      column: x.col,
      message: x.message,
      type: x.type,
    };
    return res;
  });
  return invalidCssList;
};

const hasDuplicateKeys = (jsonString) => {
  const regex = /"(\w+)"\s*:/g;
  const matches = jsonString.match(regex);

  if (matches) {
    const keys = matches.map((match) => match.trim().replace(/"/g, ''));
    const uniqueKeys = new Set(keys);

    return keys.length !== uniqueKeys.size;
  }

  return false;
};

const getCSSLintRuleSet = () => {
  /* Rules: https://github.com/CSSLint/csslint/wiki/Rules */

  const SHOW: number = 1;
  const HIDE: number = 0;

  const ruleSet = {
    /* Possible Errors */
    'box-model': HIDE,
    'display-property-grouping': SHOW,
    'duplicate-properties': SHOW,
    'empty-rules': HIDE,
    'known-properties': SHOW,
    /* Compatibility */
    'adjoining-classes': HIDE,
    'box-sizing': HIDE,
    'compatible-vendor-prefixes': SHOW,
    gradients: SHOW,
    'text-indent': SHOW,
    'vendor-prefix': SHOW,
    'fallback-colors': HIDE,
    'star-property-hack': SHOW,
    'underscore-property-hack': SHOW,
    'bulletproof-font-face': SHOW,
    /* Performance */
    'font-faces': SHOW,
    import: SHOW,
    'regex-selectors': SHOW,
    'universal-selector': SHOW,
    'unqualified-attributes': SHOW,
    'zero-units': HIDE,
    'overqualified-elements': HIDE,
    shorthand: SHOW,
    'duplicate-background-images': SHOW,
    /* Maintainability & Duplication */
    floats: HIDE,
    'font-sizes': HIDE,
    ids: HIDE,
    important: HIDE,
    'order-alphabetical': HIDE,
    /* Accessibility */
    'outline-none': SHOW,
    /* OOCSS */
    'qualified-headings': SHOW,
    'unique-headings': SHOW,
  };

  return ruleSet;
};
