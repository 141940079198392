import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Route,
  Link,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { Tab, Tabs, Box, makeStyles } from '@material-ui/core';
import { Title } from '../backoffice-admins-styles';
import { SubTitleTab } from '@wm-accounts-backoffice-center/shared-styles';
import { AccountState } from '@wm-accounts-backoffice-center/state-management-users';
import { useSelector } from 'react-redux';
import ReadyForDeletion from './ready-for-deletion';
import AllSoftDeleted from './all-soft-deleted';
import NotBackofficeErrorMessage from '../not-backoffice-error';
import { WMLoader, WMStatusDot, WMStatusDotVariant } from '@walkme/wm-ui';
import PageWrapper from '../../../../../../apps/home/src/app/components/page-wrapper/page-wrapper';
import moment from 'moment';
import {
  accountsGraphqlCaller,
  accountsSubscriptionApi,
  accountSystemManagerApi,
  SystemWithStatus,
} from '@wm-accounts-backoffice-center/wm-api';
import { SubscriptionStatusType } from 'wm-accounts-sdk';
import { useSystemDeletionCenterStyles } from './styles';

enum TabsPaths {
  MainPath = '/systems-center',
  AllSoftDeleted = '/systems-center/all-soft-deleted',
  ReadyForDeletion = '/systems-center/ready-for-deletion',
}

const dateFormater = (date) => {
  return moment(date).format('MMMM Do YYYY');
};

const getAllSystemsByStatusPages = async (
  status: SubscriptionStatusType,
  limit: number
) => {
  const systemsWithStatus: SystemWithStatus[] = [];
  let page = 1;
  let totalPages = 1;

  do {
    const response = await accountsSubscriptionApi.getSystemsByStatus(
      status,
      limit,
      page
    );
    systemsWithStatus.push(...response?.docs);
    totalPages = response?.pages;
    page++;
  } while (page <= totalPages);

  return systemsWithStatus;
};

const dateComparator = (valueA, valueB) => {
  const dateA = moment(valueA, 'MMMM Do YYYY');
  const dateB = moment(valueB, 'MMMM Do YYYY');
  if (dateA.isBefore(dateB)) return -1;
  if (dateA.isAfter(dateB)) return 1;
  return 0;
};

export const getSystemsRows = (systems: SystemWithStatus[]): any[] => {
  if (!systems) {
    return [];
  }
  return systems.map((item) => {
    const {
      id,
      guid,
      email,
      displayName,
      type,
      status,
      accountName,
      accountId,
      actionType,
      actionTypeDetails,
      updatedAt,
      updatedBy,
      updatedByUserEmail,
      deletionAt,
      lastAnalyticsDate,
    } = item;
    return {
      systemId: id,
      systemGuid: guid,
      type,
      email,
      displayName,
      accountId,
      accountName,
      updatedBy,
      updatedByUserEmail,
      deletionSource: `${actionType} - ${actionTypeDetails}`,
      deactivationCycleDate: dateFormater(updatedAt),
      deletionDate: dateFormater(deletionAt),
      status,
      lastAnalyticsDate: moment(lastAnalyticsDate)?.isValid()
        ? dateFormater(lastAnalyticsDate)
        : lastAnalyticsDate,
    };
  });
};

export const SystemsDeletionCenter = () => {
  const classes = useSystemDeletionCenterStyles();
  const location = useLocation();
  const isMainPath = location.pathname === TabsPaths.MainPath;
  const [currentPath, setCurrentPath] = useState<string>(
    isMainPath ? TabsPaths.AllSoftDeleted : location.pathname
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [softDeletedSystems, setSoftDeletedSystems] = useState<
    SystemWithStatus[]
  >([]);
  const [dueForDeletionSystems, setDueForDeletionSystems] = useState<
    SystemWithStatus[]
  >([]);

  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState<string>('');

  useEffect(() => {
    if (isMainPath) {
      setCurrentPath(TabsPaths.AllSoftDeleted);
    } else {
      setCurrentPath(location.pathname);
    }
  }, [location.pathname, isMainPath]);

  const handleChange = (event, newValue) => {
    setCurrentPath(newValue);
  };

  const backofficeRoles = useSelector(
    (state: { accountState: AccountState }) => {
      return state.accountState.backofficeRoles;
    }
  );
  const isBackofficeAdmin = useMemo(() => {
    return true; // change to     return backofficeRoles.data?.isBackofficeAdmin; after testings in prod
  }, [backofficeRoles]);

  const fetchSystems = useCallback(async () => {
    try {
      setLoading(true);
      const systemsWithStatus: SystemWithStatus[] =
        await getAllSystemsByStatusPages(
          SubscriptionStatusType.pendingForDelete,
          1000
        );
      const systemsData = await accountsGraphqlCaller.getSystemsByIds(
        systemsWithStatus.map((system) => system.systemId)
      );

      const deletedByUsers = await accountsGraphqlCaller.geyUsersByIds(
        systemsWithStatus?.map((system) => system.updatedBy)
      );

      let analytics;

      try {
        const CHUNK_SIZE = 100;
        const systemGuids = systemsData?.map((system) => system.guid) || [];
        const chunks = [];

        for (let i = 0; i < systemGuids.length; i += CHUNK_SIZE) {
          chunks.push(systemGuids.slice(i, i + CHUNK_SIZE));
        }

        const analyticsPromises = chunks.map(async (chunk) => {
          try {
            return await accountSystemManagerApi.getSystemsLastAnalytics(
              chunk,
              3
            );
          } catch (error) {
            console.error('Error fetching analytics for chunk', chunk, error);
          }
        });

        const analyticsResults = await Promise.all(analyticsPromises);

        analytics = analyticsResults.flat();
      } catch (e) {
        console.error('Error fetching analytics', e);
      }

      const analyticsTimeBuckets = analytics.flatMap(
        (analytic) => analytic?.timeBuckets || []
      );

      const sortedAnalyticsTimeBuckets = analyticsTimeBuckets.sort((a, b) => {
        return (
          new Date(b.intervalStart).valueOf() -
          new Date(a.intervalStart).valueOf()
        );
      });

      const mergedSystems: SystemWithStatus[] = systemsWithStatus.map(
        (systemWithStatus) => {
          const systemData = systemsData.find(
            (system) => system?.id === systemWithStatus?.systemId
          );

          const systemAnalytics = sortedAnalyticsTimeBuckets?.find(
            (timeBucket) => timeBucket.values?.includes(systemData?.guid)
          );

          const updatedByUserEmail =
            deletedByUsers?.find(
              (user) => user?.id === systemWithStatus.updatedBy
            )?.email || 'N/A';

          return {
            accountId: systemWithStatus.accountId,
            accountName: systemData?.email?.split('_')[0],
            id: systemWithStatus.systemId,
            guid: systemWithStatus.systemGuid,
            email: systemData?.email || 'No email available',
            displayName: systemData?.name || 'No name available',
            type: systemData?.type,
            status: systemWithStatus.status,
            updatedAt: systemWithStatus.updatedAt,
            updatedBy: systemWithStatus.updatedBy,
            updatedByUserEmail,
            actionType: systemWithStatus.actionType,
            actionTypeDetails: systemWithStatus.actionTypeDetails,
            deletionAt: systemWithStatus.deletionAt,
            lastAnalyticsDate:
              systemAnalytics?.intervalStart ||
              'No analytics available for last 3 months',
          };
        }
      );

      const now = moment();
      const dueForDeletionSystems = mergedSystems.filter((system) =>
        moment(system.deletionAt).isBefore(now)
      );

      setDueForDeletionSystems(dueForDeletionSystems);
      setSoftDeletedSystems(mergedSystems);
    } catch (err) {
      setErrorSnackbarMessage('Error fetching systems');
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    accountsSubscriptionApi,
    accountsGraphqlCaller,
    setDueForDeletionSystems,
    setSoftDeletedSystems,
    setErrorSnackbarMessage,
    moment,
  ]);

  useEffect(() => {
    fetchSystems();
  }, []);

  const headCells = useMemo(() => {
    return [
      {
        lockVisible: true,
        pinned: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerName: 'System Email',
        field: 'email',
        sortable: true,
        minWidth: 250,
      },
      {
        headerName: 'Name',
        field: 'displayName',
        sortable: true,
      },
      {
        headerName: 'Type',
        field: 'type',
        sortable: true,
        filter: true,
        hide: true,
      },
      {
        headerName: 'System Id',
        field: 'systemId',
        sortable: true,
        hide: true,
      },
      {
        headerName: 'System Guid',
        field: 'systemGuid',
        sortable: true,
        hide: true,
        minWidth: 250,
      },
      {
        headerName: 'Account Name',
        field: 'accountName',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Last Analytics Date',
        field: 'lastAnalyticsDate',
        sortable: true,
        filter: true,
        comparator: dateComparator,
      },
      {
        headerName: 'Account Id',
        field: 'accountId',
        sortable: true,
        minWidth: 100,
        filter: true,
        hide: true,
      },
      {
        headerName: 'Deletion Date',
        field: 'deletionDate',
        minWidth: 200,
        sortable: true,
        filter: 'agDateColumnFilter',
        comparator: dateComparator,
      },
      {
        headerName: 'Deletion Source',
        field: 'deletionSource',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Deleted By',
        field: 'updatedByUserEmail',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'Deleted By User Id',
        field: 'updatedBy',
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 100,
        filter: true,
        cellRenderer: (params) => {
          const status = params.data?.status;
          return (
            <>
              <WMStatusDot
                className={classes.customDot}
                variant={
                  status === SubscriptionStatusType.deleted
                    ? WMStatusDotVariant.Failure
                    : WMStatusDotVariant.Draft
                }
              />
              {status}
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <div className="noPadding">
      <Router>
        <div className={classes.contentDiv}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '14px',
            }}
          >
            <Title>Systems Deletion Center </Title>
            <SubTitleTab>
              Manage WalkMe soft and hard delete systems. Initiate the deletion
              cycle.
            </SubTitleTab>
          </div>
          {!isBackofficeAdmin ? (
            <NotBackofficeErrorMessage />
          ) : loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              This action can take up to 30 seconds
              <WMLoader style={{ width: '30%', maxWidth: '300px' }} />
            </div>
          ) : (
            <Box style={{ width: '100%', padding: 0 }}>
              <Box className={classes.tabBoxes}>
                <Tabs
                  value={currentPath}
                  classes={{
                    indicator: classes.indicator,
                  }}
                  onChange={handleChange}
                >
                  <Tab
                    label={
                      <div>
                        <span
                          className={
                            currentPath === TabsPaths.AllSoftDeleted
                              ? classes.activeTab
                              : classes.tab
                          }
                        >
                          All Soft Deleted
                        </span>
                      </div>
                    }
                    component={Link}
                    value={TabsPaths.AllSoftDeleted}
                    to={TabsPaths.AllSoftDeleted}
                  />
                  <Tab
                    label={
                      <div>
                        <span
                          className={
                            currentPath === TabsPaths.ReadyForDeletion
                              ? classes.activeTab
                              : classes.tab
                          }
                        >
                          Ready For Deletion
                        </span>
                      </div>
                    }
                    component={Link}
                    value={TabsPaths.ReadyForDeletion}
                    to={TabsPaths.ReadyForDeletion}
                  />
                </Tabs>
              </Box>
              <Route
                path={TabsPaths.AllSoftDeleted}
                render={(props) => (
                  <PageWrapper
                    {...props}
                    page={AllSoftDeleted}
                    name="all-soft-deleted"
                    container
                    pageData={{
                      headCells,
                      softDeletedSystems,
                      setLoading,
                      fetchSystems,
                    }}
                    loadingData={loading}
                  />
                )}
              />
              <Route
                path={TabsPaths.ReadyForDeletion}
                render={(props) => (
                  <PageWrapper
                    {...props}
                    page={ReadyForDeletion}
                    name="ready-for-deletion"
                    container
                    pageData={{
                      headCells,
                      dueForDeletionSystems,
                      setDueForDeletionSystems,
                    }}
                    loadingData={loading}
                  />
                )}
              />
              <Route path={TabsPaths.MainPath}>
                <Redirect to={TabsPaths.AllSoftDeleted} />
              </Route>
            </Box>
          )}
        </div>
      </Router>
    </div>
  );
};

export default SystemsDeletionCenter;
