import React from 'react';
import {
  EmptyLabel,
  SubTitleTab,
} from '@wm-accounts-backoffice-center/shared-styles';

const NotBackofficeErrorMessage: React.FC = () => {
  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img src="/assets/searching.svg" width="350px" height="350px" />
      <EmptyLabel>You are not a backoffice admin</EmptyLabel>
    </div>
  );
};

export default NotBackofficeErrorMessage;
