import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  EntityFeatureFlag,
  FeatureFlag,
} from '@wm-accounts-backoffice-center/state-management-features';
import {
  useGetSystemEnvironments,
  useGetExcludedEnvironments,
  updateExcludedEnvironments,
} from '@wm-accounts-backoffice-center/state-management-environments';
import {
  WMAgGridWrapper,
  WMButton,
  WMButtonVariant,
  WMSnackbar,
  WMSnackbarVariant,
  WMSnackbarProps,
} from '@walkme/wm-ui';
import { makeStyles } from '@material-ui/core';

export interface RuntimeFeatureFlagsConfigurationProps {
  selectedFF: FeatureFlag;
  entityAssignedToFeature: EntityFeatureFlag;
}

const useStyles = makeStyles((theme) => ({
  bottomDiv: {
    marginBottom: '32px',
    position: 'fixed',
    bottom: '0px',
    width: '416px',
  },
  styleDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

enum UpdateStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export function RuntimeFeatureFlagsConfiguration(
  props: RuntimeFeatureFlagsConfigurationProps
) {
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [selectedEnvs, setSelectedEnvs] = useState<any>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [updateStatus, setUpdateStatus] = useState(UpdateStatus.SUCCESS);
  const dispatch = useDispatch();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const { systemEnvironmentsData } = useGetSystemEnvironments(
    props.entityAssignedToFeature.EntityId
  );
  const envRows = useMemo(
    () =>
      systemEnvironmentsData.data
        .map((env) => {
          return { id: env.id, name: env.name };
        })
        .sort((a, b) => a.id - b.id),
    [systemEnvironmentsData.data]
  );

  const {
    excludedEnvironmentsData,
    excludedEnvironmentsUpdateData,
    resetUpdate,
  } = useGetExcludedEnvironments(
    props.entityAssignedToFeature.EntityId,
    props.selectedFF.flagName
  );

  const excludedEnvs = useMemo(
    () => excludedEnvironmentsData.data,
    [excludedEnvironmentsData.data]
  );

  useEffect(() => {
    if (gridApi) {
      gridApi.rowModel.forEachNode((node: any) => {
        node.setSelected(!excludedEnvs.includes(node.data.id));
      });
    }
  }, [envRows, excludedEnvs, gridApi]);

  useEffect(() => {
    if (excludedEnvironmentsUpdateData.data) {
      setUpdateStatus(UpdateStatus.SUCCESS);
      setOpenSnackbar(true);
    }
    if (excludedEnvironmentsUpdateData.error) {
      setUpdateStatus(UpdateStatus.ERROR);
      setOpenSnackbar(true);
    }
  }, [excludedEnvironmentsUpdateData]);

  const onSelectionChanged = (selectionEvent: any) => {
    const selectedEnvs = selectionEvent.api.selectionService
      .getSelectedRows()
      .map((row: any) => row.id);
    setSelectedEnvs(selectedEnvs);
  };

  const hasChanged = () => {
    const excludedSelectedEnvs = systemEnvironmentsData.data
      .filter((env) => !selectedEnvs.includes(env.id))
      .map((env) => env.id);
    return (
      JSON.stringify(excludedSelectedEnvs) !== JSON.stringify(excludedEnvs)
    );
  };

  const snackbarProps: WMSnackbarProps = {
    open: openSnackbar,
    onClose: (event, reason) => {
      if (reason === 'clickaway') return;
      setOpenSnackbar(false);
      resetUpdate();
    },
    variant:
      updateStatus === UpdateStatus.ERROR
        ? WMSnackbarVariant.Error
        : WMSnackbarVariant.Success,
    message:
      updateStatus === UpdateStatus.ERROR
        ? 'Failed to save environment configuration'
        : 'Environment configuration saved successfully',
  };

  return (
    <>
      <WMSnackbar {...snackbarProps} />
      <div style={{ height: 400 }}>
        <WMAgGridWrapper
          onGridReady={onGridReady}
          rowData={envRows}
          columnDefs={[
            {
              field: 'id',
              headerName: 'ID',
              headerCheckboxSelection: true,
              checkboxSelection: true,
              width: 120,
              resizable: true,
            },
            { field: 'name', resizable: true },
          ]}
          gridOptions={{
            onSelectionChanged: onSelectionChanged,
          }}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          rowStyle={{
            '--ag-selected-row-background-color': 'transparent',
            '--ag-range-selection-border-color': 'transparent',
          }}
        ></WMAgGridWrapper>
      </div>

      <div className={classes.bottomDiv}>
        <div className={classes.styleDiv}>
          <WMButton
            variant={WMButtonVariant.Primary}
            type="button"
            disabled={
              !hasChanged() ||
              excludedEnvironmentsData.loading === true ||
              excludedEnvironmentsUpdateData.loading === true
            }
            onClick={() => {
              const selectedEnvs = gridApi.selectionService
                .getSelectedRows()
                .map((row: any) => row.id);
              const excludedEnvs = systemEnvironmentsData.data
                .filter((env) => !selectedEnvs.includes(env.id))
                .map((env) => env.id);
              dispatch(
                updateExcludedEnvironments(
                  props.entityAssignedToFeature.EntityId,
                  props.selectedFF.flagName,
                  excludedEnvs
                )
              );
            }}
          >
            Save
          </WMButton>
        </div>
      </div>
    </>
  );
}

export default RuntimeFeatureFlagsConfiguration;
