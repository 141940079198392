import React from 'react';
import { ContentDiv, EmptyLabel } from '../merge-account-page.styles';
import { Button, Label } from '@walkme/ui-core';
import { TitleLabel } from './page-display-options.styles';

export interface PageFailureDisplayProps {
  onStartMergeClicked: () => void;
}

export const PageFailureDisplay = ({
  onStartMergeClicked,
}: PageFailureDisplayProps) => {
  return (
    <ContentDiv>
      <TitleLabel>Merge Account action has failed</TitleLabel>
      <Label>
        Note: Some of the data from the source account may have been merged.
      </Label>
      <Button color="danger" variant="outlined" onClick={onStartMergeClicked}>
        Try Again
      </Button>
    </ContentDiv>
  );
};
