import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, MergeAccountState } from '../../../../merge-account.store';
import { systemExcluded, systemIncluded } from '../../../../merge-data.slice';
import { System } from 'wm-accounts-sdk';
import { userCanProceed } from '../../merge-account-wizard.slice';

export interface ExcludeSystemsState {
  query: string;
  filteredSystems: System[];
}

export const initialExcludeSystemsState: ExcludeSystemsState = {
  query: '',
  filteredSystems: [],
};

const excludeSystemsSlice = createSlice({
  name: 'excludeSystems',
  initialState: initialExcludeSystemsState,
  reducers: {
    resetExcludeSystems: (state: ExcludeSystemsState) => {
      state.query = '';
      state.filteredSystems = [];
    },
    queryValueUpdted: (
      state: ExcludeSystemsState,
      action: PayloadAction<string>
    ) => {
      state.query = action.payload;
    },
    systemsFiltered: (
      state: ExcludeSystemsState,
      action: PayloadAction<System[]>
    ) => {
      state.filteredSystems = [...action.payload].sort((s1, s2) =>
        s1.displayName > s2.displayName ? 1 : -1
      );
    },
  },
});

export const { resetExcludeSystems, systemsFiltered, queryValueUpdted } =
  excludeSystemsSlice.actions;

const filterAssociatedSystems = (systems: System[]) =>
  systems.filter((s) => !s.associatedSystem);

const filterSystemsByQuery = (
  systemsToFilter: System[],
  query: string
): System[] => {
  const filteredSystemsByGuid = systemsToFilter.filter((system) => {
    return system.guid.toLowerCase().includes(query.toLowerCase());
  });
  const filteredSystemsByName = systemsToFilter.filter((system) => {
    return system.displayName.toLowerCase().includes(query.toLowerCase());
  });
  const filteredSystemsByEmail = systemsToFilter.filter((system) => {
    return system.email.toLowerCase().includes(query.toLowerCase());
  });
  // using Set to make sure each system appears in the result only once
  const filteredSystems = [
    ...new Set([
      ...filteredSystemsByGuid,
      ...filteredSystemsByName,
      ...filteredSystemsByEmail,
    ]),
  ];
  return filteredSystems;
};

export const stepLoaded = (): AppThunk => async (dispatch, getState) => {
  dispatch(userCanProceed());
};

export const accountSystemsUpdated =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState() as MergeAccountState;
    const sourceAccountSystems = state.mergeData?.sourceAccount?.systems || [];
    const systemsToFilter = filterAssociatedSystems(sourceAccountSystems);
    dispatch(queryValueUpdted(''));
    dispatch(systemsFiltered(systemsToFilter));
  };

export const systemsQueryChanged =
  (query: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState() as MergeAccountState;
    const sourceAccountSystems = state.mergeData?.sourceAccount?.systems || [];
    const systemsToFilter = filterAssociatedSystems(sourceAccountSystems);

    query = query?.trim() || '';
    dispatch(queryValueUpdted(query));

    if (query === '') {
      dispatch(systemsFiltered(systemsToFilter));
    } else {
      const filteredSystems = filterSystemsByQuery(systemsToFilter, query);
      dispatch(systemsFiltered(filteredSystems));
    }
  };

export const systemClicked =
  (systemId: number): AppThunk =>
  async (dispatch, getState) => {
    const state = getState() as MergeAccountState;
    const excludeSystemIds = state.mergeData.excludeSystemIds;
    if (excludeSystemIds.indexOf(systemId) === -1) {
      dispatch(systemExcluded(systemId));
    } else {
      dispatch(systemIncluded(systemId));
    }
  };

export default excludeSystemsSlice.reducer;
