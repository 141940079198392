import React from 'react';
import { AuthProvider } from 'wm-accounts-auth';
import { WMStylesProvider } from '@walkme/wm-ui';
import App from './app';

export const Root = () => {
  return (
    <AuthProvider>
      <WMStylesProvider>
        <App />
      </WMStylesProvider>
    </AuthProvider>
  );
};

export default Root;
