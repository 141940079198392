export enum LoginTestMethod {
  POPUP_AND_REDIRECT = 'popupAndRedirect',
  POPUP = 'popup',
}
export const testingLoginDoneRoute = 'testing-login-done';
const storageLoginMethod = localStorage.getItem('wm-login-method');
export const loginTestMethod = LoginTestMethod[storageLoginMethod];

export const enhanceAuthConfig = (wmAuthConfig: any) => {
  const displayPopup =
    (loginTestMethod === LoginTestMethod.POPUP_AND_REDIRECT ||
      loginTestMethod === LoginTestMethod.POPUP) &&
    !window.location.pathname.includes(testingLoginDoneRoute);
  const testAuthConfig = {
    display: displayPopup ? 'popup' : undefined,
  };

  Object.assign(wmAuthConfig, testAuthConfig);
};
