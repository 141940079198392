import React, { useState } from 'react';
import { ChevronRight } from '@walkme/ui-icons/large';
import { ChevronDown } from '@walkme/ui-icons/large';
import { makeStyles } from '@material-ui/core';
import { EventView } from './event';
import { StatusIndicator } from './status-indicator';
import { indentationConstant } from './consts';

const useStyles = makeStyles(() => ({
  logViewTask: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '5px 20px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

export const TaskView = ({ task, isSpecific, indentFactor = 0 }) => {
  const [openTask, setOpenTask] = useState(true);

  const classes = useStyles();

  return (
    <div>
      <div
        className={classes.logViewTask}
        onClick={() => setOpenTask(!openTask)}
        style={{
          background: openTask ? '#F7F9FC' : '#FFF',
          paddingLeft: `${20 + indentFactor * indentationConstant}px`,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {openTask ? <ChevronDown /> : <ChevronRight />}
          {task.description}
        </div>
        <StatusIndicator status={task.status} errors={task.errors} />
      </div>
      {openTask &&
        task.eventsDetails.map((event, index) => {
          return (
            <EventView
              key={index}
              event={event}
              indentFactor={indentFactor + 1}
              isSpecific={isSpecific}
            />
          );
        })}
    </div>
  );
};
