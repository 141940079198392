import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';
export interface SystemDeployableCountItem {
  systemId: number;
  deployables: Record<string, number>;
}

export interface SubFolder {
  deployableAmount: number;
  name: string;
  id: number;
  parentId: number;
}
export interface Folder {
  id: number;
  name: string;
  parentId: number;
  subFolders: SubFolder[];
  deployables: Deployables[];
}
export interface Deployables {
  displayType: string;
  name: string;
  id: number;
}
export interface FlatDeployables {
  displayType: string;
  id: number;
}

export interface DeployablesTypes {
  displayType: string;
  subType: number;
  type: number;
  value: string;
  iconComponent: string;
  displayName?: string;
}
class CmtApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  async getSystemsDeployablesCount(
    systemIds
  ): Promise<SystemDeployableCountItem[]> {
    try {
      const response = await this.httpClient.instance.post(
        'walkme-os-api/graphql',
        {
          query: `
            {
                backofficeAccountDeployablesCount(systemIds:[${systemIds}]) {
                    systemId
                    deployables
              }
            }
        `,
        }
      );
      return (
        response.data.data &&
        response.data.data.backofficeAccountDeployablesCount
      );
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        'Unspecified error (Deployables)';
      throw new Error(message);
    }
  }

  async getSystemDeployables(systemId, folderId): Promise<Folder> {
    try {
      const response = await this.httpClient.instance.post(
        'walkme-os-api/graphql',
        {
          query: `query accountsBackofficeFolderContent(
            $params: BackofficFolderContentInput!
          ) {
            backofficeFolderContent(params: $params) {
                id
                name
                parentId
                subFolders {
                  deployableAmount
                  name
                  id
                  parentId
                }
                deployables {
                  id
                  displayType
                  name
                }
            }
          }`,
          variables: {
            params: folderId
              ? {
                  systemId: systemId,
                  folderId: folderId,
                }
              : {
                  systemId: systemId,
                },
          },
        }
      );
      return response.data.data && response.data.data.backofficeFolderContent;
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        'Unspecified error (Deployables)';
      throw new Error(message);
    }
  }
  async flatDeployablesAndFolders(
    systemId,
    folderId
  ): Promise<FlatDeployables[]> {
    try {
      const response = await this.httpClient.instance.post(
        'walkme-os-api/graphql',
        {
          query: `
            query backofficeFlatDeployablesAndFolders(
              $params: FlatDeployablesAndFoldersInput!
            ) {
              backofficeFlatDeployablesAndFolders(params: $params) {
                deployables{
                            id
                            displayType
                            name
                          }
              }
            }
          `,
          variables: {
            params: folderId
              ? {
                  systemId: systemId,
                  folderId: folderId,
                }
              : {
                  systemId: systemId,
                  folderId: -1,
                },
          },
        }
      );
      return (
        response.data.data &&
        response.data.data.backofficeFlatDeployablesAndFolders.deployables
      );
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        'Unspecified error (Deployables)';
      throw new Error(message);
    }
  }

  async deployablesTypes(): Promise<DeployablesTypes[]> {
    try {
      const response = await this.httpClient.instance.post(
        'walkme-os-api/graphql',
        {
          query: `
          {
            Deployable_Data_deployableTypes{
                        displayType
                        type
                        subType
                        value
                        iconComponent
                      }
          }
        `,
        }
      );

      if (response.data?.data?.Deployable_Data_deployableTypes) {
        let result = response.data?.data?.Deployable_Data_deployableTypes.map(
          (dt) => ({ ...dt, displayName: dt.value })
        );
        result = result.concat([
          {
            displayType: 'BUSINESS_SOLUTION',
            type: 19,
            subType: 0,
            value: 'BusinessSolution',
            iconComponent: 'DeployablesWalkThru',
            displayName: 'Business Solution',
          },
          {
            displayType: 'CONTENT',
            type: 7,
            subType: 3,
            value: 'Content',
            iconComponent: 'DeployablesArticle',
            displayName: 'Content (Article/Video)',
          },
          {
            displayType: 'TASK',
            type: 1,
            subType: 1,
            value: 'Task',
            iconComponent: 'DeployablesOnboardingTask',
            displayName: 'On Boarding Task',
          },
        ]);

        return result;
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        'Unspecified error (Deployables)';
      throw new Error(message);
    }
  }
}

export const cmtApi = new CmtApi(wmhttpClientSharedInstance);
