import { AppData } from '@wm-accounts-backoffice-center/general-types';

interface AiManagerState {
  aiSettings: AppData<{
    isEnabled: AppData<boolean>;
    rules: Rule[];
  }>;
}

interface AiSettings {
  isEnabled: boolean;
  rules: Rule[];
}

interface Rule {
  function: string;
  provider: string;
  service: string;
  application: string;
  model: string;
  modelVersion: string;
  operation: string;
  isCustom: string;
}

export type { AiSettings, Rule, AiManagerState };
