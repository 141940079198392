import { InputSearch } from '@walkme/ui-core';
import { useDebouncedCallback } from 'use-debounce';
import {
  ContentWrapper,
  ItemsWrapper,
  StyledLoader,
  StyledPlaceholderImage,
  useStepStyles,
} from '../wizard-steps.styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MergeAccountState } from '../../../../merge-account.store';
import { useDispatch, useSelector } from 'react-redux';
import { AccountCard } from '../../components/account.card';
import {
  searchQueryChanged,
  sourceAccountClicked,
  stepLoaded,
} from './select-source-account.step.slice';
import { FolderWomanSvg } from '../../assets/icons/folder-woman';

const WizardStepSelectSourceAccount = () => {
  const classes = useStepStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(stepLoaded());
  }, [dispatch]);

  const query = useSelector(
    (state: MergeAccountState) => state.selectSourceAccount?.query || ''
  );
  const accounts = useSelector(
    (state: MergeAccountState) =>
      state.selectSourceAccount?.accounts?.data || []
  );
  const isLoading = useSelector(
    (state: MergeAccountState) =>
      state.selectSourceAccount?.accounts?.loading || false
  );

  const sourceAccountId = useSelector(
    (state: MergeAccountState) => state.mergeData.sourceAccount?.id
  );

  const onSearchChange = useDebouncedCallback((value: string) => {
    dispatch(searchQueryChanged(value));
  }, 600);

  const onAccountClicked = useCallback(
    (accountId: number) => {
      dispatch(sourceAccountClicked(accountId));
    },
    [dispatch]
  );

  const rendredAccounts = useCallback(
    (accounts) =>
      accounts.map((account) => {
        return (
          <AccountCard
            onClicked={onAccountClicked}
            key={account.id}
            account={account}
            isSelected={sourceAccountId === account.id}
          />
        );
      }),
    [sourceAccountId]
  );

  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <div className={classes.stepInnerFrame}>
          <label className={classes.titleLabel}>Select source account</label>
          <InputSearch
            placeholder="Search account by any account field (name, sfdc, id, guid, enterprise snippet email) or system snippet email"
            onChange={onSearchChange}
            value={query}
          />
          <ContentWrapper>
            {isLoading ? (
              <StyledLoader />
            ) : accounts?.length ? (
              <ItemsWrapper>
                {accounts?.length && rendredAccounts(accounts)}
              </ItemsWrapper>
            ) : (
              <StyledPlaceholderImage>
                <FolderWomanSvg />
              </StyledPlaceholderImage>
            )}
          </ContentWrapper>
        </div>
      </div>
    </div>
  );
};

export default WizardStepSelectSourceAccount;
