import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';
import {
  FeatureFlag,
  FeatureFlagBase,
  HistoryRecord,
  OwnerTeam,
} from '../../../state-management-feature-toggles/src';

const BASE_URL = 'accounts-feature-toggles/backoffice';

class FeatureTogglesApi {
  private readonly httpClient: WMhttpClient;

  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  errorParser(e) {
    const message = e.response?.data?.message;

    return Array.isArray(message) ? message[0] : message || e;
  }

  handleTargetPopulationError(data) {
    const isEntitiesMissing = data?.targetPopulation?.entities === null;

    if (isEntitiesMissing) return 'Entities are required';
    else return 'Entities are invalid';
  }

  async getFeatureFlags(): Promise<FeatureFlag[]> {
    try {
      const response = await this.httpClient.instance.get(
        `${BASE_URL}/featureFlags`
      );
      return response.data;
    } catch (e) {
      const error = this.errorParser(e);
      throw new Error(`Failed to query feature flags: ${error}`);
    }
  }

  async createFeatureFlag(
    featureFlag: FeatureFlagBase,
    validate: boolean,
    sync: boolean
  ): Promise<FeatureFlag> {
    try {
      const response = await this.httpClient.instance.put(
        `${BASE_URL}/featureFlags?validate=${validate}&sync=${sync}`,
        featureFlag
      );
      return response.data;
    } catch (e) {
      const error = this.errorParser(e);
      throw new Error(`Failed to create new feature flag: ${error}`);
    }
  }

  async updateFeatureFlag(
    updatedData: any,
    validate: boolean
  ): Promise<FeatureFlag> {
    try {
      const response = await this.httpClient.instance.post(
        `${BASE_URL}/featureFlags?validate=${validate}`,
        updatedData
      );
      return response.data;
    } catch (e) {
      const error = this.errorParser(e);
      if (error === 'Target population is invalid') {
        const message = this.handleTargetPopulationError(updatedData);
        throw new Error(`Failed to update feature flag: ${message}`);
      }
      throw new Error(`Failed to update feature flag: ${error}`);
    }
  }

  async sendFFUpdateRequest(
    url: string,
    payload: object,
    errorMessage: string
  ): Promise<FeatureFlag> {
    try {
      const response = await this.httpClient.instance.post(url, payload);
      return response.data;
    } catch (e) {
      const error = this.errorParser(e);
      throw new Error(`${errorMessage}: ${error}`);
    }
  }

  async updateFeatureFlagState(
    name: string,
    ownerTeam: string,
    state: boolean
  ): Promise<FeatureFlag> {
    return this.sendFFUpdateRequest(
      `${BASE_URL}/featureFlags`,
      {
        name,
        ownerTeam,
        isEnabled: state,
      },
      "Failed to update feature flag's state"
    );
  }

  async updateFeatureFlagStatus(
    name: string,
    ownerTeam: string,
    archiveStatus: string
  ): Promise<FeatureFlag> {
    return this.sendFFUpdateRequest(
      `${BASE_URL}/featureFlags`,
      {
        name,
        ownerTeam,
        status: archiveStatus,
      },
      "Failed to change feature flag's archive status"
    );
  }

  async getOwnerTeams() {
    try {
      const response = await this.httpClient.instance.get(
        'accounts-feature-toggles/ownerTeams'
      );
      return response.data;
    } catch (e) {
      const error = this.errorParser(e);
      throw new Error(`Failed to query owner teams: ${error}`);
    }
  }

  async createOwnerTeam(name: string): Promise<OwnerTeam> {
    try {
      const response = await this.httpClient.instance.put(
        'accounts-feature-toggles/ownerTeams',
        { name }
      );
      return response.data;
    } catch (e) {
      const error = this.errorParser(e);
      throw new Error(`Failed to create new owner team: ${error}`);
    }
  }

  async getFeatureFlagHistory(
    name: string,
    ownerTeam: string
  ): Promise<HistoryRecord[]> {
    try {
      const encodedName = encodeURIComponent(name);
      const response = await this.httpClient.instance.get(
        `accounts-feature-toggles/featureFlagsHistory?name=${encodedName}&ownerTeam=${ownerTeam}`
      );
      return response.data;
    } catch (e) {
      const error = this.errorParser(e);
      throw new Error(`Failed to query feature flag's history: ${error}`);
    }
  }

  async getFeatureFlagEvaluations(featureFlagId: string): Promise<any> {
    try {
      const encodedId = encodeURIComponent(featureFlagId);
      const response = await this.httpClient.instance.get(
        `${BASE_URL}/evaluations?featureFlag=${encodedId}`
      );
      return response.data;
    } catch (e) {
      const error = this.errorParser(e);
      throw new Error(`Failed to query feature flag's evaluations: ${error}`);
    }
  }
}

export const featureTogglesApi = new FeatureTogglesApi(
  wmhttpClientSharedInstance
);
