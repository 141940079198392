import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppThunk, MergeAccountState } from '../../../../merge-account.store';

import {
  accountsGraphqlCaller,
  Account,
} from '@wm-accounts-backoffice-center/wm-api';
import { targetAccountSelected } from '../../../../merge-data.slice';
import {
  userCanProceed,
  userCannotProceeed,
} from '../../merge-account-wizard.slice';

export interface AppData<T> {
  loading: boolean;
  error: any;
  data: T;
}

export interface TargetAccoutState {
  query: string;
  searchIndex: number;
  accounts: AppData<Account[]>;
}

export const initialTargetAccountState: TargetAccoutState = {
  query: '',
  searchIndex: 0,
  accounts: {
    loading: false,
    error: null,
    data: [],
  },
};

const selectTargetAccountSlice = createSlice({
  name: 'selectTargetAccount',
  initialState: initialTargetAccountState,
  reducers: {
    resetSearchTargetAccount: (state: TargetAccoutState) => {
      state.query = '';
      state.searchIndex = 0;
      state.accounts = {
        loading: false,
        error: null,
        data: [],
      };
    },
    searchAccountStarted: (
      state: TargetAccoutState,
      action: PayloadAction<string>
    ) => {
      state.query = action.payload;
      state.searchIndex++;
      state.accounts = {
        loading: true,
        error: null,
        data: [],
      };
    },
    searchAccountFinished: (
      state: TargetAccoutState,
      action: PayloadAction<Account[]>
    ) => {
      state.accounts = {
        loading: false,
        error: null,
        data: action.payload,
      };
    },
    searchAccontFailed: (
      state: TargetAccoutState,
      action: PayloadAction<any>
    ) => {
      state.accounts = {
        loading: false,
        error: action.payload,
        data: [],
      };
    },
  },
});

export const {
  resetSearchTargetAccount,
  searchAccountStarted,
  searchAccountFinished,
  searchAccontFailed,
} = selectTargetAccountSlice.actions;

const searchAccounts = async (query) => {
  if (query.trim() === '') {
    return [];
  }

  const response = await accountsGraphqlCaller.getAccountsByQuery({
    limit: 50,
    order: 'ASC',
    orderBy: 'email',
    page: 1,
    filter: query,
    filterBy: 'id,guid,sfId,email,name',
  });

  return response.accounts;
};

export const stepLoaded = (): AppThunk => async (dispatch, getState) => {
  const targetAccount = (getState() as MergeAccountState).mergeData
    .targetAccount;
  if (!targetAccount) {
    dispatch(userCannotProceeed());
  }
};

export const searchQueryChanged =
  (query: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(targetAccountSelected(null)); // once query changes - no account is selected
      dispatch(userCannotProceeed()); // no account selected

      dispatch(searchAccountStarted(query));

      if (query.trim() === '') {
        dispatch(userCannotProceeed());
        dispatch(searchAccountFinished([]));
        return;
      }

      const querySearchIndex = (getState() as MergeAccountState)
        .selectTargetAccount.searchIndex;

      const accounts = await searchAccounts(query);
      const currentSearchIndex = (getState() as MergeAccountState)
        .selectTargetAccount.searchIndex;

      if (querySearchIndex !== currentSearchIndex) {
        return;
      }

      const sourceAccountId = (getState() as MergeAccountState).mergeData
        ?.sourceAccount?.id;

      const accountsWithoutSource = accounts.filter(
        (account) => account.id !== sourceAccountId
      );

      dispatch(searchAccountFinished(accountsWithoutSource));
    } catch (err) {
      dispatch(searchAccontFailed(err.message));
      return;
    }
  };

export const targetAccountClicked =
  (accountId: number): AppThunk =>
  async (dispatch, getState) => {
    // avoid selecting the same account as source account
    const sourceAccountId = (getState() as MergeAccountState).mergeData
      ?.sourceAccount?.id;
    if (accountId === sourceAccountId) {
      return;
    }

    const accounts =
      (getState() as MergeAccountState).selectTargetAccount?.accounts?.data ||
      [];
    const selectedAccount = accounts.find(
      (account) => account.id === accountId
    );
    if (selectedAccount) {
      dispatch(targetAccountSelected(selectedAccount));
      dispatch(userCanProceed());
    }
  };

export default selectTargetAccountSlice.reducer;
