import { Typography } from '@material-ui/core';
import { TextHoverPopover } from '@wm-accounts-backoffice-center/ui-components';
import React from 'react';
import { DateTime } from 'luxon';

export function FeatureFlagNotesTableCell({ value }) {
  if (value.length < 2) {
    const note = value[0];
    return (
      <Typography component="p">
        <FeatureFlagNotesCell note={note} />
      </Typography>
    );
  }

  const firstNote = value[0];
  const allTheRest = value.slice(1);
  return (
    <TextHoverPopover
      text={
        <Typography component="p">
          <FeatureFlagNotesCell note={firstNote} />
          ...
        </Typography>
      }
    >
      {allTheRest.map((d) => (
        <Typography component="p" key={d.id}>
          <FeatureFlagNotesCell note={d} />
          <br />
        </Typography>
      ))}
    </TextHoverPopover>
  );
}

function FeatureFlagNotesCell({ note }) {
  return (
    <>
      {note.email} ({DateTime.fromISO(note.createdAt).toFormat('D')}) <br />{' '}
      {note.note}
    </>
  );
}
