import {
  InputSearch,
  Loader,
  ToasterVariant,
  useToaster,
} from '@walkme/ui-core';
import { AppData } from '@wm-accounts-backoffice-center/general-types';
import { Account } from '@wm-accounts-backoffice-center/wm-api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { makeStyles } from '@material-ui/core';
import { getAccountsByQuery } from '@wm-accounts-backoffice-center/state-management-accounts-search';
import SearchItem from './search-item';

const useStyles = makeStyles(() => ({
  titleLabel: {
    fontFamily: 'Proxima Nova',
    fontDtyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2F426C',
    marginBottom: '20px',
  },
  SeconderyLabel: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2F426C',
  },
  SeconderyContent: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2F426C',
  },
  error: {
    zIndex: 100,
    border: '1px solid #F7B500',
    borderRadius: '4px',
    background: '#FEF8E8',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 12px',
  },
  buttonBack: {
    padding: '6px !important',
  },
}));

interface AccountSearchProps {
  onSelectedAccount: (selectedAccount: Account) => void;
}

function AccountSearch({ onSelectedAccount }: AccountSearchProps) {
  const { addToaster } = useToaster();
  const classes = useStyles();
  const [foundAccounts, setFoundAccounts] = useState<AppData<Account[]>>({
    data: [],
    loading: false,
    error: null,
  });
  const [targetAccount, setTargetAccount] = useState<Account>(null);
  const [query, setQuery] = useState('');

  const findAccountsByQuery = async (query: string) => {
    try {
      setFoundAccounts({ data: [], loading: true, error: null });
      const newAccountsResults = await getAccountsByQuery({
        limit: 20,
        order: 'ASC',
        orderBy: 'email',
        page: 1,
        filter: query,
        filterBy: 'id,guid,sfId,email,name',
      });
      setFoundAccounts({
        data: newAccountsResults?.accounts || [],
        loading: false,
        error: null,
      });
    } catch (e) {
      addToaster({
        message: `failed to find account: ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  };

  useEffect(() => {
    findAccountsByQuery(query);
  }, [query]);

  const onSearchChange = useDebouncedCallback((event) => {
    setQuery(event);
    onSelectedAccount(null);
    setTargetAccount(null);
  }, 600);

  return (
    <div style={{ margin: '32px' }}>
      <div
        style={{
          background: 'white',
          borderRadius: '12px',
          height: '530px',
        }}
      >
        <div
          style={{
            padding: '24px 32px 0',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <InputSearch
            placeholder="Search by account any account field (name, sfdc, id, guid, enterprise snippet email) or system snippet email"
            onChange={onSearchChange}
          />
          {!foundAccounts.loading ? (
            <div style={{ height: '370px', overflow: 'auto' }}>
              {foundAccounts.data && foundAccounts.data.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '16px',
                    marginTop: '18px',
                  }}
                >
                  {foundAccounts.data
                    .slice()
                    .reverse()
                    .map((account) => {
                      return (
                        <SearchItem
                          firstText={account.name}
                          secondText={account.email}
                          thirdText={account.guid}
                          circleInitials={account.name?.charAt(0) || 'N/A'}
                          isSelected={targetAccount?.id === account.id}
                          onClickedItem={() => {
                            setTargetAccount(account);
                            onSelectedAccount(account);
                          }}
                        />
                      );
                    })}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src="/assets/folder-women.svg"
                    width={'150px'}
                    height={'150px'}
                  />
                  <span
                    style={{
                      marginTop: '36px',
                      fontFamily: 'Proxima Nova',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#2F426C',
                    }}
                  >
                    Find account by searching for the account name, enterprise
                    snippet email or system snippet email, id, guid
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                background: 'white',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AccountSearch;
