import React, { createContext } from 'react';

export const HttpClientContext = createContext(null);

export const HttpServiceProvider: React.FC<{ service: any }> = ({
  children,
  service,
}) => {
  return (
    <HttpClientContext.Provider value={service}>
      {children}
    </HttpClientContext.Provider>
  );
};
