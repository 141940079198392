import { Account, Role, System, WMAccountIdp, WMUser } from 'wm-accounts-sdk';
import {
  WMhttpClient,
  wmhttpClientSharedInstance,
} from '../../../../../../wm-api/src/lib/wm-http-client';
import {
  GetMergeProcsResponse,
  MergeAccountRequestDto,
  MergeAccountsResult,
} from './merge-account-api.types';

class MergeAccountApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  async startMergeAccount({
    fromAccountGuid,
    toAccountGuid,
    excludedSystemsGuids,
    excludedUsersGuids,
  }: MergeAccountRequestDto): Promise<MergeAccountsResult> {
    try {
      const response = await this.httpClient.instance.put(
        `/accounts-identity-manager/accounts/merge/${fromAccountGuid}/to/${toAccountGuid}`,
        {
          excludeSystems: excludedSystemsGuids,
          excludeUsers: excludedUsersGuids,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`Failed to merge account: ${e.message}`);
    }
  }

  async getAllMergeProcs(): Promise<GetMergeProcsResponse> {
    try {
      const response =
        await this.httpClient.instance.get<GetMergeProcsResponse>(
          `/accounts-identity-manager/accounts/merge/processes`
        );

      return {
        ...response.data,
        procs: response.data.procs.map((proc) => ({
          ...proc,
          createdAt: proc.createdAt.toString(),
        })),
      };
    } catch (e) {
      throw new Error(`Failed to merge account: ${e.message}`);
    }
  }
}

export const mergeAccountApi = new MergeAccountApi(wmhttpClientSharedInstance);
export { MergeAccountsResult };
