import {
  ActionTypeDetails,
  SubscriptionStatusActionType,
  SubscriptionStatusType,
  SystemPurpose,
} from 'wm-accounts-sdk';
import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';
import { AccountWithStatus } from './accounts-subscription-api';

class AccountSystemManagerApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  async updateSystemPurpose(
    systemId: number,
    accountId: number,
    purpose: SystemPurpose
  ): Promise<boolean> {
    try {
      const response = await this.httpClient.instance.put(
        '/accounts-system-manager/updateSystemPurpose',
        {
          systemId: systemId,
          accountId: accountId,
          purpose: purpose,
        }
      );

      return response.data;
    } catch (e) {
      return false;
    }
  }

  async getSystemPurpose(systemId: number): Promise<SystemPurpose> {
    try {
      const response = await this.httpClient.instance.get(
        '/accounts-system-manager/system/' + systemId
      );
      return response.data?.purpose;
    } catch (e) {
      return null;
    }
  }

  async getSystemsLastAnalytics(
    systemGuids: string[],
    numOfLastMonths: number
  ) {
    try {
      const response = await this.httpClient.instance.post(
        '/accounts-system-manager/lastSystemsAnalytics',
        { systemGuids, numOfLastMonths }
      );
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export const accountSystemManagerApi = new AccountSystemManagerApi(
  wmhttpClientSharedInstance
);
