export enum PoweredByFields {
  DISPLAY_POWERED_BY = 'isDisplayPoweredBy',
  POWERED_BY_TXT = 'poweredByLink',
}

export const poweredBySelectorLabels = {
  isDisplayPoweredBy: 'Powered By WalkMe link',
  poweredByLink: 'Powered By WalkMe text',
};

export const poweredBySelectOptions = [
  {
    label: 'Enabled',
    value: true,
  },
  {
    label: 'Disabled',
    value: false,
  },
];
