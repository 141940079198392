import React from 'react';
import {
  SystemNameLabel,
  SystemSnippetEmailLabel,
  useStepStyles,
} from '../steps/wizard-steps.styles';
import { Tooltip } from '@walkme/ui-core';
import { SystemCardItems, SystemIconBackground, SystemIconWrapper } from './components.styles';

export interface SystemCardProps {
  system: {
    id: number;
    displayName: string;
    systemTypeKey: string;
    email: string;
    guid?: string;
  };
  key: any;
  iconUrl: string;
  isSelected: boolean;
  onClicked: (id: number) => void;
}

export const SystemCard = ({
  system,
  isSelected,
  iconUrl,
  key,
  onClicked,
}: SystemCardProps) => {
  const classes = useStepStyles();

  return (
    <div
      key={key}
      className={
        isSelected ? classes.selectedSystem : classes.notSelectedSystem
      }
      onClick={(item) => onClicked?.(system?.id)}
    >
      <SystemIconWrapper>
        <SystemIconBackground>
          <img src={iconUrl} width="20px" height="20px" />
        </SystemIconBackground>
      </SystemIconWrapper>
      <SystemCardItems>
        <SystemNameLabel>{system?.displayName}</SystemNameLabel>
        <Tooltip
          title={'Guid: ' + system?.guid}
          children={
            <SystemSnippetEmailLabel>{system?.guid}</SystemSnippetEmailLabel>
          }
        />
        <SystemSnippetEmailLabel>
          {system?.systemTypeKey}
        </SystemSnippetEmailLabel>
      </SystemCardItems>
    </div>
  );
};
