import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';

class DefaultFeaturesApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }
  errorParser(e) {
    if (
      e.response &&
      e.response.data &&
      e.response.data.errors &&
      e.response.data.errors.length > 0
    ) {
      return e.response.data.errors[0].message;
    }
    return e;
  }

  //   async addDefaultFeature(): Promise<FeatureFlagType[]> {
  //     try {
  //       const response = await this.httpClient.instance.get<FeatureFlagType[]>(
  //         `/accountsDefaultFeatures/addAccountDefaultFeature`
  //       );
  //       return response.data;
  //     } catch (e) {
  //       const error = this.errorParser(e);

  //       new Error(`failed to get featue types: ${error}`);
  //     }
  //   }
}
export const defaultFeaturesApi = new DefaultFeaturesApi(
  wmhttpClientSharedInstance
);
