import { AppData } from '../../../../general-types/src';
import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateAccountRequest,
  CreateAccountResponse,
  accountsGraphqlCaller,
  Accounts,
  apiErrorFormatter,
  PaginatedAccounts,
} from '@wm-accounts-backoffice-center/wm-api';
import { ThunkAction } from 'redux-thunk';
import rootReducer from 'apps/home/src/redux/rootReducer';

export interface AccountsSearchState {
  lastAllAccountsSearchInput: string;
  allAccountSearchIndex: number;
  copyAccountSearchIndex: number;
  allAccounts: AppData<{ accounts: Accounts; totalItems: number }>;
  accountCreation: AppData<CreateAccountResponse>;
  searchedAccountsForCopyAccount: AppData<Accounts>;
}

export const initialAccountsSearchState: AccountsSearchState = {
  lastAllAccountsSearchInput: '',
  allAccountSearchIndex: 0,
  copyAccountSearchIndex: 0,
  allAccounts: {
    loading: false,
    error: null,
    data: { accounts: [], totalItems: 0 },
  },
  searchedAccountsForCopyAccount: {
    loading: false,
    error: null,
    data: [],
  },
  accountCreation: {
    loading: false,
    error: null,
    data: null,
  },
};

const accountsSearchSlice = createSlice({
  name: 'accountsSearchSlice',
  initialState: initialAccountsSearchState,
  reducers: {
    allAccountsStart(state: AccountsSearchState) {
      state.allAccountSearchIndex++;
      state.allAccounts.error = null;
      state.allAccounts.loading = true;
      return state;
    },
    allAccountsSuccess(
      state: AccountsSearchState,
      action: PayloadAction<{ accounts: Accounts; totalItems: number }>
    ) {
      state.allAccounts.data = action.payload;
      state.allAccounts.loading = false;
      state.allAccounts.error = null;
      return state;
    },
    allAccountsFailed(state, action: PayloadAction<string>) {
      state.allAccounts.loading = false;
      state.allAccounts.error = action.payload;
      return state;
    },
    searchedAccountsForCopyAccountStart(state: AccountsSearchState) {
      state.copyAccountSearchIndex++;
      state.searchedAccountsForCopyAccount.error = null;
      state.searchedAccountsForCopyAccount.loading = true;
      return state;
    },
    searchedAccountsForCopyAccountSuccess(
      state: AccountsSearchState,
      action: PayloadAction<Accounts>
    ) {
      state.searchedAccountsForCopyAccount.data = action.payload;
      state.searchedAccountsForCopyAccount.loading = false;
      state.searchedAccountsForCopyAccount.error = null;
      return state;
    },
    searchedAccountsForCopyAccountFailed(state, action: PayloadAction<string>) {
      state.searchedAccountsForCopyAccount.loading = false;
      state.searchedAccountsForCopyAccount.error = action.payload;
      return state;
    },
    accountCreationStarted(state) {
      state.accountCreation.loading = true;
      state.accountCreation.error = null;
      state.accountCreation.data = null;
      return state;
    },
    accountCreationFailed(state, action: PayloadAction<string>) {
      state.accountCreation.loading = false;
      state.accountCreation.error = action.payload;
      state.accountCreation.data = null;
      return state;
    },
    accountCreationSuccess(
      state,
      action: PayloadAction<CreateAccountResponse>
    ) {
      state.accountCreation.loading = false;
      state.accountCreation.error = null;
      state.accountCreation.data = action.payload;
      return state;
    },
    accountCreationCleanup(state) {
      state.accountCreation.loading = false;
      state.accountCreation.error = null;
      state.accountCreation.data = null;
      return state;
    },
    setAllAccountsSearchInput(state, action: PayloadAction<string>) {
      state.lastAllAccountsSearchInput = action.payload;
      return state;
    },
  },
});

export { accountsSearchSlice };

const {
  allAccountsStart,
  allAccountsSuccess,
  allAccountsFailed,
  accountCreationStarted,
  accountCreationSuccess,
  accountCreationFailed,
  accountCreationCleanup,
  searchedAccountsForCopyAccountStart,
  searchedAccountsForCopyAccountSuccess,
  searchedAccountsForCopyAccountFailed,
  setAllAccountsSearchInput,
} = accountsSearchSlice.actions;

export type AccountsStateType = ReturnType<typeof rootReducer>;
type AppThunk = ThunkAction<void, AccountsStateType, unknown, Action<string>>;

export const getAccountsByQuery = async (
  query
): Promise<{ accounts: Accounts; totalItems: number }> => {
  return accountsGraphqlCaller.getAccountsByQuery(query);
};

export const getAllAccounts =
  (query): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(allAccountsStart());
      const searchIndex = getState().accountsSearchState.allAccountSearchIndex; // get search index for this search.

      const accountsResponse = await accountsGraphqlCaller.getAccountsByQuery(
        query
      );

      const newSearchIndex =
        getState().accountsSearchState.allAccountSearchIndex; // make sure no new search has started in the meantime
      if (searchIndex === newSearchIndex) {
        dispatch(allAccountsSuccess(accountsResponse));
      }
    } catch (err) {
      dispatch(allAccountsFailed(apiErrorFormatter(err)));
      return;
    }
  };

export const getAccountForCopyAccount =
  (query: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      dispatch(searchedAccountsForCopyAccountStart());
      const searchIndex = getState().accountsSearchState.copyAccountSearchIndex;
      if (!query) {
        dispatch(searchedAccountsForCopyAccountSuccess([]));
        return;
      }
      // get search index for this search.
      const accountsResponse = await accountsGraphqlCaller.getAccountsByQuery({
        limit: 50,
        order: 'ASC',
        orderBy: 'email',
        page: 1,
        filter: query,
        filterBy: 'id,guid,sfId,email,name',
      });
      const results = accountsResponse.accounts || [];
      const newSearchIndex =
        getState().accountsSearchState.copyAccountSearchIndex;
      if (searchIndex === newSearchIndex) {
        // make sure no other search has started
        dispatch(searchedAccountsForCopyAccountSuccess(results));
      }
    } catch (err) {
      dispatch(searchedAccountsForCopyAccountFailed(apiErrorFormatter(err)));
      return;
    }
  };

export const createAccount =
  (accountData: CreateAccountRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(accountCreationStarted());

      const account: CreateAccountResponse =
        await accountsGraphqlCaller.createAccount(accountData);

      dispatch(accountCreationSuccess(account));
    } catch (err) {
      dispatch(accountCreationFailed(apiErrorFormatter(err)));
    } finally {
      dispatch(accountCreationCleanup());
    }
  };
