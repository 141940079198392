import React, { useEffect } from 'react';
import { StyledSystemTheming } from './system-theming-section.styles';
import { Tooltip, Typography } from '@walkme/ui-core';
import { WMSnackbarVariant, WMSwitch } from '@walkme/wm-ui';
import react from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountState } from '@wm-accounts-backoffice-center/state-management-users';
import { featuresApi } from '@wm-accounts-backoffice-center/wm-api';
import { defaultfeatureSlice } from '@wm-accounts-backoffice-center/state-management-default-features';
import { autoSystemThemingFFName } from './system-theming-section.lib';

export const SystemThemingSection = () => {
  const [autoThemingIsEnabled, setAutoThemingIsEnabled] = react.useState(false);
  const accountById = useSelector((state: { accountState: AccountState }) => {
    return state.accountState.accountById;
  });
  const dispatch = useDispatch();

  const setDefaultAutoTheming = () => {
    const isAutoSystemThemingEnabled = accountById?.data?.featureFlags?.find(
      (featureFlag) => featureFlag.flagName === autoSystemThemingFFName
    );
    setAutoThemingIsEnabled(!!isAutoSystemThemingEnabled);
  };

  const onAutoThemingChange = async (e) => {
    const checked = e.target.checked;
    setAutoThemingIsEnabled(checked);
    try {
      if (checked) {
        await featuresApi.addFeatureFlagNamesForAccount(accountById?.data?.id, [
          autoSystemThemingFFName,
        ]);
      } else {
        await featuresApi.removeFeatureFlagNamesFromAccount(
          accountById?.data?.id,
          [autoSystemThemingFFName]
        );
      }

      dispatch(
        defaultfeatureSlice.actions.setNotificationMessage({
          text: 'Successfully updated auto theming',
          variant: WMSnackbarVariant.Success,
          isOpen: true,
        })
      );
    } catch (e) {
      setAutoThemingIsEnabled(!checked);
      dispatch(
        defaultfeatureSlice.actions.setNotificationMessage({
          text: 'Failed to update auto theming',
          variant: WMSnackbarVariant.Error,
          isOpen: true,
        })
      );
      console.error('Failed to update auto theming', e);
    }
  };

  useEffect(setDefaultAutoTheming, []);

  return (
    <StyledSystemTheming>
      <div className="title">
        <Typography variant="T20-2">Theming enablement</Typography>
        <Tooltip
          title="Theming enablement let you enable theming automatically for any newly created system"
          arrow
        >
          <img alt={'more'} src={`/assets/icons/icon-info.svg`} />
        </Tooltip>
      </div>
      <div className="enable-auto-theming">
        <WMSwitch
          checked={autoThemingIsEnabled}
          onChange={onAutoThemingChange}
        />
        <div>Enable theming automatically for any newly created system</div>
      </div>
    </StyledSystemTheming>
  );
};
