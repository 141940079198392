import { Typography } from '@material-ui/core';
import React from 'react';

export interface UnsupportedErrorPageProps {
  title?: string;
  whyText: string;
  whatToDoText?: string;
}

export const UnsupportedErrorPage = ({
  title = 'Content is unavailable for current user',
  whyText,
  whatToDoText = 'Please contact support for help.',
}: UnsupportedErrorPageProps) => {
  return (
    <div>
      <Typography variant="h4" style={{ textAlign: 'center', margin: '30px' }}>
        {title}
      </Typography>
      <Typography
        variant="h6"
        style={{ textAlign: 'center', marginTop: '30px' }}
      >
        {whyText}
      </Typography>
      <Typography
        variant="h6"
        style={{ textAlign: 'center', marginBottom: '30px' }}
      >
        {whatToDoText}
      </Typography>
    </div>
  );
};
