import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@walkme/ui-core';
import ReactJson from 'react-json-view';
import { WMSnackbarVariant } from '@walkme/wm-ui/components/WMSnackbar';
import { WMSnackbar } from '@walkme/wm-ui';
import styled from 'styled-components';

const CustomDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 80%;
    max-width: none;
  }
`;

interface CustomDialogProps {
  onClose: () => void;
  selectedWebhook: any;
}

const WebhookView: React.FC<CustomDialogProps> = ({
  onClose,
  selectedWebhook,
}) => {
  const [copyStatus, setCopyStatus] = useState<any>({
    isOpen: false,
    variant: undefined,
    text: undefined,
  });
  const [jsonError, setJsonError] = useState(null);

  const validateAndCopyJson = (src) => {
    try {
      const jsonString = JSON.stringify(src, null, 2);
      JSON.parse(jsonString); // This will throw an error if JSON is invalid
      navigator.clipboard.writeText(jsonString).then(() => {
        setCopyStatus({
          isOpen: true,
          variant: WMSnackbarVariant.Success,
          text: 'JSON copied to clipboard',
        });
      });
      setJsonError(null);
    } catch (error) {
      setJsonError('Invalid JSON: ' + error.message);
      setCopyStatus({
        isOpen: true,
        variant: WMSnackbarVariant.Error,
        text: 'Invalid JSON: ' + error.message,
      });
    }
  };

  return (
    <>
      <CustomDialog isOpen={!!selectedWebhook} onClose={onClose}>
        <DialogTitle>Webhook Raw JSON</DialogTitle>
        <DialogContent>
          {selectedWebhook && (
            <>
              <ReactJson
                src={selectedWebhook}
                theme="monokai"
                enableClipboard={(copy) => validateAndCopyJson(copy.src)}
              />
              {jsonError && (
                <div style={{ color: '#ff6b6b', marginTop: '10px' }}>
                  {jsonError}
                </div>
              )}
            </>
          )}
        </DialogContent>
      </CustomDialog>

      <WMSnackbar
        open={copyStatus.isOpen}
        onClose={() =>
          setCopyStatus({ isOpen: false, variant: undefined, text: undefined })
        }
        variant={copyStatus.variant}
        message={copyStatus.text}
      />
    </>
  );
};

export default WebhookView;
