import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@walkme/ui-core';
import {
  StyledChangeStateButton,
  StyledDialogContent,
  StyledInput,
} from './feature-toggles-page.styles';
import {
  createOwnerTeam,
  featureTogglesSlice,
  FeatureTogglesState,
} from '../../../../state-management-feature-toggles/src';

export interface CreateTeamDialogProps {
  isOpen: boolean;
}

export const CreateTeamDialog = ({ isOpen }: CreateTeamDialogProps) => {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>('');
  const createTeamAppData = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.createOwnerTeam
  );

  const onCancel = () => {
    dispatch(featureTogglesSlice.actions.setCreateTeamDialogOpen(false));
  };

  const onConfirm = async () => {
    dispatch(createOwnerTeam(name));
  };

  return (
    <Dialog isOpen={isOpen} onClose={onCancel}>
      <DialogTitle>Create New Team</DialogTitle>
      <StyledDialogContent>
        <DialogContentText>Team Name</DialogContentText>
        <StyledInput
          value={name}
          placeholder={'Enter a name'}
          onChange={(e) => setName(e.target.value)}
          maxlength={100}
        />
      </StyledDialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <StyledChangeStateButton
          onClick={onConfirm}
          loading={createTeamAppData.loading}
        >
          Create
        </StyledChangeStateButton>
      </DialogActions>
    </Dialog>
  );
};
