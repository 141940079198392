import React from 'react';
import { Tooltip } from '@walkme/ui-core';

const UrlCellRenderer = (props) => {
  const { value } = props;

  return (
    <Tooltip title={value} arrow>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {value}
        </span>
      </div>
    </Tooltip>
  );
};

export default UrlCellRenderer;
