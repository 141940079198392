import axios, { AxiosInstance } from 'axios';
import { WMAuthManager } from 'wm-accounts-auth';

export interface IAccessTokenGetter {
  getUserToken: () => Promise<string>;
  getAuthorizationHeaderValue: () => Promise<string>;
}

export class WMhttpClient {
  baseUrl: string;
  tokenGetter: IAccessTokenGetter;
  readonly instance: AxiosInstance;
  constructor(baseUrl: string, accssTokenGetter?: IAccessTokenGetter) {
    // use default authManager if interface not provided
    this.tokenGetter = accssTokenGetter || {
      async getUserToken(): Promise<string> {
        return await WMAuthManager.getInstance().getUserTokenAsync();
      },
      async getAuthorizationHeaderValue(): Promise<string> {
        const accessTokenAsync =
          await WMAuthManager.getInstance().getUserTokenAsync();
        return `Bearer ${accessTokenAsync}`;
      },
    };
    this.baseUrl = baseUrl;
    this.instance = axios.create({
      baseURL: this.baseUrl,
    });

    // this.initializeResponseInterceptor();
    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor() {
    this.instance.interceptors.request.use(
      async (config) => {
        const accessToken = await this.tokenGetter.getUserToken();
        if (accessToken) {
          config.headers['Authorization'] =
            await this.tokenGetter.getAuthorizationHeaderValue();
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }
}

export const wmhttpClientSharedInstance = new WMhttpClient(
  window.clientConfig.NX_WM_API_URL
);
export const wmEditorClientSharedInstance = new WMhttpClient(
  window.clientConfig.NX_WM_EDITOR_URL
);
