import React, { createContext, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { MergeAccountPage } from './main-page/merge-account-page';
import { mergeAccountStore } from './merge-account.store';
import { HttpServiceProvider } from './contexts/http-service.context';
import { WMhttpClient } from 'libs/wm-api/src/lib/wm-http-client';
import { SystemIconGeneratorProvider } from './contexts/system-icon-generator.context';

export type SystemIconUrlGetter = (systemId: number) => string;
export interface MergeAccountProps {
  wmHttpClient: WMhttpClient;
  generateSystemIconUrl: SystemIconUrlGetter;
}

export const MergeAccountWrapper = ({
  wmHttpClient,
  generateSystemIconUrl,
}: MergeAccountProps) => {
  return (
    <HttpServiceProvider service={wmHttpClient}>
      <SystemIconGeneratorProvider iconUrlGenerator={generateSystemIconUrl}>
        <Provider store={mergeAccountStore}>
          <MergeAccountPage />
        </Provider>
      </SystemIconGeneratorProvider>
    </HttpServiceProvider>
  );
};
