import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';
type DefaultIdpSettings = {
  providerId: number | null;
  providerName: string;
  enforceSso: boolean;
  previousSettings: {
    providerId: number | null;
    providerName: string;
    enforceSso: boolean;
  };
};

class IdpApi {
  private readonly httpClient: WMhttpClient;
  private readonly path: string;

  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
    this.path = '/ic/idp/bo';
  }

  errorParser(e) {
    return e.response?.data?.errors?.[0]?.message || e;
  }

  async getAccountIdpProviders(accountGuid: string) {
    try {
      const providersRes = await this.httpClient.instance.get(
        `${this.path}/accounts/${accountGuid}/providers`
      );

      return providersRes?.data;
    } catch (e) {
      const error = this.errorParser(e);

      throw new Error(`failed to get account idp providers: ${error}`);
    }
  }

  async getAccountDefaultIdpProvider(accountGuid: string) {
    try {
      const defaultProvidersRes = await this.httpClient.instance.get(
        `${this.path}/accounts/${accountGuid}`
      );

      return defaultProvidersRes?.data;
    } catch (e) {
      const error = this.errorParser(e);

      throw new Error(`failed to get account default idp provider: ${error}`);
    }
  }

  async updateAccountDefaultIdpProvider(
    accountGuid: string,
    defaultSettings: DefaultIdpSettings
  ) {
    try {
      const defaultProvidersRes = await this.httpClient.instance.patch(
        `${this.path}/accounts/${accountGuid}`,
        defaultSettings
      );

      return defaultProvidersRes?.data;
    } catch (e) {
      const error = this.errorParser(e);

      throw new Error(`failed to get account default idp provider: ${error}`);
    }
  }
}

export const idpApi = new IdpApi(wmhttpClientSharedInstance);
