import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import {
  AccountsSdk,
  System,
  Systems,
  SystemsSettings,
  UniqueIdentifier,
  WmFetchPolicy,
  SystemTypeKey,
  systemTypes,
  SubscriptionStatusType,
  SubscriptionStatusActionType,
  ActionTypeDetails,
} from 'wm-accounts-sdk';
import { AppData } from '../../../../general-types/src';
import { ThunkAction } from 'redux-thunk';
import * as util from 'util';
import { apiErrorFormatter } from '../../../../wm-api/src';

export interface SystemsState {
  updateSystemsStatus: AppData<System[]>;
  systems: AppData<Systems>;
  systemTypes: AppData<systemTypes[]>;
}

const initalSystemState: System = {
  displayName: '',
};

export const initalUpdatedSystemState: System = {
  id: null,
  guid: null,
  type: null,
  displayName: null,
};

export const initialSystemsState: SystemsState = {
  systems: {
    data: [],
    error: null,
    loading: false,
  },
  systemTypes: {
    data: [],
    error: null,
    loading: false,
  },
  updateSystemsStatus: {
    data: [],
    error: null,
    loading: false,
  },
};

const systemsSlice = createSlice({
  name: 'systemsSlice',
  initialState: initialSystemsState,
  reducers: {
    getSystemsStart(state: SystemsState) {
      state.systems.error = null;
      state.systems.loading = true;
      return state;
    },
    getSystemsSuccess(state: SystemsState, action: PayloadAction<Systems>) {
      state.systems.data = action.payload;
      state.systems.loading = false;
      state.systems.error = null;
      return state;
    },
    getSystemsFailed(state, action: PayloadAction<string>) {
      state.systems.loading = false;
      state.systems.error = action.payload;
      return state;
    },
    getSystemTypesStart(state: SystemsState) {
      state.systemTypes.error = null;
      state.systemTypes.loading = true;
      return state;
    },
    getSystemTypesSuccess(
      state: SystemsState,
      action: PayloadAction<systemTypes[]>
    ) {
      state.systemTypes.data = action.payload;
      state.systemTypes.loading = false;
      state.systemTypes.error = null;
      return state;
    },
    getSystemTypesFailed(state, action: PayloadAction<string>) {
      state.systemTypes.loading = false;
      state.systemTypes.error = action.payload;
      return state;
    },
    updateSystemsStatusStart: (state: SystemsState) => {
      state.updateSystemsStatus.error = null;
      state.updateSystemsStatus.loading = true;
      state.updateSystemsStatus.data = [];
      return state;
    },
    updateSystemsStatusSuccess(
      state: SystemsState,
      action: PayloadAction<System[]>
    ) {
      state.updateSystemsStatus.data = action.payload;
      state.updateSystemsStatus.loading = false;
      state.updateSystemsStatus.error = null;
      return state;
    },
    updateSystemsStatusFailed(state, action: PayloadAction<string>) {
      state.updateSystemsStatus.error = action.payload;
      state.updateSystemsStatus.loading = false;
      return state;
    },
    cleanupUpdateSystemsStatusStart: (state: SystemsState) => {
      state.updateSystemsStatus.data = [];
      state.updateSystemsStatus.error = null;
      state.updateSystemsStatus.loading = false;
      return state;
    },
  },
});

export { systemsSlice };
const {
  getSystemsFailed,
  getSystemsStart,
  getSystemsSuccess,
  getSystemTypesFailed,
  getSystemTypesStart,
  getSystemTypesSuccess,
  updateSystemsStatusStart,
  updateSystemsStatusFailed,
  updateSystemsStatusSuccess,
  cleanupUpdateSystemsStatusStart,
} = systemsSlice.actions;
export type rootReducerType = ReturnType<typeof systemsSlice.reducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

export const getSystems =
  (forceLoad?: boolean, filterByTypes?: SystemTypeKey[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getSystemsStart());
      let systems: Systems = await AccountsSdk.getInstance().getAccountSystems(
        forceLoad ? WmFetchPolicy.network : WmFetchPolicy.cache,
        filterByTypes
      );
      dispatch(getSystemsSuccess(systems));
    } catch (err) {
      dispatch(getSystemsFailed(apiErrorFormatter(err)));
      return;
    }
  };

export const getSystemTypes = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getSystemTypesStart());
    const systemTypesData =
      await AccountsSdk.getInstance().systemsManagement.getSystemTypes();
    dispatch(getSystemTypesSuccess(systemTypesData));
  } catch (err) {
    dispatch(getSystemTypesFailed(apiErrorFormatter(err)));
    return;
  }
};

export const updateSystemsStatus =
  (
    systemsGuids: string[],
    statusType: SubscriptionStatusType,
    canBeRestored: boolean,
    actionType: SubscriptionStatusActionType,
    actionTypeDetails: ActionTypeDetails
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateSystemsStatusStart());
      const updatedSystem: System[] =
        await AccountsSdk.getInstance().backofficeAccountsSystems.updateSystemStatus(
          systemsGuids,
          canBeRestored,
          statusType,
          actionType,
          actionTypeDetails
        );
      dispatch(updateSystemsStatusSuccess(updatedSystem));
    } catch (err) {
      dispatch(updateSystemsStatusFailed(err.message));
      return;
    }
  };
