import React, { useEffect, useMemo } from 'react';
import { MainDiv } from './merge-account-page.styles';
import MergeAccountWizard from './wizard/merge-account-wizard';
import { useDispatch, useSelector } from 'react-redux';
import { showWizard } from './wizard/merge-account-wizard.slice';
import { MergeAccountState } from '../merge-account.store';
import { PageInitialDisplay } from './page-display-options/page-initial-display';
import { PageLoadingDisplay } from './page-display-options/page-loading-display';
import { PageSummaryDisplay } from './page-display-options/page-summary-display';
import { PageFailureDisplay } from './page-display-options/page-failure-display';
import { ConfirmationDialog } from './confirmation/confirmation-dialog';
import { mergeRequestFinished, resetMergeData } from '../merge-data.slice';

import { mergeAccountMockResult } from './merge-account-mock-result';

export const MergeAccountPage = () => {
  const isWizardVisible = useSelector(
    (state: MergeAccountState) => state.wizard?.isWizardVisible || false
  );

  const isConfirmationDialogVisible = useSelector(
    (state: MergeAccountState) => state.confimation?.isDialogVisible || false
  );

  const mergeRequstStatus = useSelector(
    (state: MergeAccountState) =>
      state.mergeData.mergeRequestStatus || {
        loading: false,
        result: null,
        error: null,
      }
  );

  const dispatch = useDispatch();

  // useEffect(() => {dispatch(mergeRequestFinished(mergeAccountMockResult));}, [dispatch]);

  const onStartMergeClicked = () => {
    dispatch(resetMergeData());
    dispatch(showWizard());
  };

  const selectedDisplay = useMemo(
    function selectDislpay() {
      if (mergeRequstStatus.loading) {
        return <PageLoadingDisplay />;
      }

      if (mergeRequstStatus.result) {
        return <PageSummaryDisplay onStartMergeClicked={onStartMergeClicked} />;
      }

      if (mergeRequstStatus.error) {
        return <PageFailureDisplay onStartMergeClicked={onStartMergeClicked} />;
      }

      return <PageInitialDisplay onStartMergeClicked={onStartMergeClicked} />;
    },
    [mergeRequstStatus]
  );

  return (
    <MainDiv>
      {isWizardVisible && <MergeAccountWizard />}
      {isConfirmationDialogVisible && <ConfirmationDialog />}
      {selectedDisplay}
    </MainDiv>
  );
};
