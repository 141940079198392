import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';
import { createLogger } from 'redux-logger';

const logMiddleware = createLogger();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (window.clientConfig['NX_WM_DEBUG_LOG'] === 'TRUE') {
      return getDefaultMiddleware().concat(logMiddleware);
    }
    return getDefaultMiddleware();
  },
});

export default store;
