import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Account } from '@wm-accounts-backoffice-center/wm-api';
import { MenuItemText, MenuItemEndIcon } from '@walkme/ui-core';

const useStyles = makeStyles(() => ({
  menu: {
    gap: '10px',
  },
}));

export enum CopyAccountOption {
  SameDc = 'SameDc',
  Export = 'Export',
  Import = 'Import',
}

export interface CopyAccountMenuProps {
  anchorEl: HTMLElement;
  onItemClicked: (copyAccountOption: CopyAccountOption) => void;
  onClose: () => void;
}

const CopyAccountMenu = ({
  anchorEl,
  onItemClicked,
  onClose,
}: CopyAccountMenuProps) => {
  const classes = useStyles();
  return (
    <Menu
      anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      transformOrigin={{ horizontal: 'right', vertical: 'center' }}
      open={Boolean(anchorEl)}
      onClose={() => {
        onClose();
      }}
      anchorEl={anchorEl}
    >
      <MenuItem
        key={'Copy content'}
        onClick={(e) => {
          onItemClicked(CopyAccountOption.SameDc);
          onClose();
        }}
      >
        <MenuItemText>Copy content</MenuItemText>
        <MenuItemEndIcon>
          <Tooltip
            title={
              'Copy entire systems or specific deployables between systems or accounts of the same data center'
            }
            children={<img alt={'more'} src={`/assets/icons/icon-info.svg`} />}
          />
        </MenuItemEndIcon>
      </MenuItem>
      <MenuItem
        key={'Export content'}
        onClick={(e) => {
          onItemClicked(CopyAccountOption.Export);
          onClose();
        }}
      >
        <MenuItemText>Export content</MenuItemText>
        <MenuItemEndIcon>
          <Tooltip
            title={
              'Export entire systems or specific deployables to different data centers'
            }
            children={<img alt={'more'} src={`/assets/icons/icon-info.svg`} />}
          />
        </MenuItemEndIcon>
      </MenuItem>
      <MenuItem
        key={'Import content'}
        onClick={(e) => {
          onItemClicked(CopyAccountOption.Import);
          onClose();
        }}
        className={classes.menu}
      >
        <MenuItemText>Import content</MenuItemText>
        <MenuItemEndIcon>
          <Tooltip
            title={
              'Import entire systems or specific deployables from a different data center'
            }
            children={<img alt={'more'} src={`/assets/icons/icon-info.svg`} />}
          />
        </MenuItemEndIcon>
      </MenuItem>
    </Menu>
  );
};

export default CopyAccountMenu;
