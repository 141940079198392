import * as React from 'react';
import { SVGProps } from 'react';

const SvgAccountManagementIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.625 14.375A5.628 5.628 0 0 1 8.458 9.2M6.25 7.5a3.438 3.438 0 1 0 0-6.875 3.438 3.438 0 0 0 0 6.875ZM14.375 19.375a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={14.5} cy={12.5} r={0.5} fill="currentColor" />
    <path stroke="currentColor" strokeLinecap="round" d="M14.5 14.5V17" />
  </svg>
);

export default SvgAccountManagementIcon;
