import React from 'react';
import {
  MergeAccountToOtherAccountExtraDetailsDTO,
  MergeAccountsResult,
} from '../api/merge-account-api.types';
import {
  AccountName,
  SummaryContentDiv,
  SummaryDivider,
  SummaryHeader,
} from './merge-summary.styles';
import { RolesRecreatdSummary } from './merge-summary-sections/roles-recreated-summary.section';
import { UnassignedUsersSummary } from './merge-summary-sections/unassigned-users-summary.section';
import { UnassignedSystemsSummary } from './merge-summary-sections/unassigned-systems-summary.section';
import { RolesSummary } from './merge-summary-sections/roles-summary.section';
import { UsersSummary } from './merge-summary-sections/users-summary.section';
import { SystemsSummary } from './merge-summary-sections/systems-summary.section';

export interface MergeSummaryProps {
  mergeAccountResult: MergeAccountsResult;
}

export const MergeSummary = ({ mergeAccountResult }: MergeSummaryProps) => {
  const sourceAccountName = mergeAccountResult?.fromAccountData?.name;
  const targetAccountName = mergeAccountResult?.toAccountData?.name;

  return (
    <SummaryContentDiv>
      <SummaryHeader>
        From Account:&nbsp;<AccountName>{sourceAccountName}</AccountName>
      </SummaryHeader>
      <SummaryHeader>
        To Account:&nbsp;<AccountName>{targetAccountName}</AccountName>
      </SummaryHeader>
      <SummaryDivider />
      <SystemsSummary systemsResult={mergeAccountResult?.systems} />
      <SummaryDivider />
      <UsersSummary usersResult={mergeAccountResult?.users} />
      <SummaryDivider />
      <RolesSummary rolesResult={mergeAccountResult?.roles} />
      <SummaryDivider />
      <UnassignedSystemsSummary
        excludedSystemsResult={mergeAccountResult?.unassignExcludedSystems}
      />
      <SummaryDivider />
      <UnassignedUsersSummary
        excludedUsersResult={mergeAccountResult?.unassignExcludedUsers}
      />
      <SummaryDivider />
      <RolesRecreatdSummary
        rolesRecreatedResult={mergeAccountResult?.recreatedRoles}
      />
    </SummaryContentDiv>
  );
};
