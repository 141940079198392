import {
  MenuAction,
  WMDataGrid,
  WMDataGridActionColumn,
  WMStatusDot,
  WMTagList,
  WMTagProps,
  WMTagVariant,
  WMTooltip,
} from '@walkme/wm-ui';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TitleTab, SubTitleTab } from '../styles/styles';
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { User } from 'wm-accounts-sdk';
import { StatusMapData } from './users-status';
import UserResetPassword from './users-reset-password';
import UserResendConfirmationMail from './users-resend-confirmation-mail';
import GenerateUserTempPassword from './users-temp-password';

export interface AccountUsersPageProps {
  users: User[];
  accountId: number;
  hideTitle?: boolean;
}
const useStyles = makeStyles(() => ({
  search: {
    '.WMSearchFilter-clearButton': {
      top: '20%',
    },
  },
  remove: {
    borderRadius: '40px !important',
    border: '1px solid rgba(235, 50, 50, 0.2)',
    '& .MuiButton-label': {
      color: '#EB3232',
      '&:hover': {
        color: '#EB3232',
      },
    },
  },
}));

export const TableHeader = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
`;

const getUsersRows = (users: User[]): any[] => {
  if (!users) {
    return [];
  }

  return users.map((item) => {
    const {
      email,
      firstName,
      lastName,
      role,
      systems,
      lastLoginDate,
      id,
      status,
      attachedIdpId,
    } = item;
    const { displayName: roleName } = role || {};
    const timestamp = lastLoginDate ? new Date(lastLoginDate).getTime() : 0;
    const loginMethod = (() => {
      return attachedIdpId ? 'SSO' : 'Password';
    })();
    const result = {
      id,
      email: email,
      name: `${firstName} ${lastName}`,
      role: roleName,
      system: systems || [],
      loginMethod: loginMethod,
      lastLogin: timestamp,
      status: status,
    };
    return result;
  });
};

export interface TableCustomCell {
  value: string;
  displayValue: JSX.Element;
}

export interface UserRow {
  email: TableCustomCell;
  name: TableCustomCell;
  role: TableCustomCell;
  system?: TableCustomCell;
  lastLogin: TableCustomCell;
  loginMethod: TableCustomCell;
  status: TableCustomCell;
  id: number;
}

export function AccountUsersPage({
  accountId,
  users,
  hideTitle = false,
}: AccountUsersPageProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState<User>(null);
  const [selectedUserAction, setSelectedUserAction] = useState<string>('');

  const MenuIcon: JSX.Element = (
    <img src={`/assets/icons/icon-menu.svg`} width="20px" height="20px" />
  );

  const actions: MenuAction<User>[] = [
    {
      label: 'Resend confirmation mail',
      icon: <></>,
      onClick: (data) => {
        setSelectedUser(data);
        setSelectedUserAction('ResendConfirmation');
      },
      isVisible: (data) => data.status === 'PROVISIONED',
    },
    {
      label: 'Send Reset password mail',
      icon: <></>,
      onClick: (data) => {
        setSelectedUser(data);
        setSelectedUserAction('ResetPassword');
      },
      isVisible: (data) => data.status !== 'PROVISIONED',
    },
    {
      label: 'Set temporary password',
      icon: <></>,
      onClick: (data) => {
        setSelectedUser(data);
        setSelectedUserAction('TempPassword');
      },
      isVisible: (data) => data.status !== 'PROVISIONED',
    },
  ];

  const headCells = useMemo(() => {
    return [
      {
        lockVisible: true,
        pinned: true,
        checkboxSelection: false,
        headerCheckboxSelection: false,
        headerName: 'Email',
        field: 'email',
        sortable: true,
        minWidth: 250,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        minWidth: 200,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'User ID',
        field: 'id',
        sortable: true,
        minWidth: 100,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'Role',
        field: 'role',
        minWidth: 200,
        filter: true,
        sortable: true,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'System',
        field: 'system',
        minWidth: 250,
        cellStyle: { display: 'flex' },
        cellRenderer: function (params) {
          const systemsLabels: WMTagProps[] =
            params.value && params.value.length > 0
              ? params.value.map((system) => ({
                  labelText: system.displayName,
                  fullLabel: true,
                  variant: WMTagVariant.Comet,
                }))
              : [];
          return <WMTagList labels={systemsLabels} maxTagsShown={3} />;
        },
      },
      {
        headerName: 'Login Method',
        field: 'loginMethod',
        sortable: true,
        minWidth: 150,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'Last Login',
        field: 'lastLogin',
        sortable: true,
        minWidth: 150,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
        cellRenderer: function (params) {
          const lastLoginTimestamp: number = params.value;
          const time =
            (lastLoginTimestamp &&
              moment(lastLoginTimestamp).format('MMM Do YYYY H:mm')) ||
            'N/A';
          return time;
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        sortable: true,
        minWidth: 150,
        cellRenderer: function (params) {
          const mappedStatus = StatusMapData.find(
            (mappedStatus) => mappedStatus.id == params.value
          );
          return (
            <WMTooltip title={mappedStatus.info}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {mappedStatus && <WMStatusDot variant={mappedStatus.color} />}
                  <span style={{ fontSize: '12px', marginLeft: '4px' }}>
                    {mappedStatus ? mappedStatus.display : 'N/A'}
                  </span>
                </div>
              </div>
            </WMTooltip>
          );
        },
      },
      {
        colId: 'actions-column',
        pinned: 'right',
        suppressSizeToFit: true,
        width: 120,
        sortable: true,
        filter: false,
        cellRenderer: WMDataGridActionColumn,
        cellRendererParams: {
          actions: actions,
          icon: MenuIcon,
        },
      },
    ];
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <GenerateUserTempPassword
        onClose={() => {
          setSelectedUser(null);
          setSelectedUserAction('');
        }}
        user={selectedUser}
        isOpen={selectedUserAction === 'TempPassword'}
      ></GenerateUserTempPassword>
      <UserResendConfirmationMail
        accountId={accountId}
        onClose={() => {
          setSelectedUser(null);
          setSelectedUserAction('');
        }}
        user={selectedUser}
        isOpen={selectedUserAction === 'ResendConfirmation'}
      ></UserResendConfirmationMail>
      <UserResetPassword
        accountId={accountId}
        onClose={() => {
          setSelectedUser(null);
          setSelectedUserAction('');
        }}
        user={selectedUser}
        isOpen={selectedUserAction === 'ResetPassword'}
      ></UserResetPassword>
      {!hideTitle && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '24px',
          }}
        >
          <TitleTab>Account Users</TitleTab>
          <SubTitleTab>View account users details</SubTitleTab>
        </div>
      )}
      <div style={{ height: 'calc(100% - 72px)' }}>
        <WMDataGrid
          columnDefs={headCells as any}
          rows={getUsersRows(users)}
          pagination={false}
          title={<TableHeader>Total Users {users && users.length}</TableHeader>}
          entityName={'Users'}
          hideColumnPicker
          hideExport
        />
      </div>
    </div>
  );
}

export default AccountUsersPage;
