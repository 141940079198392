import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeployablesState,
  getSystemsDeployablesCount,
} from '../redux/deployables.slice';

export const useDeployables = (systemIds: number[], forceLoad = false) => {
  const systemsDeployablesCountAppData = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.systemsDeployablesCount
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSystemsDeployablesCount(systemIds, forceLoad));
  }, [dispatch, forceLoad, systemIds]);

  return {
    systemsDeployablesCountAppData,
  };
};

export default useDeployables;
