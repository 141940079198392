import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@walkme/ui-core';
import ReactJson from 'react-json-view';
import { WebHook } from './state-management-webhooks/src';
import { WMLoader } from '@walkme/wm-ui';
import { webHooksApi } from '../../../../../wm-api/src/lib/webhooks-api';

const CustomDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 60%;
    max-width: none;
  }
`;

const Table = styled.table`
  background-color: white;
  color: black;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    color: black;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #ddd;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  background-color: #f2f2f2;
  color: black;
  padding: 8px;
  border: 1px solid #ddd;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #ddd;
  }
`;

const TableCell = styled.td`
  padding: 15px;
  border-top: 1px solid #e1f2f3;
  border-bottom: 1px solid #e1f2f3;

  &:first-child {
    border-left: 1px solid #e1f2f3;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-right: 1px solid #e1f2f3;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const CenteredCell = styled(TableCell)`
  text-align: center;
`;

const CenteredHeader = styled(TableHeader)`
  text-align: center;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
`;

interface WebhookEvenInvestigatorProps {
  onClose?: () => void;
  webhook: WebHook;
}

const fetchMessages = async (
  eventName: string,
  setMessages: React.Dispatch<React.SetStateAction<any[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  signal: AbortSignal
) => {
  try {
    const messages = await webHooksApi.getEventLastMessages(eventName, signal);

    setMessages(messages);
    setLoading(false);
    setModalIsOpen(true);
  } catch (err) {
    setError(err.message);
    setLoading(false);
  }
};

const analyzeMessagesContent = (
  messages: any[],
  setContentKeys: React.Dispatch<React.SetStateAction<any>>
) => {
  const keys = {};
  messages.forEach((message) => {
    Object.entries(message).forEach(([key, value]) => {
      if (!keys[key]) {
        keys[key] = new Set();
      }
      keys[key].add(typeof value);
      if (Array.isArray(value)) {
        keys[key].add('array');
      }
    });
  });

  // Convert Sets to Arrays for easier rendering
  Object.keys(keys).forEach((key) => {
    keys[key] = Array.from(keys[key]);
  });

  setContentKeys(keys);
};

const WebhookEventInvestigator: React.FC<WebhookEvenInvestigatorProps> = ({
  onClose,
  webhook,
}) => {
  const eventName = webhook.eventName;
  const [messages, setMessages] = useState([]);
  const [contentKeys, setContentKeys] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [messagesViewIsVisible, setMessagesViewIsVisible] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetchMessages(
      eventName,
      setMessages,
      setLoading,
      setModalIsOpen,
      setError,
      signal
    );

    return () => {
      abortController.abort();
    };
  }, [eventName]);

  useEffect(() => {
    if (messages.length > 0) {
      analyzeMessagesContent(messages, setContentKeys);
    }
  }, [messages]);

  const closeModal = () => {
    setModalIsOpen(false);
    onClose?.();
  };

  const data = Object.entries(contentKeys).map(
    (item: [string, string[]], index) => ({
      index: index + 1,
      propertyName: item[0],
      propertyType: item[1]?.join(': '),
    })
  );

  return (
    <>
      <CustomDialog
        isOpen={modalIsOpen}
        onClose={closeModal}
        contentLabel="Event Investigation"
      >
        <DialogTitle>Event Investigation</DialogTitle>

        <DialogContent>
          {loading ? (
            <CenteredContainer>
              <p>Investigating messages...</p>
              <WMLoader style={{ width: '30%', maxWidth: '300px' }} />
            </CenteredContainer>
          ) : (
            <>
              {messages.length > 0 ? (
                <>
                  <h4>Properties found on last {messages.length} messages</h4>
                  <Table>
                    <thead>
                      <tr>
                        <CenteredHeader>#</CenteredHeader>
                        <TableHeader>Property Name</TableHeader>
                        <TableHeader>Property Type</TableHeader>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <TableRow key={index}>
                          <CenteredCell>{item.index}</CenteredCell>
                          <TableCell>{item.propertyName}</TableCell>
                          <TableCell>{item.propertyType}</TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <p>No messages found for {eventName} event yet</p>
              )}

              {messages && messagesViewIsVisible && (
                <div className="border rounded p-2">
                  <h3>Last Messages Content:</h3>
                  <ReactJson src={messages} theme="monokai" />
                </div>
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => setMessagesViewIsVisible(!messagesViewIsVisible)}
          >
            {messagesViewIsVisible ? 'Hide Messages' : 'Show Messages'}
          </Button>
          <Button onClick={closeModal}>Close</Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default WebhookEventInvestigator;
