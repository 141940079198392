import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { AccountsSdk, Role, User, Users } from 'wm-accounts-sdk';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { WMDivider, WMSelect } from '@walkme/wm-ui';
import {
  Button,
  InputSearch,
  Loader,
  Spinner,
  ToasterVariant,
  useToaster,
} from '@walkme/ui-core';
import {
  EmptyLabel,
  SubTitleTab,
} from '@wm-accounts-backoffice-center/shared-styles';
import {
  AccountState,
  getBackofficeRoles,
} from '@wm-accounts-backoffice-center/state-management-users';
import { accessControlApi } from '@wm-accounts-backoffice-center/wm-api';
import NotBackofficeErrorMessage from './not-backoffice-error';
import { Card, Title } from './backoffice-admins-styles';

export const SubTitle = styled('label')`
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #2f426c;
`;

const MiniTitle = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
  margin-bottom: 10px;
`;

const IdLabel = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #637191;
`;

const EmailLabel = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2f426c;
`;

const OptionCard = styled.div`
  border: 1px solid #ecf1fa;
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  border-radius: 8px;
  margin-right: 10px;
  &:hover {
    border: 1px solid #93a8f4;
  }
`;

const useStyles = makeStyles(() => ({
  selectedTab: {
    background: '#f4f6fb',
    color: '#385FEB',
    border: '1px solid #93a8f4',
  },
  disabledTab: {
    color: '#C5CAD6',
    pointerEvents: 'none',
    justifyContent: 'space-between',
  },
}));

const DEBOUNCE_TIME = 500;

export interface BackofficeAdminsPageProps {}

export function BackofficeAdminsPage(props: BackofficeAdminsPageProps) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [foundUsersOptions, setFoundUsersOptions] = useState<Users>([]);
  const [loading, setLoading] = useState(false);
  const [updatingRole, setUpdatingRole] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>(null);
  const [inputValue, setInputValue] = useState('');
  const { addToaster } = useToaster();
  const [userRolesOptions, setUserRoleOptions] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);
  const [selectedUserRole, setSelectedUserRole] =
    useState<{ label: string; value: number }>(null);

  const dispatch = useDispatch();
  const backofficeRoles = useSelector(
    (state: { accountState: AccountState }) => {
      return state.accountState.backofficeRoles;
    }
  );

  const backofficeRolesOptions = backofficeRoles.data?.backofficeRoles
    ? backofficeRoles.data.backofficeRoles.map((role: Role) => {
        return {
          label: role.displayName,
          value: role.id,
        };
      })
    : [];

  const clearSelectedUser = () => {
    setSelectedUser(null);
    setSelectedUserRole(null);
    setUserRoleOptions([]);
    setFoundUsersOptions([]);
    setInputValue('');
  };

  const onRoleChange = useCallback(
    async (newRoleId: number) => {
      try {
        setUpdatingRole(true);
        await accessControlApi.assignRoleToUser(selectedUser.id, newRoleId);
        setUpdatingRole(false);
        addToaster({
          message: `successfully updated user ${selectedUser.email} role`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
        clearSelectedUser();
      } catch (e) {
        setUpdatingRole(false);
        addToaster({
          message: `failed to update user ${selectedUser.email} role. ${e}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      }
    },
    [selectedUser, dispatch]
  );

  useEffect(() => {
    if (selectedUser) {
      const isUserAlreadyBackoffice = backofficeRolesOptions.find(
        (borole) => borole.value === selectedUser.role.id
      );
      const currentUserRoleOption = {
        label: `${selectedUser.role.displayName} (Current Role)`,
        value: selectedUser.role.id,
      };
      const userRoleOption = isUserAlreadyBackoffice
        ? { label: 'User Account Admin (Not backoffice role)', value: 6 }
        : currentUserRoleOption;

      setUserRoleOptions([userRoleOption, ...backofficeRolesOptions]);
      setSelectedUserRole(currentUserRoleOption);
    }
  }, [selectedUser]);

  const debouncedOnLookup = useMemo(
    () => debounce(setEmail, DEBOUNCE_TIME),
    [setEmail]
  );

  useEffect(() => {
    dispatch(getBackofficeRoles());
  }, []);

  const isBackofficeAdmin = useMemo(() => {
    return backofficeRoles.data?.isBackofficeAdmin;
  }, [backofficeRoles]);

  useEffect(() => {
    let active = true;
    if (!email) {
      return;
    }

    (async () => {
      try {
        let users: Users =
          await AccountsSdk.getInstance().searchUsersDataByEmailExpression(
            email
          );
        const filteredWMUsers = users?.filter((user) =>
          user.email.endsWith('@walkme.com')
        );
        if (active) {
          setFoundUsersOptions(filteredWMUsers);
          setLoading(false);
        }
      } catch (e) {
        addToaster({
          message: `failed to find users ${email}. ${e}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      }
    })();

    return () => {
      active = false;
    };
  }, [email]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '40px',
        }}
      >
        <Title>Backoffice Admins</Title>
        <SubTitleTab>
          Manage WalkMe backoffice users. Only specific backoffice users can
          perform this action (Security and IT employees).
        </SubTitleTab>
      </div>
      {!isBackofficeAdmin && <NotBackofficeErrorMessage />}
      {backofficeRoles.data?.isBackofficeAdmin &&
        backofficeRoles.data?.backofficeRoles?.length > 0 && (
          <Card>
            <SubTitle>Set WalkMe user backoffice role</SubTitle>
            <div style={{ display: 'flex', height: '300px' }}>
              <div
                style={{
                  width: '600px',
                  marginTop: '20px',
                  marginRight: '20px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <MiniTitle>Search User</MiniTitle>
                  <InputSearch
                    value={inputValue}
                    onChange={(value) => {
                      setInputValue(value);
                      clearSelectedUser();
                      const trimmedValue = value ? value.trim() : '';
                      if (trimmedValue.length >= 1) {
                        setLoading(true);
                        debouncedOnLookup(trimmedValue);
                      } else {
                        setLoading(false);
                      }
                    }}
                  />

                  {loading && <Loader />}
                  <div
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      overflowY: 'auto',
                      gap: '10px',
                    }}
                  >
                    {!loading &&
                      foundUsersOptions &&
                      foundUsersOptions.map((option) => {
                        return (
                          <OptionCard
                            className={
                              selectedUser && selectedUser.id === option.id
                                ? classes.selectedTab
                                : null
                            }
                            onClick={() => setSelectedUser(option as User)}
                          >
                            <EmailLabel>{option.email}</EmailLabel>
                            <IdLabel>User Id: {option.id}</IdLabel>
                          </OptionCard>
                        );
                      })}
                  </div>
                </div>
              </div>

              <WMDivider orientation="vertical" flexItem />
              <div
                style={{
                  width: '100% ',
                  paddingTop: '20px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  overflowY: 'auto',
                }}
              >
                {!selectedUser && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src="/assets/folder-women.svg"
                      width={'150px'}
                      height={'150px'}
                    />
                    <span
                      style={{
                        marginTop: '50px',
                        fontFamily: 'Proxima Nova',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#2F426C',
                      }}
                    >
                      Search and Select users to choose role
                    </span>
                  </div>
                )}
                {selectedUser && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <MiniTitle>
                        Choose backoffice role for user {selectedUser.email}
                      </MiniTitle>
                      <MiniTitle style={{ fontWeight: 400, color: '#637191' }}>
                        Set relevent backoffice role for user or, remove
                        backoffice role by setting an Admin role
                      </MiniTitle>

                      <WMSelect
                        style={{ marginTop: '20px' }}
                        options={userRolesOptions}
                        value={selectedUserRole}
                        onChange={(value) => {
                          setSelectedUserRole(value);
                        }}
                        placeholder={'Select a Role'}
                      />
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        disabled={
                          !selectedUser ||
                          !selectedUserRole ||
                          updatingRole ||
                          selectedUserRole?.value === selectedUser?.role?.id
                        }
                        onClick={() => {
                          onRoleChange(selectedUserRole.value);
                        }}
                        color="danger"
                      >
                        {updatingRole ? <Spinner /> : 'Update Role'}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card>
        )}
    </div>
  );
}

export default BackofficeAdminsPage;
