import {
  Account as AccountSDK,
  FeatureFlags,
  Roles,
  System,
  Systems,
  Users,
} from 'wm-accounts-sdk';

import {
  WMhttpClient,
  wmhttpClientSharedInstance,
  IAccessTokenGetter,
} from './wm-http-client';

const AccountFields = `
id
email
name
guid
paid
sfdcAccountId
creationDate
types
roles {
  id
  displayName
  accountId
}
featureFlags {
  flagName
}
users {
  id
  account {
    id
  }
  email
  firstName
  lastName
  guid
  status
  lastLoginDate
  idpNameId
  attachedIdpId
  systems(associatedSystems: true) {
    id
    displayName
    type
  }
  role {
    id
    displayName
    permissions {
      actionKey
      permission
      resourceId
      actionPermissions {
        permission
        permissionDisplayName
      }
    }
  }
}
systems(associatedSystems: true, filterDisabled: false) {
  associatedSystem {
    id
    guid
  }
  id
  displayName
  name
  type
  systemTypeKey
  email
  guid
  creationDate
  isLimited
  usage
  purpose
  creationSource
  featureFlags {
    flagName
  }
  settings {
    displayName
    name
    icons {
      highRes
    }
  }
  statusDetails {
    status
    deletionAt
    updatedBy
    actionType
    actionTypeDetails
  }
  createdBy {
    userEmail
    impUserEmail
  }
}`;

const AccountFieldsSlim = `
  id
  email
  name
  guid
  systems(associatedSystems: true, filterDisabled: false) {
    id
    displayName
    systemTypeKey
    email
  }
  users {
    id
    lastLoginDate
    email
    firstName
    lastName
  }
`;

const PoweredByConfigFields: (keyof PoweredByConfig)[] = [
  'id',
  'userId',
  'isDisplayPoweredBy',
  'poweredByLink',
];

interface GraphQlQuery {
  [key: string]: any;
}

export interface Account extends AccountSDK {
  paid: boolean;
  name: string;
  featureFlags: FeatureFlags;
  systems: Systems;
  users: Users;
  roles: Roles;
  sfdcAccountId: string;
  creationDate: string;
}

export declare type Accounts = Account[];

export interface EditAccountRequest {
  accountId: number;
  accountName: string;
}

export interface EditAccountResponse {
  id: number;
  name: string;
  email: string;
}

export interface CreateAccountRequest {
  accountName: string;
  sfdcAccountId: string;
  email: string;
  firstName: string;
  lastName: string;
  types?: string[];
}

export interface CreateAccountResponse {
  id: number;
  name: string;
  email: string;
}

export interface PaginatedAccountsEdges {
  node: Account;
  cursor: number;
}

export interface PaginatedAccountsPageData {
  count: number;
  limit: number;
  skip: number;
}

export interface PaginatedAccountsPageInfo {
  startCursor: number;
  endCursor: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  totalItems: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  previousPage: number;
  nextPage: number;
}

export interface PaginatedAccounts {
  page: {
    edges: PaginatedAccountsEdges[];
    pageInfo: PaginatedAccountsPageInfo;
  };
  pageData: PaginatedAccountsPageData;
}

export interface PoweredByConfig {
  id: number;
  userId: number;
  isDisplayPoweredBy?: boolean;
  poweredByLink?: boolean;
}

export interface PoweredByConfigError {
  error: string;
  userId: number;
}

export interface EditPoweredByConfigDTO {
  id?: number;
  userId: number;
  isDisplayPoweredBy?: boolean;
  poweredByLink?: boolean;
}

export interface User {
  email: string;
  id: number | string;
  firstName: string;
  lastName: string;
}

class GraphqlError extends Error {
  graphQLErrors: { message: string; statusCode: number }[];
  constructor(
    message: string,
    graphQLErrors: { message: string; statusCode: number }[]
  ) {
    super(message);
    this.graphQLErrors = graphQLErrors;
  }
}

class graphqlCaller {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  async createAccount(
    accountData: CreateAccountRequest
  ): Promise<CreateAccountResponse> {
    const query = `mutation($account: CreateAccountDTO!) {
                    createAccount(account: $account) {
                    id
                    email
                    name
                  }
                }`;
    const variables = { account: accountData };

    const result = await this.query(query, variables);
    const account = result.createAccount as CreateAccountResponse;

    return account;
  }

  async editAccount(
    accountData: EditAccountRequest
  ): Promise<CreateAccountResponse> {
    const query = `mutation($account: EditAccountDTO!) {
                    editAccount(account: $account) {
                    id
                    email
                    name
                  }
                }`;
    const variables = { account: accountData };
    const result = await this.query(query, variables);
    const account = result.createAccount as EditAccountResponse;
    return account;
  }

  async getAccountsByIds(ids: number[], isMinimal = false): Promise<Account[]> {
    const chunkSize = 300;
    const chunkArray = (array: number[], chunkSize: number): number[][] => {
      const numChunks = Math.ceil(array.length / chunkSize);
      return Array.from({ length: numChunks }, (_, index) => {
        const start = index * chunkSize;
        const end = start + chunkSize;
        return array.slice(start, end);
      });
    };
    const idsChunks = chunkArray(ids, chunkSize);

    const promises = idsChunks.map(async (chunk) => {
      try {
        const response = await accountsGraphqlCaller.query(
          `query {
                    getAccountsByIds(ids:${JSON.stringify(chunk)}) {
                        ${isMinimal ? AccountFieldsSlim : AccountFields}
                    }
                }`,
          {}
        );
        return response.getAccountsByIds;
      } catch (error) {
        console.error(`Error fetching accounts for chunk ${chunk}:`, error);
        return [];
      }
    });

    const results = await Promise.all(promises.map((p) => p.catch((e) => [])));
    return results.flat();
  }

  async getAccountById(id: number): Promise<Account> {
    const accounts = await this.getAccountsByIds([
      typeof id === 'string' ? Number(id) : id,
    ]);
    return accounts[0];
  }

  async getAccounts(query: any): Promise<PaginatedAccounts> {
    const response = await accountsGraphqlCaller.query(
      `query getPaginatedAccounts($limit: Int, $filter: String, $filterBy: String, $orderBy: String, $order: String,  $page: Int) {
          getPaginatedAccounts(limit: $limit, filter:$filter, filterBy:$filterBy, orderBy:$orderBy, order:$order page: $page) {
            page {
              edges {
                cursor
                node {
                  ${AccountFields}
                }
              }
              pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
                totalItems
                limit
                totalPages
                page
                pagingCounter
                previousPage
                nextPage
              }
            }
            pageData {
              count
              limit
              skip
            }
          }
        }`,
      { ...query, filter: query.filter?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') }
    );
    return response.getPaginatedAccounts;
  }

  async getAccountsByAnyEmail(email: string): Promise<Account> {
    const response = await accountsGraphqlCaller.query(
      `query boGetAccountByEmail($email: String!) {
      getAccountByEmail(email: $email) {
        ${AccountFields}
      }
    }`,
      { email: email }
    );

    return response.getAccountByEmail;
  }

  async getAccountsByGuid(guid: string): Promise<Accounts> {
    const guidWithoutHyphens = guid.includes('-')
      ? guid.replace(/-/g, '').toLowerCase()
      : guid.toLowerCase();

    const response = await accountsGraphqlCaller.query(
      `query getAccountByGuid($expression: String!) {
        getAccountByGuid(guid: $expression) {
          ${AccountFields}
      }
    }`,
      { expression: guidWithoutHyphens }
    );

    return response.getAccountByGuid ? [response.getAccountByGuid] : [];
  }

  async query(query: string, variables: GraphQlQuery) {
    try {
      const bodyData = {
        query,
        variables,
      };
      const token = this.httpClient.tokenGetter.getUserToken();

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await this.httpClient.instance.post(
        'accounts-graphql/graphql',
        bodyData,
        config
      );
      this.checkForGraphqlErrors(response);
      return response.data && response.data.data;
    } catch (e) {
      this.checkForGraphqlErrors(e.response);
      throw e;
    }
  }

  private checkForGraphqlErrors(response) {
    const errors = response?.data?.errors;
    if (errors && errors.length > 0) {
      throw new GraphqlError('Something went wrong', errors);
    }
  }

  async getPoweredByConfig(
    userId: number,
    fields: (keyof PoweredByConfig)[] = PoweredByConfigFields
  ): Promise<PoweredByConfig[]> {
    const response = await accountsGraphqlCaller.query(
      `query($userId:Int!){
              getPoweredByConfig(userId:$userId){
                ${fields.join(',')}
              }
            }`,
      { userId }
    );

    return response.getPoweredByConfig;
  }

  async editPoweredByConfig(
    editPoweredByConfig: EditPoweredByConfigDTO,
    responseFields: (keyof PoweredByConfig)[] = PoweredByConfigFields
  ): Promise<PoweredByConfig | PoweredByConfigError> {
    const query = `mutation ($poweredByConfig: EditPoweredByConfigDTO!) {
  editPoweredByConfig(poweredByConfig: $poweredByConfig) {
      ... on PoweredByConfigError{
          error
      }
      ... on PoweredByConfig{
          ${responseFields.join(',')}
      }
  }
}`;
    const variables = { poweredByConfig: editPoweredByConfig };
    const result = await this.query(query, variables);
    const editPoweredByConfigRes = result.editSiteConfig as
      | PoweredByConfig
      | PoweredByConfigError;
    return editPoweredByConfigRes;
  }

  getAccountsByQuery = async (
    query
  ): Promise<{ accounts: Accounts; totalItems: number }> => {
    let accountsList: Accounts;
    let totalItems: number;
    if (query.filter && query.filter.includes('@')) {
      const account = await this.getAccountsByAnyEmail(query.filter.slice(0));
      if (account) {
        accountsList = [account];
      }
      totalItems = accountsList?.length || 0;
    } else {
      const paginatedResponse = await this.getAccounts(query);
      accountsList =
        paginatedResponse?.page?.edges?.map((edge) => edge.node) || [];
      totalItems = paginatedResponse?.page?.pageInfo?.totalItems || 0;
    }

    return { accounts: accountsList || [], totalItems };
  };

  async geyUsersByIds(ids: (string | number)[]): Promise<User[]> {
    try {
      const query = `query getUsersByIds($ids: [Int!]!) {
  getUsersByIds(ids: $ids) {
          id
          email
          firstName
          lastName
  }
}`;
      const response = await this.query(query, { ids });
      return response.getUsersByIds;
    } catch (e) {
      this.checkForGraphqlErrors(e.response);
      throw e;
    }
  }
  async getSystemsByIds(ids: number[]): Promise<System[]> {
    try {
      const response = await accountsGraphqlCaller.query(
        `
            query GetSystemsByIds($ids: [Int!]!) {
              getSystemsByIds(ids: $ids, filterDisabled: false) {
                id
                name
                displayName
                type
                accountId
                email
                type
                guid
              }
            }
          `,
        { ids }
      );
      return response.getSystemsByIds;
    } catch (error) {
      console.error('Error fetching systems by ids', error);
      return [];
    }
  }
}

export const accountsGraphqlCaller = new graphqlCaller(
  wmhttpClientSharedInstance
);
