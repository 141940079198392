import React from 'react';
import {
  Menu,
  MenuItem,
  MenuItemStartIcon,
  MenuList,
  MenuItemText,
  Divider,
  DropdownSelection,
  InputSearch,
  Tooltip,
} from '@walkme/ui-core';
import { useState, MouseEvent, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { System } from 'wm-accounts-sdk';
import { searchableFields } from './DropdownSelectionMenuWithSearch.lib';

const StyledActionMenuLongList = styled(MenuList)`
  max-height: 150px;
`;

const StyledTitle = styled(MenuItemText)`
  padding: 12px 16px 0 16px;
  font-weight: 600;
`;

const StyledDivider = styled(Divider)`
  margin: 0 16px;
`;

const StyledInputSearch = styled.div`
  padding: 16px;
`;

const StyledSelector = styled(DropdownSelection)`
  width: 220px;
  overflow: hidden;
  button {
    height: 32px;
    margin: 0;
  }
`;

const StyledNoResults = styled.div`
  padding: 0 16px 16px 16px;
  display: flex;
  justify-content: center;
`;

type Item = {
  label: string;
  value: number;
  startIcon: JSX.Element;
  guid?: string;
  email?: string;
} | null;

export interface DropdownSelectionMenuWithSearchProps {
  systemOptions: System[];
  defaultSelectedSystemId?: number;
  onChange: (newSystem) => void;
}

export const DropdownSelectionMenuWithSearch = ({
  systemOptions,
  defaultSelectedSystemId,
  onChange,
}: DropdownSelectionMenuWithSearchProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const items = useMemo<Item[]>(
    () =>
      systemOptions.map((option) => {
        return {
          label: option.displayName + ' (' + option.systemTypeKey + ')',
          value: option.id,
          startIcon: (
            <img
              src={option.settings?.icons?.highRes}
              width="16px"
              height="16px"
            />
          ),
          guid: option.guid,
          email: option.email,
        };
      }),
    [systemOptions]
  );
  const [selected, setSelected] = useState<Item>(
    defaultSelectedSystemId
      ? items.find((item) => item.value === defaultSelectedSystemId)
      : items[0]
  );

  useEffect(() => {
    setSelected(
      defaultSelectedSystemId
        ? items.find((item) => item.value === defaultSelectedSystemId)
        : items[0]
    );
  }, [defaultSelectedSystemId]);

  const [query, setQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    if (query && query.trim()) {
      const value = query.trim().toLowerCase();
      setFilteredItems(
        items.filter((item) =>
          searchableFields.some(
            (field) => item[field]?.toLowerCase()?.indexOf(value) !== -1
          )
        )
      );
    } else {
      setFilteredItems(items);
    }
  }, [query, items]);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setQuery('');
  };

  const handleClick = (e: MouseEvent, item: Item) => {
    setSelected(item);
    onChange(item.value);
    handleClose();
  };
  return (
    <Tooltip title={selected?.label}>
      <div>
        <StyledSelector
          handleOpen={handleOpen}
          displayValue={
            selected?.label ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MenuItemStartIcon>{selected?.startIcon}</MenuItemStartIcon>
                <MenuItemText>{selected?.label}</MenuItemText>
              </div>
            ) : (
              <p>Please select ...</p>
            )
          }
          anchorEl={anchorEl}
        />
        <Menu
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
          width={250}
        >
          <StyledTitle>Systems</StyledTitle>
          <StyledInputSearch>
            <InputSearch value={query} onChange={setQuery} square />
          </StyledInputSearch>
          {!filteredItems?.length ? (
            <StyledNoResults>
              <MenuItemText>No Options</MenuItemText>
            </StyledNoResults>
          ) : (
            <div>
              <StyledDivider />
              <StyledActionMenuLongList>
                {filteredItems.map((item) => {
                  const isSelected = selected?.value === item.value;
                  return (
                    <MenuItem
                      key={item.value}
                      onClick={(e) => handleClick(e, item)}
                      selected={isSelected}
                      checkMark
                    >
                      <MenuItemStartIcon selected={isSelected}>
                        {item.startIcon}
                      </MenuItemStartIcon>
                      <MenuItemText selected={isSelected}>
                        {item.label}
                      </MenuItemText>
                    </MenuItem>
                  );
                })}
              </StyledActionMenuLongList>
            </div>
          )}
        </Menu>
      </div>
    </Tooltip>
  );
};
