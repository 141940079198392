import { Checkbox, ITagProps, Label, TagList } from '@walkme/ui-core';
import {
  ContentWrapper,
  AccountName,
  LabelWrapper,
  ReviewItemsWrapper,
  ReviewLabel,
  ReviewWrapper,
  TagListWrapper,
  useStepStyles,
  ReviewLabelSeparator,
} from '../wizard-steps.styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MergeAccountState } from '../../../../merge-account.store';
import { useDispatch, useSelector } from 'react-redux';
import {
  DisplaySystem,
  approvalCheckboxChanged,
  mergeAccountDataUpdated,
} from './review-and-merge.step.slice';
import { SystemIconGeneratorContext } from '../../../../contexts/system-icon-generator.context';
import { Divider } from '@mui/material-int';

const MAX_TAGS_SHOWN = 5000;

const WizardStepReviewAndMerge = () => {
  const classes = useStepStyles();
  const dispatch = useDispatch();
  const generateSystemIconUrl = useContext(SystemIconGeneratorContext);

  const sourceAccountName = useSelector(
    (state: MergeAccountState) => state.mergeData?.sourceAccount?.name || ''
  );
  const targetAccountName = useSelector(
    (state: MergeAccountState) => state.mergeData?.targetAccount?.name || ''
  );
  const mergeAccountData = useSelector(
    (state: MergeAccountState) => state.mergeData
  );

  const mergeApproved = useSelector(
    (state: MergeAccountState) =>
      state.reviewAndMerge?.mergeReviewdAndApproved || false
  );

  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(approvalCheckboxChanged(event.target.checked));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(mergeAccountDataUpdated());
  }, [mergeAccountData]);

  const systemsToMerge = useSelector(
    (state: MergeAccountState) =>
      state.reviewAndMerge?.review?.systemsToMerge || []
  );
  const usersToMerge = useSelector(
    (state: MergeAccountState) =>
      state.reviewAndMerge?.review?.usersToMerge || []
  );
  const excludedSystems = useSelector(
    (state: MergeAccountState) =>
      state.reviewAndMerge?.review?.systemsToExclude || []
  );
  const excludedUsers = useSelector(
    (state: MergeAccountState) =>
      state.reviewAndMerge?.review?.usersToExclude || []
  );
  const rolesToMerge = useSelector(
    (state: MergeAccountState) =>
      state.reviewAndMerge?.review?.rolesToMerge || []
  );

  const generateSystemTagProps = (systems: DisplaySystem[]): ITagProps[] =>
    systems.map((system) => {
      return {
        label: system.displayName,
        iconComponent: (
          <img
            src={generateSystemIconUrl(system.name)}
            width="16px"
            height="16px"
          />
        ),
        className: classes.tag,
        key: system.id,
      };
    });

  const generateGenericTagProps = (items: string[]): ITagProps[] =>
    items.map((item, index) => {
      return {
        key: index,
        label: item,
        className: classes.tag,
      };
    });

  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <div className={classes.stepInnerFrame}>
          <label className={classes.titleLabel}>Summary and Confirmation</label>
          <ReviewWrapper>
            <ReviewItemsWrapper>
              <LabelWrapper>
                <div>
                  <ReviewLabel>
                    You are about to merge{' '}
                    <AccountName>{sourceAccountName}</AccountName> into{' '}
                    <AccountName>{targetAccountName}</AccountName>.&nbsp;
                  </ReviewLabel>
                </div>
                <ReviewLabelSeparator />
                <div>
                  <ReviewLabel>
                    {' '}
                    This action is irreversible.{' '}Please review the
                    following:
                  </ReviewLabel>
                </div>
              </LabelWrapper>
              <ReviewLabel>
                {' '}
                Systems that will be merged to the target account:
              </ReviewLabel>
              <TagListWrapper>
                <TagList
                  labels={generateSystemTagProps(systemsToMerge)}
                  maxTagsShown={MAX_TAGS_SHOWN}
                  className={classes.tagList}
                />
              </TagListWrapper>
              <ReviewLabel>
                {' '}
                Systems that will be excluded from the merge:
              </ReviewLabel>
              <TagListWrapper>
                <TagList
                  labels={generateSystemTagProps(excludedSystems)}
                  maxTagsShown={MAX_TAGS_SHOWN}
                  className={classes.tagList}
                />
              </TagListWrapper>
              <ReviewLabel>
                Users that will be merged to the target account:
              </ReviewLabel>
              <TagListWrapper>
                <TagList
                  labels={generateGenericTagProps(usersToMerge)}
                  maxTagsShown={MAX_TAGS_SHOWN}
                  className={classes.tagList}
                />
              </TagListWrapper>
              <ReviewLabel>
                Users that will be excluded from the merge
              </ReviewLabel>
              <TagListWrapper>
                <TagList
                  labels={generateGenericTagProps(excludedUsers)}
                  maxTagsShown={MAX_TAGS_SHOWN}
                  className={classes.tagList}
                />
              </TagListWrapper>
              <ReviewLabel>
                Roles that will be merged to the target account:
              </ReviewLabel>
              <TagListWrapper>
                <TagList
                  labels={generateGenericTagProps(rolesToMerge)}
                  maxTagsShown={MAX_TAGS_SHOWN}
                  className={classes.tagList}
                />
              </TagListWrapper>
              <Checkbox
                bold
                label="I have reviewed the information listed above and I wish to continue with the merge"
                checked={mergeApproved}
                onChange={onCheckboxChange}
              />
            </ReviewItemsWrapper>
          </ReviewWrapper>
        </div>
      </div>
    </div>
  );
};

export default WizardStepReviewAndMerge;
