import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import FileSaver from 'file-saver';
import {
  WMButton,
  WMButtonVariant,
  WMCollapsibleListItem,
  WMStatusDot,
  WMStatusDotVariant,
  WMVirtualizedList,
  WMVirtualizedListItem,
} from '@walkme/wm-ui';
import styled from 'styled-components';
import { FeatureFlag } from 'wm-accounts-sdk';

export interface ImportDialogProps {
  showDialog: boolean;
  exit: () => void;
  importFeatures: (features: string[]) => void;
  currentFeatures: any;
  entityFeatures: FeatureFlag[];
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '20px',
  },
  footer: {
    float: 'right',
    paddingTop: '1rem',
  },
  formInput: {
    paddingTop: '1rem',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#2F426C',
  },
  headerDiv: {
    marginBottom: '32px',
  },
  titleSection: {
    padding: '0 0 10px',
    fontFamily: 'ProximaNova',
    fontSize: '14px',
    color: '#2F426C',
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
  },
  titleAddFFSection: {
    padding: '0 0 10px',
    fontFamily: 'ProximaNova',
    fontSize: '14px',
    color: '#2F426C',
    display: 'block',
    fontStyle: 'normal',
    lineHeight: '20px',
  },
  wizardDialog: {
    maxHeight: '486px',
    minHeight: '344px',
    borderRadius: 15,
  },
  wizardDialogWrapper: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wizardDialogButtons: {},
  wizardDialogSeconaryButton: {
    marginRight: '24px',
    '&.WMButton-secondary': {
      background: 'transparent',
    },
  },
  wizardDialogContent: {
    marginLeft: '40px',
  },
  select: {
    '& .css-10szhiz-control': {
      backgroundColor: 'var(--wmBackground)',
    },
    '& .css-xr1weh-placeholder': {
      color: '#8D97AE',
    },
    '& .css-prf4vp-singleValue': {
      color: '#8D97AE',
    },
  },
  nameIdsField: {
    backgroundColor: '#FFFFFF !important',
    marginLeft: '6px',
  },
  categoryTitle: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  collapsibleList: {
    '& .WMCollapsibleListItem-header': {
      border: 0,
      paddingRight: 0,
      boxShadow: 'inset 0px -1px 0px #F5F6F8',
    },
  },
  collapsibleListHead: {
    '& .WMCollapsibleListItem-header': {
      border: 0,
      paddingRight: 8,
      boxShadow: 'inset 0px -1px 0px #F5F6F8',
    },
  },
  dragAndDrop: {
    border: '1px dashed #B1C0F7',
    background: '#F5F8FC',
    borderRadius: '8px',
    width: '400px',
    height: '200px',
  },
  dragAndDropDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed #B1C0F7',
    background: '#F5F8FC',
    borderRadius: '8px',
    width: '400px',
    height: '200px',
  },
  styledTextField: {
    '& .WMTextField-input': {
      color: '#8D97AE',
    },
  },
  listRow: {
    fontFamily: 'ProximaNova',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2F426C',
  },
  importFeaturesText: {
    fontFamily: 'ProximaNova',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    marginLeft: '10px',
    lineHeight: '16px',
    color: '#2F426C',
  },
  listRowHead: {
    fontFamily: 'ProximaNova',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#637191',
  },
}));

const Title = styled('label')`
  color: #303d53;
  margin-bottom: 12px;
  margin-top: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
`;

const getColor = (props) => {
  if (props.isDragReject) {
    return '#ff1744';
  }
  return '#B1C0F7';
};

const Container = styled.div`
  border-width: 1px;
  border-color: ${(props) => (props.isDragReject ? '#3B61EB' : '#B1C0F7')};
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => (props.isDragReject ? '#F1F5FB' : '#F5F8FC')};
  border-radius: 8px;
  height: 200px;
`;

export const StyledList = styled(WMVirtualizedList)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 300px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #dde5f5;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const ImportFeatureDialog = ({
  showDialog,
  exit,
  importFeatures,
  currentFeatures,
  loading,
  entityFeatures,
}: ImportDialogProps) => {
  const classes = useStyles();
  const [allImportedFeatures, setAllImportedFeatures] = useState([]);
  const [selectedImportedFeatures, setSelectedImportedFeatures] = useState([]);
  const [readyToImport, setReadyToImport] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((e) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        setAllImportedFeatures(e.target.result.toString().split(','));
        //importFeatures(e.target.result);
      };
      reader.readAsText(e);
    });
    setReadyToImport(true);
  }, []);

  useEffect(() => {
    const newSelectedFFToImport =
      allImportedFeatures.length > 0
        ? allImportedFeatures.filter(
            (ff) =>
              !currentFeatures.find(
                (selectedFF) => selectedFF.featureFlag.flagName === ff
              ) && entityFeatures.find((feature) => feature.flagName === ff)
          )
        : [];
    setSelectedImportedFeatures(newSelectedFFToImport);
  }, [allImportedFeatures]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: '.txt', onDrop });

  const removeFF = (ff: any) => {
    const newSelectedFFs = ff
      ? selectedImportedFeatures.filter((selectedFf) => ff != selectedFf)
      : [];
    setSelectedImportedFeatures(newSelectedFFs);
  };

  const addFF = (ff: any) => {
    if (selectedImportedFeatures.find((selectedFF) => selectedFF === ff)) {
      return;
    }
    const newSelectedFFs = selectedImportedFeatures.concat(ff);
    setSelectedImportedFeatures(newSelectedFFs);
  };

  const selectAll = () => {
    const newSelectedFFToImport =
      allImportedFeatures.length > 0
        ? allImportedFeatures.filter(
            (ff) =>
              !currentFeatures.find(
                (selectedFF) => selectedFF.featureFlag.flagName === ff
              ) && entityFeatures.find((feature) => feature.flagName === ff)
          )
        : [];
    setSelectedImportedFeatures(newSelectedFFToImport);
  };

  const DeselctAll = () => {
    setSelectedImportedFeatures([]);
  };

  const importFFToEntity = () => {
    importFeatures(selectedImportedFeatures);
  };

  return (
    <Dialog
      open={showDialog}
      onClose={exit}
      classes={{ paper: classes.wizardDialog }}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <DialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <label className={classes.title}>Upload Features</label>
          <IconButton onClick={exit}>
            <img src="assets/icons/close.svg" alt="close" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers style={{ padding: '32px' }}>
        {!readyToImport ? (
          <Container
            {...getRootProps({ isFocused, isDragAccept, isDragReject })}
          >
            <input {...getInputProps()} />
            <img
              src="assets/icons/add_image.svg"
              alt="close"
              style={{ marginTop: '32px' }}
            />
            <Title>Drag & drop your file here</Title>
            <WMButton
              variant={WMButtonVariant.Secondary}
              className={classes.wizardDialogSeconaryButton}
              onClick={onDrop}
            >
              Upload File
            </WMButton>
          </Container>
        ) : (
          <div className={classes.headerDiv}>
            <WMCollapsibleListItem
              className={classes.collapsibleListHead}
              preventCollapse
              checkboxProps={{
                checked:
                  selectedImportedFeatures.length == allImportedFeatures.length,
                indeterminate:
                  selectedImportedFeatures.length != allImportedFeatures.length,
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                  event.target.checked ? selectAll() : DeselctAll();
                },
              }}
              title={
                <div className={classes.categoryTitle}>
                  <span className={classes.listRowHead}>Feature Name</span>
                  <div className={classes.nameIdsField}>
                    <span className={classes.listRowHead}> Status </span>
                  </div>
                </div>
              }
            />
            <StyledList
              items={allImportedFeatures}
              itemFunction={({ item, index }: WMVirtualizedListItem<any>) => (
                <WMCollapsibleListItem
                  className={classes.collapsibleList}
                  preventCollapse
                  key={index}
                  checkboxProps={{
                    checked: !!selectedImportedFeatures.find(
                      (selectedFF) => selectedFF === item
                    ),
                    disabled:
                      currentFeatures.find(
                        (selectedFF) => selectedFF.featureFlag.flagName === item
                      ) ||
                      !entityFeatures.find(
                        (feature) => feature.flagName === item
                      ),
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                      event.target.checked ? addFF(item) : removeFF(item);
                    },
                  }}
                  title={
                    <div className={classes.categoryTitle}>
                      {currentFeatures.find(
                        (selectedFF) => selectedFF.featureFlag.flagName === item
                      ) ||
                      !entityFeatures.find(
                        (feature) => feature.flagName === item
                      ) ? (
                        <span
                          className={classes.listRow}
                          style={{ color: '#8D97AE' }}
                        >
                          {item}
                        </span>
                      ) : (
                        <span className={classes.listRow}>{item}</span>
                      )}
                      <div className={classes.nameIdsField}>
                        <WMStatusDot
                          variant={
                            currentFeatures.find(
                              (selectedFF) =>
                                selectedFF.featureFlag.flagName === item
                            ) ||
                            !entityFeatures.find(
                              (feature) => feature.flagName === item
                            )
                              ? WMStatusDotVariant.Failure
                              : WMStatusDotVariant.Published
                          }
                        />
                        {currentFeatures.find(
                          (selectedFF) =>
                            selectedFF.featureFlag.flagName === item
                        ) ? (
                          <span className={classes.listRow}>
                            Already enabled
                          </span>
                        ) : !entityFeatures.find(
                            (feature) => feature.flagName === item
                          ) ? (
                          <span className={classes.listRow}>Invalid type</span>
                        ) : (
                          <span className={classes.listRow}> Enable </span>
                        )}
                      </div>
                    </div>
                  }
                />
              )}
            />
          </div>
        )}
      </DialogContent>
      {readyToImport && (
        <div className={classes.wizardDialogWrapper}>
          <span className={classes.importFeaturesText}>
            <b>{selectedImportedFeatures.length}</b> Features will be enabled
          </span>
          <DialogActions className={classes.wizardDialogButtons}>
            <WMButton
              variant={WMButtonVariant.Text}
              className={classes.wizardDialogSeconaryButton}
              onClick={exit}
              disabled={loading}
            >
              Cancel
            </WMButton>
            <WMButton
              loading={loading}
              disabled={loading || selectedImportedFeatures.length === 0}
              onClick={importFFToEntity}
            >
              Confirm
            </WMButton>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default ImportFeatureDialog;
