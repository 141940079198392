import React from 'react';
import { CircularProgress as MUICircularProgress } from '@mui/material-int';
import { DoneColor, ErrorColor } from '@walkme/ui-icons';
import { WMIconScheduled } from '@walkme/wm-ui';

export const StatusIndicator = ({ status, errors }) => {
  return (
    <span>
      {status === 'in-progress' && (
        <span title="In Progress">
          <MUICircularProgress size={20} />
        </span>
      )}
      {status === 'pending' && (
        <span title="Pending">
          <WMIconScheduled size={20} />
        </span>
      )}
      {status === 'done' && (
        <span title="Done">
          <DoneColor />
        </span>
      )}
      {status === 'failed' && (
        <span title={JSON.stringify(errors)}>
          <ErrorColor />
        </span>
      )}
    </span>
  );
};
