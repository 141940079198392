import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import {
  WMButtonVariant,
  WMDataGrid,
  WMDialog,
  WMDivider,
  WMIconDelete,
  WMIconWarning,
  WMLoader,
  WMSelect,
  WMSnackbar,
  WMSnackbarVariant,
  WMStatusDot,
  WMStatusDotVariant,
  WMTextField,
} from '@walkme/wm-ui';
import { Account, SubscriptionStatusType } from 'wm-accounts-sdk';
import { useSelector } from 'react-redux';
import {
  AccountState,
  useLoggedInUser,
} from '@wm-accounts-backoffice-center/state-management-users';
import { SubTitleTab } from '@wm-accounts-backoffice-center/shared-styles';
import moment from 'moment';
import { datadogLogs } from '@datadog/browser-logs';
import debounce from 'lodash/debounce';
import NotBackofficeErrorMessage from './not-backoffice-error';
import {
  accountsGraphqlCaller,
  accountsSubscriptionApi,
  AccountWithStatus,
} from '@wm-accounts-backoffice-center/wm-api';
import { Card, Title } from './backoffice-admins-styles';

const useStyles = makeStyles(() => ({
  deleteItemsButton: {
    height: '40px !important',
    width: '82px',
    borderRadius: '40px !important',
    backgroundColor: '#EB3232 !important',
    '&:hover': {
      backgroundColor: '#B02626 !important',
    },
    '&:active': {
      backgroundColor: '#942020 !important',
    },
  },
  deleteButton: {
    height: '32px !important',
    width: '150px',
    backgroundColor: '#EB3232 !important',
    borderRadius: '40px !important',
    '&:hover': {
      backgroundColor: '#B02626 !important',
    },
    '&:active': {
      backgroundColor: '#942020 !important',
    },
    '& .MuiButton-label': {
      color: '#FFFFFF',
      '&:hover': {
        color: '#FFFFFF',
      },
    },
  },
  customDot: {
    marginLeft: '5px',
  },
  warningBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',
    margin: '10px 0',
    backgroundColor: '#FEF8E8',
    border: '1px solid #F7B500',
    bordeRadius: '4px',
  },
}));

export interface InactiveAccountsPageProps {}

const dateFormater = (date) => {
  return moment(date).format('MMMM Do YYYY');
};

const DEBOUNCE_TIME = 300;

export function InactiveAccountsPage(props: InactiveAccountsPageProps) {
  const classes = useStyles();
  const [accountsToDelete, setAccountsToDelete] = useState<AccountWithStatus[]>(
    []
  );
  const [inactiveAccounts, setInactiveAccounts] = useState<AccountWithStatus[]>(
    []
  );
  const [showDeleteAccountsDialog, setShowDeleteAccountsDialog] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingStatusChange, setloadingStatusChange] =
    useState<boolean>(false);
  const [isDeleteEnabled, setIsDeleteEnabled] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState<string>('');
  const [successSnackbarMessage, setSuccessSnackbarMessage] =
    useState<string>('');

  const { loggedInUserAppData } = useLoggedInUser();

  const LOGGED_IN_USER_EMAIL = useMemo(
    () => loggedInUserAppData.data?.email,
    [loggedInUserAppData]
  );

  useEffect(() => {
    const fetchInactiveAccounts = async () => {
      try {
        setLoading(true);
        const accountsWithStatus: AccountWithStatus[] =
          await accountsSubscriptionApi.getInactiveAccounts();
        const accountsData = await accountsGraphqlCaller.getAccountsByIds(
          accountsWithStatus.map((account) => account.accountId),
          true
        );
        const mergedAccounts: AccountWithStatus[] = accountsWithStatus.map(
          (accountWithStatus) => {
            const accountData = accountsData.find(
              (account) => account.id === accountWithStatus.accountId
            );
            return {
              id: accountWithStatus.accountId,
              email: accountData?.email || 'No email available',
              name: accountData?.name || 'No name available',
              status: accountWithStatus.status,
              updatedAt: accountWithStatus.updatedAt,
              deletionAt: accountWithStatus.deletionAt,
              systemsAmount: accountData?.systems?.length || 0,
              lastLoggedInUserDate: accountData?.users?.length
                ? accountData.users.reduce((latest, user) => {
                    return !latest ||
                      (user.lastLoginDate &&
                        user.lastLoginDate > latest.lastLoginDate)
                      ? user
                      : latest;
                  }, null)?.lastLoginDate || 'No user ever logged in'
                : 'No users available',
            };
          }
        );
        setInactiveAccounts(mergedAccounts);
      } catch (err) {
        setErrorSnackbarMessage('Error fetching inactive accounts');
      } finally {
        setLoading(false);
      }
    };
    fetchInactiveAccounts();
  }, []);

  const backofficeRoles = useSelector(
    (state: { accountState: AccountState }) => {
      return state.accountState.backofficeRoles;
    }
  );

  const isBackofficeAdmin = useMemo(() => {
    return backofficeRoles.data?.isBackofficeAdmin;
  }, [backofficeRoles]);

  const headCells = useMemo(() => {
    return [
      {
        lockVisible: true,
        pinned: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerName: 'Email',
        field: 'email',
        sortable: true,
        minWidth: 250,
      },
      {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: 'Account Id',
        field: 'accountId',
        sortable: true,
        minWidth: 100,
      },
      {
        headerName: 'Inactive Since',
        field: 'lastLoggedInUserDate',
        minWidth: 250,
        sortable: true,
        comparator: (valueA, valueB) => {
          if (valueA === valueB) return 0;
          if (valueA === null) return 1;
          if (valueB === null) return -1;
          return moment(valueA).valueOf() - moment(valueB).valueOf();
        },
      },
      {
        headerName: 'Deletion Date',
        field: 'deletionDate',
        minWidth: 250,
        sortable: true,
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 100,
        cellRenderer: (params) => {
          const status = params.data?.status;
          return (
            <>
              {status}
              {status === SubscriptionStatusType.inactive && (
                <WMStatusDot
                  className={classes.customDot}
                  variant={WMStatusDotVariant.Failure}
                />
              )}
            </>
          );
        },
      },
      {
        headerName: 'Systems count',
        field: 'systemsAmount',
        minWidth: 100,
        sortable: true,
      },
      {
        headerName: 'Deactivation Cycle Date',
        field: 'deactivationCycleDate',
        minWidth: 250,
        sortable: true,
      },
    ];
  }, []);

  const footerActions = [
    {
      onClick: (rows) => {
        handleSystemsDeletionClick(rows);
      },
      icon: <WMIconDelete />,
      title: 'Delete Accounts',
      className: classes.deleteButton,
    },
  ];

  const handleSystemsDeletionClick = (rowsToDelete) => {
    setAccountsToDelete(rowsToDelete);
    setShowDeleteAccountsDialog(true);
  };

  const getInactiveAccountsRows = useCallback(
    (accounts: AccountWithStatus[]): any[] => {
      if (!accounts) {
        return [];
      }
      return accounts.map((item) => {
        const {
          id,
          email,
          name,
          status,
          updatedAt,
          deletionAt,
          systemsAmount,
          lastLoggedInUserDate,
        } = item;
        return {
          email,
          name,
          accountId: id,
          deactivationCycleDate: dateFormater(updatedAt),
          deletionDate: dateFormater(deletionAt),
          status,
          systemsAmount,
          lastLoggedInUserDate: moment(lastLoggedInUserDate).isValid()
            ? dateFormater(lastLoggedInUserDate)
            : lastLoggedInUserDate,
        };
      });
    },
    [inactiveAccounts]
  );

  const handleDeletion = async () => {
    setloadingStatusChange(true);
    try {
      const accountsIds = accountsToDelete.map((account) => account.accountId);
      const response = await accountsSubscriptionApi.changeAccountsStatusType(
        accountsIds,
        SubscriptionStatusType.pendingForDelete,
        'immediately'
      );
      datadogLogs.logger.log(
        `Inactive accounts deletion was initiated by ${LOGGED_IN_USER_EMAIL}`,
        {
          status: 'warning',
          triggerdBy: LOGGED_IN_USER_EMAIL,
          notifySecurityTeam: true,
          eventName: 'INACTIVE_ACCOUNTS_DELETION',
          accountsIds: accountsIds,
          amount: accountsIds.length,
        }
      );
      setLoading(true);
      const deletedIds = response
        .filter(({ statusType }) => statusType === 'pendingForDelete')
        .map(({ accountId }) => accountId);
      setInactiveAccounts(
        inactiveAccounts.filter(({ id }) => !deletedIds.includes(id))
      );
      setLoading(false);
    } catch (err) {
      setErrorSnackbarMessage('Error deleting accounts');
    } finally {
      setInputValue('');
      setShowDeleteAccountsDialog(false);
      setloadingStatusChange(false);
    }
  };

  const handleInputChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);
      setIsDeleteEnabled(value === LOGGED_IN_USER_EMAIL);
    }, DEBOUNCE_TIME),
    []
  );

  const triggerDeleteAccountsDialog = useCallback(() => {
    const buttons = [
      {
        children: 'Cancel',
        variant: WMButtonVariant.Text,
        style: { marginRight: '30px', color: '#637191' },
        onClick: () => setShowDeleteAccountsDialog(false),
      },
      {
        children: 'Delete',
        onClick: () => handleDeletion(),
        className: classes.deleteItemsButton,
        loading: loadingStatusChange,
        disabled: !isDeleteEnabled,
      },
    ];
    const title =
      accountsToDelete.length === 1
        ? 'Delete Account'
        : `Delete ${accountsToDelete.length} Accounts`;
    return (
      <WMDialog
        ds2
        disableHeaderDivider={true}
        open={showDeleteAccountsDialog}
        onClose={() => setShowDeleteAccountsDialog(false)}
        title={title}
        buttons={buttons}
      >
        <Typography>
          By clicking 'Delete', you will be adding the selected accounts to the
          immediate deletion cycle.
          <br />
          Please be aware that <b>this action is irreversible</b> and once
          completed, the accounts cannot be restored.
        </Typography>
        <WMTextField
          label={
            <span style={{ userSelect: 'none' }}>
              Type '{LOGGED_IN_USER_EMAIL}' to enable delete
            </span>
          }
          autoFocus
          autoComplete="off"
          value={inputValue}
          onChange={handleInputChange}
          style={{ marginTop: '26px' }}
        />
        <Box className={classes.warningBox}>
          <WMIconWarning
            color={'#F7B500'}
            size={20}
            style={{ flexShrink: 0, paddingRight: '12px' }}
          />
          Please carefully review all the accounts you have selected for
          deletion.
          <br />
        </Box>
      </WMDialog>
    );
  }, [
    showDeleteAccountsDialog,
    accountsToDelete,
    isDeleteEnabled,
    loadingStatusChange,
  ]);

  const handleSnackBarErrorClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setErrorSnackbarMessage('');
    setSuccessSnackbarMessage('');
  };

  return (
    <div>
      {triggerDeleteAccountsDialog()}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '40px',
        }}
      >
        <Title>Inactive Accounts</Title>
        <SubTitleTab>
          Manage WalkMe inactive accounts. Accounts that haven't been active for
          3 months will be displayed here.
        </SubTitleTab>
      </div>
      {!isBackofficeAdmin ? (
        <NotBackofficeErrorMessage />
      ) : loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          This action can take up to 30 seconds
          <WMLoader style={{ width: '30%', maxWidth: '300px' }} />
        </div>
      ) : (
        <Card>
          <div style={{ height: '70vh' }}>
            <WMDataGrid
              columnDefs={headCells}
              rows={getInactiveAccountsRows(inactiveAccounts)}
              title={'Inactive Accounts'}
              entityName={'Account'}
              hideColumnPicker
              hideExport
              footerActions={footerActions}
              loadingData={loading}
            />
          </div>
        </Card>
      )}

      <WMSnackbar
        open={!!errorSnackbarMessage || !!successSnackbarMessage}
        onClose={handleSnackBarErrorClose}
        variant={
          errorSnackbarMessage
            ? WMSnackbarVariant.Error
            : WMSnackbarVariant.Success
        }
        message={errorSnackbarMessage || successSnackbarMessage}
      />
    </div>
  );
}

export default InactiveAccountsPage;
