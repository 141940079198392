import styled from 'styled-components';
import { styledTheme } from '../ui-theme/ui-theme';

const {
  typography: { fontWeight },
  colors: { main },
} = styledTheme;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'ProximaNova, Poppins, Open Sans', sans-serif;

  button {
    margin: 10px;
  }

  .mainPage {
    display: flex;
    flex-direction: row;
    height: calc(100% - 56px);
    height: -webkit-calc(100% - 56px);
    height: -moz-calc(100% - 56px);

    .mainContent {
      overflow: hidden;
      padding: 20px 32px;
      flex-grow: 1;
    }
  }

  h1 {
    color: ${main.mainTitle};
    font-weight: ${fontWeight.medium};
  }

  h3 {
    color: ${main.mainTitle};
    font-weight: ${fontWeight.medium};
  }
`;

export default Main;
