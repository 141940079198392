import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  StepConnector,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { Check } from '@walkme/ui-icons';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    max-width: none;
    width: 1072px;
    border-radius: 12px;
    &.MuiStepper-root {
      padding: 0;
    }
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 24px 24px 0px;
  & .MuiTypography-h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #2f426c;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
  background: #f7f9fc;
`;

export const StyledDivider = styled(Divider)`
  margin: 16px 0px 16px;
  border: 1px solid #f5f6f8;
`;

export const StyledCheck = styled(Check)`
  color: #385feb;
`;

export const StyledStepper = styled(Stepper)`
  justify-content: center;
  width: 100%;
  &.MuiStepper-root {
    padding: none;
  }
  & .MuiStepLabel-label {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2f426c !important;
  }
`;

export const StyledConnector = styled(StepConnector)`
  max-width: 20px;
  & .MuiStepConnector-line {
    border: 1px solid #e6ecf8;
  }
`;

export const StyledStepLabel = styled(StepLabel)`
  & .Mui-active {
    color: #385feb;
  }
  & .Mui-disabled {
    & .MuiStepIcon-root {
      color: #e4e9fc;
    }
    & .MuiStepIcon-text {
      fill: #8d97ae;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const StyledIconeStep = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8d97ae;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #e4e9fc;
  border-radius: 80px;
`;

export const StyledIconeStepActive = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #3b61eb;
  border-radius: 80px;
`;

export const StyledIconeStepCompleted = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #e4e9fc;
  border: 1px solid #3b61eb;
  border-radius: 80px;
`;

export const AccountNameLabel = styled('label')`
  color: #2f426c;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
export const SnippetEmailLabel = styled('label')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8d97ae;
`;
