import { Account } from '@wm-accounts-backoffice-center/wm-api';
import React, { useCallback, useEffect, useState } from 'react';
import {
  InfoDetail,
  InfoTitle,
  StyledGridDiv,
  StyledInforamtion,
  SubTitleTab,
  TitleTab,
} from '../styles/styles';
import styled from 'styled-components';
import { getAccountById } from '@wm-accounts-backoffice-center/state-management-users';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFeaturesToAccount,
  removeFeaturesFromAccount,
  FeaturesState,
} from '@wm-accounts-backoffice-center/state-management-features';
import { Spinner } from '@walkme/ui-core';
import { DropdownSelectionMenuNoSearch } from '../dropdown-aelection-menu-no-search';
import { AppData } from '@wm-accounts-backoffice-center/general-types';
import { AccountImpersonationRestrictedData } from 'wm-accounts-sdk';
import moment from 'moment';
import AccountSubscriptionPage from './account-subscription';

export interface AccountInforamtionProps {
  account: Account;
  impersonationSettings: AppData<AccountImpersonationRestrictedData>;
}

const ELACustomer = 'ELACustomer';

export function AccountInforamtion({
  account,
  impersonationSettings,
}: AccountInforamtionProps) {
  const [updatingELA, setUpdatingELA] = useState(false);
  const dispatch = useDispatch();

  const getAccountCurrentEla = (): boolean => {
    return account.featureFlags?.find(
      (fetureFlag) => fetureFlag.flagName === ELACustomer
    )
      ? true
      : false;
  };
  const [isEla, setIsELA] = useState(getAccountCurrentEla());
  const usersUsingSSO = account.users?.filter((user) => user.attachedIdpId);

  const addFFStatus = useSelector(
    (state: { featuresState: FeaturesState }) => state.featuresState.addFeature
  );

  const deleteFFStatus = useSelector(
    (state: { featuresState: FeaturesState }) =>
      state.featuresState.deleteFeature
  );

  useEffect(() => {
    if (
      addFFStatus.data?.find((ff) => ff.flagName === ELACustomer) ||
      deleteFFStatus.data?.find((ff) => ff.flagName === ELACustomer)
    ) {
      dispatch(getAccountById(account.id, true));
    }
  }, [addFFStatus, deleteFFStatus]);

  useEffect(() => {
    setIsELA(getAccountCurrentEla());
  }, [account]);

  const onELAChange = useCallback(
    async (ela: boolean) => {
      setUpdatingELA(true);
      if (ela) {
        dispatch(addFeaturesToAccount(account.id, [ELACustomer]));
      } else {
        dispatch(removeFeaturesFromAccount(account.id, [ELACustomer]));
      }
      setIsELA(ela);
      setUpdatingELA(false);
    },
    [account, setUpdatingELA, dispatch]
  );

  const items = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '24px',
        }}
      >
        <TitleTab>Account information</TitleTab>
        <SubTitleTab>
          Review your account contact and product information
        </SubTitleTab>
      </div>
      <StyledGridDiv>
        <StyledInforamtion>
          <InfoTitle>Email</InfoTitle>
          <InfoDetail>{account.email}</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle style={{ marginBottom: '5px' }}>ELA</InfoTitle>
          {updatingELA ? (
            <Spinner></Spinner>
          ) : (
            <DropdownSelectionMenuNoSearch
              items={items}
              selectedValue={isEla}
              onChange={(newStatus) => onELAChange(newStatus)}
            />
          )}
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>Paying</InfoTitle>
          <InfoDetail>{account.paid ? 'Yes' : 'No'}</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>WalkMe Access</InfoTitle>
          <InfoDetail>
            {impersonationSettings.loading ? (
              <Spinner />
            ) : impersonationSettings.data ? (
              impersonationSettings.data.fullRestrictionStartDateMillis ? (
                `Access until ${moment(
                  impersonationSettings.data.fullRestrictionStartDateMillis
                ).calendar()}`
              ) : (
                'Always'
              )
            ) : (
              'N/A'
            )}
          </InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>Account GUID</InfoTitle>
          <InfoDetail>{account.guid}</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>Account ID</InfoTitle>
          <InfoDetail>{account.id}</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>SFDC account ID</InfoTitle>
          <InfoDetail>{account.sfdcAccountId}</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>SSO</InfoTitle>
          <InfoDetail>
            {usersUsingSSO && usersUsingSSO.length > 0 ? 'Yes' : 'No'}
          </InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>Account CSM</InfoTitle>
          <InfoDetail>N/A</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>Account owner</InfoTitle>
          <InfoDetail>N/A</InfoDetail>
        </StyledInforamtion>
        <AccountSubscriptionPage
          accountId={account.id}
          accountName={account.name}
        />
      </StyledGridDiv>
    </div>
  );
}
export default AccountInforamtion;
