import React, { memo } from 'react';
import styled from 'styled-components';

import { Checkbox as MuiCheckbox, CheckboxProps } from '@material-ui/core';
import { CheckBoxOutlined } from '@material-ui/icons';
import { styledTheme } from '@wm-accounts-backoffice-center/shared-styles';
import { WMCheckbox, WMIconSuccess } from '@walkme/wm-ui';

const { colors } = styledTheme;

const StyledCheckbox = styled(WMCheckbox)`
  &.Mui-checked {
    color: ${colors.mainBlue};
  }
`;

const CheckedIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fontSize={14}
    width="1em"
    height="1em"
  >
    <rect width="16" height="16" fill="#3B61EB" rx="2" />
    <path
      fill="none"
      stroke="#FFFFFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M0,1.623,2.189,3.811,6,0"
      transform="translate(5 6)"
    />
  </svg>
);

const IndeterminateIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fontSize={14}
    width="1em"
    height="1em"
  >
    <rect width="15.995" height="16" fill="#3B61EB24" rx="2" />
    <path
      fill="none"
      stroke="#3B61EB"
      strokeLinecap="round"
      strokeWidth="2"
      d="M0 0L7 0"
      transform="translate(4.51 8)"
    />
  </svg>
);

const UncheckedIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fontSize={14}
    width="1em"
    height="1em"
  >
    <g fill="none" stroke="#6D81A6" strokeMiterlimit="10" strokeWidth="2">
      <rect width="16" height="16" stroke="none" rx="2" />
      <rect width="14" height="14" x="1" y="1" rx="1" />
    </g>
  </svg>
);

export const Checkbox = memo(({ ...rest }: CheckboxProps) => {
  return (
    <StyledCheckbox
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      indeterminateIcon={<IndeterminateIcon />}
      {...rest}
    />
  );
});

export default Checkbox;
