import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppThunk, MergeAccountState } from '../../../../merge-account.store';
import { sourceAccountSelected } from '../../../../merge-data.slice';
import {
  userCanProceed,
  userCannotProceeed,
} from '../../merge-account-wizard.slice';
import {
  accountsGraphqlCaller,
  Account,
} from '@wm-accounts-backoffice-center/wm-api';

export interface AppData<T> {
  loading: boolean;
  error: any;
  data: T;
}

export interface SourceAccountState {
  query: string;
  searchIndex: number;
  accounts: AppData<Account[]>;
}

export const initialSourceAccountState: SourceAccountState = {
  query: '',
  searchIndex: 0,
  accounts: {
    loading: false,
    error: null,
    data: [],
  },
};

const selectSourceAccountSlice = createSlice({
  name: 'selectSourceAccount',
  initialState: initialSourceAccountState,
  reducers: {
    resetSearchSourceAccount: (state: SourceAccountState) => {
      state.query = '';
      state.searchIndex = 0;
      state.accounts = {
        loading: false,
        error: null,
        data: [],
      };
    },
    searchAccountStarted: (
      state: SourceAccountState,
      action: PayloadAction<string>
    ) => {
      state.query = action.payload;
      state.searchIndex++;
      state.accounts = {
        loading: true,
        error: null,
        data: [],
      };
    },
    searchAccountFinished: (
      state: SourceAccountState,
      action: PayloadAction<Account[]>
    ) => {
      state.accounts = {
        loading: false,
        error: null,
        data: action.payload,
      };
    },
    searchAccontFailed: (
      state: SourceAccountState,
      action: PayloadAction<any>
    ) => {
      state.accounts = {
        loading: false,
        error: action.payload,
        data: [],
      };
    },
  },
});

export const {
  resetSearchSourceAccount,
  searchAccountStarted,
  searchAccountFinished,
  searchAccontFailed,
} = selectSourceAccountSlice.actions;

const searchAccounts = async (query) => {
  if (query.trim() === '') {
    return [];
  }

  const response = await accountsGraphqlCaller.getAccountsByQuery({
    limit: 50,
    order: 'ASC',
    orderBy: 'email',
    page: 1,
    filter: query,
    filterBy: 'id,guid,sfId,email,name',
  });

  return response.accounts;
};

export const stepLoaded = (): AppThunk => async (dispatch, getState) => {
  const sourceAccount = (getState() as MergeAccountState).mergeData
    .sourceAccount;
  if (!sourceAccount) {
    dispatch(userCannotProceeed());
  }
};

export const searchQueryChanged =
  (query: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(sourceAccountSelected(null)); // once query changes - no account is selected
      dispatch(userCannotProceeed()); // no account selected

      dispatch(searchAccountStarted(query));

      if (query.trim() === '') {
        dispatch(sourceAccountSelected(null));
        dispatch(searchAccountFinished([]));
        dispatch(userCannotProceeed());
        return;
      }

      const querySearchIndex = (getState() as MergeAccountState)
        .selectSourceAccount.searchIndex;

      const accountsFound = await searchAccounts(query);

      const currentSearchIndex = (getState() as MergeAccountState)
        .selectSourceAccount.searchIndex;
      if (querySearchIndex !== currentSearchIndex) {
        return;
      }

      dispatch(searchAccountFinished(accountsFound));
    } catch (err) {
      dispatch(searchAccontFailed(err.message));
      return;
    }
  };

export const sourceAccountClicked =
  (accountId: number): AppThunk =>
  async (dispatch, getState) => {
    const accounts = (getState() as MergeAccountState).selectSourceAccount
      .accounts.data;
    const selectedAccount = accounts.find(
      (account) => account.id === accountId
    );
    dispatch(sourceAccountSelected(selectedAccount));
    dispatch(userCanProceed());
  };

export default selectSourceAccountSlice.reducer;
