import { Drawer } from '@mui/material-int';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background-color: rgba(47, 66, 108, 0.61);
  }
  .MuiPaper-root {
    max-width: 60%;
  }
`;

export const SummmaryDrawerWrapper = styled('div')`
  margin: 20px 0 10px 0;
  height: calc(100% - 82px);
  width: calc(100% - 82px);
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 20px;
  margin: 20px;
`;

export const MergeSummaryTitle = styled('label')`
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #2f426c;
`;

export const DrawerTitleWrapper = styled('div')`
  margin-bottom: 10px;
`;

export const DrawerContentWrapper = styled('div')`
  height: calc(100% - 42px);
  overflow-y: auto;
`;
