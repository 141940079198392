import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FeaturesState, getSystemFeatures } from '../redux/features.slice';

export const useGetSystemFeatures = (systemId: number) => {
  const systemFeaturesAppData = useSelector(
    (state: { featuresState: FeaturesState }) =>
      state.featuresState.systemFeatures
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSystemFeatures(systemId));
  }, [dispatch]);

  return systemFeaturesAppData;
};

export default useGetSystemFeatures;
