import React from 'react';
import { MergeLog } from '../../merge-log/merge-log';

export interface PageInitialDisplayProps {
  onStartMergeClicked: () => void;
}

export const PageInitialDisplay = ({
  onStartMergeClicked,
}: PageInitialDisplayProps) => {
  return <MergeLog onStartMergeClicked={onStartMergeClicked} />;
};
