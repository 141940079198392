import React from 'react';
import {
  StyledCheck,
  StyledIconeStep,
  StyledIconeStepActive,
  StyledIconeStepCompleted,
} from '../merge-account-wizard.styles';

export const CustomStepIcon = (props) => {
  const { icon, active, completed } = props;
  if (completed)
    return (
      <StyledIconeStepCompleted>
        <StyledCheck />
      </StyledIconeStepCompleted>
    );
  if (active) {
    return <StyledIconeStepActive>{icon}</StyledIconeStepActive>;
  }
  return <StyledIconeStep>{icon}</StyledIconeStep>;
};
