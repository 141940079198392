import styled from 'styled-components';
import React, { ReactElement, ReactNode } from 'react';
import { WMButton, WMButtonVariant, WMIconError } from '@walkme/wm-ui';

export const StyledErrorContainer = styled.div`
  width: 50%;
  border: 1px solid #dde5f5;
  background-color: #ffffff;
  box-shadow: 0px 2px 13px #303d5312;
  border: 1px solid #dde5f5;
  border-radius: 4px;
  display: flex;
  margin: auto;
  padding: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-family: 'Poppins';
`;

const StyledMsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  font-size: 1rem;
`;

const StyledMsgPrimary = styled.span`
  font-weight: 700;
  font-size: 1rem;
  margin: 20px 0 5px;
`;

const StyledMsgSecondary = styled.span`
  display: flex;
  text-align: center;
  width: 90%;
`;

export interface ErrorBoundaryContainerProps {
  errorText?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
}

export const ErrorBoundaryContainer = (
  props: ErrorBoundaryContainerProps
): ReactElement => {
  return (
    <StyledErrorContainer>
      <WMIconError />
      <StyledMsgPrimary>{'Something went wrong'}</StyledMsgPrimary>
      <StyledMsgContainer>
        {props.errorText && (
          <StyledMsgSecondary>{props.errorText}</StyledMsgSecondary>
        )}
        {props.buttonOnClick && (
          <WMButton
            variant={WMButtonVariant.Primary}
            onClick={() => props.buttonOnClick()}
          >
            {props.buttonText}
          </WMButton>
        )}
      </StyledMsgContainer>
    </StyledErrorContainer>
  );
};

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorText: '',
      buttonOnClick: props.buttonOnClick,
      buttonText: props.buttonText,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorText: (error && error.message) || JSON.stringify(error),
    };
  }

  override render(): ReactNode {
    if (this.state['hasError']) {
      return <ErrorBoundaryContainer errorText={this.state['errorText']} />;
    }

    return this.props.children;
  }
}
