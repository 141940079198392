import { AppData } from '@wm-accounts-backoffice-center/general-types';

import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import {
  AddMasterFeatureDto,
  DefaultFeaturesData,
  DeleteMasterFeatureDto,
  MasterFeaturesData,
  UpdateDefaultFeaturesDto,
} from 'wm-accounts-sdk/dist/lib/accounts-sdk/types/default-features-types';
import { AccountsSdk } from 'wm-accounts-sdk';
import { WMSnackbarVariant } from '@walkme/wm-ui';

export interface NotificationMessage {
  text: string;
  variant: WMSnackbarVariant;
  isOpen: boolean;
}
export interface DefaultFeaturesState {
  defaultFeaturesPerAccount: AppData<DefaultFeaturesData[]>;
  defaultFeatures: AppData<string>;
  masterFeatures: AppData<MasterFeaturesData[]>;
  masterFeaturesPerAccount: AppData<MasterFeaturesData[]>;
  notificationMessage: NotificationMessage;
}

export const initialDefaultFeaturesState: DefaultFeaturesState = {
  defaultFeaturesPerAccount: {
    loading: false,
    error: '',
    data: [],
  },
  defaultFeatures: {
    loading: false,
    error: '',
    data: null,
  },
  masterFeatures: {
    loading: false,
    error: '',
    data: null,
  },
  masterFeaturesPerAccount: {
    loading: false,
    error: '',
    data: null,
  },
  notificationMessage: {
    text: '',
    variant: WMSnackbarVariant.Success,
    isOpen: false,
  },
};

const defaultfeatureSlice = createSlice({
  name: 'defaultfeatureSlice',
  initialState: initialDefaultFeaturesState,
  reducers: {
    getAccountDefaultFeaturesStart(state: DefaultFeaturesState) {
      state.defaultFeaturesPerAccount.error = '';
      state.defaultFeaturesPerAccount.loading = true;
      return state;
    },
    getAccountDefaultFeaturesSuccess(
      state: DefaultFeaturesState,
      action: PayloadAction<DefaultFeaturesData[]>
    ) {
      state.defaultFeaturesPerAccount.data = action.payload;
      state.defaultFeaturesPerAccount.loading = false;
      state.defaultFeaturesPerAccount.error = '';
      return state;
    },
    getAccountDefaultFeaturesFailed(state, action: PayloadAction<string>) {
      state.defaultFeaturesPerAccount.loading = false;
      state.defaultFeaturesPerAccount.error = action.payload;
      return state;
    },
    getDefaultFeaturesStart(state: DefaultFeaturesState) {
      state.defaultFeatures.error = '';
      state.defaultFeatures.loading = true;
      return state;
    },
    getDefaultFeaturesSuccess(
      state: DefaultFeaturesState,
      action: PayloadAction<string>
    ) {
      state.defaultFeatures.data = action.payload;
      state.defaultFeatures.loading = false;
      state.defaultFeatures.error = '';
      return state;
    },
    getDefaultFeaturesFailed(state, action: PayloadAction<string>) {
      state.defaultFeatures.loading = false;
      state.defaultFeatures.error = action.payload;
      return state;
    },
    getMasterFeaturesStart(state: DefaultFeaturesState) {
      state.masterFeatures.error = '';
      state.masterFeatures.loading = true;
      return state;
    },
    getMasterFeaturesSuccess(
      state: DefaultFeaturesState,
      action: PayloadAction<MasterFeaturesData[]>
    ) {
      state.masterFeatures.data = action.payload;
      state.masterFeatures.loading = false;
      state.masterFeatures.error = '';
      return state;
    },
    getMasterFeaturesFailed(state, action: PayloadAction<string>) {
      state.masterFeatures.loading = false;
      state.masterFeatures.error = action.payload;
      return state;
    },
    getMasterFeaturesPerAccountStart(state: DefaultFeaturesState) {
      state.masterFeaturesPerAccount.error = '';
      state.masterFeaturesPerAccount.loading = true;
      return state;
    },
    getMasterFeaturesPerAccountSuccess(
      state: DefaultFeaturesState,
      action: PayloadAction<MasterFeaturesData[]>
    ) {
      state.masterFeaturesPerAccount.data = action.payload;
      state.masterFeaturesPerAccount.loading = false;
      state.masterFeaturesPerAccount.error = '';
      return state;
    },
    getMasterFeaturesPerAccountFailed(state, action: PayloadAction<string>) {
      state.masterFeaturesPerAccount.loading = false;
      state.masterFeaturesPerAccount.error = action.payload;
      return state;
    },
    cleanUpNotificationMessage(state: DefaultFeaturesState) {
      state.notificationMessage.isOpen = false;
      return state;
    },
    setNotificationMessage(
      state: DefaultFeaturesState,
      action: PayloadAction<NotificationMessage>
    ) {
      state.notificationMessage = action.payload;
      return state;
    },
  },
});

export { defaultfeatureSlice };

const {
  getAccountDefaultFeaturesFailed,
  getAccountDefaultFeaturesSuccess,
  getAccountDefaultFeaturesStart,
  getDefaultFeaturesFailed,
  getDefaultFeaturesSuccess,
  getDefaultFeaturesStart,
  getMasterFeaturesFailed,
  getMasterFeaturesSuccess,
  getMasterFeaturesStart,
  getMasterFeaturesPerAccountFailed,
  getMasterFeaturesPerAccountSuccess,
  getMasterFeaturesPerAccountStart,
} = defaultfeatureSlice.actions;

export type rootReducerType = ReturnType<typeof defaultfeatureSlice.reducer>;
export type defaultfeaturesAppThunk = ThunkAction<
  void,
  rootReducerType,
  unknown,
  Action<string>
>;

export const getAccountDefaultFeatures =
  (
    accountId: number,
    platform: string,
    forceLoad?: boolean
  ): defaultfeaturesAppThunk =>
  async (dispatch) => {
    try {
      dispatch(getAccountDefaultFeaturesStart());
      const accountDefaultFeatures: DefaultFeaturesData[] =
        await AccountsSdk.getInstance().backofficeAccountDefaultFeatures.getAccountDefaultFeatures(
          accountId,
          platform
        );
      dispatch(getAccountDefaultFeaturesSuccess(accountDefaultFeatures));
    } catch (err) {
      dispatch(getAccountDefaultFeaturesFailed(err.message));
      return;
    }
  };

export const getDefaultFeatures =
  (platform: string): defaultfeaturesAppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDefaultFeaturesStart());
      const defaultFeatures: string =
        await AccountsSdk.getInstance().backofficeAccountDefaultFeatures.getDefaultFeatures(
          platform
        );
      dispatch(getDefaultFeaturesSuccess(defaultFeatures));
    } catch (err) {
      dispatch(getDefaultFeaturesFailed(err.message));
      return;
    }
  };

export const updateAccountDefaultFeature =
  (
    updateDto: UpdateDefaultFeaturesDto,
    forceLoad?: boolean
  ): defaultfeaturesAppThunk =>
  async (dispatch) => {
    try {
      dispatch(getAccountDefaultFeaturesStart());
      await AccountsSdk.getInstance().backofficeAccountDefaultFeatures.updateAccountDefaultFeature(
        updateDto
      );
      const AccountDefaultFeatures: DefaultFeaturesData[] =
        await AccountsSdk.getInstance().backofficeAccountDefaultFeatures.getAccountDefaultFeatures(
          updateDto.accountId,
          updateDto.platform
        );
      dispatch(getAccountDefaultFeaturesSuccess(AccountDefaultFeatures));
    } catch (err) {
      dispatch(getAccountDefaultFeaturesFailed(err.message));
      return;
    }
  };

export const getMasterFeatures =
  (): defaultfeaturesAppThunk => async (dispatch) => {
    try {
      dispatch(getMasterFeaturesStart());
      const masterFeatures: MasterFeaturesData[] =
        await AccountsSdk.getInstance().backofficeAccountDefaultFeatures.getAllMasterFeatures();
      dispatch(getMasterFeaturesSuccess(masterFeatures));
    } catch (err) {
      dispatch(getMasterFeaturesFailed(err.message));
      return;
    }
  };

export const getAccountMasterFeatures =
  (
    accountId: number,
    platform: string,
    forceLoad?: boolean
  ): defaultfeaturesAppThunk =>
  async (dispatch) => {
    try {
      dispatch(getMasterFeaturesPerAccountStart());
      const accountDefaultFeatures: MasterFeaturesData[] =
        await AccountsSdk.getInstance().backofficeAccountDefaultFeatures.getAccountMasterFeatures(
          accountId,
          platform
        );
      dispatch(getMasterFeaturesPerAccountSuccess(accountDefaultFeatures));
    } catch (err) {
      dispatch(getMasterFeaturesPerAccountFailed(err.message));
      return;
    }
  };

export const addAccountMasterFeature =
  (
    updateDto: AddMasterFeatureDto,
    forceLoad?: boolean
  ): defaultfeaturesAppThunk =>
  async (dispatch) => {
    try {
      dispatch(getMasterFeaturesPerAccountStart());
      await AccountsSdk.getInstance().backofficeAccountDefaultFeatures.addAccountMasterFeature(
        updateDto
      );
      const accountDefaultFeatures: MasterFeaturesData[] =
        await AccountsSdk.getInstance().backofficeAccountDefaultFeatures.getAccountMasterFeatures(
          updateDto.accountId,
          updateDto.platform
        );
      dispatch(getMasterFeaturesPerAccountSuccess(accountDefaultFeatures));
    } catch (err) {
      dispatch(getMasterFeaturesPerAccountFailed(err.message));
      return;
    }
  };

export const deleteAccountMasterFeature =
  (
    updateDto: DeleteMasterFeatureDto,
    forceLoad?: boolean
  ): defaultfeaturesAppThunk =>
  async (dispatch) => {
    try {
      dispatch(getMasterFeaturesPerAccountStart());
      await AccountsSdk.getInstance().backofficeAccountDefaultFeatures.deleteAccountMasterFeature(
        updateDto
      );
      const accountDefaultFeatures: MasterFeaturesData[] =
        await AccountsSdk.getInstance().backofficeAccountDefaultFeatures.getAccountMasterFeatures(
          updateDto.accountId,
          updateDto.platform
        );
      dispatch(getMasterFeaturesPerAccountSuccess(accountDefaultFeatures));
    } catch (err) {
      dispatch(getMasterFeaturesPerAccountFailed(err.message));
      return;
    }
  };
