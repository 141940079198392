import styled from 'styled-components';
import { Divider, DropdownSelection, Menu } from '@walkme/ui-core';
import { createStyles, makeStyles } from '@material-ui/core';

export const StyledContainer = styled.div`
  padding: 0 8px;
`;

export const StyledSelector = styled(DropdownSelection)`
  overflow: hidden;
  button {
    height: 40px;
    width: 300px;
    margin: 0;
    outline: none !important;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0 16px;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
    menuItemText: {
      color: 'var(--typography-typography-main-primary-main, #2F426C)',
      fontFamily: 'Proxima Nova',
      fontSize: '12px',
      fontStyle: 'normal',
      lineHeight: '16px',
    },
    applyButton: {
      display: 'flex !important',
      height: '48px !important',
      padding: '14px 10px !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      gap: '10px !important',
      alignSelf: 'stretch !important',
      width: '100% !important',
    },
    tagLength: {
      borderRadius: '4px',
      padding: '4px 8px',
      background: 'var(--blue-primary-primary-tint-9, #FBFCFF)',
      color: 'var(--infographic-blue-blue-shade-1, #162A69)',
      fontFamily: 'Proxima Nova',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
    },
    expectedFormatSelectorDiv: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
    uuidText: {
      color: 'var(--typography-typography-main-primary-main, #2F426C)',
      fontFamily: 'Proxima Nova',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '16px',
    },
    uuidButtonText: {
      color: 'var(--typography-typography-main-primary-main, #2f426c)',
      fontFamily: 'Proxima Nova',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '16px',
    },
  })
);

export const StyledMenuItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? '#385FEB' : '#2F426C')};
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  &:hover {
    background: var(--Primaries-Blue-P8, #f5f7fe);
  }
`;

export const StyledWMMenu = styled(Menu)`
  margin-top: 8px;

  .MuiMenu-list {
    padding: 0 !important;
    width: 300px !important;
  }
`;
