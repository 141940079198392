import moment from 'moment/moment';

export const dateValueFormatter = (params) => {
  const dateFormat = 'MMM Do YYYY, h:mm A';

  if (params?.colDef?.field === 'updatedAt') {
    return moment(params.data.updatedAt).format(dateFormat);
  } else if (params?.colDef?.field === 'createdAt') {
    return moment(params.data.createdAt).format(dateFormat);
  }
};
