import { Typography } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@walkme/ui-core';
import { WMIconWarning } from '@walkme/wm-ui';
import React, { useCallback, useRef, useState } from 'react';
import { FeatureFlag } from '@wm-accounts-backoffice-center/state-management-features';
import { exportData } from './utils';

export enum FeaturesExportWarningDialogType {
  hidden = 'hidden',
  exportEnabled = 'enabled',
  exportSelected = 'selected',
}

export interface FeaturesExportWarningDialogProps {
  features: FeatureFlag[];
  onClose: () => void;
  isOpen: boolean;
  type: FeaturesExportWarningDialogType;
}

export function FeaturesExportWarningDialog({
  features,
  isOpen,
  onClose,
  type,
}: FeaturesExportWarningDialogProps) {
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogTitle>Export {type} features</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to export{' '}
          <b
            style={{
              color: '#2f426c',
              fontSize: '18px',
            }}
          >
            {' '}
            {`${features.length} ${type}`}
          </b>{' '}
          features
        </DialogContentText>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 16px',
            marginBottom: '10px',
            marginTop: '10px',
            backgroundColor: '#FEF8E8',
            border: `1px solid #F7B500`,
            borderRadius: '4px',
          }}
        >
          <WMIconWarning
            color={'#F7B500'}
            size={20}
            style={{ flexShrink: 0, paddingRight: '12px' }}
          />
          <Typography>
            All{' '}
            {type === FeaturesExportWarningDialogType.exportEnabled
              ? 'enabled'
              : 'selected'}{' '}
            features will be exported. Make sure the features are filtered as
            intended.
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color={'primary'}
          onClick={() => {
            exportData(features);
            onClose();
          }}
          variant="solid"
        >
          {'Export'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default FeaturesExportWarningDialog;
