import { Role, System, WMAccountIdp, WMUser } from 'wm-accounts-sdk';
import {
  MergeAccountsProcessDto,
  MergeAccountsResult,
} from '../api/merge-account-api.types';
import { MergeProcGridRow } from './merge-log.types';
import { InitiatorCell } from './components/intiator.cell';
import { TimestampCell } from './components/timestamp.cell';
import { doesMergeHaveErrors } from '../merge-summary/merge-summary.lib';
import { StatusCell } from './components/status.cell';
import { ColDef } from '@ag-grid-community/core';
import { SummaryCell } from './components/summary.cell';
import { TargetAccountCell } from './components/target-account.cell';
import { SourceAccountCell } from './components/source-account.cell';

export const transformToGridRow = (
  mergeProcess: MergeAccountsProcessDto
): MergeProcGridRow => {
  if (mergeProcess && mergeProcess.result) {
    const result: MergeProcGridRow = {
      id: mergeProcess._id,
      timestamp: mergeProcess.createdAt as string,
      initiator: mergeProcess.initiator?.email,
      fromAccount: mergeProcess.result.fromAccountData?.name,
      toAccount: mergeProcess.result.toAccountData?.name,
      summary: getMergeResultSummary(mergeProcess.result),
      hasErrors: doesMergeHaveErrors(mergeProcess.result),
    };
    return result;
  }
  return null;
};

export const getMergeResultSummary = (
  mergeResult: MergeAccountsResult
): string => {
  const systemsCount = ((mergeResult.systems?.value as Partial<System>[]) || [])
    .length;
  const usersCount = ((mergeResult.users?.value as WMUser[]) || []).length;
  const rolesCount = ((mergeResult.roles?.value as Role[]) || []).length;
  const idpsCount = ((mergeResult.idps?.value as WMAccountIdp[]) || []).length;
  const apiKeysCount = ((mergeResult.apiKeys?.value as string[]) || []).length;

  const systemsString = generateEnditySummary(
    systemsCount,
    'system',
    'systems'
  );
  const usersString = generateEnditySummary(usersCount, 'user', 'users');
  const rolesString = generateEnditySummary(rolesCount, 'role', 'roles');
  const idpsString = generateEnditySummary(idpsCount, 'idp', 'idps');
  const apiKeysString = generateEnditySummary(
    apiKeysCount,
    'api key',
    'api keys'
  );

  const stringsArray = [
    systemsString,
    usersString,
    rolesString,
    idpsString,
    apiKeysString,
  ].filter((s) => s);
  let entitiesMergedString = '';
  if (stringsArray.length === 1) {
    entitiesMergedString = `${stringsArray[0]} was merged`;
  } else if (stringsArray.length === 2) {
    entitiesMergedString = `${stringsArray[0]} and ${stringsArray[1]} were merged`;
  } else if (stringsArray.length >= 3) {
    entitiesMergedString = `${stringsArray
      .slice(0, stringsArray.length - 1)
      .join(', ')}, and ${stringsArray[stringsArray.length - 1]} were merged`;
  }

  return entitiesMergedString;
};

export const generateEnditySummary = (
  count: number,
  singulerName: string,
  pluralName: string
): string => {
  if (count === 0) return null;
  if (count === 1) return `${count} ${singulerName}`;
  else return `${count} ${pluralName}`;
};

export const gridColumns: ColDef<MergeProcGridRow>[] = [
  {
    headerName: 'Date',
    sortable: true,
    cellRenderer: TimestampCell,
  },
  {
    headerName: 'Initiator',
    sortable: true,
    cellRenderer: InitiatorCell,
  },
  {
    field: 'fromAccount',
    headerName: 'Source Account',
    sortable: true,
    cellRenderer: SourceAccountCell,
  },
  {
    field: 'toAccount',
    headerName: 'Target Account',
    sortable: true,
    cellRenderer: TargetAccountCell,
  },
  {
    headerName: 'Status',
    cellRenderer: StatusCell,
    sortable: false,
  },
  {
    headerName: 'Summary',
    sortable: false,
    cellRenderer: SummaryCell,
  },
];
