import React from 'react';
import { StyledDataBuildingSettings } from './data-building-settings.styles';
import { IdpSection } from './idp/idp-section';
import { SystemThemingSection } from './system-theming/system-theming-section';

export const DataBuildingSettings = () => {
  return (
    <StyledDataBuildingSettings>
      <IdpSection />
      <SystemThemingSection />
    </StyledDataBuildingSettings>
  );
};
