import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ChildProcessView } from './child-process';
import { StatusIndicator } from './status-indicator';
import { indentationConstant } from './consts';

const useStyles = makeStyles(() => ({
  logViewEvent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 20px 5px 20px ',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

export const EventView = ({ event, isSpecific, indentFactor = 0 }) => {
  const [openEvent, setOpenEvent] = useState(true);

  const classes = useStyles();
  return (
    <div>
      {event.childProcesses ? (
        <div>
          {event.childProcesses.map((childProc, index) => {
            return (
              <ChildProcessView
                key={index}
                childProcess={childProc}
                indentFactor={indentFactor}
                isSpecific={isSpecific}
              />
            );
          })}
        </div>
      ) : (
        <div
          className={classes.logViewEvent}
          onClick={() => setOpenEvent(!openEvent)}
          style={{
            background: openEvent ? '#F7F9FC' : '#FFF',
            paddingLeft: `${20 + indentFactor * indentationConstant}px`,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* set indentation instead of the chevron: */}
            <div style={{ display: 'inline-block', width: '20px' }}></div>
            {event.type}
          </div>
          <StatusIndicator status={event.status} errors={event.errors} />
        </div>
      )}
    </div>
  );
};

//   <div
//     className={classes.logViewEvent}
//     onClick={() => setOpenEvent(!openEvent)}
//     style={{
//       background: openEvent ? '#F7F9FC' : '#FFF',
//       paddingLeft: `${32 + (indentFactor * indentationConstant)}px`
//     }}
//   >
//     <div style={{ display: 'flex', alignItems: 'center' }}>
//       {
//         event.childProcesses ?
//           (openEvent ? <ChevronDown /> : <ChevronRight /> ) :
//           (<div style={{display: 'inline-block', width: '20px'}}></div>) // set indentation instead of the chevron
//         }
//       {event.type}
//     </div>
//     <StatusIndicator status={event.status} />
//   </div>
//   {openEvent &&
//     event.childProcesses &&
//     event.childProcesses.map(childProc => {
//       return (
//         <ChildProcessView
//           childProcess={childProc}
//           indentFactor={indentFactor + 1}
//         />
//       )
//     })}
