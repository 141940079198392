import { Spinner, ToasterVariant, useToaster } from '@walkme/ui-core';
import React, { useCallback, useEffect, useState } from 'react';
import { System, SystemPurpose } from 'wm-accounts-sdk';
import { DropdownSelectionMenuNoSearch } from '../dropdown-aelection-menu-no-search';
import { InfoTitle, StyledInforamtion } from '../styles/styles';
import { accountSystemManagerApi } from '@wm-accounts-backoffice-center/wm-api';

export interface SystemPurposeSelectorProps {
  system: System;
  accountId: number;
}

export function SystemPurposeSelectoPage({
  accountId,
  system,
}: SystemPurposeSelectorProps) {
  const { addToaster } = useToaster();
  const [updatingSystemPurpose, setUpdatingSystemPurpose] = useState(true);
  const [systemPurpose, setSystemPurpose] = useState('');

  const items = [
    {
      label: 'Trial',
      value: 'TRIAL',
    },
    {
      label: 'Commercial',
      value: 'COMMERCIAL',
    },
  ];

  const onSystemPurposeChange = useCallback(
    async (systemPurpose: SystemPurpose) => {
      try {
        setUpdatingSystemPurpose(true);
        const respone = await accountSystemManagerApi.updateSystemPurpose(
          system.id,
          accountId,
          systemPurpose
        );

        setUpdatingSystemPurpose(false);
        if (respone) {
          setSystemPurpose(systemPurpose);
        }
        addToaster({
          message: `successfully updated system purpose`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      } catch (e) {
        setUpdatingSystemPurpose(false);
        addToaster({
          message: `failed to update system purpose. ${e}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      }
    },
    [accountId, system, setUpdatingSystemPurpose]
  );

  const onGetSystemPurpose = useCallback(async () => {
    try {
      const systemPurposeResult =
        await accountSystemManagerApi.getSystemPurpose(system.id);
      setUpdatingSystemPurpose(true);
      setSystemPurpose(systemPurposeResult);
      setUpdatingSystemPurpose(false);
    } catch (e) {
      addToaster({
        message: `failed to get system purpose. ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [accountId, system, setUpdatingSystemPurpose]);

  useEffect(() => {
    onGetSystemPurpose();
  }, [system]);

  return (
    <StyledInforamtion>
      <InfoTitle style={{ marginBottom: '5px' }}>System Purpose</InfoTitle>
      {updatingSystemPurpose ? (
        <Spinner></Spinner>
      ) : (
        <DropdownSelectionMenuNoSearch
          items={items}
          selectedValue={systemPurpose}
          onChange={(newStatus) => onSystemPurposeChange(newStatus)}
        />
      )}
    </StyledInforamtion>
  );
}

export default SystemPurposeSelectoPage;
