import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import mergeDataReducer from './merge-data.slice';
import selectSourceAccountReducer from './main-page/wizard/steps/select-source-account/select-source-account.step.slice';
import excludeSystemsReducer from './main-page/wizard/steps/exclude-systems/exclude-systems.step.slice';
import excludeUsersReducer from './main-page/wizard/steps/exclude-users/exclude-users.step.slice';
import selectTargetAccountReducer from './main-page/wizard/steps/select-target-account/select-target-account.step.slice';
import reviewAndMergeReducer from './main-page/wizard/steps/review-and-merge/review-and-merge.step.slice';
import wizardReducer from './main-page/wizard/merge-account-wizard.slice';
import confirmationDialogReducer from './main-page/confirmation/confirmation-dialog.slice';
import mergeLogReducer from './merge-log/merge-log.slice';

const mergeAccountReducer = combineReducers({
  mergeData: mergeDataReducer,
  wizard: wizardReducer,
  selectSourceAccount: selectSourceAccountReducer,
  excludeSystems: excludeSystemsReducer,
  excludedUsers: excludeUsersReducer,
  selectTargetAccount: selectTargetAccountReducer,
  reviewAndMerge: reviewAndMergeReducer,
  confimation: confirmationDialogReducer,
  mergeLogs: mergeLogReducer,
});

export const mergeAccountStore = configureStore({
  reducer: mergeAccountReducer,
});

export type MergeAccountState = ReturnType<typeof mergeAccountReducer>;

export type AppThunk = ThunkAction<
  void,
  MergeAccountState,
  unknown,
  Action<string>
>;
