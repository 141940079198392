import { Spinner, ToasterVariant, useToaster } from '@walkme/ui-core';
import { publishApi } from '@wm-accounts-backoffice-center/wm-api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { System } from 'wm-accounts-sdk';
import { DropdownSelectionMenuNoSearch } from '../dropdown-aelection-menu-no-search';
import { InfoTitle, StyledInforamtion } from '../styles/styles';

export interface KeepSaasAliveSelectorProps {
  system: System;
  accountId: number;
}

export function KeepSaasAliveSelectoPage({
  accountId,
  system,
}: KeepSaasAliveSelectorProps) {
  const { addToaster } = useToaster();
  const [updatingKeepSaasAlive, setUpdatingKeepSaasAlive] = useState(true);
  const [isKeepSaasAlive, setKeepSaasAlive] = useState(false);

  const items = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const onKeepSaasAliveChange = useCallback(
    async (keepSaasAlive: boolean) => {
      try {
        setUpdatingKeepSaasAlive(true);
        const response = await publishApi.updateKeepSaaSAliveStatus(
          system.id,
          keepSaasAlive
        );
        const isKeepSaasAliveAfterUpdate =
          await publishApi.getKeepSaaSAliveStatus(system.id);
        setKeepSaasAlive(isKeepSaasAliveAfterUpdate);
        setUpdatingKeepSaasAlive(false);
        addToaster({
          message: `successfully updated keep SaaS alive`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      } catch (e) {
        setUpdatingKeepSaasAlive(false);
        addToaster({
          message: `failed to update keep SaaS alive. ${e}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      }
    },
    [accountId, system, setUpdatingKeepSaasAlive]
  );

  const onGetKeepSaasAlive = useCallback(async () => {
    try {
      setUpdatingKeepSaasAlive(true);
      const isKeepSaasAliveEnabled = await publishApi.getKeepSaaSAliveStatus(
        system.id
      );
      setKeepSaasAlive(isKeepSaasAliveEnabled);
      setUpdatingKeepSaasAlive(false);
    } catch (e) {
      addToaster({
        message: `failed to get keep SaaS alive. ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [accountId, system, setUpdatingKeepSaasAlive]);

  useEffect(() => {
    onGetKeepSaasAlive();
  }, [system]);

  return (
    <StyledInforamtion>
      <InfoTitle style={{ marginBottom: '5px' }}>
        System Keep SaaS Alive
      </InfoTitle>
      {updatingKeepSaasAlive ? (
        <Spinner></Spinner>
      ) : (
        <DropdownSelectionMenuNoSearch
          items={items}
          selectedValue={isKeepSaasAlive}
          onChange={(newStatus) => onKeepSaasAliveChange(newStatus)}
        />
      )}
    </StyledInforamtion>
  );
}

export default KeepSaasAliveSelectoPage;
