import { Spinner, ToasterVariant, useToaster } from '@walkme/ui-core';
import {
  accountsGraphqlCaller,
  publishApi,
} from '@wm-accounts-backoffice-center/wm-api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { System } from 'wm-accounts-sdk';
import { DropdownSelectionMenuNoSearch } from '../dropdown-aelection-menu-no-search';
import { InfoTitle, StyledInforamtion } from '../styles/styles';
import {
  PoweredByFields,
  poweredBySelectorLabels,
  poweredBySelectOptions,
} from './powered-by-link.lib';

export interface PoweredByLinkSelectorProps {
  system: System;
  accountId: number;
  poweredByField?: PoweredByFields;
}

export function PoweredByLinkSelectorPage({
  accountId,
  system,
  poweredByField = PoweredByFields.DISPLAY_POWERED_BY,
}: PoweredByLinkSelectorProps) {
  const { addToaster } = useToaster();
  const [updatingPoweredByLink, setUpdatingPoweredByLink] = useState(true);
  const [isPoweredByLink, setIsPoweredByLink] = useState(false);

  const onPoweredByLinkChange = useCallback(
    async (poweredByLink: boolean) => {
      try {
        setUpdatingPoweredByLink(true);
        const response = await accountsGraphqlCaller.editPoweredByConfig({
          userId: system.id,
          [poweredByField]: poweredByLink,
        });
        const sitesConfig = await accountsGraphqlCaller.getPoweredByConfig(
          system.id,
          [poweredByField]
        );
        const isPoweredByLinkAfterUpdate = sitesConfig[0]?.[poweredByField];
        setIsPoweredByLink(isPoweredByLinkAfterUpdate);
        setUpdatingPoweredByLink(false);
        addToaster({
          message: `successfully updated powered by WalkMe link`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      } catch (e) {
        setUpdatingPoweredByLink(false);
        addToaster({
          message: `failed to update powered by WalkMe link. ${e}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      }
    },
    [accountId, system, setUpdatingPoweredByLink]
  );

  const onGetPoweredByLink = useCallback(async () => {
    try {
      setUpdatingPoweredByLink(true);
      const sitesConfigRes = await accountsGraphqlCaller.getPoweredByConfig(
        system.id,
        [poweredByField]
      );
      const isPoweredByLink = sitesConfigRes[0]?.[poweredByField];
      setIsPoweredByLink(isPoweredByLink);
      setUpdatingPoweredByLink(false);
    } catch (e) {
      addToaster({
        message: `failed to get powered by WalkMe link. ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [accountId, system, setUpdatingPoweredByLink]);

  useEffect(() => {
    onGetPoweredByLink();
  }, [system]);

  return (
    <StyledInforamtion>
      <InfoTitle style={{ marginBottom: '5px' }}>
        {poweredBySelectorLabels[poweredByField]}
      </InfoTitle>
      {updatingPoweredByLink ? (
        <Spinner></Spinner>
      ) : (
        <DropdownSelectionMenuNoSearch
          items={poweredBySelectOptions}
          selectedValue={isPoweredByLink}
          onChange={(newStatus) => onPoweredByLinkChange(newStatus)}
        />
      )}
    </StyledInforamtion>
  );
}

export default PoweredByLinkSelectorPage;
