import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Spinner,
  ToasterVariant,
  useToaster,
} from '@walkme/ui-core';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AccountsSdk, User } from 'wm-accounts-sdk';

export interface UserResendConfirmationMailProps {
  user: User;
  accountId: number;
  onClose: () => void;
  isOpen: boolean;
}

export function UserResendConfirmationMail({
  user,
  accountId,
  isOpen,
  onClose,
}: UserResendConfirmationMailProps) {
  const dispatch = useDispatch();
  const [resendingActivation, setResendingActivation] = useState(false);
  const { addToaster } = useToaster();

  const onResendActivationEmail = useCallback(async () => {
    try {
      setResendingActivation(true);
      await AccountsSdk.getInstance().backofficeUsers.resendConfirmationMail(
        accountId,
        user.id
      );
      addToaster({
        message: `resend activation email to ${user.email} was successfully sent.`,
        variant: ToasterVariant.Success,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
      setResendingActivation(false);
      onClose();
    } catch (e) {
      setResendingActivation(false);
      addToaster({
        message: `resend activation email to ${user.email} was falied. ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [user, accountId, setResendingActivation, dispatch, onClose]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogTitle>Resend activation email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will intiated new activation email for <b>{user?.email}</b>{' '}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={resendingActivation}
          onClick={onResendActivationEmail}
          variant="solid"
          color="primary"
        >
          {resendingActivation ? <Spinner /> : 'Send'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default UserResendConfirmationMail;
