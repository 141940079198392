import { makeStyles } from '@material-ui/core';

const useContractsStyles = makeStyles({
  contractsWrapper: {
    height: '100%',
    overflowY: 'auto',
    padding: '0 10px',
  },
});

export { useContractsStyles };
