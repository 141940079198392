import React from 'react';
import styled from 'styled-components';

export const AccountCardItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`
export const SystemCardItems = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 50%;
`
export const UserCardItems = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 50%;
`


export const SystemIconWrapper = styled.div`
  margin-right: 12px;
  margin-left: 24px
`

export const SystemIconBackground = styled.div`
  background: #F0F3F9;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`
