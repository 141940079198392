import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppThunk } from '../../merge-account.store';
import { mergeSubmitted } from '../../merge-data.slice';
import { wizardClosed } from '../wizard/merge-account-wizard.slice';

export interface ConfirmationDialogState {
  isDialogVisible: boolean;
}

export const initialConfirmationState: ConfirmationDialogState = {
  isDialogVisible: false,
};

const confirmationDialogSlice = createSlice({
  name: 'wizard',
  initialState: initialConfirmationState,
  reducers: {
    showConfirmationDialog(state: ConfirmationDialogState) {
      state.isDialogVisible = true;
    },
    hideConfirmationDialog(state: ConfirmationDialogState) {
      state.isDialogVisible = false;
    },
    mergeRequestPendingChanged(
      state: ConfirmationDialogState,
      action: PayloadAction<boolean>
    ) {
      // this is a one way action: a merge request has been submitted - close the dialog. Not the other way around
      const isMergeRequestPending = action.payload;
      if (isMergeRequestPending) {
        state.isDialogVisible = false;
      }
    },
  },
});

export const {
  showConfirmationDialog,
  hideConfirmationDialog,
  mergeRequestPendingChanged,
} = confirmationDialogSlice.actions;

export const dialogClosed = (): AppThunk => async (dispatch) => {
  // Hide the dialog and reset all states
  dispatch(hideConfirmationDialog());
};

export const actionConfirmed = (): AppThunk => async (dispatch) => {
  dispatch(hideConfirmationDialog());
  dispatch(wizardClosed());
  dispatch(mergeSubmitted());
};

export default confirmationDialogSlice.reducer;
