import React, { ChangeEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  WMButton,
  WMButtonVariant,
  WMDivider,
  WMIconClose,
  WMSelect,
  WMTextField,
} from '@walkme/wm-ui';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { useGetFeaturesTypes } from '@wm-accounts-backoffice-center/state-management-features';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '20px',
  },
  footer: {
    float: 'right',
    paddingTop: '1rem',
  },
  formInput: {
    paddingTop: '1rem',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#2F426C',
  },
  headerDiv: {
    marginBottom: '32px',
  },
  titleSection: {
    padding: '0 0 10px',
    fontFamily: 'ProximaNova',
    fontSize: '14px',
    color: '#2F426C',
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
  },
  wizardDialog: {
    borderRadius: 15,
    width: '496px',
  },
  wizardDialogButtons: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    margin: '10px',
  },
  wizardDialogSeconaryButton: {
    marginRight: '22px',
  },
  wizardDialogContent: {
    marginLeft: '40px',
  },
  contentStyle: {
    padding: '24px 32px 32px',
  },
  select: {
    '& .css-10szhiz-control': {
      backgroundColor: 'var(--wmBackground)',
      height: '40px',
      maxHeight: 'none',
    },
    '& .css-xr1weh-placeholder': {
      color: '#8D97AE',
    },
    '& .css-prf4vp-singleValue': {
      color: '#8D97AE',
    },
    '& .css-2p9bh7-control': {
      backgroundColor: 'var(--wmBackground)',
      height: '40px',
      maxHeight: 'none',
    },
  },
  styledTextField: {
    '& .WMTextField-input': {
      color: '#8D97AE',
    },
    '& .WMTextField-inputRoot': {
      height: '40px',
    },
  },
}));

export interface CreateFeatureFlagProps {
  showDialog: boolean;
  exit: () => void;
  createNewFF: (
    featureFlagName: string,
    description: string,
    featureType: string
  ) => void;
  loading: boolean;
}

const CreateFeatureFlag = ({
  showDialog,
  exit,
  createNewFF,
  loading,
}: CreateFeatureFlagProps) => {
  const classes = useStyles();

  const [featureFlagName, setFeatureFlagName] = useState('');
  const [description, setDescription] = useState('');
  const { featureFlagTypesData } = useGetFeaturesTypes();
  const filteredFeatureTypeOptions = featureFlagTypesData.data
    ? featureFlagTypesData.data.filter(
        (featureType) => !featureType.strictForNewFeatures
      )
    : [];
  const featuresOptionsRow = filteredFeatureTypeOptions.map((featureType) => ({
    value: featureType.value,
    label: featureType.displayName,
  }));
  const [selectedFeatureTypeRow, setSelectedFeatureTypeRow] = useState(
    featuresOptionsRow[0]
  );

  const onChangeFeatureType = (newFeatureType: {
    value: string;
    label: string;
  }): void => {
    setSelectedFeatureTypeRow(newFeatureType);
  };

  const createFF = () => {
    createNewFF(featureFlagName, description, selectedFeatureTypeRow.value);
  };

  const [flagNameValidationError, setFlagNameValidationError] =
    useState(undefined);

  const handleFlagNameChange = (newValue) => {
    setFeatureFlagName(newValue);

    if (/\s/g.test(newValue)) {
      return setFlagNameValidationError(
        'feature flags with white spaces are prohibited'
      );
    }

    if (!/^[a-zA-Z0-9_:.-]+$/.test(newValue)) {
      return setFlagNameValidationError(
        'the value can not contain special characters'
      );
    }

    setFlagNameValidationError(undefined);
  };

  return (
    <Dialog
      onClose={exit}
      open={showDialog}
      classes={{ paper: classes.wizardDialog }}
    >
      <DialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <label className={classes.title}>Create a feature</label>
          <WMButton style={{ color: 'black' }} variant={WMButtonVariant.Text}>
            <WMIconClose size={13} onClick={exit}></WMIconClose>
          </WMButton>
        </Box>
        <WMDivider />
      </DialogTitle>
      <DialogContent className={classes.contentStyle}>
        <div className={classes.headerDiv}>
          <label className={classes.titleSection}>Feature Name</label>
          <WMTextField
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleFlagNameChange(event.target.value)
            }
            inputProps={{ fontfamiliy: 'proximaNova' }}
            style={{ height: '40px' }}
            placeholder="Type feature name"
            className={classes.styledTextField}
            value={featureFlagName}
            status={flagNameValidationError ? 'error' : 'success'}
            error={flagNameValidationError}
          />
        </div>
        <div className={classes.headerDiv}>
          <label className={classes.titleSection}>Feature Description</label>
          <WMTextField
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setDescription(event.target.value)
            }
            inputProps={{ fontfamiliy: 'proximaNova' }}
            placeholder="Type feature description"
            className={classes.styledTextField}
            value={description}
          />
        </div>
        <div className={classes.headerDiv}>
          <label className={classes.titleSection}>Feature Type</label>
          <WMSelect
            className={classes.select}
            options={featuresOptionsRow}
            value={selectedFeatureTypeRow}
            onChange={onChangeFeatureType}
            placeholder="Select feature type"
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.wizardDialogButtons}>
        <WMButton
          variant={WMButtonVariant.Text}
          className={classes.wizardDialogSeconaryButton}
          onClick={exit}
          disabled={loading}
          style={{ height: '40px', borderRadius: '40px' }}
        >
          Cancel
        </WMButton>
        <WMButton
          onClick={createFF}
          loading={loading}
          disabled={
            loading ||
            !featureFlagName ||
            !description ||
            flagNameValidationError
          }
          style={{ height: '40px', borderRadius: '40px' }}
        >
          Create a Feature
        </WMButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFeatureFlag;
