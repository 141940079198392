import React from 'react';
import { StatusVariant } from '@walkme/ui-core';
import { StyledStatus } from '../merge-log.styles';

export const StatusCell = (params) => {
  const { hasErrors } = params.data;

  const hasErrorsString = 'Completed with some errors';
  const noErrorsString = 'Completed successfully';

  return (
    <>
      <StyledStatus
        variant={!hasErrors ? StatusVariant.Success : StatusVariant.Error}
      />
      {hasErrors ? hasErrorsString : noErrorsString}
    </>
  );
};
