import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  StyledPageContainer,
  StyledTabs,
  StyledNoPermissions,
  SubTitle,
  Title,
} from './developer-hub-page.styles';
import { DeveloperHubPageTabsIndex } from './developer-hub-page.types';
import { TabItem } from '@walkme/ui-core';
import { useLoggedInUser } from '@wm-accounts-backoffice-center/state-management-users';
import { EmptyLabel } from '@wm-accounts-backoffice-center/shared-styles';
import { SearchingSVG } from './assets/svgs/Searching';
import { WebhooksManagement } from './webhooks-management/webhooks-management';
import { Provider } from 'react-redux';
import { webHooksStore } from './webhooks-management/state-management-webhooks/src/lib/redux/webhooks.store.';
import {
  WMhttpClient,
  wmhttpClientSharedInstance,
} from 'libs/wm-api/src/lib/wm-http-client';
import { MergeAccountWrapper } from './merge-account';
import { generateSystemIconUrl } from '@wm-accounts-backoffice-center/wm-api';

const ServiceContext = React.createContext(null);

export const DeveloperHubPage = () => {
  const { tabName, id, webHookAlias } =
    useParams<{ tabName; id; webHookAlias }>();
  const [activeTab, setActiveTab] = useState<number>(
    DeveloperHubPageTabsIndex.WEBHOOKS_MANAGEMENT
  );
  const { accountFeatureEnabled } = useLoggedInUser();
  const isPermittedToViewDeveloperHub = accountFeatureEnabled('developerHub');
  const handleTabChange = (newTabIndex: number) => {
    if (activeTab === newTabIndex) {
      return;
    }
    setActiveTab(newTabIndex);
  };

  return (
    <StyledPageContainer>
      <Title>Developer Hub</Title>
      <SubTitle>Manage development utilities</SubTitle>
      {isPermittedToViewDeveloperHub ? (
        <StyledTabs
          initialSelectedIndex={activeTab}
          onChange={(tabItem: TabItem) =>
            handleTabChange(Number(tabItem.value))
          }
          items={[
            {
              content: (
                <Provider store={webHooksStore}>
                  <WebhooksManagement />
                </Provider>
              ),
              label: 'Webhooks Explorer',
              value: '0',
            },
            {
              content: <>WEBHOOK LOGS</>,
              label: 'Webhooks Logs',
              value: '1',
            },
            {
              content: (
                <MergeAccountWrapper
                  wmHttpClient={wmhttpClientSharedInstance}
                  generateSystemIconUrl={generateSystemIconUrl}
                />
              ),
              label: 'Merge Account',
              value: '2',
            },
          ]}
        />
      ) : (
        <StyledNoPermissions>
          <SearchingSVG width={350} height={350} />
          <EmptyLabel>You don't have permissions to view this page</EmptyLabel>
        </StyledNoPermissions>
      )}
    </StyledPageContainer>
  );
};
