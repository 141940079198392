import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';
import axios from 'axios';

export interface PublishSettingsRequest {
  accountId: number;
  systemId: number;
  systemGuid: string;
  envName: string;
  environmentId: number;
}

type PublishStatus = 'done' | 'in-progress' | 'pending';
export interface PublishSettingsResponse {
  content: {
    params: { accountId?: number; systemId?: number };
    status: PublishStatus;
    id: string;
  };
  success: boolean;
}

class PublishApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  async publishSettings(
    publishSettings: PublishSettingsRequest
  ): Promise<PublishSettingsResponse> {
    let publishResponseStatus: PublishSettingsResponse;
    try {
      let response =
        await this.httpClient.instance.post<PublishSettingsResponse>(
          `${window.clientConfig.NX_EUX_BACKOFFICE_API_URL}/publish/publishSettings`,
          {
            environmentId: publishSettings.environmentId,
            envName: publishSettings.envName,
            accountId: publishSettings.accountId,
            systemId: publishSettings.systemId,
            userGuid: publishSettings.systemGuid,
            checkedItemsIds: '[]',
          }
        );
      if (!response.data?.success || !response.data?.content?.id) {
        throw new Error(`failed to publish settings`);
      }
      publishResponseStatus = response.data;
      try {
        let systemStatus = false;
        let retry = 1;
        while (!systemStatus) {
          publishResponseStatus = await this.getPublishSettingsStatusEux(
            publishSettings.systemId,
            response.data?.content?.id
          );
          systemStatus = publishResponseStatus.content.status === 'done';
          retry = retry + 1;
          const maxRetry =
            publishResponseStatus.content.status === 'pending' ? 2 : 4;
          if (retry > maxRetry) {
            console.log(
              `waited to long to publish settings`,
              publishResponseStatus
            );
            break;
          }
          !systemStatus &&
            (await new Promise((resolve) => setTimeout(resolve, 1000)));
        }
      } catch (err) {
        return;
      }
      return publishResponseStatus;
    } catch (e) {
      throw new Error(`failed to publish settings: ${e.message}`);
    }
  }

  async publishSettingsForFeatures(
    publishSettings: PublishSettingsRequest
  ): Promise<PublishSettingsResponse> {
    let publishResponseStatus: PublishSettingsResponse;
    try {
      let response =
        await this.httpClient.instance.post<PublishSettingsResponse>(
          `${window.clientConfig.NX_WM_API_URL}/workflow-manager/workflow/publish-features`,
          {
            systemId: publishSettings.systemId,
            params: {
              environmentId: publishSettings.environmentId,
              accountId: publishSettings.accountId,
              userId: publishSettings.systemId,
              systemId: publishSettings.systemId,
            },
          }
        );
      if (!response.data?.success || !response.data?.content?.id) {
        throw new Error(`failed to publish settings`);
      }
      publishResponseStatus = response.data;
      try {
        let systemStatus = false;
        let retry = 1;
        while (!systemStatus) {
          publishResponseStatus = await this.getPublishSettingsStatus(
            publishSettings.systemId,
            response.data?.content?.id
          );
          systemStatus = publishResponseStatus.content.status === 'done';
          retry = retry + 1;
          const maxRetry = 40;
          if (retry > maxRetry) {
            console.log(
              `waited to long to publish settings`,
              publishResponseStatus
            );
            break;
          }
          !systemStatus &&
            (await new Promise((resolve) => setTimeout(resolve, 1000)));
        }
      } catch (err) {
        return;
      }
      return publishResponseStatus;
    } catch (e) {
      throw new Error(`failed to publish settings: ${e.message}`);
    }
  }

  private async getPublishSettingsStatusEux(
    systemId: number,
    flowId: string
  ): Promise<PublishSettingsResponse> {
    try {
      const response = await this.httpClient.instance.get<{
        id: string;
        status: PublishStatus;
      }>(
        `${window.clientConfig.NX_EUX_BACKOFFICE_API_URL}/publish/publishStatus?systemId=${systemId}&id=${flowId}`
      );

      return {
        content: {
          params: {},
          status: response.data?.status,
          id: response.data?.id,
        },
        success: true,
      };
    } catch (e) {
      throw new Error(`failed to get publish settings status: ${e.message}`);
    }
  }

  private async getPublishSettingsStatus(
    systemId: number,
    flowId: string
  ): Promise<PublishSettingsResponse> {
    try {
      const response =
        await this.httpClient.instance.get<PublishSettingsResponse>(
          `${window.clientConfig.NX_WM_API_URL}/workflow-manager/workflow/${flowId}?poll=true&source=backoffice&systemId=${systemId}`
        );
      if (!response.data?.success) {
        throw new Error(`failed to get publish settings status`);
      }
      return response.data;
    } catch (e) {
      throw new Error(`failed to get publish settings status: ${e.message}`);
    }
  }

  async getSelfHostedStatus(systemId: number): Promise<boolean> {
    try {
      const response = await this.httpClient.instance.get<boolean>(
        `${window.clientConfig.NX_WM_API_URL}/analytics/Backoffice/GetIsSelfHostedData?requestedSystemId=${systemId}`
      );
      return response.data;
    } catch (e) {
      throw new Error(`failed to get self hosted data: ${e}`);
    }
  }

  async getKeepSaaSAliveStatus(systemId: number): Promise<boolean> {
    try {
      const response = await this.httpClient.instance.get<boolean>(
        `${window.clientConfig.NX_WM_API_URL}/analytics/Backoffice/GetIsKeepSaaSAliveData?requestedSystemId=${systemId}`
      );
      return response.data;
    } catch (e) {
      throw new Error(`failed to get keep saas alive data: ${e}`);
    }
  }

  async updateKeepSaaSAliveStatus(
    systemId: number,
    keepSaaSAlive: boolean
  ): Promise<void> {
    try {
      const response = await this.httpClient.instance.post<any>(
        `${window.clientConfig.NX_WM_API_URL}/analytics/Backoffice/UpdateKeepSaaSAliveData`,
        {
          requestedSystemId: systemId,
          keepSaaSAlive,
        }
      );
    } catch (e) {
      throw new Error(`failed to update keep saas alive data: ${e}`);
    }
  }

  async updateSelfHostedStatus(
    systemId: number,
    isSelfHosted: boolean
  ): Promise<void> {
    try {
      const response = await this.httpClient.instance.post<any>(
        `${window.clientConfig.NX_WM_API_URL}/analytics/Backoffice/UpdateIsSelfHostedData`,
        {
          requestedSystemId: systemId,
          isSelfHosted,
        }
      );
    } catch (e) {
      throw new Error(`failed to update self hosted data: ${e}`);
    }
  }
}

export const publishApi = new PublishApi(new WMhttpClient(''));
