import { styledTheme } from '@wm-accounts-backoffice-center/shared-styles';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';

export const StyledContent = styled(CardContent)`
  display: flex;
  justify-content: center;
  padding: 10px 16px;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledTopSection = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  border-bottom: 1px solid ${styledTheme.colors.separator};
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledTab = styled(Tab)`
  margin-right: 16px;
  margin-bottom: 5px;
  min-height: 0;
  .MuiTab-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const TitleTab = styled('label')`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2f426c;
`;

export const SubTitleTab = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
`;

export const EmptyLabel = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
  margin-top: 32px;
`;

export const InfoTitle = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
`;
export const InfoDetail = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #637191;
`;

export const StyledInforamtion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledGridDiv = styled.div`
  grid-gap: 2rem;
  //-webkit-padding-after: 3rem;
  border-radius: 0.5rem;
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  overflow-y: auto;
  overflow-x: hidden;
  //padding-block-end: 3rem;
`;

export const useStylesMainTabs = makeStyles(() => ({
  contentDivWithBanner: {
    paddingTop: '10px',
    paddingBottom: '32px',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  contentDiv: {
    padding: '32px',
  },
  reviewLink: {
    fontFamily: 'proximaNova',
    marginRight: '6px',
    fontSize: '13px',
    color: '#5C7CF5',
    lineHeight: 1.5,
    textDecoration: 'underline',
  },
  bannerIcon: {
    marginRight: '12px',
  },
  bannerGrid: {
    backgroundColor: '#FFF8E5',
    paddingBottom: '10px',
    paddingTop: '10px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '10px',
  },
  bannerTitle: {
    fontFamily: 'proximaNova',
    marginRight: '6px',
    fontSize: '13px',
    color: '#2F3D53',
    lineHeight: 1.5,
  },
  customBadge: {
    backgroundColor: '#D4DFF5',
    color: '#637191',
  },
  customDot: {
    transform: 'translate(12px, -10px)',
  },
  tabBoxes: { borderBottom: '1px solid #D4DFF5', marginBottom: '24px' },
  tab: {
    minWidth: '105px',
    color: '#93A8F4',
    fontSize: '14px',
    textTransform: 'none',
    padding: '0',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '20px',
  },
  activeTab: {
    minWidth: '105px',
    color: '#3B61EB',
    fontSize: '14px',
    textTransform: 'none',
    padding: '0',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '20px',
  },
  indicator: {
    backgroundColor: '#3B61EB',
    left: 0,
  },
  roleRowName: {
    fontFamily: 'proximaNova',
    color: '#2F426C',
    fontSize: '12px',
  },
  roleRowUsersCounter: {
    fontFamily: 'proximaNova',
    color: '#2F426C',
    fontSize: '12px',
  },
  userRowLastLogin: {
    fontFamily: 'proximaNova',
    color: '#2F426C',
    fontSize: '12px',
  },
  userRowEmail: {
    fontFamily: 'proximaNova',
    color: '#2F426C',
    fontSize: '14px',
  },
  userRowStatus: {
    marginLeft: '6px',
    fontFamily: 'proximaNova',
    color: '#2F426C',
    fontSize: '12px',
  },
  userRowName: {
    fontFamily: 'proximaNova',
    color: '#2F426C',
    fontSize: '12px',
  },
  userRowRole: {
    fontFamily: 'proximaNova',
    color: '#2F426C',
    fontSize: '12px',
  },
  deleteItemMenu: {
    '& .WMMenu-label': {
      color: '#EB3232',
    },
  },
  EnableButton: {
    border: '1px solid rgba(59, 97, 235, 0.2)',
    '&:hover': {
      border: '1px solid #385FEB',
    },
    borderRadius: '40px !important',
    color: '#385FEB !important',
    '& .WMDataGridActionDialog-actionButton': {
      color: '#385FEB',
      '&:hover': {
        color: '#385FEB',
      },
    },
    '& .WMDataGridActionDialog-actionIcon': {
      marginRight: '0px',
    },
  },
  DisableButton: {
    border: '1px solid rgba(235, 50, 50, 0.2)',
    '&:hover': {
      border: '1px solid #EB3232',
    },
    borderRadius: '40px !important',
    color: '#EB3232 !important',
    '& .WMDataGridActionDialog-actionButton': {
      padding: '5px 15px',
      color: '#EB3232',
      '&:hover': {
        color: '#EB3232',
      },
    },
    '& .WMDataGridActionDialog-actionIcon': {
      marginRight: '0px',
    },
  },
}));
