import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Loader, Spinner, Tabs, Typography } from '@walkme/ui-core';
import AccountsCollapse from './accounts-collapse';
import styled from 'styled-components';
import { WmSnippetBoxWrapper } from './WmSnippetBox/wm-snippet-box-wrapper';
import { LanguagesTypes } from './WmSnippetBox/wm-snippet-box';
import {
  DefaultSiteConfigOverride,
  SiteConfigOverrideFields,
  monolithApi,
} from '@wm-accounts-backoffice-center/wm-api';
import { SystemTypeKey } from 'wm-accounts-sdk';
import { PlatformOption } from './default-settings-page';
import { Account } from '@wm-accounts-backoffice-center/wm-api';
import {
  DataGridModules,
  WMAgGridWrapper,
  WMSwitch,
  WMTagList,
  WMSnackbar,
  WMSnackbarVariant,
  WMDialog,
  WMButtonVariant,
} from '@walkme/wm-ui';
import { StyledLoaderBox } from './default-settings-systems';
import { Box, Link } from '@material-ui/core';
import moment from 'moment';
import './styles.scss';
import { validateJson, validateCSS } from './WmSnippetBox/validators';

export interface DefaultSettingsSiteConfigProps {
  account: Account;
  selectedPlatform: PlatformOption;
  query?: string;
}

export const LoaderBox = styled(Box)``;

export const StyledWMDataGrid = styled(WMAgGridWrapper)`
  width: 100%;
  margin-left: 0;
  margin-right: auto;
  .ag-cell-focus {
    border-color: transparent !important;
  }
`;

const StyledTypographyHeader = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 4px;
`;

const StyledTypographyContent = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
`;

class siteConfigValues {
  PoweredByLink: boolean;
  IsDisplayPoweredBy: boolean;
  FallbackDisabled: boolean;
  CollectDataDisabled: boolean;
}

const siteConfigValuesMapping = {
  PoweredByLink: {
    displayName: 'Show “Powered By” Link',
    values: [true, false],
    isPremium: true,
  },
  IsDisplayPoweredBy: {
    displayName: 'Show “Powered By” Text',
    values: ['txt', 'no'],
    isPremium: true,
  },
  FallbackDisabled: {
    path: 'EndUserSettings',
    displayName: 'Don’t load WalkMe if a User Identifier is not found',
    values: [true, false],
  },
  CollectDataDisabled: {
    path: 'EndUserSettings',
    displayName: 'Don’t collect data if a User Identifier is not found',
    values: [true, false],
  },
};

interface ErrorsState {
  jsonError: string;
  cssError: string;
}
interface DialogState {
  data: object;
  nextState: boolean;
}

export function DefaultSettingsSiteConfigPage({
  account,
  selectedPlatform,
  query,
}: DefaultSettingsSiteConfigProps) {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [jsonSnippet, setJsonSnippet] = useState<string>('');
  const [isJsonValid, setIsJsonValid] = useState<boolean>(true);
  const [cssSnippet, setCssSnippet] = useState<string>('');
  const [isCssValid, setIsCssValid] = useState<boolean>(true);
  const [switchIsLoadingKey, setSwitchIsLoadingKey] = useState<string>('');
  const [forceUpdate, setForceUpdate] = useState<boolean>(true);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState<string>('');
  const [successSnackbarMessage, setSuccessSnackbarMessage] =
    useState<string>('');
  const [errorMessages, setErrorMessages] = useState<ErrorsState>({
    jsonError: '',
    cssError: '',
  });
  const [endUserSettingsJson, setEndUserSettingsJson] = useState<{
    [key: string]: any;
  }>({});
  const [newSiteConfigValues, setNewSiteConfigValues] =
    useState<siteConfigValues>(new siteConfigValues());
  const [siteConfigData, setSiteConfigData] = useState<object>({});
  const [dialogState, setDialogState] = useState<DialogState>({
    data: {},
    nextState: false,
  });

  useEffect(() => {
    setSiteConfigData({});
    setForceUpdate(true);
  }, [selectedPlatform]);

  useEffect(() => {
    const getSiteConfig = async () => {
      try {
        const data = await monolithApi.getSiteConfigOverrides(
          account.id,
          selectedPlatform?.value as SystemTypeKey
        );
        const siteConfigObj = data.siteConfigOverrides.reduce((acc, obj) => {
          acc[obj.FieldName] = {
            EnabledOn: data.settings.Fields.find(
              (field) => field.FieldName === obj.FieldName
            )?.EnabledOn,
            ...obj,
          };
          return acc;
        }, {});

        setSiteConfigData(siteConfigObj);
        setSwitchIsLoadingKey('');
      } catch (e) {
        setErrorSnackbarMessage('Error getting site config');
      }
    };
    if (forceUpdate) {
      getSiteConfig();
      setForceUpdate(false);
    }
  }, [selectedPlatform, forceUpdate]);

  useEffect(() => {
    const validatorResponse = validateJson(jsonSnippet);
    setErrorMessages({
      ...errorMessages,
      jsonError: validatorResponse?.errorMessage,
    });
    setIsJsonValid(validatorResponse?.isValid);
  }, [jsonSnippet]);

  useEffect(() => {
    const validatorResponse = validateCSS(cssSnippet);
    setErrorMessages({
      ...errorMessages,
      cssError: validatorResponse?.errorMessage,
    });
    setIsCssValid(validatorResponse?.isValid);
  }, [cssSnippet]);

  useEffect(() => {
    const endUserSettingsJson = siteConfigData['EndUserSettings']
      ? JSON.parse(siteConfigData['EndUserSettings']?.Value)
      : {};

    setJsonSnippet(siteConfigData['Custom']?.Value);
    setCssSnippet(siteConfigData['CustomCss']?.Value);
    setEndUserSettingsJson(endUserSettingsJson);

    setNewSiteConfigValues({
      PoweredByLink: siteConfigData['PoweredByLink']?.Value === 'True',
      IsDisplayPoweredBy:
        siteConfigData['IsDisplayPoweredBy']?.Value ===
        siteConfigValuesMapping.IsDisplayPoweredBy.values[0],
      FallbackDisabled: endUserSettingsJson?.FallbackDisabled || false,
      CollectDataDisabled: endUserSettingsJson?.CollectDataDisabled || false,
    });
  }, [siteConfigData]);

  const headCells = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        maxWidth: 600,
      },
      {
        headerName: 'Default status',
        maxWidth: 200,
        sortable: true,
        filter: true,
        cellClass: 'styled-cell',
        valueGetter: (params) => {
          return params.data.checked;
        },
        valueSetter: (params) => {
          var fullName = params.newValue;
          return true;
        },
        keyCreator: (params) => (params.value ? 'Enabled' : 'Disabled'),
        cellRenderer: function (params) {
          return (
            <div>
              {switchIsLoadingKey === params.data.key ? (
                <Spinner />
              ) : (
                <WMSwitch
                  ds2
                  checked={params.value}
                  disabled={!!switchIsLoadingKey}
                  label={
                    <span
                      style={{
                        display: 'flex',
                        color: '#2F426C',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontFamily: 'Proxima Nova',
                        fontStyle: 'normal',
                        lineHeight: '16px',
                      }}
                    >
                      {params.value ? 'Enabled' : 'Disabled'}
                    </span>
                  }
                  onChange={(event) => {
                    handleSwitch(params.data, event.target.checked);
                  }}
                />
              )}
            </div>
          );
        },
      },
      {
        field: 'enabledOn',
        headerName: 'Enabled on',
        maxWidth: 250,
        sortable: true,
        filter: true,
        cellClass: 'styled-cell',
        cellRenderer: function (params) {
          const displayNames = params.value.map((systemId) => {
            const object = account.systems.find(
              (system) => system.id === systemId
            );
            return object ? object.displayName : null;
          });

          const labels = displayNames.map((name) => ({
            labelText: name,
            fullLabel: true,
          }));
          return <WMTagList labels={labels} maxTagsShown={2} />;
        },
      },
      {
        field: 'enabledBy',
        headerName: 'Enabled by',
        maxWidth: 180,
        sortable: true,
        filter: true,
        cellClass: 'styled-cell',
      },
      {
        field: 'enabledDate',
        headerName: 'Enabled date',
        maxWidth: 180,
        sortable: true,
        cellClass: 'styled-cell',
        cellRenderer: function (params) {
          return moment(params.value).format('LL');
        },
      },
    ];
  }, [newSiteConfigValues, switchIsLoadingKey, endUserSettingsJson]);

  const siteConfigRowData = useMemo(() => {
    return Object.keys(newSiteConfigValues).map((key) => {
      const path = siteConfigValuesMapping[key]?.path;
      return {
        key,
        name: siteConfigValuesMapping[key]?.displayName,
        checked: newSiteConfigValues[key],
        enabledOn: siteConfigData[path || key]?.EnabledOn || [],
        enabledBy: siteConfigData[path || key]?.CreatedByUserEmail,
        enabledDate: siteConfigData[path || key]?.UpdatedAt,
      };
    });
  }, [siteConfigData, newSiteConfigValues, endUserSettingsJson]);

  const handleSwitch = (data, nextState) => {
    setDialogState({ data, nextState });
    siteConfigValuesMapping[data.key]?.isPremium && !nextState
      ? setOpenDialog(true)
      : handleSiteConfigOverrideChange(data, nextState);
  };

  const handleSiteConfigOverrideChange = async (rowData, nextState) => {
    setSwitchIsLoadingKey(rowData.key);
    rowData.checked = nextState;
    let key = rowData.key;
    let value = siteConfigValuesMapping[rowData.key].values[nextState ? 0 : 1];

    if (siteConfigValuesMapping[key].path) {
      key = siteConfigValuesMapping[key].path;
    }
    if (key === 'EndUserSettings') {
      value = JSON.stringify({ ...endUserSettingsJson, [rowData.key]: value });
    }
    const res = await updateSiteConfig(key, value);
    if (res?.Success) {
      setForceUpdate(true);
      setSuccessSnackbarMessage(
        `${rowData.name} was ${rowData.checked ? 'enabled' : 'disabled'}`
      );
    } else {
      setErrorSnackbarMessage(res?.Error ?? 'Error updating site config');
    }
    setOpenDialog(false);
  };

  const updateSiteConfig = async (fieldName, value) => {
    try {
      let formatedValue = value;
      const isSnippetUpdate =
        fieldName === 'Custom' || fieldName === 'CustomCss';
      if (fieldName === 'Custom') {
        const trimedJsonSnippet = value && value.trim();
        formatedValue = trimedJsonSnippet ? value : '{}';
      }
      const res = await monolithApi.updateSiteConfigOverride(
        account.id,
        selectedPlatform?.value as SystemTypeKey,
        fieldName,
        formatedValue
      );
      if (isSnippetUpdate) {
        setForceUpdate(true);
        setSuccessSnackbarMessage(
          `${fieldName === 'Custom' ? 'Custom Json' : 'Custom Css'} was updated`
        );
      }
      return res;
    } catch (e) {
      setErrorSnackbarMessage('Error updating site config');
    }
  };

  const handleSnackBarErrorClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setErrorSnackbarMessage('');
    setSuccessSnackbarMessage('');
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'calc(100% - 40px)',
        overflow: 'hidden scroll',
        paddingRight: '10px',
      }}
    >
      <AccountsCollapse
        defaultOpenIndex={0}
        collapsableItems={[
          {
            header: (
              <StyledTypographyHeader variant="T30-2">
                Settings
              </StyledTypographyHeader>
            ),
            content: (
              <div
                style={{
                  background: '#ffffff',
                  borderRadius: '8px',
                  height: '100%',
                }}
              >
                {Object.keys(siteConfigData).length === 0 ? (
                  <StyledLoaderBox>
                    <Loader />
                  </StyledLoaderBox>
                ) : (
                  <StyledWMDataGrid
                    columnDefs={headCells}
                    rowData={siteConfigRowData}
                    modules={DataGridModules}
                    quickFilterText={query}
                    defaultColDef={{
                      flex: 1,
                    }}
                  />
                )}
              </div>
            ),
          },
          {
            height: '500px',
            header: (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <StyledTypographyHeader variant="T30-2">
                  Custom JSON
                </StyledTypographyHeader>
                <StyledTypographyContent variant="T30">
                  JSON customization is limited to specific keys.{' '}
                  <Link
                    href="https://knowledge.walkme.com/x/pq3EC"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    See the full list in this article
                  </Link>
                  .
                </StyledTypographyContent>
              </div>
            ),
            content: (
              <WmSnippetBoxWrapper
                value={jsonSnippet}
                setValue={setJsonSnippet}
                isValueValid={isJsonValid}
                errorMessage={errorMessages.jsonError}
                language={LanguagesTypes.JSON}
                onSave={() => updateSiteConfig('Custom', jsonSnippet)}
                lastSavedEmail={siteConfigData['Custom']?.CreatedByUserEmail}
                lastSavedDate={moment(
                  siteConfigData['Custom']?.UpdatedAt
                ).format('D MMM YYYY')}
              />
            ),
          },
          {
            height: '500px',
            header: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '0px',
                  gap: '10px',
                }}
              >
                <StyledTypographyHeader variant="T30-2">
                  Custom CSS
                </StyledTypographyHeader>
              </div>
            ),
            content: (
              <WmSnippetBoxWrapper
                value={cssSnippet}
                setValue={setCssSnippet}
                isValueValid={isCssValid}
                errorMessage={errorMessages.cssError}
                language={LanguagesTypes.CSS}
                onSave={async () =>
                  await updateSiteConfig('CustomCss', cssSnippet)
                }
                lastSavedEmail={siteConfigData['CustomCss']?.CreatedByUserEmail}
                lastSavedDate={moment(
                  siteConfigData['CustomCss']?.UpdatedAt
                ).format('D MMM YYYY')}
              />
            ),
          },
        ]}
      />

      <WMSnackbar
        open={!!errorSnackbarMessage || !!successSnackbarMessage}
        onClose={handleSnackBarErrorClose}
        variant={
          errorSnackbarMessage
            ? WMSnackbarVariant.Error
            : WMSnackbarVariant.Success
        }
        message={errorSnackbarMessage || successSnackbarMessage}
      />

      <WMDialog
        ds2
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={'Premium Feature'}
        buttons={[
          {
            children: 'Cancel',
            onClick: () => setOpenDialog(false),
            variant: WMButtonVariant.Text,
            style: { paddingRight: '20px' },
          },
          {
            children: 'Disable Feature',
            onClick: () =>
              handleSiteConfigOverrideChange(
                dialogState.data,
                dialogState.nextState
              ),
            loading: !!switchIsLoadingKey,
          },
        ]}
      >
        <StyledTypographyContent>
          This feature is enabled by default for all paying customers. <br />
          Before disabling it, please ensure that the customer has made the
          payment and explicitly agreed to disable it.
        </StyledTypographyContent>
      </WMDialog>
    </div>
  );
}
export default DefaultSettingsSiteConfigPage;
