import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FeatureTogglesState,
  getFeatureFlagEvaluations,
} from '../redux/feature-toggles.slice';

export const useFeatureFlagEvaluations = (
  featureFlagId: string,
  forceLoad = false
) => {
  const featureFlagEvaluationsAppData = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.featureFlagEvaluations
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeatureFlagEvaluations(featureFlagId));
  }, [dispatch]);

  return featureFlagEvaluationsAppData?.data;
};

export default useFeatureFlagEvaluations;
