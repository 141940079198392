import React, { useMemo } from 'react';
import Routes from './routes';
import { Main, muiTheme } from '@wm-accounts-backoffice-center/shared-styles';
import { useAuth } from 'wm-accounts-auth';

import {
  createStyles,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import LoadingPage from './components/loading-page/loading-page';
import TopBar from './components/top-bar/top-bar';
import { SideBar } from '@wm-accounts-backoffice-center/ui-components';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Tab } from 'libs/ui-components/src/lib/side-menu/side-menu';
import { ToasterProvider } from '@walkme/ui-core';
import SvgAccountManagementIcon from '../assets/AccountManagementIcon';
import SvgAllFeaturesIcon1 from '../assets/AllFeaturesIcon1';
import {
  Unlock,
  Adoption as Development,
  Block,
  Apps,
} from '@walkme/ui-icons/large';
import { ClockTimer } from '@walkme/ui-icons';
import {
  loginTestMethod,
  LoginTestMethod,
  testingLoginDoneRoute,
} from './utils/testing-login-utils';
import { useLocation } from 'react-router-dom';
import TestingLoginDone from './components/testing-login-done/testing-login-done';
import { useLoggedInUser } from '@wm-accounts-backoffice-center/state-management-users';

export const useStyles = makeStyles(() => createStyles({}));

export const App = () => {
  const { accountFeatureEnabled } = useLoggedInUser();
  const isFeatureEnabled = useMemo(
    () => (feature) => accountFeatureEnabled(feature),
    [accountFeatureEnabled]
  );

  const getTabs = (): Tab[] => [
    {
      to: 'accounts',
      label: 'Account Management',
      icon: <SvgAccountManagementIcon style={{ fill: 'none' }} />,
      index: 1,
    },
    {
      to: 'feature-flags',
      label: 'All Features',
      index: 2,
      icon: <SvgAllFeaturesIcon1 style={{ fill: 'none' }} />,
    },
    {
      to: 'backoffice-admins',
      label: 'Backoffice Admins',
      index: 3,
      icon: <Apps style={{ fill: 'none' }} />,
      children: [
        {
          to: 'backoffice-admins',
          label: 'Roles Assignment',
          icon: <Unlock style={{ fill: 'none' }} />,
          index: 1,
        },
        {
          to: 'systems-center',
          label: 'Systems Deletion Center',
          icon: <ClockTimer style={{ fill: 'none' }} />,
          index: 2,
        },
        {
          to: 'inactive',
          label: 'Inactive Accounts',
          icon: <Block style={{ fill: 'none' }} />,
          index: 3,
        },
      ],
    },
    {
      to: 'developer-hub',
      label: 'Developer Hub',
      index: 4,
      icon: <Development style={{ fill: 'none' }} />,
    },
    {
      to: 'feature-toggles',
      label: 'Feature Toggles',
      index: 5,
      icon: <SvgAllFeaturesIcon1 style={{ fill: 'none' }} />,
      hidden: !isFeatureEnabled('showFeatureToggles'),
    },
  ];

  return (
    <Main>
      <ThemeProvider theme={muiTheme}>
        <TopBar />
        <div className="mainPage">
          <SideBar tabs={getTabs()} />
          <div className="mainContent">
            <Routes />
          </div>
        </div>
      </ThemeProvider>
    </Main>
  );
};

export default () => {
  useStyles();
  const location = useLocation();
  const testingLoginDone =
    loginTestMethod === LoginTestMethod.POPUP_AND_REDIRECT &&
    location.pathname.includes(testingLoginDoneRoute);

  const authenticated = useAuth(true, testingLoginDone);
  if (!authenticated) {
    return <LoadingPage />;
  }
  if (authenticated && testingLoginDone) {
    return <TestingLoginDone />;
  }

  return (
    <ToasterProvider>
      <App />
    </ToasterProvider>
  );
};
