import { debounce, makeStyles } from '@material-ui/core';
import {
  Button,
  InputSearch,
  Loader,
  ToasterVariant,
  Tooltip,
  useToaster,
} from '@walkme/ui-core';
import {
  AccountsSearchState,
  getAccountForCopyAccount,
  getAllAccounts,
} from '@wm-accounts-backoffice-center/state-management-accounts-search';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { ArrowLeft, FilledWarning } from '@walkme/ui-icons';
import { getAdminSpaceBaseURL } from '@wm-accounts-backoffice-center/wm-api';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  titleLabel: {
    fontFamily: 'Proxima Nova',
    fontDtyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2F426C',
    marginBottom: '20px',
  },
  SeconderyLabel: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2F426C',
  },
  SeconderyContent: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2F426C',
  },
  accountNameLabel: {
    color: '#2f426c',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '2px',
  },
  snippetEmailLabel: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '16px',
    color: '#8D97AE',
  },
  notSelectedAccount: {
    border: '1px solid #e6ecf8',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '32%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(212, 223, 245, 0.5)',
    },
  },
  selectedAccount: {
    border: '1px solid #3B61EB',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '32%',
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    zIndex: 100,
    border: '1px solid #F7B500',
    borderRadius: '4px',
    background: '#FEF8E8',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 12px',
  },
  targetAccountInitial: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: '#ECEFFA',
    borderRadius: '8px',
    width: '36px',
    height: '36px',
    marginRight: '9px',
    marginLeft: '14px',
    textAlign: 'center',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
  selectedSystem: {
    border: '1px solid #3B61EB',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  notSelectedSystem: {
    border: '1px solid #e6ecf8',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(212, 223, 245, 0.5)',
    },
  },
  buttonBack: {
    padding: '6px !important',
  },
}));

const SystemNameLabel = styled('label')`
  color: #2f426c;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const SystemSnippetEmailLabel = styled('label')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8d97ae;
`;

export interface CopyAccountWizardPage3SameDcProps {
  setDisabled: (disabled: boolean) => void;
  setTargetAccount: (accountIdDestination) => void;
  targetAccount: any;
  setTargetSystem: (accountIdDestination) => void;
  targetSystem: any;
  sourceAccountSystems: any[];
  copyProgressOption: string;
}
const CopyAccountWizardPage3SameDc = ({
  setDisabled,
  setTargetAccount,
  targetAccount,
  sourceAccountSystems,
  setTargetSystem,
  targetSystem,
  copyProgressOption,
}: CopyAccountWizardPage3SameDcProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addToaster } = useToaster();
  const [query, setQuery] = useState(targetAccount?.email || '');
  const ADMIN_SPACE_BASE_URL = getAdminSpaceBaseURL();

  const allSearchedAccounts = useSelector(
    (state: { accountsSearchState: AccountsSearchState }) =>
      state.accountsSearchState.searchedAccountsForCopyAccount
  );

  const onSearchChange = useDebouncedCallback((event) => {
    setQuery(event);
    setTargetAccount(null);
  }, 600);

  useEffect(() => {
    if (allSearchedAccounts.error) {
      addToaster({
        message: `failed to search account - ${allSearchedAccounts.error}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [allSearchedAccounts]);

  useEffect(() => {
    dispatch(getAccountForCopyAccount(query));
  }, [query]);

  useEffect(() => {
    setDisabled(!targetAccount);
  }, [targetAccount]);

  const handleItemClicked = (account) => {
    if (copyProgressOption === 'systems') {
      const accountAlreadySelected = targetAccount === account;
      accountAlreadySelected
        ? setTargetAccount(null)
        : setTargetAccount(account);
    } else {
      setTargetAccount(account);
    }
  };

  const handleSystemClicked = (system) => {
    setTargetSystem(system);
  };

  return (
    <div style={{ margin: '32px' }}>
      <div
        style={{
          background: 'white',
          borderRadius: '12px',
          height: '530px',
        }}
      >
        <div
          style={{
            padding: '24px 32px 0',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <label className={classes.titleLabel}>Select destination</label>
          <InputSearch
            placeholder="Search by account any account field (name, sfdc, id, guid, enterprise snippet email) or system snippet email"
            onChange={onSearchChange}
            value={query}
          />
          {!allSearchedAccounts.loading ? (
            <div style={{ height: '370px', overflow: 'auto' }}>
              {copyProgressOption === 'contentItems' && targetAccount && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '26px',
                  }}
                >
                  <Button
                    variant="ghost"
                    onClick={() => setTargetAccount(null)}
                    className={classes.buttonBack}
                  >
                    <ArrowLeft />
                    {targetAccount.name}
                  </Button>
                </div>
              )}
              {allSearchedAccounts.data &&
              allSearchedAccounts.data.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '16px',
                    marginTop: '18px',
                  }}
                >
                  {copyProgressOption === 'contentItems' && targetAccount
                    ? targetAccount.systems
                        .filter(
                          (system) =>
                            (!system.statusDetails ||
                              system.statusDetails.status === 'enabled') &&
                            !sourceAccountSystems.find(
                              (selectedSystem) =>
                                selectedSystem.id === system.id
                            )
                        )
                        .slice()
                        .reverse()
                        .map((system) => {
                          return (
                            <div
                              className={
                                targetSystem === system
                                  ? classes.selectedSystem
                                  : classes.notSelectedSystem
                              }
                              onClick={(item) => handleSystemClicked(system)}
                            >
                              <div
                                style={{
                                  marginRight: '12px',
                                  marginLeft: '24px',
                                }}
                              >
                                <div
                                  style={{
                                    background: '#F0F3F9',
                                    borderRadius: '8px',
                                    width: '36px',
                                    height: '36px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <img
                                    src={`${ADMIN_SPACE_BASE_URL}/assets/icons/icon-${system.name}.png`}
                                    width="20px"
                                    height="20px"
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <SystemNameLabel>
                                  {system.displayName}
                                </SystemNameLabel>
                                <Tooltip
                                  title={'Guid: ' + system.guid}
                                  children={
                                    <SystemSnippetEmailLabel>
                                      {system.guid}
                                    </SystemSnippetEmailLabel>
                                  }
                                />
                                <SystemSnippetEmailLabel>
                                  {system.systemTypeKey}
                                </SystemSnippetEmailLabel>
                              </div>
                            </div>
                          );
                        })
                    : allSearchedAccounts.data
                        .slice()
                        .reverse()
                        .map((account) => {
                          return (
                            <div
                              className={
                                targetAccount?.id === account.id
                                  ? classes.selectedAccount
                                  : classes.notSelectedAccount
                              }
                              onClick={(item) => handleItemClicked(account)}
                            >
                              <div className={classes.targetAccountInitial}>
                                {account.name?.charAt(0)}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '70%',
                                }}
                              >
                                <label className={classes.accountNameLabel}>
                                  {account.name}
                                </label>
                                <label className={classes.snippetEmailLabel}>
                                  {account.email}
                                </label>
                                <label className={classes.snippetEmailLabel}>
                                  {account.guid}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src="/assets/folder-women.svg"
                    width={'150px'}
                    height={'150px'}
                  />
                  <span
                    style={{
                      marginTop: '36px',
                      fontFamily: 'Proxima Nova',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#2F426C',
                    }}
                  >
                    Find your content's destination by searching for the account
                    name, enterprise snippet email or system snippet email
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                background: 'white',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CopyAccountWizardPage3SameDc;
