import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';

const styles = (theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles(styles);

interface TextHoverPopoverProps {
  text: JSX.Element;
  children: JSX.Element;
}

export function TextHoverPopover({ text, children }: TextHoverPopoverProps) {
  const classes = useStyles();

  return (
    <PopupState variant="popover" popupId="demoPopover">
      {(popupState) => (
        <div>
          <div {...bindHover(popupState)}>{text}</div>
          <HoverPopover
            {...bindPopover(popupState)}
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {children}
          </HoverPopover>
        </div>
      )}
    </PopupState>
  );
}
