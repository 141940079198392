import React from 'react';
import styled from 'styled-components';

export const EmptyLabel = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
  margin-top: 32px;
`;

export const MainDiv = styled('div')`
  height: calc(100%);
  height: -webkit-calc(100%);
  height: -moz-calc(100%);
  background: #ffffff;
  border-radius: 8px;
`;

export const ContentDiv = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
`;

export const ButtonDiv = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
