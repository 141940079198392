import * as React from 'react';
import { SVGProps } from 'react';

const SvgSmartWalkThrough = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={13.5} cy={12.5} r={2} stroke="#162A69" />
    <path
      d="M11.5 12h-9v1h9v-1Zm-9-3h11V8h-11v1Zm11-5H4.5v1H13.5V4ZM16 6.5A2.5 2.5 0 0 0 13.5 4v1A1.5 1.5 0 0 1 15 6.5h1ZM13.5 9A2.5 2.5 0 0 0 16 6.5h-1A1.5 1.5 0 0 1 13.5 8v1ZM1 10.5A1.5 1.5 0 0 1 2.5 9V8A2.5 2.5 0 0 0 0 10.5h1ZM2.5 12A1.5 1.5 0 0 1 1 10.5H0A2.5 2.5 0 0 0 2.5 13v-1Z"
      fill="#162A69"
    />
    <circle cx={2.5} cy={4.5} r={2} stroke="#162A69" />
  </svg>
);

export default SvgSmartWalkThrough;
