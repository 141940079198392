import React, { ReactElement } from 'react';

export const SelectedItemIcon = (props: unknown): ReactElement => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'C76991D8-D6AB-40E6-BEAD-42F6A0C900B5'}</title>
      <g fill="none" fillRule="evenodd">
        <circle fill="#E4EBFC" cx={10} cy={10} r={10} />
        <path
          stroke="#303D53"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7 9.703L9.189 12 13 8"
        />
      </g>
    </svg>
  );
};

export default SelectedItemIcon;
