import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DataGridModules,
  WMDataGrid,
  WMDataGridWrapperProps,
  WMSwitch,
  WMTooltip,
} from '@walkme/wm-ui';
import styled from 'styled-components';
import moment from 'moment';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Spinner,
  TagList,
  ToasterVariant,
  Tooltip,
  Typography,
  useToaster,
} from '@walkme/ui-core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import {
  DefaultFeaturesState,
  addAccountMasterFeature,
  deleteAccountMasterFeature,
  getAccountDefaultFeatures,
} from '@wm-accounts-backoffice-center/state-management-default-features';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
  Account,
  AddMasterFeatureDto,
  DeleteMasterFeatureDto,
} from 'wm-accounts-sdk';
import { useLoggedInUser } from '@wm-accounts-backoffice-center/state-management-users';
import { PlatformOption } from './default-settings-page';
import { ChevronRight } from '@walkme/ui-icons';

export const StyledWMDataGrid = styled(WMDataGrid)`
  --ag-row-height: 60px;
  .WMDataGridHeader-wmDataGridHeader {
    align-items: center;
  }
  .WMSearchFilter-clearButton {
    top: 20%;
  }
  .ag-theme-wm-ui .ag-details-row {
    padding: 0;
  }
  &.pined-right-action-column {
    .ag-pinned-right-header {
      border-left-color: transparent;
    }

    [col-id='actions-column'].ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
      border-left-color: transparent;
    }
  }
  .ag-theme-wm-ui:not(.active-filter) .ag-header-cell[col-id='actions-column'] {
    height: 100%;
  }
  .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover) .ag-cell {
    visibility: hidden;
    padding: 0 !important;
  }
  .ag-cell-wrapper {
    height: 100%;
  }
  .client-action {
    background-color: white !important;
    .row-buttons {
      display: none !important;
    }
  }
  .ag-row-hover .client-action .row-buttons {
    display: flex !important;
    flex-direction: row;
    //justify-content: space-between;
    gap: 10px;
  }
  .editor-action {
    background-color: white !important;
    .PartnerClients-buttons {
      display: none !important;
    }
  }
  .ag-row-hover .editor-action .PartnerClients-buttons {
    display: initial !important;
  }
  .ag-theme-wm-ui .ag-row {
    background-color: #f9fbfd;
  }
  .ag-theme-wm-ui .ag-header-row {
    background-color: #f9fbfd;
  }
  .main-table {
    background-color: white !important;
  }
  .inside-table {
    background-color: #f9fbfd !important;
  }
  .ag-theme-wm-ui.bottom-scroll-shadow .ag-body-viewport {
    padding-bottom: 0 !important;
  }
  .ag-theme-wm-ui .ag-full-width-container .ag-row.ag-row-group-expanded,
  .ag-theme-wm-ui .ag-pinned-left-cols-container .ag-row {
    border-left: 2px solid var(--wmPrimary) !important;
  }

  .ag-theme-wm-ui .ag-full-width-container .ag-row.ag-row-group-expanded,
  .ag-theme-wm-ui .ag-center-cols-container .ag-row.ag-row-group-expanded {
    border-left: 0 !important;
  }

  .ag-cell-wrapper.ag-row-group {
    align-items: center;
  }
  .ag-theme-wm-ui {
    .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
      border-left: solid 0px;
    }
    .ag-pinned-right-cols-container {
      .ag-row-even
        .ag-row-no-focus
        .ag-row
        .ag-row-level-0
        .ag-row-position-absolute
        .ag-row-first {
        background-color: #f9fbfd !important;
        border-left: 0px;
      }
    }
  }
  .ag-group-value {
    .SXbBh {
      display: flex !important;
      flex-direction: column;
    }
  }
  .WMDataGridHeader-wmDataGridHeader {
    padding: 22px 8px;
  }
`;

const StyledRowTypography = styled(Typography)`
  color: #acb3c4;
  margin-left: 4px;
`;
const StyledRowTypographyDescription = styled(Typography)`
  color: #637191;
`;

const StyledTooltip = styled(Tooltip)`
  .Tooltip__StyledSpanContainer-sc-1n3y5r1-3 .SXbBh {
    display: flex;
    align-items: center;
  }
`;

const StyledTypographyBreak = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #637191;
  margin-top: 1px;
  font-family: Proxima Nova;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const StyledTypographyDialog = styled(Typography)`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #2f426c;
`;

const StyledCheckbox = styled(Checkbox)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
`;

const useStyles = makeStyles(() => ({
  tagList: {
    padding: '0 !important',
    alignItems: 'center',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
  },
}));

interface MasterFeatureRow {
  name: string;
  id: number;
  defaultStatus: boolean;
  description: string;
  updatedDate: string;
  updatedBy: string;
  detailedFeaturesAndPlugins: FeaturesAndPluginsRow[];
}
interface FeaturesAndPluginsRow {
  name: string;
  type: string;
}
export interface DefaultSettingsMasterFeatureProps {
  query: string;
  account: Account;
  platform: PlatformOption;
}

export function DefaultSettingsMasterFeaturePage(
  pageProps: DefaultSettingsMasterFeatureProps
) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addToaster } = useToaster();
  const { loggedInUserAppData } = useLoggedInUser();
  const updatedGridModules = DataGridModules.concat([
    MasterDetailModule as any,
  ]);

  const masterFeatures = useSelector(
    (state: { defaultFeatureState: DefaultFeaturesState }) =>
      state.defaultFeatureState.masterFeatures
  );

  const masterFeaturesPerAccount = useSelector(
    (state: { defaultFeatureState: DefaultFeaturesState }) =>
      state.defaultFeatureState.masterFeaturesPerAccount
  );
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [action, setAction] = useState('');
  const [selectedFeature, setSelectedFeature] = useState('');

  const updateMasterFeature = useCallback(
    (actionFotUpdate, featureName: string) => {
      if (actionFotUpdate == 'Enable') {
        const addDTO: AddMasterFeatureDto = {
          accountId: pageProps.account.id,
          masterFeatureName: featureName,
          platform: pageProps.platform.value,
          modifiedBy: loggedInUserAppData.data.email,
        };
        setUpdatingStatus(true);
        dispatch(addAccountMasterFeature(addDTO));
      } else {
        const deleteDto: DeleteMasterFeatureDto = {
          accountId: pageProps.account.id,
          featureName: featureName,
          platform: pageProps.platform.value,
        };
        setUpdatingStatus(true);
        dispatch(deleteAccountMasterFeature(deleteDto));
      }
    },
    [action]
  );

  const [quickFilter, setQuickFilter] = useState('');
  const masterFeatureRows: MasterFeatureRow[] = useMemo(() => {
    if (!masterFeatures.data) {
      return [];
    }
    return masterFeatures.data
      .filter((masterFeature) => masterFeature.whitelisted)
      .map((masterFeature) => {
        const accountMasterFeature = masterFeaturesPerAccount.data.find(
          (masterFeatureAccount) =>
            masterFeature.id == masterFeatureAccount.feature.id
        );
        const status = !!accountMasterFeature;

        return {
          name: masterFeature.name,
          id: masterFeature.id,
          defaultStatus: status,
          description: masterFeature.description,
          updatedBy: accountMasterFeature
            ? accountMasterFeature.modifiedBy
            : '',
          updatedDate:
            accountMasterFeature && accountMasterFeature.createdAt
              ? moment(accountMasterFeature.createdAt).format('MMM Do YYYY')
              : '',
          detailedFeaturesAndPlugins: masterFeature.featureFlags
            .map((ff) => {
              return { name: ff.flagName, type: 'Feature' };
            })
            .concat(
              masterFeature.plugins.map((plugin) => {
                return { name: plugin.pluginName, type: 'Plugin' };
              })
            )
            .map((ffAndPlugin) => {
              return {
                type: ffAndPlugin.type,
                name: ffAndPlugin.name,
              } as FeaturesAndPluginsRow;
            }),
        };
      });
  }, [masterFeatures.data, masterFeaturesPerAccount.data]);

  const detailCellRendererParams = useCallback(
    (e) => {
      return {
        detailGridOptions: {
          suppressCellFocus: true,
          quickFilterText: quickFilter,
          class: 'inside-table',
          columnDefs: [
            {
              field: 'name',
              headeName: 'Included features & plugins',
              maxWidth: 340,
              cellStyle: {
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '16px',
                color: '#2F426C',
                display: 'flex',
                alignItems: 'center',
              },
            },
            {
              field: 'type',
              cellStyle: {
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '16px',
                color: '#2F426C',
                display: 'flex',
                alignItems: 'center',
              },
            },
          ],
          defaultColDef: {
            flex: 1,
            headerClass: 'inside-table',
            cellClass: 'inside-table',
          },
        },
        getDetailRowData,
      };
    },
    [masterFeatures.data]
  );

  const columnDefs = [
    {
      field: 'name',
      pinned: 'left',
      minWidth: 500,
      sortable: true,
      filter: true,
      cellStyle: {
        color: '#2F426C',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: 'white !important',
      },
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: (params) => {
          const data: MasterFeatureRow = params.data;

          return (
            <WMTooltip title={data.description}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    gap: '4px',
                  }}
                >
                  <Typography variant="T30-2">{params.value}</Typography>
                  <StyledRowTypography variant="T30">
                    {data.detailedFeaturesAndPlugins?.length}
                  </StyledRowTypography>
                </div>
                <StyledTypographyBreak variant="T30">
                  {data.description}
                </StyledTypographyBreak>
              </div>
            </WMTooltip>
          );

          {
            /* return data.description.length < 50 ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography variant="T30-2">
                  {`${params.value} ${data.detailedFeaturesAndPlugins?.length}
                ${data.description}`}
                </Typography>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography variant="T30-2">{params.value}</Typography>
              <StyledRowTypography variant="T30">
                {data.detailedFeaturesAndPlugins?.length}
              </StyledRowTypography>

              <Tooltip title={data.description}>
                <StyledTypographyBreak variant="T30">
                  {data.description}
                </StyledTypographyBreak>
              </Tooltip>
            </div> */
          }
        },
      },
      getQuickFilterText: (params: any) => {
        const data: MasterFeatureRow = params.data;
        const filterParamter =
          data.detailedFeaturesAndPlugins?.find((record) =>
            record.name
              .toLowerCase()
              .includes(params.api.filterManager.quickFilter.toLowerCase())
          )?.name || params.value;

        return filterParamter;
      },
    },
    {
      field: 'defaultStatus',
      pinned: 'left',
      cellStyle: {
        color: '#2F426C',
        fontSize: '14px',
        fontWeight: 600,
        backgroundColor: 'white !important',
      },
      minWidth: 200,
      maxWidth: 200,
      sortable: true,
      filter: true,
      headerTooltip:
        'Select a feature to make it the default option for all systems. This means that every new system created under this account will automatically include the selected feature',
      cellRenderer: function (params) {
        return (
          <WMSwitch
            ds2
            checked={params.value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              changedDefaultStatus(params.value, params.data);
            }}
            label={
              <span
                style={{
                  color: '#2F426C',
                  fontSize: '12px',
                  fontWeight: 400,
                  fontFamily: 'Proxima Nova',
                  fontStyle: 'normal',
                  lineHeight: '16px',
                }}
              >
                {params.value ? 'Enabled' : 'Disabled'}
              </span>
            }
          />
        );
      },
      keyCreator: (params) => (params.value ? 'Enabled' : 'Disabled'),
    },
    {
      field: 'updatedBy',
      minWidth: 250,
      cellStyle: {
        color: '#2F426C',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        backgroundColor: 'white !important',
      },
      sortable: true,
      filter: true,
    },
    {
      field: 'updatedDate',
      cellStyle: {
        color: '#2F426C',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        backgroundColor: 'white !important',
      },
      minWidth: 150,
      sortable: true,
      filter: true,
    },
  ];

  const getRowHeight = useCallback(
    (params) => {
      const data: MasterFeatureRow = params.data;
      if (params.node && params.node.detail) {
        const offset = 0;
        const allDetailRowHeight =
          data.detailedFeaturesAndPlugins.filter((record) =>
            record.name.toLowerCase().includes(quickFilter.toLowerCase())
          ).length *
          (params.api.getSizesForCurrentTheme().rowHeight + 2);
        const gridSizes = params.api.getSizesForCurrentTheme();

        return (
          allDetailRowHeight +
          ((gridSizes && gridSizes.headerHeight) || 0) +
          offset
        );
      }
    },
    [quickFilter]
  );

  const onFilterChanged = useCallback((params) => {
    params
      ? params.api.filterManager.quickFilter
        ? setQuickFilter(params.api.filterManager.quickFilter)
        : setQuickFilter('')
      : null;
    params?.api.redrawRows();
    params?.api.resetRowHeights();
  }, []);

  const getDetailRowData = (params) => {
    const data: MasterFeatureRow = params.data;
    params.successCallback(data.detailedFeaturesAndPlugins);
  };

  const icons = useMemo(() => {
    return {
      // use some strings from group
      groupExpanded: '<img src="/assets/ChveronBottom.svg"/>',
      groupContracted: '<img src="/assets/ChveronRight.svg"/>',
    };
  }, []);

  const gridConfig: WMDataGridWrapperProps<any> = useMemo(
    () => ({
      masterDetail: true,
      detailCellRendererParams,
      getRowHeight,
      onFilterChanged,
      modules: updatedGridModules,
      class: 'main-table',
      rowHeight: 60,
      icons: icons,
      defaultColDef: {
        flex: 1,
        headerClass: 'main-table',
        cellClass: 'main-table',
      },
    }),
    [detailCellRendererParams, getRowHeight, onFilterChanged]
  );

  useEffect(() => {
    if (masterFeaturesPerAccount.data && updatingStatus) {
      addToaster({
        message: `${selectedFeature} ${action}d`,
        variant: ToasterVariant.Success,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
      dispatch(
        getAccountDefaultFeatures(
          pageProps.account.id,
          pageProps.platform.value
        )
      );
      setUpdatingStatus(false);
      setisOpen(false);
    } else if (masterFeaturesPerAccount.error) {
      addToaster({
        message: `${masterFeaturesPerAccount.error}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [masterFeaturesPerAccount]);

  const [doNotShowPopUp, setDoNotShowPopUp] = useState(
    localStorage.getItem('doNotShowPopUp')
  );

  const changedDefaultStatus = (action, feature) => {
    setAction(!action ? 'Enable' : 'Disable');
    setSelectedFeature(feature.name);
    doNotShowPopUp === 'true'
      ? updateMasterFeature(!action ? 'Enable' : 'Disable', feature.name)
      : setisOpen(true);
  };

  return (
    <div style={{ height: 'calc(100% - 60px)' }}>
      <Dialog isOpen={isOpen} onClose={() => setisOpen(false)}>
        <DialogTitle>
          {action} {selectedFeature} as default?
        </DialogTitle>
        <DialogContent>
          <div
            style={{ gap: '24px', display: 'flex', flexDirection: 'column' }}
          >
            <StyledTypographyDialog>
              If you turn on default status ,the following master feature will
              be applied by default on any new system created under this
              account.
            </StyledTypographyDialog>
            <StyledCheckbox
              label="Do not show this again"
              checked={doNotShowPopUp === 'true'}
              onChange={(e) => {
                if (doNotShowPopUp == 'true') {
                  localStorage.setItem('doNotShowPopUp', null);
                  setDoNotShowPopUp(null);
                } else {
                  localStorage.setItem('doNotShowPopUp', 'true');
                  setDoNotShowPopUp('true');
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={updatingStatus}
            onClick={() => setisOpen(false)}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={updatingStatus}
            onClick={() => updateMasterFeature(action, selectedFeature)}
            variant="solid"
          >
            {updatingStatus ? <Spinner /> : action}
          </Button>
        </DialogActions>
      </Dialog>
      <StyledWMDataGrid
        columnDefs={columnDefs as any}
        pagination={false}
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '6px',
              paddingLeft: '-24px',
            }}
          >
            <Typography variant="T20-2">Configuration Bundles</Typography>
            <Tooltip title="Configuration bundles let you enable a combination of features and plugins at once">
              <img
                alt={'more'}
                src={`/assets/icons/icon-info.svg`}
                style={{ position: 'relative', top: '1px' }}
              />
            </Tooltip>
          </div>
        }
        entityName="Partner Account"
        rows={masterFeatureRows}
        gridConfig={gridConfig}
        hideColumnPicker
        hideExport
        rowHeight={60}
      />
    </div>
  );
}
export default DefaultSettingsMasterFeaturePage;
