import { useRef } from 'react';

export interface ClientConfig {
  NODE_ENV: string;
  NX_WM_NODE_ENV: string;
  NX_AUTH_URL: string;
  NX_IMPERSONATION_URL: string;
  NX_ADMIN_CENTER_URL: string;
  NX_WM_API_URL: string;
  NX_EUX_BACKOFFICE_API_URL: string;
  NX_EUX_BACKOFFICE_UI_URL: string;
  NX_WM_EDITOR_URL: string;
  NX_DD_SITE: string;
  NX_DATADOG_PROXY: string;
  NX_IDENTITY_CLIENT_ID: string;
  NX_IDENTITY_SCOPE: string;
  NX_IDENTITY_RESPONSE_TYPE: string;
  NX_AUTH_TYPE: string;
  NX_DD_APP_ID: string;
  NX_DD_CLIENT_TOKEN: string;
  NX_SENTRY_DSN: string;
  NX_WALKME_SNIPPET: string;
  NX_SALESFORCE_LIGHTNING: string;
  NX_PUBLISH_SETTINGS: string;
}

declare global {
  interface Window {
    clientConfig: ClientConfig;
  }
}

const useClientConfig = () => {
  const clientConfigRef = useRef<Partial<ClientConfig>>(window.clientConfig);
  return clientConfigRef.current;
};

export default useClientConfig;
