import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFeaturesToAccount,
  EntityFeatureFlag,
  FeatureFlag,
  removeFeaturesFromAccount,
} from '@wm-accounts-backoffice-center/state-management-features';
import {
  Account,
  FeatureFlagType,
} from '@wm-accounts-backoffice-center/wm-api';
import FeaturesTable from './features-table';

export interface AccountFeaturesProps {
  account: Account;
  featuresForEntityType: FeatureFlag[];
  entityFeatureFlags: EntityFeatureFlag[];
  featureFlagTypes: FeatureFlagType[];
  isUpdatingFeatures: boolean;
  cleanupRowsSelection: boolean;
  loading: boolean;
}

export function AccountFeatures(pageProps: AccountFeaturesProps) {
  const accountData = pageProps.account;

  const dispatch = useDispatch();

  const entityId = accountData?.id;

  const removeFFFromEntity = useCallback(
    async (ffToRemove: string[]) => {
      dispatch(removeFeaturesFromAccount(pageProps.account.id, ffToRemove));
    },
    [entityId]
  );

  const addFFToEntity = useCallback(
    async (ffToAdd: string[]) => {
      dispatch(addFeaturesToAccount(pageProps.account.id, ffToAdd));
    },
    [entityId]
  );

  return (
    <>
      <FeaturesTable
        tableTitle="Account"
        featureFlagTypes={pageProps.featureFlagTypes}
        featuresForEntityType={pageProps.featuresForEntityType}
        entityFeatureFlags={pageProps.entityFeatureFlags}
        isUpdatingFeatures={pageProps.isUpdatingFeatures}
        cleanupRowsSelection={pageProps.cleanupRowsSelection}
        loadingTable={pageProps.loading}
        headerElement={<></>}
        onAddFeatures={(featureFlags) => {
          addFFToEntity(featureFlags.map((ff) => ff.flagName));
        }}
        onRemoveFeatures={(featureFlags) => {
          removeFFFromEntity(featureFlags.map((ff) => ff.flagName));
        }}
      />
    </>
  );
}

export default AccountFeatures;
