import { WMDivider } from '@walkme/wm-ui';
import {
  AccountState,
  getAccountById,
  accountSlice,
  useLoggedInUser,
} from '@wm-accounts-backoffice-center/state-management-users';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Copy,
  Document,
  Users,
  Adoption,
  Settings,
} from '@walkme/ui-icons/large';
import { Copy as SmallCopy, ExternalLink } from '@walkme/ui-icons';
import { makeStyles } from '@material-ui/core';
import SvgPreferences from './styles/svgMenu/Preferences';
import Svg3DBox from './styles/svgMenu/3DBox';
import SvgConfigurations from './styles/svgMenu/Configurations';
import AccountInforamtion from './account-Information/account-information';
import CopyAccountLogs from './copy-account/copy-logs/copy-account-logs';
import AccountUsersPage from './account-users/account-users';
import {
  Button,
  Loader,
  TabContent,
  ToasterVariant,
  useToaster,
} from '@walkme/ui-core';
import {
  CopyAccountState,
  copyCleanup,
  importDetailsCleanup,
} from '@wm-accounts-backoffice-center/state-management-copy-account';
import CopyAccountModal from './copy-account/copy-account-wizard-component';
import CopyAccountWizardImport from './copy-account/wizard-pages-different-dc/import';
import CopyAccountMenu, {
  CopyAccountOption,
} from './copy-account/copy-account-menu';
import { AuditLogPage } from '@walkme/audit-log';
import {
  AuditLogsState,
  getAuditLogs,
} from '@wm-accounts-backoffice-center/state-management-auditlog';
import SystemsPage from './system-details/systems-page';
import SvgAuditLog from './styles/svgMenu/AuditLog';
import AccountEditableName from './account-editable-name';
import { deployablesTypes } from '@wm-accounts-backoffice-center/state-management-deployables';
import ContractWrapper from './contracts/contracts-wrapper';
import PartnersWrapper from './partners/partners-wrapper';
import { getSystemTypes } from 'libs/state-management-systems/src';
import FeaturesPage from './features/features-page';
import DefaultSettingsPage from './default-settings/default-settings-page';
import useClientConfig from 'apps/home/src/redux/hooks/useClientConfig';
import AiSettings from './ai-settings/ai-settings';

export interface AccountPageProps {}

const useStyles = makeStyles(() => ({
  selectedTab: {
    background: '#f4f6fb',
    color: '#385FEB',
  },
  disabledTab: {
    color: '#C5CAD6',
    pointerEvents: 'none',
    justifyContent: 'space-between',
  },
}));

const MainDiv = styled('div')`
  height: calc(100% - 66px);
  height: -webkit-calc(100% - 66px);
  height: -moz-calc(100% - 66px);
  background: #ffffff;
  border-radius: 8px;
`;

const SoonDiv = styled('div')`
  background: #ff91b6;
  border-radius: 2px 8px;
  width: 44px;
  height: 20px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2f426c;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuTab = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 10px;
  width: 200px;
  height: 40px;
  :hover {
    background: #f4f6fb;
  }
  border-radius: 5px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`;
const MiniTitle = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3b61eb;
`;

const AccountEmail = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #637191;
`;

export function AccountPage(pageProps: AccountPageProps) {
  const classes = useStyles();
  const history = useHistory();
  const [copyAccountAnchorEl, setCopyAccountAnchorEl] =
    useState<null | HTMLElement>(null);
  const {
    id,
    tabName,
    systemId: systemIdParam,
  } = useParams<{ id; tabName; systemId }>();
  const systemId = Number(systemIdParam);
  const { addToaster } = useToaster();
  const [lastSelectedSystemId, setLastSelectedSystemId] = useState(null);
  const { accountFeatureEnabled } = useLoggedInUser();
  const isCopyAccountFeatureEnabled = true;
  // accountFeatureEnabled(
  //   'copyAccountBackOffice'
  // );
  const [showDialog, setShowDialog] = useState(false);
  const [copyAccountOption, setCopyAccountOption] =
    useState<CopyAccountOption>(null);

  const copyAccountDetails = useSelector(
    (state: { copyAccountState: CopyAccountState }) =>
      state.copyAccountState.CopyAccountDetails
  );
  const clientConfig = useClientConfig();

  const dispatch = useDispatch();
  const accountById = useSelector((state: { accountState: AccountState }) => {
    return state.accountState.accountById;
  });
  const accountData = accountById?.data;

  useEffect(() => {
    dispatch(deployablesTypes());
    dispatch(getSystemTypes());
  }, []);

  const accountImpersonationSettings = useSelector(
    (state: { accountState: AccountState }) => {
      return state.accountState.accountImpersonationSettings;
    }
  );

  const auditLogsAppData = useSelector(
    (state: { auditLogState: AuditLogsState }) => {
      return state.auditLogState.auditLogs;
    }
  );

  const salesforceUrl = `${clientConfig.NX_SALESFORCE_LIGHTNING}/r/Account/${accountData?.sfdcAccountId}/view`;

  useEffect(() => {
    if (copyAccountDetails.error) {
      addToaster({
        message: `Failed to copy account: ${copyAccountDetails.error}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    } else if (copyAccountDetails.data) {
      addToaster({
        message:
          copyAccountOption === 'SameDc'
            ? 'Copying account Content. This can take several minutes. Check progress in the account drawer'
            : copyAccountOption === 'Import'
            ? 'Copying account data. This can take several minutes. Check progress in the log status column'
            : 'Exporting account content. This can take several minutes. Check progress in the account drawer',
        variant: ToasterVariant.Info,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
      dispatch(copyCleanup());
      setSelectedTab(
        sideMenu.findIndex((tab) => tab.key === 'copy-account-logs')
      );
    }
  }, [copyAccountDetails]);

  useEffect(
    () => () => {
      dispatch(accountSlice.actions.accountByIdCleanup(id));
    },
    []
  );

  useEffect(() => {
    dispatch(getAccountById(id));
  }, [id]);

  useEffect(() => {
    if (systemId) {
      setLastSelectedSystemId(systemId);
    }
  }, [systemId]);

  useEffect(() => {
    if (accountById.error) {
      addToaster({
        message: accountById.error,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: false,
        distance: '60',
      });
    }
  }, [accountById]);

  const isNumber = (value: any): boolean => {
    if (typeof value === 'string') {
      return false;
    }

    return !isNaN(parseInt(value));
  };

  const sideMenu = [
    {
      key: 'info',
      name: 'Account information',
      icon: (isSelected: boolean) => (
        <SvgPreferences style={{ color: isSelected ? '#385FEB' : null }} />
      ),
      data: (
        <AccountInforamtion
          account={accountData}
          impersonationSettings={accountImpersonationSettings}
        />
      ),
    },
    {
      key: 'users',
      name: 'Account Users',
      icon: (isSelected) => <Users />,
      data: (
        <AccountUsersPage
          users={accountData.users}
          accountId={accountData.id}
        />
      ),
    },
    {
      key: 'system',
      name: 'System details',
      icon: (isSelected) => (
        <Svg3DBox style={{ color: isSelected ? '#385FEB' : null }} />
      ),
      data: (
        <SystemsPage
          account={accountData}
          selectedSystemId={systemId}
          lastSelectedSystemId={lastSelectedSystemId}
        />
      ),
    },
    {
      key: 'contracts',
      name: 'Contracts',
      icon: (isSelected) => (
        <Document style={{ color: isSelected ? '#385FEB' : null }} />
      ),
      data: <ContractWrapper accountData={accountData} />,
    },
    {
      key: 'partners',
      name: 'Partners',
      icon: (isSelected) => (
        <Adoption style={{ color: isSelected ? '#385FEB' : null }} />
      ),
      data: <PartnersWrapper accountData={accountData} />,
      disabled: !accountFeatureEnabled('backofficeEnablePartners'),
    },
    {
      key: 'features',
      name: 'Feature management',
      icon: (isSelected) => (
        <SvgConfigurations style={{ color: isSelected ? '#385FEB' : null }} />
      ),
      data: (
        <FeaturesPage
          account={accountData}
          systemId={systemId}
          lastSelectedSystemId={lastSelectedSystemId}
        />
      ),
    },

    {
      key: 'copy-account-logs',
      name: 'Content copy logs',
      icon: (isSelected) => <Copy />,
      data: <CopyAccountLogs account={accountData} />,
    },
    {
      key: 'default-settings',
      name: 'Default Settings',
      icon: (isSelected) => <Settings />,
      data: <DefaultSettingsPage account={accountData} />,
    },
    {
      key: 'audit-logs',
      name: 'Audit Logs',
      icon: (isSelected) => (
        <SvgAuditLog style={{ color: isSelected ? '#385FEB' : null }} />
      ),
      data: (
        <div style={{ height: '73vh', width: '100%' }}>
          <AuditLogPage
            systems={accountData.systems}
            logs={auditLogsAppData.data}
            hideSubtitle={true}
            loading={auditLogsAppData.loading}
            pagination={false}
            onChangeFilter={(auditParams) => {
              dispatch(
                getAuditLogs(accountData.id, {
                  ...auditParams,
                  showBackofficeLogs: true,
                })
              );
            }}
            targetSystemIds={isNumber(systemId) ? [systemId] : null}
          />
        </div>
      ),
    },
    {
      key: 'ai-settings',
      name: 'AI Settings',
      icon: () => <Settings />,
      data: <AiSettings accountGuid={accountData.guid} />,
      hidden: window.clientConfig.NX_WM_NODE_ENV === 'dev2',
    },
  ];

  const tabNameExists =
    tabName && sideMenu.findIndex((tab) => tab.key === tabName);

  const findTabInIndex =
    !tabNameExists || tabNameExists === -1 ? 0 : tabNameExists;

  const [selectedTab, setSelectedTab] = useState(findTabInIndex);

  const homeAccounts = () => {
    history.push({
      pathname: `/accounts`,
    });
  };

  if (!accountData?.id) {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Loader></Loader>
      </div>
    );
  }

  return (
    <div style={{ height: '100%' }}>
      <CopyAccountMenu
        anchorEl={copyAccountAnchorEl}
        onItemClicked={(item) => {
          setCopyAccountOption(item);
          setShowDialog(true);
        }}
        onClose={() => {
          setCopyAccountAnchorEl(null);
        }}
      />
      {(copyAccountOption === CopyAccountOption.SameDc ||
        copyAccountOption === CopyAccountOption.Export) && (
        <CopyAccountModal
          showDialog={showDialog}
          wizardExitCallback={() => setShowDialog(false)}
          account={accountData}
          copyAccountOption={copyAccountOption}
        />
      )}
      {copyAccountOption === CopyAccountOption.Import && (
        <CopyAccountWizardImport
          showDialog={showDialog}
          wizardExitCallback={(event) => {
            setShowDialog(false);
            dispatch(importDetailsCleanup());
          }}
          account={accountData}
        />
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '12px',
        }}
      >
        <div>
          {/* <div
            style={{
              marginBottom: '5px',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Home
              style={{ color: '#385FEB', cursor: 'pointer' }}
              onClick={() => homeAccounts()}
            />
            <ChevronRight />
            <MiniTitle>{accountData.name}</MiniTitle>
          </div> */}
          <AccountEditableName account={accountData} />
          {/* <AccountEmail>{accountData.email}</AccountEmail> */}
        </div>
        <div style={{ display: 'flex' }}>
          {isCopyAccountFeatureEnabled && (
            <Button
              variant="outlined"
              onClick={(e) => {
                setCopyAccountAnchorEl(e.currentTarget);
              }}
            >
              <SmallCopy style={{ marginRight: '10px' }} />
              Copy account
            </Button>
          )}
          <Button onClick={() => window.open(salesforceUrl, '_blank')}>
            <ExternalLink style={{ marginRight: '10px' }} />
            Open in Salesforce
          </Button>
        </div>
      </div>
      <MainDiv>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ padding: '16px' }}>
            <div
              style={{
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflowX: 'hidden',
              }}
            >
              {sideMenu
                .filter((tab) => !tab.hidden)
                .map((tab, index) => {
                  return (
                    <MenuTab
                      key={index}
                      onClick={() => {
                        setSelectedTab(index);
                        history.push({
                          pathname: `/accounts/${accountById.data.id}/${
                            tab.key
                          }${systemId ? `/${systemId}` : ''}`,
                        });
                      }}
                      className={
                        tab.disabled
                          ? classes.disabledTab
                          : selectedTab === index
                          ? classes.selectedTab
                          : null
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          color: selectedTab === index ? '#385FEB' : '#637191',
                        }}
                      >
                        {tab.icon(selectedTab === index)}
                        {tab.name}
                      </div>
                      {tab.disabled && <SoonDiv>Soon</SoonDiv>}
                    </MenuTab>
                  );
                })}
            </div>
          </div>
          <WMDivider orientation="vertical" flexItem />
          <div
            style={{
              width: '100%',
              padding: '22px',
              height: 'calc(100% - 44px)',
            }}
          >
            <TabContent
              key={sideMenu[selectedTab].key}
              value={selectedTab}
              index={selectedTab}
            >
              {sideMenu[selectedTab].data}
            </TabContent>
          </div>
        </div>
      </MainDiv>
    </div>
  );
}

export default AccountPage;
