import { Spinner, ToasterVariant, useToaster } from '@walkme/ui-core';
import { publishApi } from '@wm-accounts-backoffice-center/wm-api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { System } from 'wm-accounts-sdk';
import { DropdownSelectionMenuNoSearch } from '../dropdown-aelection-menu-no-search';
import { InfoTitle, StyledInforamtion } from '../styles/styles';

export interface SelfHostedSelectorProps {
  system: System;
  accountId: number;
}

export function SelfHostedSelectoPage({
  accountId,
  system,
}: SelfHostedSelectorProps) {
  const { addToaster } = useToaster();
  const [updatingSelfHosted, setUpdatingSelfHosted] = useState(true);
  const [isSelfHosted, setSelfHosted] = useState(false);

  const items = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const onSelfHostedChange = useCallback(
    async (selfHosted: boolean) => {
      try {
        setUpdatingSelfHosted(true);
        const response = await publishApi.updateSelfHostedStatus(
          system.id,
          selfHosted
        );
        const isSelfHostedAfterUpdate = await publishApi.getSelfHostedStatus(
          system.id
        );
        setSelfHosted(isSelfHostedAfterUpdate);
        setUpdatingSelfHosted(false);
        addToaster({
          message: `successfully updated self hosted`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      } catch (e) {
        setUpdatingSelfHosted(false);
        addToaster({
          message: `failed to update self hosted. ${e}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      }
    },
    [accountId, system, setUpdatingSelfHosted]
  );

  const onGetSelfHosted = useCallback(async () => {
    try {
      setUpdatingSelfHosted(true);
      const isSelfHosted = await publishApi.getSelfHostedStatus(system.id);
      setSelfHosted(isSelfHosted);
      setUpdatingSelfHosted(false);
    } catch (e) {
      addToaster({
        message: `failed to get self hosted. ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [accountId, system, setUpdatingSelfHosted]);

  useEffect(() => {
    onGetSelfHosted();
  }, [system]);

  return (
    <StyledInforamtion>
      <InfoTitle style={{ marginBottom: '5px' }}>System Self Hosted</InfoTitle>
      {updatingSelfHosted ? (
        <Spinner></Spinner>
      ) : (
        <DropdownSelectionMenuNoSearch
          items={items}
          selectedValue={isSelfHosted}
          onChange={(newStatus) => onSelfHostedChange(newStatus)}
        />
      )}
    </StyledInforamtion>
  );
}

export default SelfHostedSelectoPage;
