import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FeaturesState, getFeaturesTypes } from '../redux/features.slice';

export const useGetFeaturesTypes = (forceLoad = false) => {
  const featureFlagTypesData = useSelector(
    (state: { featuresState: FeaturesState }) =>
      state.featuresState.featureFlagTypes
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFeaturesTypes(forceLoad));
  }, [dispatch]);

  return { featureFlagTypesData };
};

export default useGetFeaturesTypes;
