import React, { useContext } from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import SpeedDial from '@material-ui/lab/SpeedDial';
import { AuthContext } from 'wm-accounts-auth';
import { useHistory } from 'react-router-dom';
import { User } from 'wm-accounts-sdk';
import { AccountsMenu } from '@walkme/accounts-menu';
import { wmhttpClientSharedInstance } from 'libs/wm-api/src/lib/wm-http-client';
import { Copy } from '@walkme/ui-icons';

export interface CredentialsProps {
  loading?: boolean;
  user?: User;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Email = styled.div`
  margin-right: 10px;
  font-family: 'ProximaNova';
`;

const StyledDial = styled(SpeedDial)`
  font-family: 'Poppins';
  margin-right: 10px;
  & .menuRoot {
    background: #303d53;
    height: 35px;
    width: 35px;
  }

  & .menuChildrenWrapper {
    position: absolute;
    margin-top: 0;
  }

  .oneLetter {
    font-size: 22px;
  }

  .twoLetter {
    font-size: 16px;
  }

  .threeLetter {
    font-size: 14px;
  }

  .menuChild {
    font-size: 12px;
    border-radius: 4px;
    width: 100px;
  }
`;

export const Credentials = (props: CredentialsProps) => {
  const { loading, user } = props;
  const { signOut, authenticated } = useContext(AuthContext);
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const userEmail = (() => {
    if (!user) return '';
    if (user.impersonator) return user.impersonator.email;
    return user.email;
  })();

  const onSignOut = () => {
    setOpen(false);
    signOut();
  };

  const copyToken = async () => {
    const token = await wmhttpClientSharedInstance.tokenGetter.getUserToken();
    navigator.clipboard.writeText(token);
  };

  const getCredentials = (email: string) => {
    const credentials =
      email &&
      email
        .split('@')[0]
        .split('.')
        .map((part) => part[0].toLocaleUpperCase())
        .join('')
        .substring(0, 3);

    let fontClass: string;
    switch (credentials && credentials.length) {
      case 1:
        fontClass = 'oneLetter';
        break;
      case 2:
        fontClass = 'twoLetter';
        break;
      default:
        fontClass = 'threeLetter';
    }
    return <div className={fontClass}>{credentials}</div>;
  };

  if (loading) {
    return (
      <Container>
        <Skeleton
          variant="rect"
          width={200}
          height={20}
          style={{ marginRight: '10px' }}
        />
        <Skeleton variant="circle" width={40} height={40} />
      </Container>
    );
  }

  return (
    <AccountsMenu
      userMail={user.email}
      accountName={user.account.name}
      userFullName={user.firstName + ' ' + user.lastName}
      items={[
        {
          action: () => onSignOut(),
          label: 'Sign Out',
          icon: <img alt={'more'} src={`/assets/SignOut.svg`} />,
        },
        {
          action: () => copyToken(),
          label: 'Copy Token',
          icon: <Copy />,
        },
      ]}
    />
  );
};
