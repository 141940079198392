import { styledTheme } from '@wm-accounts-backoffice-center/shared-styles';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
const logo = 'assets/svg/walkme-logo.svg';

const {
  colors: {
    main: { mainBg, mainText, mainShadowColor },
    apps: { menuitemColor },
  },
  sizes: { drawerWidth },
} = styledTheme;

export const StyledMenuItem = styled(MenuItem)`
  padding: 0;
  margin: 0 5px 0 0;
  cursor: initial;
`;

export const StyledLeftMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 10px;

  .MuiListItem-button:hover {
    background-color: transparent;
  }
`;

export const StyledCenterMenu = styled.div`
  display: flex;
  justify-content: flex-center;
  flex-grow: 1;

  .MuiListItem-button:hover {
    background-color: transparent;
  }
`;

export const StyledRightMenu = styled.div`
  border-left: 1px solid #dee6fc;
  height: 56px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`;

export const StyledToolbar = styled(Toolbar)`
  min-height: 56px;
  padding-left: 20px;

  .app-item {
    font-family: 'Poppins';
    flex-grow: 1;

    .app-name {
      margin: 0 4px 0 5px;
      color: ${menuitemColor};
    }

    .app-icon {
      width: 24px;
      height: 24px;
    }
  }
`;

export const StyledAppBar = styled(AppBar)`
  min-height: 56px;
  max-height: 56px;
  background-color: ${mainBg};
  color: ${mainText};
  box-shadow: inset 0px -1px 0px #f5f6f8;
  width: 100%;
`;

export const StyledLogoContainer = styled.div`
  background-color: ${styledTheme.colors.main.mainBg};
  color: ${styledTheme.colors.main.mainText};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${styledTheme.sizes.minHeaderWidth};

  .logo {
    width: 158px;
    height: 47px;
    background: url(${logo}) right no-repeat;
    background-size: 85%;
  }
`;
