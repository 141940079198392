import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EnvironmentsState,
  getEnvironmentsBySystemId,
} from '../redux/environments.slice';

export const useGetSystemEnvironments = (systemId: number) => {
  const systemEnvironmentsData = useSelector(
    (state: { environmentsState: EnvironmentsState }) =>
      state.environmentsState.systemEnvironments
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEnvironmentsBySystemId(systemId));
  }, [dispatch, systemId]);

  return { systemEnvironmentsData };
};

export default useGetSystemEnvironments;
