import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import {
  WMButton,
  WMButtonVariant,
  WMFormLabel,
  WMIconClose,
  WMSelect,
  WMSelectOption,
  WMSelectValue,
  WMTextField,
  WMTooltip,
} from '@walkme/wm-ui';
import { CreateAccountRequest } from '@wm-accounts-backoffice-center/wm-api';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccountsSearchState,
  createAccount,
} from 'libs/state-management-accounts-search/src/lib/redux/accounts-search.slice';
import { ACCOUNT_TYPES_DISPLAY_NAMES } from 'wm-accounts-sdk';

const isValidEmail = (emailString: string): boolean => {
  if (!emailString) {
    return true;
  }

  // regex allows '+' symbols in the email address
  const emailRegex =
    '^([0-9a-zA-Z]([-.\\w\\+]*[0-9a-zA-Z\\+])*@([0-9a-zA-Z][-\\w]*[0-9a-zA-Z]\\.)+[a-zA-Z]{2,9})$';
  const match = emailString.match(emailRegex);
  return !!match && match.length > 0;
};

const useStyles = makeStyles((theme) => ({
  dialogModal: {
    maxHeight: '90vh',
    maxWidth: '592px',
    borderRadius: 15,
    padding: '24px 32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '20px',
  },
  dialogTitle: {
    padding: '0px',
  },
  dialogTitleText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#2F426C',
  },
  closeButton: {
    padding: '0px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    gap: '32px',
  },
  formLabel: {
    fontFamily: 'ProximaNova',
    fontSize: '14px',
    fontStyle: 'normal',
    color: '#2F426C',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
    paddingBottom: '0px!important',
  },
  styledTextField: {
    maxWidth: '280px',
    '& .WMTextField-label': {
      fontSize: '12px',
    },
    '& .WMTextField-input': {
      padding: '10px 12px',
    },
    '& .WMTextField-inputRoot': {
      height: '40px',
      '&.WMTextField-error': {
        backgroundColor: '#FDEDED',
      },
    },
    '& .WMTextField-error': {},
  },
  styledSelect: {
    width: '280px',
    '& .wmSelect__control': {
      height: '40px',
      maxHeight: '40px',
    },
  },
  infoIcon: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    height: '15px',
    width: '15px',
    '&:hover': {
      backgroundColor: '#000000',
      filter:
        'invert(36%) sepia(99%) saturate(4118%) hue-rotate(222deg) brightness(98%) contrast(89%)', // computed from #3B61EB
    },
  },
  infoTooltip: {
    maxWidth: '600px',
    '& > .WMTooltip-htmlWrapper': {
      padding: '0px',
    },
  },
  dialogActions: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    margin: '20px 0 0 0',
    padding: '0px',
  },
  dialogSeconaryButton: {
    marginRight: '22px',
  },
}));

export interface CreateAccountProps {
  showDialog: boolean;
  dialogExitCallback: Function;
}

interface AccountState {
  accountName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  types?: string[];
}

const CreateAccountModal = ({
  showDialog,
  dialogExitCallback,
}: CreateAccountProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSaving = useSelector(
    (state: { accountsSearchState: AccountsSearchState }) =>
      state.accountsSearchState.accountCreation.loading
  );
  const [account, setAccount] = useState<AccountState>({
    accountName: '',
    email: '',
    firstName: '',
    lastName: '',
    types: [],
  } as AccountState);
  const [canSave, setCanSave] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [typesSelectValue, setTypesSelectValue] = useState<WMSelectValue>(null);

  const typesSelectOptions: WMSelectOption[] = ACCOUNT_TYPES_DISPLAY_NAMES.map(
    (t) => ({ value: t.accountType, label: t.displayName })
  ).sort((a, b) => (a.label < b.label ? -1 : 1));

  const onAccountNameChange = (accountName: string) => {
    setAccount((prevState) => ({ ...prevState, accountName }));
  };
  const onEmailChange = (email: string) => {
    setAccount((prevState) => ({ ...prevState, email }));
  };
  const onFirstNameChange = (firstName: string) => {
    setAccount((prevState) => ({ ...prevState, firstName }));
  };
  const onLastNameChange = (lastName: string) => {
    setAccount((prevState) => ({ ...prevState, lastName }));
  };
  const onTypesSelectChange = (newValue: WMSelectValue) => {
    setTypesSelectValue(newValue);
    const type = [newValue.value];
    setAccount((prevState) => ({ ...prevState, types: type }));
  };

  const createNewAccount = async () => {
    dispatch(createAccount(account as CreateAccountRequest));
  };

  const closeDialog = () => {
    dialogExitCallback();
  };

  // Validate email value
  useEffect(() => {
    setIsEmailValid(isValidEmail(account.email));
  }, [account.email]);

  // Make sure all fields are filled to allow saving the new account
  useEffect(() => {
    const areAllFieldsCorrectlyFilled =
      account.accountName &&
      account.email &&
      account.firstName &&
      account.lastName &&
      account.types &&
      account.types.length &&
      isEmailValid;

    setCanSave(areAllFieldsCorrectlyFilled);
  }, [account, isEmailValid]);

  return (
    <Dialog
      onClose={closeDialog}
      open={showDialog}
      fullWidth={true}
      disableBackdropClick={isSaving}
      disableEscapeKeyDown={isSaving}
      classes={{ paper: classes.dialogModal }}
      maxWidth={'sm'}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <label className={classes.dialogTitleText}>Create New Account</label>
          <WMButton style={{ color: 'black' }} variant={WMButtonVariant.Text}>
            <WMIconClose size={13} onClick={closeDialog}></WMIconClose>
          </WMButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <WMFormLabel className={classes.formLabel}>Account Setup</WMFormLabel>
        <Box className={classes.inputsContainer}>
          <WMTextField
            label="Account Name"
            className={classes.styledTextField}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onAccountNameChange(e.target.value)
            }
            value={account.accountName}
            disabled={isSaving}
          />
          <WMSelect
            label="Account Type"
            className={classes.styledSelect}
            options={typesSelectOptions}
            value={typesSelectValue}
            onChange={onTypesSelectChange}
          />
        </Box>
        <WMFormLabel className={classes.formLabel}>
          First Admin User
        </WMFormLabel>
        <Box className={classes.inputsContainer}>
          <WMTextField
            label="Email"
            className={classes.styledTextField}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onEmailChange(e.target.value)
            }
            error={isEmailValid ? null : 'Not a valid email address'}
            value={account.email}
            disabled={isSaving}
          />
        </Box>
        <Box className={classes.inputsContainer}>
          <WMTextField
            label="First Name"
            className={classes.styledTextField}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onFirstNameChange(e.target.value)
            }
            value={account.firstName}
            disabled={isSaving}
          />
          <WMTextField
            label="Last Name"
            className={classes.styledTextField}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onLastNameChange(e.target.value)
            }
            value={account.lastName}
            disabled={isSaving}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <WMButton
          variant={WMButtonVariant.Text}
          className={classes.dialogSeconaryButton}
          disabled={isSaving}
          onClick={closeDialog}
        >
          Cancel
        </WMButton>
        <WMButton
          loading={isSaving}
          disabled={isSaving || !canSave}
          onClick={createNewAccount}
        >
          Create Account
        </WMButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAccountModal;
