import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from '@walkme/ui-core';
import {
  featureTogglesSlice,
  FeatureTogglesState,
  updateFeatureFlagState,
} from '../../../../state-management-feature-toggles/src';
import { StyledChangeStateButton } from './feature-toggles-page.styles';

export interface FFStateChangeDialogProps {
  isOpen: boolean;
  name: string;
  ownerTeam: string;
  state: boolean;
  unsetSelectedFeatureFlag: () => void;
}

export const FeatureFlagStateChangeDialog = ({
  isOpen,
  name,
  ownerTeam,
  state,
  unsetSelectedFeatureFlag,
}: FFStateChangeDialogProps) => {
  const dispatch = useDispatch();
  const updateFeatureFlagAppData = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.updateFeatureFlag
  );

  const onCancel = () => {
    dispatch(featureTogglesSlice.actions.setStateChangeDialogOpen(false));
    unsetSelectedFeatureFlag();
  };

  const onConfirm = async () => {
    await dispatch(updateFeatureFlagState(name, ownerTeam, !state));
    unsetSelectedFeatureFlag();
  };

  return (
    <Dialog isOpen={isOpen} onClose={onCancel}>
      <DialogTitle>
        {state ? 'Disable' : 'Enable'} {name} feature flag?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to {state ? 'disable' : 'enable'} the{' '}
          <b>{name}</b> feature flag? Once {state ? 'disabled' : 'enabled'}, the
          associated features will become {state ? 'inactive' : 'active'}.
          Please confirm your action.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <StyledChangeStateButton
          onClick={onConfirm}
          loading={updateFeatureFlagAppData.loading}
        >
          {state ? 'Disable' : 'Enable'}
        </StyledChangeStateButton>
      </DialogActions>
    </Dialog>
  );
};
