import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetMergeProcsResponse } from '../api/merge-account-api.types';
import { AppThunk } from '../merge-account.store';
import { mergeAccountApi } from '../api/merge-account-api';
import { getMergeProcsResultMock } from './mock-data';
import { transformToGridRow } from './merge-log.lib';
import { MergeLogState } from './merge-log.types';

const initialState: MergeLogState = {
  mergeProcs: {
    data: null,
    loading: false,
    error: null,
  },
  gridRows: [],
  isDrawerOpen: false,
  selectedMergeProc: null,
};

const mergeLogSlice = createSlice({
  name: 'mergeLogs',
  initialState,
  reducers: {
    getAllMergeProcsStart: (state) => {
      state.mergeProcs.loading = true;
    },
    getAllMergeProcsSuccess: (
      state,
      action: PayloadAction<GetMergeProcsResponse | any>
    ) => {
      state.mergeProcs.data = action.payload;
      state.gridRows = action.payload.procs.map((mergeProc) =>
        transformToGridRow(mergeProc)
      );
      state.mergeProcs.loading = false;
    },
    getAllMergeProcsFailed: (state, action) => {
      state.mergeProcs.error = action.payload;
      state.mergeProcs.loading = false;
    },
    drawerClosed: (state) => {
      state.isDrawerOpen = false;
    },
    mergeProcessClicked: (state, action) => {
      const procId = action.payload;
      const selectedMergeProc = state.mergeProcs.data.procs.find(
        (proc) => proc._id === procId
      );
      state.selectedMergeProc = selectedMergeProc;
      state.isDrawerOpen = true;
    },
  },
});

export const {
  getAllMergeProcsStart,
  getAllMergeProcsSuccess,
  getAllMergeProcsFailed,
  drawerClosed,
  mergeProcessClicked,
} = mergeLogSlice.actions;

export const getAllMergeProcs = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(getAllMergeProcsStart());
    const result: GetMergeProcsResponse =
      await mergeAccountApi.getAllMergeProcs();
    dispatch(getAllMergeProcsSuccess(result));
  } catch (err) {
    dispatch(getAllMergeProcsFailed(err.message));
    return;
  }
};

export default mergeLogSlice.reducer;
