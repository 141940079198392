import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Spinner,
  ToasterVariant,
  useToaster,
} from '@walkme/ui-core';
import { WMSelect, WMTagList, WMTagVariant } from '@walkme/wm-ui';
import { AppData } from '@wm-accounts-backoffice-center/general-types';
import { InfoDetail } from '@wm-accounts-backoffice-center/shared-styles';
import {
  getEnvironmentsBySystemId,
  useGetSystemEnvironments,
} from '@wm-accounts-backoffice-center/state-management-environments';
import { publishApi } from '@wm-accounts-backoffice-center/wm-api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AccountsSdk,
  Environment,
  Environments,
  System,
  User,
} from 'wm-accounts-sdk';
import { InfoTitle } from '../styles/styles';

const PublishDetailsText = [
  'Settings',
  'Customize items & CSS',
  'Menu Organizer',
  'Segmentations',
  'Help Desk Integrations',
  'Search Providers',
  'Live Chat Integrations',
  'Language Settings',
  'Attributes',
];
export interface SystemPublishSettingsProps {
  system: System;
  accountId: number;
  onClose: () => void;
  isOpen: boolean;
  systemEnvironmentsData: AppData<Environments>;
}

export function SystemPublishSettingsDialog({
  system,
  accountId,
  isOpen,
  onClose,
  systemEnvironmentsData,
}: SystemPublishSettingsProps) {
  const dispatch = useDispatch();
  const [publishingSettings, setPublishingSettings] = useState(false);
  const [selectedEnv, setSelectedEnv] =
    useState<{ value: Environment; label: string }>(null);
  const { addToaster } = useToaster();

  const systemEnvironmentsRows = systemEnvironmentsData.data?.map(
    (envData) => ({
      value: envData,
      label: envData.name,
    })
  );

  const onPublishSettings = useCallback(async () => {
    try {
      setPublishingSettings(true);
      const response = await publishApi.publishSettingsForFeatures({
        accountId,
        systemId: system.id,
        envName: selectedEnv.value.name,
        environmentId: selectedEnv.value.id,
        systemGuid: system.guid,
      });
      let toasterVariant: ToasterVariant;
      let toasterText: string;
      let autoDismiss = true;
      if (response?.content?.status === 'done') {
        toasterVariant = ToasterVariant.Success;
        toasterText = `publish setting to ${system.displayName} was successfull.`;
        autoDismiss = true;
      } else if (response?.content?.status === 'in-progress') {
        toasterVariant = ToasterVariant.Info;
        toasterText = `publish setting to ${system.displayName} is still in progress (process id ${response?.content?.id})`;
        autoDismiss = false;
      } else if (response?.content?.status === 'pending') {
        toasterVariant = ToasterVariant.Info;
        toasterText = `publish setting to ${system.displayName} is pending (process id ${response?.content?.id})`;
        autoDismiss = false;
      } else {
        toasterVariant = ToasterVariant.Error;
        toasterText = `publish setting to ${system.displayName} ${
          response?.content?.status
        } (process id ${response?.content?.id || 'N/A'})`;
        autoDismiss = true;
      }

      addToaster({
        message: toasterText,
        variant: toasterVariant,
        width: 'content',
        autoDismiss: autoDismiss,
        distance: '60',
      });

      setPublishingSettings(false);
      setSelectedEnv(null);
      onClose();
    } catch (e) {
      setPublishingSettings(false);
      addToaster({
        message: `publish setting to ${system.displayName} was falied. ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [
    selectedEnv,
    system,
    accountId,
    setPublishingSettings,
    dispatch,
    onClose,
  ]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogTitle>Publish Features</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <InfoDetail style={{ marginBottom: '15px' }}>
            publish features for <b>{system?.displayName}</b>
            <br />
            <br />
          </InfoDetail>
          {/* <WMTagList
            style={{
              display: 'block',
              marginBottom: '15px',
              marginTop: '15px',
            }}
            labels={PublishDetailsText.map((text) => ({
              labelText: text,
              fullLabel: true,
              variant: WMTagVariant.Comet,
            }))}
            maxTagsShown={20}
          /> */}
          <InfoTitle style={{ marginBottom: '15px' }}>
            Please select environment
          </InfoTitle>
        </DialogContentText>
        <WMSelect
          options={systemEnvironmentsRows}
          value={selectedEnv}
          onChange={(newSelectedEnv: { value: Environment; label: string }) => {
            setSelectedEnv(newSelectedEnv);
          }}
          placeholder={'Select Environment'}
          isPopout
          isDropdownSelect
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSelectedEnv(null);
            onClose();
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          disabled={publishingSettings || !selectedEnv}
          onClick={onPublishSettings}
          variant="solid"
          color="primary"
        >
          {publishingSettings || systemEnvironmentsData.loading ? (
            <Spinner />
          ) : (
            'Publish'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default SystemPublishSettingsDialog;
