import { useDispatch, useSelector } from 'react-redux';
import { tableSlice, TableState } from './table.slice';
import { Order } from '@wm-accounts-backoffice-center/general-types';

export const useTable = () => {
  const dispatch = useDispatch();
  const rowsPerPage: number = useSelector(
    (state: { tableState: TableState }) => state.tableState.rowsPerPage
  );
  const currentPage: number = useSelector(
    (state: { tableState: TableState }) => state.tableState.currentPage
  );
  const currentOrder: string = useSelector(
    (state: { tableState: TableState }) => state.tableState.currentOrder
  );
  const currentOrderBy: string = useSelector(
    (state: { tableState: TableState }) => state.tableState.currentOrderBy
  );
  const currentTableRowSelected = useSelector(
    (state: { tableState: TableState }) => state.tableState.selectedItemId
  );

  //TODO use external config
  const config = {
    rowsPerPageOptions: [10, 20, 50],
  };

  const onSortChange = (order) => {
    dispatch(tableSlice.actions.orderChanged(order));
  };

  const onSetOrderBy = (prop) => {
    dispatch(tableSlice.actions.orderByChanged(prop));
  };

  const onPageChange = (page) => {
    dispatch(tableSlice.actions.pageChanged(page));
  };

  const onRowsPerPageChange = (num) => {
    dispatch(tableSlice.actions.rowsPerPageChanged(num));
  };

  const onSelectTableRow = (id: number) => {
    dispatch(tableSlice.actions.itemSelected(id));
  };

  return {
    config,
    onRowsPerPageChange,
    rowsPerPage,
    currentPage,
    onPageChange,
    onSortChange,
    currentOrder: currentOrder as Order,
    currentOrderBy,
    onSetOrderBy,
    currentTableRowSelected,
    onSelectTableRow,
  };
};

export default useTable;
