import styled from 'styled-components';
import { Button, Dialog, Input } from '@walkme/ui-core';
import { WMSelect } from '@walkme/wm-ui';
import { DialogContent } from '@material-ui/core';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    height: 60vh !important;
    width: 20vw !important;
    max-width: unset !important;
    min-width: 500px;
  }

  .MuiDialogContent-root {
    overflow-y: hidden !important;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid rgb(235, 239, 247);
  padding: 16px 32px;
`;

export const StyledContentContainer = styled.div`
  width: 100%;
  overflow: auto;
  scrollbar-width: none;

  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;

  .sub-title {
    padding-bottom: 16px;
  }

  #entities-field {
    align-items: baseline;
    margin-bottom: -12px;
  }

  #description-field,
  #name-field {
    align-items: baseline;
  }

  #validate-field-container {
    display: flex;
    flex-direction: row-reverse;
  }

  #validate-field {
    width: 280px;
    min-width: unset;
  }

  #sync-field {
    margin-top: 60px;
  }
`;

export const StyledField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 400px;

  .field-title-with-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  #validation-title-with-info {
    justify-content: space-between;
  }
`;

export const StyledInput = styled(Input)`
  & input:focus {
    border-width: 1px;
    outline: none;
  }

  & textarea:focus {
    border-width: 1px;
    outline: none;
  }

  #name-input:disabled {
    color: #8d97ae;
  }
`;

export const StyledWMSelect = styled(WMSelect)`
  width: 280px;

  .wmSelect__control {
    background-color: rgb(244, 246, 251);

    &:hover:not(.wmSelect__control--is-focused) {
      border-color: #93a8f4;
    }

    .wmSelect__value-container {
      padding-left: 10px;
    }

    .wmSelect__placeholder {
      color: #8d97ae;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 20px !important;
`;

export const StyledImg = styled.img`
  position: relative;
  top: 1px;
`;
