import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppThunk, MergeAccountState } from '../../../../merge-account.store';
import { System, User } from 'wm-accounts-sdk';
import {
  userCanMerge,
  userCannotMerge,
} from '../../merge-account-wizard.slice';

export interface DisplaySystem {
  id: number;
  name: string;
  displayName: string;
}

export interface ReviewState {
  sourceAccountName?: string;
  targetAccountName?: string;
  systemsToMerge: DisplaySystem[];
  systemsToExclude: DisplaySystem[];
  usersToMerge: string[];
  usersToExclude: string[];
  rolesToMerge: string[];
}

export interface MergeState {
  sourceAccountGuid?: string;
  targetAccountGuid?: string;
  systemGuidsToExclude: string[];
  userGuidsToExclude: string[];
}

export interface ReviewAndMergeState {
  mergeReviewdAndApproved: boolean;
  review: ReviewState;
  merge: MergeState;
}

export const initialReviewAndMergeState: ReviewAndMergeState = {
  mergeReviewdAndApproved: false,
  review: {
    systemsToMerge: [],
    systemsToExclude: [],
    usersToMerge: [],
    usersToExclude: [],
    rolesToMerge: [],
  },
  merge: {
    systemGuidsToExclude: [],
    userGuidsToExclude: [],
  },
};

const reviewAndMergeSlice = createSlice({
  name: 'reviewAndMerge',
  initialState: initialReviewAndMergeState,
  reducers: {
    resetReviewAndMerge: (state: ReviewAndMergeState) => {
      state.review = initialReviewAndMergeState.review;
      state.merge = initialReviewAndMergeState.merge;
      state.mergeReviewdAndApproved = false;
    },
    reviewDataUpdated: (
      state: ReviewAndMergeState,
      action: PayloadAction<ReviewState>
    ) => {
      state.review = action.payload;
    },
    mergeDataUpdated: (
      state: ReviewAndMergeState,
      action: PayloadAction<MergeState>
    ) => {
      state.merge = action.payload;
    },
    mergeApproved: (state: ReviewAndMergeState) => {
      state.mergeReviewdAndApproved = true;
    },
    mergeDisapproved: (state: ReviewAndMergeState) => {
      state.mergeReviewdAndApproved = false;
    },
  },
});

export const {
  resetReviewAndMerge,
  reviewDataUpdated,
  mergeDataUpdated,
  mergeApproved,
  mergeDisapproved,
} = reviewAndMergeSlice.actions;

const getExcludedSystems = (
  systems: System[],
  excludedSystemIds: number[]
): System[] => {
  return systems.filter(
    (system) =>
      excludedSystemIds.includes(system.id) ||
      (system.associatedSystem?.id &&
        excludedSystemIds.includes(system.associatedSystem.id))
  );
};
const getSystemsToMerge = (
  systems: System[],
  excludedSystemIds: number[]
): System[] => {
  return systems.filter(
    (system) =>
      !excludedSystemIds.includes(system.id) &&
      (!system.associatedSystem?.id ||
        !excludedSystemIds.includes(system.associatedSystem.id))
  );
};

export const approvalCheckboxChanged =
  (value: boolean): AppThunk =>
  async (dispatch, getState) => {
    if (value) {
      dispatch(mergeApproved());
      dispatch(userCanMerge());
    } else {
      dispatch(mergeDisapproved());
      dispatch(userCannotMerge());
    }
  };

export const mergeAccountDataUpdated =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState() as MergeAccountState;
    const sourceAccount = state.mergeData.sourceAccount;
    const targetAccount = state.mergeData.targetAccount;

    const sourceAccountName = sourceAccount?.name;
    const targetAccountName = targetAccount?.name;

    const excludedSystemIds = state.mergeData.excludeSystemIds;
    const excludedUserIds = state.mergeData.excludedUserIds;

    const systemsToMerge: System[] = getSystemsToMerge(
      sourceAccount.systems,
      excludedSystemIds
    );
    const systemsToExclude: System[] = getExcludedSystems(
      sourceAccount.systems,
      excludedSystemIds
    );
    const usersToMerge: User[] = sourceAccount.users.filter(
      (user) => !excludedUserIds.includes(user.id)
    );
    const usersToExclude: User[] = sourceAccount.users.filter((user) =>
      excludedUserIds.includes(user.id)
    );

    dispatch(
      reviewDataUpdated({
        sourceAccountName,
        targetAccountName,
        systemsToMerge: systemsToMerge
          .filter((system) => !system.associatedSystem)
          .sort((s1, s2) => (s1.displayName > s2.displayName ? 1 : -1))
          .map(({ id, name, displayName }) => ({ id, name, displayName })),
        systemsToExclude: systemsToExclude
          .filter((system) => !system.associatedSystem)
          .sort((s1, s2) => (s1.displayName > s2.displayName ? 1 : -1))
          .map(({ id, name, displayName }) => ({ id, name, displayName })),
        usersToMerge: usersToMerge
          .sort((u1, u2) => (u1.email > u2.email ? 1 : -1))
          .map((user) => user.email),
        usersToExclude: usersToExclude
          .sort((u1, u2) => (u1.email > u2.email ? 1 : -1))
          .map((user) => user.email),
        rolesToMerge: [...sourceAccount.roles]
          .filter((role) => role.accountId)
          .sort((r1, r2) => (r1.displayName > r2.displayName ? 1 : -1))
          .map((role) => role.displayName),
      })
    );

    dispatch(
      mergeDataUpdated({
        sourceAccountGuid: sourceAccount.guid,
        targetAccountGuid: targetAccount.guid,
        systemGuidsToExclude: systemsToExclude.map((system) => system.guid),
        userGuidsToExclude: usersToExclude.map((user) => user.guid),
      })
    );
  };

export default reviewAndMergeSlice.reducer;
