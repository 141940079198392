import * as React from 'react';
import { SVGProps } from 'react';

const SvgAllFeaturesIcon1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#AllFeaturesIcon1_svg__a)"
      stroke="currentColor"
      strokeLinecap="round"
    >
      <path
        d="M16.32 2v-.31A1.125 1.125 0 0 0 15.195.563h-13.5A1.125 1.125 0 0 0 .57 1.69v12.376a1.125 1.125 0 0 0 1.125 1.125H2"
        strokeLinejoin="round"
      />
      <path d="M1 4h10" />
      <path
        d="M11.33 12a1.111 1.111 0 1 0 2.223 0 1.111 1.111 0 0 0-2.223 0v0Z"
        strokeLinejoin="round"
      />
      <path
        d="m13.385 7.705.327 1.076a.736.736 0 0 0 .87.504l1.09-.252a.992.992 0 0 1 .945 1.643l-.764.823a.74.74 0 0 0 0 1.009l.764.823a.992.992 0 0 1-.945 1.643l-1.09-.253a.736.736 0 0 0-.87.505l-.327 1.072a.985.985 0 0 1-1.887 0l-.328-1.077a.736.736 0 0 0-.87-.504l-1.09.252a.992.992 0 0 1-.945-1.643l.764-.823a.74.74 0 0 0 0-1.009l-.764-.823a.992.992 0 0 1 .945-1.643l1.09.253a.736.736 0 0 0 .87-.505l.328-1.076a.985.985 0 0 1 1.887.005Z"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="AllFeaturesIcon1_svg__a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAllFeaturesIcon1;
