import styled from 'styled-components';
import {
  MenuItem,
  Typography,
  Button,
  MenuItemText,
  Input,
} from '@walkme/ui-core';
import { WMDataGrid, WMSelect } from '@walkme/wm-ui';
import { DialogContent } from '@material-ui/core';

export const StyledPageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledHeaderButton = styled(Button)`
  margin: 0 10px !important;
`;

export const StyledTypography = styled(Typography)`
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #2f426c;
`;

export const StyledActionButtons = styled.div`
  display: flex;
`;

export const StyledWMDataGrid = styled(WMDataGrid)`
  .WMDataGridHeader-wmDataGridHeader {
    align-items: center;
  }
  .WMSearchFilter-clearButton {
    top: 20%;
  }

  .ag-header-icon.ag-header-cell-menu-button {
    opacity: 1 !important;
  }

  .ag-center-cols-viewport {
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .WMButton-secondary.WMDataGrid-menuButton {
    color: #2f426c;

    &:hover {
      color: #3b61eb;
    }
  }

  .MuiTooltip-tooltip.WMTooltip-wmTooltip.WMTooltip-dark.MuiTooltip-tooltipPlacementTop.WMTooltip-placementTop {
    padding: 6px 12px;
  }

  .ag-row-odd.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-focus {
    height: 60px !important;
  }
`;

export const StyledDataGridTitle = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2f426c;
  }

  .sub-title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2f426c;
  }
`;

export const StyledWMSelect = styled(WMSelect)`
  width: 220px;
  margin-right: 12px;
`;

export const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: column;

  .date {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    margin-bottom: 6px;

    .date-primary {
      font-size: 14px;
      font-family: 'ProximaNova';
      color: #2f426c;
      line-height: 1.5;
    }

    .date-secondary {
      font-size: 12px;
      font-family: 'ProximaNova';
      color: #78868f;
      line-height: 1.43;
    }
  }
`;

export const StyledActionContainer = styled.div`
  display: flex;
  border-radius: 50%;

  &:hover {
    background: #3b61eb0d;
  }

  svg {
    padding: 5px;
  }
`;

export const StyledActionMenuItem = styled(MenuItem)`
  display: flex;
  gap: 8px;
`;

export const StyledTextWithTooltip = styled(MenuItemText)<{ isName: boolean }>`
  line-height: inherit;
  color: #2f426c;
  font-size: 14px;
  font-weight: ${(props) => (props.isName ? '600' : '')};
`;

export const StyledChangeStateButton = styled(Button)`
  margin-left: 20px !important;
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  border-top: 1px solid rgb(235, 239, 247);

  .MuiDialogContentText-root {
    padding: 0 0 10px;
    font-family: ProximaNova;
    font-size: 14px;
    color: #2f426c;
    display: block;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;

  & input:focus {
    border-width: 1px;
    outline: none;
  }

  & textarea:focus {
    border-width: 1px;
    outline: none;
  }
`;
