import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { AppData } from '@wm-accounts-backoffice-center/general-types';
import { ThunkAction } from 'redux-thunk';
import {
  cmtApi,
  Deployables,
  FlatDeployables,
  Folder,
  DeployablesTypes,
  SystemDeployableCountItem,
} from '@wm-accounts-backoffice-center/wm-api';

export interface DeployablesState {
  systemsDeployablesCount: AppData<SystemDeployableCountItem[]>;
  systemDeployables: AppData<Folder>;
  flatSystemDeployables: AppData<FlatDeployables[]>;
  realDeployablesTypes: AppData<DeployablesTypes[]>;
}

export const initialDeployablesState: DeployablesState = {
  systemsDeployablesCount: {
    loading: false,
    error: '',
    data: [],
  },
  realDeployablesTypes: {
    loading: false,
    error: '',
    data: [],
  },
  flatSystemDeployables: {
    loading: false,
    error: '',
    data: [],
  },
  systemDeployables: {
    loading: false,
    error: '',
    data: {
      id: 0,
      name: '',
      parentId: 0,
      subFolders: [],
      deployables: [],
    },
  },
};

const deployablesSlice = createSlice({
  name: 'deployablesSlice',
  initialState: initialDeployablesState,
  reducers: {
    getSystemsDeployablesCountStart(state: DeployablesState) {
      state.systemsDeployablesCount.error = '';
      state.systemsDeployablesCount.loading = true;
      return state;
    },
    getSystemsDeployablesCountSuccess(
      state: DeployablesState,
      action: PayloadAction<SystemDeployableCountItem[]>
    ) {
      state.systemsDeployablesCount.data = action.payload;
      state.systemsDeployablesCount.loading = false;
      state.systemsDeployablesCount.error = '';
      return state;
    },
    getSystemsDeployablesCountFailed(state, action: PayloadAction<string>) {
      state.systemsDeployablesCount.loading = false;
      state.systemsDeployablesCount.error = action.payload;
      return state;
    },
    getSystemDeployablesStart(state: DeployablesState) {
      state.systemDeployables.error = '';
      state.systemDeployables.loading = true;
      return state;
    },
    getSystemDeployablesSuccess(
      state: DeployablesState,
      action: PayloadAction<Folder>
    ) {
      state.systemDeployables.data = action.payload;
      state.systemDeployables.loading = false;
      state.systemDeployables.error = '';
      return state;
    },
    getSystemDeployablesFailed(state, action: PayloadAction<string>) {
      state.systemDeployables.loading = false;
      state.systemDeployables.error = action.payload;
      return state;
    },
    getDeployablesTypesStart(state: DeployablesState) {
      state.realDeployablesTypes.error = '';
      state.realDeployablesTypes.loading = true;
      return state;
    },
    getDeployablesTypesSuccess(
      state: DeployablesState,
      action: PayloadAction<DeployablesTypes[]>
    ) {
      state.realDeployablesTypes.data = action.payload;
      state.realDeployablesTypes.loading = false;
      state.realDeployablesTypes.error = '';
      return state;
    },
    getDeployablesTypesFailed(state, action: PayloadAction<string>) {
      state.realDeployablesTypes.loading = false;
      state.realDeployablesTypes.error = action.payload;
      return state;
    },
    getFlatDeployablesTypesStart(state: DeployablesState) {
      state.flatSystemDeployables.error = '';
      state.flatSystemDeployables.loading = true;
      return state;
    },
    getFlatDeployablesTypesSuccess(
      state: DeployablesState,
      action: PayloadAction<FlatDeployables[]>
    ) {
      state.flatSystemDeployables.data = action.payload;
      state.flatSystemDeployables.loading = false;
      state.flatSystemDeployables.error = '';
      return state;
    },
    getFlatDeployablesTypesFailed(state, action: PayloadAction<string>) {
      state.flatSystemDeployables.loading = false;
      state.flatSystemDeployables.error = action.payload;
      return state;
    },
  },
});

export { deployablesSlice };
const {
  getSystemsDeployablesCountStart,
  getSystemsDeployablesCountSuccess,
  getSystemsDeployablesCountFailed,
  getSystemDeployablesStart,
  getSystemDeployablesSuccess,
  getSystemDeployablesFailed,
  getDeployablesTypesSuccess,
  getDeployablesTypesStart,
  getDeployablesTypesFailed,
  getFlatDeployablesTypesSuccess,
  getFlatDeployablesTypesStart,
  getFlatDeployablesTypesFailed,
} = deployablesSlice.actions;
type rootReducerType = ReturnType<typeof deployablesSlice.reducer>;
type AppThunk = ThunkAction<void, rootReducerType, unknown, Action<string>>;

export const getSystemsDeployablesCount =
  (systemIds: number[], forceLoad = false): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(getSystemsDeployablesCountStart());
      const deployables: SystemDeployableCountItem[] =
        await cmtApi.getSystemsDeployablesCount(systemIds);
      dispatch(getSystemsDeployablesCountSuccess(deployables));
    } catch (err) {
      dispatch(getSystemsDeployablesCountFailed(err.message));
      return;
    }
  };

export const getSystemDeployables =
  (systemId: number, folderId: number, forceLoad = false): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(getSystemDeployablesStart());
      const deployables: Folder = await cmtApi.getSystemDeployables(
        systemId,
        folderId
      );
      dispatch(getSystemDeployablesSuccess(deployables));
    } catch (err) {
      dispatch(getSystemDeployablesFailed(err.message));
      return;
    }
  };

export const flatDeployablesAndFolders =
  (systemId: number, folderId: number, forceLoad = false): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(getFlatDeployablesTypesStart());
      const deployables: FlatDeployables[] =
        await cmtApi.flatDeployablesAndFolders(systemId, folderId);
      dispatch(getFlatDeployablesTypesSuccess(deployables));
    } catch (err) {
      dispatch(getFlatDeployablesTypesFailed(err.message));
      return;
    }
  };

export const deployablesTypes = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(getDeployablesTypesStart());
    const deployables: DeployablesTypes[] = await cmtApi.deployablesTypes();
    dispatch(getDeployablesTypesSuccess(deployables));
  } catch (err) {
    dispatch(getDeployablesTypesFailed(err.message));
    return;
  }
};
