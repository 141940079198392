import { rootReducer } from '../../../../../../../../../../apps/home/src/redux/rootReducer';
import { AppData } from '../../../../../../../../../general-types/src';
import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { webHooksApi } from '../../../../../../../../../wm-api/src/lib/webhooks-api';
import { ThunkAction } from 'redux-thunk';
import { WMSnackbarVariant } from '@walkme/wm-ui';
import { SnackBarMessage } from 'libs/wm-api/src';

export interface WebHookMapping {
  _id: string;
  source: string;
  target: string;
  required: boolean;
}
export interface WebHookCondition {
  _id: string;
  fieldName: string;
  type: string;
  value: string;
}
export interface WebHook {
  additionalData?: any[];
  alias: string;
  aliasDisplayName?: string;
  conditions?: WebHookCondition[];
  creationDate?: Date;
  updatedAt?: Date;
  encoding: string;
  eventName: string;
  isSyncOp: boolean;
  mappings: WebHookMapping[];
  requestMethod: 'POST' | 'PUT' | 'DELETE' | undefined;
  shouldExposeStatus: boolean;
  syncOpGroup?: string;
  syncOpGroupPriority?: number;
  url: string;
}
export interface WebHooksState {
  allWebHooks: AppData<WebHook[]>;
  webHookEventNames: AppData<Set<string>>;
  addWebHook: AppData<WebHook>;
  deleteWebHook: AppData<WebHook>;
  updateWebHook: AppData<WebHook>;
  snackBarMessage: SnackBarMessage;
}

export const initialWebHooksState: WebHooksState = {
  allWebHooks: {
    loading: false,
    error: null,
    data: [],
  },
  webHookEventNames: {
    loading: false,
    error: null,
    data: new Set(),
  },
  addWebHook: {
    loading: false,
    error: null,
    data: null,
  },
  deleteWebHook: {
    loading: false,
    error: null,
    data: null,
  },
  updateWebHook: {
    loading: false,
    error: null,
    data: null,
  },
  snackBarMessage: {
    text: '',
    variant: WMSnackbarVariant.Success,
    isOpen: false,
  },
};

const webHooksSlice = createSlice({
  name: 'webHooksSlice',
  initialState: initialWebHooksState,
  reducers: {
    fetchWebHooksStart(state: WebHooksState) {
      state.allWebHooks.loading = true;
      return state;
    },
    fetchWebHooksSuccess(
      state: WebHooksState,
      action: PayloadAction<WebHook[]>
    ) {
      state.allWebHooks.loading = false;
      state.allWebHooks.data = action.payload;
      return state;
    },
    fetchWebHooksEventNamesSuccess(
      state: WebHooksState,
      action: PayloadAction<Set<string>>
    ) {
      state.allWebHooks.loading = false;
      state.webHookEventNames.data = action.payload;
      return state;
    },
    fetchWebHooksFailed(state: WebHooksState, action: PayloadAction<string>) {
      state.allWebHooks.loading = false;
      state.allWebHooks.error = action.payload;
      return state;
    },
    cleanupWebHooksPageErrors(state) {
      state.snackBarMessage.isOpen = false;
      return state;
    },
    setSnackBarMessage(state, action: PayloadAction<SnackBarMessage>) {
      state.snackBarMessage.text = action.payload.text;
      state.snackBarMessage.variant = action.payload.variant;
      state.snackBarMessage.isOpen = action.payload.isOpen;
      return state;
    },
  },
});

export { webHooksSlice };

const {
  fetchWebHooksStart,
  fetchWebHooksSuccess,
  fetchWebHooksEventNamesSuccess,
  fetchWebHooksFailed,
  cleanupWebHooksPageErrors,
  setSnackBarMessage,
} = webHooksSlice.actions;

export type webHooksStateType = ReturnType<typeof webHooksSlice.reducer>;
export type rootReducerType = ReturnType<typeof rootReducer>;
export type featuresAppThunk = ThunkAction<
  void,
  rootReducerType,
  unknown,
  Action<string>
>;

export const fetchWebHooks =
  (): featuresAppThunk => async (dispatch, getState) => {
    try {
      dispatch(fetchWebHooksStart());
      const webHooksResponse: WebHook[] = await webHooksApi.getWebhooks();
      const webHooksEventName = new Set(
        webHooksResponse.flatMap((webHook) => webHook['eventName']).sort()
      );
      dispatch(fetchWebHooksSuccess(webHooksResponse));
      dispatch(fetchWebHooksEventNamesSuccess(webHooksEventName));
    } catch (err) {
      dispatch(fetchWebHooksFailed(err.message));
      return;
    }
  };
