import styled from 'styled-components';
import { Dialog } from '@walkme/ui-core';
import { DialogContent } from '@material-ui/core';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    height: 75vh !important;
    width: 85vw !important;
  }

  .MuiDialogContent-root {
    scrollbar-width: none;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  border-top: 1px solid rgb(235, 239, 247);
  border-bottom: 1px solid rgb(235, 239, 247);
  padding: 16px 32px;
`;

export const StyledContentContainer = styled.div`
  scrollbar-width: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content {
    display: flex;
    flex-direction: column;

    .sub-title {
      padding-bottom: 32px;
    }
  }
`;

export const ColorMap = styled.div`
  display: flex;
  gap: 32px;
  justify-content: center;
  padding: 32px 0 24px 0;

  .color-description-key {
    display: flex;
    align-items: baseline;
    gap: 8px;
  }
`;
