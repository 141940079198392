import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  DialogActions,
  Typography,
  DialogTitle,
  Dot,
} from '@walkme/ui-core';
import { featureTogglesSlice } from '../../../../../state-management-feature-toggles/src';
import {
  StyledDialog,
  StyledDialogContent,
  StyledContentContainer,
  ColorMap,
} from './evaluations-dialog.styles';
import { LoaderContainer } from '../feature-toggle-history/history-dialog.styles';
import { WMLoader } from '@walkme/wm-ui';
import { EvaluationChart } from './evaluation-chart';
import useFeatureFlagEvaluations from '../../../../../state-management-feature-toggles/src/lib/hooks/use-get-evaluations';
import {
  ChartSize,
  falseEvaluationColor,
  trueEvaluationColor,
} from './evaluations.lib';

export interface EvaluationsDialogProps {
  isOpen: boolean;
  activeFeatureFlag: any;
  unsetSelectedFeatureFlag: () => void;
}

export const EvaluationsDialog = ({
  isOpen,
  activeFeatureFlag,
  unsetSelectedFeatureFlag,
}: EvaluationsDialogProps) => {
  const dispatch = useDispatch();
  const {
    ownerTeam: { _id: ownerTeam },
    name,
    _id,
  } = activeFeatureFlag;
  const featureFlagEvaluations = useFeatureFlagEvaluations(_id);
  const maxTotalEvaluations = featureFlagEvaluations
    ? Math.max(
        ...featureFlagEvaluations.map(
          (evaluation) =>
            evaluation.trueEvaluations + evaluation.falseEvaluations
        )
      )
    : 0;

  const onClose = () => {
    dispatch(featureTogglesSlice.actions.setEvaluationsDialogOpen(false));
    dispatch(featureTogglesSlice.actions.cleanupEvaluationsDialog());
    unsetSelectedFeatureFlag();
  };

  const loaderComponent = (
    <LoaderContainer>
      <WMLoader />
    </LoaderContainer>
  );

  return (
    <StyledDialog isOpen={isOpen} onClose={onClose} preventClose>
      <DialogTitle>
        Evaluations for feature flag <b>{name}</b>
      </DialogTitle>
      <StyledDialogContent>
        <StyledContentContainer>
          <div className={'content'}>
            <div className={'sub-title'}>
              <Typography variant={'T20'}>
                'True' and 'False' evaluations across different time periods.
                Hover over the charts to view additional details.
              </Typography>
            </div>

            {featureFlagEvaluations?.map((evaluation) => {
              return (
                <EvaluationChart
                  evaluation={evaluation}
                  chartSize={ChartSize.DEFAULT}
                  maxTotalEvaluations={maxTotalEvaluations}
                />
              );
            })}
          </div>

          {featureFlagEvaluations && (
            <ColorMap className={'color-map'}>
              <div className={'color-description-key'}>
                <Dot customColor={trueEvaluationColor} />
                <Typography variant={'T20'}>true</Typography>
              </div>
              <div className={'color-description-key'}>
                <Dot customColor={falseEvaluationColor} />
                <Typography variant={'T20'}>false</Typography>
              </div>
            </ColorMap>
          )}

          {featureFlagEvaluations === null && loaderComponent}
        </StyledContentContainer>
      </StyledDialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </StyledDialog>
  );
};
