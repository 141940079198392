import { styledTheme } from '@wm-accounts-backoffice-center/shared-styles';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const {
  colors: {
    main: {
      mainShadowColor,
      mainBg,
      mainBorder,
      mainText,
      hoverColor,
      iconColor,
    },
    apps: { menuitemColor },
  },
} = styledTheme;

export const StyledMenu = styled(Menu)`
  .MuiPaper-elevation8 {
    box-shadow: 0px 5px 5px -3px ${mainShadowColor},
      0px 8px 10px 1px ${mainShadowColor}, 0px 3px 14px 2px ${mainShadowColor};
  }

  &.bottom-left {
    .MuiPaper-elevation8 {
      border: 1px solid ${mainBorder};
      min-width: 120px;
      box-shadow: 0 3px 10px 0 rgba(192, 206, 235, 0.5);
    }
  }

  &.top-menu {
    .MuiPaper-elevation8 {
      box-shadow: 4px 0 13px 0 rgba(192, 206, 235, 0.5);
      border: 1px solid ${mainBorder};
    }
    > div {
      margin-top: 31px;

      > ul {
        min-width: 164px;

        .app-item {
          min-width: 256px;
          font-family: 'Poppins';

          .app-icon {
            width: 24px;
            margin-right: 8px;
          }

          .app-name {
            margin: 0 4px 0 0;
            color: ${menuitemColor};
          }
        }

        .MuiListItem-button {
          background-color: ${mainBg};
          font-family: 'Poppins';

          &.no-action:hover {
            background: none;
            cursor: default;
          }

          &:hover {
            background-color: ${hoverColor};
          }
        }
      }
    }

    .link {
      text-decoration: none;
      color: ${mainText};
    }
  }
`;

export const StyledMenuTitle = styled.div`
  padding: 16px 16px 8px;
  font-weight: bold;
  pointer-events: none;
`;

export const StyledTextItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-family: 'Poppins';

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .item-icon {
    margin: 0 4px 3px 0;
  }

  .selected-icon {
    color: ${iconColor};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: 16px;

  &.no-action {
    padding: 5px 20px 15px 20px;
    background: none;
    background-color: ${mainBg};
    color: ${mainText};
    font-family: 'Poppins';
  }

  .text {
    flex: 1;
  }

  .icon {
    margin-right: 8px;
    display: flex;
  }
`;
