import React from 'react';
import { Tooltip } from '@walkme/ui-core';

export const TargetAccountCell = (params) => {
  const CellContent = () => (
    <>
      {params.data.toAccount}
    </>
  );

  if (params.data.toAccount.length > 30) {
    return (<Tooltip title={params.data.toAccount}>
      <CellContent />
    </Tooltip>)
  }

  return CellContent();
};
