import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  MenuAction,
  WMDataGrid,
  WMDataGridActionColumn,
  WMIconMoreHorizontal,
  WMSwitch,
  WMTagList,
  WMTagVariant,
} from '@walkme/wm-ui';
import styled from 'styled-components';
import { Box, Drawer, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  EntityFeatureFlag,
  FeatureFlag,
} from '@wm-accounts-backoffice-center/state-management-features';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { FeatureFlagType } from '@wm-accounts-backoffice-center/wm-api';
import { useStylesMainTabs } from '../styles/styles';
import { Export, Settings } from '@walkme/ui-icons';
import SvgImport from '../styles/svgMenu/Import';
import { Button, IconButton, Spinner, Tooltip } from '@walkme/ui-core';
import { Environment, Environments, System } from 'wm-accounts-sdk';
import ImportFeatureDialog from './import-feature-dialog';
import FeaturesDisableWarningDialog from './features-disable-warning-dialog';
import FeaturesExportWarningDialog, {
  FeaturesExportWarningDialogType,
} from './features-export-warning-dialog';

export interface FeaturesTableProps {
  featuresForEntityType: FeatureFlag[];
  entityFeatureFlags: EntityFeatureFlag[];
  featureFlagTypes: FeatureFlagType[];
  environments?: Environment[];
  excludedEnvs?: { [key: string]: number[] };
  onOpenEnvClicked?: (featureFlag: FeatureFlag) => void;
  onRowClicked?: (featureFlagId: number) => void;
  onRemoveFeatures: (featureFlag: FeatureFlag[]) => void;
  onAddFeatures: (featureFlagId: FeatureFlag[]) => void;
  extraTableHeadersElements?: JSX.Element[];
  isUpdatingFeatures: boolean;
  loadingTable: boolean;
  cleanupRowsSelection: boolean;
  headerElement?: JSX.Element;
  tableTitle: string;
}

export const StyledWMDataGrid = styled(WMDataGrid)`
  .WMDataGridHeader-wmDataGridHeader {
    align-items: center;
  }
  .WMSearchFilter-clearButton {
    top: 20%;
  }
`;
export const TableHeader = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
  margin-right: 50px;
`;

interface FeatureRow {
  featureName: string;
  description: string;
  lastModified: string;
  modifiedBy: string;
  type: string;
  id: number;
  checked: string;
  environments: Environment[];
  featureFlag: FeatureFlag;
}

const getFeatureRows = (
  allFeatures: FeatureFlag[],
  featureFlagTypesData: FeatureFlagType[],
  entityFeature: EntityFeatureFlag[],
  excludedEnvs: { [key: string]: number[] },
  envs: Environments
): FeatureRow[] => {
  if (allFeatures) {
    return allFeatures.map((feature) => {
      const featureName = feature.flagName ?? '';
      const featureType = featureFlagTypesData?.find(
        (featureTypeData) => featureTypeData.value === feature.type
      );
      const featureTypeDisplayName = featureType
        ? featureType.displayName
        : feature.type;

      const modifiedBy = feature.description ? feature.description.email : '';
      const lastModified =
        feature.description && feature.description.updatedAt
          ? moment(feature.description.updatedAt).format('MMM Do YYYY')
          : '';

      const checked = !!entityFeature?.find(
        (featureEntity) => featureEntity.FeatureId === feature.id
      );

      const existingEnvsForFeature =
        featureType.value === 'SystemRuntime' && checked
          ? envs.filter((env) => {
              const existsForFeature =
                excludedEnvs && excludedEnvs[featureName];
              return !existsForFeature || !existsForFeature.includes(env.id);
            })
          : [];

      return {
        featureFlag: feature,
        featureName,
        description: feature.description ? feature.description.description : '',
        lastModified,
        modifiedBy,
        type: featureTypeDisplayName,
        id: feature.id,
        checked: checked ? 'Enabled' : 'Disabled',
        environments: existingEnvsForFeature,
      };
    });
  }
  return [];
};

export function FeaturesTable(pageProps: FeaturesTableProps) {
  const classes = useStylesMainTabs();

  const dispatch = useDispatch();

  const [clickedRowId, setClickedRowId] = useState(0);

  const allFeaturesRowData = useMemo(() => {
    return getFeatureRows(
      pageProps.featuresForEntityType,
      pageProps.featureFlagTypes,
      pageProps.entityFeatureFlags,
      pageProps.excludedEnvs || {},
      pageProps.environments
    );
  }, [pageProps.featuresForEntityType, pageProps.featureFlagTypes]);

  const handleFFSelection = useCallback((event) => {
    setClickedRowId(event.rowIndex);
    if (!event.colDef.colId) return;
  }, []);

  const [showImportDialog, setShowImportDialog] = useState(false);

  const [showExportDialogStatus, setShowExportDialogStatus] = useState(
    FeaturesExportWarningDialogType.hidden
  );
  const [showDisableDialog, setShowDisableDialog] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState<FeatureFlag[]>([]);

  const [disableRows, setDisableRows] = useState(false);
  const [sortChanged, setSortChanged] = useState(false);

  useEffect(() => {
    if (pageProps.cleanupRowsSelection) {
      setShowExportDialogStatus(FeaturesExportWarningDialogType.hidden);
      setShowImportDialog(false);
      setShowDisableDialog(false);
    }
  }, [pageProps.cleanupRowsSelection]);

  const entityFeature = pageProps.entityFeatureFlags;

  function getActionColumn<T>(menuActions: MenuAction<T>[]) {
    return {
      colId: 'actions-column',
      pinned: 'right',
      suppressSizeToFit: true,
      width: 120,
      sortable: true,
      filter: false,
      cellStyle: (params) => {
        if (params.data.type != 'System Runtime') {
          return { visibility: 'hidden' };
        }
      },
      cellRenderer: WMDataGridActionColumn,
      cellRendererParams: {
        actions: menuActions,
        icon: <WMIconMoreHorizontal size={12} color="grey" />,
      },
    };
  }

  const actions: MenuAction<FeatureFlag>[] = [
    {
      label: 'Open feature environment',
      icon: <Settings />,
      onClick: (featureFlag) => pageProps.onOpenEnvClicked(featureFlag),
    },
  ];

  var valueFormatter = function (params) {
    return params.value
      .map(function (animal) {
        return animal.name;
      })
      .join(', ');
  };

  const headCells = useMemo(() => {
    return [
      {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerName: 'Name',
        field: 'featureName',
        lockVisible: true,
        pinned: true,
        minWidth: 250,
        sortable: true,
        cellRenderer: function (params) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 600,
                  color: '#2F426C',
                  fontSize: '12px',
                  lineHeight: '16px',
                }}
              >
                {params.value}
              </span>
              {params.data.description && (
                <span
                  style={{
                    fontFamily: 'Proxima Nova',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '16px',

                    color: '#637191',
                  }}
                >
                  {params.data.description}
                </span>
              )}
            </div>
          );
        },
      },
      {
        headerName: 'Type',
        field: 'type',
        sortable: true,
        filter: true,
        minWidth: 150,
        cellStyle: {
          color: '#2F426C',
          fontSize: '12px',
        },
      },
      {
        headerName: 'Enabled by',
        field: 'modifiedBy',
        minWidth: 250,
        filter: true,
        sortable: true,
        cellStyle: {
          color: '#2F426C',
          fontSize: '12px',
        },
        valueGetter: function (params) {
          const Feature = entityFeature?.find(
            (feature) => feature.FeatureId === params.data.id
          );
          return Feature ? Feature.activatedByUserEmail : '';
        },
      },
      {
        headerName: 'Enabled Date',
        field: 'lastModified',
        sortable: true,
        filter: true,
        minWidth: 150,
        cellStyle: {
          color: '#2F426C',
          fontSize: '12px',
        },

        keyCreator: (params) =>
          params.value
            ? moment(params.value).format('MMM Do YYYY')
            : '(Blanks)',
        valueGetter: function (params) {
          const Feature = entityFeature?.find(
            (feature) => feature.FeatureId === params.data.id
          );
          return Feature ? Feature.createdAt : '';
        },
        valueFormatter: function (params) {
          return params.value === ''
            ? ''
            : moment(params.value).format('MMM Do YYYY');
        },
      },
      {
        headerName: 'Environments',
        minWidth: 300,
        hide: !pageProps.environments,
        sortable: false,
        filter: 'agSetColumnFilter',
        valueFormatter: valueFormatter,
        keyCreator: (params) =>
          params.value.length > 0 ? params.value.map((v) => v.name) : null,
        filterParams: {
          valueFormatter: (params) =>
            params.value ? params.value.name : '(Blanks)',
        },
        field: 'environments',
        cellStyle: { display: 'flex', alignItems: 'center', height: '100%' },
        cellRenderer: function (params) {
          const envs: Environments = params.value || [];
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <WMTagList
                style={{
                  display: 'block',
                  marginBottom: '15px',
                  marginTop: '15px',
                }}
                labels={envs.map((text) => ({
                  labelText: text.name,
                  fullLabel: true,
                  variant: WMTagVariant.Comet,
                }))}
                maxTagsShown={4}
              />
            </div>
          );
        },
      },
      {
        headerName: 'Status',
        field: 'checked',
        filter: true,
        minWidth: 200,
        sortable: true,
        sort: sortChanged ? null : 'desc',
        cellRenderer: function (params) {
          return (
            <WMSwitch
              ds2
              checked={params.value === 'Enabled'}
              label={
                pageProps.isUpdatingFeatures &&
                params.rowIndex === clickedRowId ? (
                  <Spinner />
                ) : (
                  <span style={{ color: '#2F426C', fontSize: '12px' }}>
                    {params.value}
                  </span>
                )
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const entityFeatureFlag: EntityFeatureFlag = params.data;
                !(params.value === 'Enabled')
                  ? pageProps.onAddFeatures([entityFeatureFlag.featureFlag])
                  : pageProps.onRemoveFeatures([entityFeatureFlag.featureFlag]);
                setClickedRowId(params.rowIndex);
              }}
            />
          );
        },
      },

      getActionColumn<FeatureFlag>(actions),
    ];
  }, [entityFeature, pageProps.isUpdatingFeatures, clickedRowId]);

  const headerActions = [
    <Tooltip title="Import features">
      <IconButton
        variant="ghost"
        onClick={() => {
          setShowImportDialog(true);
        }}
      >
        <SvgImport />
      </IconButton>
    </Tooltip>,
    <Tooltip title="Export enabled features">
      <IconButton
        variant="ghost"
        onClick={() => {
          setSelectedFeatures(
            pageProps.entityFeatureFlags
              .filter((entityFF) =>
                pageProps.featuresForEntityType.find(
                  (ff) => ff.flagName === entityFF.featureFlag.flagName
                )
              )
              .map((entityFeature) => entityFeature.featureFlag)
          );
          setShowExportDialogStatus(
            FeaturesExportWarningDialogType.exportEnabled
          );
        }}
      >
        <Export />
      </IconButton>
    </Tooltip>,
  ];

  if (pageProps.extraTableHeadersElements) {
    headerActions.push(...pageProps.extraTableHeadersElements);
  }

  const footerActions = () => {
    const actions = mainAction;
    if (disableRows) actions.push(footerActionsNoEnable);
    return actions;
  };

  const mainAction = [
    {
      onClick: (rows: FeatureRow[]) => {
        setSelectedFeatures(rows.map((row) => row.featureFlag));
        setShowExportDialogStatus(
          FeaturesExportWarningDialogType.exportSelected
        );
      },
      icon: (
        <Tooltip title="Export selected features">
          <Export />
        </Tooltip>
      ),
      title: '',
      className: '',
    },
  ];

  const isRowSelectable = (rowNode) =>
    rowNode.data ? rowNode.data.checked === 'Enabled' : false;

  const footerActionsNoEnable = {
    onClick: (rows: FeatureRow[]) => {
      const rowsToDisable = rows.filter(
        (row) =>
          !!entityFeature?.find((feature) => row.id === feature.FeatureId)
      );
      setSelectedFeatures(rowsToDisable.map((row) => row.featureFlag));
      setShowDisableDialog(true);
    },
    title: 'Disable Features',
    icon: null,
    className: classes.DisableButton,
    loading: pageProps.isUpdatingFeatures,
    disabled: pageProps.isUpdatingFeatures,
  };

  const onRowSelected = useCallback((event) => {
    const allSelectedFF = event.api.getSelectedNodes();
    const disabledFeatures = !!allSelectedFF?.find(
      (ff) => ff.data.checked === 'Disabled'
    );
    const enabledFeatures = !!allSelectedFF?.find(
      (ff) => ff.data.checked === 'Enabled'
    );
    setDisableRows(enabledFeatures);
  }, []);

  function onSortChanged(e) {
    setSortChanged(true);
  }

  return (
    <div style={{ height: 'calc(100% - 60px)' }}>
      <ImportFeatureDialog
        showDialog={showImportDialog}
        exit={() => {
          setShowImportDialog(false);
        }}
        importFeatures={(features: string[]) => {
          pageProps.onAddFeatures(
            features.map((flagName) =>
              pageProps.featuresForEntityType.find(
                (featureFlag) => featureFlag.flagName === flagName
              )
            )
          );
        }}
        entityFeatures={pageProps.featuresForEntityType}
        currentFeatures={pageProps.entityFeatureFlags}
        loading={pageProps.isUpdatingFeatures}
      />

      <FeaturesExportWarningDialog
        features={selectedFeatures}
        onClose={() => {
          setSelectedFeatures([]);
          setShowExportDialogStatus(FeaturesExportWarningDialogType.hidden);
        }}
        isOpen={
          showExportDialogStatus !== FeaturesExportWarningDialogType.hidden
        }
        type={showExportDialogStatus}
      />

      <FeaturesDisableWarningDialog
        features={selectedFeatures}
        onClose={() => setShowDisableDialog(false)}
        onConfirm={(featureFlags) => {
          pageProps.onRemoveFeatures(featureFlags);
        }}
        isOpen={showDisableDialog}
        loading={pageProps.isUpdatingFeatures}
      />
      <div style={{ height: '100%' }}>
        <StyledWMDataGrid
          title={
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box style={{ paddingRight: '40px' }}>
                <Typography
                  style={{
                    fontFamily: 'Poppins',
                    color: '#2F426C',
                    fontSize: '18px',
                    fontWeight: 600,
                    fontStyle: 'normal',
                    lineHeight: '28px',
                  }}
                >
                  {pageProps.tableTitle}
                </Typography>
              </Box>
              {pageProps.headerElement}
            </Box>
          }
          columnDefs={headCells}
          rows={allFeaturesRowData}
          entityName={`${selectedFeatures.length > 1 ? 'Features' : 'Feature'}`}
          headerActions={headerActions}
          footerActions={footerActions()}
          hideColumnPicker
          hideExport
          pagination={false}
          loadingData={pageProps.isUpdatingFeatures || pageProps.loadingTable}
          gridConfig={{
            isRowSelectable: isRowSelectable,
            onCellClicked: (event) => handleFFSelection(event),
            onSelectionChanged: (event) => onRowSelected(event),
            onSortChanged: (event) => onSortChanged(event),
          }}
          clearRowSelection={pageProps.cleanupRowsSelection}
        />
      </div>
    </div>
  );
}

export default FeaturesTable;
