import * as React from 'react';
import { SVGProps } from 'react';

const Svg3DBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#3DBox_svg__a)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m8 8.727-5.5-2.5v6l5.5 2.5v-6ZM8 8.727l5.5-2.5v6l-5.5 2.5v-6ZM13.5 6.227 8 3.727l-5.5 2.5M8 3.727v-2.5M2.5 12.227l-2 1M13.5 12.227l2 1" />
    </g>
    <defs>
      <clipPath id="3DBox_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Svg3DBox;
