import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Root from './app/root';
import { Provider } from 'react-redux';
import store from '../src/redux/store';
import { WMAuthManager, WMAuthManagerType } from 'wm-accounts-auth';
import { AccountsSdk, AuditLogSdk } from 'wm-accounts-sdk';
import { ErrorBoundary } from './error-boundary';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import {
  loginTestMethod,
  enhanceAuthConfig,
} from './app/utils/testing-login-utils';

datadogRum.init({
  applicationId: window.clientConfig['NX_DD_APP_ID'] as string,
  clientToken: window.clientConfig['NX_DD_CLIENT_TOKEN'] as string,
  site: window.clientConfig['NX_DD_SITE'],
  service: 'accounts-backoffice-center',
  env: window.clientConfig['NX_WM_NODE_ENV'],
  sampleRate: 100,
  trackInteractions: true,
});

datadogLogs.init({
  clientToken: window.clientConfig['NX_DD_CLIENT_TOKEN'] as string,
  site: window.clientConfig['NX_DD_SITE'],
  service: 'accounts-backoffice-center',
  env: window.clientConfig['NX_WM_NODE_ENV'],
  forwardErrorsToLogs: false, // Prevent reporting every console error
  sampleRate: 100,
});

const wmAuthConfig = {
  managerType: window.clientConfig.NX_AUTH_TYPE as WMAuthManagerType,
  apiUrl: window.clientConfig.NX_WM_API_URL,
  authorityUrl: window.clientConfig.NX_AUTH_URL,
  clientId: window.clientConfig.NX_IDENTITY_CLIENT_ID,
  responseType: window.clientConfig.NX_IDENTITY_RESPONSE_TYPE,
  shouldShowDebugLogs:
    window.clientConfig.NX_WM_NODE_ENV === 'dev2' ||
    window.clientConfig.NX_WM_NODE_ENV === 'qa',
  scope: window.clientConfig.NX_IDENTITY_SCOPE,
  supportIdleLogout: true,
};

if (loginTestMethod) {
  enhanceAuthConfig(wmAuthConfig);
}

WMAuthManager.createWithConfig(wmAuthConfig);

AccountsSdk.createWithConfig({
  baseUrl: window.clientConfig.NX_WM_API_URL,
  accessTokenGetter: {
    getAuthorizationHeaderValue(): string {
      return WMAuthManager.getInstance().getAuthorizationHeaderValue();
    },
    getUserToken(): string {
      return WMAuthManager.getInstance().getUserToken();
    },
  },
});

AuditLogSdk.createWithConfig({
  baseUrl: window.clientConfig.NX_WM_API_URL,
  accessTokenGetter: {
    getAuthorizationHeaderValue(): string {
      return WMAuthManager.getInstance().getAuthorizationHeaderValue();
    },
    getUserToken(): string {
      return WMAuthManager.getInstance().getUserToken();
    },
  },
});

const script = document.createElement('script');
script.async = true;
script.innerHTML = `(function () {
    var snippetUrl = '${window.clientConfig['NX_WALKME_SNIPPET']}';
    var walkme = document.createElement('script');
    walkme.type = 'text/javascript';
    walkme.async = true;
    walkme.src = snippetUrl;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(walkme, s);
    window._walkmeConfig = { smartLoad: true };
  })();`;
document.head.appendChild(script);

console.log('load main app');

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
