import { Spinner, Tabs } from '@walkme/ui-core';
import {
  AccountState,
  getAccountById,
  hasPermission,
} from '@wm-accounts-backoffice-center/state-management-users';
import { Account } from '@wm-accounts-backoffice-center/wm-api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AccountUsersPage from '../account-users/account-users';
import { DropdownSelectionMenuWithSearch } from '../features/DropdownSelectionMenuWithSearch';
import { TitleTab, SubTitleTab } from '../styles/styles';
import SystemInfoPage from './system-info-tab';
import { useSystemStyles } from './system-details.styles';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import {
  WMButton,
  WMButtonVariant,
  WMIconDelete,
  WMIconUndo,
  WMSnackbar,
  WMSnackbarVariant,
} from '@walkme/wm-ui';
import {
  systemsSlice,
  SystemsState,
  updateSystemsStatus,
} from 'libs/state-management-systems/src';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionTypeDetails,
  SubscriptionStatusActionType,
  SubscriptionStatusType,
  System,
} from 'wm-accounts-sdk';
import { AppData } from '@wm-accounts-backoffice-center/general-types';

export interface SystemsPageProps {
  selectedSystemId: number;
  lastSelectedSystemId?: number;
  account: Account;
}

const StyledTabs = styled(Tabs)`
  height: calc(100% - 104px) !important;
  div[role='tabpanel'] {
    height: calc(100% - 60px) !important;
  }
`;

export function SystemsPage({
  selectedSystemId,
  lastSelectedSystemId,
  account,
}: SystemsPageProps) {
  const system =
    account.systems.find(
      (system) =>
        system.id === selectedSystemId || system.id === lastSelectedSystemId
    ) || account.systems[0];
  const history = useHistory();
  const [systemId, setSystemId] = useState(system?.id);
  const systemUsers = account.users.filter((user) =>
    user.systems.find((userSystems) => userSystems.id === systemId)
  );
  const [openSnackBar, setOpenSnackBar] = useState('');
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState('');

  const classes = useSystemStyles();
  const dispatch = useDispatch();

  const updateSystemsStatusAppData: AppData<System[]> = useSelector(
    (state: { systemsState: SystemsState }) =>
      state.systemsState.updateSystemsStatus
  );

  const changePathname = (newSystemId: number) => {
    newSystemId &&
      history.push({
        pathname: `/accounts/${account.id}/system/${newSystemId}`,
      });
  };
  const systemChange = useCallback(
    (newSystemId) => {
      setSystemId(newSystemId);
      changePathname(newSystemId);
    },
    [setSystemId, changePathname]
  );

  if (!selectedSystemId) {
    changePathname(system?.id);
  }

  const isSystemDeleted = useMemo(() => {
    return system?.statusDetails?.status === 'pendingForDelete';
  }, [selectedSystemId, systemId, account, systemChange]);

  const changeSystemStatus = useCallback(
    (systems, status, canBeRestored, actionType, actionTypeDetails) => {
      dispatch(
        updateSystemsStatus(
          systems,
          status,
          canBeRestored,
          actionType,
          actionTypeDetails
        )
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (updateSystemsStatusAppData.data.length > 0) {
      const message = isSystemDeleted
        ? 'System was successfully restored'
        : 'System was successfully deleted';
      setOpenSnackBar(message);
      dispatch(getAccountById(account.id, true));
      dispatch(systemsSlice.actions.cleanupUpdateSystemsStatusStart());
    } else if (updateSystemsStatusAppData.error) {
      setOpenErrorSnackBar(updateSystemsStatusAppData.error);
      dispatch(systemsSlice.actions.cleanupUpdateSystemsStatusStart());
    }
  }, [updateSystemsStatusAppData]);

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackBar('');
    setOpenErrorSnackBar('');
  };

  return (
    <div className={classes.systemDetails}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '24px',
        }}
      >
        <TitleTab>System Details</TitleTab>
        <SubTitleTab>Systems connected to Account</SubTitleTab>
      </div>
      {system ? (
        <Box style={{ display: 'flex' }}>
          <DropdownSelectionMenuWithSearch
            systemOptions={account.systems}
            defaultSelectedSystemId={selectedSystemId}
            onChange={systemChange}
          />

          <WMButton
            style={{ margin: '0 0 0 10px' }}
            isIconButton
            variant={WMButtonVariant.Secondary}
            iconComponent={isSystemDeleted ? <WMIconUndo /> : <WMIconDelete />}
            tooltipProps={{
              title: isSystemDeleted ? 'Restore System' : 'Delete System',
              placement: 'top',
            }}
            loading={updateSystemsStatusAppData?.loading}
            onClick={() =>
              changeSystemStatus(
                [system.guid],
                isSystemDeleted
                  ? SubscriptionStatusType.enabled
                  : SubscriptionStatusType.pendingForDelete,
                false,
                SubscriptionStatusActionType.backoffice,
                ActionTypeDetails.admin
              )
            }
          ></WMButton>
        </Box>
      ) : null}

      {system ? (
        <StyledTabs
          onChange={(tabItem) => {
            if (tabItem.value === 'runtime') {
              window.open(
                `${
                  window.clientConfig.NX_EUX_BACKOFFICE_UI_URL
                }/accountConfiguration/accountInfo/${encodeURIComponent(
                  system?.email
                )}`,
                '_blank'
              );
            }
          }}
          items={[
            {
              content: (
                <SystemInfoPage
                  accountId={account.id}
                  systemUsers={systemUsers}
                  system={account.systems.find(
                    (system) => system.id === systemId
                  )}
                />
              ),
              label: 'System info',
              value: 'info',
            },
            {
              content: (
                <AccountUsersPage
                  users={systemUsers}
                  accountId={account.id}
                  hideTitle
                />
              ),
              label: 'Assigned users',
              value: 'users',
            },
            {
              content: <div />,
              label: 'Runtime configuration',
              value: 'runtime',
            },
          ]}
        />
      ) : null}

      <WMSnackbar
        open={!!openErrorSnackBar}
        onClose={handleSnackBarClose}
        variant={WMSnackbarVariant.Warning}
        message={openErrorSnackBar}
      />

      <WMSnackbar
        open={!!openSnackBar}
        onClose={handleSnackBarClose}
        variant={WMSnackbarVariant.Success}
        message={openSnackBar}
      />
    </div>
  );
}
export default SystemsPage;
