import { WMStatusDotVariant } from '@walkme/wm-ui';

export interface StatusMap {
  id: string;
  display: string;
  info: string;
  color: WMStatusDotVariant;
}

export const StatusMapData: StatusMap[] = [
  {
    id: 'STAGED',
    display: 'Staged',
    info: 'New users created through the API and not activated yet',
    color: WMStatusDotVariant.Draft,
  },
  {
    id: 'PROVISIONED',
    display: 'Pending user activation',
    info: 'Admin manually activate the user account but the user has not confirmed their e-mail address yet',
    color: WMStatusDotVariant.Draft,
  },
  {
    id: 'ACTIVE',
    display: 'Active',
    info: 'Active',
    color: WMStatusDotVariant.Success,
  },
  {
    id: 'RECOVERY',
    display: 'Recovery',
    info: 'An Admin intiated force password reset. User will not be able to use or request to reset their old password.',
    color: WMStatusDotVariant.Draft,
  },
  {
    id: 'LOCKED_OUT',
    display: 'Locked Out',
    info: 'User exceeds the number of login attempts defined in the login policy',
    color: WMStatusDotVariant.Failure,
  },
  {
    id: 'PASSWORD_EXPIRED',
    display: 'Password Expired',
    info: 'User password is expired',
    color: WMStatusDotVariant.Draft,
  },
  {
    id: 'SUSPENDED',
    display: 'Suspended',
    info: 'User cannot access applications',
    color: WMStatusDotVariant.Failure,
  },
  {
    id: 'DEPROVISIONED',
    display: 'Deprovisioned',
    info: 'User deactivated',
    color: WMStatusDotVariant.Draft,
  },
];
