import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  firstTextLabel: {
    color: '#2f426c',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '2px',
  },
  secondTextLabel: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '16px',
    color: '#8D97AE',
  },
  notSelectedItem: {
    border: '1px solid #e6ecf8',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '32%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(212, 223, 245, 0.5)',
    },
  },
  selectedItem: {
    border: '1px solid #3B61EB',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '32%',
    display: 'flex',
    alignItems: 'center',
  },
  circleInitial: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: '#ECEFFA',
    borderRadius: '8px',
    width: '36px',
    height: '36px',
    marginRight: '9px',
    marginLeft: '14px',
    textAlign: 'center',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
}));

interface SearchItemProps {
  firstText: string;
  secondText: string;
  thirdText: string;
  isSelected: boolean;
  circleInitials: string;
  onClickedItem: (item: any) => void;
}

function SearchItem({
  onClickedItem,
  circleInitials,
  firstText,
  secondText,
  thirdText,
  isSelected,
}: SearchItemProps) {
  const classes = useStyles();
  return (
    <div
      className={isSelected ? classes.selectedItem : classes.notSelectedItem}
      onClick={(item) => {
        onClickedItem(item);
      }}
    >
      <div className={classes.circleInitial}>{circleInitials}</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
        }}
      >
        <label className={classes.firstTextLabel}>{firstText}</label>
        <label className={classes.secondTextLabel}>{secondText}</label>
        <label className={classes.secondTextLabel}>{thirdText}</label>
      </div>
    </div>
  );
}

export default SearchItem;
