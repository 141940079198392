import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FeatureTogglesState,
  getFeatureFlagHistory,
} from '../redux/feature-toggles.slice';

export const useFeatureFlagHistory = (
  name: string,
  ownerTeam: string,
  forceLoad = false
) => {
  const featureFlagHistoryAppData = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.featureFlagHistory
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeatureFlagHistory(name, ownerTeam));
  }, [dispatch]);

  return featureFlagHistoryAppData?.data;
};

export default useFeatureFlagHistory;
