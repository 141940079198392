import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';
import {
  ProcessDetails,
  ProcessSource,
  ProcessTarget,
} from '@wm/copy-account-types';

export enum processStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  DONE = 'done',
  FAILED = 'failed',
}

export interface EventError {
  message: string;
  stack: string;
}

export interface WorkflowEvent {
  type: string;
  status: processStatus;
  errors?: EventError[];
  childProcesses?: Process[];
}

export class WorkflowTask {
  description: string;
  events: string[];
  eventsDetails?: WorkflowEvent[];
  status: string;
  errors: string[];
}

export interface Process {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  actionType: string;
  status?: processStatus;
  details: ProcessDetails;
  workflowId?: string;
  workflowStatus?: string;
  transactionId?: string;
  tasks?: WorkflowTask[];
}

export type ProcessesByAccounts = Record<number, Process[]>;
export interface ProcessesByAccountResponse {
  processes: ProcessesByAccounts;
}

export interface ImportTargetProcess {
  exportLink: string;
  target: ProcessTarget;
}

export class ImportFromSameEnvError extends Error {}

class CopyAccountApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  async startCopyAccount(
    source: ProcessSource,
    target: ProcessTarget
  ): Promise<Process> {
    try {
      const response = await this.httpClient.instance.post(
        '/accounts-copy-account/start/copy-account-data',
        {
          source,
          target,
          env: window.clientConfig.NX_WM_NODE_ENV,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to copy account: ${e.message}`);
    }
  }

  async getProcessByAccounts(accountIds): Promise<ProcessesByAccountResponse> {
    try {
      const queryString = accountIds
        .map((id) => 'accountIds[]=' + id)
        .join('&');
      const response = await this.httpClient.instance.get(
        '/accounts-copy-account/process/by-accounts?' + queryString
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to copy account: ${e.message}`);
    }
  }

  async getProcessStatusById(processId: string): Promise<Process> {
    try {
      const response = await this.httpClient.instance.get(
        `/accounts-copy-account/process/status/${processId}`
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to fetch process status: ${e.message}`);
    }
  }

  async startExportCopyAccount(source: ProcessSource): Promise<Process> {
    try {
      const response = await this.httpClient.instance.post(
        '/accounts-copy-account/start/export-account-data',
        {
          source,
          env: window.clientConfig.NX_WM_NODE_ENV,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to copy account: ${e.message}`);
    }
  }

  async startCopySpecific(
    source: ProcessSource,
    target: ProcessTarget
  ): Promise<Process> {
    try {
      const response = await this.httpClient.instance.post(
        '/accounts-copy-account/start/copy-system-content',
        {
          source,
          target,
          env: window.clientConfig.NX_WM_NODE_ENV,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to copy account: ${e.message}`);
    }
  }

  async startExportCopySpecific(source: ProcessSource): Promise<Process> {
    try {
      const response = await this.httpClient.instance.post(
        '/accounts-copy-account/start/export-system-content',
        {
          source,
          env: window.clientConfig.NX_WM_NODE_ENV,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to copy account: ${e.message}`);
    }
  }

  async getExportLink(processId: string): Promise<string> {
    try {
      const response = await this.httpClient.instance.get(
        `/accounts-copy-account/process/exportLink/${processId}`
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to fetch process status: ${e.message}`);
    }
  }

  async getImportDetails(processURL: string): Promise<Process> {
    let exportProcess;
    try {
      const response = await this.httpClient.instance.get(
        `/accounts-copy-account/process/getProcessFromLink?processLink=${encodeURIComponent(
          processURL
        )}`
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      exportProcess = response.data;
    } catch (e) {
      throw new Error(`failed to fetch process status: ${e.message}`);
    }

    if (exportProcess.details.env === window.clientConfig.NX_WM_NODE_ENV) {
      throw new ImportFromSameEnvError(
        'Cannot import data that was exported from the same environment.'
      );
    }

    return exportProcess;
  }

  async startImportCopyAccount({
    exportLink,
    target,
  }: ImportTargetProcess): Promise<Process> {
    try {
      const response = await this.httpClient.instance.post(
        '/accounts-copy-account/start/import-remote-account-data',
        {
          exportLink,
          target,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to copy account: ${e.message}`);
    }
  }
  async startImportSpecificCopyAccount({
    exportLink,
    target,
  }: ImportTargetProcess): Promise<Process> {
    try {
      const response = await this.httpClient.instance.post(
        '/accounts-copy-account/start/import-remote-system-content',
        {
          exportLink,
          target,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to copy account: ${e.message}`);
    }
  }
}

export const copyAccountApi = new CopyAccountApi(wmhttpClientSharedInstance);
