import { Typography } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Spinner,
  ToasterVariant,
  useToaster,
} from '@walkme/ui-core';
import { WMIconWarning } from '@walkme/wm-ui';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AccountsSdk, User } from 'wm-accounts-sdk';

export interface UserResetPasswordProps {
  user: User;
  accountId: number;
  onClose: () => void;
  isOpen: boolean;
}

export function UserResetPassword({
  user,
  accountId,
  isOpen,
  onClose,
}: UserResetPasswordProps) {
  const dispatch = useDispatch();
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const { addToaster } = useToaster();

  const onResetPassword = useCallback(async () => {
    try {
      setUpdatingPassword(true);
      await AccountsSdk.getInstance().backofficeUsers.resetPassword(
        accountId,
        user.id
      );
      addToaster({
        message: `reset email to ${user.email} was successfully sent.`,
        variant: ToasterVariant.Success,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
      setUpdatingPassword(false);
      onClose();
    } catch (e) {
      setUpdatingPassword(false);
      addToaster({
        message: `reset email to ${user.email} was falied to sent. ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [user, accountId, setUpdatingPassword, dispatch, onClose]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogTitle>Send reset password email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will intiated force password reset by email for{' '}
          <b>{user?.email}</b>
        </DialogContentText>
        <DialogContentText>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 16px',
              marginBottom: '10px',
              marginTop: '10px',
              backgroundColor: '#FEF8E8',
              border: `1px solid #F7B500`,
              borderRadius: '4px',
            }}
          >
            <WMIconWarning
              color={'#F7B500'}
              size={20}
              style={{ flexShrink: 0, paddingRight: '12px' }}
            />
            <Typography>
              The user will not be able to use or request to reset their old
              password.
            </Typography>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={updatingPassword}
          onClick={onResetPassword}
          variant="solid"
          color="danger"
        >
          {updatingPassword ? <Spinner /> : 'Send'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default UserResetPassword;
