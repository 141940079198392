import React, { ReactElement } from 'react';
import { StyledMenu, StyledMenuItem, StyledMenuTitle } from './styles/styles';
import SubMenuItem from './sub-menu-item';
import { renderMenuItem } from './utils/utils';
import { MenuProps } from './types/types';

export const CustomizedMenu = ({
  anchor,
  items,
  customClass,
  open,
  onClose,
  currentSelected,
  subSide,
  currentSelectedInSub,
  onSubOpen,
  subOpen,
  title,
}: MenuProps): ReactElement => {
  return (
    <StyledMenu
      id="simple-menu"
      anchorEl={anchor}
      open={open}
      className={customClass}
      onClick={onClose}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      keepMounted
    >
      {title ? <StyledMenuTitle>{title}</StyledMenuTitle> : null}
      {items.map((item, i) => {
        if (item) {
          if (item.subMenuItems) {
            return (
              <SubMenuItem
                items={item.subMenuItems}
                title={item.text}
                key={item.text}
                subSide={subSide}
                icon={item.icon}
                currentSelectedInSub={currentSelectedInSub}
                onSubOpen={onSubOpen}
                subOpen={subOpen}
              />
            );
          }
          return (
            <StyledMenuItem
              key={i}
              onClick={item.onClick}
              className={!item.onClick && !item.href && 'no-action'}
            >
              {renderMenuItem(item, currentSelected)}
            </StyledMenuItem>
          );
        }
        return null;
      })}
    </StyledMenu>
  );
};

export default CustomizedMenu;
