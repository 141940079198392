import { WMLoader } from '@walkme/wm-ui';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  loginTestMethod,
  LoginTestMethod,
} from '../../utils/testing-login-utils';
import { testingLoginDoneRoute } from '../../utils/testing-login-utils';

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoadingPage = () => {
  let popupwindow: Window;

  useEffect(() => {
    if (loginTestMethod === LoginTestMethod.POPUP_AND_REDIRECT) {
      window.addEventListener('message', function (event) {
        if (event.data === 'wm_auth_identify_me') {
          popupwindow = event.source as Window;
        }
      });
    }
  }, []);

  return (
    <Container>
      <WMLoader style={{ width: '30%', maxWidth: '300px' }}></WMLoader>
      {loginTestMethod === LoginTestMethod.POPUP_AND_REDIRECT && (
        <button
          onClick={() => {
            popupwindow?.close();
            window.open(
              `${window.location.href}${testingLoginDoneRoute}`,
              '_blank'
            );
          }}
        >
          click here if login window is not displayed
        </button>
      )}
    </Container>
  );
};

export default LoadingPage;
