import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  titleLabel: {
    fontFamily: 'Proxima Nova',
    fontDtyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2F426C',
    marginBottom: '20px',
  },
  SeconderyLabel: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2F426C',
  },
  SeconderyContent: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2F426C',
  },
  minTitle: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2F426C',
    marginRight: '12px',
  },
  boxDiv: {
    padding: '16px 24px',
    gap: '12px',
    border: '1px solid #F1F5FB',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  tagList: {
    padding: '0 !important',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
  },
  boxInfo: {
    padding: '16px 24px',
    gap: '12px',
    background: '#EDF1FD',
    border: '1px solid #385FEB',
    borderRadius: '4px',
    marginBottom: '20px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2F426C',
    display: 'flex',
    alignItems: 'center',
  },
  tagChip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 8px',
    gap: '6px',
    height: '20px',
    background: '#ECEFFA',
    borderRadius: '99px',
  },
  labelChip: {
    width: '8px',
    height: '16px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#162A69',
  },
  infoColorIcon: {
    flex: 1,
    width: '23px',
  },
  infoText: {
    flex: 19,
  },
}));
