import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountState, getLoggedInUser } from '../redux/account.slice';
import get from 'lodash/get';

export const useLoggedInUser = () => {
  const loggedInUserAppData = useSelector(
    (state: { accountState: AccountState }) => state.accountState.loggedInUser
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLoggedInUser());
  }, [dispatch]);

  const accountFeatureEnabled = useCallback<(feature: string) => boolean>(
    (feature) => {
      const accountFeatures = get(loggedInUserAppData, [
        'data',
        'account',
        'features',
        'names',
      ]);
      return accountFeatures
        ? accountFeatures.split(' ').includes(feature)
        : false;
    },
    [loggedInUserAppData]
  );

  return {
    loggedInUserAppData,
    accountFeatureEnabled,
  };
};

export default useLoggedInUser;
