import React, { useState } from 'react';
import {
  StyledWebhooksManagementContainer,
  StyledWMDataGrid,
} from './webhooks-management.styles';
import { headCells } from './webhooks-management.lib';
import {
  useFetchWebHooks,
  WebHooksState,
} from './state-management-webhooks/src';
import { WMSnackbar } from '@walkme/wm-ui';
import { useDispatch, useSelector } from 'react-redux';
import { webHooksSlice } from './state-management-webhooks/src';
import { IconButton } from '@walkme/ui-core';
import styled from 'styled-components';
import WebhookView from './webhook-view';
import WebhookEventInvestigator from './webhook-event-investigator';
import { Copy, Envelop, Zoom, Settings, Eye } from '@walkme/ui-icons';

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const WebhooksManagement = () => {
  const { webHooks, webHookEventNames } = useFetchWebHooks();
  const currentSnackBarMessage = useSelector(
    (state: { webHooksState: WebHooksState }) =>
      state.webHooksState?.snackBarMessage
  );
  const dispatch = useDispatch();

  const handleSnackBarErrorClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(webHooksSlice.actions.cleanupWebHooksPageErrors());
  };

  const handleDetails = (data) => {
    setSelectedWebhook(data);
  };

  const handleInvestigate = (data) => {
    setShowInvestigation(data);
  };

  const [selectedWebhook, setSelectedWebhook] = useState(undefined);
  const [showInvestigating, setShowInvestigation] = useState(undefined);

  //add new item in the end of headCells array
  const actionCells = [
    {
      headerName: 'Actions',
      field: 'actions',
      minWidth: 50,
      maxWidth: 150,
      pinned: 'right',
      cellRendererFramework: (params) => {
        return (
          <ActionButtonsContainer>
            <IconButton onClick={() => handleDetails(params.data)}>
              <Envelop />
            </IconButton>
            <IconButton onClick={() => handleInvestigate(params.data)}>
              <Eye />
            </IconButton>
          </ActionButtonsContainer>
        );
      },
    },
  ];

  const cellsWIthActions = [...headCells, ...actionCells];

  const footerActions = () => {
    return [
      {
        onClick: (rows) => {
          // completeDelete(rows);
          console.log('deleting webhooks', rows);
        },
        title: 'Delete Features',
        icon: null,
      },
    ];
  };

  return (
    <StyledWebhooksManagementContainer>
      <StyledWMDataGrid
        title={'Webhooks Explorer'}
        columnDefs={cellsWIthActions}
        rows={webHooks}
        entityName={'Webhooks Explorer'}
        supressResetOnNewData={true}
        hideExport
        footerActions={footerActions()}
        defaultPageSize={25}
        resizable={true}
      />
      <WebhookView
        selectedWebhook={selectedWebhook}
        onClose={() => setSelectedWebhook(undefined)}
      />
      {showInvestigating && (
        <WebhookEventInvestigator
          webhook={showInvestigating}
          onClose={() => setShowInvestigation(undefined)}
        />
      )}
      <WMSnackbar
        open={currentSnackBarMessage.isOpen}
        onClose={handleSnackBarErrorClose}
        variant={currentSnackBarMessage.variant}
        message={currentSnackBarMessage.text}
      />
    </StyledWebhooksManagementContainer>
  );
};
