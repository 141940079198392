import { SystemTypeKey } from 'wm-accounts-sdk';
import { wmEditorClientSharedInstance, WMhttpClient } from './wm-http-client';
import {
  DefaultSiteConfigOverride,
  SiteConfigOverrideFields,
  DefaultSiteConfigOverrideFields,
} from './types';

class MonolithApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  async getSiteConfigOverrides(
    accountId: number,
    systemTypeName: SystemTypeKey
  ): Promise<DefaultSiteConfigOverride> {
    const response =
      await this.httpClient.instance.post<DefaultSiteConfigOverride>(
        `Recorder/GetAccountPlatformDefaultSiteConfigOverridesV2`,
        {
          accountId,
          systemTypeName,
          fields: SiteConfigOverrideFields,
        }
      );
    return response.data;
  }

  async updateSiteConfigOverride(
    accountId: number,
    systemTypeName: SystemTypeKey,
    fieldName: string,
    value: string
  ): Promise<any> {
    const response = await this.httpClient.instance.post(
      'Recorder/UpdateSiteConfigOverride',
      {
        siteConfigOverrides: [
          {
            accountId,
            systemTypeName,
            fieldName,
            value,
          },
        ],
      }
    );

    return response.data;
  }
}

export const monolithApi = new MonolithApi(wmEditorClientSharedInstance);
