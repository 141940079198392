import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLoggedInUser } from '@wm-accounts-backoffice-center/state-management-users';
import { Credentials } from './credentials/credentials';
import { AppBar } from '@wm-accounts-backoffice-center/ui-components';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import { styledTheme } from '@wm-accounts-backoffice-center/shared-styles';
import { ImpersonationIndication } from 'wm-accounts-auth';

const {
  colors: { appBarFirstTextColor, appBarSecondTextColor },
} = styledTheme;

const StyledImpersonationIndication = styled.div`
  margin-right: 30px;
`;

const RowContainer = styled(Container)`
  flex-direction: row;
  flex-grow: 4;
  justify-content: center;
  padding-left: 0;
`;

const CenterDiv = styled.div`
  margin: 0 auto;
  display: flex;
  border-radius: 4px;
  padding: 2px;
  align-items: center;
`;

const StyledImage = styled.img`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const StyledIconText = styled.div`
  color: ${appBarFirstTextColor};
  margin-left: 5px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  font-family: 'Poppins';
`;

const StyledIconTextColor = styled.div`
  color: ${appBarSecondTextColor};
  margin-left: 5px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  font-family: 'Poppins';
`;

export const TopBar = () => {
  const { loggedInUserAppData } = useLoggedInUser();
  const dispatch = useDispatch();

  const onImpersonateClicked = useCallback(
    (stop: boolean) => {
      const { href } = window.location;
      const impersonationUrl = window.clientConfig.NX_IMPERSONATION_URL;
      window.location.replace(
        `${impersonationUrl}?action=${
          stop ? 'stop' : 'start'
        }&returnUrl=${href}`
      );
    },
    [loggedInUserAppData, dispatch]
  );

  const logo = (
    <RowContainer>
      <CenterDiv>
        <StyledImage src="/assets/bo_logo.svg" />
        <StyledIconText>{`WalkMe`}</StyledIconText>
        <StyledIconTextColor>{`Backoffice`}</StyledIconTextColor>
      </CenterDiv>
    </RowContainer>
  );
  const credentials = (
    <Credentials
      loading={loggedInUserAppData.loading}
      user={loggedInUserAppData.data}
    />
  );

  const leftMenuItems = [];

  const centerMenuItems = [
    <StyledImpersonationIndication>
      <ImpersonationIndication
        disabled={false}
        email={
          loggedInUserAppData.data && loggedInUserAppData.data.impersonator
            ? loggedInUserAppData.data.email
            : null
        }
        onStop={() => onImpersonateClicked(true)}
        onEdit={() => onImpersonateClicked(false)}
      />
    </StyledImpersonationIndication>,
  ];

  const rightMenuItems = [credentials];

  return (
    <AppBar
      rightMenuItems={rightMenuItems}
      leftMenuItems={leftMenuItems}
      centerMenuItems={centerMenuItems}
      logo={logo}
      apps={null}
    ></AppBar>
  );
};

export default TopBar;
