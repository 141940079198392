import React from 'react';
import {
  MergeAccountsActionResult,
  MergeAccountsActionStatus,
} from '../../api/merge-account-api.types';
import { System } from 'wm-accounts-sdk';
import {
  BoldLabel,
  SectionWrapperDiv,
  StyledStatus,
  SummaryError,
  SummaryHeader,
  SummaryItem,
  SummarySubHeader,
  SummarySubItem,
} from '../merge-summary.styles';

import { DoneColor, ErrorColor } from '@walkme/ui-icons';
import { StatusVariant } from '@walkme/ui-core';

export interface SystemsSummaryProps {
  systemsResult: MergeAccountsActionResult<Partial<System>[]>;
}

export const SystemsSummary = ({ systemsResult }: SystemsSummaryProps) => {
  if (!systemsResult) {
    return;
  }

  const hasError = systemsResult.status === MergeAccountsActionStatus.failure;
  const systems = (!hasError ? systemsResult.value : []) as Partial<System[]>;
  const sortedSystems = [...systems].sort((a, b) =>
    a.displayName < b.displayName ? -1 : 1
  );
  const error = systemsResult.value as Error;

  return (
    <SectionWrapperDiv>
      <SummaryHeader>
        <StyledStatus
          variant={!hasError ? StatusVariant.Success : StatusVariant.Error}
        />
        Merge Systems: {hasError ? 'Failed' : 'Completed Successfully'}
      </SummaryHeader>
      {hasError && <SummaryError>Error: {error.message}</SummaryError>}
      {!hasError && (
        <>
          <SummarySubHeader>Systems merged:</SummarySubHeader>
          {sortedSystems.length
            ? sortedSystems.map((system) => (
              <SummaryItem key={system.guid}>{system.displayName}</SummaryItem>
            ))
            : <SummaryItem>(None)</SummaryItem>
          }
        </>
      )}
    </SectionWrapperDiv>
  );
};
