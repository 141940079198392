import React from 'react';
import {
  SystemNameLabel,
  SystemSnippetEmailLabel,
  useStepStyles,
} from '../steps/wizard-steps.styles';
import { Tooltip } from '@walkme/ui-core';
import { UserCardItems } from './components.styles';

export interface UserCardProps {
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  isSelected: boolean;
  onClicked: (id: number) => void;
}

export const UserCard = ({ user, isSelected, onClicked }: UserCardProps) => {

  const classes = useStepStyles();

  return (
    <div
      className={isSelected ? classes.selectedUser : classes.notSelectedUser}
      onClick={(item) => onClicked?.(user?.id)}
    >
      <div className={classes.cardNameInitial}>{user?.firstName?.charAt(0)}</div>
      <UserCardItems>
        <SystemNameLabel>
          {`${user?.firstName} ${user?.lastName}`}
        </SystemNameLabel>
        <SystemSnippetEmailLabel>{user?.email}</SystemSnippetEmailLabel>
      </UserCardItems>
    </div >
  );
};
