import styled from 'styled-components';

export const StyledDataBuildingSettings = styled.div`
  .automatic-enable-row {
    padding: 0 8px;
    margin-top: 12px;
    display: flex;
    gap: 8px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2f426c;
  }
`;
export const StyledIdpSettings = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  padding: 22px 8px 22px;

  & span[class^='Tooltip'] {
    display: flex;
  }
`;
