import React, { useState } from 'react';
import { Button, Typography } from '@walkme/ui-core';
import { StyledChange } from './history-dialog.styles';
import { Avatar } from '@material-ui/core';

type Record = {
  date: string;
  time: string;
  user: string;
  text: string;
  diffs: any[];
  initial?: string;
};

export interface ChangeHistoryRecordProps {
  change: Record;
}

export const ChangeHistoryRecord = ({ change }: ChangeHistoryRecordProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onViewDetails = () => {
    setIsOpen(!isOpen);
  };

  let initialFeatureFlagDetails = change.initial
    ? change.initial.split('\n')
    : [];

  return (
    <StyledChange isOpen={isOpen}>
      <div className={'change-text-top'}>
        <Avatar>
          {change.user
            ?.split(' ')
            .map((word) => word.charAt(0))
            .join('')}
        </Avatar>
        <Typography variant={'T20'}>{change.text}</Typography>
      </div>
      <div className={'change-text-bottom'}>
        <Typography variant={'T30'}>{change.time}</Typography>
        <Button variant="text" onClick={onViewDetails}>
          {isOpen ? 'Hide details' : 'View details'}
        </Button>
      </div>
      <div className={'change-details'}>
        {change?.diffs?.map((diff) => {
          return (
            <div className={'change-details-textual'}>
              <Typography variant={'T30'}>{diff.description}</Typography>
            </div>
          );
        })}
        {change?.initial && (
          <div className={'change-details-textual'}>
            {initialFeatureFlagDetails.map((detail, index) => (
              <Typography className="pre-wrap" variant={'T30'} key={index}>
                {detail}
              </Typography>
            ))}
          </div>
        )}
      </div>
    </StyledChange>
  );
};
