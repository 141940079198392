import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Spinner,
  ToasterVariant,
  useToaster,
} from '@walkme/ui-core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AccountsSdk, System } from 'wm-accounts-sdk';
import { DropdownSelectionMenuNoSearch } from '../dropdown-aelection-menu-no-search';
import { InfoTitle, StyledInforamtion } from '../styles/styles';

export interface AccountSubscriptionProps {
  accountId: number;
  accountName: string;
}

export function AccountSubscriptionPage({
  accountId,
  accountName,
}: AccountSubscriptionProps) {
  const { addToaster } = useToaster();
  const [updatingSubscription, setUpdatingSubscription] = useState(true);
  const [isAccountSubscriptionEnabled, setIsAccountSubscriptionEnabled] =
    useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleClose = () => setIsDialogOpen(false);

  const items = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const onSubscriptionChange = useCallback(async () => {
    try {
      setUpdatingSubscription(true);
      await AccountsSdk.getInstance().subscrtiptionManagement.updateAccountSubscriptionStatus(
        accountId,
        isAccountSubscriptionEnabled ? 'disabled' : 'enabled'
      );
      const isAccountSubscriptionEnabledAfterUpdate =
        await AccountsSdk.getInstance().subscrtiptionManagement.isAccountSubscriptionEnabled(
          accountId
        );
      setIsAccountSubscriptionEnabled(
        isAccountSubscriptionEnabledAfterUpdate.enabled
      );
      setUpdatingSubscription(false);
      addToaster({
        message: `Successfully updated status`,
        variant: ToasterVariant.Success,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
      setIsDialogOpen(false);
    } catch (e) {
      setUpdatingSubscription(false);
      addToaster({
        message:
          e.response.status === 403
            ? `Insufficient role permissions - Please contact the security team`
            : `Failed to update status`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [accountId, isAccountSubscriptionEnabled]);

  const onGetSubscription = useCallback(async () => {
    try {
      setUpdatingSubscription(true);
      const isAccountSubscriptionEnabled =
        await AccountsSdk.getInstance().subscrtiptionManagement.isAccountSubscriptionEnabled(
          accountId
        );
      setIsAccountSubscriptionEnabled(isAccountSubscriptionEnabled.enabled);
      setUpdatingSubscription(false);
    } catch (e) {
      addToaster({
        message: `Failed to update status`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [accountId, setUpdatingSubscription]);

  useEffect(() => {
    onGetSubscription();
  }, [accountId]);

  return (
    <StyledInforamtion>
      <InfoTitle style={{ marginBottom: '5px' }}>Account Disabled</InfoTitle>
      {updatingSubscription ? (
        <Spinner />
      ) : (
        <DropdownSelectionMenuNoSearch
          items={items}
          selectedValue={!isAccountSubscriptionEnabled}
          onChange={(newStatus) => {
            if (newStatus === isAccountSubscriptionEnabled)
              setIsDialogOpen(true);
          }}
        />
      )}
      <Dialog isOpen={isDialogOpen} onClose={handleClose}>
        <DialogTitle>
          {isAccountSubscriptionEnabled
            ? 'Deactivate account'
            : 'Activate account'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {' '}
            You are about to{' '}
            {isAccountSubscriptionEnabled ? 'deactivate' : 'activate'}{' '}
            {accountName}.<br /> All Account users will
            {isAccountSubscriptionEnabled ? ' not' : ''} be able to Login.
            <br />
            {isAccountSubscriptionEnabled
              ? `Please note: There is no deletion of any data, logged in users will remain logged in until next session`
              : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={updatingSubscription}
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={updatingSubscription}
            onClick={onSubscriptionChange}
            variant="solid"
            color="danger"
          >
            {updatingSubscription ? (
              <Spinner />
            ) : isAccountSubscriptionEnabled ? (
              'Deactivate'
            ) : (
              'Activate'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledInforamtion>
  );
}

export default AccountSubscriptionPage;
