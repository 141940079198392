import React, { useMemo, useState } from 'react';
import { WMSnackbar, WMSnackbarVariant } from '@walkme/wm-ui';
import { SnackBarMessage } from '@wm-accounts-backoffice-center/wm-api';

const defaultSnackBarMessage: SnackBarMessage = {
  text: '',
  variant: WMSnackbarVariant.Success,
  isOpen: false,
};
const UseAiManagerSnackbar = () => {
  const [snackBarMessage, setSnackBarMessage] = useState<SnackBarMessage>(
    defaultSnackBarMessage
  );

  const showSnackBar = (message: SnackBarMessage) => {
    setSnackBarMessage({
      ...message,
      isOpen: true,
    });
  };

  const onClose = () => {
    setSnackBarMessage({ ...snackBarMessage, isOpen: false });
  };

  const aiSnackBar = useMemo(() => {
    const { isOpen, variant, text } = snackBarMessage;
    return (
      <WMSnackbar
        open={isOpen}
        variant={variant}
        message={text}
        onClose={onClose}
      />
    );
  }, [snackBarMessage]);

  return {
    showSnackBar,
    aiSnackBar,
  };
};

export default UseAiManagerSnackbar;
