import { Role, Roles } from 'wm-accounts-sdk';
import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';

class AccessControlApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  async isBackofficeAdmin(): Promise<boolean> {
    try {
      const response = await this.httpClient.instance.get(
        '/accesscontrol/userRole/isBackofficeAdmin'
      );

      return response.data;
    } catch (e) {
      return false;
    }
  }

  async getBackofficeRoles(): Promise<Roles> {
    const response = await this.httpClient.instance.post<Roles>(
      `/accesscontrol/roles/getBackOfficeRoles`
    );
    return response.data;
  }

  async assignRoleToUser(targetUserId: number, roleId: number): Promise<Role> {
    const response = await this.httpClient.instance.post<Role>(
      `/accesscontrol/userRole/assign`,
      {
        targetUserId,
        roleId,
      }
    );
    return response.data;
  }
}

export const accessControlApi = new AccessControlApi(
  wmhttpClientSharedInstance
);
