import React, { useState, MouseEvent, useRef } from 'react';
import { DropdownChevronDisplayOptions } from '@walkme/ui-core';
import {
  StyledDivider,
  useStyles,
  StyledSelector,
  StyledMenuItem,
  StyledWMMenu,
  StyledContainer,
} from './default-idp-vendor-selector.styles';
import { DropdownOption } from './default-idp-vendor-selector.lib';
import { Check } from '@walkme/ui-icons/large';

export interface DefaultIdpVendorProps {
  selectedDefaultIdpVendor: DropdownOption;
  idpVendors: DropdownOption[];
  setSelectedDefaultIdpVendor: (
    selectedDefaultIdpVendor: DropdownOption
  ) => void;
}

export function DefaultIdpVendorSelector({
  selectedDefaultIdpVendor,
  idpVendors,
  setSelectedDefaultIdpVendor,
}: DefaultIdpVendorProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dropdownRef = useRef<any>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => dropdownRef?.current.blur(), 100);
  };

  const handleChangeSelection = (selectedFormat: DropdownOption) => {
    setSelectedDefaultIdpVendor(selectedFormat);
    handleClose();
  };

  const SelectorDisplayedValue = (
    <div className={classes.expectedFormatSelectorDiv}>
      <span className={classes.uuidButtonText}>
        {selectedDefaultIdpVendor?.label}
      </span>
    </div>
  );

  const chevronDisplayOption = DropdownChevronDisplayOptions.DEFAULT;

  return (
    <StyledContainer>
      <StyledSelector
        handleOpen={handleOpen}
        displayValue={SelectorDisplayedValue}
        anchorEl={anchorEl}
        ref={dropdownRef}
        chevronDisplayOption={chevronDisplayOption}
        label={'Select default IDP vendor'}
      />
      <StyledWMMenu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <StyledDivider />
        <div>
          {idpVendors?.map((formatOption: DropdownOption, index) => {
            return (
              <StyledMenuItem
                id={formatOption.value}
                onClick={() => handleChangeSelection(formatOption)}
                isSelected={
                  formatOption.value === selectedDefaultIdpVendor?.value
                }
              >
                <span>{formatOption.label}</span>
                {formatOption.value === selectedDefaultIdpVendor?.value ? (
                  <Check color="#395eea" />
                ) : null}
              </StyledMenuItem>
            );
          })}
        </div>
      </StyledWMMenu>
    </StyledContainer>
  );
}
