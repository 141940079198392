import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../../merge-account.store';
import { resetSearchTargetAccount } from './steps/select-target-account/select-target-account.step.slice';
import { resetSearchSourceAccount } from './steps/select-source-account/select-source-account.step.slice';
import { resetExcludeSystems } from './steps/exclude-systems/exclude-systems.step.slice';
import { resetExcludeUsers } from './steps/exclude-users/exclude-users.step.slice';
import { resetReviewAndMerge } from './steps/review-and-merge/review-and-merge.step.slice';

export interface MergeAccountWizardState {
  isWizardVisible: boolean;
  canProceed: boolean;
  canMerge: boolean;
}

export const initialMergeAccountWizardState: MergeAccountWizardState = {
  isWizardVisible: false,
  canProceed: true,
  canMerge: false,
};

const mergeAccountWizardSlice = createSlice({
  name: 'wizard',
  initialState: initialMergeAccountWizardState,
  reducers: {
    showWizard(state: MergeAccountWizardState) {
      state.isWizardVisible = true;
    },
    hideWizard(state: MergeAccountWizardState) {
      state.isWizardVisible = false;
      state.canProceed = true;
      state.canMerge = false;
    },
    userCanProceed(state: MergeAccountWizardState) {
      state.canProceed = true;
    },
    userCannotProceeed(state: MergeAccountWizardState) {
      state.canProceed = false;
    },
    userCanMerge(state: MergeAccountWizardState) {
      state.canMerge = true;
    },
    userCannotMerge(state: MergeAccountWizardState) {
      state.canMerge = false;
    },
  },
});

export const {
  showWizard,
  hideWizard,
  userCanProceed,
  userCannotProceeed,
  userCanMerge,
  userCannotMerge,
} = mergeAccountWizardSlice.actions;

export const wizardClosed = (): AppThunk => async (dispatch) => {
  // Hide the dialog and reset all states
  dispatch(hideWizard());
  dispatch(resetSearchSourceAccount());
  dispatch(resetExcludeSystems());
  dispatch(resetExcludeUsers());
  dispatch(resetReviewAndMerge());
  dispatch(resetSearchTargetAccount());
};

export default mergeAccountWizardSlice.reducer;
