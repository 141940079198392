import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import { Checkbox } from '../checkbox/checkbox';
import {
  TableHeadCell,
  Order,
} from '@wm-accounts-backoffice-center/general-types';
import styled from 'styled-components';
import { WMCheckbox, WMIconRemove, WMIconSuccess } from '@walkme/wm-ui';
import { makeStyles } from '@material-ui/core/styles';

const StyledTableCell = styled(TableCell)`
  padding: 0 24px;
  cursor: pointer;
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6d81a6;
  border-bottom: 1px solid #f5f6f8;
`;

const StyledCheckboxCell = styled(TableCell)`
  font-size: 14px;
  max-width: 10px;
  width: 10px;
  cursor: pointer;
  padding: 0 24px;
  border-bottom: 1px solid #f5f6f8;
`;

const useStyles = makeStyles(() => ({
  checkbox: {
    '&:hover div': {
      borderColor: '#6C89F0',
    },
  },
  icon: {
    border: '1px solid #6D81A6',
    borderRadius: '3px',
    width: 12,
    height: 12,
  },
}));

interface TableHeadProps {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: unknown) => void;
  order?: Order;
  orderBy?: string;
  rowCount?: number;
  heads?: TableHeadCell[];
  rowsSelectable?: boolean;
  allRowsSelected?: boolean;
  rowsSelectableDisabled?: boolean;
  indeterminateSelectedRows?: boolean;
  onAllRowsToggled?: () => void;
}

export const Head = (props: TableHeadProps) => {
  const {
    order,
    orderBy,
    onRequestSort,
    heads,
    rowsSelectable,
    allRowsSelected,
    rowsSelectableDisabled,
    indeterminateSelectedRows,
    onAllRowsToggled,
  } = props;
  const createSortHandler =
    (property: unknown) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const classes = useStyles();
  const icon = <div className={classes.icon} />;
  const checkedIcon = <WMIconSuccess size={16} color={'#3B61EB'} />;

  return (
    <TableHead>
      <TableRow style={{ height: '40px' }}>
        {rowsSelectable && (
          <StyledCheckboxCell align="left">
            <Checkbox
              onChange={onAllRowsToggled}
              checked={allRowsSelected}
              indeterminate={indeterminateSelectedRows}
              disabled={rowsSelectableDisabled}
            />
          </StyledCheckboxCell>
        )}
        {heads &&
          heads.map((headCell) => {
            return (
              <StyledTableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  style={{
                    fontFamily: 'ProximaNova',
                    fontSize: '12px',
                    color: '#637191',
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span style={{ display: 'none' }}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
};

export default Head;
