import { Divider, Status } from '@walkme/ui-core';
import styled from 'styled-components';

export const SummaryWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100%;
`;

export const SummaryContentDiv = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: right;
  overflow-y: scroll;
  padding-right: 20px;
  height: 100%;
`;

export const SummaryHeader = styled('div')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  min-height: 24px;
  color: #2f426c;
`;

export const SummarySubHeader = styled('div')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2f426c;
  margin-left: 30px;
`;

export const SummaryError = styled('div')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 1px solid #ff0000;
  border-radius: 4px;
  color: #2f426c;
  margin-left: 15px;
  margin-right: 20px;
  margin-top: 10px;
`;

export const SummaryItem = styled('div')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #2f426c;
  margin-bottom: 0px;
  margin-left: 40px;
`;
export const SummarySubItem = styled('div')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2f426c;
  margin-bottom: 0px;
  font-style: italic;
  margin-left: 60px;
`;

export const SummaryDivider = styled(Divider)`
  margin: 16px 20px 16px 20px;
  border: 1px solid #f5f6f8;
`;

export const StyledStatus = styled(Status)`
  vertical-align: middle;
  margin-right: 9px;
  margin-bottom: 4px;
`;

export const AccountName = styled('span')`
  font-style: italic;
  font-weight: 400;
`;

export const SectionWrapperDiv = styled('div')`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const BoldLabel = styled('span')`
  font-weight: 500;
`;
