import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DialogActions, Step } from '@material-ui/core';
import { Button, IconButton } from '@walkme/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { wizardClosed } from './merge-account-wizard.slice';
import {
  DialogActionsContent,
  DialogTitleBottom,
  DialogTitleTop,
  DialogTitleVerticalContent,
  StyledCheck,
  StyledConnector,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDivider,
  StyledStepLabel,
  StyledStepper,
} from './merge-account-wizard.styles';
import WizardStepSelectSourceAccount from './steps/select-source-account/select-source-account.step';
import WizardStepExcludeSystems from './steps/exclude-systems/exclude-systems.step';
import WizardStepSelectTargetAccount from './steps/select-target-account/select-target-account.step';
import WizardStepReviewAndMerge from './steps/review-and-merge/review-and-merge.step';
import { MergeAccountState } from '../../merge-account.store';
import WizardStepExcludeUsers from './steps/exclude-users/exclude-users.step';
import { CustomStepIcon } from './components/custom-step-icon';
import { CloseSvg } from './assets/icons/close';
import {
  BackButton,
  CancelButton,
  MergeAccountButton,
  NextButton,
} from './components/buttons';
import {
  mergeRequestPendingChanged,
  showConfirmationDialog,
} from '../confirmation/confirmation-dialog.slice';

export interface IStepProps {
  stepTitle: JSX.Element | string;
  content: React.ReactNode;
  shouldSkip?: () => boolean;
}

const MergeAccountWizard = () => {
  const dispatch = useDispatch();

  const isDialogVisible = useSelector(
    (state: MergeAccountState) => state.wizard?.isWizardVisible || false
  );
  const canProceed = useSelector(
    (state: MergeAccountState) => state.wizard?.canProceed || false
  );

  const canMerge = useSelector(
    (state: MergeAccountState) => state.wizard?.canMerge || false
  );

  const isMergeRequestPending = useSelector(
    (state: MergeAccountState) =>
      state.mergeData?.mergeRequestStatus?.loading || false
  );

  // close the dialog when a merge request is sent
  useEffect(() => {
    dispatch(mergeRequestPendingChanged(isMergeRequestPending));
  }, [isMergeRequestPending]);

  const [activeStep, setActiveStep] = useState(0);

  const onMergeAccountClicked = useCallback(
    function onMergeAccountClicked() {
      dispatch(showConfirmationDialog());
    },
    [dispatch]
  );

  const onWizardClosed = useCallback(
    function onWizardClosed() {
      dispatch(wizardClosed());
    },
    [dispatch]
  );

  const onBackClicked = useCallback(
    function onBackClicked() {
      setActiveStep(activeStep - 1);
    },
    [activeStep]
  );

  const onNextClicked = useCallback(
    function onNextClicked() {
      setActiveStep(activeStep + 1);
    },
    [activeStep]
  );

  const wizzardSteps: IStepProps[] = [
    {
      stepTitle: 'Select Source Account',
      content: <WizardStepSelectSourceAccount />,
    },
    {
      stepTitle: 'Exclude Systems',
      content: <WizardStepExcludeSystems />,
    },
    {
      stepTitle: 'Exclude Users',
      content: <WizardStepExcludeUsers />,
    },
    {
      stepTitle: 'Select Target Account',
      content: <WizardStepSelectTargetAccount />,
    },
    {
      stepTitle: 'Review and Merge',
      content: <WizardStepReviewAndMerge />,
    },
  ];

  return (
    <StyledDialog open={isDialogVisible} onClose={onWizardClosed}>
      <StyledDialogTitle>
        <DialogTitleVerticalContent>
          <DialogTitleTop>
            <span>Merge Account</span>
            <IconButton onClick={onWizardClosed} variant="ghost">
              <CloseSvg />
            </IconButton>
          </DialogTitleTop>
          <StyledDivider />
          <DialogTitleBottom>
            <StyledStepper
              activeStep={activeStep}
              connector={<StyledConnector />}
            >
              {wizzardSteps.map((step, index) => {
                return (
                  <Step key={index}>
                    <StyledStepLabel StepIconComponent={CustomStepIcon}>
                      {step.stepTitle}
                    </StyledStepLabel>
                  </Step>
                );
              })}
            </StyledStepper>
          </DialogTitleBottom>
        </DialogTitleVerticalContent>
      </StyledDialogTitle>

      <StyledDialogContent>
        {wizzardSteps[activeStep].content}
      </StyledDialogContent>

      <DialogActions>
        <DialogActionsContent>
          <CancelButton onClick={onWizardClosed} />
          <BackButton onClick={onBackClicked} disabled={activeStep === 0} />
          {activeStep < wizzardSteps.length - 1 ? (
            <NextButton onClick={onNextClicked} disabled={!canProceed} />
          ) : (
            <MergeAccountButton
              onClick={onMergeAccountClicked}
              disabled={!canMerge}
            />
          )}
        </DialogActionsContent>
      </DialogActions>
    </StyledDialog>
  );
};

export default MergeAccountWizard;
