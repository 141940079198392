import React, { useCallback, useEffect, useState } from 'react';
import { SubTitleTab, TitleTab } from '../styles/styles';
import {
  InputSearch,
  Spinner,
  Tabs,
  Tooltip,
  Typography,
} from '@walkme/ui-core';
import DefaultSettingsSystemPage from './default-settings-systems';
import {
  Account,
  FeatureFlagTypeEntity,
} from '@wm-accounts-backoffice-center/wm-api';
import {
  useGetAllFeatures,
  useGetFeaturesTypes,
} from '@wm-accounts-backoffice-center/state-management-features';
import { DropdownSelectionMenuDefaultFeatures } from '../features/DropdownSelectionMenuDefaultFeatures';
import { useDispatch, useSelector } from 'react-redux';
import { SystemsState } from 'libs/state-management-systems/src';
import {
  DefaultFeaturesState,
  getAccountDefaultFeatures,
  getAccountMasterFeatures,
  getDefaultFeatures,
  getMasterFeatures,
} from '@wm-accounts-backoffice-center/state-management-default-features';
import DefaultSettingsSiteConfigPage from './default-settings-siteConfig';
import DefaultSettingsMasterFeaturePage from './default-settings-masterFeature';
import styled from 'styled-components';
import { DataBuildingSettings } from './data-building-settings/data-building-settings';
import { useLoggedInUser } from '@wm-accounts-backoffice-center/state-management-users';
import { WMSnackbar } from '@walkme/wm-ui';
import { defaultfeatureSlice } from '@wm-accounts-backoffice-center/state-management-default-features';

const StyledTabs = styled(Tabs)`
  height: calc(100% - 60px);
`;
export interface DefaultSettingsProps {
  account: Account;
}

export interface PlatformOption {
  value: string;
  label: string;
}

export function DefaultSettingsPage(pageProps: DefaultSettingsProps) {
  const { featureFlagTypesData } = useGetFeaturesTypes();
  const { allFeaturesAppData } = useGetAllFeatures();
  const dispatch = useDispatch();
  const { accountFeatureEnabled } = useLoggedInUser();
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const systemTypes = useSelector(
    (state: { systemsState: SystemsState }) => state.systemsState.systemTypes
  );
  const accountDefaultFeatures = useSelector(
    (state: { defaultFeatureState: DefaultFeaturesState }) =>
      state.defaultFeatureState.defaultFeaturesPerAccount
  );
  const currentSnackbarMessage = useSelector(
    (state: { defaultFeatureState: DefaultFeaturesState }) =>
      state.defaultFeatureState.notificationMessage
  );

  const options = systemTypes.data.map((type) => {
    return {
      value: type.key,
      label: type.displayName,
    };
  });

  const [selectedPlatform, setSelectedPlatform] = useState<PlatformOption>(
    options[0]
  );

  const getFeaturesForEntityType = (entityType: FeatureFlagTypeEntity) => {
    return allFeaturesAppData.data?.filter((ff) => {
      const ffTypeData = featureFlagTypesData.data?.find(
        (flagType) => flagType.value === ff.type
      );
      return !ffTypeData || ffTypeData.entities.includes(entityType);
    });
  };

  const onChangeOption = (newValue: PlatformOption) => {
    setSelectedPlatform(newValue);
  };

  const handleSnackBarClose = () => {
    dispatch(defaultfeatureSlice.actions.cleanUpNotificationMessage());
  };

  useEffect(() => {
    if (selectedPlatform?.value) {
      dispatch(
        getAccountDefaultFeatures(pageProps.account.id, selectedPlatform.value)
      );
      dispatch(getDefaultFeatures(selectedPlatform.value));
      dispatch(
        getAccountMasterFeatures(pageProps.account.id, selectedPlatform.value)
      );
    }
    dispatch(getMasterFeatures());
  }, [selectedPlatform]);

  useEffect(() => {
    setIsLoading(
      accountDefaultFeatures.loading ||
        allFeaturesAppData.loading ||
        featureFlagTypesData.loading
    );
  }, [accountDefaultFeatures, allFeaturesAppData, featureFlagTypesData]);

  const TabHeader = useCallback(() => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          height: '80px',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 8px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
            }}
          >
            <Typography variant="T20-2">Select platform</Typography>
            <Tooltip title="Select the type of systems for which you want to set the default features">
              <img
                alt={'more'}
                src={`/assets/icons/icon-info.svg`}
                style={{ position: 'relative', top: '1px' }}
              />
            </Tooltip>
            <div style={{ width: '236px', marginLeft: '20px' }}>
              <DropdownSelectionMenuDefaultFeatures
                systemTypeOptions={systemTypes.data}
                onChange={onChangeOption}
                currentSelection={selectedPlatform}
              />
            </div>
            {isLoading && <Spinner />}
          </div>
          <div
            style={{
              width: '280px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InputSearch onChange={(value: string) => setQuery(value)} />
          </div>
        </div>
      </div>
    );
  }, [selectedPlatform, query]);

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '12px',
        }}
      >
        <TitleTab>Default Settings</TitleTab>
        <SubTitleTab>
          All new systems created under this account will automatically include
          the default settings.{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://walkmekb.atlassian.net/wiki/spaces/WKB/pages/4215818/Default+Settings"
          >
            Learn more
          </a>
        </SubTitleTab>
      </div>
      <StyledTabs
        onChange={(tabItem) => setQuery('')}
        items={[
          {
            content: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '0px ',
                  height: 'calc(100% - 60px)',
                }}
              >
                {TabHeader()}
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: 'calc(100% - 60px) ',
                  }}
                >
                  <DefaultSettingsSystemPage
                    allFeatures={getFeaturesForEntityType(
                      FeatureFlagTypeEntity.system
                    )}
                    account={pageProps.account}
                    query={query}
                    selectedPlatform={selectedPlatform}
                    accountDefaultFeatures={accountDefaultFeatures}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            ),
            label: 'System Features',
            value: 'info',
          },
          {
            content: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '0px ',
                  height: 'calc(100% - 60px) ',
                }}
              >
                {TabHeader()}
                <DefaultSettingsSiteConfigPage
                  selectedPlatform={selectedPlatform}
                  account={pageProps.account}
                  query={query}
                />
              </div>
            ),
            label: 'Site Config',
            value: 'users',
          },
          {
            content: (
              <DefaultSettingsMasterFeaturePage
                query={query}
                account={pageProps.account}
                platform={selectedPlatform}
              />
            ),
            label: 'Configuration Bundles',
            value: 'runtime',
          },
          {
            content: <DataBuildingSettings />,
            label: 'Data & Building Settings',
            value: 'dataBuildingSettings',
          },
        ]}
      />
      <WMSnackbar
        open={currentSnackbarMessage.isOpen}
        onClose={handleSnackBarClose}
        variant={currentSnackbarMessage.variant}
        message={currentSnackbarMessage.text}
      />
    </div>
  );
}
export default DefaultSettingsPage;
