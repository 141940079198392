import React, { useCallback } from 'react';
import {
  DrawerContentWrapper,
  DrawerTitleWrapper,
  MergeSummaryTitle,
  StyledDrawer,
  SummmaryDrawerWrapper,
} from './merge-summary-drawer.styles';
import { MergeSummary } from '../../merge-summary/merge-summary';
import { useDispatch, useSelector } from 'react-redux';
import { MergeAccountState } from '../../merge-account.store';
import { drawerClosed } from '../merge-log.slice';

export const MergeSummaryDrawer = () => {
  const dispatch = useDispatch();

  const isDrawerOpen = useSelector(
    (state: MergeAccountState) => state.mergeLogs?.isDrawerOpen
  );
  const selectedMergeProc = useSelector(
    (state: MergeAccountState) => state.mergeLogs?.selectedMergeProc
  );

  const onDrawerClosed = useCallback(() => {
    dispatch(drawerClosed());
  }, [dispatch]);

  return (
    <StyledDrawer
      anchor={'right'}
      open={isDrawerOpen}
      onClose={onDrawerClosed}
      ModalProps={{
        onBackdropClick: onDrawerClosed,
      }}
      transitionDuration={400}
      title={'Merge Process Summary'}
    >
      <SummmaryDrawerWrapper>
        <DrawerTitleWrapper>
          <MergeSummaryTitle>Merge Process Summary</MergeSummaryTitle>
        </DrawerTitleWrapper>
        <DrawerContentWrapper>
          <MergeSummary mergeAccountResult={selectedMergeProc?.result} />
        </DrawerContentWrapper>
      </SummmaryDrawerWrapper>
    </StyledDrawer>
  );
};
