export const possibleChangedFields = [
  'description',
  'isEnabled',
  'status',
  'targetPopulation',
];

export const possibleTargetPopulationChangedFields = [
  'type',
  'policy',
  'entities',
];
