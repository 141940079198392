import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  WMButtonVariant,
  WMDataGrid,
  WMDialog,
  WMIconDelete,
  WMIconWarning,
  WMLoader,
  WMSnackbar,
  WMSnackbarVariant,
} from '@walkme/wm-ui';
import { Account, SubscriptionStatusType } from 'wm-accounts-sdk';
import { useSelector } from 'react-redux';
import {
  AccountState,
  useLoggedInUser,
} from '@wm-accounts-backoffice-center/state-management-users';
import NotBackofficeErrorMessage from '../not-backoffice-error';
import {
  accountsSubscriptionApi,
  SystemWithStatus,
} from '@wm-accounts-backoffice-center/wm-api';
import { Card, Title } from '../backoffice-admins-styles';
import { ColDef } from '@ag-grid-community/core';
import { getSystemsRows } from './systems-deletion-center';
import { useSystemDeletionCenterStyles } from './styles';

export interface AllSoftDeletedProps {
  pageData: {
    softDeletedSystems: SystemWithStatus[];
    headCells: ColDef[];
    setLoading: (loading: boolean) => void;
    fetchSystems: () => void;
  };
  loadingData: boolean;
}

export function AllSoftDeleted({ pageData, loadingData }: AllSoftDeletedProps) {
  const { headCells, setLoading, fetchSystems } = pageData;

  const classes = useSystemDeletionCenterStyles();

  const [softDeletedSystems, setSoftDeletedSystems] = useState<
    SystemWithStatus[]
  >(pageData.softDeletedSystems);
  const [systemsToDelete, setsystemsToDelete] = useState<SystemWithStatus[]>(
    []
  );
  const [systemsToRestore, setSystemsToRestore] = useState<SystemWithStatus[]>(
    []
  );
  const [showDeleteSystemsDialog, setShowDeleteSystemsDialog] =
    useState<boolean>(false);
  const [showRestoreSystemsDialog, setShowRestoreSystemsDialog] =
    useState<boolean>(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState<string>('');
  const [successSnackbarMessage, setSuccessSnackbarMessage] =
    useState<string>('');
  const [selectedRows, setSelectedRows] = useState([]);

  const { loggedInUserAppData } = useLoggedInUser();

  const LOGGED_IN_USER_ID = useMemo(
    () => loggedInUserAppData.data?.id,
    [loggedInUserAppData]
  );

  const backofficeRoles = useSelector(
    (state: { accountState: AccountState }) => {
      return state.accountState.backofficeRoles;
    }
  );

  const isBackofficeAdmin = useMemo(() => {
    return true; // change to     return backofficeRoles.data?.isBackofficeAdmin; after testings in prod
  }, [backofficeRoles]);

  const footerActions = [
    {
      onClick: (rows) => {
        handleRestoreSystemsClicked(rows);
      },
      icon: (
        <img
          src={`/assets/icons/restore-icon.svg`}
          width="20px"
          height="20px"
        />
      ),
      title: 'Restore Systems',
      className: classes.restoreButton,
    },
    {
      onClick: (rows) => {
        handleSetSystemsForDeletionClick(rows);
      },
      icon: <WMIconDelete />,
      title: 'Set Systems for Deletion',
      className: classes.setDeleteButton,
    },
  ];

  const handleSetSystemsForDeletionClick = (rowsToDelete) => {
    setsystemsToDelete(rowsToDelete);
    setShowDeleteSystemsDialog(true);
  };

  const handleRestoreSystemsClicked = (rowsToRestore) => {
    setSystemsToRestore(rowsToRestore);
    setShowRestoreSystemsDialog(true);
  };

  const handleStatusChange = async (statusType: SubscriptionStatusType) => {
    setLoading(true);
    try {
      const systemsData =
        statusType === SubscriptionStatusType.enabled
          ? systemsToRestore.map((system) => ({
              systemGuid: system.systemGuid,
              systemId: system.systemId,
            }))
          : systemsToDelete.map((system) => ({
              systemGuid: system.systemGuid,
              systemId: system.systemId,
            }));

      await accountsSubscriptionApi.updateSystemsStatus(
        systemsData,
        statusType,
        LOGGED_IN_USER_ID
      );
      await fetchSystems();
    } catch (err) {
      setErrorSnackbarMessage(
        err.message?.includes('403') ? err.message : 'Error deleting systems'
      );
    } finally {
      setShowDeleteSystemsDialog(false);
      setShowRestoreSystemsDialog(false);
      setLoading(false);
    }
  };

  const handleCancelClicked = () => {
    setShowDeleteSystemsDialog(false);
    setShowRestoreSystemsDialog(false);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const triggerDeleteSystemsDialog = useCallback(() => {
    const buttons = [
      {
        children: 'Cancel',
        variant: WMButtonVariant.Text,
        style: { marginRight: '30px', color: '#637191' },
        onClick: () => handleCancelClicked(),
      },
      {
        children: 'Delete',
        onClick: () =>
          handleStatusChange(SubscriptionStatusType.pendingForDelete),
        className: classes.deleteItemsButton,
        loading: loadingData,
      },
    ];
    const title =
      systemsToDelete.length === 1
        ? 'Set system for deletion'
        : `Set ${systemsToDelete.length} systems for deletion`;
    return (
      <WMDialog
        ds2
        disableHeaderDivider={true}
        open={showDeleteSystemsDialog}
        onClose={() => setShowDeleteSystemsDialog(false)}
        title={title}
        buttons={buttons}
      >
        <Typography>
          By clicking 'Delete', you will be adding the selected systems to the
          "Ready for deletion" Tab.
        </Typography>
        <Box className={classes.warningBox}>
          <WMIconWarning
            color={'#F7B500'}
            size={20}
            style={{ flexShrink: 0, paddingRight: '12px' }}
          />
          Please carefully review all the systems you have selected for
          deletion.
          <br />
        </Box>
      </WMDialog>
    );
  }, [showDeleteSystemsDialog, systemsToDelete]);

  const triggerRestoreSystemsDialog = useCallback(() => {
    const buttons = [
      {
        children: 'Cancel',
        variant: WMButtonVariant.Text,
        style: { marginRight: '30px', color: '#637191' },
        onClick: () => handleCancelClicked(),
      },
      {
        children: 'Restore',
        onClick: () => handleStatusChange(SubscriptionStatusType.enabled),
        className: classes.restoreItemsButton,
        loading: loadingData,
      },
    ];
    const title =
      systemsToRestore.length === 1
        ? 'Restore System'
        : `Restore ${systemsToRestore.length} Systems`;
    return (
      <WMDialog
        ds2
        disableHeaderDivider={true}
        open={showRestoreSystemsDialog}
        onClose={() => setShowRestoreSystemsDialog(false)}
        title={title}
        buttons={buttons}
      >
        <Typography>
          All of the WalkMe content and data collection will be restored
        </Typography>
        <Box className={classes.warningBox}>
          <WMIconWarning
            color={'#F7B500'}
            size={20}
            style={{ flexShrink: 0, paddingRight: '12px' }}
          />
          <Typography>
            {
              <span style={{ fontWeight: 600 }}>
                The original users of these systems will be restored
                <br />
              </span>
            }
            You can add or remove users from the Users page
          </Typography>
        </Box>
      </WMDialog>
    );
  }, [showRestoreSystemsDialog, systemsToRestore, loadingData]);

  const handleSnackBarErrorClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setErrorSnackbarMessage('');
    setSuccessSnackbarMessage('');
  };

  return (
    <div>
      {triggerDeleteSystemsDialog()}
      {triggerRestoreSystemsDialog()}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      ></div>
      {!isBackofficeAdmin ? (
        <NotBackofficeErrorMessage />
      ) : loadingData ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          This action can take up to 30 seconds
          <WMLoader style={{ width: '30%', maxWidth: '300px' }} />
        </div>
      ) : (
        <Box style={{ width: '100%', padding: 0 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div style={{ flex: 5, padding: '0 10px' }}>
              <Card style={{ marginTop: '10px' }}>
                <div style={{ height: '70vh' }}>
                  <WMDataGrid
                    columnDefs={headCells}
                    rows={getSystemsRows(softDeletedSystems)}
                    title={'All Soft Deleted Systems'}
                    headerDescription={
                      "All systems with status 'pending for delete'."
                    }
                    entityName={'System'}
                    footerActions={footerActions}
                    loadingData={loadingData}
                    gridConfig={{
                      defaultColDef: {
                        resizable: true,
                      },
                      onGridReady: onGridReady,
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
        </Box>
      )}

      <WMSnackbar
        open={!!errorSnackbarMessage || !!successSnackbarMessage}
        onClose={handleSnackBarErrorClose}
        variant={
          errorSnackbarMessage
            ? WMSnackbarVariant.Error
            : WMSnackbarVariant.Success
        }
        message={errorSnackbarMessage || successSnackbarMessage}
      />
    </div>
  );
}

export default AllSoftDeleted;
