import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AppData } from '@wm-accounts-backoffice-center/general-types';
import { euxApi } from '@wm-accounts-backoffice-center/wm-api';

export interface ExcludedEnvironmentsState {
  excludedEnvironments: AppData<number[]>;
  excludedEnvironmentsUpdate: AppData<number[]>;
  allExcludedEnvironmentsForSystem: AppData<{ [key: string]: number[] }>;
}

export const initialExcludedEnvironmentsState: ExcludedEnvironmentsState = {
  excludedEnvironments: {
    loading: false,
    error: null,
    data: [],
  },
  allExcludedEnvironmentsForSystem: {
    loading: false,
    error: null,
    data: {},
  },
  excludedEnvironmentsUpdate: {
    loading: false,
    error: null,
    data: null,
  },
};

const excludedEnvironmentsSlice = createSlice({
  name: 'excludedEnvironmentsSlice',
  initialState: initialExcludedEnvironmentsState,
  reducers: {
    allExcludedEnvironmentsForSystemStart(state: ExcludedEnvironmentsState) {
      state.allExcludedEnvironmentsForSystem.loading = true;
      state.allExcludedEnvironmentsForSystem.error = null;
      return state;
    },
    allExcludedEnvironmentsForSystemSuccess(
      state: ExcludedEnvironmentsState,
      action: PayloadAction<{ [key: string]: number[] }>
    ) {
      state.allExcludedEnvironmentsForSystem.data = action.payload;
      state.allExcludedEnvironmentsForSystem.loading = false;
      state.allExcludedEnvironmentsForSystem.error = null;
      return state;
    },
    allExcludedEnvironmentsForSystemFailed(
      state: ExcludedEnvironmentsState,
      action: PayloadAction<string>
    ) {
      state.allExcludedEnvironmentsForSystem.loading = false;
      state.allExcludedEnvironmentsForSystem.error = action.payload;
      return state;
    },
    excludedEnvironmentsStart(state: ExcludedEnvironmentsState) {
      state.excludedEnvironments.loading = true;
      state.excludedEnvironments.error = null;
      return state;
    },
    excludedEnvironmentsSuccess(
      state: ExcludedEnvironmentsState,
      action: PayloadAction<number[]>
    ) {
      state.excludedEnvironments.data = action.payload;
      state.excludedEnvironments.loading = false;
      state.excludedEnvironments.error = null;
      return state;
    },
    excludedEnvironmentsFailed(
      state: ExcludedEnvironmentsState,
      action: PayloadAction<string>
    ) {
      state.excludedEnvironments.loading = false;
      state.excludedEnvironments.error = action.payload;
      return state;
    },
    updateExcludedEnvironmentsStart(state: ExcludedEnvironmentsState) {
      state.excludedEnvironmentsUpdate.data = null;
      state.excludedEnvironmentsUpdate.loading = true;
      state.excludedEnvironmentsUpdate.error = null;
      return state;
    },
    updateExcludedEnvironmentsSuccess(
      state: ExcludedEnvironmentsState,
      action: PayloadAction<number[]>
    ) {
      state.excludedEnvironments.data = action.payload;
      state.excludedEnvironmentsUpdate.data = action.payload;
      state.excludedEnvironmentsUpdate.loading = false;
      state.excludedEnvironmentsUpdate.error = null;
      return state;
    },
    updateExcludedEnvironmentsFailed(
      state: ExcludedEnvironmentsState,
      action: PayloadAction<string>
    ) {
      state.excludedEnvironmentsUpdate.data = null;
      state.excludedEnvironmentsUpdate.loading = false;
      state.excludedEnvironmentsUpdate.error = action.payload;
      return state;
    },
    resetUpdateData: (state) => {
      state.excludedEnvironmentsUpdate =
        initialExcludedEnvironmentsState.excludedEnvironmentsUpdate;
    },
  },
});

export { excludedEnvironmentsSlice };

const {
  allExcludedEnvironmentsForSystemStart,
  allExcludedEnvironmentsForSystemSuccess,
  allExcludedEnvironmentsForSystemFailed,
  excludedEnvironmentsStart,
  excludedEnvironmentsSuccess,
  excludedEnvironmentsFailed,
  updateExcludedEnvironmentsStart,
  updateExcludedEnvironmentsSuccess,
  updateExcludedEnvironmentsFailed,
} = excludedEnvironmentsSlice.actions;

export type ExcludedEnvironmentsStateType = ReturnType<
  typeof excludedEnvironmentsSlice.reducer
>;

type ExcludedEnvsThunk = ThunkAction<
  void,
  ExcludedEnvironmentsStateType,
  unknown,
  Action<string>
>;

export const getAllExcludedEnvironmentsForSystem =
  (systemId: number): ExcludedEnvsThunk =>
  async (dispatch) => {
    try {
      dispatch(allExcludedEnvironmentsForSystemStart());
      const excludedEnvs = await euxApi.getAllExcludedFeaturesEnvsForSystem(
        systemId
      );
      dispatch(allExcludedEnvironmentsForSystemSuccess(excludedEnvs));
    } catch (err) {
      dispatch(allExcludedEnvironmentsForSystemFailed(err.message));
      return;
    }
  };

export const getExcludedEnvironments =
  (systemId: number, featureFlagName: string): ExcludedEnvsThunk =>
  async (dispatch) => {
    try {
      dispatch(excludedEnvironmentsStart());
      const excludedEnvs = await euxApi.getExcludedEnvs(
        systemId,
        featureFlagName
      );
      dispatch(excludedEnvironmentsSuccess(excludedEnvs));
    } catch (err) {
      dispatch(excludedEnvironmentsFailed(err.message));
      return;
    }
  };

export const updateExcludedEnvironments =
  (
    systemId: number,
    featureFlagName: string,
    excludedEnvs: number[]
  ): ExcludedEnvsThunk =>
  async (dispatch) => {
    try {
      dispatch(updateExcludedEnvironmentsStart());
      await euxApi.updateExcludedEnvs(systemId, featureFlagName, excludedEnvs);
      dispatch(updateExcludedEnvironmentsSuccess(excludedEnvs));
      dispatch(getAllExcludedEnvironmentsForSystem(systemId));
    } catch (err) {
      dispatch(updateExcludedEnvironmentsFailed(err.message));
      return;
    }
  };

export const { resetUpdateData } = excludedEnvironmentsSlice.actions;
