import { WMDivider } from '@walkme/wm-ui';
import {
  Check,
  ChevronDown,
  ChevronRight,
  Link,
  Loading,
} from '@walkme/ui-icons';
import { WMIconDuplicate, WMIconClose } from '@walkme/wm-ui';
import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { TaskView } from './task';
import moment from 'moment';
import {
  Button,
  Spinner,
  Status,
  StatusVariant,
  Tooltip,
} from '@walkme/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import {
  CopyAccountState,
  getExportLink,
} from '@wm-accounts-backoffice-center/state-management-copy-account';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { ModalError } from './modal-error';

const useStyles = makeStyles(() => ({
  wrapperDiv: {
    marginBottom: '16px',
    border: '1px solid #ECF1FA',
  },
  processDiv: {
    borderRadius: '4px',
    padding: '15px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  processLabel: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2F426C',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
  },
  processHeader: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2F426C',
    marginBottom: '4px',
    display: 'flex',
  },
  processTree: {
    borderTop: '1px solid #ECF1FA',
  },
  exportSpan: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3B61EB',
  },
  errorContainer: {
    border: '1px solid',
    borderRadius: '10px',
    padding: '10px',
    borderColor: 'lightGray',
    margin: '5px',
  },
  errorsModal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '20px',
  },
}));

const mappedStatus = [
  { name: 'in-progress', display: StatusVariant.Warning },
  { name: 'pending', display: StatusVariant.Warning },
  { name: 'failed', display: StatusVariant.Error },
  { name: 'done', display: StatusVariant.Success },
];

export const AccountProcessView = ({ process, currentAccount }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const statusMapped = mappedStatus.find(
    (status) => status.name === process.status
  );
  const [openProcess, setOpenProcess] = useState(false);
  const [clickedCopy, setClickedCopy] = useState(false);
  const [showErrorsModal, setShowErrorsModal] = useState(false);
  const [showErrorsCopied, setShowErrorsCopied] = useState(false);

  const exportLinkAppData = useSelector(
    (state: { copyAccountState: CopyAccountState }) =>
      state.copyAccountState.ExportLink
  );

  const copyToClipboard = (exportLink) => {
    setClickedCopy(true);
    navigator.clipboard.writeText(exportLink);
  };

  const exportLink = useCallback(() => {
    if (
      exportLinkAppData.data === '' ||
      !exportLinkAppData.data.includes(process.id)
    ) {
      dispatch(getExportLink(process?.id));
    } else {
      copyToClipboard(exportLinkAppData.data);
      setTimeout(() => {
        setClickedCopy(false);
      }, 3000);
    }
  }, [exportLinkAppData.data]);

  const isSpecific: boolean = process.actionType.includes('-system-content');
  const actionTypeText: string = isSpecific ? 'specific' : 'systems';

  return (
    <div className={classes.wrapperDiv}>
      <div
        className={classes.processDiv}
        onClick={() => setOpenProcess(!openProcess)}
        style={openProcess ? { background: '#F7F9FC' } : null}
      >
        <div>
          <div className={classes.processHeader}>
            {process.details.source
              ? process.details.target
                ? currentAccount.name === process.details.source.account.name
                  ? `Copy ${actionTypeText} to ${process.details.target.account.name}`
                  : `Copy ${actionTypeText} from ${process.details.source.account.name}`
                : `Export ${actionTypeText}`
              : `Import ${actionTypeText}`}
          </div>
          <div className={classes.processLabel}>Process Id: {process.id}</div>
          <div className={classes.processLabel} style={{ marginBottom: '4px' }}>
            Transaction Id: {process.transactionId}
          </div>
          <div className={classes.processLabel}>
            <Status variant={statusMapped.display} />
            <span>{process.status} </span>
            <WMDivider
              orientation="vertical"
              flexItem
              style={{ backgroundColor: '#C5CDDB' }}
            />
            <span>{moment(process.createdAt).format('MMM Do YY')}</span>
            <WMDivider
              orientation="vertical"
              flexItem
              style={{ backgroundColor: '#C5CDDB' }}
            />
            <span>{moment(process.createdAt).format('LT')}</span>
          </div>
        </div>
        {openProcess ? <ChevronDown /> : <ChevronRight />}
      </div>
      {openProcess && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px 16px 16px 24px',
            }}
          >
            <div
              style={{
                fontFamily: 'Proxima Nova',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '20px',
                color: '#2F426C',
              }}
            >
              Process Overview
            </div>
            {process.status === 'done' && !process.details.target && (
              <Button variant="outlined" onClick={() => exportLink()}>
                {exportLinkAppData.loading ? (
                  <Spinner />
                ) : exportLinkAppData.data === '' ||
                  !exportLinkAppData.data.includes(process.id) ? (
                  <Loading style={{ marginRight: '8px' }} />
                ) : clickedCopy ? (
                  <Check color="#385FEB" style={{ marginRight: '8px' }} />
                ) : (
                  <Link style={{ marginRight: '8px' }} />
                )}
                {exportLinkAppData.loading ? (
                  ''
                ) : exportLinkAppData.data === '' ||
                  !exportLinkAppData.data.includes(process.id) ? (
                  <span className={classes.exportSpan}>Generate link</span>
                ) : clickedCopy ? (
                  <span className={classes.exportSpan}>Copied</span>
                ) : (
                  <span className={classes.exportSpan}>Copy link</span>
                )}
              </Button>
            )}
            {process.errors && process.errors.length !== 0 && (
              <Button
                variant="outlined"
                disabled={showErrorsModal}
                onClick={() => {
                  setShowErrorsModal(true);
                }}
              >
                Show errors
              </Button>
            )}
          </div>
          <div className={classes.processTree}>
            {process.tasks.map((task, index) => {
              return (
                <TaskView task={task} key={index} isSpecific={isSpecific} />
              );
            })}
          </div>
        </>
      )}
      <Dialog open={showErrorsModal}>
        <div className={classes.errorsModal}>
          <DialogTitle style={{ paddingRight: '0px' }}>
            Errors for process {process.id}
          </DialogTitle>
          <Tooltip title="copied" open={showErrorsCopied}>
            <WMIconDuplicate
              color={'gray'}
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(process.errors));
                setShowErrorsCopied(true);
                setTimeout(() => {
                  setShowErrorsCopied(false);
                }, 1000);
              }}
              size={20}
            ></WMIconDuplicate>
          </Tooltip>
          <WMIconClose
            size={20}
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              setShowErrorsModal(false);
            }}
          ></WMIconClose>
        </div>
        <DialogContent>
          {process.errors?.map((error) => (
            <ModalError error={error}></ModalError>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
};
