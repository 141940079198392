import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';
import { AiSettings } from '@wm-accounts-backoffice-center/state-management-ai-manager';

const path = '/deepui/api/am';
// const path = '';

class AiManagerApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  async getAiSettings(accountId: string): Promise<AiSettings> {
    const response = await this.httpClient.instance.get<AiSettings>(
      `${path}/account/${accountId}/settings`
    );
    return response.data;
  }

  async addToAllowList(accountId: string): Promise<void> {
    await this.httpClient.instance.post<string[]>(`${path}/allowlist`, [
      accountId,
    ]);
  }

  async removeFromAllowList(accountId: string): Promise<void> {
    await this.httpClient.instance.delete<AiSettings>(`${path}/allowlist`, {
      data: [accountId],
    });
  }
}

export const aiManagerApi = new AiManagerApi(wmhttpClientSharedInstance);
