import React from 'react';
import UserSingleImg from '../assets/user-single.svg';
import { Tooltip } from '@walkme/ui-core';

export const InitiatorCell = (params) => {
  const initiator = params.data.initiator;

  if (!initiator) {
    return null;
  }

  const CellContent = () => (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <img
        alt={'profile image'}
        style={{ float: 'left', padding: '0 5px 0 0' }}
        src={UserSingleImg}
      />
      <span style={{ float: 'left' }}>{initiator}</span>
    </span>
  );

  if (initiator.length > 30) {
    return (<Tooltip title={initiator}>
      <CellContent />
    </Tooltip>)
  }

  return CellContent();
}
