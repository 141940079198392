import { Tab } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTab = styled(Tab)`
  margin-right: 16px;
  margin-bottom: 5px;
  min-height: 0;
  .MuiTab-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const TitleTab = styled('label')`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2f426c;
`;

export const SubTitleTab = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
`;

export const EmptyLabel = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
  margin-top: 32px;
`;

export const InfoTitle = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
`;
export const InfoDetail = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #637191;
`;

export const StyledInforamtion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledGridDiv = styled.div`
  grid-gap: 2rem;
  -webkit-padding-after: 3rem;
  border-radius: 0.5rem;
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  height: 100%;
  overflow-y: auto;
  padding-block-end: 3rem;
  margin-top: 1rem;
`;
