import {
  Button,
  IconButton,
  Input,
  InputVariant,
  Spinner,
  ToasterVariant,
  useToaster,
} from '@walkme/ui-core';
import { getAccountById } from '@wm-accounts-backoffice-center/state-management-users';
import { accountsGraphqlCaller } from '@wm-accounts-backoffice-center/wm-api';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Account } from 'wm-accounts-sdk';
import styled from 'styled-components';
import { WMIconEdit } from '@walkme/wm-ui';
import { Check, Close, Edit } from '@walkme/ui-icons';

export interface AccountEditableNameProps {
  account: Account;
}

const StyledInput = styled(Input)`
  & input {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }
`;

const TitleAccount = styled('label')`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2f426c;
`;

const AccountIcon = styled('div')`
  width: 24px;
  height: 24px;
  background: #015696;
  border-radius: 100px;
  color: white;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function AccountEditableName({ account }: AccountEditableNameProps) {
  const inputRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const [updatingName, setUpdatingName] = useState(false);
  const [editable, setEditable] = useState(false);
  const [updatedAccountName, setUpdatedAccountName] = useState(account.name);
  const { addToaster } = useToaster();

  const onNameChange = useCallback(async () => {
    try {
      setUpdatingName(true);
      await accountsGraphqlCaller.editAccount({
        accountName: updatedAccountName,
        accountId: account.id,
      });
      dispatch(getAccountById(account.id, true));
      setUpdatingName(false);
      setEditable(false);
      addToaster({
        message: `successfully updated account name`,
        variant: ToasterVariant.Success,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    } catch (e) {
      setUpdatingName(false);
      addToaster({
        message: `failed to update account name. ${e}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [account, updatedAccountName, setEditable, setUpdatingName, dispatch]);

  useEffect(() => {
    if (editable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editable]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2px',
      }}
    >
      <AccountIcon>{account.name?.charAt(0)}</AccountIcon>
      {!editable && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TitleAccount>{account.name}</TitleAccount>
          <IconButton
            active
            variant="ghost"
            onClick={() => {
              setEditable(true);
            }}
          >
            <Edit />
          </IconButton>
        </div>
      )}
      {editable && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <StyledInput
            ref={inputRef}
            onChange={(event) => {
              setUpdatedAccountName(event.target.value);
            }}
            disabled={updatingName}
            value={updatedAccountName}
            variant={InputVariant.primary}
            width={280}
          />
          <IconButton
            active
            variant="ghost"
            onClick={() => {
              onNameChange();
            }}
          >
            {updatingName ? <Spinner /> : <Check />}
          </IconButton>
          <IconButton
            color="danger"
            variant="ghost"
            onClick={() => {
              setEditable(false);
            }}
          >
            <Close />
          </IconButton>
        </div>
      )}
    </div>
  );
}
export default AccountEditableName;
