import React from 'react';
import {
  MergeAccountsActionResult,
  MergeAccountsActionStatus,
} from '../../api/merge-account-api.types';
import { Role } from 'wm-accounts-sdk';
import {
  BoldLabel,
  SectionWrapperDiv,
  StyledStatus,
  SummaryError,
  SummaryHeader,
  SummaryItem,
  SummarySubHeader,
  SummarySubItem,
} from '../merge-summary.styles';

import { StatusVariant } from '@walkme/ui-core';

export interface RolesSummaryProps {
  rolesResult: MergeAccountsActionResult<Partial<Role>[]>;
}

export const RolesSummary = ({ rolesResult }: RolesSummaryProps) => {
  if (!rolesResult) {
    return;
  }

  const hasError = rolesResult.status === MergeAccountsActionStatus.failure;
  const roles = (!hasError ? rolesResult.value : []) as Role[];
  const sortedRoles = [...roles].sort((a, b) =>
    a.displayName < b.displayName ? -1 : 1
  );
  const error = rolesResult.value as Error;

  return (
    <SectionWrapperDiv>
      <SummaryHeader>
        <StyledStatus
          variant={!hasError ? StatusVariant.Success : StatusVariant.Error}
        />
        Merge Roles:&nbsp;
        {hasError ? 'Failed' : 'Completed Successfully'}
      </SummaryHeader>
      {hasError && <SummaryError>Error: {error.message}</SummaryError>}
      {!hasError && (
        <>
          <SummarySubHeader>Roles merged:</SummarySubHeader>
          {
            sortedRoles.length
              ? sortedRoles.map((role) => (
                <SummaryItem key={role.id}>{role.displayName}</SummaryItem>
              ))
              : <SummaryItem>(None)</SummaryItem>
          }
        </>
      )}
    </SectionWrapperDiv>
  );
};
