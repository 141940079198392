export const getAdminSpaceBaseURL = () => {
  const currentHostname = window.location.hostname;
  const currentOrigin = window.location.origin;

  if (currentHostname === 'localhost') return 'https://admin.walkmedev.com';

  return currentOrigin.replace('backoffice', 'admin');
};

export const ADMIN_SPACE_BASE_URL = getAdminSpaceBaseURL();
export const generateSystemIconUrl = (systemName) => {
  return `${ADMIN_SPACE_BASE_URL}/assets/icons/icon-${systemName}.png`;
};

export function apiErrorFormatter(e: any) {
  function graphqlError(): { status: number; message: string } {
    if (!e.graphQLErrors || e.graphQLErrors.length === 0) {
      return { message: null, status: null };
    }
    const status =
      e.graphQLErrors[0].extensions?.response?.statusCode ||
      e.graphQLErrors[0].extensions?.exception?.statusCode;
    const message =
      e.graphQLErrors[0].extensions?.response?.message ||
      e.graphQLErrors[0].extensions?.exception?.message ||
      e.graphQLErrors[0].message;
    return {
      status,
      message,
    };
  }
  function httpError(): { status: number; message: string } {
    const message =
      e.response?.data?.message || e.message || 'Unspecified error';
    const status = e.response && e.response.status;
    return { status, message };
  }
  const graphqlErrorResponse = graphqlError();
  const httpErrorResponse = httpError();
  const message = graphqlErrorResponse.message || httpErrorResponse.message;
  const status = graphqlErrorResponse.status || httpErrorResponse.status;
  const forbiddenExplanation =
    status === 403 || (message && message.includes('Forbidden'))
      ? '(Missing permission / WalkMe VPN)'
      : '';
  return `${message} ${forbiddenExplanation}`;
}
