import {
  Chip,
  Spinner,
  ToasterVariant,
  Typography,
  useToaster,
} from '@walkme/ui-core';
import {
  getAccountById,
  hasPermission,
} from '@wm-accounts-backoffice-center/state-management-users';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountsSdk, System, Users } from 'wm-accounts-sdk';
import {
  InfoDetail,
  InfoTitle,
  StyledGridDiv,
  StyledInforamtion,
} from '../styles/styles';
import { DropdownSelectionMenuNoSearch } from '../dropdown-aelection-menu-no-search';
import {
  DeployablesState,
  flatDeployablesAndFolders,
  getSystemsDeployablesCount,
} from '@wm-accounts-backoffice-center/state-management-deployables';
import { makeStyles } from '@material-ui/core';
import { DeployablesTagList } from '@walkme/deployables-tag-list';
import moment from 'moment';
import SelfHostedSelectoPage from '../self-hosted/self-hosted-selector';
import KeepSaasAliveSelectoPage from '../keep-saas-alive/keep-saas-alive-selector';
import PoweredByLinkSelectorPage from '../powered-by-link/powered-by-link-selector';
import { PoweredByFields } from '../powered-by-link/powered-by-link.lib';
import { camelCase, capitalize } from 'lodash';
import SystemPurposeSelectoPage from '../system-purpose-selector/system-purpose-selector';

const useStyles = makeStyles(() => ({
  tagList: {
    padding: '0 !important',
    marginTop: '10px',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
  },
}));
export interface SystemInfoPageProps {
  system: System;
  systemUsers: Users;
  accountId: number;
}

const format = (text: string): string => {
  if (!text) return;
  return capitalize(camelCase(text));
};

const addNACondition = (text: string): string => {
  if (!text) return 'N/A';
  return text;
};

export function SystemInfoPage({
  accountId,
  system,
  systemUsers,
}: SystemInfoPageProps) {
  if (!system) return <div></div>;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [updatingLimitation, setUpdatingLimitation] = useState(false);
  const [isLimited, setIsLimited] = useState(system?.isLimited);
  const { addToaster } = useToaster();
  const currentSystem = system;
  const adminUsersOfSystems = systemUsers.filter((user) =>
    hasPermission(user.role, 'Management', 'Write')
  );

  const DeployablesTypesAppData = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.realDeployablesTypes
  );

  const flatDeployablesAppData = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.flatSystemDeployables
  );

  useMemo(() => {
    if (currentSystem) {
      dispatch(getSystemsDeployablesCount([currentSystem.id], false));
      dispatch(flatDeployablesAndFolders(currentSystem.id, -1));
    }
  }, [currentSystem]);

  const deployablesLabelsForTag = useCallback(() => {
    let deployables = DeployablesTypesAppData.data.map((type) => {
      return {
        counter: 0,
        name: type.value,
        displayType: type.displayType,
        id: type.type,
      };
    });
    flatDeployablesAppData.data?.map((flatDeployable) => {
      if (
        deployables.find(
          (deployable) => deployable.displayType === flatDeployable.displayType
        )
      )
        deployables.find(
          (deployable) => deployable.displayType === flatDeployable.displayType
        ).counter++;
    });
    return deployables
      .filter((label) => label.counter > 0)
      .map((deployable) => {
        return {
          name: deployable.name,
          displayType: deployable.displayType,
          counter: deployable.counter,
        };
      });
  }, [flatDeployablesAppData.data, DeployablesTypesAppData.data]);

  useEffect(() => {
    setIsLimited(system?.isLimited);
  }, [system]);

  const onLimitationChange = useCallback(
    async (limited: boolean) => {
      try {
        setUpdatingLimitation(true);
        await AccountsSdk.getInstance().backofficeAccountsSystems.updateSystemLimitation(
          system?.id,
          {
            IsLimited: limited,
            Wts: limited ? 3 : null,
            Steps: limited ? 3 : null,
          }
        );
        setIsLimited(limited);
        dispatch(getAccountById(accountId, true));
        setUpdatingLimitation(false);
        addToaster({
          message: `successfully updated system limitation`,
          variant: ToasterVariant.Success,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      } catch (e) {
        setUpdatingLimitation(false);
        addToaster({
          message: `failed to update system limitation. ${e}`,
          variant: ToasterVariant.Error,
          width: 'content',
          autoDismiss: true,
          distance: '60',
        });
      }
    },
    [accountId, system, setUpdatingLimitation, dispatch]
  );

  const items = [
    {
      label: 'Limited',
      value: true,
    },
    {
      label: 'Unlimited',
      value: false,
    },
  ];

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <div
        style={{
          marginTop: '30px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={currentSystem.settings?.icons?.highRes}
          width="16px"
          height="16px"
        />
        <span
          style={{
            fontFamily: 'Proxima Nova',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: ' #2F426C',
            marginLeft: '10px',
          }}
        >
          {`${currentSystem.displayName} ${
            currentSystem.settings?.displayName !== currentSystem.displayName
              ? `(${currentSystem.settings?.displayName} App)`
              : ''
          }`}
        </span>
      </div>
      <StyledGridDiv>
        <StyledInforamtion>
          <InfoTitle>System ID</InfoTitle>
          <InfoDetail>{currentSystem.id}</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>System snippet email</InfoTitle>
          <InfoDetail>{currentSystem.email}</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>System GUID</InfoTitle>
          <InfoDetail>{currentSystem.guid}</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>System Platform</InfoTitle>
          <InfoDetail>{currentSystem.systemTypeKey}</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>System Creation Date</InfoTitle>
          <InfoDetail>
            {moment(system.creationDate).format('dddd MMMM Do YYYY, h:mm:ss a')}
          </InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>System Status</InfoTitle>
          <InfoDetail>
            {currentSystem.statusDetails?.status
              ? `${currentSystem.statusDetails?.status} (${
                  currentSystem.statusDetails?.actionTypeDetails
                } ${
                  currentSystem.statusDetails?.deletionAt
                    ? `at ${currentSystem.statusDetails?.deletionAt}`
                    : ''
                })`
              : 'Active'}
          </InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>System Admin</InfoTitle>
          {adminUsersOfSystems.length > 0 ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <InfoDetail>{adminUsersOfSystems[0].email}</InfoDetail>
              {adminUsersOfSystems.length > 1 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '4px 8px',
                    gap: '4px',
                    background: '#ECEFFA',
                    borderRadius: '4px',
                    justifyContent: 'center',
                    color: '#637191',
                  }}
                >{`+${adminUsersOfSystems.length - 1}`}</div>
              )}
            </div>
          ) : (
            <InfoDetail>N/A</InfoDetail>
          )}
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>Creation Source</InfoTitle>
          <InfoDetail>
            {addNACondition(format(currentSystem.creationSource))}
          </InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>Created By</InfoTitle>
          <InfoDetail>
            {addNACondition(currentSystem?.createdBy?.userEmail)}
          </InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>Impersonated To</InfoTitle>
          <InfoDetail>
            {addNACondition(currentSystem?.createdBy?.impUserEmail)}
          </InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>Usage</InfoTitle>
          <InfoDetail>{addNACondition(format(currentSystem.usage))}</InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle>Purpose</InfoTitle>
          <InfoDetail>
            {addNACondition(format(currentSystem.purpose))}
          </InfoDetail>
        </StyledInforamtion>
        <StyledInforamtion>
          <InfoTitle style={{ marginBottom: '5px' }}>System Type</InfoTitle>
          {updatingLimitation ? (
            <Spinner></Spinner>
          ) : (
            <DropdownSelectionMenuNoSearch
              items={items}
              selectedValue={isLimited}
              onChange={(newStatus) => onLimitationChange(newStatus)}
            />
          )}
        </StyledInforamtion>
        <SelfHostedSelectoPage system={system} accountId={accountId} />
        <KeepSaasAliveSelectoPage system={system} accountId={accountId} />
        <SystemPurposeSelectoPage system={system} accountId={accountId} />
        <PoweredByLinkSelectorPage
          system={system}
          accountId={accountId}
          poweredByField={PoweredByFields.DISPLAY_POWERED_BY}
        />
        <PoweredByLinkSelectorPage
          system={system}
          accountId={accountId}
          poweredByField={PoweredByFields.POWERED_BY_TXT}
        />
      </StyledGridDiv>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Typography> Content Summary</Typography>
          <Chip
            label={deployablesLabelsForTag()
              .reduce((accumulator, object) => {
                return accumulator + object.counter;
              }, 0)
              .toString()}
          />
        </div>
        <DeployablesTagList
          maxTagsShown={4}
          types={DeployablesTypesAppData.data}
          dataLabels={deployablesLabelsForTag()}
          showLabels
        />
      </div>
    </div>
  );
}
export default SystemInfoPage;
