import { Route, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import { useLoggedInUser } from '@wm-accounts-backoffice-center/state-management-users';
import LoadingPage from './components/loading-page/loading-page';
import PageWrapper from './components/page-wrapper/page-wrapper';
import { FeatureFlagsPage } from '@wm-accounts-backoffice-center/features-flag-page';
import AllAccountsPage from '../../../../libs/pages/accounts-page/src/lib/all-accounts-page';
import AccountPage from 'libs/pages/accounts-page/src/lib/account-page';
import { BackofficeAdminsPage } from '@wm-accounts-backoffice-center/pages/backoffice-admins';
import { DeveloperHubPage } from '../../../../libs/pages/developer-hub/src';
import InactiveAccountsPage from 'libs/pages/backoffice-admins/src/lib/inactive-accounts';
import SystemsDeletionCenter from 'libs/pages/backoffice-admins/src/lib/systems-deletion-center/systems-deletion-center';
import { FeatureTogglesPage } from '../../../../libs/pages/feature-toggles-page/src';

export const Routes = () => {
  const { accountFeatureEnabled } = useLoggedInUser();
  const showFeatureToggles = accountFeatureEnabled('showFeatureToggles');

  return (
    <Switch>
      <Route
        path={[
          '/accounts/:id/:tabName?/:systemId?',
          '/account/:id/:tabName?/:systemId?',
        ]}
        render={(props) => (
          <PageWrapper {...props} page={AccountPage} name="Account" container />
        )}
      />
      <Route
        exact
        path={['/feature-flags']}
        render={(props) => (
          <PageWrapper
            {...props}
            page={FeatureFlagsPage}
            name="Features"
            container
          />
        )}
      />
      {showFeatureToggles && (
        <Route
          exact
          path={['/feature-toggles']}
          render={(props) => (
            <PageWrapper
              {...props}
              page={FeatureTogglesPage}
              name="Feature Toggles"
              container
            />
          )}
        />
      )}
      <Route
        exact
        path={['/backoffice-admins']}
        render={(props) => (
          <PageWrapper
            {...props}
            page={BackofficeAdminsPage}
            name="Backoffice Admins"
            container
          />
        )}
      />
      <Route
        exact
        path={['/inactive']}
        render={(props) => (
          <PageWrapper
            {...props}
            page={InactiveAccountsPage}
            name="Inactive Accounts"
            container
          />
        )}
      />
      <Route
        exact
        path={[
          '/systems-center',
          '/systems-center/all-soft-deleted',
          '/systems-center/ready-for-deletion',
        ]}
        render={(props) => (
          <PageWrapper
            {...props}
            page={SystemsDeletionCenter}
            name="Systems Deletion Center"
            container
          />
        )}
      />
      <Route
        exact
        path={['/developer-hub']}
        render={(props) => (
          <PageWrapper
            {...props}
            page={DeveloperHubPage}
            name="Backoffice Admins"
            container
          />
        )}
      />
      <Route
        exact
        path={['/accounts']}
        render={(props) => (
          <PageWrapper
            {...props}
            page={AllAccountsPage}
            name="Accounts"
            container
          />
        )}
      />
      <Route path={['/']}>
        <Redirect to={'/accounts'} />
      </Route>
    </Switch>
  );
};

export default () => {
  const { loggedInUserAppData } = useLoggedInUser();
  if (!loggedInUserAppData.data.id) {
    return <LoadingPage />;
  }
  return <Routes />;
};
