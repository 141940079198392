import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  DialogActions,
  DialogTitle,
  Typography,
} from '@walkme/ui-core';
import {
  ExtendedFeatureFlag,
  FeatureFlag,
  featureTogglesSlice,
} from '../../../../../state-management-feature-toggles/src';
import {
  StyledDialog,
  LoaderContainer,
  StyledContentContainer,
  StyledChangeContainer,
  StyledChangeDate,
  StyledChanges,
  StyledDialogContent,
} from './history-dialog.styles';
import useFeatureFlagHistory from '../../../../../state-management-feature-toggles/src/lib/hooks/use-get-history';
import { isEqual } from 'lodash';
import { WMLoader } from '@walkme/wm-ui';
import { ChangeHistoryRecord } from './change-history-record';
import {
  possibleChangedFields,
  possibleTargetPopulationChangedFields,
} from './history-dialog.lib';
import {
  makeFirstCharCapital,
  splitOnCapitalAndLowerCase,
} from '../feature-toggle-dialog/create-edit-feature-toggle-dialog.lib';

export interface HistoryDialogProps {
  isOpen: boolean;
  activeFeatureFlag: any;
  unsetSelectedFeatureFlag: () => void;
}

export const HistoryDialog = ({
  isOpen,
  activeFeatureFlag,
  unsetSelectedFeatureFlag,
}: HistoryDialogProps) => {
  const dispatch = useDispatch();
  const {
    ownerTeam: { _id: ownerTeam },
    name,
  } = activeFeatureFlag;
  const featureFlagHistory = useFeatureFlagHistory(name, ownerTeam);
  const [processedChanges, setProcessedChanges] = useState(null);

  const getChangeDescription = (field: string, prevValue, currentValue) => {
    let changeDescription = '';
    switch (field) {
      case 'description':
        changeDescription = `Changed description from \"${prevValue}\" to \"${currentValue}\"`;
        break;
      case 'policy':
        const formattedPrevValue = makeFirstCharCapital(
          splitOnCapitalAndLowerCase(prevValue)
        );
        const formattedCurrentValue = makeFirstCharCapital(
          splitOnCapitalAndLowerCase(currentValue)
        );
        changeDescription = `Changed policy from \"${formattedPrevValue}\" to \"${formattedCurrentValue}\"`;
        break;
      case 'type':
        const capitalizedPrevValue = makeFirstCharCapital(prevValue);
        const capitalizedCurrentValue = makeFirstCharCapital(currentValue);
        changeDescription = `Changed type from \"${capitalizedPrevValue}\" to \"${capitalizedCurrentValue}\"`;
        break;
      case 'entities':
        const prev = prevValue ?? 'None';
        const current = currentValue ?? 'None';

        if (prev !== 'None' || current !== 'None') {
          changeDescription = `Changed entities from "${prev}" to "${current}"`;
        }
        break;
      case 'isEnabled':
        changeDescription = currentValue ? 'Enabled flag' : 'Disabled flag';
        break;
      case 'status':
        changeDescription =
          currentValue === 'active' ? 'Restored flag' : 'Archived flag';
        break;
      default:
        break;
    }

    return changeDescription;
  };
  const calculateFeatureFlagDiff = (
    oldFlag: FeatureFlag,
    newFlag: FeatureFlag
  ) => {
    const diffs = [];
    let changeDescription = '';

    possibleChangedFields.forEach((field) => {
      if (field === 'targetPopulation') {
        if (!isEqual(oldFlag[field], newFlag[field])) {
          possibleTargetPopulationChangedFields.forEach((innerField) => {
            if (
              !isEqual(oldFlag[field][innerField], newFlag[field][innerField])
            ) {
              changeDescription = getChangeDescription(
                innerField,
                oldFlag[field][innerField],
                newFlag[field][innerField]
              );

              if (changeDescription !== '') {
                diffs.push({
                  field: innerField,
                  oldValue: oldFlag[field][innerField],
                  newValue: newFlag[field][innerField],
                  description: changeDescription,
                });
              }
            }
          });
        }
      } else if (oldFlag[field] !== newFlag[field]) {
        changeDescription = getChangeDescription(
          field,
          oldFlag[field],
          newFlag[field]
        );

        diffs.push({
          field: field,
          oldValue: oldFlag[field],
          newValue: newFlag[field],
          description: changeDescription,
        });
      }
    });
    return diffs;
  };

  const calculateChangeHistory = (featureFlagHistory) => {
    const changes = [];
    const n = featureFlagHistory.length - 1;

    for (let i = 0; i < n; i++) {
      const newFlag = featureFlagHistory[i];
      const oldFlag = featureFlagHistory[i + 1];

      const diffs = calculateFeatureFlagDiff(oldFlag, newFlag);

      const change = {
        date: new Date(newFlag.updatedAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }),
        time: new Date(newFlag.updatedAt).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        }),
        user: newFlag.updatedBy.name,
        text:
          diffs.length > 1
            ? `${newFlag.updatedBy.name} updated the following fields: ${diffs
                .map((diff) => diff.field)
                .join(', ')}`
            : `${newFlag.updatedBy.name} updated field ${diffs[0].field}`,
        diffs: diffs,
      };

      changes.push(change);
    }

    // INFO: FF creation entry (no diffs)
    let initialDataEntry = `Created flag with the following data: \n`;
    for (let key in activeFeatureFlag) {
      if (
        typeof activeFeatureFlag[key] === 'object' &&
        activeFeatureFlag[key] !== null
      ) {
        initialDataEntry +=
          key + ': ' + JSON.stringify(activeFeatureFlag[key], null, 4) + '\n';
      } else {
        initialDataEntry += key + ': ' + activeFeatureFlag[key] + '\n';
      }
    }

    const text =
      typeof activeFeatureFlag.createdBy === 'string'
        ? `Flag ${featureFlagHistory[n].name} was created`
        : `${activeFeatureFlag.createdBy?.name} created the flag ${featureFlagHistory[n].name}`;

    const creation = {
      date: new Date(featureFlagHistory[n].createdAt).toLocaleDateString(
        'en-US',
        {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }
      ),
      time: new Date(featureFlagHistory[n].createdAt).toLocaleTimeString(
        'en-US',
        {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        }
      ),
      user: activeFeatureFlag.createdBy?.name ?? 'N A',
      text: text,
      diffs: null,
      initial: initialDataEntry,
    };
    changes.push(creation);
    return changes;
  };

  const groupChangesByDate = (changes) => {
    const changesByDate = [];

    changes.forEach((change) => {
      const existingDateGroup = changesByDate.find(
        (group) => group.date === change.date
      );

      if (existingDateGroup) {
        existingDateGroup.changes.push(change);
      } else {
        changesByDate.push({
          date: change.date,
          changes: [change],
        });
      }
    });

    changesByDate.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    return changesByDate;
  };

  const onClose = () => {
    dispatch(featureTogglesSlice.actions.setHistoryDialogOpen(false));
    dispatch(featureTogglesSlice.actions.cleanupHistoryDialog());
    unsetSelectedFeatureFlag();
  };

  const processFeatureFlagChanges = () => {
    if (featureFlagHistory?.length > 0) {
      const changes = calculateChangeHistory(featureFlagHistory);
      const groupedChanges = groupChangesByDate(changes);
      setProcessedChanges(groupedChanges);
    }
  };

  useEffect(processFeatureFlagChanges, [featureFlagHistory]);

  const loaderComponent = (
    <LoaderContainer>
      <WMLoader />
    </LoaderContainer>
  );

  return (
    <StyledDialog isOpen={isOpen} onClose={onClose} preventClose>
      <DialogTitle>Change History</DialogTitle>
      <StyledDialogContent>
        <StyledContentContainer>
          <div className="sub-title">
            <Typography variant={'T20'}>
              Change history for feature flag: <b>{name}</b>
            </Typography>
          </div>

          {processedChanges?.map((changeObj) => {
            return (
              <>
                <StyledChangeDate>
                  <Typography variant={'T10-2'}>{changeObj?.date}</Typography>
                </StyledChangeDate>
                <StyledChanges>
                  {changeObj?.changes.map((change) => {
                    return (
                      <StyledChangeContainer>
                        <ChangeHistoryRecord change={change} />
                      </StyledChangeContainer>
                    );
                  })}
                </StyledChanges>
              </>
            );
          })}
          {processedChanges === null && loaderComponent}
        </StyledContentContainer>
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </StyledDialog>
  );
};
