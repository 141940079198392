import React, { Suspense } from 'react';
import { AccountType } from 'wm-accounts-sdk';
import LoadingPage from '../loading-page/loading-page';

import { RouteComponentProps } from 'react-router-dom';
import { UnsupportedErrorPage } from '../error-pages/unsupported-error-page';
import { useLoggedInUser } from '@wm-accounts-backoffice-center/state-management-users';

export interface PageWrap extends RouteComponentProps {
  page: (props) => JSX.Element;
  name: string;
  container: boolean;
  extraProps?: Record<string, unknown>;
  pageData?: unknown;
  loadingData?: boolean;
}

export const PageWrapper = (props: PageWrap) => {
  const CurrentPage: (props) => JSX.Element = props.page;

  const { loggedInUserAppData } = useLoggedInUser();

  const isBackoffice = loggedInUserAppData.data.isBackoffice;
  const isImpersonator = loggedInUserAppData.data.impersonator;
  const impersonatePageWithImpersonatorOrBackoffice =
    isBackoffice || isImpersonator;

  if (!impersonatePageWithImpersonatorOrBackoffice) {
    return (
      <UnsupportedErrorPage whyText="Backoffice permissions are required." />
    );
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CurrentPage {...props} />
    </Suspense>
  );
};

export default PageWrapper;
