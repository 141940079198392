import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@walkme/ui-core';
import React, { useCallback } from 'react';
import { MergeAccountState } from '../../merge-account.store';
import { actionConfirmed, dialogClosed } from './confirmation-dialog.slice';
import { useDispatch, useSelector } from 'react-redux';

export const ConfirmationDialog = () => {
  const dispatch = useDispatch();

  const onDialogClose = useCallback(() => {
    dispatch(dialogClosed());
  }, [dispatch]);

  const onActionConfirmed = useCallback(() => {
    dispatch(actionConfirmed());
  }, [dispatch]);

  const isDialogVisible = useSelector(
    (state: MergeAccountState) => state.confimation?.isDialogVisible || false
  );

  return (
    <>
      <Button onClick={function noRefCheck() {}}>Open Dialog</Button>
      <Dialog isOpen={isDialogVisible} onClose={onDialogClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to go on with this merge?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose} variant="outlined">
            Cancel
          </Button>
          <Button color="danger" onClick={onActionConfirmed} variant="solid">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
