import get from 'lodash/get';
import moment from 'moment';
import {
  TableRowData,
  Order,
} from '@wm-accounts-backoffice-center/general-types';
import { RowNode } from '@ag-grid-community/core';

export function descendingComparator(
  a: TableRowData,
  b: TableRowData,
  orderBy: string
): number {
  if (!orderBy) return 1;

  const aValuePath =
    a[orderBy] instanceof Object && a[orderBy].hasOwnProperty('value')
      ? `${orderBy}.value`
      : orderBy;
  const bValuePath =
    b[orderBy] instanceof Object && b[orderBy].hasOwnProperty('value')
      ? `${orderBy}.value`
      : orderBy;

  let bVal = get(b, bValuePath, 0);
  let aVal = get(a, aValuePath, 0);

  if (typeof bVal === 'string') {
    bVal = bVal.toLowerCase();
  }

  if (typeof aVal === 'string') {
    aVal = aVal.toLowerCase();
  }

  if (orderBy === 'lastModified' || orderBy.toLowerCase().includes('date')) {
    const b = moment(`${bVal}`, 'MMM Do YYYY').valueOf();
    const a = moment(`${aVal}`, 'MMM Do YYYY').valueOf();

    if (isNaN(a) && isNaN(b)) return 0;
    if (isNaN(a) && !isNaN(b)) return 1;
    if (!isNaN(a) && isNaN(b)) return -1;

    return b - a;
  }

  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

export function getComparator(
  order: Order,
  orderBy: string
): (a: TableRowData, b: TableRowData) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function filterData(arr: TableRowData[], str: string) {
  const searchValue = `${str}`.toLowerCase();

  if (!searchValue) {
    return arr;
  }

  return arr.filter((rowData) => {
    const rowValues = Object.values(rowData);

    return rowValues.find((property) => {
      const val = property instanceof Object ? property.value : property;
      return `${val}`.toLowerCase().includes(searchValue);
    });
  });
}

export function generateRandomKey() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export const dateComparator =
  <T = any>(nodePropertyPath: string) =>
  (date1, date2, nodeA: RowNode<T>, nodeB: RowNode<T>): number => {
    let date1Data, date2Data;

    if (nodePropertyPath) {
      date1Data = get(nodeA, nodePropertyPath);
      date2Data = get(nodeB, nodePropertyPath);
    } else {
      date1Data = date1;
      date2Data = date2;
    }

    const date1Number = date1Data && new Date(date1Data).getTime();
    const date2Number = date2Data && new Date(date2Data).getTime();

    if (date1Number == null && date2Number == null) {
      return 0;
    }

    if (date1Number == null) {
      return -1;
    } else if (date2Number == null) {
      return 1;
    }

    return date1Number - date2Number;
  };
