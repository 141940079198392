import styled from 'styled-components';

export const PageTitle = styled.h1`
  margin: 0 0 24px 0;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
`;

export default PageTitle;
