import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FeaturesState, getAllFeatures } from '../redux/features.slice';

export const useGetAllFeatures = (forceLoad = false) => {
  const allFeaturesAppData = useSelector(
    (state: { featuresState: FeaturesState }) => state.featuresState.allFeatures
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllFeatures(forceLoad));
  }, [dispatch]);

  return { allFeaturesAppData };
};

export default useGetAllFeatures;
