import styled from 'styled-components';

export const StyledIdpEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #f0f3f9;
  padding: 12px 0;
`;

export const StyledSubTitle = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #2f426c;
`;
