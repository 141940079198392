import React from 'react';
import {
  Menu,
  MenuItem,
  MenuList,
  MenuItemText,
  DropdownSelection,
} from '@walkme/ui-core';
import { useState, MouseEvent } from 'react';
import styled from 'styled-components';

const StyledActionMenuLongList = styled(MenuList)`
  max-height: 150px;
`;

const StyledSelector = styled(DropdownSelection)`
  button {
    height: 32px;
    margin: 0;
    padding: 5px;
    border-radius: 16px;
    background: #edf1fd;
  }
`;

const StyledNoResults = styled.div`
  padding: 0 16px 16px 16px;
  display: flex;
  justify-content: center;
`;

interface Item {
  label: string;
  value: any;
}

export interface DropdownSelectionMenuNoSearchProps {
  onChange: (newStatus) => void;
  selectedValue: any;
  items: Item[];
}

export const DropdownSelectionMenuNoSearch = ({
  items,
  onChange,
  selectedValue,
}: DropdownSelectionMenuNoSearchProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const selected = items.find((item) => item.value === selectedValue);
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: MouseEvent, item: Item) => {
    onChange(item.value);
    handleClose();
  };
  return (
    <>
      <StyledSelector
        handleOpen={handleOpen}
        displayValue={
          selected?.label ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MenuItemText>{selected?.label}</MenuItemText>
            </div>
          ) : (
            'Please select ...'
          )
        }
        anchorEl={anchorEl}
      />
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        width={250}
      >
        {!items?.length ? (
          <StyledNoResults>
            <MenuItemText>No Options</MenuItemText>
          </StyledNoResults>
        ) : (
          <StyledActionMenuLongList>
            {items.map((item) => {
              const isSelected = selected?.value === item.value;
              return (
                <MenuItem
                  key={item.value}
                  onClick={(e) => handleClick(e, item)}
                  selected={isSelected}
                  checkMark
                >
                  <MenuItemText selected={isSelected}>
                    {item.label}
                  </MenuItemText>
                </MenuItem>
              );
            })}
          </StyledActionMenuLongList>
        )}
      </Menu>
    </>
  );
};
