import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SubTitleTab, TitleTab } from '../styles/styles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputSearch,
  Loader,
  Spinner,
  Tabs,
  TagList,
  ToasterVariant,
  Tooltip,
  Typography,
  useToaster,
} from '@walkme/ui-core';
import { DropdownSelectionMenuDefaultFeatures } from '../features/DropdownSelectionMenuDefaultFeatures';
import { useDispatch, useSelector } from 'react-redux';
import {
  DefaultFeaturesState,
  getAccountDefaultFeatures,
  getDefaultFeatures,
  updateAccountDefaultFeature,
} from '@wm-accounts-backoffice-center/state-management-default-features';
import { SystemsState } from 'libs/state-management-systems/src';
import AccountsCollapse from './accounts-collapse';
import styled from 'styled-components';
import { FilledWarning } from '@walkme/ui-icons';
import { DataGridModules, WMAgGridWrapper, WMSwitch } from '@walkme/wm-ui';
import moment from 'moment';
import { Box, makeStyles } from '@material-ui/core';
import {
  DefaultFeaturesData,
  MasterFeaturesData,
  Systems,
  UpdateDefaultFeaturesDto,
} from 'wm-accounts-sdk';
import { FeatureFlag } from '@wm-accounts-backoffice-center/state-management-features';
import { Account } from '@wm-accounts-backoffice-center/wm-api';
import { useLoggedInUser } from '@wm-accounts-backoffice-center/state-management-users';
import { PlatformOption } from './default-settings-page';
import { AppData } from '@wm-accounts-backoffice-center/general-types';

export interface DefaultSettingsSystemProps {
  allFeatures: FeatureFlag[];
  account: Account;
  query: string;
  selectedPlatform: PlatformOption;
  accountDefaultFeatures: AppData<DefaultFeaturesData[]>;
  isLoading?: boolean;
}

const StyledCheckbox = styled(Checkbox)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2f426c;
`;
const StyledTypography = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
`;
const StyledWMAgGridWrapper = styled(WMAgGridWrapper)<{ isCore }>`
  .ag-cell-focus {
    border-color: transparent !important;
  }
  height: ${(props) => (props.isCore ? '90% !important' : '100% !important')};
`;
const StyledTypographyDialog = styled(Typography)`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #2f426c;
`;
const StyledWarningTypography = styled(Typography)`
  padding: 2px 0px;
`;
const StyledWmDisabled = styled(WMSwitch)`
  .WMSwitch-switchBase.WMSwitch-disabled + .WMSwitch-track {
    background-color: #d8dffb;
  }
`;

export const StyledLoaderBox = styled(Loader)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledWarning = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background: #fef8e8;
  border-bottom: 1px solid #e4e9fc;
  padding: 8px 12px 8px 30px;
`;

const useStyles = makeStyles(() => ({
  tagList: {
    padding: '0 !important',
    alignItems: 'center',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
  },
}));

interface DefaultFeatureRow {
  name: string;
  type: string;
  id: number;
  defaultStatus: boolean;
  enabledOn: string[];
  description: string;
}

const getFeatureRows = (
  allFeatures: FeatureFlag[],
  accountSystems: Systems,
  accountDefaultFeatures: DefaultFeaturesData[]
): DefaultFeatureRow[] => {
  if (allFeatures) {
    return allFeatures.map((feature) => {
      const enabledSystems = accountSystems.filter((system) =>
        system.featureFlags.find((ff) => ff.flagName === feature.flagName)
      );
      const defaultFeature = accountDefaultFeatures.find(
        (ff) => ff.flagName === feature.flagName
      );
      const defaultStatus = defaultFeature
        ? defaultFeature.enabled ?? true
        : false;
      return {
        name: feature.flagName,
        type: feature.type,
        id: feature.id,
        description: feature.description && feature.description.description,
        defaultStatus: defaultStatus,
        enabledOn: enabledSystems.map((system) => {
          return system.displayName;
        }),
        updatedBy: defaultFeature?.modifiedBy,
        updatedDate:
          defaultFeature && defaultFeature.updatedAt
            ? moment(defaultFeature.updatedAt).format('MMM Do YYYY')
            : '',
      };
    });
  }
  return [];
};

export function DefaultSettingsSystemPage(
  pageProps: DefaultSettingsSystemProps
) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addToaster } = useToaster();
  const { loggedInUserAppData } = useLoggedInUser();

  const [isOpen, setisOpen] = useState(false);
  const [action, setAction] = useState('');
  const [selectedFeature, setSelectedFeature] = useState('');
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [switchIsLoadingName, setSwitchIsLoadingName] = useState<string>('');

  const defaultFeatures = useSelector(
    (state: { defaultFeatureState: DefaultFeaturesState }) =>
      state.defaultFeatureState.defaultFeatures
  );

  const masterFeaturesPerAccount = useSelector(
    (state: { defaultFeatureState: DefaultFeaturesState }) =>
      state.defaultFeatureState.masterFeaturesPerAccount
  );

  const accountData = pageProps.account;
  const allSystems =
    accountData?.systems?.filter(
      (system) =>
        !system.statusDetails || system.statusDetails.status === 'enabled'
    ) || [];

  const featuresTable = useCallback(
    (isCore: boolean) => {
      const defaultFeaturesSet = new Set<string>(
        defaultFeatures.data?.split(' ')
      );

      const features: FeatureFlag[] = isCore
        ? pageProps.allFeatures.filter((feature) =>
            defaultFeaturesSet.has(feature.flagName)
          )
        : pageProps.allFeatures.filter(
            (feature) =>
              feature.whitelisted && !defaultFeaturesSet.has(feature.flagName)
          );

      const allFeaturesRowData = useMemo(() => {
        return getFeatureRows(
          features,
          allSystems,
          pageProps.accountDefaultFeatures.data
        );
      }, [
        pageProps.allFeatures,
        pageProps.accountDefaultFeatures,
        defaultFeatures,
      ]);

      return pageProps.isLoading && !switchIsLoadingName ? (
        <StyledLoaderBox>
          <Loader />
        </StyledLoaderBox>
      ) : (
        <StyledWMAgGridWrapper
          isCore={isCore}
          quickFilterText={pageProps.query}
          defaultColDef={{
            flex: 1,
          }}
          columnDefs={[
            {
              field: 'name',
              pinned: 'left',
              cellClass: 'styled-cell',
              minWidth: 300,
              sortable: true,
              filter: true,
              cellRenderer: function (params) {
                return (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 400,
                        color: '#2F426C',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                    >
                      {params.value}
                    </span>
                    {params.data.description && (
                      <span
                        style={{
                          fontFamily: 'Proxima Nova',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '12px',
                          lineHeight: '16px',

                          color: '#637191',
                        }}
                      >
                        {params.data.description}
                      </span>
                    )}
                  </div>
                );
              },
            },
            {
              field: 'defaultStatus',
              minWidth: 200,
              maxWidth: 200,
              sortable: true,
              filter: true,
              headerTooltip:
                'Select a feature to make it the default option for all systems. This means that every new system created under this account will automatically include the selected feature',
              pinned: 'left',
              cellClass: 'styled-cell',
              cellRenderer: function (params) {
                const disabledSwitch = !!masterFeaturesPerAccount.data?.find(
                  (masterFeature) =>
                    masterFeature.feature?.featureFlags?.find(
                      (masterFF) => masterFF.id == params.data.id
                    )
                );
                return disabledSwitch && params.value ? (
                  <Tooltip
                    arrow
                    placement={'top-start'}
                    title="This feature cannot be turned off since it is part of a Master feature that is enabled."
                  >
                    <StyledWmDisabled
                      style={{ backgroundColor: '#D8DFFB !important' }}
                      ds2
                      checked={params.value}
                      disabled={disabledSwitch}
                      label={
                        <span
                          style={{
                            color: '#2F426C',
                            fontSize: '14px',
                            fontWeight: 400,
                            fontFamily: 'Proxima Nova',
                            fontStyle: 'normal',
                            lineHeight: '16px',
                          }}
                        >
                          {params.value ? 'Enabled' : 'Disabled'}
                        </span>
                      }
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        changedDefaultStatus(params.value, params.data.name);
                      }}
                    />
                  </Tooltip>
                ) : (
                  <div>
                    {switchIsLoadingName === params.data.name ? (
                      <Spinner />
                    ) : (
                      <WMSwitch
                        ds2
                        checked={params.value}
                        label={
                          <span
                            style={{
                              display: 'flex',
                              color: '#2F426C',
                              fontSize: '14px',
                              fontWeight: 400,
                              fontFamily: 'Proxima Nova',
                              fontStyle: 'normal',
                              lineHeight: '16px',
                            }}
                          >
                            {params.value ? 'Enabled' : 'Disabled'}
                          </span>
                        }
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          changedDefaultStatus(params.value, params.data.name);
                        }}
                      />
                    )}
                  </div>
                );
              },
              keyCreator: (params) => (params.value ? 'Enabled' : 'Disabled'),
            },
            {
              field: 'enabledOn',
              cellClass: 'styled-cell',
              minWidth: 300,
              sortable: true,
              filter: true,
              cellRenderer: function (params) {
                return params.value ? (
                  <TagList
                    labels={params.value.map((text) => {
                      return {
                        label: text,
                        className: classes.tag,
                      };
                    })}
                    maxTagsShown={2}
                    showTooltip
                    className={classes.tagList}
                  />
                ) : (
                  ''
                );
              },
            },
            {
              field: 'type',
              cellClass: 'styled-cell',
              minWidth: 150,
              sortable: true,
              filter: true,
            },
            {
              field: 'updatedBy',
              minWidth: 250,
              cellClass: 'styled-cell',
              sortable: true,
              filter: true,
            },
            {
              field: 'updatedDate',
              cellClass: 'styled-cell',
              minWidth: 150,
              sortable: true,
              filter: true,
              keyCreator: (params) =>
                params.value
                  ? moment(params.value).format('MMM Do YYYY')
                  : '(Blanks)',
              valueGetter: function (params) {
                const defaultFeature =
                  pageProps.accountDefaultFeatures.data.find(
                    (ff) => ff.id === params.data.id
                  );
                return defaultFeature ? defaultFeature.updatedAt ?? '' : '';
              },
              valueFormatter: function (params) {
                return params.value === ''
                  ? ''
                  : moment(params.value).format('MMM Do YYYY');
              },
            },
          ]}
          rowData={allFeaturesRowData}
          modules={DataGridModules}
        />
      );
    },
    [
      switchIsLoadingName,
      pageProps.accountDefaultFeatures.data,
      allSystems,
      pageProps.query,
      pageProps.allFeatures,
    ]
  );

  const updateDefaultFeature = useCallback(
    async (action, selectedFeatureToUpdate) => {
      setSwitchIsLoadingName(selectedFeatureToUpdate);
      const updateDto: UpdateDefaultFeaturesDto = {
        accountId: accountData.id,
        featureName: selectedFeatureToUpdate,
        platform: pageProps.selectedPlatform.value,
        action: action === 'Enable' ? 'Add' : 'Remove',
        modifiedBy: loggedInUserAppData.data.email,
      };
      setUpdatingStatus(true);
      await dispatch(updateAccountDefaultFeature(updateDto));
      setSwitchIsLoadingName('');
    },
    [pageProps.selectedPlatform]
  );

  useEffect(() => {
    if (pageProps.accountDefaultFeatures.data && updatingStatus) {
      addToaster({
        message: `${selectedFeature} ${action}`,
        variant: ToasterVariant.Success,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
      setUpdatingStatus(false);
      setisOpen(false);
    } else if (pageProps.accountDefaultFeatures.error) {
      addToaster({
        message: `${pageProps.accountDefaultFeatures.error}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [pageProps.accountDefaultFeatures]);

  const [doNotShowPopUp, setDoNotShowPopUp] = useState(
    localStorage.getItem('doNotShowPopUp')
  );

  const changedDefaultStatus = (action, feature) => {
    setAction(!action ? 'Enable' : 'Disable');
    setSelectedFeature(feature);
    doNotShowPopUp === 'true'
      ? updateDefaultFeature(!action ? 'Enable' : 'Disable', feature)
      : setisOpen(true);
  };

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <Dialog isOpen={isOpen} onClose={() => setisOpen(false)}>
        <DialogTitle>
          {action} {selectedFeature} as default?
        </DialogTitle>
        <DialogContent>
          <div
            style={{ gap: '24px', display: 'flex', flexDirection: 'column' }}
          >
            <StyledTypographyDialog>
              If you turn on default status the following features will be
              applied by default on any new system created under this account.
            </StyledTypographyDialog>
            <StyledCheckbox
              label="Do not show this again"
              checked={doNotShowPopUp === 'true'}
              onChange={(e) => {
                if (doNotShowPopUp == 'true') {
                  localStorage.setItem('doNotShowPopUp', null);
                  setDoNotShowPopUp(null);
                } else {
                  localStorage.setItem('doNotShowPopUp', 'true');
                  setDoNotShowPopUp('true');
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={updatingStatus}
            onClick={() => setisOpen(false)}
            variant="text"
          >
            <span style={{ color: '#637191' }}>Cancel</span>
          </Button>
          <Button
            disabled={updatingStatus}
            onClick={() => updateDefaultFeature(action, selectedFeature)}
            variant="solid"
          >
            {updatingStatus ? <Spinner /> : action}
          </Button>
        </DialogActions>
      </Dialog>
      <AccountsCollapse
        defaultOpenIndex={0}
        collapsableItems={[
          {
            header: (
              <StyledTypography variant="T30-2">
                General Features
              </StyledTypography>
            ),
            content: <div className="open-div">{featuresTable(false)}</div>,
          },
          {
            header: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '0px',
                  gap: '55px',
                }}
              >
                <StyledTypography variant="T30-2">
                  Core Features
                </StyledTypography>
                <FilledWarning />
              </div>
            ),
            content: (
              <div className="open-div-core">
                <StyledWarning>
                  <StyledWarningTypography variant="T20">
                    Core features are enabled by default. Disabling them can
                    cause damage and should be done only if required
                  </StyledWarningTypography>
                </StyledWarning>
                {featuresTable(true)}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
export default DefaultSettingsSystemPage;
