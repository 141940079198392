import {
  Account,
  ActionTypeDetails,
  Role,
  Roles,
  SubscriptionStatusActionType,
  SubscriptionStatusType,
  System,
} from 'wm-accounts-sdk';
import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';
import axios from 'axios';

export type AccountWithStatus = Account & {
  accountId?: number;
  status?: SubscriptionStatusType;
  statusType?: SubscriptionStatusType;
  updatedAt: string;
  deletionAt: string;
  systemsAmount: number;
  lastLoggedInUserDate: string | Date;
};

export type SystemWithStatus = System & {
  systemId?: number;
  systemGuid?: string;
  accountId?: number;
  accountName?: string;
  status?: SubscriptionStatusType;
  statusType?: SubscriptionStatusType;
  actionType: SubscriptionStatusActionType;
  actionTypeDetails: ActionTypeDetails;
  updatedBy: number;
  updatedByUserEmail: string;
  updatedAt: string;
  deletionAt: string;
  lastAnalyticsDate: string;
};

export type SystemData = {
  systemId: number;
  systemGuid: string;
};

class AccountsSubscriptionApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  async getInactiveAccounts(): Promise<AccountWithStatus[]> {
    const response = await this.httpClient.instance.get<AccountWithStatus[]>(
      `/accounts-subscription/accounts?statusType=inactive`
    );
    return response.data;
  }

  async getSystemsByStatus(
    status: SubscriptionStatusType,
    limit: number = 1000,
    page: number = 1
  ): Promise<any> {
    const response = await this.httpClient.instance.get(
      `/accounts-subscription/systems/all?limit=${limit}&statusType=${status}&page=${page}`
    );
    return response.data;
  }

  async changeAccountsStatusType(
    accountsIds: number[],
    statusType: SubscriptionStatusType,
    deletionIntervalKey: string
  ): Promise<AccountWithStatus[]> {
    const response = await this.httpClient.instance.post<AccountWithStatus[]>(
      `/accounts-subscription/accounts?statusType=${statusType}&deletionIntervalKey=${deletionIntervalKey}`,
      { accountsIds }
    );
    return response.data;
  }

  async startSystemsSchedulerBySystemsGuids(
    systemsGuids: string[],
    masterPassword: string
  ): Promise<any> {
    const response = await this.httpClient.instance.post<AccountWithStatus[]>(
      `/accounts-subscription/startSystemsSchedulerBySystemsGuids`,
      systemsGuids,
      {
        headers: {
          'x-password': masterPassword,
        },
      }
    );
    return response.data;
  }

  async updateSystemsStatus(
    systemsData: SystemData[],
    statusType: SubscriptionStatusType,
    updatingUserId: number
  ): Promise<any> {
    try {
      const response = await this.httpClient.instance.post(
        '/accounts-subscription/systems?deletionIntervalKey=immediately',
        {
          systemsData,
          statusType,
          updatingUserId,
          canBeRestored: false,
          actionType: SubscriptionStatusActionType.backoffice,
          actionTypeDetails: ActionTypeDetails.deletionCycle,
        }
      );

      return response.data;
    } catch (e) {
      return e;
    }
  }
}

export const accountsSubscriptionApi = new AccountsSubscriptionApi(
  wmhttpClientSharedInstance
);
