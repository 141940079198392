import React, { useEffect, useState, useCallback, useMemo } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getComparator, stableSort, filterData } from './utils';
import Head from '../table-head/table-head';
import styled from 'styled-components';
import {
  TableHeadCell,
  TableRowData,
  Order,
} from '@wm-accounts-backoffice-center/general-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox } from '../checkbox/checkbox';
import Footer, { FooterButtonsConfig } from '../table-footer/table-footer';
import EmptySearch from '../empty-search/empty-search';
import {
  Grid,
  Button,
  IconButton,
  OutlinedInput,
  TextField,
  makeStyles,
  Divider,
} from '@material-ui/core';
import { WMButton, WMTooltipProps, WMDivider, WMMenu } from '@walkme/wm-ui';

export interface DataTableProps {
  data: TableRowData[];
  buttonText?: string;
  buttonWidth?: number;
  heads?: TableHeadCell[];
  title?: JSX.Element;
  hasToolbar?: boolean;
  loading?: boolean;
  rowsPerPage?: number;
  onSortChange?: (s: string) => void;
  onRowsPerPageChange?: (n: number) => void;
  currentPage?: number;
  onPageChange?: (n: number) => void;
  config?: Record<string, number[]>;
  currentOrder?: Order;
  currentOrderBy?: string;
  onSetOrderBy?: (u: unknown) => void;
  onToolbarButtonClick?: () => void;
  onRowClick?: (n: unknown) => void;
  onDataChanged?: (n: unknown[]) => void;
  customToolbarButton?: JSX.Element;
  rowsSelectable?: boolean;
  rowsSelectableDisabled?: boolean;
  onAllRowsToggled?: (areAllSelected: boolean) => void;
  onRowToggled?: (n: unknown, isSelected: boolean) => void;
  selectedSystem?: string;
  footerButtons: FooterButtonsConfig[];
  footerMessage?: string | JSX.Element;
  tableHeader?: JSX.Element;
  menuButton?: any;
  query?: string;
  serverSideRender?: boolean;
  serverSidePropsRenderProps?: any;
  jobsCount?: number;
  buttonTooltipProps?: WMTooltipProps;
  emptySerchText?: string;
}

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 450px;
`;

const StyledButton = styled(Button)`
  border-radius: 17px;
  height: 32px;
  margin-left: 16px;
  margin-right: 16px;
  width: 150px;
  font-family: 'ProximaNova';
  box-shadow: none;
  border-color: #e1e9f5;
  background-color: #3b61eb;
  color: #ffffff;
  text-transform: none;
  &:hover {
    background-color: #1f46d1;
    opacity: 0.9;
  }
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  .MuiOutlinedInput-root {
    height: 32px;
    border-radius: 4px;
    border-color: #6d81a6;
    border-width: 1px;
  }
`;

const StyledSearchInput = styled(OutlinedInput)`
  width: 280px;
  height: 28px;
  background-color: #e1e9f5;
  font-family: 'ProximaNova';
  &hover {
    background-color: #d8e2f2;
  }
  .MuiOutlinedInput-inputAdornedStart {
    padding-left: 14px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0px solid;
  }
  .MuiInputBase-input {
    color: #6d81a6;
  }
  top: 0px;
  border-radius: 50px;
`;

const StyledRightDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 240px;
  margin-bottom: 20px;
`;

const StyledLeftDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 10px;
  width: 240px;
  margin-bottom: 20px;
`;

const StyledTableContainer = styled(TableContainer)`
  box-sizing: border-box;
  height: 100%;
`;

const StyledTable = styled(Table)`
  width: 100%;
  min-width: 750px;
  height: 100%;
`;

const StyledHeaderDiv = styled.div`
  height: 56px;
`;

const Cell = styled(TableCell)`
  max-width: 80%;
  cursor: pointer;
  font-family: 'ProximaNova';
  padding: 0 24px;
  color: #2f426c;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #f5f6f8;
`;

const StyledCheckboxCell = styled(TableCell)`
  font-size: 14px;
  max-width: 10px;
  width: 10px;
  cursor: pointer;
  padding: 0 24px;
  height: 40px;
  border-bottom: 1px solid #f5f6f8;
`;

const StyledPaper = styled(Paper)`
  box-shadow: none;
  border-radius: 12px;
  height: calc(100% - 60px);
`;

const StyledContainer = styled(Paper)`
  border-radius: 12px;
  box-shadow: none;
  margin-top: 24px;
  height: calc(100% - 56px);
`;

const StyledWMButton = styled(WMButton)`
  --size: 24px;
`;

const getCellDisplayValue = (cellData) => {
  const value =
    cellData instanceof Object && cellData.hasOwnProperty('displayValue')
      ? cellData.displayValue
      : cellData;

  if (value instanceof Date && value.toString() === 'Invalid Date') {
    return 'Invalid Date';
  } else if (value instanceof Date) {
    return `${value.toDateString()} ${value.toTimeString().slice(0, 8)}`;
  }

  return value;
};

const getRowId = (row) => {
  return row.id;
};

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      border: '0px solid',
    },
    '&:hover $notchedOutline': {
      border: '1px solid #ACB3C4',
    },
    '&$focused $notchedOutline': {
      border: '1px solid #3B61EB',
    },
    '&$focused': {
      backgroundColor: '#FFFFFF',
    },
  },
  focused: {},
  notchedOutline: {},
}));

const useStyles = makeStyles(() => ({
  rowHover: {
    '&:hover': {
      backgroundColor: '#FFFFFF !important',
      boxShadow: '0px 0px 15px 0px #D4DFF5',
    },
  },
  rowSelected: {
    backgroundColor: '#F4F6FB !important',
  },
  menuButton: {
    width: '24px !important',
    height: '24px !important',
  },
  deleteItemMenu: {
    '& .WMMenu-label': {
      color: '#EB3232',
    },
  },
  checkbox: {
    '&:hover div': {
      borderColor: '#6C89F0',
    },
  },
  icon: {
    border: '1px solid #6D81A6',
    borderRadius: '3px',
    width: 12,
    height: 12,
  },
}));

export const DataTable = ({
  data = [],
  buttonText,
  buttonWidth,
  heads = [],
  title,
  hasToolbar,
  loading,
  onRowsPerPageChange,
  rowsPerPage,
  onPageChange,
  currentPage,
  config,
  onSortChange,
  currentOrder,
  currentOrderBy,
  onSetOrderBy,
  onToolbarButtonClick,
  onRowClick,
  rowsSelectable,
  rowsSelectableDisabled,
  onAllRowsToggled,
  onRowToggled,
  selectedSystem,
  footerButtons,
  footerMessage,
  onDataChanged,
  customToolbarButton,
  tableHeader,
  menuButton,
  query,
  serverSideRender,
  serverSidePropsRenderProps,
  buttonTooltipProps,
  emptySerchText,
}: DataTableProps) => {
  const { rowsPerPageOptions } = config;
  const [myQuery, setMyQuery] = useState(
    serverSideRender ? serverSidePropsRenderProps.query : query
  );
  const [filteredRows, setFiltered] = useState(data);
  const updatedPage = serverSideRender
    ? serverSidePropsRenderProps.currentPage
    : useMemo(
        () =>
          filteredRows.length / rowsPerPage > currentPage ? currentPage : 0,
        [currentPage, filteredRows]
      );
  const [selectedRows, setSelectedRows] = useState({});
  const [indeterminateSelectedRows, setIndeterminateSelectedRows] =
    useState(false);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  useEffect(() => {
    const selectedRows = data.reduce((obj: object, row: any) => {
      obj[row.id] = false;

      return obj;
    }, {});

    setSelectedRows(selectedRows);
    setAllRowsSelected(false);
    setIndeterminateSelectedRows(false);
    setFiltered(data);
  }, [data]);

  const handleInputChange = useCallback(
    (e) => {
      serverSideRender
        ? serverSidePropsRenderProps.onSetQuery(e.target.value)
        : null;
      setMyQuery(e.target.value);
    },
    [setMyQuery]
  );

  const cancelSearch = useCallback(() => {
    serverSideRender ? serverSidePropsRenderProps.onSetQuery('') : null;
    setMyQuery('');
  }, [setMyQuery]);

  const saveFilteredData = useCallback(() => {
    if (serverSideRender) {
      return;
    }
    const filteredData = filterData(data, myQuery);
    setFiltered(filteredData);
    onDataChanged && onDataChanged(filteredData);
  }, [setFiltered, data, myQuery, onDataChanged]);

  useEffect(() => {
    const timeout = setTimeout(saveFilteredData, 0, filterData(data, myQuery));
    return () => clearTimeout(timeout);
  }, [data, myQuery]);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: unknown) => {
      const isAsc = serverSideRender
        ? serverSidePropsRenderProps.currentOrder === 'asc'
        : currentOrder === 'asc';
      serverSideRender
        ? serverSidePropsRenderProps.handleSortChange(isAsc ? 'desc' : 'asc')
        : onSortChange(isAsc ? 'desc' : 'asc');
      serverSideRender
        ? serverSidePropsRenderProps.onSetOrderBy(property)
        : onSetOrderBy(property);
    },
    [onSortChange, onSetOrderBy, currentOrder]
  );

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      onPageChange(newPage - 1);
    },
    [onPageChange]
  );

  const handleItemClick = (row) => {
    onRowClick(row.id);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedRowsPerPageNum = parseInt(event.target.value);
    onRowsPerPageChange(selectedRowsPerPageNum);
    onPageChange(0);
  };

  const handleRowSelected = (row) => {
    const currentSelectedRows = !!!selectedRows[row.id];
    const updatedSelectedRows = {
      ...selectedRows,
      [row.id]: currentSelectedRows,
    };
    const count = Object.values(updatedSelectedRows).reduce(
      (count: number, isRowSelected) => {
        if (isRowSelected) {
          count++;
        }

        return count;
      },
      0
    );

    const allRowsSelected = count == data.length;

    setSelectedRows(updatedSelectedRows);
    setAllRowsSelected(allRowsSelected);
    setIndeterminateSelectedRows(!allRowsSelected && count > 0);

    onRowToggled(row.id, currentSelectedRows);

    if (allRowsSelected || count == 0) {
      onAllRowsToggled(allRowsSelected);
    }
  };

  const handleAllRowsSelected = () => {
    let updatedSelectedRows;
    let updatedAllRowsSelected;

    if (allRowsSelected || indeterminateSelectedRows) {
      updatedSelectedRows = Object.keys(selectedRows).reduce(
        (obj: object, rowKey) => {
          obj[rowKey] = false;

          return obj;
        },
        {}
      );

      updatedAllRowsSelected = false;
    } else {
      updatedSelectedRows = Object.keys(selectedRows).reduce(
        (obj: object, rowKey) => {
          obj[rowKey] = true;

          return obj;
        },
        {}
      );

      updatedAllRowsSelected = true;
    }

    setAllRowsSelected(updatedAllRowsSelected);
    setIndeterminateSelectedRows(false);
    setSelectedRows(updatedSelectedRows);
    onAllRowsToggled(updatedAllRowsSelected);
  };

  const emptyRows = serverSideRender
    ? rowsPerPage - filteredRows.length
    : rowsPerPage -
      Math.min(rowsPerPage, filteredRows.length - updatedPage * rowsPerPage);
  const sortedRows = serverSideRender
    ? stableSort(
        filteredRows,
        getComparator(
          serverSidePropsRenderProps.currentOrder,
          serverSidePropsRenderProps.currentOrderBy
        )
      )
    : stableSort(filteredRows, getComparator(currentOrder, currentOrderBy));
  const slicedSortedRows = serverSideRender
    ? sortedRows
    : sortedRows.slice(
        updatedPage * rowsPerPage,
        updatedPage * rowsPerPage + rowsPerPage
      );

  const exportOnMenu = menuButton
    ? menuButton.find((menuAction) => menuAction.actionName == 'export')
    : null;
  const deleteOnMenu = menuButton
    ? menuButton.find((menuAction) => menuAction.actionName == 'delete')
    : null;
  const importOnMenu = menuButton
    ? menuButton.find((menuAction) => menuAction.actionName == 'import')
    : null;

  const menuItems = () => {
    const showMenuItems = [];
    exportOnMenu
      ? showMenuItems.push({
          label: 'Export Selected',
          startIcon: <img alt={'import'} src={`../assets/icons/export.svg`} />,
          onClick: () => exportOnMenu.action(selectedRows),
        })
      : null;
    importOnMenu
      ? showMenuItems.push({
          label: 'Import Features',
          startIcon: <img alt={'import'} src={`../assets/icons/import.svg`} />,
          onClick: () => importOnMenu.action(),
        })
      : null;
    deleteOnMenu
      ? showMenuItems.push({
          onClick: () => deleteOnMenu.action(selectedRows),
          label: 'Delete Selected',
          startIcon: <img alt={'import'} src={`../assets/icons/delete.svg`} />,
          className: classes.deleteItemMenu,
        })
      : null;
    return showMenuItems;
  };

  return (
    <StyledContainer>
      <StyledHeaderDiv>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ height: '56px', flexWrap: 'nowrap' }}
        >
          <Grid item>{tableHeader}</Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={{ flexWrap: 'nowrap' }}
            >
              <Grid item>
                <StyledSearchInput
                  placeholder="Search.."
                  onChange={handleInputChange}
                  value={myQuery}
                  classes={outlinedInputClasses}
                  startAdornment={
                    <img
                      src="assets/icons/search.svg"
                      alt="system added"
                      style={{ width: '14px', height: '14px' }}
                    />
                  }
                  endAdornment={
                    <IconButton
                      onClick={cancelSearch}
                      edge="end"
                      style={{ margin: 0, padding: 0 }}
                    >
                      <img src="assets/icons/close.svg" alt="system added" />
                    </IconButton>
                  }
                />
              </Grid>

              <Divider
                flexItem
                orientation="vertical"
                style={{ margin: '14px 0px 14px 14px', color: '#ECEEF2' }}
              />
              {customToolbarButton}
              {!customToolbarButton && onToolbarButtonClick && (
                <Grid item>
                  <StyledWMButton
                    iconComponent={
                      <img alt={'more'} src={`/assets/icons/Icon-Plus.svg`} />
                    }
                    isIconButton
                    onClick={onToolbarButtonClick}
                    tooltipProps={buttonTooltipProps}
                  >
                    {buttonText}
                  </StyledWMButton>
                </Grid>
              )}
              {menuButton && (
                <WMMenu
                  autoFocus={false}
                  menuItems={menuItems()}
                  menuButtonClassName={classes.menuButton}
                  menuButtonIconComponent={
                    <img
                      alt={'more'}
                      src={`/assets/icons/icon-moreVertical.svg`}
                      style={{ backgroundColor: 'transparent' }}
                    />
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </StyledHeaderDiv>
      <WMDivider />
      <StyledPaper>
        {loading ? (
          <LoadingContainer>
            <CircularProgress size={50} thickness={4} />
          </LoadingContainer>
        ) : (
          <StyledTableContainer>
            <StyledTable
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <Head
                order={
                  serverSideRender
                    ? serverSidePropsRenderProps.currentOrder
                    : currentOrder
                }
                orderBy={
                  serverSideRender
                    ? serverSidePropsRenderProps.currentOrderBy
                    : currentOrderBy
                }
                onRequestSort={handleRequestSort}
                rowCount={filteredRows.length}
                heads={heads}
                rowsSelectable={rowsSelectable}
                rowsSelectableDisabled={rowsSelectableDisabled}
                indeterminateSelectedRows={indeterminateSelectedRows}
                allRowsSelected={allRowsSelected}
                onAllRowsToggled={handleAllRowsSelected}
              />
              {!loading && filteredRows.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ borderBottom: '1px solid #F5F6F8' }}
                    >
                      <EmptySearch text={emptySerchText} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {slicedSortedRows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        className="data-row"
                        hover
                        selected={selectedRows[getRowId(row)] || false}
                        classes={{
                          hover: classes.rowHover,
                          selected: classes.rowSelected,
                        }}
                        style={{ height: '40px' }}
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        {rowsSelectable && (
                          <StyledCheckboxCell id="checkItems" scope="row">
                            <Checkbox
                              checked={selectedRows[getRowId(row)] || false}
                              onChange={() => handleRowSelected(row)}
                              disabled={rowsSelectableDisabled}
                            />
                          </StyledCheckboxCell>
                        )}
                        {heads.map((head) => (
                          <Cell
                            key={head.id}
                            id={labelId}
                            scope="row"
                            onClick={() => handleItemClick(row)}
                          >
                            {getCellDisplayValue(row[head.id])}
                          </Cell>
                        ))}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <Cell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              )}
              <Footer
                rowsPerPageOptions={rowsPerPageOptions}
                rowsLength={
                  serverSideRender
                    ? serverSidePropsRenderProps.jobsCount
                    : filteredRows.length
                }
                colsNumber={heads.length + (rowsSelectable ? 1 : 0)}
                rowsPerPage={
                  serverSideRender
                    ? serverSidePropsRenderProps.rowsPerPage
                    : rowsPerPage
                }
                currentPage={updatedPage}
                onChangePage={
                  serverSideRender
                    ? serverSidePropsRenderProps.handleChangePage
                    : handleChangePage
                }
                onChangeRowsPerPage={
                  serverSideRender
                    ? serverSidePropsRenderProps.onChangeRowsPerPage
                    : handleChangeRowsPerPage
                }
                buttons={footerButtons}
                message={footerMessage}
                serverSideRender={serverSideRender}
                {...serverSidePropsRenderProps}
              />
            </StyledTable>
          </StyledTableContainer>
        )}
      </StyledPaper>
    </StyledContainer>
  );
};

DataTable.defaultProps = {
  data: [],
  buttonText: '',
  title: '',
  hasToolbar: false,
  config: {},
  rowsPerPageOptions: [],
  rowsPerPage: 10,
  currentPage: 0,
  rowsSelectable: false,
  showFooter: false,
  footerButtons: [],
  query: '',
};
