import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFeatureFlags,
  FeatureTogglesState,
} from '../redux/feature-toggles.slice';

export const useFeatureToggles = (forceLoad = false) => {
  const featureTogglesAppData = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.allFeatureFlags
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeatureFlags(forceLoad));
  }, [dispatch]);

  return featureTogglesAppData;
};

export default useFeatureToggles;
