import { Button } from '@walkme/ui-core';
import React from 'react';

export const MergeAccountButton = ({ onClick, disabled }) => {
  return (
    <Button color="danger" onClick={onClick} size="large" disabled={disabled}>
      Merge Account
    </Button>
  );
};

export const NextButton = ({ onClick, disabled }) => {
  return (
    <Button onClick={onClick} size="large" disabled={disabled}>
      Next
    </Button>
  );
};

export const BackButton = ({ onClick, disabled }) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      size="large"
    >
      Back
    </Button>
  );
};

export const CancelButton = ({ onClick }) => {
  return (
    <Button variant="outlined" onClick={onClick} size="large">
      Cancel
    </Button>
  );
};
