import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  padding: 60px;
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  justify-content: center;
`;

const StyledTitleTypography = styled(Typography)`
  color: #303d53;
  font-size: 14px;
  font-weight: 600;
  font-family: 'ProximaNova';
  text-align: center;
`;

const StyledSubTitleTypography = styled(Typography)`
  color: #303d53;
  font-size: 14px;
  font-family: 'ProximaNova';
  text-align: center;
`;

interface EmptySearchProps {
  title?: string;
  text?: string;
}

export const EmptySearch = ({
  title = 'Nothing’s here',
  text = 'No accounts found in the current search',
}: EmptySearchProps) => {
  return (
    <StyledBox>
      <Box>
        <Box mb={4} display="flex" justifyContent="center">
          <img src="/assets/no-results-empty-state.svg" />
        </Box>
        <StyledTitleTypography>{title}</StyledTitleTypography>
        <StyledSubTitleTypography>{text}</StyledSubTitleTypography>
      </Box>
    </StyledBox>
  );
};

export default EmptySearch;
