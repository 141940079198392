import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledActionMenuItem,
  StyledDateContainer,
  StyledTextWithTooltip,
  StyledPageContainer,
  StyledTypography,
  StyledWMDataGrid,
  StyledDataGridTitle,
  StyledActionContainer,
  StyledHeader,
  StyledActionButtons,
  StyledHeaderButton,
} from './feature-toggles-page.styles';
import useFeatureToggles from '../../../../state-management-feature-toggles/src/lib/hooks/use-get-feature-toggles';
import { Menu, MenuItemText, TagList, Typography } from '@walkme/ui-core';
import {
  Add,
  Users,
  ThreeDots,
  Edit,
  Archive,
  ClockTimer,
  Restore,
  Insights,
} from '@walkme/ui-icons';
import { Eye } from '@walkme/ui-icons/large';
import {
  WMButton,
  WMButtonVariant,
  WMDataGridWrapperProps,
  WMIconDownload,
  WMSnackbar,
  WMSwitch,
} from '@walkme/wm-ui';
import {
  featureTogglesSlice,
  FeatureTogglesState,
  FeatureFlag,
  updateFeatureFlagStatus,
  Status,
} from '../../../../state-management-feature-toggles/src';
import { FeatureFlagStateChangeDialog } from './feature-flag-state-change-dialog';
import { dateValueFormatter } from './feature-toggles-page.lib';
import { CreateEditFeatureToggleDialog } from './feature-toggle-dialog/create-edit-feature-toggle-dialog';
import { dateComparator } from '../../../../ui-components/src/lib/data-table/utils';
import { FeatureFlagDialogType } from './feature-toggle-dialog/create-edit-feature-toggle-dialog.lib';
import { HistoryDialog } from './feature-toggle-history/history-dialog';
import FileSaver from 'file-saver';
import moment from 'moment/moment';
import { PaginationPageSize } from '@walkme/wm-ui/components/WMDataGridFooter';
import { RowNode } from '@ag-grid-community/core';
import { CreateTeamDialog } from './create-team-dialog';
import { EvaluationsDialog } from './feature-toggle-evaluation/evaluations-dialog';
import { EvaluationChart } from './feature-toggle-evaluation/evaluation-chart';
import { ChartSize } from './feature-toggle-evaluation/evaluations.lib';

export const FeatureTogglesPage = () => {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState(null);
  const currentSnackbarMessage = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.notificationMessage
  );
  const featureFlagsAppData = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.allFeatureFlags
  );
  const isStateChangeDialogOpen = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.isStateChangeDialogOpen.data
  );
  const isCreateTeamDialogOpen = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.isCreateTeamDialogOpen.data
  );
  const isCreateEditFeatureFlagDialogOpen = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.isCreateEditFeatureFlagDialogOpen.data
  );
  const isHistoryDialogOpen = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.isHistoryDialogOpen.data
  );
  const isEvaluationsDialogOpen = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.isEvaluationsDialogOpen.data
  );
  const [selectedFeatureFlag, setSelectedFeatureFlag] =
    useState<FeatureFlag>(null);
  const [isShowArchived, setShowArchived] = useState<boolean>(false);

  const featuresFlags = useFeatureToggles();
  const featureFlagsRows = useMemo(() => {
    return featuresFlags.data;
  }, [featuresFlags]);

  const activeFeatureFlags = useMemo(() => {
    return featureFlagsRows?.filter((row) => row.status !== Status.archived);
  }, [featureFlagsRows, isShowArchived, Status]);

  const dateCellRenderer = (params) => {
    const [primary, secondary] = params.value.split(', ');
    return (
      <StyledDateContainer>
        <div className={'date'}>
          <span className={'date-primary'}>{primary}</span>
          <span className={'date-secondary'}>{secondary}</span>
        </div>
      </StyledDateContainer>
    );
  };

  const getActionColumn = (actions) => {
    return {
      colId: 'actions-column',
      width: 60,
      pinned: 'right',
      suppressSizeToFit: true,
      filter: false,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRenderer: (params) => {
        const actionsMenuRef = useRef<HTMLDivElement>(null);
        const [showActionMenuOpen, setShowActionMenuOpen] =
          useState<boolean>(false);

        return (
          <StyledActionContainer
            ref={actionsMenuRef}
            onClick={() => setShowActionMenuOpen(!showActionMenuOpen)}
          >
            <ThreeDots />
            <Menu
              open={showActionMenuOpen}
              onClose={() => setShowActionMenuOpen(false)}
              anchorEl={actionsMenuRef.current}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              {actions.map((action) => {
                return (
                  <StyledActionMenuItem
                    onClick={() => {
                      action.onClick(params.data);
                    }}
                  >
                    {typeof action.icon === 'function'
                      ? action.icon(params.data)
                      : action.icon}
                    <MenuItemText>
                      {typeof action.label === 'function'
                        ? action.label(params.data)
                        : action.label}
                    </MenuItemText>
                  </StyledActionMenuItem>
                );
              })}
            </Menu>
          </StyledActionContainer>
        );
      },
    };
  };

  const onExport = () => {
    const filteredRows = gridApi?.getModel().rowsToDisplay;

    if (!filteredRows) {
      console.error(
        'An issue occurred: Unable to access the grid API or no rows are available for export.'
      );
      return;
    }

    try {
      const dataToJson = filteredRows.reduce((acc, filteredRow: RowNode) => {
        acc[filteredRow.data.name] = filteredRow.data.isEnabled;
        return acc;
      }, {});

      const jsonData = JSON.stringify(dataToJson);
      const blob = new Blob([jsonData], { type: 'application/json' });
      FileSaver.saveAs(blob, `exported_FF_${moment().format('MM-DD-YY')}.json`);
    } catch (e) {
      console.error('An error occurred during feature flags export:', e);
    }
  };

  const headerActions = [
    <WMButton
      tooltipProps={{ title: 'Export Feature Flags' }}
      iconComponent={<WMIconDownload size={21} />}
      onClick={onExport}
      isIconButton
      variant={WMButtonVariant.Secondary}
    />,
    <WMButton
      tooltipProps={{
        title: isShowArchived ? 'Hide Archived' : 'Show Archived',
      }}
      iconComponent={<Eye />}
      onClick={() => setShowArchived(!isShowArchived)}
      isIconButton
      variant={
        isShowArchived ? WMButtonVariant.Primary : WMButtonVariant.Secondary
      }
    />,
  ];

  const actions = [
    {
      label: 'Edit',
      onClick: (featureFlag) => {
        setSelectedFeatureFlag(featureFlag);
        dispatch(
          featureTogglesSlice.actions.setCreateEditFeatureFlagDialogOpen(true)
        );
      },
      icon: <Edit />,
    },
    {
      label: (featureFlag) => {
        return featureFlag.status === Status.active ? 'Archive' : 'Restore';
      },
      onClick: (featureFlag) => {
        setSelectedFeatureFlag(featureFlag);
        const newStatus =
          featureFlag.status === Status.active
            ? Status.archived
            : Status.active;
        dispatch(
          updateFeatureFlagStatus(
            featureFlag.name,
            featureFlag.ownerTeam._id,
            newStatus
          )
        );
      },
      icon: (featureFlag) => {
        return featureFlag.status === Status.active ? <Archive /> : <Restore />;
      },
    },
    {
      label: 'History',
      onClick: (featureFlag) => {
        setSelectedFeatureFlag(featureFlag);
        dispatch(featureTogglesSlice.actions.setHistoryDialogOpen(true));
      },
      icon: <ClockTimer />,
    },
    {
      label: 'Evaluations',
      onClick: (featureFlag) => {
        setSelectedFeatureFlag(featureFlag);
        dispatch(featureTogglesSlice.actions.setEvaluationsDialogOpen(true));
      },
      icon: <Insights />,
    },
  ];

  const getHideState = (colId) => {
    try {
      const savedColumnState = JSON.parse(
        localStorage.getItem('featureTogglesColumnState')
      );
      const column: any = savedColumnState?.find((col) => col.colId === colId);
      return column?.hide;
    } catch (e) {
      console.error('Failed to parse JSON of featureTogglesColumnState', e);
    }
  };

  const headCells = useMemo(() => {
    return [
      {
        headerName: 'Name',
        field: 'name',
        colId: 'name',
        hide: getHideState('name') ?? false,
        minWidth: 200,
        sortable: true,
        cellRenderer: (params) => {
          return (
            <StyledTextWithTooltip isName>{params.value}</StyledTextWithTooltip>
          );
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        colId: 'description',
        hide: getHideState('description') ?? false,
        minWidth: 200,
        sortable: true,
        cellStyle: {
          color: '#2F426C',
        },
        cellRenderer: (params) => {
          return <StyledTextWithTooltip>{params.value}</StyledTextWithTooltip>;
        },
      },
      {
        headerName: 'Is Enabled',
        field: 'isEnabled',
        colId: 'isEnabled',
        hide: getHideState('isEnabled') ?? false,
        minWidth: 70,
        sortable: true,
        filter: 'agMultiColumnFilter',
        cellStyle: { display: 'flex', alignItems: 'center' },
        cellRenderer: (params) => {
          const isChecked = params.value;
          return (
            <div>
              <WMSwitch
                ds2
                checked={isChecked}
                onChange={() => {
                  onStateChange(params.data);
                }}
                label={<span>{isChecked ? 'On' : 'Off'}</span>}
              />
            </div>
          );
        },
      },
      {
        headerName: 'Entities',
        field: 'entities',
        colId: 'entities',
        hide: getHideState('entities') ?? true,
        minWidth: 350,
        sortable: true,
        cellStyle: { color: '#2F426C', display: 'flex' },
        filter: 'agTextColumnFilter',
        valueGetter: (params) => params?.data?.targetPopulation?.entities,
        cellRenderer: (params) => {
          if (params?.value) {
            return (
              <div style={{ display: 'flex' }}>
                <TagList
                  labels={params?.value?.map((entity: string) => ({
                    label: entity,
                  }))}
                  maxTagsShown={1}
                  showTooltip
                />
              </div>
            );
          } else {
            return null;
          }
        },
      },
      {
        headerName: 'Creation Date',
        field: 'createdAt',
        colId: 'createdAt',
        hide: getHideState('createdAt') ?? true,
        minWidth: 150,
        sortable: true,
        comparator: dateComparator('data.createdAt'),
        valueGetter: dateValueFormatter,
        cellRenderer: dateCellRenderer,
      },
      {
        headerName: 'Created By',
        field: 'createdBy.email',
        colId: 'createdBy',
        hide: getHideState('createdBy') ?? true,
        minWidth: 220,
        sortable: true,
        filter: 'agTextColumnFilter',
        cellStyle: {
          color: '#2F426C',
        },
        cellRenderer: (params) => {
          return (
            <StyledTextWithTooltip>
              {params.value ? params.value : params.data.createdBy}
            </StyledTextWithTooltip>
          );
        },
      },
      {
        headerName: 'Last Modified',
        field: 'updatedAt',
        colId: 'updatedAt',
        hide: getHideState('updatedAt') ?? true,
        minWidth: 150,
        sortable: true,
        sort: 'desc',
        comparator: dateComparator('data.updatedAt'),
        valueGetter: dateValueFormatter,
        cellRenderer: dateCellRenderer,
      },
      {
        headerName: 'Status',
        field: 'status',
        colId: 'status',
        hide: getHideState('status') ?? true,
        minWidth: 100,
        sortable: true,
        filter: 'agMultiColumnFilter',
        cellStyle: {
          color: '#2F426C',
        },
        valueFormatter: (params) => {
          const value = params.value ?? '';
          return value.charAt(0).toUpperCase() + value.slice(1);
        },
      },
      {
        headerName: 'Team',
        field: 'ownerTeam.name',
        colId: 'ownerTeam',
        hide: getHideState('ownerTeam') ?? false,
        minWidth: 150,
        sortable: true,
        filter: 'agMultiColumnFilter',
        cellStyle: {
          color: '#2F426C',
        },
      },
      {
        headerName: 'Evaluation',
        field: 'evaluation',
        colId: 'evaluation',
        hide: getHideState('evaluation') ?? false,
        minWidth: 400,
        cellStyle: { display: 'flex', lineHeight: 'normal' },
        cellRenderer: (params) => {
          if (params?.value) {
            return (
              <EvaluationChart
                evaluation={params.value}
                chartSize={ChartSize.SMALL}
              />
            );
          } else {
            return <Typography variant={'T20'}>No evaluations</Typography>;
          }
        },
      },
      getActionColumn(actions),
    ];
  }, [featureFlagsRows]);

  const pageSizeFromLocalStorage = useMemo(() => {
    try {
      const savedPageSize = JSON.parse(
        localStorage.getItem('featureTogglesPageSize')
      );
      return savedPageSize || PaginationPageSize.ROWS_MEDIUM;
    } catch (e) {
      console.error('Error parsing page size from local storage:', e);
      return PaginationPageSize.ROWS_MEDIUM;
    }
  }, []);

  const onGridReady = useCallback((event) => {
    setGridApi(event.api);

    try {
      const savedState = JSON.parse(
        localStorage.getItem('featureTogglesColumnState')
      );
      if (savedState) {
        event.api?.columnModel?.applyColumnState(savedState);
      }

      const savedFilterState = localStorage.getItem(
        'featureTogglesFilterState'
      );
      if (savedFilterState) {
        event.api?.setFilterModel(JSON.parse(savedFilterState));
      }

      event.api.paginationSetPageSize(pageSizeFromLocalStorage);
    } catch (e) {
      console.error(
        'Failed to parse JSON when getting feature toggles grid state from local storage for onGridReady',
        e
      );
    }
  }, []);

  const onColumnVisible = useCallback((event) => {
    const columnState = event.api?.columnModel?.getColumnState();
    localStorage.setItem(
      'featureTogglesColumnState',
      JSON.stringify(columnState)
    );
    event.api.sizeColumnsToFit();
  }, []);

  const onFilterChanged = useCallback((event) => {
    if (event?.api) {
      const filterState = event.api?.getFilterModel();
      localStorage.setItem(
        'featureTogglesFilterState',
        JSON.stringify(filterState)
      );
    }
  }, []);

  const onPaginationChanged = useCallback((event) => {
    if (event?.api) {
      const pageSize = event?.api.paginationGetPageSize();
      localStorage.setItem('featureTogglesPageSize', JSON.stringify(pageSize));
    }
  }, []);

  const gridConfig: WMDataGridWrapperProps<any> = useMemo(() => {
    return {
      onColumnVisible: onColumnVisible,
      onFirstDataRendered: onColumnVisible,
      onGridReady: onGridReady,
      onFilterChanged: onFilterChanged,
      onPaginationChanged: onPaginationChanged,
    };
  }, []);

  const onCreateFeatureFlag = () => {
    setSelectedFeatureFlag(null);
    dispatch(
      featureTogglesSlice.actions.setCreateEditFeatureFlagDialogOpen(true)
    );
  };

  const onCreateTeam = () => {
    dispatch(featureTogglesSlice.actions.setCreateTeamDialogOpen(true));
  };

  const onStateChange = (featureFlag) => {
    setSelectedFeatureFlag(featureFlag);
    dispatch(featureTogglesSlice.actions.setStateChangeDialogOpen(true));
  };

  const handleSnackBarClose = () => {
    dispatch(featureTogglesSlice.actions.cleanUpNotificationMessage());
  };

  const applyFilterState = () => {
    try {
      if (gridApi && featureFlagsRows) {
        const savedFilterModel = localStorage.getItem(
          'featureTogglesFilterState'
        );
        if (savedFilterModel) {
          const filterModel = JSON.parse(savedFilterModel);
          gridApi.setFilterModel(filterModel);
        }
      }
    } catch (e) {
      console.error(
        'Failed to parse and apply JSON of featureTogglesFilterState',
        e
      );
    }
  };

  useEffect(applyFilterState, [featureFlagsRows, gridApi]);

  const title = () => {
    return (
      <StyledDataGridTitle>
        <label className={'title'}>All Feature Flags</label>
        <label className={'sub-title'}>
          View and manage feature flags. The 'Team' column enables you to filter
          these flags by team when hovered over.
        </label>
      </StyledDataGridTitle>
    );
  };

  return (
    <StyledPageContainer>
      <StyledHeader>
        <StyledTypography variant={'T20-2'}>
          Feature Toggle Management
        </StyledTypography>
        <StyledActionButtons>
          <StyledHeaderButton startIcon={<Users />} onClick={onCreateTeam}>
            Create New Team
          </StyledHeaderButton>
          <StyledHeaderButton startIcon={<Add />} onClick={onCreateFeatureFlag}>
            Create Feature Flag
          </StyledHeaderButton>
        </StyledActionButtons>
      </StyledHeader>
      <StyledWMDataGrid
        title={title()}
        entityName={'Feature Toggle'}
        loadingData={featureFlagsAppData.loading}
        columnDefs={headCells}
        rows={isShowArchived ? featureFlagsRows : activeFeatureFlags}
        supressResetOnNewData={true}
        hideExport
        headerActions={headerActions}
        defaultPageSize={pageSizeFromLocalStorage}
        gridConfig={gridConfig}
      />
      {isStateChangeDialogOpen && (
        <FeatureFlagStateChangeDialog
          isOpen={true}
          name={selectedFeatureFlag?.name}
          ownerTeam={selectedFeatureFlag?.ownerTeam?._id}
          state={selectedFeatureFlag?.isEnabled}
          unsetSelectedFeatureFlag={() => setSelectedFeatureFlag(null)}
        />
      )}
      {isCreateTeamDialogOpen && <CreateTeamDialog isOpen={true} />}
      {isCreateEditFeatureFlagDialogOpen && (
        <CreateEditFeatureToggleDialog
          isOpen={true}
          dialogType={
            selectedFeatureFlag
              ? FeatureFlagDialogType.EDIT
              : FeatureFlagDialogType.CREATE
          }
          data={selectedFeatureFlag}
          unsetSelectedFeatureFlag={() => setSelectedFeatureFlag(null)}
        />
      )}
      {isHistoryDialogOpen && (
        <HistoryDialog
          isOpen={true}
          activeFeatureFlag={selectedFeatureFlag}
          unsetSelectedFeatureFlag={() => setSelectedFeatureFlag(null)}
        />
      )}
      {isEvaluationsDialogOpen && (
        <EvaluationsDialog
          isOpen={true}
          activeFeatureFlag={selectedFeatureFlag}
          unsetSelectedFeatureFlag={() => setSelectedFeatureFlag(null)}
        />
      )}
      <WMSnackbar
        open={currentSnackbarMessage.isOpen}
        onClose={handleSnackBarClose}
        variant={currentSnackbarMessage.variant}
        message={currentSnackbarMessage.text}
      />
    </StyledPageContainer>
  );
};
