import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronTop, ChevronBottom } from '@walkme/ui-icons/large';
import { Typography } from '@walkme/ui-core';

export interface CollapsableItem {
  header: JSX.Element;
  content: JSX.Element;
  height?: string;
}
export interface AccountsCollapseProps {
  collapsableItems: CollapsableItem[];
  defaultOpenIndex?: number;
}

const StyledAccountsCollapse = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledCollapse = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #dde5f5;
`;

const StyledCollapseHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 14px 14px 14px 24px;
  gap: 12px;

  max-height: 56px;

  /* White */

  background: #ffffff;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export function SingleCollapse(
  item: CollapsableItem,
  openState = false,
  onClick: () => void
) {
  return (
    <StyledCollapse style={{ height: openState && (item.height ?? '100%') }}>
      <StyledCollapseHeader
        onClick={() => {
          onClick();
        }}
      >
        {item.header}
        <div>{openState ? <ChevronTop /> : <ChevronBottom />}</div>
      </StyledCollapseHeader>
      {openState && (
        <div
          style={{
            borderTop: ' 1px solid #E4E9FC',
            height: 'calc(100% - 60px)',
          }}
        >
          {item.content}
        </div>
      )}
    </StyledCollapse>
  );
}

export function AccountsCollapse(props: AccountsCollapseProps) {
  const [openIndex, setOpenIndex] = useState<number | null>(
    props.defaultOpenIndex + 1 &&
      props.defaultOpenIndex < props.collapsableItems.length
      ? props.defaultOpenIndex
      : null
  );

  const handleItemClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <StyledAccountsCollapse>
      {props.collapsableItems.map((item, index) => {
        return SingleCollapse(item, openIndex === index, () =>
          handleItemClick(index)
        );
      })}
    </StyledAccountsCollapse>
  );
}

export default AccountsCollapse;
