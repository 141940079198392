import React from 'react';
import {
  Menu,
  MenuItem,
  MenuItemStartIcon,
  MenuList,
  MenuItemText,
  Divider,
  DropdownSelection,
  InputSearch,
  Tooltip,
} from '@walkme/ui-core';
import { useState, MouseEvent, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { System, systemTypes } from 'wm-accounts-sdk';

const StyledActionMenuLongList = styled(MenuList)`
  max-height: 150px;
`;

const StyledTitle = styled(MenuItemText)`
  padding: 12px 16px 0 16px;
  font-weight: 600;
`;

const StyledDivider = styled(Divider)`
  margin: 0 16px;
`;

const StyledSelector = styled(DropdownSelection)`
  width: 220px;
  overflow: hidden;
  button {
    height: 32px;
    margin: 0;
  }
`;

const StyledNoResults = styled.div`
  padding: 0 16px 16px 16px;
  display: flex;
  justify-content: center;
`;

type Item = {
  label: string;
  value: string;
} | null;

export interface DropdownSelectionMenuDefaultFeaturesProps {
  systemTypeOptions: systemTypes[];
  onChange: (newSystemType) => void;
  currentSelection?: Item;
}

export const DropdownSelectionMenuDefaultFeatures = ({
  systemTypeOptions,
  onChange,
  currentSelection,
}: DropdownSelectionMenuDefaultFeaturesProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const items = useMemo<Item[]>(
    () =>
      systemTypeOptions.map((option) => {
        return {
          label: option.displayName,
          value: option.key,
        };
      }),
    [systemTypeOptions]
  );
  const [selected, setSelected] = useState<Item>(currentSelection || items[0]);
  const [filteredItems, setFilteredItems] = useState(items);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: MouseEvent, item: Item) => {
    setSelected(item);
    onChange(item);
    handleClose();
  };
  return (
    <Tooltip title={selected?.label}>
      <div>
        <StyledSelector
          handleOpen={handleOpen}
          displayValue={
            selected?.label ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MenuItemText>{selected?.label}</MenuItemText>
              </div>
            ) : (
              <p>Please select ...</p>
            )
          }
          anchorEl={anchorEl}
        />
        <Menu
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
          width={250}
        >
          {!filteredItems?.length ? (
            <StyledNoResults>
              <MenuItemText>No Options</MenuItemText>
            </StyledNoResults>
          ) : (
            <div>
              <StyledDivider />
              <StyledActionMenuLongList>
                {filteredItems.map((item) => {
                  const isSelected = selected?.value === item.value;
                  return (
                    <MenuItem
                      key={item.value}
                      onClick={(e) => handleClick(e, item)}
                      selected={isSelected}
                      checkMark
                    >
                      <MenuItemText selected={isSelected}>
                        {item.label}
                      </MenuItemText>
                    </MenuItem>
                  );
                })}
              </StyledActionMenuLongList>
            </div>
          )}
        </Menu>
      </div>
    </Tooltip>
  );
};
