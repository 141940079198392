import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateFeatureFlag from './add-feature-flag-modal';
import {
  WMDataGrid,
  WMIconSettings,
  WMSnackbar,
  WMSnackbarVariant,
} from '@walkme/wm-ui';
import {
  addFeatures,
  deleteFeature,
  deleteFeaturesCleanup,
  EntityFeatureFlag,
  FeatureFlag,
  featureSlice,
  FeaturesState,
  getAllFeatures,
  updateFeature,
  useGetAllFeatures,
  useGetFeaturesTypes,
} from '@wm-accounts-backoffice-center/state-management-features';
import { useDispatch, useSelector } from 'react-redux';
import { FeatureFlagType } from '@wm-accounts-backoffice-center/wm-api';
import useTable from '../../../../ui-components/src/lib/data-table/use-table';
import { FeatureRow } from '../../../../general-types/src/lib/systemFeature';
import moment from 'moment';
import DetailedFF from './detailed-ff';
import FileSaver from 'file-saver';
import { AppData } from '@wm-accounts-backoffice-center/general-types';
import { IconButton, Tooltip, Typography } from '@walkme/ui-core';
import { Add, Export } from '@walkme/ui-icons';
import {
  StyledWMDataGrid,
  StyledDrawer,
  useStyles,
} from './feature-flags-page.styles';

export interface FeatureFlagsPageProps {}

export const getFeaturesRows = (
  features: FeatureFlag[],
  featureFlagTypesData: FeatureFlagType[]
): FeatureRow[] => {
  if (!features && !features.length) {
    return [];
  }
  return features.map((feature) => {
    const { id } = feature;
    const description =
      feature.description && feature.description.description
        ? feature.description.description
        : '';
    const modifiedBy = feature.description ? feature.description.email : '';
    const lastModified =
      feature.description && feature.description.updatedAt
        ? moment(feature.description.updatedAt).format('MMM Do YYYY')
        : '';
    const featureName = feature.flagName;
    const featureType = featureFlagTypesData.find(
      (featureTypeData) => featureTypeData.value === feature.type
    );
    const featureTypeDisplayName = featureType
      ? featureType.displayName
      : feature.type;
    return {
      id,
      description,
      modifiedBy,
      lastModified,
      type: featureTypeDisplayName,
      featureName,
    };
  });
};

export function FeatureFlagsPage(props: FeatureFlagsPageProps) {
  const classes = useStyles();
  const tableSettings = useTable();
  const [showDialog, setShowDialog] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedFF, setSelectedFF] = useState<FeatureFlag>(null);
  const [cleanupRowsSelection, setCleanupRowsSelection] = useState(false);

  const { featureFlagTypesData } = useGetFeaturesTypes();
  const addFFStatus = useSelector(
    (state: { featuresState: FeaturesState }) => state.featuresState.addFeature
  );
  const deleteFFStatus = useSelector(
    (state: { featuresState: FeaturesState }) =>
      state.featuresState.deleteFeature
  );
  const updateFFStatus = useSelector(
    (state: { featuresState: FeaturesState }) =>
      state.featuresState.updateFeature
  );

  const currentSnackBarMessage = useSelector(
    (state: { featuresState: FeaturesState }) =>
      state.featuresState.snackBarMessage
  );

  const { allFeaturesAppData } = useGetAllFeatures();
  const allFeatures = useMemo(
    () => getFeaturesRows(allFeaturesAppData.data, featureFlagTypesData.data),
    [allFeaturesAppData.data, featureFlagTypesData]
  );
  const dispatch = useDispatch();

  const exitDialog = useCallback(() => {
    setShowDialog(false);
  }, []);

  const exitDrawer = useCallback(() => setShowDrawer(false), []);

  useEffect(
    () => () => {
      dispatch(deleteFeaturesCleanup());
    },
    []
  );

  useEffect(() => {
    setCleanupRowsSelection(false);
    if (addFFStatus.data) {
      setCleanupRowsSelection(true);
      setShowDialog(false);
      dispatch(featureSlice.actions.setAddFeaturesCleanup());
      dispatch(getAllFeatures(true));
      dispatch(
        featureSlice.actions.setSnackBarMessage({
          text: `${addFFStatus.data.length} Features added`,
          variant: WMSnackbarVariant.Success,
          isOpen: true,
        })
      );
    }
  }, [addFFStatus]);

  useEffect(() => {
    setCleanupRowsSelection(false);
    if (deleteFFStatus.data) {
      setCleanupRowsSelection(true);
      setShowDrawer(false);
      dispatch(featureSlice.actions.setDeleteFeaturesCleanup());
      dispatch(getAllFeatures(true));
      dispatch(
        featureSlice.actions.setSnackBarMessage({
          text: `${deleteFFStatus.data.length} Features deleted`,
          variant: WMSnackbarVariant.Success,
          isOpen: true,
        })
      );
    }
  }, [deleteFFStatus]);

  useEffect(() => {
    setCleanupRowsSelection(false);
    if (updateFFStatus.data && updateFFStatus.data.flagName) {
      setCleanupRowsSelection(true);
      setShowDrawer(false);
      dispatch(featureSlice.actions.setUpdateFeatureCleanup());
      dispatch(getAllFeatures(true));
      dispatch(
        featureSlice.actions.setSnackBarMessage({
          text: `1 Feature Flag has been updated`,
          variant: WMSnackbarVariant.Success,
          isOpen: true,
        })
      );
    }
  }, [updateFFStatus]);

  const handleEdit = (
    featureFlagName: string,
    description: string,
    type: string
  ) => {
    dispatch(updateFeature(featureFlagName, description, type));
  };

  const triggerFFDetails = useCallback(() => {
    return (
      <StyledDrawer
        anchor={'right'}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <DetailedFF
          selectedFF={selectedFF}
          featureFlagTypes={featureFlagTypesData.data || []}
          exit={exitDrawer}
          onDelete={deleteOneFF}
          onEdit={handleEdit}
        />
      </StyledDrawer>
    );
  }, [showDrawer]);

  const handleFFSelection = useCallback(
    (event) => {
      const ff = allFeaturesAppData.data.find((ff) => ff.id == event.data.id);
      setSelectedFF(ff);
      setShowDrawer(true);
    },
    [allFeaturesAppData]
  );

  const headCells = useMemo(() => {
    const cells = [
      {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerName: 'Feature Name',
        field: 'featureName',
        lockVisible: true,
        pinned: true,
        minWidth: 300,
        sortable: true,
      },
      {
        headerName: 'Description',
        field: 'description',
        minWidth: 300,
        sortable: true,
      },
      {
        headerName: 'Last Modified',
        field: 'lastModified',
        minWidth: 300,
        sortable: true,
      },
      {
        headerName: 'Modified By',
        field: 'modifiedBy',
        minWidth: 300,
        sortable: true,
      },
      {
        headerName: 'Type',
        field: 'type',
        minWidth: 300,
        sortable: true,
      },
    ];

    return cells;
  }, []);

  const createNewFFDialog = useCallback(() => {
    setShowDialog(true);
  }, []);

  const deleteOneFF = useCallback(
    (ffToDelete) => {
      ffToDelete.length == 1
        ? dispatch(deleteFeature(ffToDelete[0]))
        : dispatch(deleteFeature(ffToDelete));
    },
    [dispatch]
  );

  const completeDelete = (selectedRows) => {
    for (const ffToDelete of selectedRows) {
      deleteOneFF(ffToDelete.featureName);
    }
  };

  const exportData = (selectedRows) => {
    const selectedRowsFFNames = selectedRows.map((row) => row.featureName);
    const blob = new Blob([selectedRowsFFNames.join(',')]);
    FileSaver.saveAs(blob, `exported_FF_${moment().format('MM-DD-YY')}.txt`);
  };

  const createNewFF = useCallback(
    (featureFlagName: string, description: string, featureType: string) => {
      dispatch(addFeatures(featureFlagName, description, featureType));
    },
    [dispatch]
  );

  const handleSnackBarErrorClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(featureSlice.actions.cleanupFeatureFlagPageErrors());
  };

  const headerActions = [
    <Tooltip title="Add feature">
      <IconButton onClick={createNewFFDialog}>
        <Add />
      </IconButton>
    </Tooltip>,
  ];

  const footerActions = () => {
    return [
      {
        onClick: (rows) => {
          exportData(rows);
        },
        icon: <Export />,
        title: '',
        className: '',
      },
      {
        onClick: (rows) => {
          completeDelete(rows);
        },
        title: 'Delete Features',
        icon: null,
        className: classes.DisableButton,
        loading: deleteFFStatus.loading,
        disabled: deleteFFStatus.loading,
      },
    ];
  };

  return (
    <div style={{ height: '61vh' }}>
      <Typography className={classes.title} variant="H10">
        All Features
      </Typography>

      {showDialog && (
        <CreateFeatureFlag
          showDialog={showDialog}
          exit={exitDialog}
          createNewFF={createNewFF}
          loading={addFFStatus.loading}
        />
      )}

      {showDrawer && triggerFFDetails()}

      <StyledWMDataGrid
        title={''}
        columnDefs={headCells}
        rows={allFeatures}
        entityName={'Features'}
        supressResetOnNewData={true}
        clearRowSelection={cleanupRowsSelection}
        hideColumnPicker
        hideExport
        headerActions={headerActions}
        footerActions={footerActions()}
        gridConfig={{
          onCellClicked: (event) => handleFFSelection(event),
        }}
      />

      <WMSnackbar
        open={currentSnackBarMessage.isOpen}
        onClose={handleSnackBarErrorClose}
        variant={currentSnackBarMessage.variant}
        message={currentSnackBarMessage.text}
      />
    </div>
  );
}

export default FeatureFlagsPage;
