import { EntityFeatureFlag } from '@wm-accounts-backoffice-center/state-management-features';
import { FeatureFlagType } from './types';
import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';
class FeaturesApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  errorParser(e) {
    return e.response?.data?.errors?.[0]?.message || e;
  }

  async countFeatureFlagActivations(featureFlagName) {
    try {
      const response = await this.httpClient.instance.get(
        `/featuresmanagement/featureFlags/countActivations?featureFlagName=${featureFlagName}`
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to count feature flag activations: ${e.message}`);
    }
  }

  async addNoteToFeatureFlag(featureFlagName, note) {
    try {
      const response = await this.httpClient.instance.post(
        '/featuresmanagement/featureFlags/addNoteToFeatureFlag',
        {
          featureFlagName,
          note,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to add note to feature flag: ${e.message}`);
    }
  }

  async addFeatureFlagWithDescription(
    featureFlagName: string,
    description: string,
    type: string
  ) {
    try {
      const response = await this.httpClient.instance.post(
        '/featuresmanagement/featureFlags/addFeatureFlagWithDescription',
        {
          featureFlagName,
          description,
          type,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      if (e.response?.data?.error) {
        throw new Error(
          `failed to add feature flag: ${e.response?.data?.error}`
        );
      }
      throw new Error(`failed to add feature flag: ${e.message}`);
    }
  }

  async updateFeatureFlag(
    featureFlagName: string,
    description: string,
    type: string
  ) {
    try {
      const response = await this.httpClient.instance.post(
        '/featuresmanagement/featureFlags/updateFeatureFlag',
        {
          featureFlagName,
          description,
          type,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      if (e.response?.data?.error) {
        throw new Error(
          `failed to edit feature flag: ${e.response?.data?.error}`
        );
      }
      throw new Error(`failed to edit feature flag: ${e.message}`);
    }
  }

  async removeFeatureFlagNamesFromSystems(
    accountId: number,
    systemIds: number[],
    featuresFlagNames: string[]
  ): Promise<EntityFeatureFlag[]> {
    try {
      const response = await this.httpClient.instance.post(
        '/featuresmanagement/entityFeature/deactivateFeaturesForSystems',
        {
          accountId,
          systemIds,
          featuresFlagNames,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data?.status;
    } catch (e) {
      if (e.response?.data?.error) {
        throw new Error(
          `failed to add feature flag: ${e.response?.data?.error}`
        );
      }
      throw new Error(`failed to add feature flag: ${e.message}`);
    }
  }

  async addFeatureFlagNamesForSystems(
    accountId,
    systemIds,
    featuresFlagNames
  ): Promise<EntityFeatureFlag[]> {
    try {
      const response = await this.httpClient.instance.post(
        '/featuresmanagement/entityFeature/activateFeaturesForSystems',
        {
          accountId,
          systemIds,
          featuresFlagNames,
        }
      );

      if (response.data.error) {
        throw new ToggleFlagError(
          JSON.stringify(response.data.error),
          featuresFlagNames,
          systemIds,
          accountId
        );
      }

      return response.data?.status;
    } catch (e) {
      if (e.response?.data?.error) {
        throw new ToggleFlagError(
          `failed to add feature flag: ${e.response?.data?.error}`,
          featuresFlagNames,
          systemIds,
          accountId
        );
      }
      throw new ToggleFlagError(
        `failed to add feature flag: ${e.message}`,
        featuresFlagNames,
        systemIds,
        accountId
      );
    }
  }

  async addFeatureFlagNamesForAccount(
    accountId,
    featuresFlagNames
  ): Promise<EntityFeatureFlag[]> {
    try {
      const response = await this.httpClient.instance.post(
        '/featuresmanagement/entityFeature/activateFeaturesForAccount',
        {
          accountId,
          featuresFlagNames,
        }
      );

      if (response.data.error) {
        throw new ToggleFlagError(
          JSON.stringify(response.data.error),
          featuresFlagNames,
          [],
          accountId
        );
      }

      return response.data?.status;
    } catch (e) {
      if (e.response?.data?.error) {
        throw new ToggleFlagError(
          `failed to add feature flag: ${e.response?.data?.error}`,
          featuresFlagNames,
          [],
          accountId
        );
      }
      throw new ToggleFlagError(
        `failed to add feature flag: ${e.message}`,
        featuresFlagNames,
        [],
        accountId
      );
    }
  }

  async removeFeatureFlagNamesFromAccount(
    accountId: number,
    featuresFlagNames: string[]
  ): Promise<EntityFeatureFlag[]> {
    try {
      const response = await this.httpClient.instance.post(
        '/featuresmanagement/entityFeature/deactivateFeaturesForAccount',
        {
          accountId,
          featuresFlagNames,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data?.status;
    } catch (e) {
      if (e.response?.data?.error) {
        throw new Error(
          `failed to add feature flag: ${e.response?.data?.error}`
        );
      }
      throw new Error(`failed to add feature flag: ${e.message}`);
    }
  }

  async getEntityFeatureFlags(entityId) {
    try {
      const response = await this.httpClient.instance.get(
        `/featuresmanagement/featureFlags/getEntityFeatureFlags?entityId=${entityId}`
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      throw new Error(`failed to get entity feature flags: ${e.message}`);
    }
  }

  async getAllFeatureFlags() {
    try {
      const response = await this.httpClient.instance.get(
        '/featuresmanagement/featureFlags/getAllFeatureFlags'
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      const error = this.errorParser(e);

      throw new Error(`failed to query feature flags: ${error}`);
    }
  }

  async getEntityFeatures(systemId: number) {
    try {
      const response = await this.httpClient.instance.get(
        `/featuresmanagement/featureFlags/getEntityFeatureFlags?entityId=${systemId}`
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      const error = this.errorParser(e);

      new Error(`failed to query ${systemId} feature flags: ${error}`);
    }
  }

  async removeFeatureFromEntity(
    entityIds: number[],
    featuresFlagNames: string[]
  ) {
    try {
      const response = await this.httpClient.instance.post(
        `/featuresmanagement/entityFeature/removeFeatureFlagNamesFromEntities`,
        {
          entityIds,
          featuresFlagNames,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      const error = this.errorParser(e);

      new Error(
        `failed to remove feature flags: ${featuresFlagNames.toString()} from ${entityIds.toString()}: ${error}}`
      );
    }
  }

  async deleteFeature(featureFlagName: string) {
    try {
      const response = await this.httpClient.instance.post(
        `/featuresmanagement/featureFlags/deleteFeatureFlag`,
        {
          featureFlagName,
        }
      );

      if (response.data.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      const error = this.errorParser(e);

      throw new Error(
        `failed to remove feature flags: ${featureFlagName.toString()} : ${
          e.response.data.error
        }}`
      );
    }
  }

  async getFeaturesTypes(): Promise<FeatureFlagType[]> {
    try {
      const response = await this.httpClient.instance.get<FeatureFlagType[]>(
        `/featuresmanagement/featureFlags/featureFlagsTypesOptions`
      );
      return response.data;
    } catch (e) {
      const error = this.errorParser(e);

      new Error(`failed to get featue types: ${error}`);
    }
  }
}

export class ToggleFlagError extends Error {
  featuresFlagNames: string[];
  entityIds: number[];
  accountId: number;

  constructor(
    message: string,
    featuresFlagNames: string[],
    entityIds: number[],
    accountId: number
  ) {
    super(message);
    this.name = 'ToggleFlagError';
    this.featuresFlagNames = featuresFlagNames;
    this.entityIds = entityIds;
    this.accountId = accountId;
    Object.setPrototypeOf(this, ToggleFlagError.prototype);
  }
}

export const featuresApi = new FeaturesApi(wmhttpClientSharedInstance);
