import {
  AccountType,
  Role,
  System,
  WMAccountIdp,
  WMUser,
} from 'wm-accounts-sdk';

export class MergeAccountToOtherAccountDTO {
  fromAccountGuid: string;
  toAccountGuid: string;
}

export class MergeAccountToOtherAccountExtraDetailsDTO {
  excludeSystems?: string[];
  excludeUsers?: string[];
}

export interface MergeAccountRequestDto {
  fromAccountGuid: string;
  toAccountGuid: string;
  excludedSystemsGuids: string[];
  excludedUsersGuids: string[];
}

export enum MergeAccountsActionStatus {
  success = 'success',
  failure = 'failure',
}

export interface MergeAccountsActionResult<T = any> {
  status: MergeAccountsActionStatus;
  value: T | Error;
}

export interface ExcludedSystemUnassignedUsers {
  system: Partial<System>;
  unassignedUsers: Partial<WMUser>[];
}

export interface ExcludedUserUnassignedSystems {
  user: Partial<WMUser>;
  unassignedSystems: Partial<System>[];
}

export interface RoleWithUsers {
  role?: Partial<Role>;
  users?: Partial<WMUser>[];
}

export type RolesToRecreate = Record<string, RoleWithUsers>;

export class MergeAccountsResult {
  fromAccountData: AccountForMerge;
  toAccountData: AccountForMerge;
  users: MergeAccountsActionResult<Partial<WMUser>[]>;
  apiKeys: MergeAccountsActionResult<string[]>;
  idps: MergeAccountsActionResult<WMAccountIdp[]>;
  roles: MergeAccountsActionResult<Partial<Role>[]>;
  systems: MergeAccountsActionResult<Partial<System>[]>;
  unassignExcludedSystems: MergeAccountsActionResult<
    ExcludedSystemUnassignedUsers[]
  >;
  unassignExcludedUsers: MergeAccountsActionResult<
    ExcludedUserUnassignedSystems[]
  >;
  recreatedRoles: MergeAccountsActionResult<RolesToRecreate>;
}

export class InitiatorAccount {
  id: number;
  name: string;
}

export class MergeAccountInitiator {
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  account: InitiatorAccount;
}

export class AccountForMerge {
  id: number;
  guid: string;
  name: string;
  email: string;
  sfId: string;
  type: AccountType[];
}

export class MergeAccountsProcessDto {
  _id: string;
  fromAccountId: number;
  toAccountId: number;
  excludedSystemsGuids: string[];
  excludedUsersGuids: string[];
  result: MergeAccountsResult;
  initiator: MergeAccountInitiator;
  createdAt: Date | string;
}

export enum GetMergeProcsStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export class GetMergeProcsResponse {
  status: GetMergeProcsStatus;
  procs: MergeAccountsProcessDto[];
  count: number;
  total: number;
  limit?: number;
  offset?: number;
  error?: Error;
}
