import { SystemTypeKey } from 'wm-accounts-sdk';
import { WMSnackbarVariant } from '@walkme/wm-ui';

export interface Features {}

export enum FeatureFlagTypeEntity {
  account = 'account',
  system = 'system',
}

export interface FeatureFlagType {
  value: string;
  displayName: string;
  strictForNewFeatures: boolean;
  entities: FeatureFlagTypeEntity[];
}

export interface DefaultSiteConfigOverrideFields {
  Id: number;
  AccountId: number;
  Platform: number;
  FieldName: string;
  Value: string;
  UpdatedAt: string;
  CreatedByUserId: number;
}

export interface DefaultSiteConfigOverrideSettingsFields {
  FieldName: string;
  JsonPath: string;
  EnabledValue: any;
  EnabledOn: number[];
}

export interface DefaultSiteConfigOverride {
  settings: DefaultSiteConfigOverrideSettings;
  siteConfigOverrides: DefaultSiteConfigOverrideFields[];
}

export interface DefaultSiteConfigOverrideSettings {
  AccountId: number;
  Fields: DefaultSiteConfigOverrideSettingsFields[];
  SystemTypeName: SystemTypeKey;
}

export const SiteConfigOverrideFields = [
  {
    fieldName: 'EndUserSettings',
    jsonPath: '$.CollectDataDisabled',
    enabledValue: true,
  },
  { fieldName: 'IsDisplayPoweredBy', jsonPath: '$', enabledValue: 'txt' },
  { fieldName: 'PoweredByLink', jsonPath: '$', enabledValue: true },
  {
    fieldName: 'EndUserSettings',
    jsonPath: '$.FallbackDisabled',
    enabledValue: true,
  },
  { fieldName: 'CustomCss', jsonPath: '$', enabledValue: '' },
  { fieldName: 'Custom', jsonPath: '$', enabledValue: '' },
];

export type SnackBarMessage = {
  text: string;
  variant:
    | WMSnackbarVariant.Success
    | WMSnackbarVariant.Error
    | WMSnackbarVariant.Warning
    | WMSnackbarVariant.Info;
  isOpen: boolean;
};
