import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { Box, Drawer, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  addFeatureToSystem,
  EntityFeatureFlag,
  FeatureFlag,
  removeFeatureFromSystem,
} from '@wm-accounts-backoffice-center/state-management-features';
import {
  Account,
  FeatureFlagType,
} from '@wm-accounts-backoffice-center/wm-api';
import DetailedFF from 'libs/pages/features-flag-page/src/lib/detailed-ff';
import { useStylesMainTabs } from '../styles/styles';
import { DropdownSelectionMenuWithSearch } from './DropdownSelectionMenuWithSearch';
import SystemPublishSettingsDialog from '../publish/system-publish-settings';
import { Environments, System } from 'wm-accounts-sdk';
import { AppData } from '@wm-accounts-backoffice-center/general-types';
import FeaturesTable from './features-table';
import { Button } from '@walkme/ui-core';

export interface SystemFeaturesProps {
  account: Account;
  selectedSystem: System;
  featuresForEntityType: FeatureFlag[];
  entityFeatureFlags: EntityFeatureFlag[];
  featureFlagTypes: FeatureFlagType[];
  isUpdatingFeatures: boolean;
  systemEnvironmentsData?: AppData<Environments>;
  allExcludedEnvironmentsForSystemData: {
    [key: string]: number[];
  };
  handleSystemChange: (newSystemId: number) => void;
  cleanupRowsSelection: boolean;
  loading: boolean;
}

const StyledDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background-color: rgba(47, 66, 108, 0.61);
  }
`;

export function SystemFeatures(pageProps: SystemFeaturesProps) {
  const classes = useStylesMainTabs();
  const [openPublishSettingsDialog, setOpenPublishSettingsDialog] =
    useState(false);

  const [showDrawer, setShowDrawer] = useState(false);

  const accountData = pageProps.account;

  const dispatch = useDispatch();

  const [selectedFF, setSelectedFF] = useState<FeatureFlag>(null);

  const accountSystems =
    pageProps.account?.systems?.filter(
      (system) =>
        !system.statusDetails || system.statusDetails.status === 'enabled'
    ) || [];
  const selectedSystem = pageProps.selectedSystem;
  const entityId = pageProps.selectedSystem?.id;

  const [selectedEntityFeatureFlag, setSelectedEntityFeatureFlag] =
    useState<EntityFeatureFlag>(null);

  const triggerFFDetails = useCallback(() => {
    return (
      <StyledDrawer
        anchor={'right'}
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
        }}
      >
        <DetailedFF
          featureFlagTypes={pageProps.featureFlagTypes || []}
          entityAssignedToFeature={selectedEntityFeatureFlag}
          selectedFF={selectedFF}
          exit={() => {
            setShowDrawer(false);
          }}
          onDelete={removeFFFromEntity}
          onEdit={() => {}}
        />
      </StyledDrawer>
    );
  }, [showDrawer, entityId, selectedFF]);

  const handleFFEnvironments = useCallback(
    (featureflag: FeatureFlag) => {
      const ff = pageProps.entityFeatureFlags?.find(
        (ff) => ff.FeatureId == featureflag.id
      );
      setSelectedFF(ff.featureFlag);
      setSelectedEntityFeatureFlag(ff);
      setShowDrawer(true);
    },
    [pageProps.entityFeatureFlags]
  );

  const removeFFFromEntity = useCallback(
    async (ffToRemove: string[]) => {
      dispatch(
        removeFeatureFromSystem(pageProps.account.id, entityId, ffToRemove)
      );
    },
    [entityId]
  );

  const addFFToEntity = useCallback(
    async (ffToAdd: string[]) => {
      dispatch(addFeatureToSystem(pageProps.account.id, entityId, ffToAdd));
    },
    [entityId]
  );

  const extraTableHeadersElements = [];
  if (window.clientConfig.NX_PUBLISH_SETTINGS === 'true') {
    extraTableHeadersElements.push(
      <Button
        onClick={() => {
          setOpenPublishSettingsDialog(true);
        }}
        variant="solid"
        color="primary"
      >
        Publish Features
      </Button>
    );
  }

  return (
    <>
      {selectedSystem && (
        <SystemPublishSettingsDialog
          isOpen={openPublishSettingsDialog}
          system={selectedSystem}
          accountId={accountData?.id}
          systemEnvironmentsData={pageProps.systemEnvironmentsData}
          onClose={() => {
            setOpenPublishSettingsDialog(false);
          }}
        ></SystemPublishSettingsDialog>
      )}

      {showDrawer && triggerFFDetails()}

      <FeaturesTable
        tableTitle="Systems"
        featureFlagTypes={pageProps.featureFlagTypes}
        featuresForEntityType={pageProps.featuresForEntityType}
        entityFeatureFlags={pageProps.entityFeatureFlags}
        environments={pageProps.systemEnvironmentsData.data}
        excludedEnvs={pageProps.allExcludedEnvironmentsForSystemData}
        isUpdatingFeatures={pageProps.isUpdatingFeatures}
        cleanupRowsSelection={pageProps.cleanupRowsSelection}
        loadingTable={pageProps.loading}
        headerElement={
          <DropdownSelectionMenuWithSearch
            systemOptions={accountSystems}
            defaultSelectedSystemId={selectedSystem?.id}
            onChange={pageProps.handleSystemChange}
          />
        }
        onAddFeatures={(featureFlags) => {
          addFFToEntity(featureFlags.map((ff) => ff.flagName));
        }}
        onRemoveFeatures={(featureFlags) => {
          console.log(`hello remove features`, featureFlags);
          removeFFFromEntity(featureFlags.map((ff) => ff.flagName));
        }}
        extraTableHeadersElements={extraTableHeadersElements}
        onOpenEnvClicked={(featureFlag) => {
          handleFFEnvironments(featureFlag);
        }}
      />
    </>
  );
}

export default SystemFeatures;
