import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Loader } from '@walkme/ui-core';
import styled from 'styled-components';

export const useStepStyles = makeStyles(() => ({
  root: {
    margin: '24px',
    overflow: 'auto',
  },
  background: {
    background: 'white',
    borderRadius: '12px',
    minHeight: '450px',
    padding: '24px',
  },
  stepInnerFrame: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  titleLabel: {
    fontFamily: 'Proxima Nova',
    fontDtyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2F426C',
    marginBottom: '20px',
  },
  SeconderyLabel: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2F426C',
  },
  SeconderyContent: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2F426C',
  },
  accountNameLabel: {
    color: '#2f426c',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '2px',
  },
  snippetEmailLabel: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '16px',
    color: '#8D97AE',
  },
  notSelectedAccount: {
    border: '1px solid #e6ecf8',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '32%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(212, 223, 245, 0.5)',
    },
  },
  selectedAccount: {
    border: '1px solid #3B61EB',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '32%',
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    zIndex: 100,
    border: '1px solid #F7B500',
    borderRadius: '4px',
    background: '#FEF8E8',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 12px',
  },
  cardNameInitial: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: '#ECEFFA',
    borderRadius: '8px',
    width: '36px',
    height: '36px',
    marginRight: '9px',
    marginLeft: '14px',
    textAlign: 'center',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
  selectedSystem: {
    border: '1px solid #3B61EB',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  notSelectedSystem: {
    border: '1px solid #e6ecf8',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(212, 223, 245, 0.5)',
    },
  },
  selectedUser: {
    border: '1px solid #3B61EB',
    borderRadius: '8px',
    height: '60px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  notSelectedUser: {
    border: '1px solid #e6ecf8',
    borderRadius: '8px',
    height: '60px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(212, 223, 245, 0.5)',
    },
  },
  buttonBack: {
    padding: '6px !important',
  },
  tagList: {
    padding: '0 !important',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
  },
  tagListWrapper: {},
}));

export const StyledPlaceholderImage = styled('div')`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledLoader = () => (
  <div
    style={{
      background: 'white',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '50px',
    }}
  >
    <Loader />
  </div>
);

export const ContentWrapper = styled('div')`
`;

export const ReviewWrapper = styled('div')`
  height: 406px;
`;

export const ReviewItemsWrapper = styled('div')`
  height: 390px;
  overflow-y: scroll;
`;

export const ReviewLabel = styled('label')`
  font-size: 15px;
  font-weight: 600;
  font-family: Proxima Nova;
`;

export const AccountName = styled.span`
  font-weight: 400;
  font-style: italic;
`;
export const ReviewLabelSeparator = styled('div')`
  height: 8px;
`;

export const ItemsWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px;
  margin-top: 18px;
  overflow-y: scroll;
`;

export const LabelWrapper = styled.div`
  margin-bottom: 20px;
`;

export const SystemNameLabel = styled('label')`
  color: #2f426c;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const SystemSnippetEmailLabel = styled('label')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8d97ae;
`;

export const TagListWrapper = styled('div')`
  min-height: 23px;
  overflow-y: auto;
  margin: 5px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #c0c0c0;
  margin-bottom: 20px;
`;
