import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebHooksState, fetchWebHooks } from '../redux/webhooks.slice';

export const useFetchWebHooks = () => {
  const allWebHooksData = useSelector(
    (state: { webHooksState: WebHooksState }) =>
      state.webHooksState?.allWebHooks
  );

  const webHookEventNamesData = useSelector(
    (state: { webHooksState: WebHooksState }) =>
      state.webHooksState?.webHookEventNames
  );

  const webHooks = allWebHooksData?.data;
  const webHookEventNames = webHookEventNamesData?.data;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWebHooks());
  }, [dispatch]);

  return { allWebHooksData, webHooks, webHookEventNames };
};

export default useFetchWebHooks;
