import { Policy } from '../../../../../state-management-feature-toggles/src';

export const enum FeatureFlagDialogType {
  CREATE = 'create',
  EDIT = 'edit',
}

export const isFFCreationDialog = (dialogType: string) => {
  return dialogType === FeatureFlagDialogType.CREATE;
};

type State = {
  name: string;
  description: string;
  team: string;
  type: string;
  policy: string;
  entities: string[] | null;
  isEnabled: boolean;
};

type Action = { type: typeof SET_FIELD; field: string; value: any };

export const initialFeatureFlagState = {
  name: '',
  description: '',
  team: '',
  type: '',
  policy: '',
  entities: null,
  isEnabled: false,
};

export const SET_FIELD = 'SET_FIELD';

export const validationTooltipText =
  "Checking the box validates GUIDs; without it, no validation is done. IDs can be used, but they won't be validated, which may cause errors. If you choose to use IDs, be sure to uncheck this.";

export const isEnabledTooltipText =
  'Use this switch to activate or deactivate the feature.';

const entitiesTooltipText =
  'Enter the correct Entities identifiers, separated by a comma, to avoid errors.';

const entitiesDisabledTooltipText =
  'This field is disabled because the policy applies to all entities, so no specific identifiers are needed.';

export const isSyncTooltipText =
  'Toggle on to enable synchronization of the feature flag across all environments. Toggle off to keep it only in the current environment.';

export const getEntitiesTooltipText = (policy: string) => {
  return policy === Policy.all
    ? entitiesDisabledTooltipText
    : entitiesTooltipText;
};

export const featureFlagStateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_FIELD:
      return { ...state, [action.field]: action.value };
    default:
      throw new Error('unknown reducer action');
  }
};

export const getFFDialogTitle = (isFFCreationDialog: boolean) => {
  return isFFCreationDialog ? 'Create a Feature Flag' : 'Edit Feature Flag';
};

export const getFFDialogSubTitle = (isFFCreationDialog: boolean) => {
  return isFFCreationDialog
    ? 'Enter the necessary information in each field to successfully create and manage a new feature flag.'
    : "Edit the necessary details for your existing feature flag. Note that the 'Name' and 'Team' fields are not editable.";
};

export const getFFDialogConfirmButtonText = (isFFCreationDialog: boolean) => {
  return isFFCreationDialog ? 'Create / Create & Sync' : 'Save';
};

export function splitOnCapitalAndLowerCase(str: string) {
  return str
    .split(/(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join(' ');
}

export function makeFirstCharCapital(str: string) {
  return str.replace(/^./, (match) => match.toUpperCase());
}

export const validateState = (args: (string | string[])[]) => {
  return args.every((arg) => arg?.length > 0);
};

export const validateGuids = (guids: string[]) => {
  if (new Set(guids).size !== guids.length) return false;

  for (let i = 0; i < guids.length; i++) {
    let guid = guids[i].replace(/[-\r\n]/g, '');
    if (guid.length !== 32) {
      return false;
    }
  }

  return true;
};
