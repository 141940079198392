import styled from 'styled-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Check,
  Launcher,
  Resource,
  Settings,
  Shotout,
  Shuttle,
  Survey,
  Task,
} from '@walkme/ui-icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import CopyAccountWizardPage1 from './wizard-pages-common/page1';
import CopyAccountWizardPage3SameDc from './wizard-pages-same-dc/page3';
import CopyAccountWizardPage4SameDc from './wizard-pages-same-dc/page4';
import { Button, IconButton } from '@walkme/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import {
  startCopyAccount,
  startCopySpecific,
  startExportCopyAccount,
  startExportCopySpecific,
} from '@wm-accounts-backoffice-center/state-management-copy-account';
import { ProcessSource, ProcessTarget } from '@wm/copy-account-types';
import {
  DeployablesState,
  getSystemsDeployablesCount,
} from '@wm-accounts-backoffice-center/state-management-deployables';
import OnbordingTask from '../styles/OnbordingTask';
import SmartWalkThrough from '../styles/SmartWalkThrough';
import CopyAccountWizardPage1_5 from './wizard-pages-common/page1.5';
import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDivider,
  StyledCheck,
  StyledStepper,
  StyledConnector,
  StyledStepLabel,
  StyledIconeStep,
  StyledIconeStepActive,
  StyledIconeStepCompleted,
  AccountNameLabel,
  SnippetEmailLabel,
} from './copy-account-wizard.styles';

export interface CopyAccountModalProps {
  showDialog: boolean;
  wizardExitCallback: Function;
  account: any;
  copyAccountOption: 'SameDc' | 'Export' | 'Import';
}

export interface IStepProps {
  stepTitle: JSX.Element | string;
  content: React.ReactNode;
  shouldSkip?: () => boolean;
}

const CopyAccountModal = ({
  showDialog,
  wizardExitCallback,
  account,
  copyAccountOption,
}: CopyAccountModalProps) => {
  const [disableNext, setDisableNext] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [sourceAccountId, setSourceAccountId] = useState(account.id);
  const [targetAccount, setTargetAccount] = useState(null);
  const [targetSystem, setTargetSystem] = useState(null);
  const [selectedSystems, setSelectedSystems] = useState([]);
  const dispatch = useDispatch();
  const [deployablesToCopy, setDeployablesToCopy] = useState([]);
  const [copyProgressOption, setCopyProgressOption] = useState(null);

  const systemsDeployablesCountAppData = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.systemsDeployablesCount
  );

  const realDeployablesTypes = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.realDeployablesTypes
  );

  const DeployablesTypesAppData = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.realDeployablesTypes
  );

  useMemo(() => {
    if (selectedSystems) {
      const systemIds = selectedSystems.map((system) => system.id);
      copyProgressOption === 'systems' &&
        selectedSystems.length > 0 &&
        dispatch(getSystemsDeployablesCount(systemIds, false));
    }
  }, [copyProgressOption, selectedSystems]);

  const labelsSystemsDeployablesCount = (systemsDeployablesCountAppData) => {
    let deployables = DeployablesTypesAppData.data.map((type) => {
      return {
        counter: 0,
        name: type.value,
        displayType: type.displayType,
        displayName: type.displayName,
        id: type.type,
      };
    });

    systemsDeployablesCountAppData &&
      systemsDeployablesCountAppData.data.forEach((system) => {
        Object.entries(system.deployables).forEach((deployable) => {
          let countLabel = deployables.find(
            (deployableLabel) => deployableLabel.name === deployable[0]
          );
          countLabel ? (countLabel.counter += Number(deployable[1])) : null;
        });
      });
    return deployables
      .filter((label) => label.counter > 0)
      .map((deployable) => {
        return {
          name: deployable.displayName,
          displayType: deployable.displayType,
          counter: deployable.counter,
        };
      });
  };

  const labelsSpecificDeployablesCount = useCallback(() => {
    return deployablesToCopy?.map((deployable) => {
      const deployableType = DeployablesTypesAppData.data.find(
        (deployableTypeImage) =>
          deployable.deployableType
            ? deployableTypeImage.type === deployable.deployableType
            : deployableTypeImage.displayType === deployable.displayType
      );
      return {
        name: deployable.name ?? deployableType.value,
        displayType: deployableType.displayType,
      };
    });
  }, [deployablesToCopy]);

  const stepIncludePlatformSystems: IStepProps = {
    stepTitle: 'Include Platform Systems (optional)',
    shouldSkip: () => {
      return !selectedSystems.find(
        (s) => !!account.systems.find((as) => as.associatedSystem?.id === s.id)
      );
    },
    content: (
      <CopyAccountWizardPage1_5
        account={account}
        setDisabled={(disabled) => setDisableNext(disabled)}
        selectedSystemsToCopy={selectedSystems}
        setSelectedSystems={(selectedSystems) =>
          setSelectedSystems(selectedSystems)
        }
      />
    ),
  };

  const stepSelectContentToCopy: IStepProps = {
    stepTitle: 'Select content to copy',
    content: (
      <CopyAccountWizardPage1
        account={account}
        setDisabled={(disabled) => setDisableNext(disabled)}
        selectedSystemsToCopy={selectedSystems}
        setSelectedSystems={setSelectedSystems}
        copyProgressOption={copyProgressOption}
        setCopyProgressOption={(ProgressOption) =>
          setCopyProgressOption(ProgressOption)
        }
        deployablesToCopy={deployablesToCopy}
        setDeployablesToCopy={(deployablesToCopy) =>
          setDeployablesToCopy(deployablesToCopy)
        }
      />
    ),
  };

  const stepSelectDestination: IStepProps = {
    stepTitle: 'Select destination',
    content: (
      <CopyAccountWizardPage3SameDc
        setDisabled={(disabled) => setDisableNext(disabled)}
        targetAccount={targetAccount}
        setTargetAccount={(accountDestination) =>
          setTargetAccount(accountDestination)
        }
        setTargetSystem={(selectedTargetSystem) =>
          setTargetSystem(selectedTargetSystem)
        }
        targetSystem={targetSystem}
        sourceAccountSystems={selectedSystems}
        copyProgressOption={copyProgressOption}
      />
    ),
  };

  const stepCopySummary: IStepProps = {
    stepTitle: 'Summary',
    content: (
      <CopyAccountWizardPage4SameDc
        setDisabled={(disabled) => setDisableNext(disabled)}
        reselectData={() => {
          setActiveStep(0);
          setDeployablesToCopy([]);
          setSelectedSystems([]);
          setTargetAccount(null);
        }}
        systemsToCopy={selectedSystems}
        deployablesDetails={
          copyProgressOption === 'contentItems'
            ? labelsSpecificDeployablesCount()
            : labelsSystemsDeployablesCount(systemsDeployablesCountAppData)
        }
      />
    ),
  };

  const stepExportSummary: IStepProps = {
    stepTitle: 'Summary & Export content',
    content: (
      <CopyAccountWizardPage4SameDc
        setDisabled={(disabled) => setDisableNext(disabled)}
        reselectData={() => {
          setActiveStep(0);
          setDeployablesToCopy([]);
          setSelectedSystems([]);
          setTargetAccount(null);
        }}
        systemsToCopy={selectedSystems}
        deployablesDetails={
          copyProgressOption === 'contentItems'
            ? labelsSpecificDeployablesCount()
            : labelsSystemsDeployablesCount(systemsDeployablesCountAppData)
        }
      />
    ),
  };

  const wizzardStepsCopySystems: IStepProps[] = [
    stepSelectContentToCopy,
    stepIncludePlatformSystems,
    stepSelectDestination,
    stepCopySummary,
  ];

  const wizzardStepsCopyContent = [
    stepSelectContentToCopy,
    stepSelectDestination,
    stepCopySummary,
  ];

  const wizzardStepsExportSystems: IStepProps[] = [
    stepSelectContentToCopy,
    stepIncludePlatformSystems,
    stepExportSummary,
  ];

  const wizzardStepsExportContent = [
    stepSelectContentToCopy,
    stepExportSummary,
  ];

  let wizzardStepsToUse = [stepSelectContentToCopy];
  if (copyAccountOption === 'SameDc') {
    if (copyProgressOption === 'systems') {
      wizzardStepsToUse = wizzardStepsCopySystems;
    } else if (copyProgressOption === 'contentItems') {
      wizzardStepsToUse = wizzardStepsCopyContent;
    }
  } else {
    if (copyProgressOption === 'systems') {
      wizzardStepsToUse = wizzardStepsExportSystems;
    } else if (copyProgressOption === 'contentItems') {
      wizzardStepsToUse = wizzardStepsExportContent;
    }
  }

  const startCopyAccountProcess = useCallback(() => {
    const selectedSystemToCopy = selectedSystems.map((system) => {
      return {
        id: system.id,
        name: system.name,
        displayName: system.displayName,
      };
    });
    const deployablesCountByType = {};
    labelsSystemsDeployablesCount(systemsDeployablesCountAppData).map(
      (deployable) => {
        deployablesCountByType[deployable.name] = deployable.counter;
      }
    );
    const sourceAccountToCopy: ProcessSource = {
      account: {
        id: account.id,
        name: account.name,
        selectedSystems: selectedSystemToCopy,
        contentSummary: deployablesCountByType,
      },
      selectedSystems: selectedSystemToCopy,
    };
    const targetAccountToCopy: ProcessTarget = {
      account: { id: targetAccount.id, name: targetAccount.name },
    };
    dispatch(startCopyAccount(sourceAccountToCopy, targetAccountToCopy));
    copyCleanupWhenExit();
  }, [
    sourceAccountId,
    targetAccount,
    selectedSystems,
    systemsDeployablesCountAppData,
  ]);

  const startExportAccountProcess = useCallback(() => {
    const selectedSystemToCopy = selectedSystems.map((system) => {
      return {
        id: system.id,
        name: system.name,
        displayName: system.displayName,
      };
    });
    const deployablesCountByType = {};
    labelsSystemsDeployablesCount(systemsDeployablesCountAppData).map(
      (deployable) => {
        deployablesCountByType[deployable.name] = deployable.counter;
      }
    );
    const sourceAccountToCopy: ProcessSource = {
      account: {
        id: account.id,
        name: account.name,
        selectedSystems: selectedSystemToCopy,
        contentSummary: deployablesCountByType,
      },
      selectedSystems: selectedSystemToCopy,
    };
    dispatch(startExportCopyAccount(sourceAccountToCopy));
    copyCleanupWhenExit();
  }, [sourceAccountId, selectedSystems, systemsDeployablesCountAppData]);

  const startCopySpecificProcess = useCallback(() => {
    const deployablesCountByType = {};
    const deployablesToCopySpecific = deployablesToCopy?.map((deployable) => {
      deployablesCountByType[deployable.displayType] = 1;
      return {
        id: deployable.id,
        type:
          deployable.deployableType ??
          realDeployablesTypes.data.find(
            (type) => type.displayType === deployable.displayType
          ).type,
        name:
          deployable.name ??
          realDeployablesTypes.data.find(
            (type) => type.type === deployable.deployableType
          )?.value,
      };
    });
    const sourceAccountToCopy: ProcessSource = {
      account: {
        id: account.id,
        name: account.name,
      },
      system: {
        id: selectedSystems[0].id,
        name: selectedSystems[0].name,
        displayName: selectedSystems[0].displayName,
        deployables: deployablesToCopySpecific,
      },
    };
    const targetAccountToCopy: ProcessTarget = {
      account: { id: targetAccount.id, name: targetAccount.name },
      system: {
        id: targetSystem.id,
        name: targetSystem.name,
        displayName: targetSystem.displayName,
      },
    };
    dispatch(startCopySpecific(sourceAccountToCopy, targetAccountToCopy));
    copyCleanupWhenExit();
  }, [
    sourceAccountId,
    targetAccount,
    selectedSystems,
    targetSystem,
    deployablesToCopy,
  ]);

  const startExportCopySpecificProcess = useCallback(() => {
    const deployablesCountByType = {};
    const deployablesToCopySpecific = deployablesToCopy?.map((deployable) => {
      deployablesCountByType[deployable.displayType] = 1;
      return {
        id: deployable.id,
        type:
          deployable.deployableType ??
          realDeployablesTypes.data.find(
            (type) => type.displayType === deployable.displayType
          ).type,
        name:
          deployable.name ??
          realDeployablesTypes.data.find(
            (type) => type.type === deployable.deployableType
          )?.value,
      };
    });
    const sourceAccountToCopy: ProcessSource = {
      account: {
        id: account.id,
        name: account.name,
      },
      system: {
        id: selectedSystems[0].id,
        name: selectedSystems[0].name,
        deployables: deployablesToCopySpecific,
        displayName: selectedSystems[0].displayName,
      },
    };
    dispatch(startExportCopySpecific(sourceAccountToCopy));
    copyCleanupWhenExit();
  }, [
    sourceAccountId,
    targetAccount,
    selectedSystems,
    targetSystem,
    deployablesToCopy,
  ]);

  const CustomStepIcon = (props) => {
    const { icon, active, completed } = props;
    if (completed)
      return (
        <StyledIconeStepCompleted>
          <StyledCheck />
        </StyledIconeStepCompleted>
      );
    if (active) return <StyledIconeStepActive>{icon}</StyledIconeStepActive>;
    return <StyledIconeStep>{icon}</StyledIconeStep>;
  };

  const stepper = useCallback(
    (title: string, steps: IStepProps[], activeStep: number) => {
      return (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ whiteSpace: 'nowrap' }}>{title}</span>
            <StyledStepper
              activeStep={activeStep}
              connector={<StyledConnector />}
            >
              {steps.map((step, index) => {
                const stepProps = {};
                return (
                  <Step key={index} {...stepProps}>
                    <StyledStepLabel StepIconComponent={CustomStepIcon}>
                      {step.stepTitle}
                    </StyledStepLabel>
                  </Step>
                );
              })}
            </StyledStepper>
            <IconButton onClick={(e) => copyCleanupWhenExit()} variant="ghost">
              <img src="assets/icons/close.svg" alt="Close" />
            </IconButton>
          </div>
          <StyledDivider />
          <div
            style={{ display: 'flex', alignItems: 'center', margin: '16px 0' }}
          >
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                background: '#ECEFFA',
                borderRadius: '100px',
                width: '40px',
                height: '40px',
                marginRight: '9px',
                textAlign: 'center',
                fontFamily: 'Proxima Nova',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '20px',
                textTransform: 'uppercase',
              }}
            >
              {account.name.charAt(0)}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <AccountNameLabel>{account.name}</AccountNameLabel>
              <SnippetEmailLabel>{account.email}</SnippetEmailLabel>
            </div>
            {targetAccount && activeStep === 2 && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="/assets/ArrowRight.svg"
                  style={{ margin: '0 24px' }}
                />
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    background: '#ECEFFA',
                    borderRadius: '100px',
                    width: '40px',
                    height: '40px',
                    marginRight: '9px',
                    textAlign: 'center',
                    fontFamily: 'Proxima Nova',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    textTransform: 'uppercase',
                  }}
                >
                  {targetAccount.name.charAt(0)}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <AccountNameLabel>{targetAccount.name}</AccountNameLabel>
                  <SnippetEmailLabel>{targetAccount.email}</SnippetEmailLabel>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    },
    [copyAccountOption, showDialog]
  );

  const copyCleanupWhenExit = () => {
    wizardExitCallback();
    setActiveStep(0);
    setSelectedSystems([]);
    setTargetAccount(null);
    setCopyProgressOption(null);
    setDeployablesToCopy([]);
    setTargetSystem(null);
    setSourceAccountId(null);
  };

  const wizardTitle =
    copyAccountOption === 'SameDc' ? 'Copy content' : 'Export content';

  return (
    <StyledDialog open={showDialog} onClose={copyCleanupWhenExit}>
      <StyledDialogTitle>
        {stepper(wizardTitle, wizzardStepsToUse, activeStep)}
      </StyledDialogTitle>
      <StyledDialogContent>
        {wizzardStepsToUse &&
          wizzardStepsToUse[activeStep] &&
          wizzardStepsToUse[activeStep].content}
      </StyledDialogContent>
      <DialogActions
        style={{
          padding: '16px 32px 32px',
          justifyContent:
            ((deployablesToCopy?.length > 0 && selectedSystems.length > 0) ||
              (copyProgressOption === 'systems' &&
                selectedSystems.length > 0)) &&
            activeStep === 0
              ? 'space-between'
              : 'flex-end',
        }}
      >
        {copyProgressOption === 'systems' &&
          selectedSystems.length > 0 &&
          activeStep === 0 && (
            <label
              style={{
                fontFamily: 'Proxima Nova',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '20px',
                color: '#637191',
              }}
            >
              {`${selectedSystems.length} system${
                selectedSystems.length > 1 ? 's' : ''
              } selected`}
            </label>
          )}
        {copyProgressOption === 'contentItems' &&
          deployablesToCopy?.length > 0 &&
          activeStep === 0 && (
            <label
              style={{
                fontFamily: 'Proxima Nova',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '20px',
                color: '#637191',
              }}
            >
              {`${deployablesToCopy?.length} deployable${
                deployablesToCopy?.length > 1 ? 's' : ''
              } selected`}
            </label>
          )}
        <div style={{ display: 'flex', gap: '14px' }}>
          {activeStep === 0 && (
            <Button
              variant="outlined"
              onClick={(e) => {
                copyCleanupWhenExit();
              }}
              size="large"
            >
              Cancel
            </Button>
          )}
          {activeStep > 0 && (
            <Button
              variant="outlined"
              onClick={() => {
                if (
                  wizzardStepsToUse[activeStep - 1].shouldSkip &&
                  wizzardStepsToUse[activeStep - 1].shouldSkip()
                ) {
                  setActiveStep(activeStep - 2);
                } else {
                  setActiveStep(activeStep - 1);
                }
              }}
              size="large"
            >
              Back
            </Button>
          )}
          {activeStep < wizzardStepsToUse.length - 1 && (
            <Button
              onClick={() => {
                if (
                  wizzardStepsToUse[activeStep + 1].shouldSkip &&
                  wizzardStepsToUse[activeStep + 1].shouldSkip()
                ) {
                  setActiveStep(activeStep + 2);
                } else {
                  setActiveStep(activeStep + 1);
                }
                setDisableNext(true);
              }}
              disabled={disableNext}
              size="large"
            >
              Next
            </Button>
          )}
          {activeStep === wizzardStepsToUse.length - 1 &&
            copyAccountOption === 'SameDc' && (
              <Button
                onClick={() =>
                  copyProgressOption === 'systems'
                    ? startCopyAccountProcess()
                    : startCopySpecificProcess()
                }
                size="large"
              >
                Copy
              </Button>
            )}
          {activeStep === wizzardStepsToUse.length - 1 &&
            copyAccountOption === 'Export' && (
              <Button
                onClick={() =>
                  copyProgressOption === 'systems'
                    ? startExportAccountProcess()
                    : startExportCopySpecificProcess()
                }
                size="large"
              >
                Export
              </Button>
            )}
        </div>
      </DialogActions>
    </StyledDialog>
  );
};

export default CopyAccountModal;
