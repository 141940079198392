import styled from 'styled-components';
import React, { useRef, useEffect } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { json } from '@codemirror/legacy-modes/mode/javascript';
import { css } from '@codemirror/legacy-modes/mode/css';
import { Box } from '@material-ui/core';
import { wmTheme } from './wm-snippet-theme';

export interface WmSnippetBoxProps {
  language: string;
  value: string;
  setValue: (value: string) => void;
  height?: string;
}

export enum LanguagesTypes {
  JSON = 'json',
  CSS = 'css',
}

const codemirrorLanguages = {
  json: json,
  css: css,
};

const StyledCodeMirror = styled(CodeMirror)`
  .cm-editor {
    outline: 0;
    border-radius: 8px;
  }
  .cm-scroller {
    border-radius: 8px;
    ::-webkit-scrollbar {
      background-color: #2f426c;
      border-radius: 8px;
    }
    ::-webkit-scrollbar-corner {
      background-color: #2f426c;
      border-radius: 0px 0px 8px 0px;
    }
    ::-webkit-scrollbar-track {
      background-color: #2f426c;
    }
    ::-webkit-scrollbar-track-piece {
      border-radius: 8px;
      background-color: #2f426c;
    }
    ::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: padding-box;
      background-color: #637191;
    }
    ::-webkit-scrollbar:horizontal {
      height: 10px;
    }
    ::-webkit-scrollbar:vertical {
      width: 10px;
    }
  }
`;

export function WmSnippetBox(props: WmSnippetBoxProps) {
  return (
    <Box
      style={{ maxHeight: `${props.height || '60vh'}`, borderRadius: '8px' }}
    >
      <StyledCodeMirror
        style={{ borderRadius: '8px' }}
        theme={wmTheme}
        value={props.value}
        height={`${props.height || '60vh'}`}
        extensions={[
          StreamLanguage.define(codemirrorLanguages[props.language]),
        ]}
        onChange={(code) => props.setValue(code)}
        lint={true}
      />
    </Box>
  );
}

export default WmSnippetBox;
