import React, { useCallback, useEffect, useMemo } from 'react';
import { SubTitleTab, TitleTab } from '../styles/styles';
import {
  WMButtonVariant,
  WMCircularProgress,
  WMDataGrid,
  WMDialog,
  WMFormControlLabel,
  WMSwitch,
} from '@walkme/wm-ui';
import useAiManager from '../../../../../state-management-ai-manager/src/lib/hooks/use-ai-manager';

import { clearAiSettingsState } from '@wm-accounts-backoffice-center/state-management-ai-manager';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export interface AiSettingsProps {
  accountGuid: string;
}

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const AiTextEnums = {
  PAGE_HEADER: 'AI Settings',
  PAGE_DESCRIPTION: 'View and manage AI capabilities for your account.',
};

const styles = () => ({
  errorButton: {
    color: '#EB3232 !important',
    borderColor: 'rgba(235, 50, 50, 0.2) !important',
    '&:hover': {
      backgroundColor: 'inherit',
      borderColor: '#EB3232 !important',
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles(styles);

const AiSettings = ({ accountGuid }: AiSettingsProps) => {
  const classes = useStyles();
  const [promptOpen, setPromptOpen] = React.useState(false);
  const dispatch = useDispatch();
  const {
    rules,
    isEnabled: { loading: isEnabledLoading, data: enableAi },
    aiSettingsMetaData: { loading: aiSettingsLoading },
    handleGetAiSettings,
    handleAddToAllowList,
    handleRemoveFromAllowList,
    aiSnackBar,
  } = useAiManager();

  useEffect(() => {
    handleGetAiSettings(accountGuid).catch(() => {
      // left empty
    });
    return () => {
      dispatch(clearAiSettingsState());
    };
  }, [dispatch]);

  const getSwitchLabel = useMemo(() => {
    return (
      <>
        {isEnabledLoading && (
          <WMCircularProgress style={{ marginRight: 8 }} size={10} />
        )}
        <span style={{ fontWeight: 600 }}>
          Connect account to WalkMe's AI Manager
        </span>
      </>
    );
  }, [isEnabledLoading, enableAi]);

  const onIsEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const res = event.target.checked
      ? handleAddToAllowList(accountGuid)
      : setPromptOpen(true);
  };

  const headCells = useMemo(() => {
    return [
      {
        lockVisible: true,
        pinned: true,
        checkboxSelection: false,
        headerCheckboxSelection: false,
        headerName: 'Function',
        field: 'function',
        sortable: true,
        minWidth: 250,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'Provider',
        field: 'provider',
        sortable: true,
        filter: true,
        minWidth: 200,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'Service',
        field: 'service',
        sortable: true,
        filter: true,
        minWidth: 200,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'Application',
        field: 'application',
        sortable: true,
        filter: true,
        minWidth: 200,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'Model',
        field: 'model',
        sortable: true,
        filter: true,
        minWidth: 200,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'Model Version',
        field: 'modelVersion',
        sortable: true,
        minWidth: 200,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
      {
        headerName: 'Operation',
        field: 'operation',
        sortable: true,
        filter: true,
        minWidth: 200,
        cellStyle: {
          fontStyle: 'normal',
          fontSize: '12px',
          color: '#2F426C',
        },
      },
    ];
  }, []);

  const promptDialog = useCallback(() => {
    return (
      <WMDialog
        ds2
        open={promptOpen}
        onClose={() => setPromptOpen(false)}
        title={'Disconnect account from AI Manager?'}
        buttons={[
          {
            children: 'Cancel',
            onClick: () => setPromptOpen(false),
            variant: WMButtonVariant.Text,
            style: { paddingRight: '20px' },
          },
          {
            children: 'Disconnect',
            variant: WMButtonVariant.Secondary,
            className: classes.errorButton,
            onClick: () => {
              setPromptOpen(false);
              handleRemoveFromAllowList(accountGuid).catch(() => {});
            },
          },
        ]}
      >
        <p>The Account will not be able to utilize WalkMe's Gen AI features.</p>
      </WMDialog>
    );
  }, [promptOpen]);

  return (
    <>
      {aiSnackBar}
      {promptDialog()}
      <div style={{ height: '100%' }}>
        <HeaderContainer>
          <TitleTab> {AiTextEnums.PAGE_HEADER} </TitleTab>
          <SubTitleTab>{AiTextEnums.PAGE_DESCRIPTION}</SubTitleTab>
        </HeaderContainer>
        {!aiSettingsLoading && (
          <WMFormControlLabel
            style={{ display: 'inline-flex' }}
            control={
              <WMSwitch checked={enableAi} onChange={onIsEnableChange} />
            }
            label={getSwitchLabel}
          />
        )}
        <div style={{ height: 'calc(100% - 104px)' }}>
          <WMDataGrid
            columnDefs={headCells as any}
            rows={rules}
            pagination={true}
            title={'Rules'}
            entityName={'Ai Routes'}
            hideColumnPicker
            hideExport
            loadingData={aiSettingsLoading}
          />
        </div>
      </div>
    </>
  );
};

export default AiSettings;
