import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import { Drawer, Loader, Status, StatusVariant, Tabs } from '@walkme/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import {
  CopyAccountState,
  getCopyAccountProcessByAccountID,
} from '@wm-accounts-backoffice-center/state-management-copy-account';
import { AccountProcessView } from './account-process';
import { TitleTab, SubTitleTab, EmptyLabel } from './../../styles/styles';
import { Account } from '@wm-accounts-backoffice-center/wm-api';
import { InfoColor } from '@walkme/ui-icons/large';

const useStyles = makeStyles(() => ({
  roundedFirstLeteer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: '#ECEFFA',
    borderRadius: '100px',
    width: '29px',
    height: '29px',
    marginRight: '9px',
    textAlign: 'center',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  SeconderyLabel: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2F426C',
  },
  SeconderyContent: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2F426C',
  },
  processDiv: {
    border: '1px solid #ECF1FA',
    borderRadius: '4px',
    marginBottom: '16px',
    padding: '15px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  processLabel: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#2F426C',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
  },
  processHeader: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2F426C',
    marginBottom: '4px',
    display: 'flex',
  },
  childDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #ECF1FA',
    borderRadius: '4px',
    padding: '15px 32px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontSeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '16px',
  },
  logViewChildProcess: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #ECF1FA',
    borderRadius: '4px',
    padding: '15px 32px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontSeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '16px',
  },
  logViewTask: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #ECF1FA',
    borderRadius: '4px',
    padding: '15px 32px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontSeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '16px',
  },
  logViewEvent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #ECF1FA',
    borderRadius: '4px',
    padding: '15px 32px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontSeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '16px',
  },
}));

export interface CopyAccountLogsProps {
  showDrawer?: boolean;
  drawerExitCallback?: Function;
  account: Account;
}

const CopyAccountLogs = ({
  showDrawer,
  drawerExitCallback,
  account,
}: CopyAccountLogsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedAccountToDisplay, setSelectedAccountToDisplay] =
    useState(null);

  const mappedStatus = [
    { name: 'in-progress', display: StatusVariant.Warning },
    { name: 'pending', display: StatusVariant.Warning },
    { name: 'failed', display: StatusVariant.Error },
    { name: 'done', display: StatusVariant.Success },
  ];
  const iconTitle = (
    <div className={classes.roundedFirstLeteer}>{account.name.charAt(0)}</div>
  );

  const allAccountsProcess = useSelector(
    (state: { copyAccountState: CopyAccountState }) =>
      state.copyAccountState.PageProcesses
  );

  const selectedAccountProccess = allAccountsProcess.data
    ? allAccountsProcess.data.processes[account.id]
    : [];

  useEffect(() => {
    !selectedAccountProccess &&
      dispatch(getCopyAccountProcessByAccountID([account.id]));
  }, []);

  const copyLogsDetails = selectedAccountProccess ? (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '24px',
        }}
      >
        <TitleTab>Content operations</TitleTab>
        <SubTitleTab>Monitor your WalkMe account contract usage</SubTitleTab>
      </div>
      <div
        key={account.id}
        style={{
          height: 'calc(100% - 72px)',
          overflow: 'hidden scroll',
          paddingRight: '5px',
        }}
      >
        {selectedAccountProccess?.length > 0 ? (
          selectedAccountProccess?.map((process, index) => {
            return (
              <AccountProcessView
                key={index}
                process={process}
                currentAccount={account}
              />
            );
          })
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src="/assets/searching.svg" width="350px" height="350px" />
            <EmptyLabel>Copy Logs Not found for account</EmptyLabel>
          </div>
        )}
      </div>
    </>
  ) : (
    <div
      key={account.id}
      style={{
        background: 'white',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '530px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader />
    </div>
  );

  return <>{copyLogsDetails}</>;
};

export default CopyAccountLogs;
