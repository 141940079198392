import {
  Account,
  FeatureFlagTypeEntity,
} from '@wm-accounts-backoffice-center/wm-api';
import { TitleTab, SubTitleTab } from '../styles/styles';
import { Tabs, ToasterVariant, Tooltip, useToaster } from '@walkme/ui-core';
import {
  EntityFeatureFlag,
  FeaturesState,
  deleteFeaturesCleanup,
  featuresCleanup,
  getAccountFeatures,
  getSystemFeatures,
  useGetAllFeatures,
  useGetFeaturesTypes,
} from '@wm-accounts-backoffice-center/state-management-features';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { System } from 'wm-accounts-sdk';
import {
  getEnvironmentsBySystemId,
  getAllExcludedEnvironmentsForSystem,
  ExcludedEnvironmentsState,
  EnvironmentsState,
} from '@wm-accounts-backoffice-center/state-management-environments';
import { getAccountById } from '@wm-accounts-backoffice-center/state-management-users';
import AccountFeatures from './account-features-tab';
import SystemFeatures from './systems-features-tab';
import { Info } from '@walkme/ui-icons';
import styled from 'styled-components';

export interface FeaturesPageProps {
  account: Account;
  systemId?: number;
  lastSelectedSystemId?: number;
}

const StyledTooltip = styled(Tooltip)`
  .SXbBh .Tooltip__StyledSpanContainer-sc-1n3y5r1-3 {
    display: flex;
    align-items: center;
  }
  margin-left: 8px;
`;

const StyledTabs = styled(Tabs)`
  &.kRsNDe {
    height: calc(100% - 53px) !important;
    &.hDgHTq {
      height: calc(100% - 57px) !important;
    }
  }
`;

export function FeaturesPage(pageProps: FeaturesPageProps) {
  const { featureFlagTypesData } = useGetFeaturesTypes();
  const { allFeaturesAppData } = useGetAllFeatures();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToaster } = useToaster();
  const [activeTab, setActiveTab] = useState(
    history.location.pathname.includes('default') ? 2 : 1
  );
  const accountSystems =
    pageProps.account?.systems?.filter(
      (system) =>
        !system.statusDetails || system.statusDetails.status === 'enabled'
    ) || [];
  const [selectedSystem, setSelectedSystem] = useState<System>(
    pageProps.account.systems.find((system) => system.id === pageProps.systemId)
  );
  const [cleanupRowsSelection, setCleanupRowsSelection] = useState(false);

  const systemFeaturesAppData = useSelector(
    (state: { featuresState: FeaturesState }) =>
      state.featuresState.systemFeatures
  );
  const accountFeaturesAppData = useSelector(
    (state: { featuresState: FeaturesState }) =>
      state.featuresState.accountFeatures
  );
  const addFFStatus = useSelector(
    (state: { featuresState: FeaturesState }) => state.featuresState.addFeature
  );
  const deleteFFStatus = useSelector(
    (state: { featuresState: FeaturesState }) =>
      state.featuresState.deleteFeature
  );
  const systemEnvironmentsData = useSelector(
    (state: { environmentsState: EnvironmentsState }) =>
      state.environmentsState.systemEnvironments
  );
  const allExcludedEnvironmentsForSystemData = useSelector(
    (state: { excludedEnvironmentsState: ExcludedEnvironmentsState }) =>
      state.excludedEnvironmentsState?.allExcludedEnvironmentsForSystem
  );

  const getFeaturesForEntityType = (entityType: FeatureFlagTypeEntity) => {
    return allFeaturesAppData.data?.filter((ff) => {
      const ffTypeData = featureFlagTypesData.data?.find(
        (flagType) => flagType.value === ff.type
      );
      return !ffTypeData || ffTypeData.entities.includes(entityType);
    });
  };

  const handleTabChange = (newValue: number) => {
    if (activeTab == newValue) return;

    dispatch(featuresCleanup());
    const isSystemTab = newValue === 1;
    const systemsOptions = pageProps.account?.systems?.filter(
      (system) =>
        !system.statusDetails || system.statusDetails.status === 'enabled'
    );
    if (isSystemTab && systemsOptions?.length === 0) {
      return;
    }
    setActiveTab(newValue);
    let isAccountTab = newValue === 0;
    let entityIdToSet = Number(
      isAccountTab ? pageProps.account.id : pageProps.systemId || 0
    );
    if (isAccountTab) {
      history.push({
        pathname: `/accounts/${pageProps.account.id}/features`,
      });
    } else {
      if (isSystemTab) {
        history.push({
          pathname: `/accounts/${pageProps.account.id}/features/${entityIdToSet}`,
        });
      } else {
        history.push({
          pathname: `/accounts/${pageProps.account.id}/features/default`,
        });
      }
    }
  };

  const handleSystemChange = (newSystemId) => {
    const systemId = Number(newSystemId);
    const newSystem = pageProps.account?.systems?.find(
      (system) => system.id === systemId
    );
    setSelectedSystem(newSystem);
    history.push({
      pathname: `/accounts/${pageProps.account.id}/features/${systemId}`,
    });
  };

  useEffect(() => {
    const entityIsAccount = activeTab === 0;
    if (entityIsAccount) {
      dispatch(getAccountFeatures(pageProps.account.id));
    } else {
      if (selectedSystem) {
        dispatch(getSystemFeatures(selectedSystem?.id));
        dispatch(getEnvironmentsBySystemId(selectedSystem?.id));
        dispatch(getAllExcludedEnvironmentsForSystem(selectedSystem?.id));
      }
    }
  }, [activeTab, selectedSystem]);

  useEffect(() => {
    setCleanupRowsSelection(false);
    if (addFFStatus.data) {
      setCleanupRowsSelection(true);
      addToaster({
        message: `${addFFStatus.data.length} features Enabled`,
        variant: ToasterVariant.Success,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    } else if (addFFStatus.error) {
      addToaster({
        message: `${addFFStatus.error}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [addFFStatus]);

  useEffect(() => {
    setCleanupRowsSelection(false);
    if (deleteFFStatus.data) {
      setCleanupRowsSelection(true);
      addToaster({
        message: `${deleteFFStatus.data.length} features Disabled`,
        variant: ToasterVariant.Success,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    } else if (deleteFFStatus.error) {
      addToaster({
        message: `${deleteFFStatus.error}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [deleteFFStatus]);

  useEffect(() => {
    dispatch(deleteFeaturesCleanup());
  }, []);

  useEffect(() => {
    const systemTabActive = activeTab === 1;
    const systemIdParam = pageProps.systemId;
    const isDefaultTab = history.location.pathname.includes('default');
    if (isDefaultTab) {
      setActiveTab(2);
      return;
    }
    const systemFromUrl = accountSystems?.find((sf) => sf.id == systemIdParam);
    const selectedSystem = (() => {
      if (!systemTabActive) return null;
      if (systemFromUrl) return systemFromUrl;
      const lastSelectedSystem =
        pageProps.lastSelectedSystemId &&
        accountSystems?.find((sf) => sf.id == pageProps.lastSelectedSystemId);
      if (lastSelectedSystem) {
        return lastSelectedSystem;
      }
      const result = accountSystems?.length > 0 ? accountSystems[0] : null;
      return result;
    })();
    setSelectedSystem(selectedSystem);

    if (systemTabActive && !selectedSystem) {
      setActiveTab(0);
    }
    if (
      systemTabActive &&
      selectedSystem &&
      (!systemIdParam || systemIdParam !== selectedSystem.id)
    ) {
      // when move to system tab, we need to update url
      handleSystemChange(selectedSystem?.id);
    }
  }, [pageProps, activeTab]);

  useEffect(() => {
    if (activeTab === 1 && (addFFStatus.data || deleteFFStatus.data)) {
      dispatch(getAccountById(pageProps.account.id));
    }
  }, [addFFStatus, deleteFFStatus]);

  return (
    <div style={{ height: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '5px',
        }}
      >
        <TitleTab>Feature management</TitleTab>
        <SubTitleTab>
          View and manage your account and systems features
        </SubTitleTab>
      </div>

      <StyledTabs
        onChange={(tabItem) => handleTabChange(Number(tabItem.value))}
        initialValue={activeTab}
        items={[
          {
            content: (
              <AccountFeatures
                account={pageProps.account}
                featuresForEntityType={getFeaturesForEntityType(
                  FeatureFlagTypeEntity.account
                )}
                loading={
                  allFeaturesAppData.loading || accountFeaturesAppData.loading
                }
                entityFeatureFlags={accountFeaturesAppData.data}
                featureFlagTypes={featureFlagTypesData.data}
                isUpdatingFeatures={
                  addFFStatus.loading || deleteFFStatus.loading
                }
                cleanupRowsSelection={cleanupRowsSelection}
              />
            ),
            label: 'Account Features',
            value: 0,
          },
          {
            content: (
              <SystemFeatures
                account={pageProps.account}
                featuresForEntityType={getFeaturesForEntityType(
                  FeatureFlagTypeEntity.system
                )}
                loading={
                  allFeaturesAppData.loading || systemFeaturesAppData.loading
                }
                entityFeatureFlags={systemFeaturesAppData.data}
                featureFlagTypes={featureFlagTypesData.data}
                isUpdatingFeatures={
                  addFFStatus.loading || deleteFFStatus.loading
                }
                cleanupRowsSelection={cleanupRowsSelection}
                handleSystemChange={handleSystemChange}
                systemEnvironmentsData={systemEnvironmentsData}
                allExcludedEnvironmentsForSystemData={
                  allExcludedEnvironmentsForSystemData.data
                }
                selectedSystem={selectedSystem}
              />
            ),
            label: 'System Features',
            value: 1,
          },
        ]}
      />
    </div>
  );
}
export default FeaturesPage;
