import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { WMIconDuplicate } from '@walkme/wm-ui';
import { Tooltip } from '@walkme/ui-core';

const useStyles = makeStyles(() => ({
  errorContainer: {
    border: '1px solid',
    borderRadius: '10px',
    padding: '10px',
    borderColor: 'lightGray',
    margin: '5px',
  },
  errorsModal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '20px',
  },
}));

export const ModalError = ({ error }) => {
  const classes = useStyles();

  const [showErrorCopied, setShowErrorCopied] = useState(false);

  return (
    <div className={classes.errorContainer}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <DialogContentText style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
          Error in {error.sourceEventType} handler
        </DialogContentText>
        <Tooltip title="copied" open={showErrorCopied}>
          <WMIconDuplicate
            color={'gray'}
            size={20}
            style={{
              marginLeft: 0,
              marginTop: 3,
              position: 'relative',
              top: '0%',
            }}
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(error.stack));
              setShowErrorCopied(true);
              setTimeout(() => {
                setShowErrorCopied(false);
              }, 1000);
            }}
          ></WMIconDuplicate>
        </Tooltip>
      </div>
      {error.sourceProcess && (
        <DialogContentText>
          <strong>Child Process:</strong> {error.sourceProcess.actionType} (
          {error.sourceProcess.processId})
        </DialogContentText>
      )}
      <DialogContentText>
        <strong>message:</strong> {error.stack}
      </DialogContentText>
    </div>
  );
};
