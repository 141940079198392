import React, { useEffect, useState } from 'react';
import { Account } from '@wm-accounts-backoffice-center/wm-api';
import {
  AccountsSdk,
  BoConnectSystemsDto,
  ContractsRawData,
} from 'wm-accounts-sdk';
import { ContractsComponent } from '@walkme/accounts-contracts-ui';
import { useContractsStyles } from './contracts.styles';

export interface ContractWrapperProps {
  accountData: Account;
}

export function ContractWrapper({ accountData }: ContractWrapperProps) {
  const classes = useContractsStyles();

  const [contracts, setContracts] = useState<ContractsRawData>({
    contracts: [],
    systems: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | React.ReactNode>('');
  const [success, setSuccess] = useState<string | React.ReactNode>('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getContracts();
      setLoading(false);
    };
    fetchData();
  }, [accountData]);

  const getContracts = async () => {
    try {
      const contracts =
        await AccountsSdk.getInstance().contractsManagement.backofficeGetContracts(
          accountData?.id
        );
      setContracts(contracts);
    } catch (error) {
      setError(error.message);
    }
  };

  const findDisplayName = (guids) => {
    const formatGuid = (guid) => guid.replace(/-/g, '');
    const systems = accountData.systems.filter((system) =>
      guids.map((guid) => formatGuid(guid)).includes(formatGuid(system.guid))
    );
    return systems.map((s) => s.displayName);
  };

  const findContractName = (sfId) => {
    const contract = contracts.contracts.find(
      (contract) => contract.sfId === sfId
    );

    return contract?.type;
  };

  const responseValidation = (
    response,
    opsDto: BoConnectSystemsDto,
    isConnectRequest
  ) => {
    const contractName = findContractName(opsDto.contractId) || '';

    if (response.errorSystemGuids.length === 0) {
      const displayNames = findDisplayName(response.successSystemGuids);
      const operationText = isConnectRequest
        ? 'connected to'
        : 'disconnected from';
      const formattedText = (
        <>
          <strong>{displayNames}</strong> system {operationText}{' '}
          <strong>{contractName}</strong> contract
        </>
      );
      setSuccess(<div>{formattedText}</div>);
      return;
    }
    const displayNames = findDisplayName(response.errorSystemGuids);
    const operationText = isConnectRequest ? 'connecting' : 'disconnecting';
    const destinationText = isConnectRequest ? 'to' : 'from';
    const formattedText = (
      <>
        Error {operationText} <strong>{displayNames}</strong> {destinationText}{' '}
        <strong>{contractName}</strong> contract
      </>
    );
    setError(<div>{formattedText}</div>);
  };

  const connectSystemToContract = async (
    connectSystemsDto: BoConnectSystemsDto
  ) => {
    try {
      const response =
        await AccountsSdk.getInstance().contractsManagement.backofficeConnectSystemToContract(
          connectSystemsDto
        );
      responseValidation(response, connectSystemsDto, true);
    } catch (e) {
      setError(e.message);
    }
  };

  const disconnectSystemFromContract = async (
    disconnectSystemsDto: BoConnectSystemsDto
  ) => {
    try {
      const response =
        await AccountsSdk.getInstance().contractsManagement.backofficeDisconnectSystemToContract(
          disconnectSystemsDto
        );
      responseValidation(response, disconnectSystemsDto, false);
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div className={classes.contractsWrapper}>
      <ContractsComponent
        contractsData={contracts}
        systemsData={accountData.systems}
        connectSystemToContract={connectSystemToContract}
        usersData={accountData.users}
        getContracts={getContracts}
        disconnectSystemFromContract={disconnectSystemFromContract}
        loading={loading}
        accountId={accountData?.id}
        accountGuid={accountData?.guid}
        error={error}
        success={success}
        title={'Contracts'}
        subTitle={'Monitor your WalkMe account contract usage.'}
      />
    </div>
  );
}
export default ContractWrapper;
