import { InputSearch } from '@walkme/ui-core';
import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MergeAccountState } from '../../../../merge-account.store';
import {
  systemClicked,
  systemsQueryChanged,
  accountSystemsUpdated,
  stepLoaded,
} from './exclude-systems.step.slice';
// some other component
import { SystemIconGeneratorContext } from '../../../../contexts/system-icon-generator.context';
import { SystemCard } from '../../components/system.card';
import {
  ContentWrapper,
  ItemsWrapper,
  useStepStyles,
} from '../wizard-steps.styles';

const WizardStepExcludeSystems = () => {
  const classes = useStepStyles();

  const generateSystemIconUrl = useContext(SystemIconGeneratorContext);

  const dispatch = useDispatch();

  const initialSystems = useSelector(
    (state: MergeAccountState) => state.mergeData?.sourceAccount?.systems || []
  );
  const excludedSystemIds = useSelector(
    (state: MergeAccountState) => state.mergeData?.excludeSystemIds || []
  );

  useEffect(() => {
    dispatch(stepLoaded());
  }, [dispatch]);

  useEffect(() => {
    dispatch(accountSystemsUpdated());
  }, [initialSystems]);

  const query = useSelector(
    (state: MergeAccountState) => state.excludeSystems?.query
  );
  const systemsToDisplay = useSelector(
    (state: MergeAccountState) => state.excludeSystems?.filteredSystems || []
  );

  const onSearchChange = useCallback(
    (value) => {
      dispatch(systemsQueryChanged(value));
    },
    [dispatch]
  );

  const onSystemClicked = useCallback(
    (systemId: number) => {
      dispatch(systemClicked(systemId));
    },
    [dispatch]
  );

  const rendrSystems = useCallback(
    (systems) =>
      systems.map((system) => {
        return (
          <SystemCard
            onClicked={onSystemClicked}
            key={system.id}
            system={system}
            isSelected={!excludedSystemIds.includes(system.id)}
            iconUrl={generateSystemIconUrl(system.name)}
          />
        );
      }),
    [systemsToDisplay, excludedSystemIds]
  );

  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <div className={classes.stepInnerFrame}>
          <label className={classes.titleLabel}>
            Un-Select any system that you wish to exclude from the merge
          </label>
          <InputSearch
            placeholder="Filter systems by name or snippet email"
            onChange={onSearchChange}
            value={query}
          />
          <ContentWrapper>
            <ItemsWrapper>{rendrSystems(systemsToDisplay)}</ItemsWrapper>
          </ContentWrapper>
        </div>
      </div>
    </div>
  );
};

export default WizardStepExcludeSystems;
