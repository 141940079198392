import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import {
  Button,
  Chip,
  ChipVariant,
  Input,
  InputSearch,
  InputVariant,
  TagList,
  Tooltip,
} from '@walkme/ui-core';
import {
  TextFile,
  Close,
  ChevronDown,
  ChevronRight,
  ErrorColor,
  DeployablesWalkThru,
} from '@walkme/ui-icons';
import {
  CopyAccountState,
  getImportDetails,
  startImportCopyAccount,
  startImportSpecificCopyAccount,
} from '@wm-accounts-backoffice-center/state-management-copy-account';
import {
  DeployablesState,
  useDeployables,
} from '@wm-accounts-backoffice-center/state-management-deployables';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  ChevronUp,
  Launcher,
  Resource,
  Shotout,
  Shuttle,
  Survey,
  Task,
} from '@walkme/ui-icons';
import { WMDivider } from '@walkme/wm-ui';
import {
  getAdminSpaceBaseURL,
  ImportTargetProcess,
} from '@wm-accounts-backoffice-center/wm-api';
import { ProcessTarget } from '@wm/copy-account-types';
import { Settings } from '@material-ui/icons';
import { CircularProgress as MUICircularProgress } from '@mui/material-int';
import SmartWalkThrough from '../../styles/SmartWalkThrough';
import OnbordingTask from '../../styles/OnbordingTask';
import { DialogButtonsWrapper } from './wizard-pages-different-dc.styles';

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    max-width: none;
    width: 680px;
    border-radius: 12px;
    &.MuiStepper-root {
      padding: 0;
    }
  }
`;

const useStyles = makeStyles(() => ({
  titleLabel: {
    fontFamily: 'Poppins',
    fontDtyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#2F426C',
  },
  miniTitle: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#8D97AE',
  },
  importSpan: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#2F426C',
    marginTop: '10px',
  },
  ImportInfoDiv: {
    backgroundColor: 'white',
    width: '100%',
    marginBottom: '16px',
    minHeight: '48px',
  },
  minTitle: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2F426C',
    marginRight: '12px',
  },
  boxDiv: {
    padding: '16px 24px',
    gap: '12px',
    border: '1px solid #F1F5FB',
    borderRadius: '4px',
    marginBottom: '20px',
    backgroundColor: 'white',
  },
  tagList: {
    padding: '0 !important',
  },
  selectedSystem: {
    border: '1px solid #3B61EB',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
  },
  notSelectedSystem: {
    border: '1px solid #e6ecf8',
    borderRadius: '8px',
    height: '80px',
    width: '31%',
    flex: '1 1 auto',
    maxWidth: '31%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(212, 223, 245, 0.5)',
    },
  },
  labelChoseSystem: {
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '28px',
    color: '#2F426C',
  },
  tagChip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 8px',
    gap: '6px',
    height: '20px',
    background: '#ECEFFA',
    borderRadius: '99px',
  },
  labelChip: {
    width: '8px',
    height: '16px',
    fontFamily: 'Proxima Nova',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#162A69',
  },
}));

const SystemNameLabel = styled('label')`
  color: #2f426c;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const SystemSnippetEmailLabel = styled('label')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8d97ae;
`;

export interface CopyAccountWizardImportProps {
  showDialog: boolean;
  wizardExitCallback: Function;
  account: any;
}
const CopyAccountWizardImport = ({
  showDialog,
  wizardExitCallback,
  account,
}: CopyAccountWizardImportProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [processURL, setProcessURL] = useState('');
  const [dataAvailible, setDataAvailible] = useState(false);
  const [importedSystem, setImportedSystem] = useState(null);
  const [targetSystem, setTargetSystem] = useState(null);
  const [importedDeployables, setImportedDeployables] = useState(null);
  const [openSystem, setOpenSystem] = useState(false);
  const [openContent, setOpenContent] = useState(false);
  const [pickTarget, setPickTarget] = useState(false);
  const [contentCounter, setContentCounter] = useState(0);
  const [copySpecific, setCopySpecific] = useState(false);
  const ADMIN_SPACE_BASE_URL = getAdminSpaceBaseURL();
  const allSystems = [...(account?.systems || [])].sort((s1, s2) => {
    return s1.displayName < s2.displayName ? -1 : 1;
  });
  const [searchedSystems, setSearchedSystems] = useState(allSystems);
  const [query, setQuery] = useState('');

  const deployableTypesImage = [
    {
      label: 'ALL',
      iconComponent: <Settings />,
    },
    { label: 'SMART_WALKTHRU', iconComponent: <SmartWalkThrough /> },
    { label: 'WALKTHRU', iconComponent: <SmartWalkThrough /> },
    { label: 'SMART_TIP', iconComponent: <SmartWalkThrough /> },
    { label: 'SHOUTOUT', iconComponent: <Shotout /> },
    { label: 'SHUTTLE', iconComponent: <Shuttle /> },
    { label: 'LAUNCHER', iconComponent: <Launcher /> },
    { label: 'ON_BOARDING_TASK', iconComponent: <OnbordingTask /> },
    { label: 'TASK', iconComponent: <OnbordingTask /> },
    { label: 'SURVEY', iconComponent: <Survey /> },
    { label: 'VIDEO', iconComponent: <Resource /> },
    { label: 'ARTICLE', iconComponent: <Resource /> },
  ];

  const realDeployablesTypes = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.realDeployablesTypes
  );

  useEffect(() => {
    const newSystems = allSystems.filter(
      (system) =>
        system.displayName.includes(query) ||
        system.displayName.toLowerCase().includes(query)
    );
    setSearchedSystems(newSystems);
  }, [query]);

  const allAccountsProcess = useSelector(
    (state: { copyAccountState: CopyAccountState }) =>
      state.copyAccountState.ImportDetails
  );

  const getImportLink = useCallback(
    (processUrl) => {
      processUrl && dispatch(getImportDetails(processUrl));
    },
    [dispatch]
  );

  const importCleanupWhenExit = () => {
    wizardExitCallback();
    setProcessURL('');
    setDataAvailible(false);
    setImportedSystem(null);
    setImportedDeployables(null);
    setOpenSystem(false);
    setOpenContent(false);
    setPickTarget(false);
    setCopySpecific(false);
  };

  const startImport = useCallback(() => {
    const targetAccountToImport: ImportTargetProcess = {
      exportLink: processURL,
      target: {
        account: { id: account.id, name: account.name },
        system: copySpecific
          ? {
              id: targetSystem.id,
              name: targetSystem.name,
              displayName: targetSystem.displayName,
            }
          : null,
      },
    };
    if (copySpecific) {
      dispatch(startImportSpecificCopyAccount(targetAccountToImport));
    } else {
      dispatch(startImportCopyAccount(targetAccountToImport));
    }
    importCleanupWhenExit();
  }, [dispatch, processURL, copySpecific, targetSystem]);

  const labelsSystemsDeployablesCount = (systemsDeployablesCountAppData) => {
    return Object.entries(systemsDeployablesCountAppData || {}).map((dep) => ({
      counter: dep[1],
      label: dep[0],
      iconComponent: <DeployablesWalkThru />,
    }));
  };

  const labelSystemDeployables = (deployables) => {
    let labels = [];
    deployables.map((deployable) => {
      const deployableType = realDeployablesTypes.data.find(
        (realDeployable) => realDeployable.type === deployable.type
      ).displayType;
      labels.push({
        label: deployable.name,
        iconComponent: deployableTypesImage.find(
          (type) => type.label === deployableType
        ).iconComponent,
      });
    });
    return labels;
  };

  useEffect(() => {
    setImportedSystem(
      allAccountsProcess.data?.details.source.system
        ? [allAccountsProcess.data?.details.source.system]
        : allAccountsProcess.data?.details.source.account.selectedSystems
    );
    setImportedDeployables(
      allAccountsProcess.data
        ? allAccountsProcess.data?.details.source.system
          ? labelSystemDeployables(
              allAccountsProcess.data?.details.source.system.deployables
            )
          : labelsSystemsDeployablesCount(
              allAccountsProcess.data?.details.source.account.contentSummary
            )
        : null
    );
    let contentNumber = 0;
    allAccountsProcess.data &&
      !allAccountsProcess.data?.details.source.system &&
      allAccountsProcess.data?.details.source.account.contentSummary &&
      Object.entries(
        allAccountsProcess.data?.details.source.account.contentSummary
      ).forEach((content) => (contentNumber += Number(content[1])));
    setContentCounter(
      allAccountsProcess.data?.details.source.system
        ? allAccountsProcess.data?.details.source.system.deployables.length
        : contentNumber
    );
  }, [allAccountsProcess.data]);

  useEffect(() => {
    if (!allAccountsProcess.loading && allAccountsProcess.data) {
      setDataAvailible(true);
      setCopySpecific(!!allAccountsProcess.data?.details.source.system);
    }
  }, [allAccountsProcess]);

  const handleSystemClicked = (system) => {
    const systemAlreadySelected = targetSystem === system;
    !systemAlreadySelected ? setTargetSystem(system) : setTargetSystem(null);
  };

  return (
    <StyledDialog open={showDialog} onClose={importCleanupWhenExit}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" flexDirection="column">
            <span className={classes.titleLabel}>
              Import content to {account.name} account
            </span>
            <span className={classes.miniTitle}>
              {!dataAvailible
                ? 'Import content from URL'
                : !pickTarget
                ? 'View the summery of the imported content'
                : 'Select target system'}
            </span>
          </Box>
          <div onClick={(e) => importCleanupWhenExit()}>
            <Close />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        {!dataAvailible ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#F4F6FB',
              border: '1px dashed #C5CAD6',
              borderRadius: '12px',
              height: '200px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                marginBottom: '24px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {allAccountsProcess.error ? (
                <ErrorColor />
              ) : allAccountsProcess.loading ? (
                <MUICircularProgress size={21} />
              ) : (
                <TextFile />
              )}
              <span className={classes.importSpan}>
                {allAccountsProcess.loading
                  ? 'Uploading link...'
                  : allAccountsProcess.error
                  ? 'Something went wrong'
                  : 'Import content'}
              </span>
            </div>
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setProcessURL(e.target.value);
              }}
              variant={InputVariant.light}
              placeholder="Paste URL"
              width={480}
              value={processURL}
              error={allAccountsProcess.error ? allAccountsProcess.error : null}
            />
          </div>
        ) : !pickTarget ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#F4F6FB',
              height: '440px',
              padding: '32px',
            }}
          >
            <div className={classes.boxDiv}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <label className={classes.minTitle}> Systems </label>
                  <div className={classes.tagChip}>
                    <label className={classes.labelChip}>
                      {importedSystem?.length.toString()}
                    </label>
                  </div>
                </div>
                {openSystem ? (
                  <ChevronUp onClick={() => setOpenSystem(false)} />
                ) : (
                  <ChevronRight onClick={() => setOpenSystem(true)} />
                )}
              </div>

              {openSystem && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '15px',
                  }}
                >
                  <WMDivider style={{ marginBottom: '10px' }} />
                  <TagList
                    maxTagsShown={4}
                    className={classes.tagList}
                    labels={importedSystem?.map((system) => {
                      return {
                        label: system.displayName,
                        iconComponent: (
                          <img
                            src={`${ADMIN_SPACE_BASE_URL}/assets/icons/icon-${system.name}.png`}
                            width="16px"
                            height="16px"
                          />
                        ),
                      };
                    })}
                  />
                </div>
              )}
            </div>
            <div className={classes.boxDiv}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <label className={classes.minTitle}> Content Items </label>
                  <div className={classes.tagChip}>
                    <label className={classes.labelChip}>
                      {contentCounter.toString()}
                    </label>
                  </div>
                </div>
                {openContent ? (
                  <ChevronUp onClick={() => setOpenContent(false)} />
                ) : (
                  <ChevronRight onClick={() => setOpenContent(true)} />
                )}
              </div>

              {openContent && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '15px',
                  }}
                >
                  <WMDivider style={{ marginBottom: '10px' }} />
                  <TagList
                    maxTagsShown={4}
                    className={classes.tagList}
                    labels={importedDeployables}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '440px',
              overflow: 'auto',
            }}
          >
            <InputSearch onChange={(e) => setQuery(e)} />
            <label className={classes.labelChoseSystem}>{account.name}</label>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
                marginTop: '12px',
              }}
            >
              {searchedSystems.map((system) => {
                return (
                  <div
                    className={
                      targetSystem === system
                        ? classes.selectedSystem
                        : classes.notSelectedSystem
                    }
                    onClick={(item) => handleSystemClicked(system)}
                  >
                    <div style={{ marginRight: '12px', marginLeft: '24px' }}>
                      <div
                        style={{
                          background: '#F0F3F9',
                          borderRadius: '8px',
                          width: '36px',
                          height: '36px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={`${ADMIN_SPACE_BASE_URL}/assets/icons/icon-${system.name}.png`}
                          width="20px"
                          height="20px"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        width: '50%',
                      }}
                    >
                      <SystemNameLabel>{system.displayName}</SystemNameLabel>
                      <Tooltip
                        title={'Guid: ' + system.guid}
                        children={
                          <SystemSnippetEmailLabel>
                            {system.guid}
                          </SystemSnippetEmailLabel>
                        }
                      />
                      <SystemSnippetEmailLabel>
                        {system.systemTypeKey}
                      </SystemSnippetEmailLabel>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions
        style={{
          padding: '16px 24px',
        }}
      >
        <DialogButtonsWrapper>
          <Button
            onClick={(e) => importCleanupWhenExit()}
            variant="text"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              !dataAvailible
                ? getImportLink(processURL)
                : copySpecific
                ? !targetSystem
                  ? setPickTarget(true)
                  : startImport()
                : startImport()
            }
            size="large"
            disabled={
              dataAvailible && copySpecific && pickTarget && !targetSystem
            }
          >
            {dataAvailible && !copySpecific
              ? 'Import Data'
              : !pickTarget
              ? 'Next'
              : 'Import Data'}
          </Button>
        </DialogButtonsWrapper>
      </DialogActions>
    </StyledDialog>
  );
};

export default CopyAccountWizardImport;
