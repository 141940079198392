import { AppData } from '../../../../general-types/src';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { featuresAppThunk } from '@wm-accounts-backoffice-center/state-management-features';
import {
  accountsGraphqlCaller,
  featureTogglesApi,
} from '@wm-accounts-backoffice-center/wm-api';
import { WMSnackbarVariant } from '@walkme/wm-ui';

export enum Status {
  active = 'active',
  archived = 'archived',
}

export enum Policy {
  all = 'all',
  allExcept = 'allExcept',
  onlyTo = 'onlyTo',
}

export enum Type {
  account = 'account',
  user = 'user',
  system = 'system',
  extension = 'extension',
  other = 'other',
}

export type OwnerTeam = {
  _id: string;
  createdAt: string;
  name: string;
  uniqueName: string;
  updatedAt: string;
};

type User = {
  email: string;
  name: string;
};

export type HistoryRecord = {
  _id: string;
  createdAt: string;
  createdBy: string;
  description: string;
  isEnabled: boolean;
  name: string;
  origin: string;
  ownerTeam: OwnerTeam;
  status: Status;
  targetPopulation: {
    policy: Policy;
    type: Type;
    entities?: string[];
  };
  uniqueName: string;
  updatedAt: string;
  updatedBy: string | { email: string; name: string };
};

export type Evaluation = {
  _id: string;
  timeConst: number;
  featureFlag: string;
  trueEvaluations: number;
  falseEvaluations: number;
  createdAt: string;
  updatedAt: string;
};

export type FeatureFlagBase = {
  name: string;
  description: string;
  ownerTeam: OwnerTeam;
  isEnabled: boolean;
  status: Status;
  targetPopulation: {
    policy: Policy;
    type: Type;
    entities?: string[];
  };
};

export type FeatureFlag = FeatureFlagBase & {
  _id: string;
  uniqueName: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  origin: string;
};

export type ExtendedFeatureFlag = Omit<FeatureFlag, 'createdBy'> & {
  createdBy: User | string;
};

export enum OriginSource {
  user = 'user',
  sync = 'sync',
  import = 'import',
}

export interface NotificationMessage {
  text: string;
  variant: WMSnackbarVariant;
  isOpen: boolean;
}

export interface FeatureTogglesState {
  notificationMessage: NotificationMessage;
  allFeatureFlags: AppData<ExtendedFeatureFlag[]>;
  isStateChangeDialogOpen: AppData<boolean>;
  updateFeatureFlag: AppData<FeatureFlag>;
  createEditFeatureFlag: AppData<FeatureFlag>;
  allOwnerTeams: AppData<OwnerTeam[]>;
  isCreateTeamDialogOpen: AppData<boolean>;
  createOwnerTeam: AppData<OwnerTeam>;
  isCreateEditFeatureFlagDialogOpen: AppData<boolean>;
  isHistoryDialogOpen: AppData<boolean>;
  isEvaluationsDialogOpen: AppData<boolean>;
  featureFlagHistory: AppData<HistoryRecord[]>;
  featureFlagEvaluations: AppData<Evaluation[]>;
}

export const initialFeatureTogglesState: FeatureTogglesState = {
  notificationMessage: {
    text: '',
    variant: WMSnackbarVariant.Success,
    isOpen: false,
  },
  allFeatureFlags: {
    data: [],
    error: null,
    loading: false,
  },
  isStateChangeDialogOpen: {
    data: false,
    error: null,
    loading: false,
  },
  updateFeatureFlag: {
    data: null,
    error: null,
    loading: false,
  },
  createEditFeatureFlag: {
    data: null,
    error: null,
    loading: false,
  },
  allOwnerTeams: {
    data: [],
    error: null,
    loading: false,
  },
  isCreateTeamDialogOpen: {
    data: false,
    error: null,
    loading: false,
  },
  createOwnerTeam: {
    data: null,
    error: null,
    loading: false,
  },
  isCreateEditFeatureFlagDialogOpen: {
    data: false,
    error: null,
    loading: false,
  },
  isHistoryDialogOpen: {
    data: false,
    error: null,
    loading: false,
  },
  isEvaluationsDialogOpen: {
    data: false,
    error: null,
    loading: false,
  },
  featureFlagHistory: {
    data: null,
    error: null,
    loading: false,
  },
  featureFlagEvaluations: {
    data: null,
    error: null,
    loading: false,
  },
};

const updateStateWithNotificationMessage = ({
  state,
  text,
  variant,
  isOpen,
}) => {
  state.notificationMessage.text = text;
  state.notificationMessage.variant = variant;
  state.notificationMessage.isOpen = isOpen;
};

const featureTogglesSlice = createSlice({
  name: 'featureTogglesSlice',
  initialState: initialFeatureTogglesState,
  reducers: {
    getFeatureFlagsStart: (state: FeatureTogglesState) => {
      state.allFeatureFlags.loading = true;
      return state;
    },
    getFeatureFlagsSuccess: (
      state: FeatureTogglesState,
      action: PayloadAction<ExtendedFeatureFlag[]>
    ) => {
      state.allFeatureFlags.data = action.payload;
      state.allFeatureFlags.loading = false;
      return state;
    },
    getFeatureFlagsFailed: (
      state: FeatureTogglesState,
      action: PayloadAction<string>
    ) => {
      state.allFeatureFlags.error = action.payload;
      state.allFeatureFlags.loading = false;
      return state;
    },
    setStateChangeDialogOpen: (
      state: FeatureTogglesState,
      action: PayloadAction<boolean>
    ) => {
      state.isStateChangeDialogOpen.data = action.payload;
      return state;
    },
    updateFeatureFlagStart: (state: FeatureTogglesState) => {
      state.updateFeatureFlag.loading = true;
      return state;
    },
    updateFeatureFlagSuccess: (
      state: FeatureTogglesState,
      action: PayloadAction<FeatureFlag>
    ) => {
      state.updateFeatureFlag.data = action.payload;
      state.updateFeatureFlag.loading = false;
      updateStateWithNotificationMessage({
        state,
        text: 'Feature flag was updated successfully',
        variant: WMSnackbarVariant.Success,
        isOpen: true,
      });

      return state;
    },
    updateFeatureFlagFailed: (
      state: FeatureTogglesState,
      action: PayloadAction<string>
    ) => {
      state.updateFeatureFlag.error = action.payload;
      state.updateFeatureFlag.loading = false;
      updateStateWithNotificationMessage({
        state,
        text: action.payload,
        variant: WMSnackbarVariant.Error,
        isOpen: true,
      });
      return state;
    },
    createFeatureFlagStart: (state: FeatureTogglesState) => {
      state.createEditFeatureFlag.loading = true;
      return state;
    },
    createFeatureFlagSuccess: (
      state: FeatureTogglesState,
      action: PayloadAction<FeatureFlag>
    ) => {
      state.createEditFeatureFlag.data = action.payload;
      state.createEditFeatureFlag.loading = false;
      updateStateWithNotificationMessage({
        state,
        text: 'Feature flag was created successfully',
        variant: WMSnackbarVariant.Success,
        isOpen: true,
      });

      return state;
    },
    createFeatureFlagFailed: (
      state: FeatureTogglesState,
      action: PayloadAction<string>
    ) => {
      state.createEditFeatureFlag.error = action.payload;
      state.createEditFeatureFlag.loading = false;
      updateStateWithNotificationMessage({
        state,
        text: action.payload,
        variant: WMSnackbarVariant.Error,
        isOpen: true,
      });

      return state;
    },
    getOwnerTeamsStart: (state: FeatureTogglesState) => {
      state.allOwnerTeams.loading = true;
      return state;
    },
    getOwnerTeamsSuccess: (
      state: FeatureTogglesState,
      action: PayloadAction<OwnerTeam[]>
    ) => {
      state.allOwnerTeams.data = action.payload;
      state.allOwnerTeams.loading = false;
      return state;
    },
    getOwnerTeamsFailed: (
      state: FeatureTogglesState,
      action: PayloadAction<string>
    ) => {
      state.allOwnerTeams.error = action.payload;
      state.allOwnerTeams.loading = false;
      return state;
    },
    createOwnerTeamStart: (state: FeatureTogglesState) => {
      state.createOwnerTeam.loading = true;
      return state;
    },
    createOwnerTeamSuccess: (
      state: FeatureTogglesState,
      action: PayloadAction<OwnerTeam>
    ) => {
      state.createOwnerTeam.data = action.payload;
      state.createOwnerTeam.loading = false;
      updateStateWithNotificationMessage({
        state,
        text: `Team ${action.payload.name} was created successfully`,
        variant: WMSnackbarVariant.Success,
        isOpen: true,
      });

      return state;
    },
    createOwnerTeamFailed: (
      state: FeatureTogglesState,
      action: PayloadAction<string>
    ) => {
      state.createOwnerTeam.error = action.payload;
      state.createOwnerTeam.loading = false;
      updateStateWithNotificationMessage({
        state,
        text: action.payload,
        variant: WMSnackbarVariant.Error,
        isOpen: true,
      });

      return state;
    },
    setCreateTeamDialogOpen: (
      state: FeatureTogglesState,
      action: PayloadAction<boolean>
    ) => {
      state.isCreateTeamDialogOpen.data = action.payload;
      return state;
    },
    setCreateEditFeatureFlagDialogOpen: (
      state: FeatureTogglesState,
      action: PayloadAction<boolean>
    ) => {
      state.isCreateEditFeatureFlagDialogOpen.data = action.payload;
      return state;
    },
    setHistoryDialogOpen: (
      state: FeatureTogglesState,
      action: PayloadAction<boolean>
    ) => {
      state.isHistoryDialogOpen.data = action.payload;
      return state;
    },
    setEvaluationsDialogOpen: (
      state: FeatureTogglesState,
      action: PayloadAction<boolean>
    ) => {
      state.isEvaluationsDialogOpen.data = action.payload;
      return state;
    },
    getFeatureFlagHistoryStart: (state: FeatureTogglesState) => {
      state.featureFlagHistory.loading = true;
      return state;
    },
    getFeatureFlagHistorySuccess: (
      state: FeatureTogglesState,
      action: PayloadAction<HistoryRecord[]>
    ) => {
      state.featureFlagHistory.data = action.payload;
      state.featureFlagHistory.loading = false;
      return state;
    },
    getFeatureFlagHistoryFailed: (
      state: FeatureTogglesState,
      action: PayloadAction<string>
    ) => {
      state.featureFlagHistory.error = action.payload;
      state.featureFlagHistory.loading = false;
      return state;
    },
    getFeatureFlagEvaluationsStart: (state: FeatureTogglesState) => {
      state.featureFlagEvaluations.loading = true;
      return state;
    },
    getFeatureFlagEvaluationsSuccess: (
      state: FeatureTogglesState,
      action: PayloadAction<Evaluation[]>
    ) => {
      state.featureFlagEvaluations.data = action.payload;
      state.featureFlagEvaluations.loading = false;
      return state;
    },
    getFeatureFlagEvaluationsFailed: (
      state: FeatureTogglesState,
      action: PayloadAction<string>
    ) => {
      state.featureFlagEvaluations.error = action.payload;
      state.featureFlagEvaluations.loading = false;
      return state;
    },
    cleanupHistoryDialog: (state: FeatureTogglesState) => {
      state.featureFlagHistory.data = null;
      return state;
    },
    cleanupEvaluationsDialog: (state: FeatureTogglesState) => {
      state.featureFlagEvaluations.data = null;
      return state;
    },
    setNotificationMessage(
      state: FeatureTogglesState,
      action: PayloadAction<NotificationMessage>
    ) {
      state.notificationMessage = action.payload;
      return state;
    },
    cleanUpNotificationMessage(state: FeatureTogglesState) {
      state.notificationMessage.isOpen = false;
      state.notificationMessage.text = '';
      state.notificationMessage.variant = WMSnackbarVariant.Success;
      return state;
    },
  },
});

export { featureTogglesSlice };

const {
  getFeatureFlagsStart,
  getFeatureFlagsSuccess,
  getFeatureFlagsFailed,
  updateFeatureFlagStart,
  updateFeatureFlagSuccess,
  updateFeatureFlagFailed,
  setStateChangeDialogOpen,
  setCreateEditFeatureFlagDialogOpen,
  createFeatureFlagStart,
  createFeatureFlagSuccess,
  createFeatureFlagFailed,
  getOwnerTeamsStart,
  getOwnerTeamsSuccess,
  getOwnerTeamsFailed,
  setCreateTeamDialogOpen,
  createOwnerTeamStart,
  createOwnerTeamSuccess,
  createOwnerTeamFailed,
  getFeatureFlagHistoryStart,
  getFeatureFlagHistorySuccess,
  getFeatureFlagHistoryFailed,
  getFeatureFlagEvaluationsStart,
  getFeatureFlagEvaluationsSuccess,
  getFeatureFlagEvaluationsFailed,
} = featureTogglesSlice.actions;

export const getFeatureFlags =
  (forceLoad: boolean): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(getFeatureFlagsStart());
      const featureFlags: FeatureFlag[] =
        await featureTogglesApi.getFeatureFlags();

      const userIds = featureFlags?.map((flag) => flag.createdBy);
      const uniqueUserIds = Array.from(
        new Set(userIds?.filter((origin) => origin !== OriginSource.user))
      );
      const users = await accountsGraphqlCaller.geyUsersByIds(uniqueUserIds);

      const updatedFeatureFlags: ExtendedFeatureFlag[] = featureFlags?.map(
        (flag) => {
          if (flag.origin === OriginSource.import) {
            return { ...flag, createdBy: 'Import' };
          }

          if (flag.createdBy === OriginSource.sync) {
            return { ...flag, createdBy: 'Sync' };
          }

          const user = users?.find((user) => user?.id === flag.createdBy);
          return user
            ? {
                ...flag,
                createdBy: {
                  email: user.email,
                  name: user.firstName + ' ' + user.lastName,
                },
              }
            : { ...flag, createdBy: 'N/A' };
        }
      );

      dispatch(getFeatureFlagsSuccess(updatedFeatureFlags));
    } catch (err) {
      dispatch(getFeatureFlagsFailed(err.message));
    }
  };

export const updateFeatureFlagState =
  (name: string, ownerTeam: string, state: boolean): featuresAppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateFeatureFlagStart());
      const updatedFeatureFlag: FeatureFlag =
        await featureTogglesApi.updateFeatureFlagState(name, ownerTeam, state);
      dispatch(getFeatureFlags(true));
      dispatch(updateFeatureFlagSuccess(updatedFeatureFlag));
      dispatch(setStateChangeDialogOpen(false));
    } catch (err) {
      dispatch(updateFeatureFlagFailed(err.message));
    }
  };

export const updateFeatureFlagStatus =
  (name: string, ownerTeam: string, archiveStatus: string): featuresAppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(updateFeatureFlagStart());
      const updatedFeatureFlag: FeatureFlag =
        await featureTogglesApi.updateFeatureFlagStatus(
          name,
          ownerTeam,
          archiveStatus
        );
      dispatch(getFeatureFlags(true));
      dispatch(updateFeatureFlagSuccess(updatedFeatureFlag));
    } catch (err) {
      dispatch(updateFeatureFlagFailed(err.message));
    }
  };

export const getOwnerTeams =
  (forceLoad: boolean): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(getOwnerTeamsStart());
      const ownerTeams: OwnerTeam[] = await featureTogglesApi.getOwnerTeams();
      dispatch(getOwnerTeamsSuccess(ownerTeams));
    } catch (err) {
      dispatch(getOwnerTeamsFailed(err.message));
    }
  };

export const createOwnerTeam =
  (name: string): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(createOwnerTeamStart());
      const createdOwnerTeam = await featureTogglesApi.createOwnerTeam(name);
      dispatch(createOwnerTeamSuccess(createdOwnerTeam));
      dispatch(setCreateTeamDialogOpen(false));
    } catch (err) {
      dispatch(createOwnerTeamFailed(err.message));
    }
  };

export const createFeatureFlag =
  (newFeatureFlag, validate: boolean, sync: boolean): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(createFeatureFlagStart());
      const createdFeatureFlag = await featureTogglesApi.createFeatureFlag(
        newFeatureFlag,
        validate,
        sync
      );
      dispatch(getFeatureFlags(true));
      dispatch(createFeatureFlagSuccess(createdFeatureFlag));
      dispatch(setCreateEditFeatureFlagDialogOpen(false));
    } catch (err) {
      dispatch(createFeatureFlagFailed(err.message));
    }
  };

export const editFeatureFlag =
  (updateFeatureFlag, validate: boolean): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateFeatureFlagStart());
      const updatedFeatureFlag: FeatureFlag =
        await featureTogglesApi.updateFeatureFlag(updateFeatureFlag, validate);
      dispatch(getFeatureFlags(true));
      dispatch(updateFeatureFlagSuccess(updatedFeatureFlag));
      dispatch(setCreateEditFeatureFlagDialogOpen(false));
    } catch (err) {
      dispatch(updateFeatureFlagFailed(err.message));
      throw new Error(err.message);
    }
  };

export const getFeatureFlagHistory =
  (name: string, ownerTeam: string): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(getFeatureFlagHistoryStart());
      const featureFlagHistory = await featureTogglesApi.getFeatureFlagHistory(
        name,
        ownerTeam
      );

      const userIds: string[] = featureFlagHistory
        ?.map((flag) => flag.updatedBy)
        .filter((userId): userId is string => userId !== undefined);
      const uniqueUserIds = Array.from(new Set(userIds));
      const users = await accountsGraphqlCaller.geyUsersByIds(uniqueUserIds);
      const updatedFeatureFlagHistory = featureFlagHistory?.map(
        (historyEntry) => {
          const user = users?.find(
            (user) => user?.id === historyEntry.updatedBy
          );
          return user
            ? {
                ...historyEntry,
                updatedBy: {
                  email: user.email,
                  name: user.firstName + ' ' + user.lastName,
                },
              }
            : { ...historyEntry, updatedBy: 'N/A' };
        }
      );

      dispatch(getFeatureFlagHistorySuccess(updatedFeatureFlagHistory));
    } catch (err) {
      dispatch(getFeatureFlagHistoryFailed(err.message));
    }
  };

export const getFeatureFlagEvaluations =
  (featureFlagId: string): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(getFeatureFlagEvaluationsStart());
      const featureFlagEvaluations =
        await featureTogglesApi.getFeatureFlagEvaluations(featureFlagId);
      dispatch(getFeatureFlagEvaluationsSuccess(featureFlagEvaluations));
    } catch (err) {
      dispatch(getFeatureFlagEvaluationsFailed(err.message));
    }
  };
