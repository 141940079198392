import React from 'react';
import { useSelector } from 'react-redux';
import { MergeAccountState } from '../../merge-account.store';
import {
  ExcludedSystemUnassignedUsers,
  MergeAccountsActionResult,
  MergeAccountsActionStatus,
  RoleWithUsers,
  RolesToRecreate,
} from '../../api/merge-account-api.types';
import {
  BoldLabel,
  SectionWrapperDiv,
  StyledStatus,
  SummaryError,
  SummaryHeader,
  SummaryItem,
  SummarySubHeader,
  SummarySubItem,
} from '../merge-summary.styles';

import { StatusVariant } from '@walkme/ui-core';

export interface RolesRecreatdSummaryProps {
  rolesRecreatedResult: MergeAccountsActionResult<RolesToRecreate>;
}

export const RolesRecreatdSummary = ({
  rolesRecreatedResult,
}: RolesRecreatdSummaryProps) => {
  if (!rolesRecreatedResult) {
    return;
  }

  const hasError =
    rolesRecreatedResult.status === MergeAccountsActionStatus.failure;
  const rolesRecreated = hasError
    ? []
    : (Object.values(
      (rolesRecreatedResult.value as RolesToRecreate) || {}
    ) as RoleWithUsers[]);
  const sortedRolesRecreated = [...rolesRecreated].sort((a, b) =>
    a.role.displayName < b.role.displayName ? -1 : 1
  );

  const error = rolesRecreatedResult.value as Error;

  return (
    <SectionWrapperDiv>
      <SummaryHeader>
        <StyledStatus
          variant={!hasError ? StatusVariant.Success : StatusVariant.Error}
        />
        Recreate Roles for Excluded Users:{' '}
        {hasError ? 'Failed' : 'Completed Successfully'}
      </SummaryHeader>
      {hasError && <SummaryError>Error: {error.message}</SummaryError>}
      {!hasError && (
        <>
          <SummarySubHeader>Roles recreated:</SummarySubHeader>
          {
            sortedRolesRecreated.length
              ? sortedRolesRecreated.map((roleWithUsers) => (
                <React.Fragment key={roleWithUsers.role.id}>
                  <SummaryItem key={roleWithUsers.role.id}>
                    Role:&nbsp;
                    <BoldLabel>{roleWithUsers.role.displayName}</BoldLabel>,
                    assigned to:
                  </SummaryItem>
                  {[...roleWithUsers.users]
                    .sort((a, b) => (a.email < b.email ? -1 : 1))
                    .map((user) => (
                      <SummarySubItem key={user.guid}>
                        {user.email}
                      </SummarySubItem>
                    ))}
                </React.Fragment>
              ))
              : <SummaryItem>(None)</SummaryItem>
          }
        </>
      )}
    </SectionWrapperDiv>
  );
};
