import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAiSettingsMetaData,
  getIsEnabled,
  getRules,
  getAiSettings,
  addToAllowList,
  removeFromAllowList,
} from '@wm-accounts-backoffice-center/state-management-ai-manager';
import useAiManagerSnackBar from './use-ai-manager-snackbar';
import { WMSnackbarVariant } from '@walkme/wm-ui';

const useAiManager = () => {
  const dispatch = useDispatch();

  const aiManagerSnackBar = useAiManagerSnackBar();

  const isEnabled = useSelector(getIsEnabled);
  const rules = useSelector(getRules);
  const aiSettingsMetaData = useSelector(getAiSettingsMetaData);

  const handleGetAiSettings = async (accountId: string): Promise<void> => {
    const res: any = await dispatch(getAiSettings(accountId));
    if (res.error) {
      const message = res.payload?.message || 'Failed to get AI settings';
      const reason = res.payload?.reason?.message || '';
      aiManagerSnackBar.showSnackBar({
        isOpen: true,
        variant: WMSnackbarVariant.Error,
        text: `${message} ${reason}`,
      });
    }
  };

  const handleAddToAllowList = async (accountId: string): Promise<void> => {
    const res: any = await dispatch(addToAllowList(accountId));
    if (res.error) {
      const message = res.payload?.message || 'Failed to enable AI settings';
      const reason = res.payload?.reason?.message || '';
      aiManagerSnackBar.showSnackBar({
        isOpen: true,
        variant: WMSnackbarVariant.Error,
        text: `${message} ${reason}`,
      });
      return;
    }
    aiManagerSnackBar.showSnackBar({
      isOpen: true,
      variant: WMSnackbarVariant.Success,
      text: `Success to enable AI settings`,
    });
  };

  const handleRemoveFromAllowList = async (
    accountId: string
  ): Promise<void> => {
    const res: any = await dispatch(removeFromAllowList(accountId));
    if (res.error) {
      const message = res.payload?.message || 'Failed to disable AI settings';
      const reason = res.payload?.reason?.message || '';
      aiManagerSnackBar.showSnackBar({
        isOpen: true,
        variant: WMSnackbarVariant.Error,
        text: `${message} ${reason}`,
      });
      return;
    }
    aiManagerSnackBar.showSnackBar({
      isOpen: true,
      variant: WMSnackbarVariant.Success,
      text: `Success to disable AI settings`,
    });
  };

  return {
    isEnabled,
    rules,
    aiSettingsMetaData,
    handleGetAiSettings,
    handleAddToAllowList,
    handleRemoveFromAllowList,
    ...aiManagerSnackBar,
  };
};

export default useAiManager;
