import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FeaturesState, getAccountFeatures } from '../redux/features.slice';

export const useGetAccountFeatures = (accountId: number) => {
  const accountFeaturesAppData = useSelector(
    (state: { featuresState: FeaturesState }) =>
      state.featuresState.accountFeatures
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAccountFeatures(accountId));
  }, [dispatch]);

  return accountFeaturesAppData;
};

export default useGetAccountFeatures;
