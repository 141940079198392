import * as React from 'react';
import { SVGProps } from 'react';

const SvgAuditLog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.125 18.708h-5.25a.583.583 0 0 1-.583-.583V5.875a.583.583 0 0 1 .583-.583h7.754c.155 0 .303.061.413.17l2.162 2.163c.11.11.17.258.171.412v1.921"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.708 15.209a3.5 3.5 0 1 0 7 0 3.5 3.5 0 0 0-7 0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.375 15.209h-1.167v-1.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgAuditLog;
