import { makeStyles } from '@material-ui/core';

export const useSystemDeletionCenterStyles = makeStyles(() => ({
  contentDiv: {
    height: '100%',
  },
  tabBoxes: {
    marginBottom: '1vh',
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  tab: {
    fontFamily: 'proximaNova',
    color: '#637191',
    fontSize: '14px',
    fontWeight: 400,
    marginRight: '20px',
    padding: '2px',
    textTransform: 'none',
  },
  activeTab: {
    fontFamily: 'proximaNova',
    color: '#3B61EB',
    marginRight: '20px',
    fontSize: '14px',
    fontWeight: 400,
    padding: '2px',
    textTransform: 'none',
  },
  indicator: {
    backgroundColor: '#3B61EB',
  },
  // Common button styles
  buttonBase: {
    height: '32px !important',
    borderRadius: '40px !important',
    '&:hover': {
      backgroundColor: '#B02626 !important',
    },
    '&:active': {
      backgroundColor: '#942020 !important',
    },
    '& .MuiButton-label': {
      color: '#FFFFFF',
      '&:hover': {
        color: '#FFFFFF',
      },
    },
  },
  // Delete Button
  deleteItemsButton: {
    width: '82px',
    backgroundColor: '#EB3232 !important',
    height: '32px !important',
    borderRadius: '40px !important',
    '&:hover': {
      backgroundColor: '#B02626 !important',
    },
    '&:active': {
      backgroundColor: '#942020 !important',
    },
    '& .MuiButton-label': {
      color: '#FFFFFF',
      '&:hover': {
        color: '#FFFFFF',
      },
    },
  },
  deleteButton: {
    width: '150px',
    backgroundColor: '#EB3232 !important',
    height: '32px !important',
    borderRadius: '40px !important',
    '&:hover': {
      backgroundColor: '#B02626 !important',
    },
    '&:active': {
      backgroundColor: '#942020 !important',
    },
    '& .MuiButton-label': {
      color: '#FFFFFF',
      '&:hover': {
        color: '#FFFFFF',
      },
    },
  },
  setDeleteButton: {
    width: '200px',
    backgroundColor: '#EB3232 !important',
    height: '32px !important',
    borderRadius: '40px !important',
    '&:hover': {
      backgroundColor: '#B02626 !important',
    },
    '&:active': {
      backgroundColor: '#942020 !important',
    },
    '& .MuiButton-label': {
      color: '#FFFFFF',
      '&:hover': {
        color: '#FFFFFF',
      },
    },
  },
  // Restore Button
  restoreItemsButton: {
    width: '82px',
    backgroundColor: '#3B61EB !important',
    height: '32px !important',
    borderRadius: '40px !important',
    '&:hover': {
      backgroundColor: '#253D94 !important',
    },
    '&:active': {
      backgroundColor: '#2C49B0 !important',
    },
    '& .MuiButton-label': {
      color: '#FFFFFF',
      '&:hover': {
        color: '#FFFFFF',
      },
    },
  },
  restoreButton: {
    width: '150px',
    backgroundColor: '#3B61EB !important',
    height: '32px !important',
    borderRadius: '40px !important',
    '&:hover': {
      backgroundColor: '#253D94 !important',
    },
    '&:active': {
      backgroundColor: '#2C49B0 !important',
    },
    '& .MuiButton-label': {
      color: '#FFFFFF',
      '&:hover': {
        color: '#FFFFFF',
      },
    },
  },
  customDot: {
    marginRight: '5px',
  },
  warningBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',
    margin: '10px 0',
    backgroundColor: '#FEF8E8',
    border: '1px solid #F7B500',
    borderRadius: '4px',
  },
}));
