import styled from 'styled-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Check,
  Launcher,
  Resource,
  Settings,
  Shotout,
  Shuttle,
  Survey,
  Task,
} from '@walkme/ui-icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import {
  Button,
  IconButton,
  Spinner,
  ToasterVariant,
  useToaster,
} from '@walkme/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import AccountSearch from '../search-components/account-search';
import SearchItem from '../search-components/search-item';
import { AccountsSdk, Systems, WMPartnerClient } from 'wm-accounts-sdk';
import {
  Account,
  apiErrorFormatter,
} from '@wm-accounts-backoffice-center/wm-api';

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    max-width: none;
    width: 1072px;
    border-radius: 12px;
    &.MuiStepper-root {
      padding: 0;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 24px 24px 0px;
  & .MuiTypography-h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #2f426c;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
  background: #f7f9fc;
`;

const StyledDivider = styled(Divider)`
  margin: 16px 0px 16px;
  border: 1px solid #f5f6f8;
`;

const StyledCheck = styled(Check)`
  color: #385feb;
`;

const StyledStepper = styled(Stepper)`
  justify-content: center;
  width: 100%;
  &.MuiStepper-root {
    padding: none;
  }
  & .MuiStepLabel-label {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2f426c !important;
  }
`;

const StyledConnector = styled(StepConnector)`
  max-width: 20px;
  & .MuiStepConnector-line {
    border: 1px solid #e6ecf8;
  }
`;

const StyledStepLabel = styled(StepLabel)`
  & .Mui-active {
    color: #385feb;
  }
  & .Mui-disabled {
    & .MuiStepIcon-root {
      color: #e4e9fc;
    }
    & .MuiStepIcon-text {
      fill: #8d97ae;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const StyledIconeStep = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8d97ae;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #e4e9fc;
  border-radius: 80px;
`;

const StyledIconeStepActive = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #3b61eb;
  border-radius: 80px;
`;

const StyledIconeStepCompleted = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #e4e9fc;
  border: 1px solid #3b61eb;
  border-radius: 80px;
`;

const AccountNameLabel = styled('label')`
  color: #2f426c;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
const SnippetEmailLabel = styled('label')`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8d97ae;
`;

export interface PartnerClientsSelectionProps {
  showDialog: boolean;
  wizardExitCallback: (addedClient: boolean) => void;
  account: Account;
  partnerClients: WMPartnerClient[];
}

interface IStepProps {
  stepTitle: JSX.Element | string;
  content: React.ReactNode;
  setActionTitle: string;
  setNextActionButtonText: string;
  setBackActionButtonText: string;
  setNextActionButtonHnadler: (data) => void;
  setBackActionButtonHnadler: (data) => void;
}

const PartnerClientsSelection = ({
  showDialog,
  wizardExitCallback,
  account,
  partnerClients,
}: PartnerClientsSelectionProps) => {
  const [disableNext, setDisableNext] = useState(true);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [targetAccount, setTargetAccount] = useState<Account>(null);
  const [targetSystems, setTargetSystems] = useState<Systems>([]);
  const [targetUserRoles, setTargetUserRoles] = useState<number[]>([]);

  const roles = account.roles?.filter((role) => !role.accountId);

  const { addToaster } = useToaster();

  const dispatch = useDispatch();

  const handleAssignAccountPartnerClient = useCallback(async () => {
    try {
      if (!targetAccount) {
        return;
      }
      const isPartnerClientExists = partnerClients?.find(
        (client) => client.accountId === targetAccount.id
      );
      setNextButtonLoading(true);
      if (!isPartnerClientExists) {
        await AccountsSdk.getInstance().partners.backofficAddClientForPartner(
          account.id,
          {
            clientAccountId: targetAccount.id,
            clientSystemIds: targetSystems.map((system) => system.id),
            partnerUsersRole: targetUserRoles,
          }
        );
      } else {
        await AccountsSdk.getInstance().partners.backofficeUpdateClientForPartner(
          account.id,
          {
            clientAccountId: targetAccount.id,
            clientSystemIds: targetSystems.map((system) => system.id),
            partnerUsersRole: targetUserRoles,
          }
        );
      }

      addToaster({
        message: `sucessfully update account client`,
        variant: ToasterVariant.Success,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
      cleanupWhenExit(true);
    } catch (e) {
      addToaster({
        message: `failed to update partner client data: ${apiErrorFormatter(
          e
        )}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
      setNextButtonLoading(false);
      cleanupWhenExit(true);
    }
  }, [targetSystems, dispatch, targetAccount]);
  const stepsData: IStepProps[] = [
    {
      stepTitle: 'Select account to assign',
      content: (
        <AccountSearch
          onSelectedAccount={(account) => {
            setTargetAccount(account);
            setDisableNext(account ? false : true);
            if (account) {
              const isPartnerClientExists = partnerClients?.find(
                (client) => client.accountId === account.id
              );
              if (isPartnerClientExists) {
                setTargetSystems(isPartnerClientExists.systems || []);
              }
            }
          }}
        />
      ),
      setActionTitle: ` ${
        targetAccount
          ? `account selected ${targetAccount.name}`
          : `no account selected`
      }`,
      setBackActionButtonHnadler: (data) => {
        cleanupWhenExit(false);
      },
      setNextActionButtonHnadler: (data) => {
        setActiveStep(activeStep + 1);
        setDisableNext(true);
      },
      setBackActionButtonText: 'Close',
      setNextActionButtonText: 'Select account',
    },
    {
      stepTitle: 'Select account allowed roles',
      content: (
        <div>
          <div
            style={{
              height: '370px',
              overflow: 'auto',
              backgroundColor: 'white',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
                marginLeft: '10px',
                marginRight: '10px',
                marginTop: '18px',
              }}
            >
              {roles.map((role) => {
                return (
                  <SearchItem
                    firstText={role.displayName}
                    secondText={''}
                    thirdText={''}
                    circleInitials={role.displayName.charAt(0)}
                    isSelected={
                      targetUserRoles.find(
                        (selectedRole) => selectedRole === role.id
                      )
                        ? true
                        : false
                    }
                    onClickedItem={() => {
                      const selectedRole = targetUserRoles.find(
                        (selectedRole) => selectedRole === role.id
                      );
                      if (selectedRole) {
                        const newRoles = targetUserRoles.filter(
                          (selectedSystem) => selectedRole !== role.id
                        );
                        setDisableNext(newRoles.length === 0);
                        setTargetUserRoles(newRoles);
                      } else {
                        setDisableNext(false);
                        setTargetUserRoles([...targetUserRoles, role.id]);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      ),
      setActionTitle: ` ${
        targetUserRoles.length > 0
          ? `${targetUserRoles.length} rules selected`
          : `no rules selected`
      }`,
      setBackActionButtonHnadler: (data) => {
        setActiveStep(0);
      },
      setNextActionButtonHnadler: (data) => {
        setActiveStep(activeStep + 1);
        setDisableNext(true);
      },
      setBackActionButtonText: 'Back',
      setNextActionButtonText: 'Select allowed roles',
    },
    {
      stepTitle: 'Select account systems to assign',
      content: (
        <div>
          <div
            style={{
              height: '370px',
              overflow: 'auto',
              backgroundColor: 'white',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
                marginLeft: '10px',
                marginRight: '10px',
                marginTop: '18px',
              }}
            >
              {targetAccount?.systems?.map((system) => {
                return (
                  <SearchItem
                    firstText={system.name}
                    secondText={system.email}
                    thirdText={system.guid}
                    circleInitials={system.name?.charAt(0) || 'N/A'}
                    isSelected={
                      targetSystems.find(
                        (selectedSystem) => selectedSystem.id === system.id
                      )
                        ? true
                        : false
                    }
                    onClickedItem={() => {
                      const selectedSystem = targetSystems.find(
                        (selectedSystem) => selectedSystem.id === system.id
                      );
                      if (selectedSystem) {
                        const newSystems = targetSystems.filter(
                          (selectedSystem) => selectedSystem.id !== system.id
                        );
                        setDisableNext(newSystems.length === 0);
                        setTargetSystems(newSystems);
                      } else {
                        setDisableNext(false);
                        setTargetSystems([...targetSystems, system]);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      ),
      setActionTitle: `systems selected ${targetSystems
        ?.map((selectedSystem) => selectedSystem.displayName)
        .join(', ')}`,
      setBackActionButtonHnadler: (data) => {
        setActiveStep(1);
      },
      setNextActionButtonHnadler: (data) => {
        handleAssignAccountPartnerClient();
        setDisableNext(true);
      },
      setBackActionButtonText: 'Back',
      setNextActionButtonText: 'Select account systems',
    },
  ];

  const CustomStepIcon = (props) => {
    const { icon, active, completed } = props;
    if (completed)
      return (
        <StyledIconeStepCompleted>
          <StyledCheck />
        </StyledIconeStepCompleted>
      );
    if (active) return <StyledIconeStepActive>{icon}</StyledIconeStepActive>;
    return <StyledIconeStep>{icon}</StyledIconeStep>;
  };

  const stepper = (title: string, steps: IStepProps[], activeStep: number) => {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ whiteSpace: 'nowrap' }}>{title}</span>
          <StyledStepper
            activeStep={activeStep}
            connector={<StyledConnector />}
          >
            {steps.map((step, index) => {
              const stepProps = {};
              return (
                <Step key={index} {...stepProps}>
                  <StyledStepLabel StepIconComponent={CustomStepIcon}>
                    {step.stepTitle}
                  </StyledStepLabel>
                </Step>
              );
            })}
          </StyledStepper>
          <IconButton onClick={(e) => cleanupWhenExit(false)} variant="ghost">
            <img src="assets/icons/close.svg" alt="Close" />
          </IconButton>
        </div>
        <StyledDivider />
        <div
          style={{ display: 'flex', alignItems: 'center', margin: '16px 0' }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              background: '#ECEFFA',
              borderRadius: '100px',
              width: '40px',
              height: '40px',
              marginRight: '9px',
              textAlign: 'center',
              fontFamily: 'Proxima Nova',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '20px',
              textTransform: 'uppercase',
            }}
          >
            {account.name.charAt(0)}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <AccountNameLabel>{account.name}</AccountNameLabel>
            <SnippetEmailLabel>{account.email}</SnippetEmailLabel>
          </div>
          {targetAccount && activeStep > 0 && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="/assets/ArrowRight.svg" style={{ margin: '0 24px' }} />
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  background: '#ECEFFA',
                  borderRadius: '100px',
                  width: '40px',
                  height: '40px',
                  marginRight: '9px',
                  textAlign: 'center',
                  fontFamily: 'Proxima Nova',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '20px',
                  textTransform: 'uppercase',
                }}
              >
                {targetAccount.name.charAt(0)}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <AccountNameLabel>{targetAccount.name}</AccountNameLabel>
                <SnippetEmailLabel>{targetAccount.email}</SnippetEmailLabel>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const cleanupWhenExit = useCallback(
    (addedNewClient: boolean) => {
      wizardExitCallback(addedNewClient);
      setActiveStep(0);
      setNextButtonLoading(false);
      setTargetSystems([]);
      setTargetAccount(null);
    },
    [wizardExitCallback]
  );

  const wizardTitle = `Add Partner Clients`;

  return (
    <StyledDialog
      open={showDialog}
      onClose={() => {
        cleanupWhenExit(false);
      }}
    >
      <StyledDialogTitle>
        {stepper(wizardTitle, stepsData, activeStep)}
      </StyledDialogTitle>
      <StyledDialogContent>
        {stepsData && stepsData[activeStep] && stepsData[activeStep].content}
      </StyledDialogContent>
      <DialogActions
        style={{
          padding: '16px 32px 32px',
          justifyContent: 'space-between',
        }}
      >
        <label
          style={{
            fontFamily: 'Proxima Nova',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#637191',
          }}
        >
          {(stepsData &&
            stepsData[activeStep] &&
            stepsData[activeStep].setActionTitle) ||
            ''}
        </label>

        <div style={{ display: 'flex', gap: '14px' }}>
          <Button
            variant="outlined"
            onClick={(e) => {
              stepsData[activeStep]?.setBackActionButtonHnadler(null);
            }}
            size="large"
          >
            {stepsData[activeStep]?.setBackActionButtonText}
          </Button>
          <Button
            onClick={() => {
              stepsData[activeStep]?.setNextActionButtonHnadler(null);
            }}
            disabled={disableNext}
            size="large"
          >
            {nextButtonLoading ? (
              <Spinner />
            ) : (
              stepsData[activeStep]?.setNextActionButtonText
            )}
          </Button>
        </div>
      </DialogActions>
    </StyledDialog>
  );
};

export default PartnerClientsSelection;
