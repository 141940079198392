import React from 'react';
import { useStepStyles } from '../steps/wizard-steps.styles';
import { AccountCardItems } from './components.styles';

export interface AccountCardProps {
  account: {
    id: number;
    name: string;
    email: string;
    guid?: string;
  };
  isSelected: boolean;
  onClicked: (id: number) => void;
}

export const AccountCard = ({
  account,
  isSelected,
  onClicked,
}: AccountCardProps) => {
  const classes = useStepStyles();

  return (
    <div
      className={
        isSelected ? classes.selectedAccount : classes.notSelectedAccount
      }
      onClick={() => onClicked?.(account?.id)}
    >
      <div className={classes.cardNameInitial}>{account?.name?.charAt(0)}</div>
      <AccountCardItems>
        <label className={classes.accountNameLabel}>{account?.name}</label>
        <label className={classes.snippetEmailLabel}>{account?.email}</label>
        <label className={classes.snippetEmailLabel}>{account?.guid}</label>
      </AccountCardItems>
    </div>
  );
};
