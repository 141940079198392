import React from 'react';
import styled from 'styled-components';
import { WMDataGrid } from '@walkme/wm-ui';
import { Loader, Status } from '@walkme/ui-core';

export const MergeLogContainer = styled.div`
  height: 100%;
`;

export const StyledWMDataGrid = styled(WMDataGrid)`
  .WMDataGridHeader-wmDataGridHeader {
    align-items: center;
  }
  .WMSearchFilter-clearButton {
    top: 20%;
  }
`;

export const StyledStatus = styled(Status)`
  vertical-align: middle;
  margin-right: 9px;
  margin-bottom: 4px;
`;

export const StyledLoader = () => (
  <div
    style={{
      background: 'white',
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '150px',
    }}
  >
    <Loader />
  </div>
);
