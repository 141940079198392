import * as React from 'react';
import { SVGProps } from 'react';

const SvgImport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 11.5v3a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v0"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M18.5 11.5v-1a2 2 0 0 0-2-2h-9m0 0 2-2m-2 2 2 2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgImport;
