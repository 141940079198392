import React, { ReactElement, useRef } from 'react';
import { StyledMenu, StyledMenuItem } from './styles/styles';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { renderMenuItem } from './utils/utils';
import { MenuProps } from './types/types';

interface SubMenuProps extends MenuProps {
  title?: string;
  icon?: JSX.Element;
  onSubOpen?: () => void;
  subOpen: boolean;
}

const SubMenuItem = ({
  title,
  items,
  subSide,
  icon,
  currentSelectedInSub,
  onSubOpen,
  subOpen,
}: SubMenuProps): ReactElement => {
  const subMenuRef = useRef(null);
  const handleItemClick = (e) => {
    e.stopPropagation();
    subMenuRef.current = e.currentTarget;
    onSubOpen();
  };

  return (
    <>
      <StyledMenuItem onClick={handleItemClick} data-testid="sub-menu-handle">
        <span className="icon">{icon}</span>
        <span className="text">{title}</span>
        <ArrowRightIcon />
      </StyledMenuItem>
      <StyledMenu
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        className={subSide}
        open={subOpen}
        anchorEl={subMenuRef.current && subMenuRef.current}
        data-testid="sub-menu"
      >
        {items.map((item, i) => (
          <StyledMenuItem key={i} onClick={item.onClick}>
            {renderMenuItem(item, currentSelectedInSub)}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default SubMenuItem;
