import { combineReducers } from '@reduxjs/toolkit';
import { accountSlice } from '@wm-accounts-backoffice-center/state-management-users';
import { accountsSearchSlice } from '@wm-accounts-backoffice-center/state-management-accounts-search';
import { featureSlice } from '@wm-accounts-backoffice-center/state-management-features';
import { webHooksSlice } from '../../../../libs/pages/developer-hub/src/lib/webhooks-management/state-management-webhooks/src';
import { tableSlice } from '../../../../libs/ui-components/src/lib/data-table/table.slice';
import { systemsSlice } from '../../../../libs/state-management-systems/src';
import { environmentsSlice } from 'libs/state-management-environments/src';
import { excludedEnvironmentsSlice } from 'libs/state-management-environments/src/lib/redux/excluded-environments.slice';
import { deployablesSlice } from '@wm-accounts-backoffice-center/state-management-deployables';
import { copyAccountSlice } from '@wm-accounts-backoffice-center/state-management-copy-account';
import { auditLogsSlice } from '@wm-accounts-backoffice-center/state-management-auditlog';
import { defaultfeatureSlice } from '@wm-accounts-backoffice-center/state-management-default-features';
import { aiManagerSlice } from '@wm-accounts-backoffice-center/state-management-ai-manager';
import { featureTogglesSlice } from '../../../../libs/state-management-feature-toggles/src';

export const rootReducer = combineReducers({
  accountState: accountSlice.reducer,
  accountsSearchState: accountsSearchSlice.reducer,
  featuresState: featureSlice.reducer,
  tableState: tableSlice.reducer,
  systemsState: systemsSlice.reducer,
  environmentsState: environmentsSlice.reducer,
  excludedEnvironmentsState: excludedEnvironmentsSlice.reducer,
  deployablesState: deployablesSlice.reducer,
  copyAccountState: copyAccountSlice.reducer,
  auditLogState: auditLogsSlice.reducer,
  defaultFeatureState: defaultfeatureSlice.reducer,
  aiManagerState: aiManagerSlice.reducer,
  featureTogglesState: featureTogglesSlice.reducer,
});

export default rootReducer;
