import styled from 'styled-components';

export const Card = styled('div')`
  background: #ffffff;
  border-radius: 8px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  margin-top: 26px;
`;

export const Title = styled('label')`
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #2f426c;
`;
