import { WMhttpClient, wmhttpClientSharedInstance } from './wm-http-client';
import { WebHook } from '../../../pages/developer-hub/src/lib/webhooks-management/state-management-webhooks/src';

class WebhooksApi {
  private readonly httpClient: WMhttpClient;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
  }

  errorParser(e) {
    return e.response?.data?.errors?.[0]?.message || e;
  }

  async getEventLastMessages(
    eventName: string,
    signal?: AbortSignal
  ): Promise<object[]> {
    try {
      const response = await this.httpClient.instance.get(
        `accounts-webhooks/${eventName}/lastMessages`,
        {
          signal,
        }
      );

      if (response?.data?.error) {
        throw new Error(`${JSON.stringify(response.data.error)}`);
      }

      return response.data;
    } catch (e) {
      const error = this.errorParser(e);
      throw new Error(`failed to fetch messages: ${error}`);
    }
  }

  async getWebhooks() {
    try {
      const wehHooksResponse = await this.httpClient.instance.get(
        'accounts-webhooks/webhook'
      );

      if (wehHooksResponse?.data?.error) {
        throw new Error(`${JSON.stringify(wehHooksResponse.data.error)}`);
      }

      return wehHooksResponse.data;
    } catch (e) {
      const error = this.errorParser(e);

      throw new Error(`failed to fetch webhooks: ${error}`);
    }
  }

  async createWebhook(webhook: WebHook) {
    try {
      const createdWebhookResponse = await this.httpClient.instance.post(
        'accounts-webhooks/webhook',
        webhook
      );
      return createdWebhookResponse.data;
    } catch (e) {
      const error = this.errorParser(e);

      throw new Error(`failed to create webhook: ${error}`);
    }
  }

  async deleteWebhook(webhookAlias: string) {
    try {
      const deleteWehHookResponse = await this.httpClient.instance.delete(
        `accounts-webhooks/webhook/${webhookAlias}`
      );

      if (deleteWehHookResponse?.data?.error) {
        throw new Error(`${JSON.stringify(deleteWehHookResponse.data.error)}`);
      }

      return deleteWehHookResponse.data;
    } catch (e) {
      const error = this.errorParser(e);

      throw new Error(`failed to delete webhook: ${error}`);
    }
  }
}

export const webHooksApi = new WebhooksApi(wmhttpClientSharedInstance);
