import { Typography } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Spinner,
} from '@walkme/ui-core';
import { WMIconWarning } from '@walkme/wm-ui';
import React, { useCallback, useRef, useState } from 'react';
import { FeatureFlag } from '@wm-accounts-backoffice-center/state-management-features';
import { exportData } from './utils';

export interface FeaturesDisableWarningDialogProps {
  features: FeatureFlag[];
  onClose: () => void;
  isOpen: boolean;
  onConfirm: (features: FeatureFlag[]) => void;
  loading: boolean;
}

export function FeaturesDisableWarningDialog({
  features,
  isOpen,
  onConfirm,
  onClose,
  loading,
}: FeaturesDisableWarningDialogProps) {
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogTitle>Disable Features</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to
          <b
            style={{
              color: '#EB3232',
              fontSize: '18px',
            }}
          >
            {' '}
            {`disable ${features.length}`}
          </b>{' '}
          features
        </DialogContentText>
        <DialogContentText>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 16px',
              marginBottom: '10px',
              marginTop: '10px',
              backgroundColor: '#FEF8E8',
              border: `1px solid #F7B500`,
              borderRadius: '4px',
            }}
          >
            <WMIconWarning
              color={'#F7B500'}
              size={20}
              style={{ flexShrink: 0, paddingRight: '12px' }}
            />
            <Typography>
              For backup purposes, please export the list of all selected
              features prior to the action.{' '}
              <Button variant="text" onClick={() => exportData(features)}>
                Export now
              </Button>
            </Typography>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" disabled={loading}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          color={'danger'}
          onClick={() => {
            onConfirm(features);
          }}
          variant="solid"
        >
          {loading ? <Spinner /> : 'Disable'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default FeaturesDisableWarningDialog;
