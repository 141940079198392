import styled from 'styled-components';
import { ChartSize } from './evaluations.lib';

export const ChartContainer = styled.div`
  width: ${(props) => props.size}px;
  height: 10px;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  gap: 1px;
`;

export const PrimaryBar = styled.div`
  background-color: ${(props) => props.color};
  height: 100%;
  border-radius: 0 5px 5px 0;
  width: ${(props) => props.width}px;
  transition: width 0.3s ease;
`;

export const SecondaryBar = styled.div`
  background-color: ${(props) => props.color};
  height: 100%;
  border-radius: 5px 0 0 5px;
  width: ${(props) => props.width}px;
  transition: width 0.3s ease;
`;

export const ChartTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: ${(props) => (props.size === ChartSize.SMALL ? '0' : '8px')};

  .chart-title-total {
    min-width: ${(props) => (props.size === ChartSize.SMALL ? '50px' : '')};
  }
`;

export const StyledEvaluation = styled.div`
  padding: 8px 8px 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 410px;
`;

export const EvaluationChartContainer = styled.div`
  margin-bottom: ${(props) =>
    props.size === ChartSize.DEFAULT ? '12px' : '0'};

  ${(props) =>
    props.size === ChartSize.SMALL &&
    `
    display: flex;
    align-items: center;
    gap: 12px;
  `}
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    color: white;
  }

  .color-map {
    display: flex;
    gap: 8px;
    flex-direction: column;

    .color-description {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .color-description-key {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
`;
