import React, { useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginDonePage = () => {
  useEffect(() => {
    setTimeout(function () {
      window.close();
    }, 5000);
  }, []);

  return (
    <Container>Login is Done! We will navigate you shortly back...</Container>
  );
};

export default LoginDonePage;
