import {
  MergeAccountsActionStatus,
  MergeAccountsResult,
} from '../api/merge-account-api.types';

export const doesMergeHaveErrors = (
  mergeAccountResult: MergeAccountsResult
): boolean => {
  return (
    mergeAccountResult.users.status === MergeAccountsActionStatus.failure ||
    mergeAccountResult.apiKeys.status === MergeAccountsActionStatus.failure ||
    mergeAccountResult.idps.status === MergeAccountsActionStatus.failure ||
    mergeAccountResult.roles.status === MergeAccountsActionStatus.failure ||
    mergeAccountResult.systems.status === MergeAccountsActionStatus.failure ||
    mergeAccountResult.unassignExcludedSystems.status ===
      MergeAccountsActionStatus.failure ||
    mergeAccountResult.unassignExcludedUsers.status ===
      MergeAccountsActionStatus.failure ||
    mergeAccountResult.recreatedRoles.status ===
      MergeAccountsActionStatus.failure
  );
};
