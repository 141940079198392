/**
 * @name WmTheme
 */
import { tags as t } from '@lezer/highlight';
import { createTheme, CreateThemeOptions } from '@uiw/codemirror-themes';

export const defaultSettingsWmTheme: CreateThemeOptions['settings'] = {
  background: '#2F426C',
  gutterBackground: '#2F426C',
  foreground: '#FFFFFF',
  gutterForeground: '#637191',
  caret: '#FFFFFF',
  selection: '#003d73',
  selectionMatch: '#003d73',
  lineHighlight: '#36334280',
};

export const wmThemeInit = (options?: Partial<CreateThemeOptions>) => {
  const { theme = 'dark', settings = {}, styles = [] } = options || {};
  return createTheme({
    theme: theme,
    settings: {
      ...defaultSettingsWmTheme,
      ...settings,
    },
    styles: [
      { tag: [t.standard(t.tagName), t.tagName], color: '#DFC162' },
      { tag: [t.bracket], color: '#FFFFFF' },
      { tag: [t.comment], color: '#9CBDE5' },
      { tag: [t.className, t.propertyName], color: '#d2a8ff' },
      {
        tag: [t.variableName, t.attributeName, t.number, t.operator],
        color: '#F7B500',
      },
      {
        tag: [t.keyword, t.typeName, t.typeOperator, t.typeName],
        color: '#FFFFFF',
      },
      { tag: [t.string, t.meta, t.regexp], color: '#5ACE8A' },
      { tag: [t.name, t.quote], color: '#7ee787' },
      { tag: [t.heading], color: '#d2a8ff', fontWeight: '600' },
      { tag: [t.emphasis], color: '#FFFFFF', fontStyle: 'italic' },
      { tag: [t.deleted], color: '#ffdcd7', backgroundColor: 'ffeef0' },
      { tag: [t.atom, t.bool, t.special(t.variableName)], color: '#ffab70' },
      { tag: t.link, textDecoration: 'underline' },
      { tag: t.strikethrough, textDecoration: 'line-through' },
      { tag: t.invalid, color: '#f97583' },
      ...styles,
    ],
  });
};

export const wmTheme = wmThemeInit();
