import { makeStyles } from '@material-ui/core';
import { WMDivider } from '@walkme/wm-ui';
import {
  Button,
  Chip,
  ChipVariant,
  InputSearch,
  ITagProps,
  Tag,
  TagList,
} from '@walkme/ui-core';
import {
  DeployablesState,
  useDeployables,
} from '@wm-accounts-backoffice-center/state-management-deployables';
import { getAdminSpaceBaseURL } from '@wm-accounts-backoffice-center/wm-api';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoColor } from '@walkme/ui-icons/large';
import { Data_Labels, DeployablesTagList } from '@walkme/deployables-tag-list';
import { useStyles } from './page4.styles';

export interface CopyAccountWizardPage4SameDcProps {
  setDisabled: (disabled: boolean) => void;
  reselectData: () => void;
  systemsToCopy: any[];
  deployablesDetails: any[];
}
const CopyAccountWizardPage4SameDc = ({
  setDisabled,
  reselectData,
  systemsToCopy,
  deployablesDetails,
}: CopyAccountWizardPage4SameDcProps) => {
  const classes = useStyles();
  const ADMIN_SPACE_BASE_URL = getAdminSpaceBaseURL();
  const dispatch = useDispatch();
  const DeployablesTypesAppData = useSelector(
    (state: { deployablesState: DeployablesState }) =>
      state.deployablesState.realDeployablesTypes
  );
  const systemToCopySummary: ITagProps[] = systemsToCopy.map((system) => {
    return {
      label: system.displayName,
      iconComponent: (
        <img
          src={`${ADMIN_SPACE_BASE_URL}/assets/icons/icon-${system.name}.png`}
          width="16px"
          height="16px"
        />
      ),
      className: classes.tag,
    };
  });

  const deployablesCount = deployablesDetails?.length
    ? deployablesDetails[0].counter
      ? deployablesDetails.reduce((acc, curr) => acc + curr.counter, 0)
      : deployablesDetails.length
    : 0;

  return (
    <div style={{ margin: '32px' }}>
      <div
        style={{
          background: 'white',
          borderRadius: '12px',
          minHeight: '530px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 32px 0',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <label className={classes.titleLabel}>Content Summary</label>
            <Button variant="text" onClick={() => reselectData()}>
              Reselect data
            </Button>
          </div>

          <div className={classes.boxDiv}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <label className={classes.minTitle}> Systems </label>
              <div className={classes.tagChip}>
                <label className={classes.labelChip}>
                  {systemToCopySummary.length.toString()}
                </label>
              </div>
            </div>
            <WMDivider style={{ margin: '8px 0' }} />
            <div>
              <TagList
                labels={systemToCopySummary}
                maxTagsShown={4}
                className={classes.tagList}
              />
            </div>
          </div>
          <div className={classes.boxDiv}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <label className={classes.minTitle}> Content Items </label>
              <div className={classes.tagChip}>
                <label className={classes.labelChip}>
                  {deployablesCount.toString()}
                </label>
              </div>
            </div>
            <WMDivider style={{ margin: '8px 0' }} />
            <div>
              <DeployablesTagList
                maxTagsShown={3}
                types={DeployablesTypesAppData.data}
                dataLabels={deployablesDetails}
                showLabels
              />
            </div>
          </div>
          <div style={{ padding: '12px 24px' }}>
            <div className={classes.boxInfo}>
              <InfoColor className={classes.infoColorIcon} />
              <div className={classes.infoText}>
                All system Features and Roles are Automatically copied. Users
                are not Automatically copied. To view the system in the editor,
                you need to assign your self to the copied system.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyAccountWizardPage4SameDc;
