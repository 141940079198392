import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import {
  AccountImpersonationRestrictedData,
  AccountsSdk,
  Role,
  Roles,
  User,
} from 'wm-accounts-sdk';
import { AppData } from '@wm-accounts-backoffice-center/general-types';
import { ThunkAction } from 'redux-thunk';
import {
  accessControlApi,
  Account,
  accountsGraphqlCaller,
  apiErrorFormatter,
} from '@wm-accounts-backoffice-center/wm-api';
import { get } from 'lodash';

export interface BackofficeRolesData {
  isBackofficeAdmin: boolean;
  backofficeRoles: Roles;
}
export interface AccountState {
  loggedInUser: AppData<User>;
  accountById: AppData<Account>;
  accountImpersonationSettings: AppData<AccountImpersonationRestrictedData>;
  backofficeRoles: AppData<BackofficeRolesData>;
}

export const initialUser: User = {
  status: '',
  id: null,
  email: null,
  firstName: null,
  lastLoginDate: null,
  lastName: null,
  idpNameId: null,
  isBackoffice: false,
  account: {
    id: null,
    email: '',
    types: null,
  },
};

export const initialAccount: Account = {
  id: null,
  email: null,
  paid: false,
  name: null,
  featureFlags: [],
  systems: [],
  roles: [],
  users: [],
  guid: null,
  sfdcAccountId: '',
  creationDate: '',
};

export const initialAccountState: AccountState = {
  accountImpersonationSettings: {
    loading: false,
    error: null,
    data: null,
  },
  loggedInUser: {
    loading: false,
    error: null,
    data: initialUser,
  },
  accountById: {
    loading: false,
    error: null,
    data: initialAccount,
  },
  backofficeRoles: {
    loading: false,
    error: null,
    data: null,
  },
};

const accountSlice = createSlice({
  name: 'accountSlice',
  initialState: initialAccountState,
  reducers: {
    getLoggedInUserStart(state: AccountState) {
      state.loggedInUser.error = null;
      state.loggedInUser.loading = true;
      return state;
    },
    getLoggedInUserSuccess(state: AccountState, action: PayloadAction<User>) {
      state.loggedInUser.data = action.payload;
      state.loggedInUser.loading = false;
      state.loggedInUser.error = null;
      return state;
    },
    getLoggedInUserFailed(state, action: PayloadAction<string>) {
      state.loggedInUser.loading = false;
      state.loggedInUser.error = action.payload;
      return state;
    },
    accountImpersonationSettingsStart(state: AccountState) {
      state.accountImpersonationSettings.error = null;
      state.accountImpersonationSettings.loading = true;
      return state;
    },
    accountImpersonationSettingsSuccess(
      state: AccountState,
      action: PayloadAction<AccountImpersonationRestrictedData>
    ) {
      state.accountImpersonationSettings.data = action.payload;
      state.accountImpersonationSettings.loading = false;
      state.accountImpersonationSettings.error = null;
      return state;
    },
    accountImpersonationSettingsFailed(state, action: PayloadAction<string>) {
      state.accountImpersonationSettings.loading = false;
      state.accountImpersonationSettings.error = action.payload;
      return state;
    },
    backofficeRolesStart(state: AccountState) {
      state.backofficeRoles.error = null;
      state.backofficeRoles.loading = true;
      return state;
    },
    backofficeRolesSuccess(
      state: AccountState,
      action: PayloadAction<BackofficeRolesData>
    ) {
      state.backofficeRoles.data = action.payload;
      state.backofficeRoles.loading = false;
      state.backofficeRoles.error = null;
      return state;
    },
    backofficeRolesFailed(state, action: PayloadAction<string>) {
      state.backofficeRoles.loading = false;
      state.backofficeRoles.error = action.payload;
      return state;
    },
    accountByIdStart(state: AccountState) {
      state.accountById.error = null;
      state.accountById.loading = true;
      return state;
    },
    accountByIdSuccess(state: AccountState, action: PayloadAction<Account>) {
      state.accountById.data = action.payload;
      state.accountById.loading = false;
      state.accountById.error = null;
      return state;
    },
    accountByIdFailed(state, action: PayloadAction<string>) {
      state.accountById.loading = false;
      state.accountById.error = action.payload;
      return state;
    },
    accountByIdCleanup(state, action: PayloadAction<string>) {
      state.accountById.loading = false;
      state.accountById.error = null;
      state.accountById.data = initialAccount;
      state.accountImpersonationSettings.loading = false;
      state.accountImpersonationSettings.error = null;
      state.accountImpersonationSettings.data = null;
      return state;
    },
  },
});

export { accountSlice };
const {
  getLoggedInUserFailed,
  getLoggedInUserSuccess,
  getLoggedInUserStart,
  accountByIdStart,
  accountByIdSuccess,
  accountByIdFailed,
  accountByIdCleanup,
  accountImpersonationSettingsStart,
  accountImpersonationSettingsSuccess,
  accountImpersonationSettingsFailed,
  backofficeRolesStart,
  backofficeRolesSuccess,
  backofficeRolesFailed,
} = accountSlice.actions;
export type AccountStateType = ReturnType<typeof accountSlice.reducer>;
type AppThunk = ThunkAction<void, AccountStateType, unknown, Action<string>>;

export const getLoggedInUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getLoggedInUserStart());
    const user: User = await AccountsSdk.getInstance().getLoggedInUserData();
    dispatch(getLoggedInUserSuccess(user));
  } catch (err) {
    dispatch(getLoggedInUserFailed(apiErrorFormatter(err)));
    return;
  }
};

export const getBackofficeRoles = (): AppThunk => async (dispatch) => {
  try {
    dispatch(backofficeRolesStart());
    const isBackofficeAdmin = await accessControlApi.isBackofficeAdmin();
    let backofficeRoles: Roles = [];
    if (isBackofficeAdmin) {
      backofficeRoles = await accessControlApi.getBackofficeRoles();
    }
    dispatch(
      backofficeRolesSuccess({
        isBackofficeAdmin,
        backofficeRoles,
      })
    );
  } catch (err) {
    dispatch(backofficeRolesFailed(apiErrorFormatter(err)));
    return;
  }
};

export const getAccountImpersonationSettings =
  (id: number, forceLoad = false): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(accountImpersonationSettingsStart());
      const impersonationSettings =
        await AccountsSdk.getInstance().impersonationSettings.getOtherAccountImpersonationRestrictionSettings(
          id
        );
      dispatch(accountImpersonationSettingsSuccess(impersonationSettings));
    } catch (err) {
      dispatch(accountImpersonationSettingsFailed(apiErrorFormatter(err)));
      return;
    }
  };

export const getAccountById =
  (id: number, forceLoad = false): AppThunk =>
  async (dispatch, getState) => {
    try {
      const alreadyLoadedAccount = getState().accountById?.data;
      if (alreadyLoadedAccount?.id === id && !forceLoad) {
        return;
      }
      dispatch(getAccountImpersonationSettings(id, forceLoad));
      dispatch(accountByIdStart());
      const account = await accountsGraphqlCaller.getAccountById(id);
      dispatch(accountByIdSuccess(account));
    } catch (err) {
      dispatch(accountByIdFailed(apiErrorFormatter(err)));
      return;
    }
  };

export const hasPermission = (
  userRole: Role,
  actionKey: string,
  permission: string
) => {
  const userPermissions = get(userRole, ['permissions']);
  const foundRole =
    userPermissions &&
    userPermissions.find(
      (perm) => perm.actionKey === actionKey && perm.permission === permission
    );
  return !!foundRole;
};
