import React from 'react';
import { ListItemText } from '@mui/material-int';

export const TimestampCell = (params) => {
  const timestamp: Date = new Date(params.data.timestamp);

  if (!timestamp) {
    return null;
  }

  const date = timestamp.toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const time = timestamp.toLocaleTimeString('en-us', {
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <div
      style={{
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        fontFamily: 'ProximaNova',
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          style: {
            fontWeight: 600,
            fontSize: '14px',
            fontFamily: 'ProximaNova',
            color: '#0A2333',
          },
        }}
        primary={date}
        secondaryTypographyProps={{
          style: {
            fontSize: '12px',
            fontFamily: 'ProximaNova',
            color: '#78868F',
          },
        }}
        secondary={time}
      />
    </div>
  );
};
