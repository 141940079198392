import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AppData } from '@wm-accounts-backoffice-center/general-types';
import { euxApi } from '@wm-accounts-backoffice-center/wm-api';
import { Environments } from 'wm-accounts-sdk';

export interface EnvironmentsState {
  systemEnvironments: AppData<Environments>;
}

export const initialEnvironmentsState: EnvironmentsState = {
  systemEnvironments: {
    loading: false,
    error: null,
    data: [],
  },
};

const environmentsSlice = createSlice({
  name: 'environmentsSlice',
  initialState: initialEnvironmentsState,
  reducers: {
    environmentsBySystemIdStart(state: EnvironmentsState) {
      state.systemEnvironments.loading = true;
      state.systemEnvironments.error = null;
      return state;
    },
    environmentsBySystemIdSuccess(
      state: EnvironmentsState,
      action: PayloadAction<Environments>
    ) {
      state.systemEnvironments.data = action.payload;
      state.systemEnvironments.loading = false;
      state.systemEnvironments.error = null;
      return state;
    },
    environmentsBySystemIdFailed(state, action: PayloadAction<string>) {
      state.systemEnvironments.loading = false;
      state.systemEnvironments.error = action.payload;
      return state;
    },
  },
});

export { environmentsSlice };

const {
  environmentsBySystemIdStart,
  environmentsBySystemIdSuccess,
  environmentsBySystemIdFailed,
} = environmentsSlice.actions;

export type EnvironmentsStateType = ReturnType<
  typeof environmentsSlice.reducer
>;

type AppThunk = ThunkAction<
  void,
  EnvironmentsStateType,
  unknown,
  Action<string>
>;
export const getEnvironmentsBySystemId =
  (systemId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(environmentsBySystemIdStart());
      const envs = await euxApi.getSystemEnvironments(systemId);
      dispatch(environmentsBySystemIdSuccess(envs));
    } catch (err) {
      dispatch(environmentsBySystemIdFailed(err.message));
      return;
    }
  };
