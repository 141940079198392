import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOwnerTeams,
  FeatureTogglesState,
} from '../redux/feature-toggles.slice';

export const useOwnerTeams = (forceLoad = false) => {
  const ownerTeamsAppData = useSelector(
    (state: { featureTogglesState: FeatureTogglesState }) =>
      state.featureTogglesState.allOwnerTeams
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOwnerTeams(forceLoad));
  }, [dispatch]);

  return ownerTeamsAppData;
};

export default useOwnerTeams;
