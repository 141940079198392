import { Checkbox, Loader, Tooltip, Typography } from '@walkme/ui-core';
import { StyledIdpSettings } from '../data-building-settings.styles';
import { IdpEmptyState } from './idp-empty-state';
import { DefaultIdpVendorSelector } from './default-idp-vendor-selector';
import React, { useEffect } from 'react';
import react from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountState } from '@wm-accounts-backoffice-center/state-management-users';
import { idpApi } from '../../../../../../../wm-api/src/lib/idp-api';
import { defaultIdpSelector } from './idp-section.lib';
import { defaultfeatureSlice } from '@wm-accounts-backoffice-center/state-management-default-features';
import { WMSnackbarVariant } from '@walkme/wm-ui';

export const IdpSection = () => {
  const [idpProviders, setIdpProviders] = react.useState(null);
  const [selectedDefaultIdpVendor, setSelectedDefaultIdpVendor] =
    react.useState(null);
  const [enforceSSO, setEnforceSSO] = react.useState(false);
  const accountById = useSelector((state: { accountState: AccountState }) => {
    return state.accountState.accountById;
  });
  const dispatch = useDispatch();

  const getIdpProviders = async () => {
    try {
      const idpProviders = await idpApi.getAccountIdpProviders(
        accountById?.data?.guid
      );
      setIdpProviders(idpProviders);
    } catch (e) {
      console.error('Failed to fetch IDP providers', e);
    }
  };

  const getDefaultIdpProvider = async () => {
    try {
      const defaultIdpProviderRes = await idpApi.getAccountDefaultIdpProvider(
        accountById?.data?.guid
      );

      const defaultProvider = idpProviders.find(
        (provider) => provider.id === defaultIdpProviderRes.providerId
      );
      setSelectedDefaultIdpVendor(
        defaultProvider
          ? {
              value: defaultProvider?.id,
              label: defaultProvider?.name,
              id: defaultProvider?.id,
            }
          : defaultIdpSelector
      );
      setEnforceSSO(defaultIdpProviderRes?.enforceSso);
    } catch (e) {
      console.error('Failed to fetch default IDP provider', e);
    }
  };

  const onDefaultIdpProviderChange = async (selectedIdpVendor) => {
    const shouldUpdateService =
      selectedDefaultIdpVendor?.id !== selectedIdpVendor?.id;
    const currentIdpVendor = selectedDefaultIdpVendor;
    setSelectedDefaultIdpVendor(selectedIdpVendor);
    if (shouldUpdateService) {
      try {
        await idpApi.updateAccountDefaultIdpProvider(accountById?.data?.guid, {
          providerId: selectedIdpVendor?.id || null,
          providerName:
            idpProviders?.find(
              (provider) => provider.id === selectedIdpVendor?.id
            )?.name || '',
          enforceSso: enforceSSO,
          previousSettings: {
            providerId: selectedDefaultIdpVendor?.id || null,
            providerName:
              idpProviders?.find(
                (provider) => provider.id === selectedDefaultIdpVendor?.id
              )?.name || '',
            enforceSso: enforceSSO,
          },
        });
        dispatch(
          defaultfeatureSlice.actions.setNotificationMessage({
            text: 'Successfully updated IDP settings',
            variant: WMSnackbarVariant.Success,
            isOpen: true,
          })
        );
      } catch (e) {
        setSelectedDefaultIdpVendor(currentIdpVendor);
        dispatch(
          defaultfeatureSlice.actions.setNotificationMessage({
            text: 'Failed to update default IDP settings',
            variant: WMSnackbarVariant.Error,
            isOpen: true,
          })
        );
        console.error('Failed to update default IDP settings', e);
      }
    }
  };

  const onEnforceSsoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setEnforceSSO(checked);
    try {
      await idpApi.updateAccountDefaultIdpProvider(accountById?.data?.guid, {
        providerId: selectedDefaultIdpVendor?.id || null,
        providerName:
          idpProviders?.find(
            (provider) => provider.id === selectedDefaultIdpVendor?.id
          )?.name || '',
        enforceSso: checked,
        previousSettings: {
          providerId: selectedDefaultIdpVendor?.id || null,
          providerName:
            idpProviders?.find(
              (provider) => provider.id === selectedDefaultIdpVendor?.id
            )?.name || '',
          enforceSso: !checked,
        },
      });

      dispatch(
        defaultfeatureSlice.actions.setNotificationMessage({
          text: 'Successfully updated enforce sso',
          variant: WMSnackbarVariant.Success,
          isOpen: true,
        })
      );
    } catch (e) {
      setEnforceSSO(!checked);
      dispatch(
        defaultfeatureSlice.actions.setNotificationMessage({
          text: 'Failed to update enforce sso',
          variant: WMSnackbarVariant.Error,
          isOpen: true,
        })
      );
      console.error('Failed to update enforce sso', e);
    }
  };

  const getAccountIdpInfo = () => {
    // fetch idp providers
    getIdpProviders();
  };

  const getDefaultIdpProviderInfo = () => {
    if (!idpProviders) return;
    getDefaultIdpProvider();
  };

  useEffect(getAccountIdpInfo, []);
  useEffect(getDefaultIdpProviderInfo, [idpProviders]);

  return (
    <div>
      <StyledIdpSettings>
        <Typography variant="T20-2">IDP Settings</Typography>
        <Tooltip
          title="Automatically assign IDP as a UUID method when IDP is configured"
          arrow
        >
          <img alt={'more'} src={`/assets/icons/icon-info.svg`} />
        </Tooltip>
      </StyledIdpSettings>
      {!idpProviders ? <Loader /> : null}
      {idpProviders?.length === 0 ? <IdpEmptyState /> : null}
      {idpProviders?.length > 0 ? (
        <>
          <DefaultIdpVendorSelector
            selectedDefaultIdpVendor={selectedDefaultIdpVendor}
            idpVendors={[
              defaultIdpSelector,
              ...idpProviders?.map((idp) => ({
                id: idp.id,
                value: idp.id,
                label: idp.name,
              })),
            ]}
            setSelectedDefaultIdpVendor={onDefaultIdpProviderChange}
          />
          {selectedDefaultIdpVendor?.id ? (
            <div className="automatic-enable-row">
              <Checkbox checked={enforceSSO} onChange={onEnforceSsoChange} />
              <div>Enforce SSO</div>
              <Tooltip
                title="Users will be prompted to login with IDP in case they login with other credentials"
                arrow
              >
                <img alt={'more'} src={`/assets/icons/icon-info.svg`} />
              </Tooltip>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
