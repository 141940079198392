import React, { SVGProps } from 'react';

export const CloseSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00008 16.75C7.90161 16.75 7.8041 16.7306 7.71315 16.6928C7.6222 16.6551 7.5396 16.5997 7.47008 16.53C7.40039 16.4603 7.3451 16.3776 7.30738 16.2866C7.26966 16.1956 7.25024 16.098 7.25024 15.9995C7.25024 15.901 7.26966 15.8034 7.30738 15.7124C7.3451 15.6213 7.40039 15.5386 7.47008 15.469L15.4701 7.46899C15.5397 7.39929 15.6224 7.344 15.7135 7.30628C15.8045 7.26856 15.9021 7.24915 16.0006 7.24915C16.0991 7.24915 16.1967 7.26856 16.2877 7.30628C16.3787 7.344 16.4614 7.39929 16.5311 7.46899C16.6007 7.5387 16.6559 7.62145 16.6936 7.71252C16.7312 7.80358 16.7505 7.90116 16.7504 7.99969C16.7503 8.09822 16.7308 8.19577 16.693 8.28676C16.6552 8.37775 16.5998 8.4604 16.5301 8.52999L8.53008 16.53C8.46057 16.5997 8.37797 16.6551 8.28702 16.6928C8.19607 16.7306 8.09856 16.75 8.00008 16.75Z"
      fill="#6D81A6"
    />
    <path
      d="M16.0001 16.75C15.9016 16.75 15.8041 16.7306 15.7131 16.6929C15.6222 16.6551 15.5396 16.5998 15.4701 16.53L7.47007 8.53001C7.32962 8.38939 7.25073 8.19876 7.25073 8.00001C7.25073 7.80126 7.32962 7.61064 7.47007 7.47001C7.6107 7.32956 7.80132 7.25067 8.00007 7.25067C8.19882 7.25067 8.38945 7.32956 8.53007 7.47001L16.5301 15.47C16.6348 15.5749 16.7062 15.7085 16.735 15.8539C16.7639 15.9993 16.7491 16.15 16.6924 16.2869C16.6356 16.4239 16.5396 16.541 16.4164 16.6234C16.2932 16.7058 16.1483 16.7499 16.0001 16.75Z"
      fill="#6D81A6"
    />
  </svg>
);
