import React from 'react';
import styled from 'styled-components';

export const TitleLabel = styled('label')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2f426c;
  margin-bottom: 20px;
`;

export const ItemsDiv = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 32px
  align-items: right;
  margin-bottom: 20px
`;

export const AccountName = styled('span')`
  font-style: italic;
  font-weight: 400;
`;

export const BoldLabel = styled('span')`
  font-weight: 500;
`;
