import React, { useMemo } from 'react';
import {
  ChartContainer,
  PrimaryBar,
  SecondaryBar,
  ChartTitle,
  EvaluationChartContainer,
  TooltipContent,
  StyledEvaluation,
} from './evaluation-chart.styles';
import { Insights } from '@walkme/ui-icons';
import { Tooltip, Typography, Dot } from '@walkme/ui-core';
import {
  ChartSize,
  falseEvaluationColor,
  trueEvaluationColor,
} from './evaluations.lib';
import { Evaluation } from '../../../../../state-management-feature-toggles/src';

export interface EvaluationChartProps {
  evaluation: Evaluation;
  chartSize: ChartSize;
  maxTotalEvaluations?: number;
}

export const EvaluationChart = ({
  evaluation,
  chartSize,
  maxTotalEvaluations,
}: EvaluationChartProps) => {
  const totalEvaluations =
    evaluation.trueEvaluations + evaluation.falseEvaluations;

  const barWidth = useMemo(() => {
    if (evaluation.trueEvaluations === 0 && evaluation.falseEvaluations === 0) {
      return 0;
    }

    if (chartSize === ChartSize.SMALL) {
      return 200;
    }

    if (!maxTotalEvaluations) {
      return totalEvaluations;
    }

    const percentage = (totalEvaluations / maxTotalEvaluations) * 100;
    const widthInPx = (percentage / 100) * 400; // Max width is 400px
    return Math.min(widthInPx, 400);
  }, [chartSize, totalEvaluations, maxTotalEvaluations]);

  const trueBarWidth = useMemo(() => {
    return (evaluation.trueEvaluations / totalEvaluations) * barWidth;
  }, [evaluation.trueEvaluations, totalEvaluations, barWidth]);

  const falseBarWidth = useMemo(() => {
    return (evaluation.falseEvaluations / totalEvaluations) * barWidth;
  }, [evaluation.falseEvaluations, totalEvaluations, barWidth]);

  return (
    <div id={'evaluation-chart'}>
      <Tooltip
        arrow
        placement={'right'}
        title={
          <TooltipContent>
            <Typography variant={'T20'}>
              Evaluations of the Last{' '}
              {evaluation.timeConst === 1
                ? 'Day'
                : `${evaluation.timeConst} Days`}
            </Typography>
            <div className={'color-map'}>
              <div className={'color-description'}>
                <div className={'color-description-key'}>
                  <Dot customColor={trueEvaluationColor} />
                  <Typography variant={'T20'}>true</Typography>
                </div>
                <Typography variant={'T20'}>
                  {evaluation.trueEvaluations}
                </Typography>
              </div>
              <div className={'color-description'}>
                <div className={'color-description-key'}>
                  <Dot customColor={falseEvaluationColor} />
                  <Typography variant={'T20'}>false</Typography>
                </div>
                <Typography variant={'T20'}>
                  {evaluation.falseEvaluations}
                </Typography>
              </div>
            </div>
          </TooltipContent>
        }
      >
        <StyledEvaluation>
          {chartSize === ChartSize.DEFAULT && (
            <Typography variant={'T20'}>
              Evaluations of the Last{' '}
              {evaluation.timeConst === 1
                ? 'Day'
                : `${evaluation.timeConst} Days`}
            </Typography>
          )}

          <EvaluationChartContainer
            size={chartSize}
            id={'evaluation-chart-container'}
          >
            <ChartTitle size={chartSize}>
              <Insights />
              <Typography className={'chart-title-total'} variant={'T20'}>
                {totalEvaluations}
              </Typography>
            </ChartTitle>

            {chartSize !== ChartSize.SMALL && barWidth === 0 ? (
              <Typography variant={'T20'}>Chart N/A</Typography>
            ) : barWidth !== 0 ? (
              <ChartContainer size={barWidth}>
                <PrimaryBar width={trueBarWidth} color={trueEvaluationColor} />
                <SecondaryBar
                  width={falseBarWidth}
                  color={falseEvaluationColor}
                />
              </ChartContainer>
            ) : (
              <Typography variant={'T20'}>N/A</Typography>
            )}
          </EvaluationChartContainer>
        </StyledEvaluation>
      </Tooltip>
    </div>
  );
};
