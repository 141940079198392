import styled from 'styled-components';
import { Dialog } from '@walkme/ui-core';
import { DialogContent } from '@material-ui/core';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    height: 75vh !important;
    width: 85vw !important;
  }

  .MuiDialogContent-root {
    scrollbar-width: none;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  border-top: 1px solid rgb(235, 239, 247);
  border-bottom: 1px solid rgb(235, 239, 247);
  padding: 16px 32px;
`;

export const StyledContentContainer = styled.div`
  scrollbar-width: none;
  height: 100%;

  .sub-title {
    padding-bottom: 16px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  .WMLoader-wmLoader {
    width: 30%;
  }
`;

export const StyledChanges = styled.div`
  border: 1px solid #e4e9fc;
  border-bottom: none;
`;
export const StyledChangeDate = styled.div`
  padding: 8px 0;
`;

export const StyledChangeContainer = styled.div`
  border-bottom: 1px solid #e4e9fc;
`;
export const StyledChange = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;

  .change-text-top {
    display: flex;
    align-items: center;
    gap: 8px;

    .MuiAvatar-root {
      width: 24px;
      height: 24px;
      font-size: 12px;
      background-color: #42a5f5;
    }
  }

  .change-text-bottom {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 32px;

    button {
      font-size: 12px;
    }
  }

  .change-details {
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    max-height: ${(props) => (props.isOpen ? '100%' : '0')};
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    overflow: hidden;
    height: ${(props) => (props.isOpen ? 'auto' : '0')};

    padding-left: 32px;

    .change-details-textual {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 4px 16px;

      .pre-wrap {
        white-space: pre-wrap;
      }
    }

    .change-details-textual::before {
      content: '';
      position: absolute;
      left: 0;
      top: 12px;
      transform: translateY(-50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: black;
    }

    .change-diffs {
      display: flex;
      flex-direction: column;
      padding-top: 8px;
    }
  }
`;
