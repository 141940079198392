import React from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { useState, useMemo } from 'react';
import { WmSnippetBox } from './wm-snippet-box';
import { Typography } from '@walkme/ui-core';
import { WMButton, WMTooltip, WMButtonVariant } from '@walkme/wm-ui';
import { FilledWarning } from '@walkme/ui-icons';
import { Box } from '@material-ui/core';
import { LanguagesTypes } from './wm-snippet-box';
import beautify from 'beautify';

export interface WmSnippetBoxWrapperProps {
  language: LanguagesTypes;
  value: string;
  setValue: (value: string) => void;
  isValueValid: boolean;
  onSave: () => void;
  lastSavedEmail: string;
  lastSavedDate: string;
  errorMessage?: string;
  loading?: boolean;
  height?: string;
}

const StyledTypographyComment = styled(Typography)`
  color: #637191;
  font-size: 12px;
  line-height: 16px;
`;

const StyledSaveButton = styled(WMButton)`
  width: 110px;
  margin-left: 0px !important;
  margin-right: 0px !important;
`;

const StyledBeautifyButton = styled(WMButton)`
  margin-right: 20px !important;
`;

export function WmSnippetBoxWrapper(props: WmSnippetBoxWrapperProps) {
  const [initValue, setInitValue] = useState<string>(props.value);

  const isDirty = useMemo(() => {
    if (props.isValueValid) {
      if (props.language === LanguagesTypes.JSON) {
        if (initValue === props.value) {
          return false;
        }
        try {
          return !isEqual(JSON.parse(initValue), JSON.parse(props.value));
        } catch (e) {
          return true;
        }
      }
      return !(initValue?.trim() === props.value?.trim());
    }
    return false;
  }, [initValue, props.isValueValid, props.value]);

  const handleSaveClick = () => {
    props.onSave();
    setInitValue(props.value);
  };

  const beautifyClicked = () => {
    props.setValue(beautify(props.value, { format: props.language }));
  };

  return (
    <Box style={{ padding: '30px' }}>
      <WmSnippetBox
        value={props.value}
        setValue={props.setValue}
        language={props.language}
        height={'300px'}
      />
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <StyledTypographyComment>
          Last saved by {props.lastSavedEmail || 'unkown user'},{' '}
          {props.lastSavedDate}
        </StyledTypographyComment>
        <Box
          style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}
        >
          <StyledBeautifyButton
            onClick={beautifyClicked}
            variant={WMButtonVariant.Secondary}
          >
            Beautify Code
          </StyledBeautifyButton>
          <WMTooltip
            condition={!props.isValueValid}
            title={props.errorMessage}
            placement={'left'}
            arrow={true}
          >
            <Box>
              <StyledSaveButton
                onClick={handleSaveClick}
                loading={props.loading}
                disabled={props.loading || !props.isValueValid || !isDirty}
              >
                {!props.isValueValid && (
                  <FilledWarning style={{ paddingRight: '5px' }} />
                )}
                Save Changes
              </StyledSaveButton>
            </Box>
          </WMTooltip>
        </Box>
      </Box>
    </Box>
  );
}

export default WmSnippetBoxWrapper;
