import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  WMBreadcrumbs,
  WMIconRightAngle,
  WMTagList,
  WMTagProps,
  WMTagVariant,
  WMTooltipProps,
} from '@walkme/wm-ui';
import styled from 'styled-components';
import { useStylesMainTabs } from './styles/styles';
import { DataTable } from '../../../../ui-components/src/lib/data-table/data-table';
import useTable from '../../../../ui-components/src/lib/data-table/use-table';
import { useDispatch, useSelector } from 'react-redux';
import { Order } from '@wm-accounts-backoffice-center/general-types';
import {
  AccountsSearchState,
  getAllAccounts,
  accountsSearchSlice,
} from '@wm-accounts-backoffice-center/state-management-accounts-search';
import { AccountRow } from '../../../../general-types/src/lib/account';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import CreateAccountModal from './create-account-modal';
import { User, Users } from 'wm-accounts-sdk';

import { ToasterVariant, useToaster } from '@walkme/ui-core';
import {
  accountSlice,
  hasPermission,
} from '@wm-accounts-backoffice-center/state-management-users';
import { deployablesTypes } from '@wm-accounts-backoffice-center/state-management-deployables';
import { Account, Accounts } from '@wm-accounts-backoffice-center/wm-api';

export interface URLParams {
  search?: string;
}

export interface AllAccountsPageProps {
  location?: URLParams;
}

type QueryState = {
  limit: number;
  filter?: string;
  filterBy?: string;
  orderBy?: string;
  order?: string;
  next?: string;
};

const Title = styled('label')`
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #2f426c;
`;

const AccountNameLabel = styled('label')`
  color: #2f426c;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const TitleAccount = styled('label')`
  color: #303d53;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
`;

const StyledTagList = styled(WMTagList)`
  .WMTagList-tag {
    height: 23px;
    left: 0px;
    top: 16.5px;
    border-radius: 4px;
    padding: 4px 3px 4px 3px;
    background-color: #ebeff7;
    color: #637191;
  }
`;

const isAdmin = (user: User) => {
  return hasPermission(user && user.role, 'Management', 'Write');
};

const getNumberOfAdminUsers = (users: Users) => {
  return users.filter((user: User) => isAdmin(user)).length;
};

const getAllAccountsRow = (accounts: Accounts): AccountRow[] => {
  return accounts.map((account) => {
    const systems = account.systems;
    const users = account.users;
    const id = account.id;
    const name = account.name;
    const sfdcAccountId = account.sfdcAccountId;
    const paid = account.paid;
    const featureFlagsLength = account.featureFlags?.length;
    const creationDate = account.creationDate;
    const systemsLabels: WMTagProps[] =
      systems && systems.length > 0
        ? systems.map((system) => ({
            labelText: system.displayName,
            fullLabel: true,
            variant: WMTagVariant.Comet,
          }))
        : [];
    return {
      id: id,
      name: {
        value: name,
        displayValue: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                background: '#ECEFFA',
                borderRadius: '100px',
                width: '29px',
                height: '29px',
                marginRight: '9px',
                textAlign: 'center',
                fontFamily: 'Proxima Nova',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '16px',
                textTransform: 'uppercase',
              }}
            >
              {name.charAt(0)}
            </div>
            <AccountNameLabel>{name}</AccountNameLabel>
          </div>
        ),
      },
      systems: {
        value: systemsLabels.join(','),
        displayValue: <StyledTagList labels={systemsLabels} maxTagsShown={3} />,
      },
      usersAdmins: {
        value: `${users.length}/${getNumberOfAdminUsers(users)}`,
        displayValue: (
          <p>
            {users.length}/{getNumberOfAdminUsers(users)}
          </p>
        ),
      },
      featureFlags: featureFlagsLength,
      sfdcAccountId,
      paid: {
        value: String(paid),
        displayValue: <p>{paid ? 'Yes' : 'No'}</p>,
      },
      creationDate: moment(creationDate).format('MMM Do YYYY'),
    };
  });
};

export function AllAccountsPage(props: AllAccountsPageProps) {
  const classes = useStylesMainTabs();
  const tableSettings = useTable();
  const history = useHistory();

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [page, setPage] = useState(1);
  const [activityOrder, setActivityOrder] = useState(
    tableSettings.currentOrder
  );
  const [activityOrderBy, setActivityOrderBy] = useState(
    tableSettings.currentOrderBy
  );

  const searchInput = useSelector(
    (state: { accountsSearchState: AccountsSearchState }) =>
      state.accountsSearchState.lastAllAccountsSearchInput
  );

  const [showCreateAccountDialog, setShowCreateAccountDialog] = useState(false);

  const { addToaster } = useToaster();

  const [query, setQuery] = useState<QueryState>({
    limit: tableSettings.rowsPerPage,
    order: activityOrder.toUpperCase(),
    orderBy: activityOrderBy,
    filter: searchInput || undefined,
    filterBy: 'id,guid,sfId,email,name',
  });

  const accountCreation = useSelector(
    (state: { accountsSearchState: AccountsSearchState }) =>
      state.accountsSearchState.accountCreation
  );

  const allAccounts = useSelector(
    (state: { accountsSearchState: AccountsSearchState }) =>
      state.accountsSearchState.allAccounts
  );

  const accountsRows = getAllAccountsRow(allAccounts.data.accounts);

  useEffect(() => () => {}, []);

  useEffect(() => {
    dispatch(deployablesTypes());
  }, []);

  useEffect(() => {
    if (allAccounts.error && !allAccounts.loading) {
      addToaster({
        message: allAccounts.error,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [allAccounts]);

  useEffect(() => {
    dispatch(getAllAccounts(query));
  }, [query]);

  useEffect(() => {
    if (accountCreation.data) {
      setShowCreateAccountDialog(false);
      addToaster({
        message: `${accountCreation.data.name} account was successfully created.`,
        variant: ToasterVariant.Success,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
      // re-query the accounts to include the newly created account in the table
      dispatch(getAllAccounts(query));
    } else if (accountCreation.error) {
      addToaster({
        message: `Unable to create new account. ${accountCreation.error}`,
        variant: ToasterVariant.Error,
        width: 'content',
        autoDismiss: true,
        distance: '60',
      });
    }
  }, [accountCreation]);

  const numberOfPages = allAccounts.data ? allAccounts.data.totalItems : 0;

  const headCells = useMemo(() => {
    const cells = [
      { id: 'name', label: 'Account Name' },
      { id: 'systems', label: 'Systems' },
      { id: 'usersAdmins', label: 'Users / Admins' },
      { id: 'sfdcAccountId', label: 'SFDC Account Id' },
      { id: 'creationDate', label: 'Created date' },
    ];

    return cells;
  }, []);

  const onPageChange = (event, page) => {
    setPage(page);

    setQuery((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const onChangeRowsPerPage = (event) => {
    setQuery((prevState) => ({
      ...prevState,
      limit: event.target.value,
    }));
  };

  const onSortChange = (event) => {
    setActivityOrder(event);
    setQuery((prevState) => ({
      ...prevState,
      order: event.toUpperCase(),
    }));
  };

  const onSetOrderBy = (event) => {
    setActivityOrderBy(event);
    setQuery((prevState) => ({
      ...prevState,
      orderBy: event,
    }));
  };

  const onSetQuery = useDebouncedCallback((event) => {
    dispatch(accountsSearchSlice.actions.setAllAccountsSearchInput(event));
    setPage(1);
    setQuery((prevState) => ({
      ...prevState,
      filter: event,
      page: 1,
    }));
  }, 600);

  const serverSidePropsRenderProps = {
    currentPage: page,
    rowsPerPage: query.limit,
    jobsCount: numberOfPages,
    handleChangePage: onPageChange,
    onChangeRowsPerPage: onChangeRowsPerPage,
    onSetOrderBy: onSetOrderBy,
    handleSortChange: onSortChange,
    currentOrder: activityOrder as Order,
    currentOrderBy: activityOrderBy,
    query: searchInput,
    onSetQuery: onSetQuery,
  };

  const tooltipTitle = 'Create New Account';
  const buttonTooltipProps: WMTooltipProps = {
    variant: 'dark',
    placement: 'top-end',
    title: tooltipTitle,
    children: null,
  };

  const handleAccountSelection = useCallback(
    (id) => {
      const clickedAccount: Account = allAccounts.data.accounts.find(
        (account) => account.id === id
      );
      dispatch(accountSlice.actions.accountByIdSuccess(clickedAccount));
      const openSystemData = clickedAccount.systems?.find(
        (system) => system.email === searchInput
      );
      const pathName = openSystemData
        ? `/accounts/${clickedAccount.id}/system/${openSystemData.id}`
        : `/accounts/${clickedAccount.id}`;
      history.push({
        pathname: pathName,
      });
    },
    [allAccounts.data, dispatch]
  );

  const onAddAccountClicked = () => {
    setShowCreateAccountDialog(true);
  };

  const exitDialog = () => {
    setShowCreateAccountDialog(false);
  };

  return (
    <div style={{ height: '100% ' }}>
      <WMBreadcrumbs
        separator={<WMIconRightAngle />}
        style={{ marginBottom: '24px' }}
      >
        <Title>Accounts</Title>
      </WMBreadcrumbs>

      {showCreateAccountDialog && (
        <CreateAccountModal
          showDialog={showCreateAccountDialog}
          dialogExitCallback={exitDialog}
        />
      )}
      <DataTable
        heads={headCells}
        data={accountsRows}
        hasToolbar
        serverSideRender
        serverSidePropsRenderProps={serverSidePropsRenderProps}
        onRowClick={handleAccountSelection}
        loading={allAccounts.loading}
        onToolbarButtonClick={onAddAccountClicked}
        buttonTooltipProps={buttonTooltipProps}
        {...tableSettings}
      />
    </div>
  );
}

export default AllAccountsPage;
