import { InputSearch } from '@walkme/ui-core';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MergeAccountState } from '../../../../merge-account.store';
// some other component
import {
  useStepStyles,
  ItemsWrapper,
  ContentWrapper,
} from '../wizard-steps.styles';
import {
  accountUsersUpdated,
  stepLoaded,
  userClicked,
  usersQueryChanged,
} from './exclude-users.step.slice';
import { UserCard } from '../../components/user.card';

const WizardStepExcludeUsers = () => {
  const classes = useStepStyles();

  const dispatch = useDispatch();

  const initialUsers = useSelector(
    (state: MergeAccountState) => state.mergeData?.sourceAccount?.users || []
  );
  const excludedUserIds = useSelector(
    (state: MergeAccountState) => state.mergeData?.excludedUserIds || []
  );

  useEffect(() => {
    dispatch(stepLoaded());
  }, [dispatch]);

  useEffect(() => {
    dispatch(accountUsersUpdated());
  }, [initialUsers]);

  const query = useSelector(
    (state: MergeAccountState) => state.excludedUsers?.query
  );
  const usersToDisplay = useSelector(
    (state: MergeAccountState) => state.excludedUsers?.filteredUsers
  );

  const onSearchChange = useCallback(
    (value) => {
      dispatch(usersQueryChanged(value));
    },
    [dispatch]
  );

  const onUserClicked = useCallback(
    (systemId: number) => {
      dispatch(userClicked(systemId));
    },
    [dispatch]
  );

  const rendrUsers = useCallback(
    (users) =>
      users.map((user) => {
        return (
          <UserCard
            onClicked={onUserClicked}
            key={user.id}
            user={user}
            isSelected={!excludedUserIds.includes(user.id)}
          />
        );
      }),
    [usersToDisplay, excludedUserIds]
  );

  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <div className={classes.stepInnerFrame}>
          <label className={classes.titleLabel}>
            Un-Select any user that you wish to exclude from the merge
          </label>
          <InputSearch
            placeholder="Filter users by name or email"
            onChange={onSearchChange}
            value={query}
          />
          <ContentWrapper>
            <ItemsWrapper>{rendrUsers(usersToDisplay)}</ItemsWrapper>
          </ContentWrapper>
        </div>
      </div>
    </div>
  );
};

export default WizardStepExcludeUsers;
