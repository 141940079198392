import styled from "styled-components";
import {WMDataGrid} from "@walkme/wm-ui";

export const StyledWebhooksManagementContainer = styled.div`
  height: 100%;
`

export const StyledWMDataGrid = styled(WMDataGrid)`
  .WMDataGridHeader-wmDataGridHeader {
    align-items: center;
  }
  .WMSearchFilter-clearButton {
    top: 20%;
  }
`;
