import { WMhttpClient } from './wm-http-client';
import { Environments } from 'wm-accounts-sdk';

class EuxApi {
  private readonly httpClient: WMhttpClient;
  private readonly systemsEnvs: Record<string, Environments>;
  constructor(httpClient: WMhttpClient) {
    this.httpClient = httpClient;
    this.systemsEnvs = {};
  }

  async getSystemEnvironments(systemId: number): Promise<Environments> {
    const response = await this.httpClient.instance.get(
      `/users/environments/${systemId}`
    );

    const systemEnvs: Environments = response.data.map((env) => {
      return {
        name: env.Name,
        id: env.Id,
        userId: env.UserId,
        path: env.Path,
        isDev: env.IsDev,
      };
    });

    return systemEnvs;
  }

  async getAllExcludedFeaturesEnvsForSystem(
    systemId: number
  ): Promise<{ [key: string]: number[] }> {
    const response = await this.httpClient.instance.get(
      `/runtimeConfig/excludedFeatureFlags/SystemExcludedEnvs/${systemId}`
    );
    return response.data;
  }

  async getExcludedEnvs(
    systemId: number,
    featureFlagName: string
  ): Promise<number[]> {
    const response = await this.httpClient.instance.get(
      `/runtimeConfig/excludedFeatureFlags/environments/${systemId}/${featureFlagName}`
    );
    return response.data;
  }

  async updateExcludedEnvs(
    systemId: number,
    featureFlagName: string,
    excludedEnvs: number[]
  ): Promise<any> {
    await this.httpClient.instance.post(
      `/runtimeConfig/excludedFeatureFlags/environments`,
      {
        systemId,
        featureFlagName,
        excludedEnvs,
      }
    );
  }
}

export const euxApi = new EuxApi(
  new WMhttpClient(window.clientConfig.NX_EUX_BACKOFFICE_API_URL)
);
