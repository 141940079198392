import { rootReducer } from './../../../../../apps/home/src/redux/rootReducer';
import { AppData } from '../../../../general-types/src';
import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import {
  featuresApi,
  FeatureFlagType,
  SnackBarMessage,
} from '@wm-accounts-backoffice-center/wm-api';
import { ThunkAction } from 'redux-thunk';
import { WMSnackbarVariant } from '@walkme/wm-ui';

export interface EntityFeatureFlag {
  EntityId: number;
  FeatureId: number;
  activatedByUserId: string;
  activatedByUserGuid: string;
  activatedByUserEmail: string;
  createdAt: string;
  featureFlag: FeatureFlag;
}

export interface FeatureFlagDescription {
  id: number;
  featureFlagId: number;
  userId: number;
  userGuid: string;
  email: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface FeatureFlag {
  id: number;
  flagName: string;
  description: FeatureFlagDescription;
  notes: Array<Note>;
  type: string;
  whitelisted: boolean;
}

export interface Note {
  id: number;
  featureFlagId: number;
  userId: number;
  userGuid: string;
  email: string;
  note: string;
  createdAt: string;
  updatedAt: string;
}

export interface FeaturesState {
  allFeatures: AppData<FeatureFlag[]>;
  accountFeatures: AppData<EntityFeatureFlag[]>;
  systemFeatures: AppData<EntityFeatureFlag[]>;
  addFeature: AppData<FeatureFlag[]>;
  deleteFeature: AppData<FeatureFlag[]>;
  updateFeature: AppData<FeatureFlag>;
  featureFlagTypes: AppData<FeatureFlagType[]>;
  snackBarMessage: SnackBarMessage;
}

export const initialFeaturesState: FeaturesState = {
  allFeatures: {
    loading: false,
    error: null,
    data: [],
  },
  accountFeatures: {
    loading: false,
    error: null,
    data: [],
  },
  systemFeatures: {
    loading: false,
    error: null,
    data: [],
  },
  addFeature: {
    loading: false,
    error: null,
    data: null,
  },
  deleteFeature: {
    loading: false,
    error: null,
    data: null,
  },
  updateFeature: {
    loading: false,
    error: null,
    data: null,
  },
  featureFlagTypes: {
    loading: false,
    error: null,
    data: null,
  },
  snackBarMessage: {
    text: '',
    variant: WMSnackbarVariant.Success,
    isOpen: false,
  },
};

const featureSlice = createSlice({
  name: 'featureSlice',
  initialState: initialFeaturesState,
  reducers: {
    updateFeatureFailed(state, action: PayloadAction<string>) {
      state.snackBarMessage.text = action.payload;
      state.snackBarMessage.variant = WMSnackbarVariant.Error;
      state.snackBarMessage.isOpen = true;
      state.updateFeature.loading = false;
      return state;
    },
    updateFeatureSuccess(
      state: FeaturesState,
      action: PayloadAction<FeatureFlag>
    ) {
      state.updateFeature.data = action.payload;
      state.updateFeature.loading = false;
      return state;
    },
    updateFeatureStart(state: FeaturesState) {
      state.updateFeature.loading = true;
      return state;
    },
    setUpdateFeatureCleanup(state: FeaturesState) {
      state.updateFeature.data = null;
      state.updateFeature.loading = false;
      return state;
    },
    allFeaturesStart(state: FeaturesState) {
      state.allFeatures.loading = true;
      return state;
    },
    allFeaturesSuccess(
      state: FeaturesState,
      action: PayloadAction<FeatureFlag[]>
    ) {
      state.allFeatures.data = action.payload;
      state.allFeatures.loading = false;
      return state;
    },
    allFeaturesFailed(state, action: PayloadAction<string>) {
      state.allFeatures.loading = false;
      state.snackBarMessage.text = action.payload;
      state.snackBarMessage.variant = WMSnackbarVariant.Error;
      state.snackBarMessage.isOpen = true;
      return state;
    },
    accountFeaturesStart(state: FeaturesState) {
      state.accountFeatures.loading = true;
      return state;
    },
    accountFeaturesSuccess(
      state: FeaturesState,
      action: PayloadAction<EntityFeatureFlag[]>
    ) {
      state.accountFeatures.data = action.payload;
      state.accountFeatures.loading = false;
      return state;
    },
    accountFeaturesFailed(state, action: PayloadAction<string>) {
      state.accountFeatures.loading = false;
      state.snackBarMessage.text = action.payload;
      state.snackBarMessage.variant = WMSnackbarVariant.Error;
      state.snackBarMessage.isOpen = true;
      return state;
    },
    systemFeaturesStart(state: FeaturesState) {
      state.systemFeatures.loading = true;
      return state;
    },
    systemFeaturesSuccess(
      state: FeaturesState,
      action: PayloadAction<EntityFeatureFlag[]>
    ) {
      state.systemFeatures.data = action.payload;
      state.systemFeatures.loading = false;
      return state;
    },
    systemFeaturesFailed(state, action: PayloadAction<string>) {
      state.systemFeatures.loading = false;
      state.snackBarMessage.text = action.payload;
      state.snackBarMessage.variant = WMSnackbarVariant.Error;
      state.snackBarMessage.isOpen = true;
      return state;
    },
    setFeaturesCleanup(state: FeaturesState) {
      state.systemFeatures.data = [];
      state.systemFeatures.loading = false;
      return state;
    },
    addFeaturesStart(state: FeaturesState) {
      state.addFeature.loading = true;
      return state;
    },
    addFeaturesSuccess(state: FeaturesState, action: PayloadAction<any>) {
      state.addFeature.data = action.payload;
      state.addFeature.loading = false;
      return state;
    },
    addFeaturesFailed(state, action: PayloadAction<string>) {
      state.addFeature.loading = false;
      state.snackBarMessage.text = action.payload;
      state.snackBarMessage.variant = WMSnackbarVariant.Error;
      state.snackBarMessage.isOpen = true;
      return state;
    },
    setAddFeaturesCleanup(state: FeaturesState) {
      state.addFeature.data = null;
      state.addFeature.loading = false;
      return state;
    },
    deleteFeaturesStart(state: FeaturesState) {
      state.deleteFeature.loading = true;
      return state;
    },
    deleteFeaturesSuccess(state: FeaturesState, action: PayloadAction<any>) {
      state.deleteFeature.data = action.payload;
      state.deleteFeature.loading = false;
      return state;
    },
    deleteFeaturesFailed(state, action: PayloadAction<string>) {
      state.deleteFeature.loading = false;
      state.snackBarMessage.text = action.payload;
      state.snackBarMessage.variant = WMSnackbarVariant.Error;
      state.snackBarMessage.isOpen = true;
      return state;
    },
    setDeleteFeaturesCleanup(state: FeaturesState) {
      state.deleteFeature.data = null;
      state.deleteFeature.loading = false;
      return state;
    },
    featureFlagTypesStart(state: FeaturesState) {
      state.featureFlagTypes.loading = true;
      return state;
    },
    featureFlagTypesSuccess(
      state: FeaturesState,
      action: PayloadAction<FeatureFlagType[]>
    ) {
      state.featureFlagTypes.data = action.payload;
      state.featureFlagTypes.loading = false;
      return state;
    },
    featureFlagTypesFailed(state, action: PayloadAction<string>) {
      state.featureFlagTypes.loading = false;
      state.snackBarMessage.text = action.payload;
      state.snackBarMessage.variant = WMSnackbarVariant.Error;
      state.snackBarMessage.isOpen = true;
      return state;
    },
    cleanupFeatureFlagPageErrors(state) {
      state.snackBarMessage.isOpen = false;
      return state;
    },
    setSnackBarMessage(state, action: PayloadAction<SnackBarMessage>) {
      state.snackBarMessage.text = action.payload.text;
      state.snackBarMessage.variant = action.payload.variant;
      state.snackBarMessage.isOpen = action.payload.isOpen;
      return state;
    },
  },
});

export { featureSlice };

const {
  allFeaturesFailed,
  allFeaturesSuccess,
  allFeaturesStart,
  accountFeaturesFailed,
  accountFeaturesSuccess,
  accountFeaturesStart,
  systemFeaturesFailed,
  systemFeaturesSuccess,
  systemFeaturesStart,
  setFeaturesCleanup,
  addFeaturesStart,
  addFeaturesSuccess,
  addFeaturesFailed,
  setAddFeaturesCleanup,
  deleteFeaturesStart,
  deleteFeaturesSuccess,
  deleteFeaturesFailed,
  updateFeatureFailed,
  updateFeatureSuccess,
  updateFeatureStart,
  setDeleteFeaturesCleanup,
  featureFlagTypesStart,
  featureFlagTypesSuccess,
  featureFlagTypesFailed,
  cleanupFeatureFlagPageErrors,
  setSnackBarMessage,
} = featureSlice.actions;

export type featuresStateType = ReturnType<typeof featureSlice.reducer>;
export type rootReducerType = ReturnType<typeof rootReducer>;
export type featuresAppThunk = ThunkAction<
  void,
  rootReducerType,
  unknown,
  Action<string>
>;

export const getFeaturesTypes =
  (forceLoad = false): featuresAppThunk =>
  async (dispatch, getState) => {
    try {
      const appState = getState();
      if (!forceLoad && appState.featuresState.featureFlagTypes.data) {
        return;
      }
      dispatch(featureFlagTypesStart());
      const featureFlagTypes: FeatureFlagType[] =
        await featuresApi.getFeaturesTypes();
      dispatch(featureFlagTypesSuccess(featureFlagTypes));
    } catch (err) {
      dispatch(featureFlagTypesFailed(err.message));
      return;
    }
  };

export const deleteFeaturesCleanup =
  (): featuresAppThunk => async (dispatch) => {
    dispatch(setDeleteFeaturesCleanup());
  };

export const featuresCleanup = (): featuresAppThunk => async (dispatch) => {
  dispatch(setFeaturesCleanup());
};

export const updateFeature =
  (
    featureFlagName: string,
    description: string,
    featureType: string
  ): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateFeatureStart());
      const feature = await featuresApi.updateFeatureFlag(
        featureFlagName,
        description,
        featureType
      );
      dispatch(updateFeatureSuccess(feature));
    } catch (err) {
      dispatch(updateFeatureFailed(err.message));
      return;
    }
  };

export const getAllFeatures =
  (forceLoad: boolean): featuresAppThunk =>
  async (dispatch, getState) => {
    try {
      const appState = getState();
      if (!forceLoad && appState.featuresState.allFeatures.data.length > 0) {
        return;
      }
      dispatch(allFeaturesStart());
      const allFeatures: FeatureFlag[] = await featuresApi.getAllFeatureFlags();
      dispatch(allFeaturesSuccess(allFeatures));
    } catch (err) {
      dispatch(allFeaturesFailed(err.message));
      return;
    }
  };

export const getAccountFeatures =
  (accountId: number): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(accountFeaturesStart());
      const accountFeatures: EntityFeatureFlag[] =
        await featuresApi.getEntityFeatures(accountId);
      dispatch(accountFeaturesSuccess(accountFeatures));
    } catch (err) {
      dispatch(accountFeaturesFailed(err.message));
      return;
    }
  };

export const getSystemFeatures =
  (systemId: number): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(systemFeaturesStart());
      const systemFeatures: EntityFeatureFlag[] =
        await featuresApi.getEntityFeatures(systemId);
      dispatch(systemFeaturesSuccess(systemFeatures));
    } catch (err) {
      dispatch(systemFeaturesFailed(err.message));
      return;
    }
  };

export const addFeatures =
  (
    featureFlagName: string,
    description: string,
    featureType: string
  ): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(addFeaturesStart());
      const addedFeature: FeatureFlag =
        await featuresApi.addFeatureFlagWithDescription(
          featureFlagName,
          description,
          featureType
        );
      dispatch(addFeaturesSuccess([addedFeature]));
    } catch (err) {
      dispatch(addFeaturesFailed(err.message));
      return;
    }
  };

export const deleteFeature =
  (featureFlagNameToDelete: string): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteFeaturesStart());
      const deletedFeature: FeatureFlag = await featuresApi.deleteFeature(
        featureFlagNameToDelete
      );
      dispatch(deleteFeaturesSuccess([deletedFeature]));
    } catch (err) {
      dispatch(deleteFeaturesFailed(err.message));
      return;
    }
  };

export const addFeatureToSystem =
  (accountId, systemId, ffToAdd: string[]): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(addFeaturesStart());
      const addedFeature = await featuresApi.addFeatureFlagNamesForSystems(
        accountId,
        [systemId],
        ffToAdd
      );
      dispatch(addFeaturesSuccess(addedFeature));
      dispatch(setAddFeaturesCleanup());
      dispatch(getSystemFeatures(systemId));
    } catch (err) {
      dispatch(addFeaturesFailed(err.message));
      return;
    }
  };

export const addFeaturesToAccount =
  (accountId, ffToAdd: string[]): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(addFeaturesStart());
      const addedFeature = await featuresApi.addFeatureFlagNamesForAccount(
        accountId,
        ffToAdd
      );
      dispatch(addFeaturesSuccess(addedFeature));
      dispatch(setAddFeaturesCleanup());
      dispatch(getAccountFeatures(accountId));
    } catch (err) {
      dispatch(addFeaturesFailed(err.message));
      return;
    }
  };

export const removeFeaturesFromAccount =
  (accountId, ffToRemove: string[]): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteFeaturesStart());
      const removedFeature =
        await featuresApi.removeFeatureFlagNamesFromAccount(
          accountId,
          ffToRemove
        );
      dispatch(deleteFeaturesSuccess(removedFeature));
      dispatch(setDeleteFeaturesCleanup());
      dispatch(getAccountFeatures(accountId));
    } catch (err) {
      dispatch(deleteFeaturesFailed(err.message));
      return;
    }
  };

export const removeFeatureFromSystem =
  (accountId, systemId: number, ffToRemove: string[]): featuresAppThunk =>
  async (dispatch) => {
    try {
      dispatch(deleteFeaturesStart());
      const removedFeature =
        await featuresApi.removeFeatureFlagNamesFromSystems(
          accountId,
          [systemId],
          ffToRemove
        );
      dispatch(deleteFeaturesSuccess(removedFeature));
      dispatch(setDeleteFeaturesCleanup());
      dispatch(getSystemFeatures(systemId));
    } catch (err) {
      dispatch(deleteFeaturesFailed(err.message));
      return;
    }
  };
