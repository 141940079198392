import React from 'react';
import { Button } from '@walkme/ui-core';
import { MergeAccountState } from '../../merge-account.store';
import { useDispatch, useSelector } from 'react-redux';
import {
  SummaryPageWrapper,
  SummaryTitle,
  SummaryContent,
  SummmaryPageContentWrapper,
} from './page-summary-display.styles';
import { MergeAccountsResult } from '../../api/merge-account-api.types';
import { MergeSummary } from '../../merge-summary/merge-summary';

import { doesMergeHaveErrors } from '../../merge-summary/merge-summary.lib';
import { resetMergeData } from '../../merge-data.slice';

export interface PageSummaryDisplayProps {
  onStartMergeClicked: () => void;
}

export const PageSummaryDisplay = ({
  onStartMergeClicked,
}: PageSummaryDisplayProps) => {
  const dispatch = useDispatch();
  const mergeAccountResult: MergeAccountsResult = useSelector(
    (state: MergeAccountState) => state.mergeData.mergeRequestStatus.result
  );

  const onDoneClicked = () => {
    dispatch(resetMergeData());
  }

  const mergeHasErrors = doesMergeHaveErrors(mergeAccountResult);

  return (
    <SummaryPageWrapper>
      <SummmaryPageContentWrapper>
        <SummaryTitle>
          Merge process has completed{' '}
          {mergeHasErrors ? 'with some errors' : 'sucessfully'}
        </SummaryTitle>
        <SummaryContent>
          <MergeSummary mergeAccountResult={mergeAccountResult} />
        </SummaryContent>
      </SummmaryPageContentWrapper>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button variant="solid" color="primary" onClick={onStartMergeClicked}>
          {'Merge Another Account'}
        </Button>
        <Button variant="outlined" onClick={onDoneClicked}>
          {'Done'}
        </Button>
      </div>
    </SummaryPageWrapper>
  );
};
