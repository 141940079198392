import React from 'react';
import styled from 'styled-components';

export const SummaryPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100%;
`;

export const SummmaryPageContentWrapper = styled('div')`
  margin: 20px 0 10px 0;
  height: calc(100% - 150px);
  width: 1000px;
  border-radius: 8px;

  border: 1px solid #e0e0e0;
  padding: 20px;
`;

export const SummaryTitle = styled('div')`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 28px;
  color: #2f426c;
  margin-bottom: 10px;
  text-align: center;
`;
export const SummaryContent = styled('div')`
  height: calc(100% - 42px);
`;
