import React, { ReactElement } from 'react';
import { SelectedItemIcon } from '../../svg-icons/SelectedItemIcon';
import Link from '@material-ui/core/Link';
import { StyledTextItem } from '../styles/styles';
import { MenuItem } from '../types/types';

export const renderMenuItem = (
  item: MenuItem,
  currentSelected: string
): ReactElement => {
  if (item.component) {
    const Component = item.component;
    return (
      <>
        <Component />
        {item.alt && currentSelected === item.alt && <SelectedItemIcon />}
      </>
    );
  } else if (item.href) {
    return (
      <Link key={item.text} href={item.href} target="_blank" className="link">
        {item.text}
      </Link>
    );
  }
  return (
    <StyledTextItem>
      {item.icon && item.icon} <div className="text">{item.text}</div>
      {currentSelected === item.text && <SelectedItemIcon />}
    </StyledTextItem>
  );
};
