import React from 'react';
import { ContentDiv, EmptyLabel } from '../merge-account-page.styles';
import { MergeAccountState } from '../../merge-account.store';
import { MergeDataState } from '../../merge-data.slice';
import { useSelector } from 'react-redux';
import { StyledLoader } from '../wizard/steps/wizard-steps.styles';
import { AccountName, TitleLabel } from './page-display-options.styles';

export const PageLoadingDisplay = ({}) => {
  const sourceAccountName = useSelector(
    (state: MergeAccountState) => state.mergeData.sourceAccount?.name
  );

  const mergeData: MergeDataState = useSelector(
    (state: MergeAccountState) => state.mergeData
  );

  return (
    <ContentDiv>
      <TitleLabel>
        Merging&nbsp;<AccountName>{mergeData.sourceAccount.name}</AccountName>
        &nbsp;into&nbsp;
        <AccountName>{mergeData.targetAccount.name}</AccountName>
      </TitleLabel>
      <StyledLoader />
    </ContentDiv>
  );
};
